import { CxBuilderModule } from '../cx-builder/cx-builder.module';
import { RelaySharedModule } from '../shared/shared.module';
import { DelayComponent } from './delay/delay.component';
import { JourneyPreviewComponent } from './journey-preview/journey-preview.component';
import { StartComponent } from './start/start.component';
import { StopComponent } from './stop/stop.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JourneyPdfPreviewComponent } from './journey-pdf-preview/journey-pdf-preview.component';
import { JourneyPdfExpandPreviewComponent } from './journey-pdf-expand-preview/journey-pdf-expand-preview.component';
@NgModule({
  imports: [CommonModule, RelaySharedModule, CxBuilderModule],
  declarations: [
    DelayComponent,
    JourneyPreviewComponent,
    StartComponent,
    StopComponent,
    JourneyPdfPreviewComponent,
    JourneyPdfExpandPreviewComponent,
  ],
  exports: [JourneyPreviewComponent],
})
export class PreviewModule {}
