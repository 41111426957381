import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ToolbarService {
  
  collapsed = false;
  inspectorError = new EventEmitter<string>();
  inspectorUpdateConflict = new EventEmitter<string>();
  inspectorSave = new EventEmitter<string>();
  requestEditMessage = new EventEmitter<string>();
  requestCopyMessage = new EventEmitter<string>();
  requestCopyMessageResponse = new EventEmitter<string>();
  delayIntervalTypeUpdate = new EventEmitter<string>();
  openInspector = new EventEmitter<string>();
  
  constructor() { }
  
  reset() {
    this.inspectorError = new EventEmitter<string>();
    this.inspectorUpdateConflict = new EventEmitter<string>();
    this.inspectorSave = new EventEmitter<string>();
    this.delayIntervalTypeUpdate = new EventEmitter<string>();
    this.openInspector = new EventEmitter<string>();
  }
}
