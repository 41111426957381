import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import * as c from 'crypto-js';

@Injectable()
export class TwoWayPackerService {

  constructor() { }

  static pack(msg: string, id: string): string {
    const kSize = 32;
    const iterCount = 500;

    const salt = c.lib.WordArray.random(64);
    const key = c.PBKDF2(id, salt, {keySize: kSize, iterations: iterCount, hasher: c.algo.SHA1});
    const iv = c.lib.WordArray.random(16);
    const eData = c.AES.encrypt(msg, key, {iv: iv});

    // packed obj
    const bufData = Buffer.concat([
      Buffer.from(salt.toString(c.enc.Base64), 'base64'),
      Buffer.from(iv.toString(c.enc.Base64), 'base64'),
      Buffer.from(eData.toString(), 'base64')
    ]);

    return bufData.toString('base64');
  }

  static unpackSHA256(data: string, id: string): string {
    const kSize = 32;
    const iterCount = 500;

    try {
      const dBuf = Buffer.from(data, 'base64');

      // unpack the salt/iv/encrypted data
      const dSaltRaw = dBuf.slice(0, 64).toString('base64');
      const dSalt = c.enc.Base64.parse(dSaltRaw);
      const dIvRaw = dBuf.slice(64, 80).toString('base64');
      const dIv = c.enc.Base64.parse(dIvRaw);

      const dat = dBuf.slice(80).toString('base64');

      // Generate a key for decryption using shared secret and passed in salt
      const dKey = c.PBKDF2(id, dSalt, {keySize: kSize, iterations: iterCount, hasher: c.algo.SHA256});
      return c.AES.decrypt(dat, dKey, {iv: dIv}).toString(c.enc.Utf8);

    } catch (e) {
      console.error(e);
    }
  }

  static unpack(data: string, id: string): string {
    const kSize = 32;
    const iterCount = 500;

    try {
      const dBuf = Buffer.from(data, 'base64');

      // unpack the salt/iv/encrypted data
      const dSaltRaw = dBuf.slice(0, 64).toString('base64');
      const dSalt = c.enc.Base64.parse(dSaltRaw);
      const dIvRaw = dBuf.slice(64, 80).toString('base64');
      const dIv = c.enc.Base64.parse(dIvRaw);

      const dat = dBuf.slice(80).toString('base64');

      // Generate a key for decryption using shared secret and passed in salt
      const dKey = c.PBKDF2(id, dSalt, {keySize: kSize, iterations: iterCount, hasher: c.algo.SHA1});
      const result = c.AES.decrypt(dat, dKey, {iv: dIv}).toString(c.enc.Utf8);
      if (result == null || (typeof result === 'string' && result.length === 0)) {
        // Failed to unpack with SHA1 hasher, try SHA256
        return this.unpackSHA256(data, id);
      }
      return result;
    } catch (e) {
      // Failed to unpack with SHA1 hasher, try SHA256
      return this.unpackSHA256(data, id);
    }
  }
}
