import React from 'react';
import { ButtonGroup, MODE } from 'baseui/button-group';
import { RelayButton } from './RelayButton';

export const RelayToggle = ({
  selected,
  cb,
}: {
  selected: number;
  cb: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => any;
}) => {
  return (
    <ButtonGroup
      mode={MODE.radio}
      selected={selected}
      onClick={cb}
      overrides={{
        Root: {
          style: {
            backgroundColor: '#aaa',
            borderRadius: '4px',
            width: '100px',
            alignItems: 'center',
            verticalAlign: 'middle',
          },
          props: {
            'data-testid': 'relay-button-group',
          },
        },
      }}
    >
      <RelayButton dataTestid={`disable-btn`}>No</RelayButton>
      <RelayButton dataTestid={`enable-btn`}>Yes</RelayButton>
    </ButtonGroup>
  );
};
