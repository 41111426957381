import { createTheme, Theme } from 'baseui';
import { Font } from 'baseui/themes';

export type CustomColorTokens = {
  // grayscale
  gray100: string;
  gray200: string;
  gray300: string;
  gray400: string;
  gray500: string;
  gray600: string;
  gray700: string;
  gray800: string;
  gray900: string;

  // slate
  slate100: string;
  slate200: string;
  slate300: string;
  slate400: string;
  slate500: string;
  slate600: string;
  slate700: string;
  slate800: string;
  slate900: string;

  // Transparent Color
  transparent: string;

  // App Colors - Alerts
  info: string;
  success: string;
  warning: string;
  error: string;
  white: string;
  black: string;
};
export interface CustomTheme extends Theme {
  colors: Theme['colors'] &
    CustomColorTokens & {
      // chat footer
      twoWayChatFooterBackground?: string;
      // Chat bubble colors
      chatBubbleBackgroundAgent?: string;
      chatBubbleTextAgent?: string;
      chatBubbleBackgroundCustomer?: string;
      chatBubbleTextCustomer?: string;
    };
  sizing: Theme['sizing'] & {
    agentInfoButtonPadding?: string;
    // two way chat card footer
    twoWayCardFooterPaddingHorizontal?: string;
    twoWayCardFooterPaddingVertical?: string;
    twoWayChatFooterInputIconGap?: string;
    chatBubblePadding?: string;
    notificationChatItemPadding?: string;
    notificationChatItemMargin?: string;
  };
  borders: Theme['borders'] & {
    agentInfoButtonBorderRadius?: string;

    chatBubbleBorderRadius: string;
    chatBubbleBorderColor: string;
  };
  typography: Theme['typography'] & {
    chatBubbleFont: Font;
  };
}

const primitiveColors: CustomColorTokens = {
  // grayscale
  gray100: '#EDEEEF',
  gray200: '#DBDDDE',
  gray300: '#C9CDCE',
  gray400: '#B7BCBE',
  gray500: '#70797C',
  gray600: '#6E7376',
  gray700: '#636668',
  gray800: '#4E4F51',
  gray900: '#231F20',

  // slate
  slate100: '#CCE2EB',
  slate200: '#99C5D7',
  slate300: '#66A9C2',
  slate400: '#338CAE',
  slate500: '#006F9A',
  slate600: '#00597B',
  slate700: '#00435C',
  slate800: '#002C3E',
  slate900: '#00161F',

  // Transparent Color
  transparent: 'transparent',

  // App Colors - Alerts
  info: '#0089BF',
  success: '#2CD7C0',
  warning: '#F5C22C',
  error: '#ED5F67',
  white: '#FDFDFD',
  black: '#000000',
};

const primitiveSpacing = {
  // Spacing
  space_1: '0.25rem',
  space_2: '0.5rem',
  space_3: '0.75rem',
  space_4: '1rem',
  space_5: '1.25rem',
  space_6: '1.5rem',
  space_7: '2rem',
  space_8: '2.5rem',
  space_9: '2.75rem',
  space_10: '3rem',
  space_11: '3.5rem',
  space_12: '4rem',
  space_13: '4.5rem',
  space_14: '5rem',
  space_15: '6rem',
  space_16: '7.5rem',
};

const primitives = {
  ...primitiveColors,
  ...primitiveSpacing,
};

const overrides = {
  colors: {
    chatBubbleBackgroundCustomer: primitives.gray100,
    chatBubbleTextCustomer: primitives.gray800,
    chatBubbleBackgroundAgent: primitives.gray800,
    chatBubbleTextAgent: primitives.gray100,

    twoWayChatFooterBackground: primitives.gray100,
  },
  sizing: {
    agentInfoButtonPadding: primitives.space_6,
    twoWayCardFooterPaddingHorizontal: primitives.space_4,
    twoWayCardFooterPaddingVertical: primitives.space_3,
    twoWayChatFooterInputIconGap: primitives.space_2,
    chatBubblePadding: primitives.space_4,
    notificationChatItemPadding: primitives.space_6,
    notificationChatItemMargin: primitives.space_2,
  },
  borders: {
    agentInfoButtonBorderRadius: '5px',
    chatBubbleBorderRadius: primitives.space_5,
    chatBubbleBorderColor: primitives.gray100,
  },
  typography: {
    chatBubbleFont: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
    },
  },
} as CustomTheme;

export const customTheme = createTheme(primitives, overrides) as CustomTheme;
