<div *ngIf="showDialog" class="dialog-background">
    <div class="dialog-wrapper">
        <div class="dialog-content" [class.dialog-content--wide]="wide">
            <div *ngIf="showClose" class='close-button' (click)="cancelAction()"><i class='fa fa-close'></i></div>
    
            <p *ngIf="message != null">{{message}}</p>
    
            <!-- 
                There are 2 options for how to pass in content here:
                    - using ngContent will instantiate all components when the messageDialog is declared
                    - using ngTemplate will instantiate components when `*ngIf="showDialog"` is true, and the content is displayed
                Ref: https://medium.com/claritydesignsystem/ng-content-the-hidden-docs-96a29d70d11b#38c9
            -->
            <ng-content></ng-content>
            <ng-container [ngTemplateOutlet]="template"></ng-container>
    
            <button *ngIf="showCancel"
                id="message-dialog-cancel" type="button" class="btn {{cancelButtonClass}}"
                (click)="cancelAction()">{{cancelButtonText}}</button>
            <button *ngIf="showAffirm"
                id="message-dialog-ok" type="button" class="btn {{affirmButtonClass}}"
                (click)="affirmAction()">{{affirmButtonText}}</button>
        </div>
    </div>
</div>
