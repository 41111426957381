import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { SecureHttp } from '@app/security/secure-http';
import { CreateKeyResponse, GetKeysResponse } from '@app/core/models/api-key';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ApiKeyService {
  constructor(private secureHttp: SecureHttp) {}

  getUrl = (clientId) =>
    `${environment.apiKeyAdminURLBase}/client/${clientId}/apikey`;

  getKeys(clientId: string): Observable<GetKeysResponse> {
    return this.secureHttp.get(this.getUrl(clientId));
  }

  createKey(clientId: string): Observable<CreateKeyResponse> {
    return this.secureHttp.post(this.getUrl(clientId), {});
  }
}
