import React from 'react';

export const SortIcon = ({
  isActive,
  isReverse,
}: {
  isActive: boolean;
  isReverse: boolean;
}) => {
  if (!isActive) {
    return <i className="fa fa-sort" style={{ cursor: 'pointer' }} />;
  } else {
    return (
      <i
        className={isReverse ? 'fa fa-sort-desc' : 'fa fa-sort-asc'}
        style={{ cursor: 'pointer' }}
      />
    );
  }
};
