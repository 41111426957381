import { styled } from 'baseui';

//adapted from app.scss: .icon .icon-right
export const ButtonIconRight = styled('div', {
  lineHeight: '31px',
  display: 'inline-block',
  borderStyle: 'none none none solid',
  borderColor: 'rgba(91, 90, 89, 0.2)',
  borderWidth: '1px',
  paddingRight: '0px',
  paddingLeft: '.28em',
  marginLeft: '.3em',
});

export const ButtonIconLeft = styled('div', {
  lineHeight: '31px',
  display: 'inline-block',
  borderStyle: 'none none none none',
  borderColor: 'rgba(91, 90, 89, 0.2)',
  borderWidth: '1px',
  paddingLeft: '0px',
  paddingRight: '.28em',
  marginRight: '.3em',
});
