<div class="step-action step-action-{{actionType}}" [ngClass]="isPrimary ? 'primary' : ''" [class.invalid]="isInvalidMessage" *ngIf="showAction()">
  <div class="row" id="step-action-{{actionId}}">
    <div class="col-sm-2 step-action-icon">
      <i class="{{iconClasses()}}" aria-hidden="true"></i>
    </div>
    <div class="col-sm-8 step-action-text">{{actionLabel}}</div>
    <div class="pull-right">
      <i class="fa fa-caret-right" aria-hidden="true"></i>
    </div>

    <!-- only display svg for 'message' types, don't display in journey preview (showSVG = false). -->
    <svg *ngIf="actionType === 'message' && showSVG" xmlns="http://www.w3.org/2000/svg" version="1.1" width='58' height='90'>
      <g transform="translate(26, 52)">
        <path d="M 20,11 5,20 5,3 z" />
      </g>
      <line class="top-line" x1="24" y1="0"
            stroke-width="2" />
      <line class="down-line" x1="25" x2="25" y1="68" y2="0"
            stroke-width="2" />
      <line class="bottom-line" id="" x1="12" y1="0"
            stroke-width="2" />
    </svg>

  </div>
</div>
