import { Direction } from '@app/core/utils/ordering';

/**
 * The type of the items in the list you're sorting.
 * ex:
 *    type Project = { name: string, startDate: Date, endDate: Date };
 *    const projects: Project[] = [
 *        { name: 'Spaghetti Sale', startDate: new Date('1/15/22'), endDate: new Date('1/30/22') }, 
 *        { name: 'Farfalle Fiesta', startDate: new Date('8/1/20'), endDate: new Date('8/15/20') }
 *    ];
 *    sortBy<Project>(projects, Direction.Asc, 'name');
 */
type ListItemTypeT<T extends object> = T;

/**
 * Field name on ListItemTypeT that will get us the value you want to sort by. 
 * Value must be of type PreDefinedValueType.
 * ex, given data above:
 *   sortBy<Project>(projects, Direction.Asc, 'name');
 * projects[0][name] must contain a value of type Date | string | number
 */
 type FieldName = string; 

/*
 * Function that derives a value (of unknown type) from ListItemTypeT.
 * ex, given the data above, types would look like this:
 * 
 *    sortBy<Project>(data: Project[], Direction.Asc,
 *        (item: Project): number => item.endDate - item.startDate
 *    );
 *    
 *    sortBy<Project>(data: Project[], Direction.Asc,
 *        (item: Project): number => item.name.length
 *    );
*/
type FieldValueFn<ListItemTypeT extends object> = ((item: ListItemTypeT) => any);

/**
 * @description sorts list passed in data param IN-PLACE, according to the direction 
 *   and fieldNameOrFn params. This fn uses Array.sort, and doesn't do any object 
 *   cloning, so whatever list you pass in WILL be modified. 
 * @generic ListItemTypeT = type of the items in the list you're sorting 
 *   (not the type of the Value you're sorting by!)
 * @param data List to sort.  note this list will be sorted IN-PLACE.  
 * @param direction 'Asc' or 'Desc', as defined in the Direction type
 * @param field can be either a string field name, or a function that 
 *   returns the value you want to sort by.
 * @returns the sorted list.  Note this is just for convenience, as this fn sorts in-place.
 */
export const sortBy = <ListItemTypeT extends object>(
  data: ListItemTypeT[],
  direction: Direction,
  fieldNameOrFn: FieldValueFn<ListItemTypeT> | FieldName, 
): ListItemTypeT[] => {

  // if there's 0 or 1 item, return data untouched
  if (data.length <= 1) { return data; }
  
  // get a function that will return the value that we're comparing/passing to sort 
  let getCompareValue: FieldValueFn<ListItemTypeT>; // accepts param of type ListItemType, returns any
  if (typeof fieldNameOrFn === 'string') {
    getCompareValue = fnForFieldName<ListItemTypeT>(fieldNameOrFn, data[0]);
  } else if (typeof fieldNameOrFn === 'function') {
    getCompareValue = fieldNameOrFn;
  }

  // Sorts data in-place!! 
  data = data.sort((a, b) => {
    const valA = getCompareValue(a);
    const valB = getCompareValue(b);
    return compareValuesForSort(valA, valB, direction);
  });

  // return data for convenience, but the original data is already sorted!
  return data;
}

/**
 * Convenience fn that allows you to pass in a field name, and generates a function for you.
 * Assumes strings are case-insensitive, numbers should be parsed, and dates are just dates
 * @param fieldName 
 * @param item 
 * @returns 
 *  - Fn<string | number | Date> (happy path)
 *  - undefined (if item[fieldName] doesn't exist)
 */
const fnForFieldName = <ListItemTypeT extends object>(fieldName: FieldName, item: ListItemTypeT): (item: ListItemTypeT) => string | number | Date => {
  if (typeof item[fieldName] === 'string') {
    return (item): string => item[fieldName].toLowerCase();
  } else if (typeof item[fieldName] === 'number') {
    return (item): number => parseInt(item[fieldName]);
  } else if (item[fieldName] instanceof Date) {
    return (item): Date => item[fieldName].valueOf();
  }
}

/**
 * A function that returns 0, 1, or -1, as expected by Array.sort
 * @param valA first value to compare
 * @param valB second value to compare.  Must be same T as valA
 * @param direction 'Asc' or 'Desc', as defined in the Direction type
 * @returns *  1 if valA > valB
 *          * -1 if valA < valB
 *          *  0 if valA === valB
 */
const compareValuesForSort = <T extends any>(valA: T, valB: T, direction: Direction): number => {
  if (valA === valB) {
    return 0;
  }

  if (direction === Direction.Asc) {
    return valA > valB ? 1 : -1;
  } else {
    return valA < valB ? 1 : -1;
  }
}