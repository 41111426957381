import { Customer } from '../models/customer';
import { ClientService } from './client.service';
import { Injectable, EventEmitter } from '@angular/core';

/**
 * A service to coordinate the customer lookup and journey launch
 */
@Injectable()
export class CustomerLookupLaunchService {

  private _customer: Customer;
  private _productGroupName: string;
  public productGroupNameUpdates: EventEmitter<string>;
  
  constructor(private _clientService: ClientService) { }
  
  get customer() {
    return this._customer;
  }
  
  set customer(customer: Customer) {
    this._customer = customer;
    this._productGroupName = null;
    this.productGroupNameUpdates = new EventEmitter<string>();
    if (this.customer.product_group_id) {
        this._clientService.getProductGroupForCurrentUser(this.customer.product_group_id).subscribe(
            pg => {
                this._productGroupName = pg.name;
                this.productGroupNameUpdates.emit(this._productGroupName);
            });
    }
  }
  
  get productGroupName() {
    return this._productGroupName;
  }
}
