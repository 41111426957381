<div class="inspector" [class.collapsed-size]="toolbarService.collapsed">
  <div class="header header-with-number">
    <span id="message-number" class="message-number">{{trigger.step}}</span>
    <span class="inspector-title" i18n="Header for time delay">Trigger</span>
    <i class="fa fa-times clickable" aria-hidden="true" (click)="close.emit()"></i>
  </div>
  <div class="body">
    <form [formGroup]="triggerForm" (keydown.enter)="keydownEnter($event)">
      <div>
        <div class="label" i18n="Label for trigger nickname">Nickname</div>
        <div>
          <input id="client-trigger-id" [formControlName]="'triggerNickname'"/>
        </div>
      </div>
      <div>
        <div class="label" i18n="Label for trigger nickname">Client Trigger ID</div>
        <div>
          <input id="trigger-nickname" [formControlName]="'clientTriggerId'"/>
        </div>
      </div>
  
      <div class="form-group">
        <div class="label">Trigger ID</div>
        <div id="trigger-id" class="value">{{trigger.name}}</div>
        <rn-copied-message [inputData]="trigger.name"></rn-copied-message>
      </div>
  
      <div class="form-group">
        <div class="label" i18n="Label for Trigger Labels List">Labels</div>
        <p *ngIf="labelsLengthCheck(trigger.labels.length)" class="error-text">no more than 20 labels are allowed</p>
        <rn-editable-list [formArray]="triggerLabelArray" [limit]="20"></rn-editable-list> 
      </div>
      
      <div>
        <div class="label" i18n="Label for trigger notes">Notes</div>
        <div>
          <textarea id="trigger-notes" [formControlName]="'triggerNote'"></textarea>
        </div>
      </div>
  
      <div class="footer-bar">
        <span id="cancelBtn" class="cancel-link" (click)="cancel()">
          <span class="icon"><i class="fa fa-angle-left" aria-hidden="true"></i> </span>
            <span i18n="Cancel the current operation">Cancel</span>
        </span>
        <button id="saveBtn" class="next-link btn btn-primary" (click)="save()" [disabled]="isSaveDisabled()">
          <span i18n="Button heading to save trigger">Save</span>
          <span class="icon icon-right"><i class="fa fa-angle-right"></i></span>
        </button>
      </div>
    </form>
  </div>
</div>
