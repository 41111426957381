import { CustomValidators } from '@app/core/utils/custom-validators';
import * as _ from 'lodash';

export class FormatUtils {

  static formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber == null) {
      return phoneNumber;
    }

    if (CustomValidators.isPhoneNumberExtMatch(phoneNumber)) {
      return phoneNumber.trim();
    }

    phoneNumber = phoneNumber.replace(/\D/g, '');
    if (phoneNumber.length === 10) {
        phoneNumber = phoneNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
    } else if (phoneNumber.length >= 11) {
        phoneNumber = phoneNumber.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d\d\d)/, "$1($2) $3-$4");
    }
    return phoneNumber;
  }
  static formatInputField(inputField: string | string[]): string {
    if (inputField && (inputField instanceof Array)) {
      return inputField.map((field) => FormatUtils.formatInputFieldStr(field)).join(', ');
    }
    return FormatUtils.formatInputFieldStr(inputField.toString());
  }

  static formatInputFieldStr(inputField: string ): string {
    if (inputField === "ssn_last4") {
      return 'Last 4 of SSN';
    }

    inputField = inputField.replace(/\_|\-/g, ' ');

    _.forEach(inputField.match(/\w+/g), word => {
      if (word === 'of') {
        return;
      }
      if (word.match(/csr/i)) {
        inputField = inputField.replace(word, 'CSR');
        return;
      }
      inputField = inputField.replace(word, _.capitalize(word));
    });

    return inputField;
  }

  static sanitizePhone(phoneNum: string): string {
    return phoneNum.replace(/[^\d]/g, '');
  }

  static truncateTextWithEllipsis(value: string, length: number): string {
    return value.length > length ? value.slice(0, length) + '...' : value;
  }
}
