<div>
  <tab-bar [ngClass]="{'exp-lib-admin-tab': true }" [tabs]="tabs" [(currentTabId)]="currentTabId" (currentTabIdChange)="onTabChange($event)" [experienceLibraryAdminTabs]="true"></tab-bar>
  <div *ngIf="isIndustryTabDisplayed">
    <div>
      <rn-industry-list></rn-industry-list>
    </div>
  </div>
  <div *ngIf="isCompanyTypeTabDisplayed">
    <div>
      <rn-company-type-list></rn-company-type-list>
    </div>
  </div>
  <div *ngIf="isOutcomeTabDisplayed">
    <div>
      <rn-outcome-list></rn-outcome-list>
    </div>
  </div>
  <div *ngIf="isExperienceTypeTabDisplayed">
    <div>
      <rn-experience-type-list></rn-experience-type-list>
    </div>
  </div>
</div>