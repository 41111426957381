import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';

// Model and Service Imports
import { Client } from '../../../../../core/models/client';
import { Journey } from '@app/core/models/journey';
import { ClientService } from '../../../../../core/services/client.service';
import { JourneySelectService } from '../../../../../core/services/journey-select.service';
import { JourneyService } from '@app/core/services/journey.service';
import { CsrJourneysModalService } from '@app/core/services/csr-journeys-modal.service';

@Component({
  selector: 'app-agent-journey-select',
  templateUrl: './agent-journey-select.component.html',
  styleUrls: ['./agent-journey-select.component.scss']
})
export class AgentJourneySelectComponent implements OnInit {
  @Input() client: Client;

  journeysSelected: Array<string> = [];
  journeyToRemove: string;
  colors: string[] = this.clientService.selectJourneyColors;

  constructor(
    private clientService: ClientService,
    private journeySelectService: JourneySelectService,
    private journeyService: JourneyService,
    private csrJourneysModalService: CsrJourneysModalService) { }

  ngOnInit() {
    this.refreshJourneys();

    /* when modal closes, reset journeys back to whatever is currently in `client.onboarding.csr.eligible_triggers`; */
    this.csrJourneysModalService.closeCSRJourneysModal.subscribe(() => {
      this.refreshJourneys();
    });

    /** when the "select these journeys" button is clicked in the journey list, take the selectedTriggerIds in the journeySelectService 
     *  (finalized triggers here) and assign them to the client object, so when the save action and put request happens, the new triggers 
     *  will get sent over
     */
    this.journeySelectService.journeySelectionFinalized.subscribe((finalizedTriggers: string[]) => {
      this.client.onboarding.csr.eligible_triggers = finalizedTriggers;
    });
  }

  generateColor(index): string {
    return this.colors[index % this.colors.length];
  }

  /**
   * Requests the journey object for each selected triggerId in journeySelectService. 
   * Uses these journeys to display selected journeys as an alpha-sorted list of 
   * select-journey-button components.
   */
  refreshJourneys() {
    if (!(this.journeySelectService.selectedJourneyIDs && this.journeySelectService.init)) {
      this.journeySelectService.setSelectedJourneyIDs(this.client.onboarding.csr.eligible_triggers); 
    }

    const ids: string[] = this.journeySelectService.selectedJourneyIDs;
    this.journeysSelected = [];

    if (ids?.length > 0) {
      this.fetchJourneysForTriggerIds(ids).subscribe((journeys) => {
        this.setJourneys(journeys);
      });
    }
  }

  private fetchJourneysForTriggerIds(ids: string[]): Observable<Journey[]> {
    const observables: Observable<Journey>[] = [];
    ids.forEach(id => observables.push(
      this.journeyService.getJourneyById(id, this.client.id).pipe(
        catchError((error) => of(null)) // error handling for individual observables is required to prevent 1 failed call from cancelling the whole stream
      )
    ));

    return forkJoin(observables);
  }

  private setJourneys(journeys: Journey[]) {
    this.journeysSelected = [];
    journeys = _.compact(journeys); // remove 'null' values
    const sortedJourneys = _.sortBy(journeys, journey => {
      return journey.live.name.toLowerCase();
    });
    sortedJourneys.forEach(journey => this.journeysSelected.push(journey.id));

    if (this.journeySelectService.init) {
      this.client.onboarding.csr.eligible_triggers = this.journeysSelected;
    }
  }

  removeJourney(journeyID: string): void {
    this.journeyToRemove = undefined;
    this.journeySelectService.removeJourney(journeyID);
    this.refreshJourneys();
  }
}
