<div *ngIf="selectionType!==null" class="dialog-background under-title"></div>

<ng-container *ngIf="isTemplateCopy()">
  <div class="copy-wrapper exp-lib-wrapper">
    <rn-experience-library [listTitle]="getListTitle()"></rn-experience-library>
  </div>
</ng-container>

<ng-container *ngIf="isImport()">
  <div class="copy-wrapper exp-lib-wrapper">
    <rn-experience-import></rn-experience-import>
  </div>
</ng-container>

<ng-container *ngIf="selectionType!==null && !isTemplateCopy() && !isImport()">
  <div class="copy-wrapper">
    <app-journey-list [selectionType]="selectionType" [copyType]="copyType"
      [includeSmsOnlyMessages]="includeSmsOnlyMessages" [csrFilter]="csrFilter" [limit]="limit"
      [listTitle]="getListTitle()" [ngStyle]="{'z-index': 3, 'position': 'relative'}"></app-journey-list>
  </div>
</ng-container>

<ng-container *ngIf="selectionType===null">
  <div class="journey-dialog" [ngClass]="{'exp-lib-feature-on': showUseRelayExperienceOption, 'exp-lib-feature-off': !showUseRelayExperienceOption}">
    <div class="title-bar">
      <span i18n="Prompt for new or existing experience">How would you like to start?</span>
    </div>
    <div>
      <div class="cx-builder-btns">
        <div id="use-relay-experience-template-btn" *ngIf="showUseRelayExperienceOption" class="btn btn-primary journey-dialog-btn use" routerLink="/cx-builder/experience-copy/experience-library">
          <i class="fa fa-list-alt"></i>
          <div i18n="Label for button to use a Relay template experience - first line">Use a</div>
          <div i18n="Label for button to use a Relay template experience - second line">Relay Experience</div>
        </div>
        <div class="btn btn-primary journey-dialog-btn add" routerLink="/cx-builder/experience-metadata">
          <img src="assets/icons/add-journey.png" alt="An airplane with a plus icon to indicate the create action">
          <div i18n="Label for button to add a new experience - first line">Create a</div>
          <div i18n="Label for button to add a new experience - second line">New Experience</div>
        </div>
        <div class="btn btn-primary journey-dialog-btn copy" routerLink="/cx-builder/experience-copy">
          <img src="assets/icons/copy-journey.png" [alt]="'An airplane with a smaller copy behind it to indicate the copy action'">
          <div i18n="Label for button to copy an existing experience - first line">Copy An</div>
          <div i18n="Label for button to copy an existing experience - second line">Existing Experience</div>
        </div>
        <div class="btn btn-primary journey-dialog-btn import" *ngIf="showImportExperienceOption()" routerLink="/cx-builder/experience-import">
          <i class="fa fa-cloud-upload"></i>
          <div i18n="Label for button to import an existing experience - first line">Import An</div>
          <div i18n="Label for button to import an existing experience - second line">Experience</div>
        </div>
      </div>
      <div class="cancel-link-area">
        <button routerLink="/experiences" id="cx-builder-cancel-btn" class="btn btn-light cx-builder-cancel-link" type="button">cancel</button>
      </div>
    </div>
  </div>
</ng-container>
