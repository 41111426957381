import { Pipe, PipeTransform } from '@angular/core';
/*
  DESCRIPTION
  
  Will run the function in question 1 time at startup, 
  and then not run again until one of the arguments change, 
  as per the angular specs for pure pipes: https://angular.io/guide/pipes#pure-pipes

  useful when you'd prefer to write a function then to store a value, but dont want 
  that function running on every single repaint.

  - Will rerun if primitive type arguments change, or if objects/arrays are replaced. 
  - Will not rerun if object/array params are simply modified.


  USAGE

  in a template
  {{ myFunction | once: 'arg1':'arg2':'arg3':'arg4' }}
  - Note: no parens

  in a typescript file
  new OncePipe().transform(myFunction, arg1, arg2, arg3)
*/

@Pipe({
  name: 'once',
  pure: true
})
export class OncePipe implements PipeTransform {
  transform(func: Function, ...params): any {
    return func(...params);
  }
}
