import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { PushAPIReactContainer } from './push-api-react-container/PushAPIReactContainer';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'rn-push-api',
  templateUrl: './push-api.component.html',
  styleUrls: ['./push-api.component.scss'],
})
export class PushApiComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  messageDialog: MessageDialogComponent;
  currentClient: Client;
  client: Client;
  clientId: string;
  reactWrapperId: string;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
  ) {
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.clientService.getClient(this.clientId).subscribe(
      (client) => {
        this.client = client;
      },
      () => {
        this.client = new Client();
        // this.messageDialog.showMessage(
        //   `Oops...Could not load client: ${params['clientId']}`
        // );
      }
    );
    this.reactWrapperId = 'push-api-react-wrapper-id';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges() {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(PushAPIReactContainer, {
          clientId: this.clientId,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }
}
