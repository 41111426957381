import { SmsClass } from '@app/core/models/message';
import { BlackoutWindow } from '@app/core/models/blackout-window';

export interface CloseReasons {
  name: string;
  use_detail_text: boolean;
  enabled: boolean;
}

export class TwoWayConfig {
  enabled: boolean;
  default_auto_response: string = "Hi! Thank you for contacting us! An agent will be with you as soon as possible.";
  after_hours_auto_response: string = "We are not currently online, but we’d like to talk to you. One of our agents will message you as soon as possible. Thank you!";
  after_hours_blackout_window: BlackoutWindow = null; // if no blackout window exists, components are responsible for setting it to client defaults.  
  sms: SmsClass = new SmsClass({showSmsByDefault: true}); // ignoring client config value, since otherwise you wouldn't get notified when twoway conversation happens
  close_reasons_enabled: boolean = false;
  close_reasons: CloseReasons[] = [];
  mask_agent_display_name: boolean = false;
  agent_display_name: string = '';

  constructor(data = { enabled: false }) {
    this.enabled = data['enabled'];
    this.default_auto_response = data['default_auto_response'] || this.default_auto_response;
    this.after_hours_auto_response = data['after_hours_auto_response'] || this.after_hours_auto_response;
    this.after_hours_blackout_window = data['after_hours_blackout_window'] || this.after_hours_blackout_window;
    this.close_reasons_enabled = data['close_reasons_enabled'] || this.close_reasons_enabled;
    this.close_reasons = data['close_reasons'] || this.close_reasons;
    this.mask_agent_display_name = data['mask_agent_display_name'] || this.mask_agent_display_name;
    this.agent_display_name = data['agent_display_name'] || this.agent_display_name;

    if (data['sms']) {
      this.sms = data['sms'];
    } else {
      this.setSmsDefaults();
    }
  }

  private setSmsDefaults() {
    this.sms.body = "Hi, @{account_firstname}! You have a new message waiting for you. Click here ";
    this.sms.alternate_text = "Hi there! You have a new message waiting for you. Click here @{auth-link}";
    this.sms.text = `${this.sms.body} ${this.sms.auth_link} ${this.sms.disclaimer}`;
  }
}
