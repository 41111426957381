<div  [ngClass]="{'search-bar-container-for-media': isMediaLibrary, 'search-bar-container': !isMediaLibrary}" [class.search-bar-container--fixed]="isFixed && !isMediaLibrary" [class.search-bar-container-for-media--fixed]="isFixed && isMediaLibrary" 
     [style.left.px]="navbarStateService.sideNavbarWidth" (window:click)="hideSearchableFields()">
     <div class="search-bar">
        <ng-content></ng-content>
        <div class="search-widget-container">
            <div class="search-widget">
                <button id="search-drop-down-btn" class="btn btn-default" [class.drop-down-active]="showSearchableFields" (click)="toggleSearchableFields($event)">
                    <i class="fa fa-caret-down fa-lg"></i>
                </button>
                <div class="search-field-wrapper">
                    <input id="search-field" class="search-field" placeholder="Search..." i18n-placeholder="Placeholder for search input" [class.drop-down-active]="showSearchableFields" [(ngModel)]="searchText" (keypress)="checkForEnter($event)">
                <button class="search-field-wrapper__search-clear-icon" [hidden]="searchText === ''" (click)="clearSearch()">
                    <i class="fa fa-times"></i>
                </button></div>
                <button id="submit-search" class="submit-search btn btn-primary" [class.drop-down-active]="showSearchableFields" (click)="submitSearchRequest()">
                    <i class="fa fa-search"></i>
                </button>
            </div>
            <div *ngIf="showSearchableFields" id="searchable-fields" (click)="displaySearchableFields($event)">
                <div *ngFor="let searchableField of searchableFields; let i = index" class="field-row" id="field-row-{{i}}">
                    <div (click)="toggleSearchField(searchableField.fieldName)" class="check-box" id="check-box-{{i}}"><i class="fa fa-check fa-lg" *ngIf="this.searchableFieldsChecked[searchableField.fieldName]"></i></div>
                    <span class="check-box-label" id="check-box-label-{{i}}">{{searchableField.displayName}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="priorSearchText" class="search-criteria">
            <span i18n="Search results label" class="results-label">Results for:</span> 
            <span id="search-text" class="search-text">{{truncatedPriorSearchText}}</span> 
            <i class="fa fa-times-circle" id="clear-search" (click)="clearSearch()"></i>
        </div>
    </div>
</div>
