<div class="row journey-row" [class.active]="expanded && isSelectedVersion" [ngClass]="{'deleted': journeyRecord.deleted}">
  <div class="multi-select-container" *ngIf="showCheckbox">
    <div class="checkbox-container" *ngIf="journeyRecord.id" (click)="stopProp($event)">
      <input type="checkbox" id="checkbox-{{journeyRecord.id}}">
      <label class="fake-checkbox" for="checkbox-{{journeyRecord.id}}" (click)="journeyHasBeenChecked($event)">
        <i class="fa fa-check" aria-hidden="true" [style.visibility]="isCheckedJourney ? 'visible' : 'hidden'"></i>
      </label>
    </div>
  </div>
  <div [ngClass]="journeyListUtils.columnNameClassWidth" class="colw journey-name wrdbrk">
    <ng-container *ngIf="showVersionToggle">
      <div class="version-select-radio-btn"
           [class.active]="isSelectedVersion"
           [class.draft]="isDraft()"
           [class.complete]="isLive()"
           (click)="versionRadioToggled($event)">
      </div>
    </ng-container>

    <span>{{journeyContent.name}}</span>
  </div>
  <div *ngIf="isOutOfBoxExperienceEnabled" id="journey-record-outcome-{{index}}" class="colw colw-7 journey-data-field center wrdbrk">
    <span class="ctrl-overflow" [matTooltip]="outcome_names" matTooltipPosition="above">{{outcome_names}}</span>
  </div>
  <div *ngIf="isOutOfBoxExperienceEnabled" id="journey-record-experience-type-{{index}}" class="colw colw-7 journey-data-field center wrdbrk">
    <span class="ctrl-overflow" [matTooltip]="experience_type_names" matTooltipPosition="above">{{experience_type_names}}</span>
  </div>
  <div id="journey-record-created-by-{{index}}" [ngClass]="journeyListUtils.columnCreatedByClassWidth"  class="colw journey-data-field center wrdbrk">
    <span>{{journeyRecord.created_by_name}}</span>
  </div>
  <div id="journey-record-product-group-{{index}}" [ngClass]="journeyListUtils.columnProductGroupClassWidth" class="colw journey-data-field center wrdbrk">
    <span>{{journeyContent.product_group_internal_name }}</span>
  </div>
  <div id="journey-record-campaign-{{index}}" [ngClass]="journeyListUtils.columnCampaignClassWidth" class="colw journey-data-field center wrdbrk">
    <span>{{journeyContent.campaign}}</span>
  </div>
  <div [ngClass]="journeyListUtils.columnDateCreatedClassWidth" class="colw journey-data-field center">
    <span>{{journeyRecord.created_at | date:'shortDate'}}</span>
  </div>
  <div [ngClass]="journeyListUtils.columnDateModifiedClassWidth" class="colw journey-data-field center">
    <span>{{journeyContent.updated_at | date:'shortDate'}}</span>
  </div>
  <div [ngClass]="journeyListUtils.columnStatusClassWidth" class="colw journey-data-field center journey-status-container">
    <ng-container *ngIf="!showVersionToggle  && !shouldSuppressDrafts()">
      <span class="status status--draft" [class.status--active]="journeyRecord.draft" i18n="Label for experience in draft status">Draft</span>
      <span class="status status--complete" [class.status--active]="journeyRecord.live" i18n="Label for experience in complete status">Complete</span>
    </ng-container>
    <ng-container *ngIf="!shouldSuppressDrafts() && showVersionToggle">
      <span [ngClass]="{'status status--draft': true, 'status--active': isDraft(), 'status--hidden': isLive()}"
            i18n="Label for experience in draft status">Draft</span>
      <span [ngClass]="{'status status--complete': true, 'status--active': isLive(), 'status--hidden': isDraft()}"
            i18n="Label for experience in complete status">Complete</span>
    </ng-container>
    <ng-container *ngIf="shouldSuppressDrafts() && !showVersionToggle">
      <span class="status status--complete status--active launcher-mode"
            i18n="Label for experience in complete status">Complete</span>
    </ng-container>
  </div>
  <app-spinner class="colw journey-busy-icon" *ngIf="busy"></app-spinner>
  <div [ngClass]="journeyListUtils.columnActionIconsClassWidth" class="colw journey-button-row" *ngIf="!busy">
    <ng-container *ngIf="isAllJourneysList() && showActions">

      <i *ngIf="showEditExperienceButton(journeyRecord)" id="edit-icon-{{journeyRecord.id.slice(0,8)}}"
        routerLink="/cx-builder/experience-builder/{{journeyRecord.id}}"
        class="fa fa-pencil" aria-hidden="true" matTooltip="Edit" matTooltipPosition="above"></i>

      <i *ngIf="journeyPreviewable()" id="preview-icon-{{journeyRecord.id.slice(0,8)}}"
        (click)="previewJourney()"
        class="fa-solid fa-file-magnifying-glass" aria-hidden="true" matTooltip="Preview" matTooltipPosition="above"></i>

      <i *ngIf="showSendExperienceButton(journeyRecord)"
        id="launch-icon-{{journeyRecord.id.slice(0,8)}}"
        routerLink="/launcher/launch/{{journeyRecord.id}}"
        [class.disabled]="isDraft() && showActions"
        [class.hidden-icon]="!isAllJourneysList()"
        class="fa fa-paper-plane" aria-hidden="true" matTooltip="Send" matTooltipPosition="above"></i>

      <i *ngIf="showExportExperienceButton(journeyRecord)" id="export-icon-{{journeyRecord.id.slice(0,8)}}"
         [class.disabled]="disableExportExperienceButton(journeyRecord) && showActions"
         class="fa-solid fa-file-export" aria-hidden="true" matTooltip="Export" matTooltipPosition="above"
      (click)="exportExperience(journeyRecord, $event)"></i>

      <i *ngIf="showArchiveButton(journeyRecord)"
         id="delete-icon-{{journeyRecord.id.slice(0,8)}}"
         (click)="archiveJourney(journeyRecord, $event)"
         class="fa fa-archive" aria-hidden="true" matTooltip="Archive" matTooltipPosition="above"></i>

      <i *ngIf="showUnarchiveButton(journeyRecord)"
         id="restore-icon-{{journeyRecord.id.slice(0,8)}}"
         (click)="restoreJourney(journeyRecord, $event)"
         class="fa fa-undo" aria-hidden="true" matTooltip="Restore" matTooltipPosition="above"></i>



    </ng-container>

    <ng-container	
      *ngIf="selectionType==='customer-list-launch' && isLive() && permissionService.checkPermissions(permissions.journey_trigger)">
      <i routerLink="/launcher/launchforuser/{{activeRoute.snapshot.params['ccid']}}/launch/{{journeyRecord.id}}"	
         class="fa fa-paper-plane" aria-hidden="true" *ngIf="!journeyRecord.deleted"></i>	
    </ng-container>

    <button id="copy-btn-{{journeyRecord.id.slice(0,8)}}" class="btn btn-primary btn-copy-journey"
            *ngIf="showCopyBtn" (click)="copyJourney(journeyRecord, $event)">
      <span class="btn-label" i18n="Label for copy-experience button">copy</span>
      <span class="icon icon-right">
                <img src="assets/icons/copy-journey-sm.png">
            </span>
    </button>
    <button id="launch-btn-{{journeyRecord.id.slice(0,8)}}" class="btn btn-warning btn-launch"
            *ngIf="launcherMode && !journeyRecord.deleted" routerLink="/launcher/launch/{{journeyRecord.id}}"
            [class.disabled]="isDraft() || !journeyRecord.live">
      <span class="btn-label" i18n="Label for launch-experience button">Launch</span>
      <span class="icon icon-right">
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </span>
    </button>

    <button id="select-fallback-btn-{{journeyRecord.id.slice(0,8)}}" class="btn btn-primary select-journey"
            *ngIf="selectionType === 'CSR' && !showCheckbox" (click)="selectFallbackJourney(journeyRecord, $event)">
      <span class="btn-label" i18n="Label for select-experience button">Select</span>
    </button>

    <ng-container *ngIf="showActions">
      <i *ngIf="!expanded" class="fa fa-caret-right expand-collapse" matTooltip="Expand" matTooltipPosition="above"></i>
      <i *ngIf="expanded" class="fa fa-caret-down expand-collapse" matTooltip="Collapse" matTooltipPosition="above"></i>
    </ng-container>
  </div>
</div>
<p *ngIf="journeyHasClientTriggerId && showCopyBtn" class="client-trigger-id-message">
  <i class="fa fa-exclamation-circle"></i>
  Client Trigger IDs will not appear in copied experiences.
</p>
