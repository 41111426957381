import { ProductGroup } from '@app/core/models/client';
import { MessageClass } from '@app/core/models/message';
import {
  Message as MessageComponent,
  StyleProvider,
  defaultBrandingData,
  BrandingData,
  DisclaimerContainer,
} from '@relaynetwork/design-system';
import {
  MediaAsset,
  buildFeedMessageStructure,
} from '@relaynetwork/rn-node-feed-api-types';
import { ExpirationUnit } from '@relaynetwork/rn-node-feed-api-types/dist/routes/messages';
import React, { useMemo, useState, useEffect } from 'react';

type WirePreviewProps = {
  message?: MessageClass;
  brandingColor: string;
  disclaimerConfig?: BrandingData['disclaimer'];
  isDestinationMessageDetail?: boolean;
  previewMode?: 'expanded' | 'collapsed';
  components?: any[];
  productGroup?: ProductGroup;
  clientId?: string;
  getMediaAssetForMessage: (assetId: string) => Promise<MediaAsset>;
};

function WirePreview({
  message,
  brandingColor,
  disclaimerConfig,
  isDestinationMessageDetail = false,
  previewMode,
  components,
  productGroup,
  clientId,
  getMediaAssetForMessage,
}: WirePreviewProps) {
  const [renderedMessage, setRenderedMessage] = useState(null);

  useEffect(() => {
    const messageExpiration = message.parent
      ? components.find((component) => message.parent === component.name)
          ?.message_expiration
      : {
          unit:
            message.message_expiration?.unit === 'days'
              ? ExpirationUnit.Day
              : ExpirationUnit.Week,
          amount: message.message_expiration?.amount || null,
          type: message.message_expiration?.type || null,
        };
    buildFeedMessageStructure(
      clientId,
      components,
      message.name,
      (_, assetId) => {
        return getMediaAssetForMessage(assetId);
      },
      productGroup,
      productGroup?.consent.in_wire_upgrade.ts_cs,
      messageExpiration,
    )
      .then((message) => {
        setRenderedMessage(message);
      })
      .catch(console.error);
  }, [
    message,
    components,
    productGroup,
    clientId,
    brandingColor,
    disclaimerConfig,
  ]);

  const brandingData = useMemo(
    () => ({
      ...defaultBrandingData,
      color: brandingColor,
      disclaimer: disclaimerConfig,
    }),
    [brandingColor],
  );
  return (
    renderedMessage && (
      <StyleProvider brandingData={brandingData}>
        <MessageComponent
          message={renderedMessage}
          isPreview
          previewMode={previewMode}
        />
        {isDestinationMessageDetail &&
          brandingData.disclaimer.messageDetailsEnabled && (
            <DisclaimerContainer
              content={brandingData.disclaimer.text ?? ''}
              expandMaxHeight={previewMode === 'expanded'}
            />
          )}
      </StyleProvider>
    )
  );
}

export default WirePreview;
