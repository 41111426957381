<div class="network-container" >
  <div class="banner-container">
    <img class="banner" [src]="productGroup.branding.banner_s3_url" />
  </div>
  <ng-container *ngIf="productGroup.description || productGroup.contact_us_info.length">
    <div class="product-group-info-trigger"
         [ngStyle]="{'background-color': productGroup.branding.color}"
         (click)="viewProductInfo = !viewProductInfo"
         [ngClass]="{'open':viewProductInfo}">
      <span class="triangle"></span>
    </div>
    <div class="product-group-info" [ngStyle]="{'border-top':  '1px solid '+ productGroup.branding.color}">
      <div class="brand">
        <h3 class="brand-name">{{productGroup.name}}</h3>
        <p>{{productGroup.description}}</p>
      </div>
      <div class="contact-info-container" [ngStyle]="{'border-bottom':  '2px solid '+ productGroup.branding.color}">
        <div class="contact-info" *ngFor="let contact of productGroup.contact_us_info">
          <p>{{contact.display_text}}</p>
          <p class="contact-value">{{contact.value}}</p>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </ng-container>
  <div>
    <bubble [twoWay]="true" [text]="messageText"></bubble>
  </div>
</div>
