import { UntypedFormArray, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
// TODO: uncomment when DV-5175 is complete
// import { ControlGroup } from '@app/two-way/control-groups/models/control-group';
// import { ControlTag } from '@app/two-way/control-groups/models/control-tag';
import { ControlGroup, ControlTag } from '@app/core/services/control-tags.service';
import { CustomValidators } from '@app/core/utils/custom-validators';

export class ControlGroupsFormBuilder {
  /**
    Creates a form in this shape: 

    controlGroupsForm: FormGroup
        groups: FormArray<ControlGroup[]>
            temp_id: FormControl<string> (ui-only key used to track which group is selected.  Matches group_id for previously-saved groups)
            group_id: FormControl<string> (only present on groups that have been saved previously)
            group_name: FormControl<string> (required, must be unique within the 'groups' FormArray)
            tags: FormArray<ControlTag[]> (tags are added on control-group-select, not init)
                tag_id: FormControl<string> (only present on tags that have been saved previously)
                tag_name: FormControl<string> (required, must be unique within the 'tags' FormArray)
                tag_autocomplete: FormControl<string> (optional)
  */
  static buildGroupsFormArray(groups: ControlGroup[] = []): UntypedFormArray {
    const formGroups: UntypedFormGroup[] = groups.map((rawGroup) => {
      return ControlGroupsFormBuilder.buildGroupForm(rawGroup);
    });

    return new UntypedFormArray(formGroups, CustomValidators.itemsAreUniqueByKey('group_name'));
  }

  static buildGroupForm(group: ControlGroup = new ControlGroup()): UntypedFormGroup {
    return new UntypedFormGroup({
      temp_id: new UntypedFormControl(group.temp_id),
      group_id: new UntypedFormControl(group.group_id),
      group_name: new UntypedFormControl(group.group_name, [Validators.required])
    }) as UntypedFormGroup;
  }

  static buildTagsFormArray(tags: ControlTag[] = []): UntypedFormArray {
    const tagsArray = new UntypedFormArray([], CustomValidators.itemsAreUniqueByKey('tag_name'));

    tags.forEach((tag) => {
      tagsArray.push(ControlGroupsFormBuilder.buildTagForm(tag))
    });

    return tagsArray as UntypedFormArray;
  }

  static buildTagForm(tag: ControlTag = new ControlTag()): UntypedFormGroup {
    const group = new UntypedFormGroup({
      tag_id: new UntypedFormControl(tag.tag_id),
      tag_name: new UntypedFormControl(tag.tag_name, [Validators.required]),
      tag_autoresponse: new UntypedFormControl((tag.tag_autoresponse || ''), Validators.required)
    });

    if (!tag.tag_autoresponse) { 
      group.get('tag_autoresponse').disable(); // disabled if there is no value on init
    }

    return group;
  }
}
