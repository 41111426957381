<div class="select-tag-container">
  <h4>Tags</h4>
  <select class="form-control" id="select-tag" [(ngModel)]="selectedTag">
    <option value="" selected>Select</option>
    <option *ngFor="let tag of filterTags" [value]="tag.tag_id">{{tag.tag_name}}</option>
  </select>
  <button class="btn btn-primary btn-block" (click)="addTag()">Filter</button>
</div>

<div class="selected-tags">
  <div *ngFor="let t of selectedTags">
    <button class="btn btn-primary btn-remove" (click)="removeTag(t.tag_id)">
      <span>{{t.tag_name}}</span>
    </button>
  </div>
</div>
