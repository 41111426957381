import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  templateUrl: './client-config-container.component.html',
  styleUrls: ['./client-config-container.component.scss'],
})
export class ClientConfigContainerComponent implements OnInit, OnDestroy {
  routeSub$: Subscription;
  hideCrumbs: boolean = false;
  subnavLinks: { text: string; path: string }[];
  statusTabs: object[] = [
    { id: 'client-info', text: 'Client Information' },
    { id: 'product-group', text: 'Product Group Information' },
  ];
  featureFlags = FeatureFlags;

  constructor(
    private activatedRoute: ActivatedRoute,
    private featureService: FeatureService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.setSubnavLinks();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setSubnavLinks();
      });
  }

  ngOnDestroy() {
    this.routeSub$.unsubscribe();
  }

  isClientEdit(segment2): boolean {
    return segment2?.path === 'edit';
  }

  isPgEdit(segment2, pgId): boolean {
    return segment2?.path === 'product-group' && pgId != null;
  }

  isPowerUpsFormConfig(): boolean {
    return (
      this.featureService.checkFlag(this.featureFlags.power_ups_form_config) ===
      true
    );
  }

  setSubnavLinks(): void {
    const routeInfo = this.activatedRoute.snapshot.children[0];
    const cId = routeInfo.url[0];
    const segment2 = routeInfo.url[1]; // 'edit' or 'product-group'  (not sure what else to call this?)
    const pgId = routeInfo.url[2];

    this.showHideCrumbs();

    /* Product Group */
    if (this.isPgEdit(segment2, pgId)) {
      this.subnavLinks = [
        { text: 'Basics', path: `/client/${cId}/product-group/${pgId}` },
        { text: 'Legal', path: `/client/${cId}/product-group/${pgId}/legal` },
        {
          text: 'Onboarding',
          path: `/client/${cId}/product-group/${pgId}/onboarding`,
        },
      ];

      /* Client Information */
    } else if (this.isClientEdit(segment2)) {
      this.subnavLinks = [
        { text: 'Basics', path: `/client/${cId}/edit` },
        { text: 'Settings', path: `/client/${cId}/edit/branding-settings` },
        { text: 'Validation', path: `/client/${cId}/edit/validation` },
        { text: 'Inputs', path: `/client/${cId}/edit/inputs` },
        { text: 'Onboarding', path: `/client/${cId}/edit/onboarding` },
        this.isPowerUpsFormConfig()
          ? { text: 'Power-Ups', path: `/client/${cId}/edit/power-ups-config` }
          : undefined,
      ].filter(Boolean);

      /* Neither */
    } else {
      this.subnavLinks = [];
    }
  }

  tabClicked(tabId) {
    if (tabId === 'client-info') {
      this.goToClient();
    } else {
      this.goToPGList();
    }
  }

  goToClient(): void {
    const routeInfo = this.activatedRoute.snapshot.children[0];
    if (routeInfo.url[1]?.path !== 'edit') {
      this.router.navigateByUrl(
        '/client/' + routeInfo.params.clientId + '/edit',
      );
    }
  }

  activeTabId(): string {
    return this.activatedRoute.snapshot.children[0].data.step;
  }

  isComplete(param: string): boolean {
    return this.activatedRoute.snapshot.children[0].data.step === param;
  }

  goToPGList(): void {
    const routeInfo = this.activatedRoute.snapshot.children[0];

    if (routeInfo.url[1]?.path === 'edit') {
      this.router.navigateByUrl(
        '/client/' + routeInfo.params.clientId + '/product-group',
      );
    }
  }

  private showHideCrumbs(): void {
    const key = '/client';
    this.hideCrumbs = this.router.url === key;
    this.routeSub$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideCrumbs = event.urlAfterRedirects === key;
      }
    });
  }
}
