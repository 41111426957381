import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorUtils } from '@app/core/utils/color-utils';

@Component({
  selector: 'rn-tag-filter-list', // rn-tag-filter-list
  templateUrl: './tag-filter-list.component.html',
  styleUrls: ['./tag-filter-list.component.scss']
})
export class TagFilterListComponent {
  @Input() appliedFilters: string[];
  @Input() showClearBtn: boolean = true;
  @Input() colorPresets?: object[] = [];
  @Output() filterRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() filtersCleared: EventEmitter<void> = new EventEmitter<void>();

  backgroundColor: string;

  constructor() { }

  removeFilter(filterToRemove: string): void {
    this.filterRemoved.emit(filterToRemove);
  }

  clearFilters(): void {
    this.appliedFilters = [];
    this.filtersCleared.emit();
  }

  getFilterColor(filter: string): string {
    if (!filter) { 
      return ''; 
    }

    if (this.colorPresets[filter]) { 
      return this.colorPresets[filter]; 
    } 

    return ColorUtils.stringToHSL(filter);
  }
}


