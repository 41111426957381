<div class="rn-page">
  
  <h3 class="rn-page__heading">
    <span>All Jobs</span>
    <span *ngIf="appliedFilters.length >= 1">results for <strong>"{{displayableFilters()}}"</strong></span>
    <span *ngIf="appliedSort"> sorted by <strong>{{appliedSort | titleText}}</strong></span>
  </h3>
  <div class="rn-page__utility">
    <rn-search-input (searchTerm)="searchChanged($event)"></rn-search-input>
    <rn-select id="sort-select" [selectedOption]="appliedSort" [options]="sortOptions" [labelText]="'Sort by'" [placeholderText]="'Select Sort'" (selectChanged)="sortSelectChanged($event)"></rn-select>
    <div>
      <rn-select #filetypeSelect id="type-filter-select" [name]="'filetype'" [options]="fileTypes" [labelText]="'Filter by'" [placeholderText]="'Filter By Type'" (selectChanged)="filterChanged($event)"></rn-select>
      <rn-select #statusSelect id="status-filter-select" [name]="'status'" [options]="statusFilterOptions" [placeholderText]="'Filter By Status'" (selectChanged)="filterChanged($event)"></rn-select>    
    </div>
  </div>
  <div class="rn-page__sub-utility">
    <rn-tag-filter-list [colorPresets]="jobsListStatusColorMap" [appliedFilters]="appliedFilters" (filterRemoved)="filterRemoved($event)" (filtersCleared)="clearFilters()"></rn-tag-filter-list>
    <button [ngClass]="{'btn-refresh': true, 'btn-refresh--loading': jobsLoading }" (click)="refreshJobsClicked()">
      <i aria-hidden="true" class="fa fa-refresh"></i>
      {{jobsLoading ? 'Loading' : 'Refresh List'}}
    </button>
  </div>
  <div class="rn-list">
    <ul class="rn-list__row rn-list__row--header"> 
      <li class="rn-list__col">
        <span class="rn-list__heading-1" (click)="sortChanged('status')">
          Status
          <i *ngIf="appliedSort === 'status' && sortDirection === 'desc'" class='fa fa-caret-down'></i>
          <i *ngIf="appliedSort === 'status' && sortDirection === 'asc'" class='fa fa-caret-up'></i>
        </span>
      </li> 
      <li class="rn-list__col rn-list__col--wide">
        <span class="rn-list__heading-1" (click)="sortChanged('filename')">
          File Name
          <i *ngIf="appliedSort === 'filename' && sortDirection === 'desc'" class='fa fa-caret-down'></i>
          <i *ngIf="appliedSort === 'filename' && sortDirection === 'asc'" class='fa fa-caret-up'></i>
        </span>
      </li> 
      <li class="rn-list__col rn-list__col--spacer"></li>
      <li class="rn-list__col">
        <span class="rn-list__heading-1" (click)="sortChanged('filetype')">
          File Type 
          <i *ngIf="appliedSort === 'filetype' && sortDirection === 'desc'" class='fa fa-caret-down'></i>
          <i *ngIf="appliedSort === 'filetype' && sortDirection === 'asc'" class='fa fa-caret-up'></i>
        </span>
      </li> 
      <li class="rn-list__col">
        <span class="rn-list__heading-1" (click)="sortChanged('last_modified_at')">
          Modified At
          <i *ngIf="appliedSort === 'last_modified_at' && sortDirection === 'desc'" class='fa fa-caret-down'></i>
          <i *ngIf="appliedSort === 'last_modified_at' && sortDirection === 'asc'" class='fa fa-caret-up'></i>
        </span>
      </li> 
      <li class="rn-list__col">
        <span class="rn-list__heading-1" (click)="sortChanged('job_started_at')">
          Started At
          <i *ngIf="appliedSort === 'job_started_at' && sortDirection === 'desc'" class='fa fa-caret-down'></i>
          <i *ngIf="appliedSort === 'job_started_at' && sortDirection === 'asc'" class='fa fa-caret-up'></i>
        </span>
      </li> 
    </ul>
    
    <div *ngIf="noJobsForClient() && !jobsError()">
      This client has no File Engine jobs<span *ngIf="appliedFilters.length > 0"> matching these filters</span>.
    </div>

    <div *ngIf="jobsError()">
      There was an error loading Jobs for this client. 
    </div>

    <div *ngFor="let job of jobs$ | async; trackBy: jobTrackbyKey">
      <rn-list-item-expandable>
        <div expandable-item>
          <ul class="rn-list__row"> 
            <li class="rn-list__col">
                <rn-status [status]="job.status"></rn-status>
            </li> 
            <li class="rn-list__col rn-list__col--wide">{{job.filename}}</li> 
            <li class="rn-list__col rn-list__col--spacer"></li>
            <li class="rn-list__col">{{job.filetype.replace('journey', 'experience') | titleText}}</li> 
            <li class="rn-list__col">{{jobTimestampText(job, 'last_modified_at')}}</li> 
            <li class="rn-list__col">{{jobTimestampText(job, 'job_started_at')}}</li> 
          </ul>
        </div>

        <rn-jobs-list-detail expandable-detail [job]="job" 
                             [showCancel]="jobCanBeCancelled(job)" (cancelClicked)="showCancelJobWarning($event)"
                             [showSchedule]="jobCanBeScheduled(job)" (scheduleClicked)="openScheduleDialog($event)"></rn-jobs-list-detail>
      </rn-list-item-expandable>
    </div>
  </div>
</div>

<message-dialog #cancelJobDialog [showCancel]="true" [affirmButtonText]="'yes'" [cancelButtonText]="'no'" (affirm)="cancelJob($event)"></message-dialog>
<message-dialog #messageDialog></message-dialog>

<!-- using ngif out here instead of in the component -->
<schedule-launch-dialog *ngIf="scheduleTime"
    [showDialog]="true"
    [thingBeingLaunched]="'file'"
    [(scheduleTime)]="scheduleTime"
    (confirmTime)="scheduleFile($event)"
    (showDialogChange)="closeScheduleDialog()">

    <ng-container schedule-button-content>
      <span class="btn-label">Schedule</span>
      <span class="icon icon-right"><i aria-hidden="true" class="fa fa-history"></i></span>
    </ng-container>
</schedule-launch-dialog>

<message-dialog #progressDialog>
  <app-spinner></app-spinner>
</message-dialog>
