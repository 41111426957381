<form class="ivr-config__form" [formGroup]="ivrForm">
  <fieldset class="ivr-config__fieldset">
    <app-radio-btn class="toggle-container" id="allow-onboarding-minors-on"
      [btnLabel]="'Allow Onboarding Minors'" [btnName]="'allow_onboarding_minors'" [btnWidth]="'460px'"
      [btnModel]="allowOnboardingMinors.value" (btnModelChange)="allowOnboardingMinorsChanged($event)"
      [tiptext]="'Indicates whether to allow minors under the cutoff age to be onboarded.'"
    ></app-radio-btn>

    <label for="customer_minor_cutoff_age_years">Minor cutoff age</label>
    <input class="ivr-config__input" type="number" name="customer_minor_cutoff_age_years" min="0" formControlName="customer_minor_cutoff_age_years">
    <p class="error-msg" *ngIf="minorCutoffAge.invalid && minorCutoffAge.touched">
      Field is required and must contain only a positive number.
    </p>
  </fieldset>

  <fieldset class="ivr-config__fieldset">
    <legend>Data Request Exclusions</legend>
    <ul class="ivr-config__list" formArrayName="data_request_exclusions" name="data_request_exclusions">
      <li class="ivr-config__list-item" *ngFor="let dataRequestExclusion of this.dataRequestExclusions.controls; let i = index" [formGroupName]="i">
        <div class="input-container">
          <input class="ivr-config__input" type="text" name="data_request_exclusions_value_{{i}}" formControlName="value">
          <button class='btn btn-primary' (click)="deleteDataRequestExclusion(i)">remove</button>
        </div>
      </li>
    </ul>
    <div (click)="addDataRequestExclusion()" class="add-row-button">
      <i class="fa fa-plus-circle"></i><span>Add Data Request Exclusion</span>
    </div>
  </fieldset>

  <fieldset class="ivr-config__fieldset">
    <legend>Consent Exclusions</legend>
    <p>Duplicate Consent Exclusion keys will be discarded.</p>
    <ul class="ivr-config__list" formArrayName="consent_exclusions" name="consent_exclusions">
      <li>
        <div class="ivr-config__list-heading">Key</div>
        <div class="ivr-config__list-heading">Value</div>
      </li>
      <li *ngFor="let consentExclusion of this.consentExclusions.controls; let i = index" [formGroupName]="i">
        <div class="input-container">
          <input class="ivr-config__input" type="text" name="consent_exclusions_key_{{i}}" placeholder="key" formControlName="key">
          <input class="ivr-config__input" type="text" name="consent_exclusions_value_{{i}}" placeholder="value" formControlName="value">
          <button class='btn btn-primary' (click)="deleteConsentExclusion(i)">remove</button>
        </div>
      </li>
    </ul>
    <div (click)="addConsentExclusion()" class="add-row-button">
      <i class="fa fa-plus-circle"></i><span>Add Consent Exclusion</span>
    </div>
  </fieldset>

  <fieldset class="ivr-config__fieldset">
    <legend>Phone Number Mapping</legend>
    <p>Phone number must be 10 digits long, including the area code.  Duplicate Phone numbers will be discarded.</p>
    <ul class="ivr-config__list" formArrayName="phone_number_mapping" name="phone_number_mapping">
      <li class="ivr-config__list-item" >
        <div class="ivr-config__list-heading">Phone Number</div>
        <div class="ivr-config__list-heading">Product Group</div>
      </li>
      <li class="ivr-config__list-item" *ngFor="let phoneNumberMap of this.phoneNumberMaps.controls; let i = index" [formGroupName]="i">
        <div class="input-container">
          <input class="ivr-config__input" type="text" placeholder="Phone Number" name="phone_number_{{i}}" formControlName="phone_number" maxlength="11">
          <select class="form-control" formControlName="product_group_id" name="product_group_{{i}}">
            <option *ngFor="let option of this.productGroupOptions" [ngValue]="option.id">
              {{option.name}}
            </option>
          </select>
          <button class='btn btn-primary' (click)="deletePhoneNumberMap(i)">remove</button>
        </div>
      </li>
    </ul>
    <div (click)="addPhoneNumberMap()" class="add-row-button">
      <i class="fa fa-plus-circle"></i><span>Add Phone Number Map</span>
    </div>
  </fieldset>

</form>
