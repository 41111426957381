<ng-container *ngIf="selectedChannel">
  <div *ngIf="loading">
    <h4>loading messages</h4>
    <app-spinner></app-spinner>
  </div>

  <section class="messaging" *ngIf="!error && !loading">
    <div class="messaging-container" #divToScroll>
      <div class="messaging-messages">
        <ng-container *ngFor="let msg of messages">
          <app-agent-message
            *ngIf="isCurrentUser(msg.author)"
            [msg]="msg"
          ></app-agent-message>
          <app-customer-message
            *ngIf="!isCurrentUser(msg.author)"
            [msg]="msg"
          ></app-customer-message>
        </ng-container>
        <br /><br />
      </div>
    </div>
    <div class="messaging-input-container">
      <span *ngIf="typingMessage">{{ typingMessage }}</span>
      <div class="messaging-input">
        <form class="messaging-input-form">
          <textarea
            (focus)="emitFocus($event)"
            (focusout)="emitFocusOut($event)"
            maxlength="8000"
            rows="5"
            class="form-input"
            placeholder="Type a message"
            (keydown)="sendTyping($event)"
            [(ngModel)]="twoWayMessage"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
          <button
            type="submit"
            class="form-send"
            (click)="sendMessage()"
            [disabled]="disableSend()"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  </section>
</ng-container>
