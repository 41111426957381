import axios, { AxiosResponse } from 'axios';
import { environment } from '@env/environment';
import { Integration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { OtherConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';

export const client = axios.create({
  baseURL: environment.pushAPIURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
  responseType: 'json',
});

let configSet: Integration[] = [];
let cacheClientId: string = '';
let cacheTimestamp = Date.now();
export const PushAPIService = {
  async tryUpdateCache(clientId: string) {
    if (clientId !== cacheClientId || Date.now() - cacheTimestamp > 60000) {
      await PushAPIService.updateCache(clientId);
    }
  },

  async updateCacheValue(clientId: string, integrations: Integration[]) {
    configSet = integrations;
    cacheClientId = clientId;
    cacheTimestamp = Date.now();
  },

  async updateCache(clientId: string) {
    try {
      const response = await client.get<Integration[]>(
        `/${clientId}/configuration`,
      );
      configSet = response.data;
      cacheClientId = clientId;
      cacheTimestamp = Date.now();
    } catch {
      alert('Could not fetch integrations.');
    }
  },

  async getConfigSet(clientId: string) {
    await PushAPIService.tryUpdateCache(clientId);
    return configSet;
  },

  async createConfig(
    clientId: string,
    newConfig: Exclude<Integration, OtherConfiguration>,
  ): Promise<Integration[]> {
    try {
      await PushAPIService.tryUpdateCache(clientId);
      const resultSet = (
        await client.post<Integration[], AxiosResponse<Integration[]>>(
          `/${cacheClientId}/configuration`,
          newConfig,
        )
      ).data;
      await PushAPIService.updateCacheValue(clientId, resultSet);

      return resultSet;
    } catch {
      alert('Could not create the integration.');
    }
  },

  async readConfig(clientId: string, uid: string) {
    let set = await PushAPIService.getConfigSet(clientId);
    return set.filter((config: Integration) => config.uid === uid);
  },

  async updateConfig(
    clientId: string,
    updatedConfig: Exclude<Integration, OtherConfiguration>,
  ) {
    try {
      await PushAPIService.tryUpdateCache(clientId);
      await client.put<Integration[]>(
        `/${cacheClientId}/configuration/${updatedConfig.uid}`,
        updatedConfig,
      );
      await PushAPIService.updateCache(clientId);
    } catch {
      alert('Could not update integration.');
    }
  },

  async deleteConfig(clientId: string, uid: string) {
    try {
      await PushAPIService.tryUpdateCache(clientId);
      await client.delete<Integration[]>(
        `/${cacheClientId}/configuration/${uid}`,
      );
      await PushAPIService.updateCache(clientId);
    } catch {
      alert('Could not delete the integration.');
    }
  },

  getEventSubscriptionList: async (type?: string) => {
    let path = `/utility/event-list`;
    path += type ? `?type=${type}` : '';
    const payload = await client.get<{ eventlist: string[] }>(path);
    return payload.data?.eventlist ?? [];
  },

  async getIntegrationNames(clientId: string) {
    return (await PushAPIService.getConfigSet(clientId)).map((c) => c.name);
  },

  async getSfdcVersions(managed: string) {
    const path = `/sfdc/versions?managed=${managed}`;
    const payload = await client.get<string[]>(path);
    return payload.data ?? [];
  },

  async getSfmcAuthHash(clientId: string) {
    await PushAPIService.tryUpdateCache(clientId);
    const path = `/${cacheClientId}/configuration/sfmc/authhash`;
    const payload = await client.get<string>(path);
    await PushAPIService.updateCache(clientId);
    return payload.data ?? undefined;
  },

  async updateSfmcAuthHash(clientId: string, authHash: string) {
    try {
      await PushAPIService.tryUpdateCache(clientId);
      const payload = await client.put<string>(
        `/${cacheClientId}/configuration/sfmc/authhash`,
        authHash,
      );
      await PushAPIService.updateCache(clientId);
      return payload.data ?? undefined;
    } catch {
      alert('Could not update Auth Hash.');
    }
  },

  async generateSfmcAuthHash(clientId: string) {
    try {
      await PushAPIService.tryUpdateCache(clientId);
      const payload = await client.get<string>(
        `/${cacheClientId}/configuration/sfmc/generate-authhash`,
      );
      await PushAPIService.updateCache(clientId);
      return payload.data ?? undefined;
    } catch {
      alert('Could not generate a new Auth Hash.');
    }
  },
};
