<div class="header header-height"
     [class.margin-for-side-navbar-full]="navbarStateService.fullSize"
     [class.margin-for-side-navbar-small]="!navbarStateService.fullSize">

  <div class="title-area" [ngClass]="{'active': title && title.active}">

    <h1 class="primary-title">{{title?.primaryTitle}}</h1>

    <h2 class="secondary-title">{{title?.secondaryTitle}}</h2>

    <button *ngIf="title?.buttonHandler && title?.buttonAlignLeft" class="title-action btn btn-sm btn-primary" (click)="onButtonClick()">
      <span class="icon icon-left"><i class="fa {{title?.buttonIconClass}}" aria-hidden="true"></i> </span>
      <span class="button-text">{{title?.buttonText}}</span>
    </button>
    <button *ngIf="title?.buttonHandler && !title?.buttonAlignLeft" class="title-action btn btn-sm btn-primary" (click)="onButtonClick()">
      <span class="button-text">{{title?.buttonText}}</span>
      <span class="icon icon-right"><i class="fa {{title?.buttonIconClass}}" aria-hidden="true"></i> </span>
    </button>
  </div>

</div>

<div id="user_profile" [class.open]="showUserProfile" (window:click)="hideUserProfile($event)">
  <div (click)="toggleUserProfile($event)" class="no-text-selection">
    <span class="user-name">{{sessionService.currentUser?.abbreviatedName}}</span> <span class="user-icon"><i
    class="fa fa-user fa-lg" aria-hidden="true"></i></span>
  </div>
  <div id="user-profile-menu" *ngIf="showUserProfile">
    <div class="close-icon"><i class="fa fa-times fa-lg" aria-hidden="true" (click)="hideUserProfile()"></i></div>
    <div class="user-profile-menu__search-bar-wrapper" *ngIf="hasClientsDisplaySearchBar">
      <div class="user-profile-menu__search-bar">
        <input id="user-profile-menu-search-input" autofocus class="user-profile-menu__search-input" name="user-profile-menu-search" type="text" placeholder="Search..." [(ngModel)]="searchTerm">
        <button id="user-profile-menu-clear-btn" class="user-profile-menu__search-clear-icon" [hidden]="searchTerm === ''" (click)="clearSearchBar()">
          <i id="user-profile-menu-clear-icon" class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="user-profile-menu-item">
      <span>Current client</span>
    </div>
    <div class="user-profile-menu-item client">
      <img class="logo" aria-hidden="true" [src]="currentClient.branding.icon_s3_url"/>
      <span>{{currentClient.company_name}}</span>
    </div>
    <div class="client-list" *ngIf="hasClientsDisplaySearchBar">
      <div class="user-profile-menu-item">
        <span>Client list ({{ filteredClients().length }})</span>
      </div>
      <p class="client-list__message" *ngIf="hasNoClientsFromQueryDisplayError">There are no clients matching your query</p>
      <span *ngIf="!hasNoClientsFromQueryDisplayError">
        <ng-container *ngFor="let client of filteredClients(); let i = index">
          <div class="user-profile-menu-item pointer client" (click)="switchClients(client)">
            <img class="logo" aria-hidden="true" [src]="client.branding.icon_s3_url"/>
            <span i18n="Log out menu item">{{client.company_name}}</span>
          </div>
        </ng-container>
      </span>
    </div>
    <div class="client-list" *ngIf="loadingClientList">
      <div class="client-list-loading">
        <span>fetching client list</span>
        <app-spinner></app-spinner>
      </div>
    </div>
    <div class="user-profile-menu__btn-container">
      <button id="user-profile-menu-logout-btn" class="btn btn-primary user-profile-menu__btn-container--btns" type="button" routerLink="/logout">
        <i class="fa fa-power-off fa-lg" aria-hidden="true"></i> log out
      </button>
      <button id="user-profile-menu-cancel-btn" class="btn btn-secondary user-profile-menu__btn-container--btns" type="button" (click)="hideUserProfile()">cancel</button>
    </div>
  </div>
</div>
