import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';
import { TitleService } from '@app/core/services/title.service';
import { ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { BlackoutUtils } from '@app/core/utils/blackout-utils';
import { BlackoutSettings } from '@app/core/models/message';
import { SmsService } from '@app/core/services/sms.service';
import { InputParamUtil } from '@app/core/models/input-param-util';
import { switchMap } from 'rxjs/operators';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';

@Component({
  selector: 'app-setup-sms-ping',
  templateUrl: './setup-sms-ping.component.html',
  styleUrls: ['./setup-sms-ping.component.scss'],
})
export class SetupSmsPingComponent implements OnInit, OnDestroy {
  @ViewChild('smsPingMessage', { static: true })
  smsPingMessage: MessageDialogComponent;
  @ViewChild('dynamicInputsMessage', { static: true })
  dynamicInputsMessage: MessageDialogComponent;

  blackoutWindowMessage: string;
  twoWayConfig: TwoWayConfig;
  defaultProductGroup: ProductGroup;
  smsPingForm: UntypedFormGroup;
  currentClient: Client;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private smsService: SmsService,
    private twoWaySetupService: TwoWaySetupService,
    public newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleService.activate(
      this.currentClient.feed_enabled
        ? 'Relay Messenger Setup'
        : 'Two Way Messaging Setup',
      this.newFeed.enabled ? 'Notification Ping' : 'SMS Ping',
    );

    this.twoWaySetupService
      .getTwoWayConfig()
      .pipe(
        switchMap((twoWayConfig) => {
          this.twoWayConfig = twoWayConfig;
          return this.twoWaySetupService.getClient();
        }),
        switchMap((client) => {
          // if twoWayConfig doesn't have a blackout Window, set one using the client default
          if (
            !this.twoWayConfig.sms.blackout_settings &&
            !this.twoWayConfig.sms.blackout_settings.blackout_window
          ) {
            this.twoWayConfig.sms.blackout_settings = new BlackoutSettings(
              client.blackout_window,
            );
          }
          this.setBlackoutWindowMessage(client);
          this.initSmsPingForm();

          return this.twoWaySetupService.getDefaultProductGroup();
        }),
      )
      .subscribe((defaultProductGroup) => {
        this.smsService.init(this.twoWayConfig.sms, defaultProductGroup);
        this.defaultProductGroup = defaultProductGroup;
      });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  isLoading(): boolean {
    return this.smsPingForm === undefined;
  }

  saveAndContinue(skipWarning: boolean = false) {
    if (!this.smsPingForm.valid) {
      return;
    }

    this.updateConfigWithFormData(); // has to come before alternateSmsRequired() check

    if (this.alternateSmsRequired() && !skipWarning) {
      this.dynamicInputsMessage.showMessage();
      return;
    }

    this.twoWayConfig.sms.text = `${this.smsBodyCtl.value} @{auth-link} ${this.smsDisclaimerCtl.value}`;

    this.twoWaySetupService.saveTwoWayConfig(this.twoWayConfig).subscribe(
      (response) => {
        this.router.navigateByUrl(`/two-way/setup/summary`);
      },
      (error) => {
        this.smsPingMessage.showMessage(
          `We're sorry, there was an error saving your information.`,
        );
      },
    );
  }

  get smsBodyCtl(): AbstractControl {
    return this.smsPingForm.get('sms_body');
  }

  get smsDisclaimerCtl(): AbstractControl {
    return this.smsPingForm.get('sms_disclaimer');
  }

  get fullSmsText(): string {
    return this.smsService.getFullSmsBody(
      this.smsBodyCtl.value,
      this.smsDisclaimerCtl.value,
    );
  }

  alternateSmsRequired(): boolean {
    const smsInputParams = InputParamUtil.findInputParametersForObject(
      this.twoWayConfig.sms,
    );
    return (
      this.twoWayConfig.sms.is_shown === 'true' &&
      smsInputParams.length > 0 &&
      (!this.twoWayConfig.sms.is_alternate_message ||
        this.twoWayConfig.sms.alternate_text.trim().length === 0)
    );
  }

  private setBlackoutWindowMessage(client) {
    const clientBlackoutWindow = client.blackout_window;
    const weekendBlackoutMessage = BlackoutUtils.weekendBlackoutMessage(
      clientBlackoutWindow,
      'Messages will also not be sent on ',
    );
    this.blackoutWindowMessage = `Client Blackout window applies.  Messages will not be sent between ${BlackoutUtils.getDisplayHour(
      clientBlackoutWindow.start_time,
    )} and ${BlackoutUtils.getDisplayHour(
      clientBlackoutWindow.end_time,
    )} Eastern Standard Time.  ${weekendBlackoutMessage}`;
  }

  private updateConfigWithFormData() {
    const formData = this.smsPingForm.getRawValue();
    this.twoWayConfig.sms.body = formData['sms_body'];
    this.twoWayConfig.sms.disclaimer = formData['sms_disclaimer'];
    this.twoWayConfig.sms.alternate_text = formData['alternate_sms_text'];
  }

  private initSmsPingForm() {
    this.smsPingForm = this.formBuilder.group({
      alternate_sms_text: [
        this.twoWayConfig.sms.alternate_text,
        CustomValidators.validateInputParamsAuthlinkAllowed(
          InputParamUtil.inputParamPattern,
        ),
      ],
      sms_body: [this.twoWayConfig.sms.body, [Validators.required]],
      auth_link: [],
      sms_disclaimer: [this.twoWayConfig.sms.disclaimer, [Validators.required]],
    });
  }
}
