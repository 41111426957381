import { Injectable } from '@angular/core';

export class TimeWindowHour {
  displayHour: string;
  isoHour: string;
}

@Injectable()
export class SharedDateTimeService {
  private timeWindowHours: TimeWindowHour[];

  constructor() {
    this.createTimeWindowHours();
  }

  getTimeWindowHours(): TimeWindowHour[] {
    return this.timeWindowHours;
  }

  getNextTimeWindowHour(isoHour: string): string {
    const index = this.timeWindowHours.findIndex(bh => bh.isoHour === isoHour);
    let nextIndex = index + 1;
    if (nextIndex >= this.timeWindowHours.length) {
      nextIndex = 0;
    }
    return this.timeWindowHours[nextIndex].isoHour;
  }

  getPreviousTimeWindowHour(isoHour: string): string {
    const index = this.timeWindowHours.findIndex(bh => bh.isoHour === isoHour);
    let nextIndex = index - 1;
    if (nextIndex < 0) {
      nextIndex = this.timeWindowHours.length - 1;
    }
    return this.timeWindowHours[nextIndex].isoHour;
  }

  private createTimeWindowHours() {
    const hours = [];
    for (let h = 1; h <= 24; h++) {

      const hour = new TimeWindowHour();

      if (h.toString().length < 2) {
        hour.isoHour = '0' + h + ':00:00';
      } else {
        hour.isoHour = h + ':00:00';
      }

      if (h === 24) {
        hour.displayHour = (h - 12) + ':00 MIDNIGHT';
        hour.isoHour = '00:00:00';
      } else if (h === 12) {
        hour.displayHour = h + ':00 NOON';
      } else if (h < 12) {
        hour.displayHour = h + ':00 AM';
      } else {
        hour.displayHour = (h - 12) + ':00 PM';
      }
      hours.push(hour);
    }
    this.timeWindowHours = hours;
  }
}
