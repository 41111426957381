<div
  class="intro-line-1"
  i18n="Introductory for creating a new client - step 3, line 1"
>
  More Information
</div>

<client-header
  [headerLabel]="'Extra data inputs'"
  [headerGroup]="'messageInputs'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container dynamic-input-container"
  *ngIf="headerGroups.messageInputs"
>
  <div
    class="input-header"
    i18n="Introductory for product info step 3 - line 1"
  >
    Add any extra data fields you may want to use regularly when building new
    experiences and messages. The allowed characters are letters, numbers,
    dashes, periods, and underscores.
  </div>
  <br />
  <ng-container *ngIf="dataInputsArray">
    <div
      class="dynamic-inputs input-container"
      *ngFor="
        let dataInputCtl of dataInputsArray.controls;
        let i = index;
        trackBy: trackInput
      "
    >
      <label>Data input</label>

      <span
        class="error-msg"
        *ngIf="!dataInputCtl.valid && dataInputCtl.touched"
        tooltip="Must be unique, and start with 'input_'"
        placement="bottom"
      >
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>
      <input
        [formControl]="dataInputCtl"
        [ngClass]="{ 'has-error': !dataInputCtl.valid && dataInputCtl.touched }"
        class="dynamic-input"
        id="inputText-{{ i }}"
        placeholder="display text"
        i18n-placeholder="Placeholder text for display text for dynamic input"
      />
      <i class="fa fa-minus-circle" (click)="removeDataInput(i)"></i>
    </div>
  </ng-container>

  <div (click)="addDataInput()" class="add-input no-text-selection">
    <i class="fa fa-plus-circle"></i
    ><span i18n="Label for button to add new dynamic input field"
      >Add data input</span
    >
  </div>
</div>

<client-header
  [headerLabel]="'SSO Role Mapping'"
  [headerGroup]="'roleMapping'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container dynamic-input-container"
  *ngIf="headerGroups.roleMapping"
>
  <div
    class="input-header"
    i18n="map identity provider role values with relay roles"
  >
    map identity provider role values with relay roles
  </div>
  <br />
  <ng-container *ngIf="ssoRoleMapsArray">
    <div
      class="dynamic-inputs input-container"
      *ngFor="
        let roleMapGroup of ssoRoleMapsArray.controls;
        let i = index;
        trackBy: trackInput
      "
      [formGroup]="roleMapGroup"
    >
      <label>Client Role</label>
      <input
        formControlName="client_role"
        class="dynamic-input"
        id="client-role-{{ i }}"
      />
      <select
        formControlName="relay_role"
        class="dynamic-input"
        id="relay-role-{{ i }}"
      >
        <option value="client_user">Client User</option>
        <option value="client_user_with_fengine">
          Client User with File Engine
        </option>
        <option value="client_admin">Client Admin</option>
        <option value="csr">CSR</option>
        <option value="csr_message_only">CSR Message Only</option>
        <option value="two_way_user">{{client.feed_enabled ? "Messenger User" : "Two Way User"}}</option>
        <option value="two_way_admin">{{client.feed_enabled ? "Messenger Admin" : "Two Way Admin"}}</option>
      </select>
      <i class="fa fa-minus-circle" (click)="removeSSORoleMap(i)"></i>
    </div>
  </ng-container>
  <div (click)="addSSORoleMap()" class="add-input no-text-selection">
    <i class="fa fa-plus-circle"></i
    ><span i18n="Add Role Map">Add Role Map</span>
  </div>
</div>
<client-header
  [headerLabel]="'Apply Ascii Armor'"
  [headerGroup]="'asciiArmor'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container"
  *ngIf="headerGroups.asciiArmor"
  style="padding-bottom: 5px"
>
  <app-radio-btn
    [btnLabel]="'Apply Ascii Armor'"
    [btnWidth]="'300px'"
    [btnName]="'apply-ascii-armor'"
    [btnModel]="client.use_ascii_armor"
    [tiptext]="
      'Switch to ON to apply ascii armor during encryption of outputs (reject files and reports posted to either an internal or external SFTP location)'
    "
    (btnModelChange)="client.use_ascii_armor = !client.use_ascii_armor"
  ></app-radio-btn>
</div>

<client-header
  [headerLabel]="'File Engine Jobs List Upload'"
  [headerGroup]="'feUpload'"
  (toggleHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container"
  *ngIf="headerGroups.feUpload"
  style="padding-bottom: 5px"
>
  <app-radio-btn
    [btnLabel]="'Upload button and route'"
    [btnWidth]="'300px'"
    [btnName]="'show-fe-upload'"
    [btnModel]="client.fe_upload_enabled"
    (btnModelChange)="client.fe_upload_enabled = !client.fe_upload_enabled"
  ></app-radio-btn>
</div>

<client-header
  [headerLabel]="'Short/Long Code Setup'"
  [headerGroup]="'clientShortCodeLongCode'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container"
  *ngIf="headerGroups.clientShortCodeLongCode"
  [formGroup]="this.shortCodeGroup"
  style="padding-bottom: 5px"
>

  <div class="short-long-code-entry-container" style="height: 150px;">
    <div class="short-long-code-input">
      <rn-text-input
        [inputLabel]="'Short/Long Code Entry'"
        [inputControl]="this.shortCodeControl"
        (change)="onShortCodeChange($event)"
        [placeholderText]="'Short/Long Code Entry'"
      ></rn-text-input>
    </div>  
    <p class="tip-text" style="padding-top: 45px;">
      <svg width="15" [attr.height]="60" style="float: left;">
        <polyline [attr.points]="'8,0 8,24, 2,30 8,36 8,80'" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>
        <p>Long Codes: Must begin with a 1.</p>
        <p>Short Codes: Can be 5-6 digits long.</p>
      </span>
    </p>
  </div>


  <div class="short-long-code-entry-container" style="height: 300px;">
    <div class="sms-response-input">
      <label>Stop Response</label>
      <rn-client-sms-response
        [smsLanguage]="this.stopKeyword"
        id="rn-stop-client-sms-response"
        [textLimit]="this.maxLength"
      ></rn-client-sms-response>
    </div>
    <p class="tip-text" style="padding-top: 80px;">
      <svg width="15" height="fit-content" style="float: left;">
        <polyline [attr.points]="'8,0 8,39, 2,45 8,51 8,90'" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>
        <p>Remove the language for Stop All, unless the program specifically requires it.</p>
        <p><strong>%s</strong> represents the Product Group Feed display name.</p>
      </span>
    </p>
  </div>


  <div class="short-long-code-entry-container" style="height: 350px;">
    <div class="sms-response-input">
      <label>Help Response</label>
      <rn-client-sms-response
        [smsLanguage]="this.helpKeyword"
        id="rn-help-client-sms-response"
        [textLimit]="this.maxLength"
      ></rn-client-sms-response>
    </div>
    <p class="tip-text">
      <svg width="15" [attr.height]="300" style="float: left; vertical-align: middle;">
        <polyline [attr.points]="'8,0 8,138, 2,144 8,150 8,288'" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>
        Product Group information displays here by default.
        <ul>
          <li>First <strong>%s</strong> represents the Product Group Feed display name.</li>
          <li>Second <strong>%s</strong> represents the Product Group Help Text including the Relay hosted help site.</li>
        </ul>
        If specific branding needs to be used for the help site per product group, client may choose to use a self-hosted site.
        <br><br>
        If help text is going to be static across the program, a global message can be entered here instead of Product Group information.
      </span>
    </p>
  </div>


  <div class="short-long-code-entry-container" style="height: 300px;">
    <div class="sms-response-input">
      <label>Unrecognized Response</label>
      <rn-client-sms-response
        [smsLanguage]="this.unrecognizedKeyword"
        id="rn-help-client-sms-response"
        [textLimit]="this.maxLength"
        [allowedLanguages]="this.languageOptionsForUnrecognizedResponse"
      ></rn-client-sms-response>
    </div>
    <p class="tip-text" style="padding-top: 80px;">
      <svg width="15" [attr.height]="80" style="float: left;">
        <polyline [attr.points]="'8,0 8,34, 2,40 8,46 8,80'" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>This is the response received for any unrecognized keywords. If custom keywords below are unchecked, customers will receive the unrecognized response.</span>
    </p>
  </div>


  <div class="short-long-code-entry-container" id="custom-notification-keywords-select" style="height: 350px;">
    <div class="sms-response-input" style="padding-top: 100px;">
      <rn-client-sms-custom-keywords
        [shortCodeGroup]="this.shortCodeGroup"
        [shortCode]="this.shortCode"
        [client]="this.client"
      ></rn-client-sms-custom-keywords>
    </div>
    <p class="tip-text">
      <svg width="15" [attr.height]="320" style="float: left;">
        <polyline [attr.points]="'8,0 8,154, 2,160 8,166 8,320'" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>
        If custom keywords are checked, customers will receive the stop/help responses accordingly. They will be opted out if it is considered a stop response.
        <br><br>
        <strong>Best practice is to have all checked except “stopall”.</strong>
        <br><br>
        If stopall is unchecked, it will initiate the standard stop response above, and user will be opted out.
        <br><br>
        If stopall is checked, texting stop all will initiate the Relay global Stop All response: “You have stopped all mobile service alerts. You will not receive any messages.”
      </span>
    </p>
  </div>

</div>


<client-header
  [headerLabel]="'Help Site File Upload'"
  [headerGroup]="'clientHelpsite'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div
  class="collapse-container"
  *ngIf="headerGroups.clientHelpsite"
  style="padding-bottom: 5px"
>
  <rn-client-helpsite *ngIf="client.id" [client]="client"></rn-client-helpsite>
</div>

<hr />

<div class="button-container">
  <button
    id="prev-btn"
    class="btn btn-primary btn-with-icon"
    (click)="previousStep()"
  >
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button
    class="btn btn-primary"
    id="next-btn"
    (click)="saveAndContinue()"
    [disabled]="!isValid()"
  >
    <span
      i18n="Label for Next button on new client configuration"
      id="save-and-next-button"
    >
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
