<div class="input-container">
  <div class="input">
    <label>{{btnLabel}}</label>
    <span>{{helperText}}</span>
    <div class="btn-group config" data-toggle="buttons">
      <div class="btn-group">
        <div class="btn btn-sm btn-default" name="{{btnName}}" [attr.id]="formatBtnID(btnLabel, 'off')" ngDefaultControl [(ngModel)]="btnModel" (ngModelChange)="emitChangeEvent($event)"
              btnRadio="false">Off</div>

        <div class="btn btn-sm btn-default" name="{{btnName}}" [attr.id]="formatBtnID(btnLabel, 'on')" ngDefaultControl [(ngModel)]="btnModel" (ngModelChange)="emitChangeEvent($event)"
              btnRadio="true">On</div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
