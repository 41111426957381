import { Directive, HostListener, Input, ElementRef} from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive( {
  selector : 'input[trim], textarea[trim]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TrimDirective, multi: true }
  ],

} )
export class TrimDirective extends DefaultValueAccessor {
  
  private set value( val: any ) {
    this.writeValue( val );
    this.onChange( val );
  }
  
  @Input() trim: string;
    
  @HostListener( 'blur', ['$event.type', '$event.target.value'] )
  onBlur( event: string, value: string ): void {
    this.updateValue( event, value );
  }

  @HostListener( 'input', ['$event.type', '$event.target.value'] )
  onInput( event: string, value: string ): void {
    this.updateValue( event, value );
  }

  private updateValue(event: string, value: string): void {   
    if (this.trim === event) {
      this.value = value.trim();
    } else {
      this.value = value;
    }
  }

}