import { TitleService } from '@app/core/services/title.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-experience-library-admin',
  templateUrl: './experience-library-admin.component.html',
  styleUrls: ['./experience-library-admin.component.scss'],
})
export class ExperienceLibraryAdminComponent implements OnInit, OnDestroy {
  tabs = [
    { id: 'industry-tab', label: 'Industry' },
    { id: 'company-type-tab', label: 'Company Type' },
    { id: 'outcome-tab', label: 'Outcome' },
    { id: 'experience-type-tab', label: 'Experience Type' },
  ];
  currentTabId: string = 'industry-tab';
  isIndustryTabDisplayed: boolean;
  isCompanyTypeTabDisplayed: boolean;
  isOutcomeTabDisplayed: boolean;
  isExperienceTypeTabDisplayed: boolean;

  constructor(
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.titleService.activate(
      'Experience Library Admin',
      '',
      'experience library',
      'fa-chevron-left',
      () => {
        this.navigateToExpLib();
      },
      true
    );
    this.currentTabId =
      this.activatedRoute.snapshot.queryParams['tab'] ?? 'industry-tab';
    this.onTabChange(this.currentTabId);
  }

  onTabChange(newTabId: string) {
    if (newTabId === 'industry-tab') {
      this.configureTabDisplay(true, false, false, false);
    } else if (newTabId === 'company-type-tab') {
      this.configureTabDisplay(false, true, false, false);
    } else if (newTabId === 'outcome-tab') {
      this.configureTabDisplay(false, false, true, false);
    } else if (newTabId === 'experience-type-tab') {
      this.configureTabDisplay(false, false, false, true);
    } else {
      this.configureTabDisplay(false, false, false, false);
    }

    // update "tab" query param without reloading the component
    this.location.go(`/experience-library/admin?tab=${newTabId}`);
  }

  configureTabDisplay(
    isIndustryTabDisplayed: boolean,
    isCompanyTypeTabDisplayed: boolean,
    isOutcomeTabDisplayed: boolean,
    isExperienceTypeTabDisplayed: boolean
  ) {
    this.isIndustryTabDisplayed = isIndustryTabDisplayed;
    this.isCompanyTypeTabDisplayed = isCompanyTypeTabDisplayed;
    this.isOutcomeTabDisplayed = isOutcomeTabDisplayed;
    this.isExperienceTypeTabDisplayed = isExperienceTypeTabDisplayed;
  }

  navigateToExpLib() {
    this.router.navigate(['/experience-library'], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }
}
