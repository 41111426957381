import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-journey-copy',
  templateUrl: './journey-copy.component.html',
  styleUrls: ['./journey-copy.component.scss']
})
export class JourneyCopyComponent implements OnInit {
  copyType: string = null;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const type = this.activatedRoute.snapshot.data['type'];
    switch (type) {
      case 'template':
      case 'import':
        this.copyType = type;
        break;
      default:
        this.copyType = 'journey';
    }
  }
}
