import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Journey } from '@app/core/models/journey';
import { ComponentBaseClass, MessageClass } from '@app/core/models/message';
import { JourneyService } from '@app/core/services/journey.service';
import { MediaService } from '@app/core/services/media.service';
import { SelectableJourneyComponent } from '@app/cx-builder/selectable-journey-component';
import { ToolbarService } from '@app/cx-builder/services/toolbar.service';
import { SmsService } from '@app/core/services/sms.service';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
  selector: 'journey-message',
  templateUrl: './journey-message.component.html',
  styleUrls: ['./journey-message.component.scss'],
})
export class JourneyMessageComponent
  implements OnInit, SelectableJourneyComponent, AfterViewInit, OnDestroy
{
  @Input() msg: MessageClass;
  @Input() journeyId: string;
  @Input() journey: Journey;
  @Input() isValid: boolean;
  @Input() isNewStep: boolean = false;
  @Output() responseMessageSelected =
    new EventEmitter<SelectableJourneyComponent>();
  @Output() deleteMsg = new EventEmitter();
  @ViewChild('MessageWithContent', { read: ElementRef, static: true })
  messageElement: ElementRef;
  @ViewChildren(JourneyMessageComponent)
  responseMessageComponents: QueryList<JourneyMessageComponent>;
  smsTextPreview = '';
  selected = false;
  generations = [];
  boxMenuItems = [
    {
      labelText: 'Edit message',
      onClickHandler: () => {
        this.editMessage();
      },
    },
    {
      labelText: 'Delete message',
      onClickHandler: () => {
        this.deleteMessage();
      },
    },
  ];

  constructor(
    private router: Router,
    private mediaService: MediaService,
    private toolbarService: ToolbarService,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef,
    private journeyService: JourneyService,
    private smsService: SmsService,
    public newFeed: NewFeedTransitionService,
  ) {}

  ngOnInit() {
    if (this.msg.wire.media_id) {
      this.mediaService
        .getMediaAsset(this.msg.wire.media_id)
        .subscribe((media) => {
          if (media.asset_type === 'video') {
            this.msg.wire.video_url = media.url;
          } else {
            this.msg.wire.image_url = media.s3_url;
          }
        });
    }

    // ensures that we don't show @{input_url} for custom tracked link destinations
    this.smsTextPreview =
      this.msg.sms.auth_link ===
      this.smsService.allLinkTypes.custom_tracked_url.auth_link
        ? this.msg.sms.text.replace(
            this.smsService.allLinkTypes.custom_tracked_url.custom_input_field,
            this.smsService.allLinkTypes.custom_tracked_url.auth_link,
          )
        : this.msg.sms.text;
  }

  ngAfterViewInit() {
    if (this.msg.type === 'MessageSender') {
      this.getDepth(this.msg, 0);

      if (this.isNewStep) {
        this.focusComponent();
      }
    }

    this.journeyService.selectableComponennts.push(this);
  }

  ngOnDestroy() {
    const selectableComponents = this.journeyService.selectableComponennts;
    selectableComponents.splice(
      selectableComponents.findIndex((m) => m.msg.name === this.msg.name),
      1,
    );
  }

  deselectChildren() {
    this.responseMessageComponents.forEach((c) => {
      c.selected = false;
      c.deselectChildren();
    });
  }

  getJourneyComponentElementRef(): ElementRef {
    return this.messageElement;
  }

  deleteMessage() {
    this.deleteMsg.emit(this.msg);
  }

  calculateTop() {
    this.generations.forEach((a) => {
      let top = 175;
      const x = 24;
      a.forEach((r, j) => {
        const id = `response-messages-${r.name}`;
        const responsesDOM = document.getElementById(id);
        if (responsesDOM && j > 0) {
          responsesDOM.setAttribute('style', `top:${top}px`);
        }
        top += responsesDOM ? responsesDOM.clientHeight : 0;
        //calculate arrow height
        const actionbtn = document.getElementById(`step-action-${r.name}`);
        const messageBox = document.getElementById(`response-msg-${r.name}`);

        if (
          actionbtn &&
          messageBox &&
          messageBox.getClientRects()[0] &&
          actionbtn.getClientRects()[0]
        ) {
          const ah = messageBox.getClientRects()[0].top;
          const bh = actionbtn.getClientRects()[0].top;
          const diff = ah - bh;
          document
            .querySelector(`#step-action-${r.name} svg`)
            .setAttribute('height', (diff + 40).toString());
          document
            .querySelector(`#step-action-${r.name} g`)
            .setAttribute('transform', `translate(26,${diff + 4})`);
          document
            .querySelector(`#step-action-${r.name} line.down-line`)
            .setAttribute('y1', (diff + 16).toString());
          document
            .querySelector(`#step-action-${r.name} line.down-line`)
            .setAttribute('x1', (x - j * 3).toString());
          document
            .querySelector(`#step-action-${r.name} line.down-line`)
            .setAttribute('x2', (x - j * 3).toString());
          document
            .querySelector(`#step-action-${r.name} line.top-line`)
            .setAttribute('x1', (x - j * 3 + 1).toString());
          document
            .querySelector(`#step-action-${r.name} line.bottom-line`)
            .setAttribute('y1', (diff + 16).toString());
          document
            .querySelector(`#step-action-${r.name} line.bottom-line`)
            .setAttribute('y2', (diff + 16).toString());
          document
            .querySelector(`#step-action-${r.name} line.bottom-line`)
            .setAttribute('x1', (x - 1 - j * 3).toString());
          document
            .querySelector(`#step-action-${r.name} line.bottom-line`)
            .setAttribute('x2', (40).toString());
        }
      });
    });
  }

  getDepth(msg, depth: number) {
    if (!this.generations[depth]) {
      this.generations[depth] = [];
    }
    const responses = this.getResponseMessages(msg);

    this.generations[depth] = this.generations[depth].concat(responses);
    responses.forEach((r) => {
      this.getDepth(r, depth + 1);
    });
  }

  getChildValidity(msgId) {
    const msg = this.journey.latestContent.components.find(
      (c) => c.name === msgId,
    );
    if (msg) {
      return msg.wire.text === '' || msg.wire.media_id;
    } else {
      return true;
    }
  }

  editMessage() {
    this.toolbarService.requestEditMessage.emit(this.msg.name);
  }

  copyMessage() {
    if (this.msg.type === 'MessageResponse') {
      this.toolbarService.requestCopyMessageResponse.emit(this.msg.name);
    } else {
      this.toolbarService.requestCopyMessage.emit(this.msg.name);
    }
  }

  getResponseMessages(parent) {
    return this.journey.latestContent.components.filter(
      (f) => f.parent === parent.name,
    );
  }

  onDeleteMsg(c) {
    this.deleteMsg.emit(c);
  }

  getJourneyComponent(): ComponentBaseClass {
    return this.msg;
  }

  onResponseMessageClick(event, responseMessage: SelectableJourneyComponent) {
    event.stopPropagation();
    this.responseMessageSelected.emit(responseMessage);
  }

  selectComponent(responseMessage) {
    this.responseMessageSelected.emit(responseMessage);
  }

  /**
   * For a new message resulting from a new journey step,
   * find the element for the new message and scroll to
   * it in the builder-area div.
   */
  focusComponent() {
    const msgElement = Array.from(
      document.querySelectorAll('.messageNumber'),
    ).find((x) => x.innerHTML === this.msg.step);

    // have a slight delay to ensure the new element is rendered
    if (msgElement) {
      setTimeout(() => {
        const top = msgElement.getBoundingClientRect().top - 260;
        const left = msgElement.getBoundingClientRect().left - 530;
        const builderEL = <HTMLElement>document.querySelector('.builder-area');
        const y = builderEL.offsetTop + builderEL.scrollTop;
        const x = builderEL.offsetLeft + builderEL.scrollLeft;
        const offsetTop = top - 80 + y;
        const offsetLeft = left - 180 + x;
        builderEL.scroll({
          top: offsetTop,
          left: offsetLeft,
          behavior: 'smooth',
        });
      }, 1500);
    }
  }
}
