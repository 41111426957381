/*
  A Super generic component that provides an area to drop files or select files,
   and emits an event when a file is captured

   Example Usage: 

  <rn-upload-drop-area (fileCaptured)="myHandler($event)">
    <span select-btn-text>SELECT BUTTON TEXT</span>
    <p description-text>File format message</p>
  </rn-upload-drop-area>

 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rn-upload-drop-area',
  templateUrl: './upload-drop-area.component.html',
  styleUrls: ['./upload-drop-area.component.scss']
})
export class UploadDropAreaComponent {
  @Input() acceptedFileTypes: string = '.csv, text/csv';
  @Input() disabled: boolean = false;
  @Output() fileCaptured: EventEmitter<any> = new EventEmitter();

  constructor() { }

  dragOver(event) {
    event.preventDefault();
  }

  fileDropped(event) {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.fileCaptured.emit(event.dataTransfer.files[0]);
    }
  }

  fileSelected(event) {
    if (event.target['files'].length > 0) {
      this.fileCaptured.emit(event.target['files'][0]);
      event.target.value = '';
    }
  }
}
