import React, { createContext, useContext, useState, ReactNode } from 'react';

export type NewlyAssignedConvSidsContextType = {
  newlyAssignedConvSids: string[];
  setNewlyAssignedConvSids: (
    newlyAssignedConvSids:
      | string[]
      | ((newlyAssignedConvSids: string[]) => string[]),
  ) => void;
};

export type NewlyAssignedConvSidsContextProviderProps = {
  children: ReactNode;
};

const defaultState: NewlyAssignedConvSidsContextType = {
  newlyAssignedConvSids: [],
  setNewlyAssignedConvSids: () => {},
};
const NewlyAssignedConvSidsContext =
  createContext<NewlyAssignedConvSidsContextType>(defaultState);

export const NewlyAssignedConvSidsProvider = ({
  children,
}: NewlyAssignedConvSidsContextProviderProps) => {
  const [newlyAssignedConvSids, setNewlyAssignedConvSids] = useState<string[]>(
    [],
  );

  return (
    <NewlyAssignedConvSidsContext.Provider
      value={{ newlyAssignedConvSids, setNewlyAssignedConvSids }}
    >
      {children}
    </NewlyAssignedConvSidsContext.Provider>
  );
};

export const useNewlyAssignedConvSids = () => {
  const context = useContext(NewlyAssignedConvSidsContext);

  if (context === undefined) {
    throw new Error(
      `useNewlyAssignedConvSids must be used within a NewlyAssignedConvSidsProvider`,
    );
  }

  return context;
};
