export class DataMappingUtils {
    static entriesPolyFill(obj) {
        const keys = Object.keys(obj);

        const keyValuePairs = keys.map(key => {
            const value = obj[key];

            return [key, value];
        });

        return keyValuePairs;
    };
}
