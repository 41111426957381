import React from 'react';
import { SelectTitle } from '@app/api-mgmt/components/push-api/styles/ConfigurationSelectStyles';
import { Select } from 'baseui/select';
import {
  DropdownContainerWhiteOverride,
  SelectFilterComponentsOverride,
  SelectIconsContainerOverride,
  SelectIconsOverride,
  SelectInputOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';

type MethodString = 'GET'|'POST'|'PUT';

export const HTTPMethodSelectBox = ({
  placeholder,
  title,
  value,
  onChange,
}: {
  placeholder?: string;
  title?: string;
  value?: MethodString;
  onChange?: (value: MethodString) => void;
}) => {
  return (
    <>
      <SelectTitle>
        <label>{title}</label>
      </SelectTitle>
      <Select
        overrides={{
          ControlContainer: SelectFilterComponentsOverride,
          ClearIcon: {
            props: { overrides: { Svg: SelectIconsOverride } },
          },
          SelectArrow: {
            props: { overrides: { Svg: SelectIconsOverride } },
          },
          IconsContainer: SelectIconsContainerOverride,
          Dropdown: DropdownContainerWhiteOverride,
          DropdownListItem: DropdownContainerWhiteOverride,
          ValueContainer: SelectInputOverride,
        }}
        options={[
          { label: 'GET', id: 'GET' },
          { label: 'POST', id: 'POST' },
          { label: 'PUT', id: 'PUT' },
        ]}
        value={[{ label: value, id: value }]}
        placeholder={placeholder}
        onChange={(events) => onChange(events.value[0].id as MethodString)}
      />
    </>
  );
};
