import { Customer } from '@app/core/models/customer';
import { environment } from '@env/environment';
import axios from 'axios';

export const client = axios.create({
  baseURL: environment.cdmURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
});

export const UserService = {
  /** Get Customer Details
   *
   *  @param clientId the clientId.
   *  @param customerId the customerId.
   */
  getCustomerDetails: async (
    clientId: string,
    customerId: string,
  ): Promise<Customer> => {
    const url = `/client/${clientId}/customer/id/${customerId}`;
    const { data } = await client.get(url);
    return data.customer;
  },

  /** Get Customer Details By CCID
   *
   *  @param clientId the clientId.
   *  @param ccid the customer ccid.
   */
  getCustomerDetailsByCcid: async (
    clientId: string,
    ccid: string,
  ): Promise<Customer> => {
    const url = `/client/${clientId}/customer/${ccid}`;
    const { data } = await client.get(url);
    return data.customer;
  },
};
