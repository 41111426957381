import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { ChatItem, Role } from '../chat-item/ChatItem';
import { Placeholder } from '@relaynetwork/design-system';
import { UserStatusBanner } from '../user-status-badge/UserStatusBadge';
import { ConversationsClicked } from '../relay-messenger-conversations/RelayMessengerConversations';
import { useCustomerInfo } from '../../context/CustomerInfoContext';

export type TwoWayChatMessage = {
  id?: string;
  author: string;
  timestamp: string;
  body: string;
  displayName: string;
};

export type MessageSendingStatus =
  | 'notSent'
  | 'inProgress'
  | 'success'
  | 'failed';

export type TwoWayChatCardProps = {
  chatMessages: TwoWayChatMessage[];
  isJoinError?: boolean;
  errorMessage?: React.ReactNode;
  isOtherUserTyping?: boolean;
  typingMessage?: string;
  messageSendingStatus?: MessageSendingStatus;
  onTyping?: () => void;
  conversationsClicked: ConversationsClicked;
  lastMessageLoaded: boolean;
};

export type ChatTextProps = {
  handleInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onTyping?: () => void;
  messageVal: string;
  twoWayChatLoading: boolean;
};

export type TwoWayChatSkelProps = {
  alignment: string;
};

export function checkAuthorIsAgent(author: string) {
  return author && author.split('::')[0] === 'agent';
}

const TwoWayChatSkeleton = ({ alignment = 'left' }: TwoWayChatSkelProps) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: alignment === 'left' ? 'start' : 'end',
        marginTop: '20px',
        bottom: '10px',
      })}
    >
      <Placeholder
        height="12vh"
        width="70vw"
        borderRadius="15px"
        animation={true}
      ></Placeholder>
      <br />
      <Placeholder
        rows={2}
        height="4vh"
        width="30vw"
        borderRadius="10px"
        animation={true}
      ></Placeholder>
    </div>
  );
};

export const TwoWayChatCard = ({
  chatMessages,
  isJoinError,
  errorMessage,
  typingMessage = '',
  conversationsClicked: { IsMyClosedConversationsClicked },
  lastMessageLoaded = false,
}: TwoWayChatCardProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const skeletonCount = 5;
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState(chatMessages);
  const [twoWayChatLoading, setTwoWayChatLoading] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const {
    customerInfo: { closedReason, closedReasonAdditionalDetail },
  } = useCustomerInfo();

  useEffect(() => {
    setScrollFlag(false);
    setMessages(chatMessages);
  }, [chatMessages]);

  useEffect(() => {
    if (!scrollFlag && twoWayChatLoading) {
      setScrollFlag(true);
      const scrollHeight = messagesContainerRef.current.scrollHeight;
      const height = messagesContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesContainerRef.current.scrollTop =
        maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [twoWayChatLoading, scrollFlag, typingMessage]);

  useEffect(() => {
    setTwoWayChatLoading(lastMessageLoaded);
  }, [lastMessageLoaded]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).scrollTop === 0) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div
      ref={messagesContainerRef}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: customTheme.colors.white,
        flexGrow: '1',
      })}
    >
      {isJoinError ? (
        <div>{errorMessage}</div>
      ) : (
        <>
          <main
            className={css({
              paddingLeft: '1rem',
              paddingRight: '1rem',
            })}
            key={messages.length}
            onScroll={handleScroll}
          >
            {!twoWayChatLoading && (
              <div>
                {Array.from(Array(skeletonCount), (el, index) => {
                  return (
                    <TwoWayChatSkeleton
                      alignment={index % 2 ? 'right' : 'left'}
                      key={index}
                    ></TwoWayChatSkeleton>
                  );
                })}
              </div>
            )}
            {messages &&
              twoWayChatLoading &&
              messages.map((message, index) => {
                return (
                  <div
                    key={index}
                    className={css({
                      maxWidth: '75%',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      ...(!checkAuthorIsAgent(message.author)
                        ? {
                            marginRight: 'auto',
                          }
                        : {
                            marginLeft: 'auto',
                          }),
                    })}
                  >
                    <ChatItem
                      stampVariant={'user-time'}
                      user={message.displayName}
                      timestamp={message.timestamp}
                      role={
                        checkAuthorIsAgent(message.author)
                          ? Role.Agent
                          : Role.Customer
                      }
                      text={message.body}
                    ></ChatItem>
                  </div>
                );
              })}
            {typingMessage && (
              <div className="two-way-typing-prompt">
                {/* the 3 <span> elements represent the 3 loading animations  */}
                <span></span>
                <span></span>
                <span></span>
                {/* end of code for the typing prompt animation  */}
              </div>
            )}
          </main>

          {IsMyClosedConversationsClicked && (
            <>
              <UserStatusBanner
                content="This conversation has been closed."
                variant="secondary"
              ></UserStatusBanner>
              {closedReason && (
                <UserStatusBanner
                  content={closedReason}
                  secondaryContent={closedReasonAdditionalDetail}
                  variant="secondary"
                ></UserStatusBanner>
              )}
              <p
                className={css({
                  fontSize: '12px',
                  color: '#908E8F',
                  textAlign: 'center',
                  marginTop: '10px',
                })}
              >
                You can no longer respond to this conversation unless the
                customer re-opens it.
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};
