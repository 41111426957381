const hostNamePrefix = window.location.origin + '/';
export class AwsUtils {
  static addHostName(s3Url: string) {
    if (s3Url && !s3Url.startsWith(hostNamePrefix)) {
      return new URL(s3Url, hostNamePrefix).toString();
    } else {
      return s3Url;
    }
  }

  static removeHostName(url: string) {
    if (url) {
      try {
        // return the path minus leading slash
        return new URL(url).pathname.replace(/^\//, '');
      } catch (e) {
        return url.replace(hostNamePrefix, '');
      }
    }
    return url;
  }
}
export class YoutubeUtils {
  static addHostName(s3Url: string) {
    const hostNamePrefix = 'http://www.youtubeinmp4.com/redirect.php?video=';

    if (s3Url && !s3Url.startsWith(hostNamePrefix)) {
      return 'http://www.youtubeinmp4.com/redirect.php?video=' + s3Url;
    } else {
      return s3Url;
    }
  }

  static removeHostName(url: string) {
    if (url) {
      return url.replace('http://www.youtubeinmp4.com/redirect.php?video=', '');
    } else {
      return url;
    }
  }
}
