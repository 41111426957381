import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TimeOutLocalStorageService } from '@app/core/services/timeOutLocalStorage.service';
import { SecureHttp } from '@app/security/secure-http';
import { interval, map } from 'rxjs';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'rn-timer-popup',
  templateUrl: './timer-popup.component.html',
  styleUrls: ['./timer-popup.component.scss'],
})
export class TimerPopupComponent implements OnInit, OnDestroy {
  @ViewChild('audioControl', { static: true }) audiblePing: ElementRef;

  showWarning = false;
  timeLeftInSeconds: number;
  chimeHasPlayed = false;
  subscription = null;

  private readonly warningTimeInMinutes = 5 * 60;
  constructor(
    private timeOutLocalStorageService: TimeOutLocalStorageService,
    private secureHttp: SecureHttp,
    private router: Router,
    private title: Title,
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    TimeOutLocalStorageService.timeOutEventChange.subscribe(({ type }) => {
      if (type === 'delete') {
        this.router.navigateByUrl('/logout');
      }
    });
    this.timeOutLocalStorageService.listenToTimeoutChanges();

    this.subscription = interval(1000)
      .pipe(
        map(() => {
          const currentTime = new Date().getTime();

          const expirationTime =
            this.timeOutLocalStorageService.getTimeoutValue()
              ? new Date(
                  JSON.parse(this.timeOutLocalStorageService.getTimeoutValue()),
                )
              : null;

          if (!expirationTime) {
            return false;
          }

          const timeLeft = expirationTime.getTime() - currentTime;
          this.timeLeftInSeconds = Math.floor(timeLeft / 1000);

          return timeLeft <= this.warningTimeInMinutes * 1000; // in milliseconds
        }),
      )
      .subscribe((showWarning) => {
        this.showWarning = showWarning;
        if (this.timeLeftInSeconds <= 0) {
          this.logOut();
        }
        if (showWarning) {
          this.title.setTitle('Session is expiring!');
          if (!this.chimeHasPlayed) {
            this.audiblePing.nativeElement.play();
            this.chimeHasPlayed = true;
          }
        } else {
          this.title.setTitle('Client Portal');
        }
      });
  }

  get minutesLeft() {
    return Math.floor(this.timeLeftInSeconds / 60);
  }
  get secondsLeft() {
    return (this.timeLeftInSeconds % 60).toString().padStart(2, '0');
  }

  stayLoggedIn() {
    const url = `${environment.portalURLBase}/refresh-session`;
    this.secureHttp.get(url, {}, true).subscribe();
    this.chimeHasPlayed = false;
  }

  logOut() {
    this.router.navigateByUrl('/logout');
  }
}
