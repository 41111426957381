import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import {
  ButtonCancelFormConfigOverride,
  ButtonConfigurationOverride,
  CheckboxCheckedOverride,
  CheckboxUncheckedOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import {
  FormConfig,
  FormConfigRequest,
} from '@app/client-config/client-form/client-power-ups-config/types/form-config';
import { FormConfigService } from '@app/client-config/client-form/client-power-ups-config/services/form-config-service';
import { useClientInfo } from '@app/client-config/client-form/client-power-ups-config/context/ClientInfoContext';
import { ConfigurationFooter } from '@app/client-config/client-form/client-power-ups-config/styles/ConfigurationModalStyles';
import { Button } from 'baseui/button';
import { ButtonIconRight } from '@app/react-baseweb/styled-containers/styledContainers';
import { Checkbox } from 'baseui/checkbox';
import { useStyletron } from 'baseui';

export const EditConfigurationModal = ({
  configuration,
  onConfigurationUpdate,
  onCancelClicked,
}: {
  configuration: FormConfig;
  onConfigurationUpdate: (
    editedConfiguration: FormConfigRequest,
    uid: string,
  ) => void;
  onCancelClicked: () => void;
}) => {
  const [{ clientId }] = useClientInfo();
  const [downloadPdfs, setDownloadPdfs] = useState(configuration.download_pdfs);

  const [css] = useStyletron();

  const cancel = () => {
    // callback to close the modal
    onCancelClicked();
  };

  const checkCloseSource = (e?) => {
    // if user clicks outside of modal (the backdrop), the modal will not close
    if (e?.closeSource && e?.closeSource === 'backdrop') return;
    // otherwise, close modal
    onCancelClicked();
  };

  const onSubmit = async () => {
    onConfigurationUpdate(
      {
        form_name: configuration.form_name,
        client_id: clientId,
        external_id: configuration.external_id,
        external_source: configuration.external_source,
        download_pdfs: downloadPdfs,
      },
      configuration.uid,
    );
  };

  return (
    <Modal
      isOpen
      onClose={(e) => checkCloseSource(e)}
      overrides={{
        Dialog: {
          style: {
            marginTop: '75px',
            width: '45%',
          },
        },
      }}
    >
      <ModalHeader style={ModalHeaderOverride.style}>
        Edit Power-Ups Form Configuration
      </ModalHeader>
      <ModalBody>
        <div>
          <span
            className={css({
              fontSize: '16px',
              color: '#5B5A59',
            })}
          >
            < br />
            <div>
              <b>Form Name: </b>
              {configuration.form_name}
            </div>
            <br />
            <div>
              <b>UUID: </b>
              {configuration.uid}
            </div>
            <br />
            <div>
              <b>Form ID: </b>
              {configuration.external_id}
            </div>
            <br />
          </span>
        </div>
        <div className={css({ marginTop: '20px' })}>
          <Checkbox
            checked={downloadPdfs}
            onChange={(e) => {
              setDownloadPdfs((e.target as HTMLInputElement).checked);
            }}
            overrides={{
              Checkmark: downloadPdfs
                ? CheckboxCheckedOverride
                : CheckboxUncheckedOverride,
            }}
          >
            <span
              className={css({
                fontSize: '16px',
                fontWeight: '700',
                color: '#5B5A59',
              })}
            >
              Enable PDF creation
            </span>
          </Checkbox>
        </div>
        <ConfigurationFooter>
          <div style={{ float: 'right' }}>
            <Button
              onClick={cancel}
              kind="tertiary"
              overrides={{ Root: ButtonCancelFormConfigOverride }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSubmit()}
              overrides={{ BaseButton: ButtonConfigurationOverride }}
            >
              <span style={{ marginRight: '8px'}}>SAVE</span>
              <ButtonIconRight>
                <i className="fas fa-save"></i>
              </ButtonIconRight>
            </Button>
          </div>
        </ConfigurationFooter>
      </ModalBody>
    </Modal>
  );
};
