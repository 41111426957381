import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/security/auth-guard.service';
import { ExperienceLibraryAdminComponent } from './components/experience-library-admin/experience-library-admin.component';
import { ExperienceLibraryComponent } from './components/experience-library/experience-library.component';
import { IndustryAddEditComponent } from './components/industry/industry-add-edit/industry-add-edit.component';
import { Permissions } from '../core/services/permission.service';
import { CompanyTypeAddEditComponent } from './components/company-type/company-type-add-edit/company-type-add-edit.component';
import { ExperienceTypeAddEditComponent } from './components/experience-type/experience-type-add-edit/experience-type-add-edit.component';
import { OutcomeAddEditComponent } from './components/outcome/outcome-add-edit/outcome-add-edit.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    component: ExperienceLibraryAdminComponent,
    data: {
      perm: Permissions.exp_lib_admin,
    },
  },
  {
    path: 'admin/add-industry',
    canActivate: [AuthGuardService],
    component: IndustryAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNewIndustry: true,
    },
  },
  {
    path: 'admin/edit-industry/:industry-id',
    canActivate: [AuthGuardService],
    component: IndustryAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNewIndustry: false,
    },
  },
  {
    path: 'admin/add-company-type',
    canActivate: [AuthGuardService],
    component: CompanyTypeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: true,
    },
  },
  {
    path: 'admin/edit-company-type/:company-type-id',
    canActivate: [AuthGuardService],
    component: CompanyTypeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: false,
    },
  },
  {
    path: 'admin/add-outcome',
    canActivate: [AuthGuardService],
    component: OutcomeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: true,
    },
  },
  {
    path: 'admin/edit-outcome/:outcome-id',
    canActivate: [AuthGuardService],
    component: OutcomeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: false,
    },
  },
  {
    path: 'admin/add-experience-type',
    canActivate: [AuthGuardService],
    component: ExperienceTypeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: true,
    }
  },
  {
    path: 'admin/edit-experience-type/:experience-type-id',
    canActivate: [AuthGuardService],
    component: ExperienceTypeAddEditComponent,
    data: {
      perm: Permissions.exp_lib_admin,
      isNew: false,
    },
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    component: ExperienceLibraryComponent,
    data: {
      perm: Permissions.exp_lib_experiences,
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExperienceLibraryRoutingModule {}
