import React from 'react';
import { Banner } from 'baseui/banner';
import { useStyletron } from 'baseui';

export type UserStatusBannerProps = {
  content?: string;
  secondaryContent?: string;
  variant?: 'primary' | 'secondary';
};

export const UserStatusBanner = ({
  content = '',
  secondaryContent = '',
  variant = 'primary',
}: UserStatusBannerProps) => {
  const [css] = useStyletron();
  const bgColor = variant === 'primary' ? '#E8F8B7' : 'rgba(55, 71, 79, 0.05)';
  const color = variant === 'primary' ? '#37474F' : '##6b6b6b';
  return (
    <Banner
      overrides={{
        Root: {
          style: () => ({
            outline: `${bgColor} solid`,
            backgroundColor: bgColor,
            color: color,
            textAlign: 'center',
          }),
        },
        MessageContent: {
          style: () => ({
            paddingBlockEnd: 'unset !important',
            paddingBlockStart: 'unset !important',
          }),
        },
        Message: {
          style: () => ({
            fontSize: '12px',
          }),
        },
      }}
    >
      {content}
      {secondaryContent && (
        <div
          className={css({
            fontSize: '12px',
            color: '#908E8F',
            textAlign: 'center',
          })}
        >
          {secondaryContent}
        </div>
      )}
    </Banner>
  );
};
