export class Background {
  
  // Manipulate the DOM to add or remove the "grids-on" class to 
  // the body tag.  Since the body tag falls outside of any angular 
  // component, I'm thinking DOM manipulation is OK for this.
  
  static gridsOn() {
    this.addBodyClass('grids-on');
  }
  
  static gridsOff() {
    this.removeBodyClass('grids-on');
  }
  
  static loginOn() {
    this.addBodyClass('login-bg-on');
  }
  
  static loginOff() {
    this.removeBodyClass('login-bg-on');
  }
  
  static previewOn() {
    this.addBodyClass('preview');
  }
  
  static previewOff() {
    this.removeBodyClass('preview');
  }
  
  private static addBodyClass(clazz: string) {
    const elements = document.getElementsByTagName('body');
    if (elements.length > 0) {
      const body = elements[0];
      if (!body.classList.contains(clazz)) {
        body.classList.add(clazz);
      }
    }
  }
  
  static removeBodyClass(clazz: string) {
    const elements = document.getElementsByTagName('body');
    if (elements.length > 0) {
      const body = elements[0];
      if (body.classList.contains(clazz)) {
        body.classList.remove(clazz);
      }
    }
  }
}
