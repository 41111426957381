/*
  This is meant to be a much more generic version of the 'UploadComponent'
*/

import { ProgressData } from '@app/file-launcher/services/file-upload.service'
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rn-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent {
  @Input() progressData: ProgressData;
  @Input() fileName: string;

  constructor() { }
}
