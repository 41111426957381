import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'launch-select',
  templateUrl: './launch-select.component.html',
  styleUrls: ['./launch-select.component.scss']
})
export class LaunchSelectComponent implements OnInit {

  @Input() launches: any[];
  @Output() filter: EventEmitter<any[]> = new EventEmitter();

  loading = true;
  error: string;
  
  // all the launches
  filterLaunches: { lb_name: string }[];

  // all the selected launches
  selectedLaunches: { lb_name: string }[] = [];

  // the one being added or removed to selectedLaunches
  selectedLaunch: { lb_name: string };

  constructor() { }

  ngOnInit() {
    this.filterLaunches = _.cloneDeep(this.launches);
  }

  addLaunch(): void {
    const launch = _.find(this.filterLaunches, (l) => l.lb_name === this.selectedLaunch);
    if (launch) {
      this.selectedLaunches.push(launch);
      this.filter.emit(this.selectedLaunches);
    }
    _.remove(this.filterLaunches, (l) => l.lb_name === this.selectedLaunch);
  }

  removeLaunch(email: string): void {
    _.remove(this.selectedLaunches, (l) => l.lb_name === email);
    this.filter.emit(this.selectedLaunches);
    this.filterLaunches.push(_.find(this.launches, (l) => l.lb_name === email));
  }
}
