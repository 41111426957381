import { AwsUtils } from '../utils/aws-utils';

export class MediaAsset {
  asset_type: string; // 'image' | 'video' | 'brand' | 'banner'
  tags: Array<string>;
  is_deleted: boolean;
  is_disabled: boolean;
  updated_at: Date;
  s3_url: string;
  url: string;
  asset_id: string;
  shortname: string;
  description: string;
  client_id: string;
  attributes: Object;
  created_at: Date;
  created_by: string;
  updated_by: string;
  file_size: string;
  alternate_text: string;
  videoThumbnailUrl: string;
  branding_color?: string;

  static deserialize(input: Object) {
    const asset = new MediaAsset();
    asset.asset_type = input['asset_type'];
    asset.tags = input['tags'];
    asset.is_deleted = input['is_deleted'];
    asset.is_disabled = input['is_disabled'];
    if (input['updated_at']) {
      asset.updated_at = new Date(input['updated_at']);
    }
    asset.s3_url = AwsUtils.addHostName(input['s3_url']);
    asset.url = input['url'];
    asset.asset_id = input['asset_id'];
    asset.shortname = input['shortname'];
    asset.description = input['description'];
    asset.client_id = input['client_id'];
    asset.attributes = input['attributes'];
    if (input['created_at']) {
      asset.created_at = new Date(input['created_at']);
    }
    asset.created_by = input['created_by_name'];
    asset.updated_by = input['updated_by_name'];
    asset.file_size = input['file_size'];
    asset.alternate_text = input['alternate_text'] || input['shortname'];
    if (input['branding_color']) {
      asset.branding_color = input['branding_color']
    }
    return asset;
  }

  serialize(forUpdate: boolean) {
    const obj = JSON.parse(JSON.stringify(this));

    obj['created_by'] = undefined;
    obj['updated_by'] = undefined;

    if (forUpdate) {
      obj['videoThumbnailUrl'] = undefined;
      obj['asset_id'] = undefined;
      obj['asset_type'] = undefined;
      obj['client_id'] = undefined;
      obj['s3_url'] = undefined;
      obj['created_at'] = undefined;
      obj['updated_at'] = undefined;
      obj['file_size'] = undefined;
      if (obj['url'] == null) {
        obj['url'] = undefined;
      }
    }
    return obj;
  }

  getFileName(): string {
    let fileName = null;
    if (this.s3_url) {
      const parts = this.s3_url.split('/');
      if (parts.length > 0) {
        fileName = parts[parts.length - 1];
      }
    }
    return fileName;
  }

  getFileExtension(): string {
    const fileName = this.getFileName();
    const parts = this.s3_url.split('.');
    let extension = '';
    if (parts.length > 0) {
      extension = parts[parts.length - 1].toLocaleUpperCase();
    }
    return extension;
  }

  getFileSizeKb(): number {
    return Math.round(+this.file_size / 1024);
  }

  getS3UrlCacheBuster() {
    return `${this.s3_url}?t=${this.updated_at.getTime()}`;
  }
}
