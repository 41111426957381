import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { Consent, Customer } from '@app/core/models/customer';
import { SecureHttp } from '@app/security/secure-http';
import { Ordering } from '@app/core/utils/ordering';
import { SessionService } from '@app/security/session.service';
import moment from 'moment-timezone';
import { FormatUtils } from '@app/core/utils/format-utils';
import { map } from 'rxjs/operators';
import { ConsentEnum } from '../models/consent-type';
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs"; // ref: https://github.com/aspnetboilerplate/abp-ng2-module/pull/66

@Injectable()
export class CustomerService {

  constructor(private http: SecureHttp, private sessionService: SessionService) { }

  getConsentByCCID(ccid: string): Observable<Consent[]> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.messagingURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/consents`;
    return this.http.get(url, {}, true).pipe(
      map(response => {
        return response['consents'].map(Consent.deserialize);
      })
    );
  }

  updateConsent(consent: Consent): Observable<any> {
    if (consent.channel_type === 'sms') {
      consent.channel_addr = FormatUtils.sanitizePhone(consent.channel_addr);
    }
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.messagingURLBase}/client/${clientId}/consent/channel_addr/${consent.channel_addr}/channel_type/${consent.channel_type}`;
    const results = this.http.put(url, {consent_type: consent.consent_type, source_channel: consent.source_channel});
    return results;
  }

  locateCustomer(ccid: string, validation: Object): Observable<Customer> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/csr/locate`;
    let query = '?';
    let first = true;
    for (const prop in validation) {
      if (validation.hasOwnProperty(prop) && prop !== 'phone_number') {
        if (validation['date_of_birth']) {
          validation['date_of_birth'] = moment(validation['date_of_birth']).format('YYYY-MM-DD');
        }
        if (!first) {
          query += '&';
        }
        query += `${prop}=${validation[prop]}`;
        first = false;
      }
    }

    const result = this.http.get(url + query, null, true).pipe(
      map(response => {
        let customer = response['customer'];
        if (customer == null) {
          customer = response['customers'][0];
        }
        return Customer.deserialize(customer);
      })
    );

    return result;
  }

  registerCSRDecline(ccid: string, validationModel: any): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/csr/consent_decline`;

    return this.http.post(url, {validationModel}, false);
  }

  getCustomerBySearch(customerSearchInputs: {
    limit: number,
    offset: number,
    ordering: Ordering,
    searchCriteria: SearchCriteria,
    hideMask: boolean,
  }): Observable<Customer[]> {
    const clientId = this.sessionService.currentUser.client.id;

    let result: any;

    if (customerSearchInputs.searchCriteria) {
      let url = `${environment.searchURLBase}/client/${clientId}/customers?limit=${customerSearchInputs.limit}&offset=${customerSearchInputs.offset}`;

      if (
        customerSearchInputs.searchCriteria.searchFields.find(
          (c) => c === 'notification_channels.phone_number'
        )
      ) {
        customerSearchInputs.searchCriteria.searchFields.push(
          'telephones.phone_number'
        );
      }

      if (customerSearchInputs.ordering) {
        url = `${url}&${customerSearchInputs.ordering.getQueryStringUnderscorish()}`;
      }

      result = this.http.post(url, customerSearchInputs.searchCriteria.getSearchRequest(), null, customerSearchInputs.hideMask);
    }

    return result.pipe(
      map(response => {
        return response['customers'].map(Customer.deserialize);
      })
    );
  }

  getCustomerDetail(ccid: string): Observable<{ 'customer': Customer, 'customer_details': any }> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.searchURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/customer_details`;

    return this.http.get(url).pipe(
      map((response: Response) => {
        const customer = Customer.deserialize(response['customer']['customer']);
        const customerDetail = response['customer_details']['customer_details']['data'][0];
        return {'customer': customer, 'customer_details': customerDetail};
      })
    );
  }

  getCustomerMessaging(ccid: string, limit: number, offset: number): Observable<any[] | any> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.searchURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/message_details`;

    return this.http.get(url, {}, true).pipe(
      map((response: any) => {
        try {
          return [response.messages, response.notifications];
        } catch (e) {
          return throwError(e);
        }
      })
    );
  }

  addPhoneNumber(ccid: string, deviceAddress: string, consentType: ConsentEnum): Observable<{message: string}> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/add_phone/${deviceAddress}`;

    return this.http.put(url, { consent_type: consentType }, undefined, true);
  }

  removePhoneNumber(ccid: string, deviceAddress: string): Observable<{message: string}> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/remove_phone/${deviceAddress}`;

    return this.http.put(url, {}, undefined, true);
  }
}
