import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NotificationAdmin } from './NotificationAdmin';

@Component({
  selector: 'notification-admin',
  templateUrl: './notification-admin.component.html',
})
export class NotificationAdminComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  messageDialog: MessageDialogComponent;
  currentClient: Client;
  client: Client;
  clientId: string;
  reactWrapperId: string;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
  ) {
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.clientService.getClient(this.clientId).subscribe({
      next: (client) => {
        this.client = client;
      },
      error: () => {
        this.client = new Client();
      },
    });

    this.reactWrapperId = 'notification-admin-react-wrapper-id';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges() {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(NotificationAdmin, {
          clientId: this.clientId,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }
}
