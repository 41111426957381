import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';
import { Client, ProductGroup, PushSFTP, SMTPCredentials } from '../models/client';
import { Ordering } from '../utils/ordering';
import { SessionService } from '@app/security/session.service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { BlackoutUtils } from '@app/core/utils/blackout-utils';
import { BlackoutHour } from '@app/core/models/blackout-hour';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { SmsCustomKeyword } from '@app/core/models/sms-custom-keyword';

import customKeywordMasterList from './sms-custom-keyword-master-list.json';
import { DataMappingUtils } from '@app/core/utils/data-mapping-utils';

// Type for Client Service mocks
export interface ClientServiceT {
  cleanseError?(err: any): string | undefined,
  getBlackoutHours?(): BlackoutHour[],
  getDisplayHour?(isoHour: string): string,
  getNextBlackoutHour?(isoHour: string): string,
  getPreviousBlackoutHour?(isoHour: string): string,
  getValidationFields?(hideMask: boolean): Observable<any>,
  getCampaignsByClient?(clientId: string, suppressMask?: boolean): Observable<any>,
  getAllClients?(ordering?: Ordering): Observable<Client[]>,
  getClients?(limit: number, offset: number, ordering?: Ordering, hideMask?: boolean): Observable<Client[]>,
  getClient?(clientId: string, hideMask): Observable<Client>,
  createClient?(client: Client): Observable<any>,
  updateClient?(client: Client): Observable<any>,
  updateClientPartial?(clientId: string, key: string, config: any): Observable<any>,
  getProductGroupsByClient?(clientId: string, limit: number, offset: number, ordering?: Ordering, supressMask?: boolean): Observable<ProductGroup[]>,
  getProductGroup?(clientId: string, productGroupId: string, hidemask: boolean),
  getProductGroupForCurrentUser?(productGroupId: string),
  createProductGroup?(clientId: string, productGroup: ProductGroup),
  updateProductGroup?(clientId: string, productGroup: ProductGroup, supressMask?: boolean),
  getPrivateConfigSMTP?(clientId: string): Observable<SMTPCredentials>,
  createPrivateConfigSMTP?(clientId: string, credentials: object): Observable<SMTPCredentials>,
  updatePrivateConfigSMTP?(clientId: string, credentials: object): Observable<SMTPCredentials>,
  deletePrivateConfigSMTP?(clientId: string): Observable<any>,
  updateAutoFileProcessing?(clientId: string, automatedFileProcessing: boolean ): Observable<any>,
  getSFTPPush?(clientId: string): Observable<PushSFTP>,
  updateSFTPPush?(clientId: string, config: PushSFTP): Observable<PushSFTP>,
}

@Injectable()
export class ClientService {

  public selectJourneyColors = ['#00BCD4', '#FFC107', '#88C34A', '#E040FB', '#009688'];

  /**
   * Builds the master list of keywords from the local JSON file.
   */
  masterCustomSmsKeywordList: SmsCustomKeyword[] = DataMappingUtils.entriesPolyFill(customKeywordMasterList.custom_sms_keywords).map(value => {
    return SmsCustomKeyword.deserialize(value[0], value[1]);
  });

  constructor(private http: SecureHttp,
              private sessionService: SessionService,
              private rawHttp: HttpClient) {}

  cleanseError(err: any): string | undefined {
    let serverMessage;
    if (err.reason && typeof err.reason === 'string') {
      serverMessage = ': ' + err.reason;
    } else if (err.message && typeof err.message === 'string') {
      serverMessage = ': ' + err.message;
    } else if (err.error.message && typeof err.error.message === 'string') {
      serverMessage = ': ' + err.error.message;
    }  else if (err.error.reason && typeof err.error.reason === 'string') {
      serverMessage = ': ' + err.error.reason;
    } else if (err.error && err.error.error && err.error.error.message) {
      serverMessage = ': ' + err.error.error.message;
    } else if (err.error && err.error.error && err.error.error.reason) {
      serverMessage = ': ' + err.error.error.reason;
    }
    return serverMessage;
  }

  getBlackoutHours(): BlackoutHour[] {
    return BlackoutUtils.blackoutHours;
  }

  getDisplayHour(isoHour: string): string {
    return BlackoutUtils.getDisplayHour(isoHour);
  }

  getNextBlackoutHour(isoHour: string): string {
    return BlackoutUtils.getNextBlackoutHour(isoHour);
  }

  getPreviousBlackoutHour(isoHour: string): string {
    return BlackoutUtils.getPreviousBlackoutHour(isoHour);
  }

  getValidationFields(hideMask: boolean = true): Observable<any> {
    const url = `${environment.cdmURLBase}/validation_fields`;

    const result = this.http.get(url, null, hideMask).pipe(
      map(response => response['validation_fields'])
    );

    return result;
  }

  getCampaignsByClient(clientId: string, suppressMask?: boolean): Observable<any> {
    const url = `${environment.cdmURLBase}/client/${clientId}/campaigns`;

    return this.http.get(url, null, suppressMask).pipe(
      map(response => response['campaigns'])
    );
  }

  getAllClients(ordering?: Ordering): Observable<Client[]> {
    if (this.sessionService.allClients.length) {
      return of(this.sessionService.allClients);
    } else {
      const result = new Observable<Array<Client>>(subscriber => {
        const limit = 100;
        let offset = 0;
        const allClients = new Array<Client>();
        const processNextClientBatch = (clients: Array<Client>) => {
          if (clients.length > 0) {
            clients.forEach(asset => {
              allClients.push(asset);
            });

            offset += limit;

            this.getClients(limit, offset, ordering, true).subscribe(
              b => processNextClientBatch(b),
              error => subscriber.error(error)
            );
          } else {
            this.sessionService.allClients = allClients;
            subscriber.next(allClients);
            subscriber.complete();
          }
        };

        this.getClients(limit, offset, ordering, true).subscribe(
          clients => processNextClientBatch(clients),
          error => subscriber.error(error)
        );
      });
      return result;
    }
  }

  getClients(limit: number, offset: number, ordering?: Ordering, hideMask?: boolean): Observable<Client[]> {
    let url = `${environment.configURLBase}/clients?limit=${limit}&offset=${offset}`;

    if (ordering) {
      url = `${url}&${ordering.getQueryString()}`;
    }

    return this.http.get(url, null, hideMask).pipe(
      map((response) => {
        return response['clients'].map(Client.deserialize);
      })
    );
  }

  getClient(clientId: string, hideMask = false): Observable<Client> {
    const url = `${environment.configURLBase}/client/${clientId}`;

    const result = this.http.get(url, {}, hideMask).pipe(
      map((response) => {
        const client = Client.deserialize(response['client']);
        if (client.id === this.sessionService.currentUser.client.id) {
          this.sessionService.currentUser.client = client;
        }
        return client;
      })
    );

    return result;
  }

  createClient(client: Client): Observable<any> {
    const url = `${environment.configURLBase}/clients`;
    // We can't send sms_keywords
    // this will break the default response templates we get at creation
    delete client.sms_keywords;
    return this.http.post(url, client.serialize(true));
  }

  updateClient(client: Client): Observable<any> {
    const url = `${environment.configURLBase}/client/${client.id}`;
    return this.http.put(url, client.serialize(false));
  }

  updateClientPartial(clientId: string, key: string, config: any): Observable<any> {
    const url = `${environment.configURLBase}/client/${clientId}/config/${key}`;
    return this.http.put(url, {data: config});
  }

  getProductGroupsByClient(clientId: string, limit: number, offset: number, ordering?: Ordering, supressMask?: boolean): Observable<ProductGroup[]> {
    let url = `${environment.configURLBase}/client/${clientId}/product_groups_collection?limit=${limit}&offset=${offset}`;

    if (ordering) {
      url = `${url}&${ordering.getQueryString()}`;
    }

    return this.http.get(url, null, supressMask).pipe(
      map(response => {
        return response['product_groups'].map(obj => ProductGroup.deserialize(obj));
      })
    );
  }

  getProductGroup(clientId: string, productGroupId: string, hidemask: boolean = true) {
    const url = `${environment.configURLBase}/client/${clientId}/product_group/${productGroupId}`;

    return this.http.get(url, null, hidemask).pipe(
      map(response => ProductGroup.deserialize(response['product_group']))
    );
  }

  getProductGroupForCurrentUser(productGroupId: string) {
    const url = `${environment.configURLBase}/client/${this.sessionService.getCurrentUsersClient().id}/product_group/${productGroupId}`;

    return this.http.get(url).pipe(
      map(response => ProductGroup.deserialize(response['product_group']))
    );
  }

  createProductGroup(clientId: string, productGroup: ProductGroup) {
    const url = `${environment.configURLBase}/client/${clientId}/product_groups`;
    return this.http.post(url, productGroup.serialize(true));
  }

  updateProductGroup(clientId: string, productGroup: ProductGroup, supressMask?: boolean) {
    const url = `${environment.configURLBase}/client/${clientId}/product_group/${productGroup.id}`;
    return this.http.put(url, productGroup.serialize(false), null, supressMask);
  }

  getPrivateConfigSMTP(clientId: string): Observable<SMTPCredentials> {
    const url = `${environment.configURLBase}/client/${clientId}/private/smtp/credentials`;
    return this.http.get(url).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of({credentials: {}});
        } else {
          return throwError(err);
        }
      }),
      map((res: any) => res.credentials)
    );
  }

  createPrivateConfigSMTP(clientId: string, credentials: object): Observable<SMTPCredentials> {
    const url = `${environment.configURLBase}/client/${clientId}/private/smtp/credentials`;
    return this.http.post(url, {credentials}).pipe(map(res => res.credentials));
  }

  updatePrivateConfigSMTP(clientId: string, credentials: object): Observable<SMTPCredentials> {
    const url = `${environment.configURLBase}/client/${clientId}/private/smtp/credentials`;
    return this.http.put(url, {credentials}).pipe(map(res => res.credentials));
  }

  deletePrivateConfigSMTP(clientId: string): Observable<any> {
    const url = `${environment.configURLBase}/client/${clientId}/private/smtp/credentials`;
    return this.http.delete(url).pipe(map(res => res.credentials));
  }

  updateAutoFileProcessing(clientId: string, automatedFileProcessing: boolean ): Observable<any> {
    const url = `${environment.configURLBase}/client/${clientId}/auto-file-processing`;
    return this.http.put(url, {"automated-file-processing" : automatedFileProcessing}, null, false);
  }

  getSFTPPush(clientId: string): Observable<PushSFTP> {
    const url = `${environment.configURLBase}/client/${clientId}/sftp`;
    return this.http.get(url);
  }

  updateSFTPPush(clientId: string, config: PushSFTP): Observable<PushSFTP> {
    Object.keys(config).forEach((k) => {
      if (config[k] === "") {
        config[k] = null;
      }
    });
    const url = `${environment.configURLBase}/client/${clientId}/sftp`;
    return this.http.put(url, config);
  }

  getHelpSite(clientId: string, filename: string): Observable<any> {
    const url = `${environment.helpsitesURLBase}/client/${clientId}/file/${filename} `;
    return this.http.get(url);
  }

  getHelpSites(clientId: string): Observable<any> {
    const url = `${environment.helpsitesURLBase}/client/${clientId} `;
    return this.http.get(url);
  }

  uploadHelpSites(clientId: string, file: File, filename: string): any {
    const url = `${environment.helpsitesURLBase}/client/${clientId}`;
    return this.http.post(url, {filename}, {}, true).pipe(
      take(1),
      switchMap((res: {s3_url: string}) => {
        return this.uploadToS3(res.s3_url, file).pipe(
          filter((event) => event instanceof HttpResponse)
        )
      })
    )
  }

  private uploadToS3(s3Url: string, file: File): Observable<object> {
    const req = new HttpRequest('PUT', s3Url, file, {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'text/html'
      })
    });
    return this.rawHttp.request(req)
  }

  /**
   * Gets the short code/sms aggregator for the client id.
   * @param clientId
   */
  getClientShortCode(clientId: string): Observable<any> {
    const url = `${environment.configURLBase}/client/${clientId}/sms_aggregator`;
    return this.http.get(url);
  }

  /**
   * Updates the short code/sms aggregator for the client id.
   * @param clientId
   * @param shortCode
   */
  updateClientShortCode(clientId: string, shortCode: string): Observable<boolean> {
    const url = `${environment.configURLBase}/client/${clientId}/shortcode/${shortCode}`;
    return this.http.put(url, {shortcode: `${shortCode}`}).pipe(map(result => {
      return result.message.includes('update successful');
    }));
  }

  /**
   * Retrieves the shortcodes associated with the given clientId/shortcode combination.
   * @param clientId
   * @param shortCode
   */
  getCustomSMSKeywords(clientId: string, shortCode: string): Observable<any> {
    const url = `${environment.configURLBase}/client/${clientId}/sms/shortcodes/${shortCode}/custom_sms_keywords`;
    return this.rawHttp.get(url);
  }

  /**
   * Pulls the master keyword list.
   */
  getMasterCustomSMSKeywords(): Observable<SmsCustomKeyword[]> {
    return of(this.masterCustomSmsKeywordList);
  }

  updateCustomSMSKeywords(clientId: string, shortCode: string, keywords: SmsCustomKeyword[]) {
    const url = `${environment.configURLBase}/client/${clientId}/sms/shortcodes/${shortCode}/custom_sms_keywords`;
    const bodyContents = {custom_sms_keywords: {}};

    // translates from an array back to the object format the service wants
    keywords.forEach(keyword => {
      bodyContents.custom_sms_keywords[keyword.keyword] = { locale: keyword.locale, conversation: keyword.conversation };
    });

    return this.http.put(url, bodyContents);
  }

  getGPGDetails(clientId: string, fileContents: string) {
    const url = `${environment.adminUrlBase}/client/${clientId}/gpg/gpg-details`;
    return this.http.post(url, { 'gpg_key': fileContents });
  }

  deleteGPGKey(clientId: string) {
    const url = `${environment.configURLBase}/client/${clientId}/gpg-key`;
    return this.http.delete(url);
  }
}
