
<div class="messageBox with-box-menu clearfix" [class.selected]="selected" [class.invalid]="msg.isNew && !isValid">

  <box-menu [menuItems]="boxMenuItems" [selected]="selected" [invalid]="msg.isNew && !isValid"></box-menu>

  <span *ngIf="msg.isNew" class="invalid-indicator"><i class="fa fa-exclamation"></i></span>

  <div class="messageTop with-box-menu">
    <div class="messageNumber">{{msg.step}}</div>
    <span class="title fixed-width">{{msg.nickname}}</span>
  </div>

  <div *ngIf="msg.isNew; else MessageWithContent" class="new-message">
    <p>Which would you like to do?</p>
    <div class="btn decisionBtn create-message" (click)="editMessage()">Create a new message</div>
    <div class="btn decisionBtn copy-message" (click)="copyMessage()">Copy an existing message</div>
  </div>
  <ng-template #MessageWithContent >

    <p *ngIf="msg.wire.is_shown !== 'true'; else MessageWithWireContent" class="message-text">
      {{this.smsTextPreview}}
    </p>

    <ng-template #MessageWithWireContent>
    <div class="media">
      <img *ngIf="msg.wire['media_type'] === 'image' && msg.wire['image_url']" [src]="msg.wire['image_url']" />
      <app-video *ngIf="msg.wire['media_type'] === 'video' && msg.wire['video_url']" [videoUrl]="msg.wire['video_url']"></app-video>
    </div>
    <p class="message-text" [innerHtml]="msg.wire.text | safeHtml"></p>
    <ng-container *ngIf="!newFeed.enabled">
      <ng-container *ngFor="let action of msg.wire['actions']">
        <app-step-action [actionLabel]="action.label" [actionType]="action.type" [actionId]="action.type === 'message' ? action.value : action.id" [showSVG]="true" [isInvalidMessage]="action.type === 'message' && getChildValidity(action.value) && !isValid"></app-step-action>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="newFeed.enabled">
      <ng-container *ngFor="let action of msg.wire['actions']; let i = index;">
        <ng-container *ngIf="i === 0 && !!action.primary">
          <app-step-action isPrimary="true" [actionLabel]="action.label" [actionType]="action.type" [actionId]="action.type === 'message' ? action.value : action.id" [showSVG]="true" [isInvalidMessage]="action.type === 'message' && getChildValidity(action.value) && !isValid"></app-step-action>
        </ng-container>
  
        <ng-container *ngIf="i !== 0 || !action.primary">
          <app-step-action [actionLabel]="action.label" [actionType]="action.type" [actionId]="action.type === 'message' ? action.value : action.id" [showSVG]="true" [isInvalidMessage]="action.type === 'message' && getChildValidity(action.value) && !isValid"></app-step-action>
        </ng-container>
      </ng-container>
    </ng-container>

    <div #responses class="response-messages" id="response-messages-{{msg.name}}">

      <div *ngFor="let responseMsg of getResponseMessages(msg); let i = index">
        <!--<svg width="20" height="90" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <line id="" x1="20" y1="0"
                stroke-width="2" />
        </svg>-->
          <!--<div class="line-connector" [ngStyle]="getlineHeight(responseMsg.name,msg.name)"></div>-->
          <journey-message #responseMessage [(isValid)]="isValid" id="response-msg-{{responseMsg.name}}" [msg]="responseMsg" [journeyId]="journeyId" [journey]="journey" (click)="onResponseMessageClick($event, responseMessage)" class="response-message-{{i}}" (responseMessageSelected)="selectComponent($event)" (deleteMsg)="onDeleteMsg($event)"></journey-message>

      </div>
    </div>
    </ng-template>
  </ng-template>
</div>

