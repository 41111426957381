import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Background } from '../core/utils/background';
import { TitleService } from '../core/services/title.service';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { FeatureService } from '@app/core/services/feature.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-cx-builder',
  templateUrl: './cx-builder.component.html',
  styleUrls: ['./cx-builder.component.scss']
})

export class CxBuilderComponent implements OnInit, OnDestroy {
  @Input() selectionType: string = null;
  @Input() copyType: string = null;
  @Input() includeSmsOnlyMessages: boolean = true;

  csrFilter: boolean;
  limit: number;
  featureFlags = FeatureFlags;
  experienceLibFeatureFlag: boolean = false;
  client: Client;

  constructor(public sessionService: SessionService,
              private titleService: TitleService,
              private permissionService: PermissionService,
              private featureService: FeatureService,
              public location: Location) {
  }

  get showUseRelayExperienceOption(): boolean {
    return this.experienceLibFeatureFlag && this.client['industry'] !== '' && this.client['company_type'] !== '';
  }

  ngOnInit() {
    this.csrFilter = this.permissionService.checkPermissions(Permissions.ui_journey_launcher_msg_only)
      && !this.permissionService.checkPermissions(Permissions.ui_journey_list);
    this.csrFilter ? this.limit = 100 : this.limit = 20;

    this.experienceLibFeatureFlag = this.featureService.checkFlag(this.featureFlags.experience_library);

    this.client = this.sessionService.currentUser.client;

    this.titleService.activate('Experience Builder');
    Background.gridsOn();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    Background.gridsOff();
  }

  getListTitle() {
    if (this.copyType === 'message') {
      return 'Select a Message to Copy';
    } else if (this.copyType === 'journey') {
      return 'Select an Experience to Copy';
    } else if (this.copyType === 'template') {
      return 'Use a Relay Experience';
    } else if (this.copyType === 'import') {
      return 'Import a Relay Experience';
    }
  }

  isTemplateCopy() {
    return !!(this.copyType === 'template' && this.selectionType);
  }

  isImport() {
    return !!(this.copyType === 'import' && this.selectionType);
  }

  showImportExperienceOption() {
    return !!(this.permissionService.checkPermissions(Permissions.exp_lib_experience_import));
  }
}
