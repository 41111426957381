<div class="container-fluid" *ngIf="isError">
  <h1>Error:</h1>
  <h3>{{error}}</h3>
</div>

<div class="container-fluid list" *ngIf="customer">

  <h1>Customer</h1>

  <table class="customer">
    <tr>
      <td>Client ID</td>
      <td>{{customer.client_id}}</td>
    </tr>
    <tr>
      <td>Product Group</td>
      <td>{{customer.product_group_id}}</td>
    </tr>
    <tr *ngIf="customerDetails && customerDetails.account_detail && customerDetails.account_detail.product_group_name" >
      <td>Product Group {{newFeed.enabled ? 'Feed' : 'Wire'}} Display Name</td>
      <td>{{customerDetails.account_detail.product_group_name}}</td>
    </tr>
    <tr *ngIf="customerDetails && customerDetails.account_detail && customerDetails.account_detail.product_group_internal_name" >
      <td>Product Group Internal Name</td>
      <td>{{customerDetails.account_detail.product_group_internal_name}}</td>
    </tr>
    <tr>
      <td>Customer ID</td>
      <td>{{customer.id}}</td>
    </tr>
    <tr>
      <td>Customer CCID</td>
      <td>{{customer.ccid}}</td>
    </tr>
    <tr>
      <td>First Name</td>
      <td>{{customer.first_name}}</td>
    </tr>
    <tr>
      <td>Last Name</td>
      <td>{{customer.last_name}}</td>
    </tr>
  </table>

  <table class="customer">
    <tr>
      <td>DOB</td>
      <td>{{customer.date_of_birth}}</td>
    </tr>
    <tr>
      <td>SSN Last 4</td>
      <td>{{customer.ssn_last4}}</td>
    </tr>
    <tr>
      <td>First Postal Code</td>
      <td>{{customer.addresses[0]?.postal_code}}</td>
    </tr>
  </table>

  <table class="customer">
    <tr>
      <td>Created At</td>
      <td>{{customer.created_at}}</td>
    </tr>
    <tr>
      <td>Created By</td>
      <td>NOT RETURNED</td>
    </tr>
    <tr>
      <td>Created By Name</td>
      <td>NOT RETURNED</td>
    </tr>
    <tr>
      <td>Updated At</td>
      <td>{{customer.updated_at}}</td>
    </tr>
    <tr>
      <td>Updated By</td>
      <td>NOT RETURNED</td>
    </tr>
    <tr>
      <td>Updated By Name</td>
      <td>NOT RETURNED</td>
    </tr>
  </table>

  <div *ngIf="!customerDetails">
    <h1>Customer Details</h1>
    <h2>No customer details available</h2>
  </div>

  <div *ngIf="customerDetails">
    <h1>Customer Details</h1>

    <h2>Deactivated</h2>
    <div class="row colhdrs">
      <div class="colw colw-20">
        <span>Deactivation Status</span>
      </div>
    </div>
    <div class="row">
      <div class="colw colw-20">
        <span>{{customerDetails?.account_detail?.disabled | json}}</span>
      </div>
    </div>

    <h2>Account History</h2>
    <div class="row colhdrs">
      <div class="colw colw-20">
        <span>Client Source</span>
      </div>
      <div class="colw colw-20">
        <span>Portal User ID</span>
      </div>
      <div class="colw colw-20">
        <span>Source</span>
      </div>
      <div class="colw colw-20">
        <span>Updated At</span>
      </div>
    </div>

    <div class="row" *ngFor="let accountHistory of customerDetails.account_activity.history">
      <div class="colw colw-20">
        <span>{{accountHistory.client_source}}</span>
      </div>
      <div class="colw colw-20">
        <span>{{accountHistory.portal_user_id}}</span>
      </div>
      <div class="colw colw-20">
        <span>{{accountHistory.source}}</span>
      </div>
      <div class="colw colw-20">
        <span>{{accountHistory.updated_at | date:'short'}}</span>
      </div>
    </div>

    <ng-container *ngFor="let consent of customerDetails?.notification_channel_detail">
      <h2>Consent History - {{consent.channel_addr}}</h2>
      <div class="row colhdrs">
        <div class="colw colw-20">
          <span>Consent Type</span>
        </div>
        <div class="colw colw-20">
          <span>Updated At</span>
        </div>
        <div class="colw colw-20">
          <span>Source</span>
        </div>
        <div class="colw colw-20">
          <span>Client Source</span>
        </div>
      </div>

      <div class="row" *ngFor="let history of consent.history">
        <div class="colw colw-20">
          <span>{{history.type}}</span>
        </div>
        <div class="colw colw-20">
          <span>{{history.updated_at | date:'short'}}</span>
        </div>
        <div class="colw colw-20">
          <span>{{history.source}}</span>
        </div>
        <div class="colw colw-20">
          <span>{{history.client_source}}</span>
        </div>
      </div>
    </ng-container>

    <h2>Notification Channel</h2>
    <div class="row colhdrs">
      <div class="colw colw-15">
        <span>Channel Address</span>
      </div>
      <div class="colw colw-15">
        <span>Channel Type</span>
      </div>
      <div class="colw colw-15">
        <span>Consent Type</span>
      </div>
      <div class="colw colw-15">
        <span>Updated At</span>
      </div>
      <div class="colw colw-15">
        <span>Removed</span>
      </div>
    </div>

    <div class="row" *ngFor="let notification of customerDetails?.notification_channel_detail">
      <div class="colw colw-15">
        <span>{{notification.channel_addr}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.channel_type}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.consent_latest.type}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.consent_latest.updated_at | date: 'shortDate'}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification?.removed_from_account }}</span>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid list" *ngIf="!!customerMessages">
  <h1>Wire Messages</h1>

  <div class="row colhdrs">
    <div class="colw colw-15">
      <span>Date/Time (attempted)</span>
    </div>
    <div class="colw colw-15">
      <span>Message ID</span>
    </div>
    <div class="colw colw-15">
      <span>Experience ID</span>
    </div>
    <div class="colw colw-10">
      <span>Experience Name</span>
    </div>
    <div class="colw colw-10">
      <span>Experience Campaign</span>
    </div>
    <div class="colw colw-10">
      <span>Wire Message Text</span>
    </div>
  </div>

  <div *ngFor="let message of customerMessages; let i = index"
       [ngClass]="{'message-selected' : messageDetails.indexOf(message.message_id) > -1}">
    <div class="row message" (click)="onMessageRowClick(message.message_id)">
      <div class="colw colw-15">
        <span>{{message.wire_msg_attempted_ts | date:'short'}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{message.message_id}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{message.journey_id}}</span>
      </div>
      <div class="colw colw-10">
        <span>{{message.journey_name}}</span>
      </div>
      <div class="colw colw-10">
        <span>{{message.journey_campaign}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{message.wire_text}}</span>
      </div>
    </div>

    <div class="row" *ngIf="messageDetails.indexOf(message.message_id) > -1">
      <div class="row">
        <h3>Raw Message</h3>
        <div class="message-raw">
          {{message | json}}
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container-fluid list" *ngIf="!!customerNotifications">
  <h1>Channel Notifications</h1>

  <div class="row colhdrs">
    <div class="colw colw-15">
      <span>Date/Time</span>
    </div>
    <div class="colw colw-15">
      <span>Channel</span>
    </div>
    <div class="colw colw-15">
      <span>Message ID</span>
    </div>
    <div class="colw colw-15">
      <span>Experience ID</span>
    </div>
    <div class="colw colw-15">
      <span>Experience Name</span>
    </div>
    <div class="colw colw-15">
      <span>Experience Campaign</span>
    </div>
  </div>

  <div *ngFor="let notification of customerNotifications; let i = index"
       [ngClass]="{'message-selected' : customerNotifications.indexOf(notification.message_id) > -1}">
    <div class="row message" (click)="onNotificationRowClick(notification.message_id)">
      <div class="colw colw-15">
        <span>{{notification.notification_sms_attempted_ts | date:'short'}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.notification_sms_attempted?.payload.channel_addr}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.message_id}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.journey_id}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.journey_name}}</span>
      </div>
      <div class="colw colw-15">
        <span>{{notification.journey_campaign}}</span>
      </div>
    </div>

    <div class="row" *ngIf="notificationDetails.indexOf(notification.message_id) > -1">
      <h3>Raw Message</h3>
      <div class="message-raw">
        {{notification | json}}
      </div>
    </div>
  </div>
</div>
