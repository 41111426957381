
<div id="login-page-content">
  <div>
    <img id="relay-logo" src="/assets/relay-logo-full.png">
  </div>
  <div id="login-form-and-help">
    <div id="login-form">
      <p *ngIf="submitted && !error" id="instructions" i18n="Password prompt on login page"><b>Email sent</b></p>
      <p *ngIf="submitted && !error">We have sent an email to the address "{{loginFormGroup.controls['username'].value}}" with instructions on resetting your password</p>
      <p *ngIf="!submitted">Please enter your email address and we'll send you a link to help you reset your password</p>
      <p *ngIf="!!error" id="login-failure">
        <i class="fa fa-exclamation-circle"></i>
        <span id="login-failure-msg" i18n="Login failure message">There was an error resetting your password.</span>
      </p>
      <div *ngIf="!submitted" class="input-with-icon"
           [ngClass]="{'has-error':!loginFormGroup.controls['username'].valid && loginFormGroup.controls['username'].touched}">
        <input id="user-name-input"
               class="form-control"
               placeholder="Email Address"
               i18n-placeholder="Placeholder text for username field on login page"
               [formControl]="loginFormGroup.controls['username']" (keypress)="checkForEnter($event)">
        <i class="fa fa-user"></i>
      </div>
      <div class="login-spinner" *ngIf="requestInFlight">
        <app-spinner></app-spinner>
      </div>
      <button *ngIf="!submitted && !requestInFlight" class="submit-login btn" (click)="submitForgotLogin()">
        <div class="submit-label" i18n="Label for submit login information button">Submit</div>
        <span class="icon icon-right">
          <i class="fa fa-arrow-right"></i>
        </span>
      </button>
      <br>
      <button *ngIf="submitted && !error && !requestInFlight" class="submit-login btn" routerLink="/login">
        <div class="submit-label" i18n="Label for submit login information button">Ok</div>
      </button>
      <button *ngIf="submitted && error && !requestInFlight" class="submit-login btn" (click)="reset()">
        <div class="submit-label" i18n="Label for submit login information button">Ok</div>
      </button>
      <p class="generic-link" routerLink="/login">Cancel</p>
    </div>
  </div>
  <div id="welcome-text" i18n="Welcome text for login prompt">
    Welcome to CX builder<span>&trade;</span>
  </div>
</div>
