import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';

@Component({
  selector: 'rn-form-input-state-control',
  templateUrl: './form-input-state-control.component.html',
  styleUrls: ['./form-input-state-control.component.scss'],
})
export class FormInputStateControlComponent implements OnInit {
  @Input() formReference: UntypedFormControl;
  @Input() isError?: boolean;
  @Input() showCharacterCount?: boolean;
  @Input() totalLength?: number;
  constructor(private actionService: ActionService) {}

  ngOnInit(): void {}

  getTooltipData(errors: Object): string | void {
    return this.actionService.getTooltipData(errors);
  }

  get remainingLength() {
    return this.totalLength! - (!!this.formReference.value ? this.formReference.value.length : 0);
  }
}
