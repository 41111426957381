import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import {
  ControlTagsService,
  ControlTag,
} from '@app/core/services/control-tags.service';
import { LoggerService } from '@app/core/services/logger.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { reduce, switchMap, takeUntil } from 'rxjs/operators';
import { TwoWayConversationMessageEvents } from '../twilio-conversation.types';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import { Message } from '@twilio/conversations';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit, OnDestroy {
  @ViewChild('audioControl', { static: true }) audiblePing: ElementRef;
  error: string;
  loading = true;
  tags: ControlTag[];
  launches: any[];
  canPlaySound = true;

  selectedChannel: string = '';
  $selectedChannel: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.selectedChannel,
  );
  $tagFilter: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  $launchFilter: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private twoWayConversationService: TwoWayConversationService,
    private controlTagsService: ControlTagsService,
    private featureService: FeatureService,
  ) {}

  ngOnInit() {
    if (this.featureService.checkFlag(FeatureFlags.two_way_notifications)) {
      this.twoWayConversationService.clientEmitter
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((item) => this.channelConversationEventRouter(item));
    }
    this.titleService.activate('Two Way Messaging');
    this.initChat();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tagFilterChange(event: any[]): void {
    this.$tagFilter.next(event);
  }

  launchFilterChange(event: any[]): void {
    this.$launchFilter.next(event);
  }

  setSelectedChannel(channel_sid: string): void {
    this.selectedChannel = channel_sid;
    this.$selectedChannel.next(this.selectedChannel);
  }

  textAreaFocused(event: boolean) {
    this.canPlaySound = !event;
  }

  private initChat() {
    const clientId = this.sessionService.getCurrentUsersClient().id;
    this.twoWayConversationService
      .getAgentToken(clientId)
      .pipe(
        switchMap(() => this.twoWayConversationService.getClient(clientId)),
        switchMap(() => this.controlTagsService.getTagsForAgent(clientId)),
      )
      .subscribe(
        (tags: ControlTag[]) => {
          this.tags = tags;
          this.loading = false;
        },
        (err) => this.handleError(err),
      );

    // this is a concat map
    // what would happen if we put this in a forkJoin with a normal http call?
    // will we emit both result sets every time?
    this.twoWayConversationService
      .getAllLaunchedBy(clientId)
      .pipe(
        reduce(
          (accumulatedResults, newResults) =>
            accumulatedResults.concat(newResults),
          [],
        ),
      )
      .subscribe(
        (res) => (this.launches = res),
        (err) => this.handleError(err),
      );
  }

  private handleError(err: string): void {
    this.error = 'There was an error initializing your conversation';
    this.loading = false;
    LoggerService.log('MessagingComponent', err);
  }

  private channelConversationEventRouter(event: any): void {
    switch (event.event_type) {
      case TwoWayConversationMessageEvents.messageAdded:
        if (!(event.event as Message).author.startsWith('agent')) {
          if (
            this.selectedChannel !== (event.event as Message).conversation.sid
          ) {
            //If not the current channel always play the sound
            this.audiblePing.nativeElement.play();
          } else if (this.canPlaySound) {
            //if canPlaySound is true text is not focused play the sound
            this.audiblePing.nativeElement.play();
          }
        }
        return;
    }
  }
}
