<div class="container-fluid grid" *ngIf="!!currentClient">
  <h4>Current Client</h4>
  <div class="row grid-header">
    <div class="col-sm-5 column-heading no-text-selection" (click)="onColumnHeadingClick('data.company_name')">
      <span i18n="Client name column heading">Client Name</span>
    </div>
    <div class="col-sm-2 column-heading center no-text-selection" (click)="onColumnHeadingClick('created_at')">
      <span i18n="Client date created column heading">Date Created</span>
    </div>
    <div class="col-sm-2 column-heading center no-text-selection" (click)="onColumnHeadingClick('updated_at')">
      <span i18n="Client date modified column heading">Date Modified</span>
    </div>
  </div>
  <div
    class="grid-row" routerLink="/client/{{currentClient.id}}/edit">
    <div class="row">
      <div class="col-sm-5 client-name-col">
        <img  class="client-icon" [(src)]="currentClient.branding.icon_s3_url" width="50" height="50"/>
        <span class="client-name strong">{{currentClient.company_name}}</span>
      </div>
      <div class="col-sm-2 center">
        <span>{{currentClient.created_at | date:'shortDate'}}</span>
      </div>
      <div class="col-sm-2 center">
        <span>{{currentClient.updated_at | date:'shortDate'}}</span>
      </div>
      <div class="col-sm-2 action-column center">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        <i class="fa fa-caret-right expand-collapse"></i>
      </div>
    </div>
  </div>
</div>
<br>
<div class="container-fluid grid">
  <div class="row grid-header">
    <div class="col-sm-5 column-heading no-text-selection" (click)="onColumnHeadingClick('data.company_name')">
      <span i18n="Client name column heading">Client Name</span>
      <app-column-sort-icon id="sort-icon-client-name" [ordering]="ordering"
                            [fieldName]="'data.company_name'"></app-column-sort-icon>
    </div>
    <div class="col-sm-2 column-heading center no-text-selection" (click)="onColumnHeadingClick('created_at')">
      <span i18n="Client date created column heading">Date Created</span>
      <app-column-sort-icon id="sort-icon-date-created" [ordering]="ordering"
                            [fieldName]="'created_at'"></app-column-sort-icon>
    </div>
    <div class="col-sm-2 column-heading center no-text-selection" (click)="onColumnHeadingClick('updated_at')">
      <span i18n="Client date modified column heading">Date Modified</span>
      <app-column-sort-icon id="sort-icon-date-modified" [ordering]="ordering"
                            [fieldName]="'updated_at'"></app-column-sort-icon>
    </div>
  </div>
  <div class="infinite-scroll" data-infinite-scroll
       debounce
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollThrottle]="throttle"
       (scrolled)="onScrollDown()">
    <ng-container *ngFor="let client of clients">
      <div class="grid-row" routerLink="/client/{{client.id}}/edit">
        <div class="row">
          <div class="col-sm-5 client-name-col">
            <img class="client-icon" [(src)]="client.branding.icon_s3_url" width="50" height="50"/>
            <span class="client-name strong">{{client.company_name}}</span>
          </div>
          <div class="col-sm-2 center">
            <span>{{client.created_at | date:'shortDate'}}</span>
          </div>
          <div class="col-sm-2 center">
            <span>{{client.updated_at | date:'shortDate'}}</span>
          </div>
          <div class="col-sm-2 action-column center">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            <i class="fa fa-caret-right expand-collapse"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="loaded && clients.length == 0" class="not-found"
     i18n="Message that the user gets when there are no clients to show in the list">There were no clients found.
</div>

<message-dialog></message-dialog>
