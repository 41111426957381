import {Injector} from "@angular/core";

/**
 * Expose angular injector in a static context for the rare cases where you can't inject a service.
 * 
 * Only use this in special cases where you can't inject normally.
 */
export class ServiceLocator {
    static injector: Injector;
}
