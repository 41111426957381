import React from 'react';
import { Button } from 'baseui/button';
import {
  FilterMethodOptions,
  FilterOptionClosedReasonClickedType,
  FilterOptionDateCreatedClickedType,
  FilterOptionLastModifiedClickedType,
  FilterOptionType,
} from '../../relay-messenger-conversations/RelayMessengerConversations';
import { Check } from 'baseui/icon';

type FilterVariantButtonProps = {
  value: FilterMethodOptions | string;
  filterOptionClicked:
    | FilterOptionLastModifiedClickedType
    | FilterOptionDateCreatedClickedType
    | FilterOptionClosedReasonClickedType;
  filterByOption: FilterOptionType;
  setFilterByOption: (value: FilterOptionType) => void;
  close: () => void;
  dataTestId: string;
};

export const FilterVariantButton = ({
  value,
  filterOptionClicked,
  filterByOption,
  setFilterByOption,
  close,
  dataTestId,
}: FilterVariantButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        setFilterByOption(
          (e.target as HTMLButtonElement).value as FilterMethodOptions,
        );
        close();
      }}
      value={value}
      overrides={{
        BaseButton: {
          style: {
            width: '100%',
            display: 'flex',
            backgroundColor: 'transparent',
            textAlign: 'left',
            justifyContent: 'left',
            color:
              filterOptionClicked && filterByOption === value
                ? '#3693BF'
                : 'black',
            fontWeight:
              filterOptionClicked && filterByOption === value
                ? 'bolder'
                : 'inherit',
            fontSize: '12px',
            outline: 'none !important',
            ':hover': {
              backgroundColor: '#E1EFF5',
            },
            ':active': {
              backgroundColor: 'transparent',
            },
          },
          props: {
            'data-testid': dataTestId,
          },
        },
      }}
      padding={'10px'}
      startEnhancer={() =>
        filterOptionClicked && filterByOption === value ? (
          <Check
            size={'20px'}
            color="#3693BF"
            overrides={{
              Svg: {
                style: {
                  display: 'flex',
                },
              },
            }}
          />
        ) : (
          ''
        )
      }
    >
      {value} {/* TODO: wrap text */}
    </Button>
  );
};
