import { Component, Input, OnInit } from '@angular/core';
import { DisplayOption } from '../list-options';
import { MediaService } from '@app/core/services/media.service';

@Component({
  selector: 'journey-details',
  templateUrl: './journey-details.component.html',
  styleUrls: ['./journey-details.component.scss']
})
export class JourneyDetailsComponent implements OnInit {
  @Input() journeyRecord: any;
  @Input() messageView;
  @Input() displayOption: DisplayOption;
  @Input() selectionType: string = null;
  @Input() copyType: string = null;
  @Input() copyMsgParams: any = null;
  @Input() includeSmsOnlyMessages: boolean = true;

  ulWidth: number;
  fetchingAsset: boolean;

  constructor(private mediaService: MediaService) {
  }

  ngOnInit() {
    const messages = this.journeyRecord.latestContent.components.filter(c => (c.type === 'MessageSender') || (c.type === 'MessageResponse'));
    this.setAssetURLs(messages);

    if (this.journeyRecord.live) {
      const liveMessages = this.journeyRecord.live.components.filter(c => (c.type === 'MessageSender') || (c.type === 'MessageResponse'));
      this.setAssetURLs(liveMessages);
    }

    this.ulWidth = this.calculateUlWidth(messages);
  }

  copyIdToClipboard(messageId: string, event) {
    event.stopPropagation();
    (<HTMLInputElement> document.getElementById(messageId)).select();
    document.execCommand('copy');
  }

  calculateUlWidth(messages) {
    return messages.length * 300;
  }

  setAssetURLs(messages: any) {
    for (let i = 0; i < messages.length; i++) {
      const wire = messages[i].wire;
      const media_asset = wire.media_asset;
      const media_id = wire.media_id;

      if (media_asset) {
        // template experiences have their assets pre-fetched, so media_id is
        // undefined and media_asset contains the getMediaAsset response
        if (media_asset.asset_type === 'video') {
          wire.video_url = media_asset.url;
        } else {
          wire.image_url = media_asset.s3_url;
        }
      } else if (media_id) {
        this.fetchingAsset = true;
        this.mediaService.getMediaAsset(media_id, true).subscribe(media => {
          if (media.asset_type === 'video') {
            wire.video_url = media.url;
          } else {
            wire.image_url = media.s3_url;
          }
          this.fetchingAsset = false;
        });
      }
    } 
  }
  
  get filteredComponents() {
    if (this.includeSmsOnlyMessages) {
      return this.journeyContent.components;
    } else {
      return this.journeyContent.components.filter(c => !c.wire || c.wire.is_shown === 'true');
    }
  }

  get journeyContent() {
    if (this.displayOption === DisplayOption.live) {
      return this.journeyRecord.live;
    } else if (this.displayOption === DisplayOption.draft) {
      return this.journeyRecord.draft;
    } else if (this.displayOption === DisplayOption.preferLiveToDraft) {
      return this.journeyRecord.live ? this.journeyRecord.live : this.journeyRecord.draft;
    }
  }
}
