<div class="rn-page">
  <div class="apikey__box">
    <h2 class="intro-line-1">API Key</h2>

    <ng-container *ngIf="!hasExistingKeys()">
      <div class="apikey__create">
        <p class="apikey__text">
          <em>No keys have been created.</em>
        </p>
        <button class="btn btn-primary" (click)="createApiKey()">Create</button>
      </div>
    </ng-container>

    <ng-container *ngIf="hasExistingKeys()">
      <rn-apikey-item *ngFor="let key of existingKeys" [text]="key"></rn-apikey-item>
    </ng-container>
  </div>
</div>

<message-dialog></message-dialog>
