import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JobsService } from '@app/jobs-list/services/jobs.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Job, JobFiletype } from '@app/jobs-list/models/job';
import { TitleTextPipe } from '@app/shared/pipes/title-text.pipe';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { FilterGroupingService } from '@app/jobs-list/services/filter-grouping.service';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { FileUploadService } from '@app/file-launcher/services/file-upload.service'
import { DatePipe } from '@angular/common';

import * as _ from 'lodash';
import { SelectComponent } from '@app/shared/select/select.component';
import { SessionService } from '@app/security/session.service';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})

export class JobsListComponent implements OnInit, OnDestroy {
  jobs$: BehaviorSubject<Job[]> = new BehaviorSubject([]);
  appliedFilters: Array<string> = []; 
  appliedSort: string = 'last_modified_at';
  sortDirection: 'asc' | 'desc' = 'desc';
  permissions = Permissions;
  jobsLoading: boolean = false;

  // only filled in when dialog is open
  scheduleTime: Date;
  jobBeingScheduled: string;
  pollingSub$: Subscription;
  
  @ViewChild('cancelJobDialog', { static: true }) cancelJobDialog: MessageDialogComponent; 
  @ViewChild('messageDialog', { static: true }) messageDialog: MessageDialogComponent; 
  @ViewChild('progressDialog', { static: true }) progressDialog: MessageDialogComponent; 
  @ViewChild('filetypeSelect', { static: true }) filetypeSelect: SelectComponent;
  @ViewChild('statusSelect', { static: true }) statusSelect: SelectComponent;

  /*
    Stores loaded s3Urls for all jobs. ex:
    {  
      'JOBID001': { original_url: '', accept_url: '', reject_url: '' },
      'JOBID002': { original_url: '', accept_url: '', reject_url: '' },
    }
  */
  s3Urls: Object = {};

  // used to display the filters for statuses w/ the same color as the status markers
  // Copy from & keep in-sync with mixins/status-color.scss
  jobsListStatusColorMap =  {
    'default':      '#808080', // $middle-gray,
    'idle':         '#CCCCCC', // $light-gray-3,
    'scheduled':    '#3FA9DD', // $picton-blue,  
    'pending':      '#8f8be6', // $violet,
    'processing':   '#f49916', // $red-orange,
    'completed':    '#abd037', // $relay-green,
    'cancelled':    '#f7d64d', // $yellow,
    'failed':       '#db4437', // $red 
  };

  sortOptions: string[] = [
    'last_modified_at',
    'status',
    'filename',
    'filetype',
    'job_started_at',
  ];

  constructor(
    private permissionService: PermissionService,
    private sessionService: SessionService,
    private jobsService: JobsService, 
    private titleTextPipe: TitleTextPipe,
    private titleService: TitleService,
    private filterGroupingService: FilterGroupingService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private datePipe: DatePipe
  ) {}
  fileTypes = [
    { value: JobFiletype.Messaging, label: 'Messaging' },
    { value: JobFiletype.ExperienceMessaging, label: 'Experience Messaging' },
    { value: JobFiletype.Onboarding, label: 'Onboarding' },
    { value: JobFiletype.CCA, label: 'CCA' },
    { value: JobFiletype.MobileAnalysis, label: 'Mobile Analysis' },
    { value: JobFiletype.PhoneManagement, label: 'Phone Management' },
    { value: JobFiletype.ConsentUpdate, label: 'Consent Update' },
    { value: JobFiletype.Deactivation, label: 'Deactivation' },
    { value: JobFiletype.MessagingV2, label: 'Messaging V2' },
    { value: JobFiletype.MessagingV2, label: 'Onboarding V2' },
    { value: JobFiletype.CCAV2, label: 'CCA V2' },
    { value: JobFiletype.DeactivationV2, label: 'Deactivation V2' },
    { value: JobFiletype.Report, label: 'Report' },
  ];

  ngOnInit() {
    const client = this.sessionService.getCurrentUsersClient();
    // If user has the jobs_add permission, show the 'upload file' button in the title bar
    if (this.permissionService.checkPermissions(Permissions.jobs_add) && client.fe_upload_enabled) {
      this.addTitleAndUploadButton(true);
    } else {
      this.addTitleAndUploadButton();
    }
        
    this.updateJobs();
  }

  addTitleAndUploadButton(showUploadButton: boolean = false) {
    if (showUploadButton) {
      this.titleService.activate('File Engine Jobs', '', 'Upload File', 'fa-plus-circle', () => {
        this.router.navigateByUrl('/file-engine-jobs/upload'); // this is the handler for the 'upload file' button
      });
    } else {
      this.titleService.activate('File Engine Jobs', '');
    }
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  get statusFilterOptions() {
    return this.filterGroupingService.filterGroupSeeds()['status'];
  }

  get filetypeFilterOptions() {
    return this.filterGroupingService.filterGroupSeeds()['filetype'];
  }

  cancelJob(jobId): void {
    this.progressDialog.showMessage(`Cancelling job...`);
    this.jobsService.cancelJob(jobId).subscribe((response) => {
      this.progressDialog.cancelAction();
      this.messageDialog.showMessage(response['response']);
      this.updateJobs();
    }, (error) => {
      this.progressDialog.cancelAction();
      this.messageDialog.showMessage(`There was an error cancelling your job. ${error['response']}`);
    });
  }

  clearFilters(): void {
    this.appliedFilters = [];
    this.rearrangeJobs();
    this.statusSelect.clear();
    this.filetypeSelect.clear();
  }

  displayableFilters(): string {
    return this.appliedFilters
      .map((val) => {
        return this.titleTextPipe.transform(
          val.replace('journey', 'experience')
        );
      })
      .join(', ');
  }

  filterChanged(event): void {
    this.addFilter(event.target.value);
    this.rearrangeJobs();
  } 

  filterRemoved(filter: string): void {
    this.removeFilter(filter);
    this.rearrangeJobs();
    this.statusSelect.clear();
    this.filetypeSelect.clear();
  }
  
  jobCanBeCancelled(job): boolean {
    return job.isCancellable() && this.permissionService.checkPermissions(this.permissions.jobs_cancel);
  }

  jobCanBeScheduled(job): boolean {
    return job.isSchedulable() && this.permissionService.checkPermissions(this.permissions.jobs_schedule);
  }

  jobsError(): boolean {
    return this.jobsService.hasError().getValue();
  }
  
  jobTrackbyKey(index, job): string {
    if (!job) { return ''; }
    return `job-${job.id}`;
  }

  noJobsForClient(): boolean {
    return this.jobs$.getValue().length === 0;
  }

  refreshJobsClicked(): void {
    this.updateJobs();
  }

  searchChanged(searchTerm): void {
    if (_.isEmpty(searchTerm)) { return; }
    this.addFilter(searchTerm);
    this.rearrangeJobs();
  }

  showCancelJobWarning(jobId): void {
    this.cancelJobDialog.showMessage(`Are you sure you want to cancel this job?`, jobId);
  }

  openScheduleDialog(jobId): void {
    this.jobBeingScheduled = jobId;
    this.scheduleTime = new Date(Date.now());
  }

  closeScheduleDialog(jobId): void {
    this.scheduleTime = null;
  }

  scheduleFile(scheduleTime): void {
    this.fileUploadService.schedule(this.jobBeingScheduled, scheduleTime).subscribe((response) => {
      this.messageDialog.showMessage(`Your file has been scheduled for ${this.datePipe.transform(scheduleTime, 'short')}`);
      this.jobsService.updateJobs();
    }, (error) => {
      this.messageDialog.showMessage(`There was a problem Scheduling your file: ${JSON.stringify(error['error']['response'])}`);
    }, () => {
      this.scheduleTime = null;
    });
  }

  sortSelectChanged(event): void {
    this.sortChanged(event.target.value);
  }

  sortChanged(sort): void {
    this.sortDirection = this.isNewSort(sort) ? 'asc' : this.nextSortDirection(); 
    this.appliedSort = sort;
    this.rearrangeJobs();
  }

  /**
   * returns formatted timestamp, or appropriate text to describe the missing timestamp based on job status
   * @param job 
   * @param key: 'job_started_at' or 'last_modified_at'
   */
  jobTimestampText(job: Job, key: string): string {
    if (job[key]) {
      return job.displayableDate(job[key]);
    } 
    if (_.includes(['cancelled', 'cancelling', 'failed', 'completed'], job.status)) {
      return 'unavailable';
    }
    return 'pending';
  }

  private addFilter(filterValue) {
    const processedFilter = filterValue.toLowerCase();
    if (this.isInvalidFilter(processedFilter)) { return; }
    this.appliedFilters.push(processedFilter);
  }

  private isInvalidFilter(newFilter): boolean {
    return _.includes(this.appliedFilters, newFilter) || _.isEmpty(newFilter);
  }

  private isNewSort(sort): boolean {
    return !(this.appliedSort === sort); 
  }

  private nextSortDirection(): 'asc' | 'desc' {
    return this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  /**
   * For changing sort/filters without affecting data.  Doesn't make additional requests.
   */
  private rearrangeJobs(): void {
    this.jobsService
      .rearrangeJobs(this.appliedFilters, this.appliedSort, this.sortDirection)
      .subscribe(
        (jobs) => {
          this.jobs$.next(jobs);
        }
      );
  }

  private removeFilter(filterToRemove: string): void {
    _.remove(this.appliedFilters, (filter) => {
      return filter === filterToRemove;
    });
    this.rearrangeJobs();
  }

  /**
   * For updating the actual data via a new request
   */
  private updateJobs(): void {
    this.jobsLoading = true;
    this.jobsService.updateJobs().subscribe(
    (job) => {
      this.jobsLoading = false;
    }, 
    (error) => {
      this.jobsLoading = false;
    });

    this.rearrangeJobs();
  }
}
