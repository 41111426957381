import { Injectable } from '@angular/core';

@Injectable()
export class NavbarStateService {

  public fullSize = true;

  constructor() { }

  get sideNavbarWidth() {
    if (this.fullSize) {
      return 198;
    } else {
      return 60;
    }
  }

  get headerHeight() {
    return 62;
  }
}
