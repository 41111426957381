<label>Upload Files</label>
<p>Upload Help documents, Privacy Policy, and Terms & Conditions documents here</p>
<p><b>Files must be named one of the following: {{validNames.join(', ')}}</b></p>

<div class="container-fluid list">
    <div class="help-site-column help-site-heading-row">
        <div class="column-heading no-text-selection">
            <span i18n="File Name">File Name</span>
        </div>
        <div class="column-heading no-text-selection">
            <span i18n="Modified At">Modified At</span>
        </div>
        <div class="column-heading no-text-selection">
            <span i18n="Preview Link">Preview Link</span>
        </div>
    </div>
    <div *ngFor="let helpsite of helpsiteList" class="help-site-column help-site-row">
        <div>{{helpsite.filename}}</div>
        <div>{{helpsite.last_modified | date : 'short'}}</div>
        <div *ngIf="helpsite.url"><a href="{{helpsite.url}}" target="_blank">{{helpsite.filename}}</a></div>
        <div>
            <button (click)="downloadFile(helpsite.filename_no_extension)" class="btn btn-primary" id="download-btn">
                <span class="btn-label">Download</span>
            </button>
        </div>
    </div>
</div>

<div style="display: grid; grid-template-columns: 1fr 125px 125px 1fr; grid-template-rows: 50px 1fr">
    <button class="btn btn-primary" id="new-template-btn" style="grid-column: 2">
        <span (click)="showTemplate()" class="btn-label">Use Template</span>
    </button>
    <button (click)="showUploadScreen()" class="btn btn-primary" id="upload-btn" style="grid-column: 3">
        <span class="btn-label">Upload File</span>
    </button>
    <rn-upload-drop-area (fileCaptured)="handleDrop($event)"
                         *ngIf="showUpload"
                         [acceptedFileTypes]="'text/html'"
                         style="grid-row: 2; grid-column: 1/-1; margin: 0 10px">
        <span select-btn-text>SELECT FILE</span>
        <p description-text><b>Supported File Formats:</b> .html</p>
    </rn-upload-drop-area>
    <div *ngIf="showTemplateForm"
         style="grid-row: 2; grid-column: 1/-1; margin: 0 10px">
        <rn-client-helpsite-generator (cancelClicked)="hideTemplate()" (saveClicked)="handleSaveFromTemplateClicked($event)" [client]="client"></rn-client-helpsite-generator>
    </div>
</div>


<message-dialog></message-dialog>
