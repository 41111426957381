// angular + modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { RelaySharedModule } from '@app/shared/shared.module';

// services
import { JobsService } from '@app/jobs-list/services/jobs.service';
import { FilterGroupingService } from '@app/jobs-list/services/filter-grouping.service';
import { FileUploadService } from '@app/file-launcher/services/file-upload.service';

// components
import { JobsListComponent } from '@app/jobs-list/components/jobs-list/jobs-list.component';
import { ListItemExpandableComponent } from '@app/jobs-list/components/list-item-expandable/list-item-expandable.component';
import { JobsListDetailComponent } from '@app/jobs-list/components/jobs-list-detail/jobs-list-detail.component';
import { FileLauncherModule } from '@app/file-launcher/file-launcher.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FileLauncherModule,
    RelaySharedModule,
  ],
  declarations: [
    JobsListComponent,
    JobsListDetailComponent,
    ListItemExpandableComponent,
  ],
  providers: [
    JobsService,
    FileUploadService,
    FilterGroupingService,
  ],
  exports: [
    JobsListComponent,
    JobsListDetailComponent,
    ListItemExpandableComponent,
  ]
})

export class JobsListModule {
}
