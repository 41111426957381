<ng-container
  *ngIf="screen === 'form' || (message && (productGroup || screen === 'sms'))"
>
  <div
    [id]="wrapperId()"
    [ngClass]="{
      phone: true,
      stretch: stretch,
      'phone--expanded': previewMode === 'expanded',
      'phone--large': large,
      'response-messages': screen === 'form' && showPdfMarkup === false,
      'response-messages--form': screen === 'form' && showPdfMarkup === false
    }"
  >
    <div class="bazzel">
      <div class="screen" [class.stretch]="stretch">
        <div class="card-phone" [class.stretch]="stretch">
          <!-- render Wire header -->
          <div
            *ngIf="
              (screen === 'wire' || screen === 'feed' || screen === 'form') &&
              !newFeed.enabled
            "
            class="card-phone-header"
            [ngStyle]="{ 'background-color': headerColor }"
            (click)="viewMenu = !viewMenu"
          >
            <i
              class="card-phone-menu fa fa-bars"
              *ngIf="!productGroup?.hide_hamburger"
            ></i>
            <h3 class="card-phone-title">{{ headerTitle }}</h3>
          </div>

          <!-- Render Feed header -->
          <ng-container
            *ngIf="
              (screen === 'wire' || screen === 'feed' || screen === 'form') &&
              newFeed.enabled
            "
          >
            <product-group-preview-react
              [messageId]="messageId"
              [logoUrl]="logoUrl"
              [color]="headerColor"
              [showProductGroup]="!productGroup?.hide_pg_wire_display_name"
              [productGroupName]="productGroup?.name || ''"
              [bannerUrl]="productGroup?.branding?.banner_s3_url || ''"
              [productGroupDescription]="productGroup?.description || ''"
            ></product-group-preview-react>
          </ng-container>

          <!-- Render powerUp preview -->
          <div *ngIf="screen === 'form'">
            <h3 class="response-messages__form-label">{{ formLabel }}</h3>
            <img class="full-width" [src]="powerUpPlaceholderUrl" />
          </div>

          <wire-preview
            *ngIf="screen === 'wire' && !newFeed.enabled"
            [branding]="message.wire.branding"
            [wire]="message.wire"
            [productGroup]="productGroup"
            [hasIWCU]="hasIWCU"
            [showPdfMarkup]="showPdfMarkup"
            [isJourneyPreview]="isJourneyPreview"
            (toggleCollapsible)="toggleCollapsible.emit()"
          ></wire-preview>

          <wire-preview-react
            *ngIf="screen === 'wire' && newFeed.enabled"
            [message]="message"
            [components]="components"
            [brandingColor]="headerColor"
            [disclaimerConfig]="disclaimerConfig"
            [productGroup]="productGroup"
            [previewMode]="previewMode"
          ></wire-preview-react>
          <bubble *ngIf="screen === 'sms'" [text]="message.sms.text"></bubble>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="header">
      <div class="sensor-1"></div>
      <div class="sensor-2"></div>
      <div class="sensor-3"></div>
    </div>
    <div class="volume-button"></div>
    <div class="power-button"></div>
  </div>

  <!-- This recursively generates an additional preview for all "branch" messages (messageResponse types) and any branches-of-branches -->
  <div *ngIf="recursive && journeyContent">
    <div
      id="response-messages-{{ message.name }}"
      class="response-messages"
      *ngFor="let responseMsg of getAllResponseMessages(); let i = index"
    >
      <phone
        [message]="responseMsg"
        [screen]="screen"
        [recursive]="true"
        [stretch]="stretch"
        [preivewMode]="previewMode"
        [journeyContent]="journeyContent"
        [productGroup]="productGroup"
        [components]="components"
        id="response-msg-{{ responseMsg.name }}"
      >
      </phone>
    </div>
  </div>
</ng-container>
