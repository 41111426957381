<div id="login-page-content">
  <div>
    <img id="relay-logo" src="/assets/relay-logo-full.png">
  </div>
  <div id="login-form-and-help">
    <div class="login-form">
      <p class="instructions" i18n="Password prompt on login page">Set password</p>

      <p *ngIf="!loginFormGroup.controls['password'].valid && loginFormGroup.controls['password'].touched" id="login-failure">
        <i class="fa fa-exclamation-circle"></i>
        <span id="login-failure-msg" i18n="Login failure message">Password does not meet requirements listed below</span>
      </p>
      <p *ngIf="error" id="login-failure">
        <i class="fa fa-exclamation-circle"></i>
        <span id="login-failure-msg" i18n="Login failure message">{{error}}</span>
      </p>
      <div class="password-container">

        <div class="show-password">
          <span class="" (click)="showPassword = !showPassword">{{showPassword?'hide':'show '}} password</span>
        </div>
        <div class="input-with-icon" [ngClass]="{'has-error':!loginFormGroup.controls['password'].valid && loginFormGroup.controls['password'].touched}">
          <input id="password-input" [attr.type]="showPassword?'text':'password'" class="form-control" placeholder="Password" i18n-placeholder="Placeholder text for password field on login page"
                 [formControl]="loginFormGroup.controls['password']" (keypress)="checkForEnter($event)">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <button class="submit-login btn" [disabled]="!loginFormGroup.controls['password'].valid" (click)="resetPassword()" *ngIf="!loginInProgress">
        <div class="submit-label" i18n="Label for submit login information button">Submit</div>
        <span class="icon icon-right">
          <i class="fa fa-arrow-right"></i>
        </span>
      </button>
      <div class="login-spinner" *ngIf="loginInProgress">
        <app-spinner></app-spinner>
      </div>
    </div>
    <div class="directions">
      <p>Your password must:</p>
      <ul>
        <li>Be 8 characters long</li>
        <li>Contain 1 lowercase letter</li>
        <li>Contain 1 uppercase letter</li>
        <li>Contain 1 number</li>
        <li>Not be any of your 6 previous passwords</li>
      </ul>
    </div>

    <div class="login-form">
      <button class="submit-login btn" (click)="goHome()">
        <div class="submit-label" i18n="Label for go to login">Go to log in</div>
        <span class="icon icon-right">
          <i class="fa fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>
  <div id="welcome-text" i18n="Welcome text for login prompt">
    Welcome to CX builder<span>&trade;</span>
  </div>
</div>
