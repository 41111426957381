<div class="blackout-window-group">

  <div class="input-container">
    <div class="input clearfix">

      <label i18n="Blackout window start label">{{labelPrefix}} start</label>

      <select class="form-control light" [(ngModel)]="blackoutWindow.start_time" id="blackout-start-select" (ngModelChange)="onBlackoutStartChange($event)">
        <ng-container *ngFor="let hour of hours">
          <option [attr.value]="hour.isoHour">{{hour.displayHour}}</option>
        </ng-container>
      </select>

    </div>
  </div>

  <div class="input-container">
    <div class="input clearfix">

      <label i18n="Blackout window end label">{{labelPrefix}} end</label>

      <select class="form-control light" [(ngModel)]="blackoutWindow.end_time" id="blackout-end-select" (ngModelChange)="onBlackoutEndChange($event)">
        <ng-container *ngFor="let hour of hours">
          <option [attr.value]="hour.isoHour">{{hour.displayHour}}</option>
        </ng-container>
      </select>

    </div>
  </div>

  <div class="input-container">
    <div class="input clearfix"> 
      <app-radio-btn [btnLabel]="labelPrefix+' on Saturday?'" [btnWidth]="'300px'" [btnName]="'blackout-on-saturday'" [(btnModel)]="blackoutWindow.include_saturday"></app-radio-btn> 
    </div>
  </div>

  <div class="input-container">
    <div class="input clearfix"> 
      <app-radio-btn [btnLabel]="labelPrefix+' on Sunday?'" [btnWidth]="'300px'" [btnName]="'blackout-on-sunday'" [(btnModel)]="blackoutWindow.include_sunday"></app-radio-btn> 
    </div>
  </div>
</div>


<p class="tip-text blackout-window-tip-text">
  <svg width="15" height="250" style="float: left;">
    <polyline points="8,0 8,94, 2,100 8,106 8,200" style="fill: none; stroke: #5b5a59; stroke-width:1" />
  </svg>
  <span i18n="User friendly description of the default blackout window">
    Users {{verb}} {{thingBeingSent}} {{timeRange}} Eastern Time. {{weekendBlackoutMessage}}
  </span>
  <br><br>
  <span i18n="Description for blackout window start/end values">
    Note: The {{labelPrefix}} start and end times cannot be set equal.  Attempting to do so will cause the other to be adjusted by one hour.
  </span>
  <br><br>
  <span> 
    Note: Users {{verb}} {{thingBeingSent}} on Saturday or Sunday when “On” is selected.
  </span>
</p>