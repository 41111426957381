import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Client,
  IvrOnboardingType,
  ProductGroup,
  SMTPCredentials,
} from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { JourneySelectService } from '@app/core/services/journey-select.service';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { CsrJourneysModalService } from '@app/core/services/csr-journeys-modal.service';
import { FileProcessingConfigComponent } from './file-processing-config/file-processing-config.component';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
  selector: 'app-client-onboarding-and-feature',
  templateUrl: './client-onboarding-and-feature.component.html',
  styleUrls: ['./client-onboarding-and-feature.component.scss'],
})
export class ClientOnboardingAndFeatureComponent implements OnInit, OnDestroy {
  @Input() showJourneySelect: boolean = false;
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialogComponent: MessageDialogComponent;
  @ViewChild('fileProcessingConfig', { static: false })
  fileProcessingConfig: FileProcessingConfigComponent;

  client: Client;
  // used to determine if POST or PUT
  hasSMTPCredentials = false;
  smtpCredentials: SMTPCredentials = {};

  headerGroups = {
    onboarding: true,
    feature: true,
    fileProcessingConfig: true,
    externalConfig: true,
    smsArchive: true,
  };
  isFileProcessingConfigValid: boolean = true;
  journeyDialogPosition: string = '';
  productGroupOptions: { name: string; id: string }[];
  ivrConfigFormValid: boolean;

  private modalOpenSub$;
  private modalCloseSub$;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private journeySelectService: JourneySelectService,
    private titleService: TitleService,
    private csrJourneysModalService: CsrJourneysModalService,
    private router: Router,
    public newFeed: NewFeedTransitionService,
  ) {}

  ngOnInit() {
    this.getClient();
    this.initCsrJourneySelectModal();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    this.modalOpenSub$.unsubscribe();
    this.modalCloseSub$.unsubscribe();
  }

  onSMTPChange(event): void {
    this.smtpCredentials = event;
  }

  isDisabled(): boolean {
    let disabled: boolean = false;

    if (!this.client) {
      return true;
    }

    if (
      this.client.onboarding.csr.enabled === 'true' &&
      this.client.onboarding.csr.capture_written_consent === 'true' &&
      this.client.onboarding.csr.written_consent_language?.length === 0
    ) {
      disabled = true;
    }

    if (
      this.client.onboarding.csr.enabled === 'true' &&
      this.client.onboarding.csr.capture_written_consent === 'false' &&
      this.client.onboarding.csr.express_consent_language?.length === 0
    ) {
      disabled = true;
    }

    if (
      this.client.onboarding.csr.enabled === 'true' &&
      this.client.onboarding.csr.validation_language?.length === 0
    ) {
      disabled = true;
    }

    if (!this.isFileProcessingConfigValid) {
      disabled = true;
    }

    if (!this.ivrFormIsValid()) {
      disabled = true;
    }

    return disabled;
  }

  checkIfFileProcessingValid(e: any) {
    this.isFileProcessingConfigValid = e ? e : false;
  }

  previousStep(): void {
    if (this.journeySelectService.init) {
      this.journeySelectService.toggleInitOff();
    }
    this.router.navigateByUrl(`/client/${this.client.id}/edit/inputs`);
  }

  saveAndContinue(): void {
    // update client with reactive form data before save
    // NOTE: `fileProcessingConfig.getFormValue()` gives us `cs_manager_email` and `client_contact_email`
    // as keys, but the api doesn't currently accept either of these with an empty string or a null value.
    // Hence we have to delete any keys w/ empty values here, before sending them over if they are empty.
    this.client.job_processing = _.omitBy(
      this.fileProcessingConfig.getFormValue(),
      _.isNil,
    );

    this.client.mobile_analysis.should_prevent_onboarding =
      this.client.mobile_analysis.enabled &&
      this.client.mobile_analysis.should_prevent_onboarding;

    const obs = [this.clientService.updateClient(this.client)];

    // determine what to do with the SMTP config
    if (Object.keys(this.smtpCredentials).length === 0) {
      if (this.hasSMTPCredentials) {
        obs.push(this.clientService.deletePrivateConfigSMTP(this.client.id));
      }
    } else if (this.hasSMTPCredentials) {
      obs.push(
        this.clientService.updatePrivateConfigSMTP(
          this.client.id,
          this.smtpCredentials,
        ),
      );
    } else {
      obs.push(
        this.clientService.createPrivateConfigSMTP(
          this.client.id,
          this.smtpCredentials,
        ),
      );
    }

    forkJoin(obs).subscribe(
      (res) => {
        this.journeySelectService.clearJourneys();
        if (this.journeySelectService.init) {
          this.journeySelectService.toggleInitOff();
        }
        this.router.navigateByUrl(`/client/${this.client.id}/product-group`);
      },
      () =>
        this.messageDialogComponent.showMessage(
          `Oops...Could not save client: ${this.client.id}`,
        ),
    );
  }

  setJourneyDialogPosition(): void {
    this.journeyDialogPosition = `${window.scrollY - window.innerHeight / 6}px`;
  }

  updateIvrData(newIvrData: IvrOnboardingType): void {
    this.client.onboarding.ivr = newIvrData;
  }

  updateIvrFormStatus(isValid: boolean) {
    this.ivrConfigFormValid = isValid;
  }

  private ivrFormIsValid(): boolean {
    if (this.client.onboarding.ivr.enabled === 'false') {
      return true;
    }
    return this.ivrConfigFormValid;
  }

  private getClient(): void {
    const limit = 100;
    const offset = 0;

    const clientId = this.activatedRoute.snapshot.params['clientId'];
    const obs = [
      this.clientService.getClient(clientId),
      this.clientService.getProductGroupsByClient(clientId, limit, offset),
      this.clientService.getPrivateConfigSMTP(clientId),
    ];

    forkJoin(obs).subscribe(
      (res: [Client, ProductGroup[], SMTPCredentials]) => {
        this.client = res[0];
        this.productGroupOptions = res[1].map((productGroup) => {
          return { id: productGroup.id, name: productGroup.internal_name };
        });
        this.smtpCredentials = res[2];
        if (Object.keys(this.smtpCredentials).length !== 0) {
          this.hasSMTPCredentials = true;
        }
        this.setPrimaryTitle();
      },
      () =>
        this.messageDialogComponent.showMessage(
          `Oops...Could not load client: ${clientId}`,
        ),
    );
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Client - ' + this.client.company_name
        : 'Configure Client',
    );
  }

  private initCsrJourneySelectModal(): void {
    this.modalOpenSub$ =
      this.csrJourneysModalService.openCSRJourneysModal.subscribe(() => {
        this.setJourneyDialogPosition();
        this.showJourneySelect = true;
      });

    this.modalCloseSub$ =
      this.csrJourneysModalService.closeCSRJourneysModal.subscribe(() => {
        this.showJourneySelect = false;
      });
  }
}
