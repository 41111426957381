import { Component, OnInit, OnChanges, EventEmitter, Output, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

class EditableListEvent {
  list: any[];
  item: any;
  action: 'add' | 'remove';

  constructor(action, item, list) {
    this.action = action;
    this.item = item;
    this.list = list;
  }
}

@Component({
  selector: 'rn-editable-list',
  templateUrl: './editable-list.component.html',
  styleUrls: ['./editable-list.component.scss']
})
export class EditableListComponent implements OnInit, OnChanges {
  @Input() showRemove: boolean = true;
  @Input() formArray: UntypedFormArray;
  @Input() limit: number;
  @Output() listChanged: EventEmitter<EditableListEvent> = new EventEmitter();
  listForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.initListForm();
  }

  ngOnChanges(event) {
    // updates internal form if 'initialList' input changes
    if (event.initialList && !event.initialList.firstChange && this.initialListChanged(event)) {
      this.initListForm();
    }
  }

  get newItemCtl(): AbstractControl {
    return this.listForm.get('new_item');
  }

  get listCtl(): UntypedFormArray {
    return (this.listForm.get('list') as UntypedFormArray);
  }

  get list(): string[] {
    return this.listCtl.controls.map((control) => {
      return control.value;
    });
  }

  handleOptionSelected(selected) {
    this.newItemCtl.setValue(selected);
  }

  removeItem(removedItem) {
    const index = this.list.indexOf(removedItem);
    this.listCtl.removeAt(index);
    this.listCtl.markAsDirty();
    this.broadcastUpdate('remove', removedItem);
  }

  addItem() {
    if (this.newItemCtl.value && !this.isDuplicateItem(this.newItemCtl.value)) {
      const control = this.formBuilder.control(this.newItemCtl.value.toLowerCase());
      this.listCtl.insert(0, control);
      control.markAsDirty();
      this.broadcastUpdate('add', this.newItemCtl.value.toLowerCase()); // only returns lower cased items
      this.newItemCtl.setValue('');
    }
  }

  listAtLimit(): boolean {
    if (!this.limit) { return; }
    return this.list.length >= this.limit;
  }

  private isDuplicateItem(newItem): boolean {
    if (!newItem) { return false; }
    return _.includes(this.list, newItem.toLowerCase());
  }

  private broadcastUpdate(action, item) {
    const formData = this.listForm.getRawValue();
    this.listChanged.emit(new EditableListEvent(action, item, formData['list'].sort()));
  }

  private initListForm() {
    this.listForm = this.formBuilder.group({
      list: this.formArray,
      new_item: ['', null]
    });
  }

  private initialListChanged(event): boolean {
    const differentValues = _.xor(event.initialList.currentValue, event.initialList.previousValue);
    return differentValues.length > 0;
  }
}
