<div class="loading-mask dialog-background" [class.show-loader]="loadingMaskService.loadingMaskOn">
  <div class="loader">
    <div class="spinner">
      <div class="container">
        <div class="hex0"></div>
        <div class="hex120"></div>
        <div class="hex240"></div>
      </div>
    </div>
  </div>
</div>
