import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Background } from '@app/core/utils/background';
import { JourneyService } from '@app/core/services/journey.service';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { ComponentBaseClass, MessageClass, MessageDelayerClass, WorkflowClass } from '@app/core/models/message';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SelectableJourneyComponent } from '../selectable-journey-component';
import { JourneyTriggerComponent } from '../journey-trigger/journey-trigger.component';
import { JourneyDelayComponent } from '../journey-delay/journey-delay.component';
import { JourneyMessageComponent } from '../journey-message/journey-message.component';
import { Renumberer } from '@app/core/utils/renumberer';
import { AutosaveService } from '../services/autosave.service';
import { ToolbarService } from '../services/toolbar.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { IWCUHelperService } from '@app/cx-builder/services/builder-helper.service';
import { ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { SessionService } from '@app/security/session.service';
import { Ordering } from '@app/core/utils/ordering';
import * as _ from 'lodash';

@Component({
  selector: 'app-journey-builder',
  templateUrl: './journey-builder.component.html',
  styleUrls: ['./journey-builder.component.scss']
})

export class JourneyBuilderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(JourneyMessageComponent) messageComponents: QueryList<JourneyMessageComponent>;
  @ViewChildren(JourneyDelayComponent) delayComponents: QueryList<JourneyDelayComponent>;
  @ViewChildren(JourneyTriggerComponent) triggerComponents: QueryList<JourneyTriggerComponent>;
  @ViewChild(ToolbarComponent, { static: true }) toolbarComponent: ToolbarComponent;
  @ViewChild('messageDialogWithReload', { static: true }) messageDialogWithReload: MessageDialogComponent;
  @ViewChild('messageDialogNoReload', { static: true }) messageDialogNoReload: MessageDialogComponent;
  @ViewChild('messageDialogDeleteMessage', { static: true }) messageDialogDeleteMessage: MessageDialogComponent;
  @ViewChild('iwcuWarning', { static: true }) iwcuWarning: MessageDialogComponent;

  deletingFamily: boolean = false;
  families: WorkflowClass[];
  isValid = true;
  journeyInputCopy: Journey;
  journey: Journey;
  journeyId: string;
  navigationInProgress = false;
  responses = [];
  selectedComponent: ComponentBaseClass;
  selectedComponentName: string;
  showEditDialog: boolean = false;
  isNewStep: boolean = false;
  buttonHandler: any;
  showSmsByDefault: boolean;

  constructor(private titleService: TitleService,
              private journeyService: JourneyService,
              private autosaveService: AutosaveService,
              private toolbarService: ToolbarService,
              private router: Router,
              private sessionService: SessionService,
              private clientService: ClientService,
              private iwcuHelperService: IWCUHelperService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.showSmsByDefault = this.sessionService.getCurrentUsersClient().show_sms_by_default;
    this.toolbarService.reset();

    this.toolbarService.inspectorUpdateConflict.subscribe(errorMessage => {
      this.messageDialogWithReload.showMessage(errorMessage);
    });

    this.toolbarService.inspectorSave.subscribe(() => {
      this.incrementDraftVersion();
    });

    this.toolbarService.requestEditMessage.subscribe(this.navigationFunction('/cx-builder/message-builder/${journeyId}/message/${msgName}'));
    this.toolbarService.requestCopyMessage.subscribe(this.navigationFunction('/cx-builder/message-copy/message-sender/${journeyId}/${msgName}'));
    this.toolbarService.requestCopyMessageResponse.subscribe(this.navigationFunction('/cx-builder/message-copy/message-response/${journeyId}/${msgName}'));

    this.titleService.getShowEditFlag().subscribe(flag => {
      this.showEditDialog = flag;
      if (this.showEditDialog) {
        this.autosaveService.disableAutosave();
      }
    });
    Background.gridsOn();
    this.journeyId = this.route.snapshot.params['journeyId'];
    if (this.journeyId) {
      this.reload();
    } else {
      this.journey = new Journey({showSmsByDefault: this.showSmsByDefault, meta_data: null});
    }
  }

  ngOnDestroy() {
    this.autosaveService.saveIfNeeded();
    this.autosaveService.disableAutosave();
    this.titleService.deactivate();
    this.toolbarService.reset();
    Background.gridsOff();
  }

  ngAfterViewInit() {
    this.messageComponents.changes.subscribe(r => {
      setTimeout(() => {
        r.forEach((v) => {
          v.calculateTop();
        });
      }, 100);
    });
  }

  getResponseMessages(parent) {
    this.journey.draft.components.filter(f => f.parent.name === parent.name).forEach((r) => {
      this.getResponseMessages(r);
    });
  }

  prepForSave(): void {
    if (this.iwcuHelperService.journeyHasIWCU(this.journey) && this.journey.draft.product_group !== 'all') {
      this.checkOnePG();
    } else if (this.iwcuHelperService.journeyHasIWCU(this.journey) && this.journey.draft.product_group === 'all') {
      this.checkAllPG();
    } else {
      this.saveJourney();
    }
  }

  private checkOnePG(): void {
    this.clientService.getProductGroup(this.sessionService.getCurrentUsersClient().id, this.journey.draft.product_group)
      .subscribe(
        (productGroup: ProductGroup) => {
          if (this.iwcuHelperService.journeyHasIWCU(this.journey) && !productGroup.consent.in_wire_upgrade.enabled) {
            this.iwcuWarning.showMessage('Saving this message will require removing consent upgrade actions. If you would like to keep these actions, please contact your Client Success Manager.');
          } else {
            this.saveJourney();
          }
        },
        error => {
          this.autosaveService.disableAutosave();
          if (error.status !== 401) {
            this.messageDialogNoReload.showMessage('Oops...the journey could not be loaded.');
          }
        }
      );
  }

  private checkAllPG(): void {
    let hasIWCU = false;
    this.clientService.getProductGroupsByClient(this.sessionService.currentUser.client.id, 100, 0, new Ordering('created_at'), true)
    .subscribe(
      (productGs: ProductGroup[]) => {
        _.each(productGs, (pg: ProductGroup) => {
          if (pg.consent.in_wire_upgrade.enabled) {
            hasIWCU = true;
          }
        });

        if (this.iwcuHelperService.journeyHasIWCU(this.journey) && !hasIWCU) {
          this.iwcuWarning.showMessage('Saving this message will require removing consent upgrade actions. If you would like to keep these actions, please contact your Client Success Manager.');
        } else {
          this.saveJourney();
        }
      },
      (err) => this.messageDialogNoReload.showMessage(`Oops...The journey could not be updated: ${err['response']}`)
    );
  }

  cleanIWCU(): void {
    this.journey = this.iwcuHelperService.clearJourneyIWCUActions(this.journey);
    this.journey.draft.updated_at = new Date();
    this.journeyService.updateJourney(this.journeyId, this.journey).subscribe(
      () => {
        this.autosaveService.enableAutosave(this.journey);
        this.incrementDraftVersion();
        this.saveJourney();
      },
      error => {
        this.handleJourneyUpdateError(error);
      }
    );
  }

  saveJourney() {
    const components = this.journeyService.selectableComponennts;
    const invalidComponents = components.filter(c => (c.msg.type === 'MessageSender' || c.msg.type === 'MessageResponse') && (c.msg.isNew));
    this.isValid = !(invalidComponents.length); 

    if (this.isValid) {
      this.autosaveService.disableAutosave();
      this.journeyService.promoteJourney(this.journeyId, this.journey).subscribe(
        () => {
          this.reload();
        },
        (error) => {
          this.autosaveService.enableAutosave(this.journey);
          const errBody = error;
          if (error.status === 409 && errBody.response && errBody.response.indexOf('Mismatch') > -1) {
            this.messageDialogWithReload.showMessage('The journey could not be promoted because a new version is available.');
          } else if (error.status === 409 && errBody.message && errBody.message) {
            this.messageDialogWithReload.showMessage(error.message);
          } else if (error.status !== 401) {
            this.messageDialogWithReload.showMessage(`Oops...The journey could not be updated: ${error.error.response}`);
          }
        }
      );
    } else if (invalidComponents.length) {
      this.selectComponent(null, invalidComponents[0]);
    }
  }

  openEditJourney() {
    this.titleService.emitOnShowEditChange(true);
  }

  renumber() {
    const renumberer = new Renumberer(this.journey);
    renumberer.renumber(this.families[0], 0);
  }

  getNextComponent(step) {
    return this.journey.latestContent.components.find(c => c.name === step.to);
  }

  familyReunion() {
    let family: string;
    this.families = this.journey.draft.components.filter(c => c.type === 'APITrigger');
    const highCmp = this.journey.draft.components.filter(f => f.type !== 'MessageResponse');
    for (let i = 0; i < highCmp.length; i++) {
      const component = this.journey.draft.components.find(c => c.name === highCmp[i].name);
      if (component.type === 'WorkflowEnder') {
        component.to = null;
      } else if (highCmp[i + 1]) {
        component.to = highCmp[i + 1].name;
      }
      if (component.type === 'APITrigger') {
        family = component.name;
        continue;
      }
      component.family = family;
    }

    if (this.families.length) {
      this.renumber();
    }
  }


  createComponent(e) {
    this.autosaveService.disableAutosave();
    this.setupDraft();

    const components = this.journey.draft.components;
    const componentIndex = components.findIndex(c => c.name === e.currentComponent.name);

    if (window?.event != null) {
      window.event.stopPropagation();
    }

    const msg = new MessageClass({showSmsByDefault: this.showSmsByDefault});

    switch (e.type) {
      case 'WorkflowDelayer':
        this.createWorkflowDelayerComponent(components, componentIndex, msg);
        break;

      case 'MessageSender':
        this.createMessageSenderComponent(components, componentIndex, msg);
        break;

      case 'APITrigger':
        this.createAPITriggerComponent(e, components, componentIndex, msg);
        break;
    }

    this.familyReunion();
    this.updateJourney();
  }

  private createMessageSenderComponent(components: any[], componentIndex: number, msg: MessageClass) {
    components.splice(componentIndex, 0, msg);
  }

  private createWorkflowDelayerComponent(components: any[], componentIndex: number, msg: MessageClass) {
    this.deselectComponent();
    const component = new MessageDelayerClass();
    components.splice(componentIndex++, 0, component);
    components.splice(componentIndex, 0, msg);
    return componentIndex;
  }

  private createAPITriggerComponent(e, components: any[], componentIndex: number, msg: MessageClass) {
    const ender = new WorkflowClass('WorkflowEnder', null);
    const trigger = new WorkflowClass('APITrigger', ender.name);

    if (e.insert) {
      components.splice(componentIndex++, 0, ender);
      //if the current component is workflow delayer start the trigger with a message
      if (e.currentComponent.type === 'WorkflowDelayer') {
        components.splice(componentIndex++, 0, trigger);
        components.splice(componentIndex++, 0, msg);
      } else {
        // only branch where msg is not used
        components.splice(componentIndex++, 0, trigger);
      }
    } else {
      componentIndex++;
      components.splice(componentIndex++, 0, trigger);
      components.splice(componentIndex++, 0, msg);
      components.splice(componentIndex++, 0, ender);
    }
    return componentIndex;
  }

  deleteComponent(msg, force = false) {
    this.autosaveService.disableAutosave();
    this.deleteMessage(msg);
    this.updateJourney();
  }

  deleteOrphans(msg) {
    const components = this.journey.draft.components.filter(c => c.type === 'MessageResponse' && c.parent === msg.name);
    if (components.length) {
      components.forEach((v) => {
        this.deleteMessage(v, true);
      });
    }
    if (!this.deletingFamily) {
      this.familyReunion();
    }
  }

  focusComponent(uiComponent: ElementRef) {
    // Do scrolling on a slight delay to give a possible double click a head start on registering
    // before the component scrolls away from the mouse.
    setTimeout(() => {
      const top = uiComponent.nativeElement.parentElement.getBoundingClientRect().top - 259;
      const left = uiComponent.nativeElement.parentElement.getBoundingClientRect().left - 530;
      const builderEL = <HTMLElement>document.querySelector('.builder-area');
      const y = builderEL.offsetTop + builderEL.scrollTop;
      const x = builderEL.offsetLeft + builderEL.scrollLeft;
      const offsetTop = (top - 80 + y);
      const offsetLeft = (left - 180 + x);
      builderEL.scroll({top: offsetTop, left: offsetLeft, behavior: 'smooth'});
    }, 100);
  }

  deleteMessage(msg, force = false) {
    this.setupDraft();
    this.deselectComponent();
    const prevComp = this.journey.draft.components.find(c => c.to === msg.name);
    const nextComp = this.journey.draft.components.find(c => c.name === msg.to);
    const index = this.journey.draft.components.findIndex(c => c.name === msg.name);
    if ((!force && msg.type === 'MessageResponse') || (msg.type === 'MessageSender' && prevComp && (prevComp.type === 'APITrigger' || prevComp.type === 'WorkflowDelayer'))) {
      const newMsg = MessageClass.deserialize(new MessageClass({showSmsByDefault: this.showSmsByDefault}));
      newMsg.type = msg.type;
      newMsg.step = msg.step;
      newMsg.parent = msg.parent;
      if (msg.type === 'MessageResponse') {
        newMsg.name = msg.name;
      } else {
        if (prevComp) {
          prevComp.to = newMsg.name;
        }
        if (nextComp) {
          newMsg.to = nextComp.name;
        }
      }
      this.journey.draft.components.splice(index, 1, newMsg);

    } else {
      this.journey.draft.components.splice(index, 1);
    }
    if (msg.type === 'MessageSender' || msg.type === 'MessageResponse') {
      this.deleteOrphans(msg);
    } else if (msg.type === 'WorkflowDelayer') {
      //the next component after a delay is alway a message we want
      //to call our self to make sure we delete all the orphans.
      this.deleteMessage(nextComp);
      if (prevComp) {
        prevComp.to = nextComp.to;
      }
    } else if (msg.type === 'APITrigger') {
      this.deletingFamily = true;
      this.families.splice(this.families.findIndex(f => f.name === msg.name));
      const stopFamilyIndex = this.journey.draft.components.findIndex(c => (c.family === msg.name && c.type === 'WorkflowEnder')) + 1;
      while (this.journey.draft.components.find(c => (c.family === msg.name && c.type === 'WorkflowEnder'))) {
        this.deleteMessage(this.journey.draft.components[index], true);
      }
      if (this.journey.draft.components.length === 0) {
        this.journey.draft.components = new Journey({showSmsByDefault: this.showSmsByDefault, meta_data: null}).draft.components;
      }
      this.deletingFamily = false;
      this.familyReunion();
    }
  }

  onDeleteMsg(c) {
    this.messageDialogDeleteMessage.showMessage('Are you sure you want to delete this?', c);
  }

  onNewStep(e) {
    this.isNewStep = true;
    this.createComponent(e);
  }

  onStepDoubleClick(event: any) {
    this.toolbarService.openInspector.emit();
  }

  onEditTriggerRequest() {
    this.toolbarService.openInspector.emit();
  }

  saveJourneyMetadata(journeyContentProperties: JourneyContent) {
    this.autosaveService.disableAutosave();
    this.setupDraft();
    this.journey.draft.updated_at = new Date();
    this.journeyService.updateJourney(this.journeyId, this.journey).subscribe(
      response => {
        this.autosaveService.enableAutosave(this.journey);
        this.titleService.activate('Experience Builder', this.journey.latestContent.name, 'Edit', 'fa fa-pencil', () => {
          this.openEditJourney();
        });
        this.titleService.emitOnShowEditChange(false);
        this.incrementDraftVersion();
        this.journeyInputCopy = Object.assign(new Journey({showSmsByDefault: this.showSmsByDefault, meta_data: null}), this.journey);
      },
      error => {
        this.handleJourneyUpdateError(error);
      }
    );
  }

  closeEditDialog() {
    this.autosaveService.enableAutosave(this.journey);
    this.journey = Object.assign(new Journey({showSmsByDefault: this.showSmsByDefault, meta_data: null}), this.journeyInputCopy);
    this.titleService.emitOnShowEditChange(false);
  }

  selectComponent(event, uiComponent: SelectableJourneyComponent) {
    if (event) {
      event.stopPropagation();
    }
    const selectedCmp = uiComponent.getJourneyComponent();
    if (!this.selectedComponent || this.selectedComponent.name !== selectedCmp.name) {
      this.deselectComponent();
      uiComponent.selected = true;
      this.selectedComponent = selectedCmp;
      this.toolbarComponent.selectedComponent = this.selectedComponent;
      this.focusComponent(uiComponent.getJourneyComponentElementRef());
    }
  }

  deselectComponent() {
    this.messageComponents.forEach(c => {
      c.selected = false;
      c.deselectChildren();
    });
    this.delayComponents.forEach(c => {
      c.selected = false;
    });
    this.triggerComponents.forEach(c => {
      c.selected = false;
    });
    this.selectedComponent = undefined;
    this.toolbarComponent.selectedComponent = undefined;
  }

  reload() {
    if (this.selectedComponent) {
      this.selectedComponentName = this.selectedComponent.name;
    }
    this.journeyService.getJourneyById(this.journeyId).subscribe(
      journey => {
        this.journey = journey;
        this.autosaveService.enableAutosave(this.journey);
        this.journeyInputCopy = Object.assign({}, this.journey);
        this.titleService.activate('Experience Builder', this.journey.latestContent.name, 'Edit', 'fa fa-pencil', () => {
          this.openEditJourney();
        });
        this.families = this.journey.latestContent.components.filter(c => c.type === 'APITrigger');
      },
      error => {
        this.autosaveService.disableAutosave();
        if (error.status !== 401) {
          this.messageDialogNoReload.showMessage('Oops...the experience could not be loaded.');
        }
      });
  }

  handleJourneyUpdateError(error) {
    const errBody = error;
    if (error.status === 409 && errBody.response && errBody.response.indexOf('Mismatch') > -1) {
      this.messageDialogWithReload.showMessage('The experience could not be promoted because a new version is available.');
    } else if (error.status === 409 && errBody.message && errBody.message) {
      this.messageDialogWithReload.showMessage(error.message);
    } else if (error.status !== 401) {
      this.messageDialogWithReload.showMessage(`Oops...The experience could not be updated: ${error.error.response}`);
    }
    if (error.status === 409 && this.toolbarComponent.inspectorActive) {
        this.toolbarComponent.toggleInspector();
    } else if (error.status !== 401) {
      this.messageDialogNoReload.showMessage(`Oops...The experience could not be updated: ${error.error.response}`);
      this.autosaveService.enableAutosave(this.journey);
    }
  }

  setupDraft() {
    if (!this.journey.draft) {
      this.journey.draft = Object.assign(new JourneyContent(), this.journey.live);
    }
  }

  updateJourney(callback?: Function) {
    this.journey.draft.updated_at = new Date();
    this.journeyService.updateJourney(this.journeyId, this.journey).subscribe(
      response => {
        this.autosaveService.enableAutosave(this.journey);
        this.incrementDraftVersion();
        if (callback) {
          callback();
        }
      },
      error => {
        this.handleJourneyUpdateError(error);
      }
    );
  }

  incrementDraftVersion() {
    this.journey.draft_version = this.journey.draft_version + 1;
  }

  /**
   * Returns a function that take a message name and navigates to the routeTemplate either immediately
   * or after the next inspector save (if one is expected).
   *
   * This is necessary to prevent version conflicts when an autosave saves at the same time the
   * message builder is becoming active.  The goal is to ensure that the autosave changes are saved before the
   * message builder starts loading so that the version number loaded by the message builder is the latest.
   */
  navigationFunction(routeTemplate: string) {
    return (msgName: string) => {
      if (this.navigationInProgress) {
        return;
      }

      const navigate = () => {
        const url = routeTemplate.replace('${journeyId}', this.journeyId).replace('${msgName}', msgName);
        this.router.navigateByUrl(url);
      };

      if (this.autosaveService.isSaveNeeded()) {
        if (this.autosaveService.saving) {
          // Wait for current save to complete, then save again, and navigate when that's complete.
          const subscription = this.toolbarService.inspectorSave.subscribe(() => {
            subscription.unsubscribe();
            this.toolbarService.inspectorSave.subscribe(navigate);
            this.autosaveService.save(false);
          });
        } else {
          // Do another save and navigate when complete.
          this.toolbarService.inspectorSave.subscribe(navigate);
          this.autosaveService.save(false);
        }
      } else {
        // No saving to worry about.  Immediately navigate.
        navigate();
      }
      this.navigationInProgress = true;
    };
  }
}
