import { RelaySharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderComponent } from './header/header.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RelaySharedModule,
    MatTooltipModule,
  ],
  declarations: [SideNavComponent, HeaderComponent],
  exports: [HeaderComponent, SideNavComponent],
})
export class NavigationModule {}
