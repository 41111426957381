<div>
  <rn-text-input
    [inputLabel]="'Client contact email'"
    [inputControl]="jobProcessingFormGroup.controls['client_contact_email']"
    [placeholderText]="'Client contact email'"
    (blurred)="checkIfValid($event)"
    [untouchedIfEmpty]="true">
  </rn-text-input>

  <rn-text-input
    [inputLabel]="'CS manager email'"
    [inputControl]="jobProcessingFormGroup.controls['cs_manager_email']"
    [placeholderText]="'CS manager email'"
    (blurred)="checkIfValid($event)"
    [untouchedIfEmpty]="true">
  </rn-text-input>
</div>
