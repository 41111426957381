import React, { Dispatch, SetStateAction, useState, ReactNode } from 'react';

export type UserInfo = {
  /**
   * ID of the current user
   */
  userId: string;
  userRoleIds?: string[];
  userFullName?: string;
};

export type UserInfoContextType = [
  UserInfo,
  Dispatch<SetStateAction<UserInfo>>,
];

export type UserInfoContextProviderProps = {
  children: ReactNode;
} & UserInfo;

const UserInfoContext = React.createContext<UserInfoContextType>([
  {
    userId: '',
    userRoleIds: [],
  },
  () => {},
]);

export function UserInfoProvider({
  userId,
  userRoleIds,
  children,
}: UserInfoContextProviderProps) {
  const [userInfo, setUserInfo] = useState({
    userId,
    userRoleIds,
  });
  return (
    <UserInfoContext.Provider value={[userInfo, setUserInfo]}>
      {children}
    </UserInfoContext.Provider>
  );
}

export const useUserInfo = () => {
  const context = React.useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error(`useUserInfo must be used within a UserInfoProvider`);
  }

  return context;
};
