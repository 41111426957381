import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SecureHttp } from '@app/security/secure-http';
import { LoggerService } from '@app/core/services/logger.service';
import * as _ from 'lodash';
import { SessionService } from '@app/security/session.service';
import { switchMap } from 'rxjs/operators';

export type Status = 'idle' | 'busy' | 'error';
@Injectable()
export class PdfService {
  status: Subject<Status> = new Subject();

  constructor(
    private http: SecureHttp,
    private sessionService: SessionService,
  ) {
    this.status.next('idle');
  }

  /**
   * convert a Unicode string to a string in which each 16-bit unit occupies only one byte
   * @param string
   * @returns string
   */
  toBinary(string: {
    length: Iterable<number>;
    charCodeAt: (arg0: number) => number;
  }) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  b64EncodeUnicode(str: string | number | boolean) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(Number('0x' + p1));
      }),
    );
  }
  /**
   *
   * @param pages
   * @param title file name of the pdf when being downloaded
   * @param options width and height of the page in pixels
   */
  requestPdf(
    pages: HTMLCollectionOf<Element>,
    title: string,
    options?: { width: number; height: number },
  ) {
    this.status.next('busy');
    const documentHTML = new XMLSerializer().serializeToString(document);
    const convertedHtml = this.b64EncodeUnicode(documentHTML);
    const dpi = 96; // converts pixels to inches

    this.sessionService.selectedClient
      .pipe(
        switchMap((clientId) => {
          return this.http.post(
            `${environment.portalURLBase}/client/${clientId}/pdf`,
            {
              html: convertedHtml,
              title: this.parameterize(title),
              options: {
                displayHeaderFooter: false,
                width:
                  (options.width ? options.width : document.body.scrollWidth) /
                  dpi,
                height:
                  (options.height
                    ? options.height
                    : document.body.scrollHeight) / dpi,
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                usePrintCss: true,
              },
            },
          );
        }),
      )
      .subscribe({
        next: (response) => {
          this.status.next('idle');
          window.open(response.FileUrl);
        },
        error: (error) => {
          this.status.next('error');
          LoggerService.log(
            'PdfService',
            `getPdf error: ${JSON.stringify(error)}`,
          );
        },
      });
  }

  private parameterize(str: string): string {
    return `${str.replace(/ /g, '_').toLowerCase()}`;
  }
}
