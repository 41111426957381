export class Clipboard {

  static copy(value: string): void {
    const input = <HTMLInputElement>document.getElementById('copy_input');
    if (input) {
      input.value = value;
      input.select();
      document.execCommand('copy');
    }
  }

  static copyFromElement(elementId: string): void {
    const input = <HTMLInputElement>document.getElementById(elementId);
    if (input) {
      input.select();
      document.execCommand('copy');
    }
  }
}
