import { styled } from 'baseui';

/**
 * A button for a configuration action in the {@link ConfigurationsDisplay}.
 */
export const DisplayAction = styled('button', {
  background: 'none',
  border: 'none',
  color: '#3693BF',
});
