import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agent-message',
  templateUrl: './agent-message.component.html',
  styleUrls: ['./agent-message.component.scss']
})
export class AgentMessageComponent {
  @Input() msg: any; // todo - type this out
}
