<div class="inspector" [class.collapsed-size]="toolbarService.collapsed">
  <div class="header">
    <h3 i18n="Header for experience inspector">Experience</h3>
    <i class="fa fa-times clickable" aria-hidden="true" (click)="close.emit()"></i>
  </div>
  <div class="body">
    <form [formGroup]="journeyForm" (ngSubmit)="onSubmit()" (keydown.enter)="keydownEnter($event)">
      <div class="label" i18n="Label for experience name">Experience Name</div>
      <div id="edit-journey-btn" class="btn btn-sm btn-primary edit" (click)="editJourney()">
        <span class="icon icon-left"><i class="fa fa-pencil" aria-hidden="true"></i></span> <span
        i18n="Label for edit experience button">edit</span>
      </div>
      <div id="journey-name" class="value">{{journey.latestContent.name}}</div>
      <div class="label" i18n="Label for experience ID">Experience ID</div>
      <div id="journey-id" class="value">{{journeyId}}</div>
      <rn-copied-message [inputData]="journeyId"></rn-copied-message>
      <div>
        <div class="label" i18n="Label for product group">Product group</div>
        <div id="product-group-name" class="value">{{journey.latestContent.product_group_name}}</div>
      </div>
      <div>
        <div class="label" i18n="Label for experience campaign">Campaign</div>
        <div id="campaign" class="value">{{journey.latestContent.campaign}}</div>
      </div>
      <div>
        <div class="label" i18n="Label for experience campaign">Inputs</div>
        <div class="input-container">
          <div id="input-fields-{{field}}" class="input" *ngFor="let field of inputParamsArray">{{field}}</div>
        </div>
      </div>
      <hr/>
      <div>
        <div class="label" i18n="Label for experience notes">Notes</div>
        <div>
          <textarea id="journey-notes" [formControlName]="'journeyNotes'"></textarea>
        </div>
      </div>

      <div class="footer-bar">
        <span id="cancelBtn" class="cancel-link" (click)="cancel()">
          <span class="icon"><i class="fa fa-angle-left" aria-hidden="true"></i> </span>
            <span i18n="Cancel the current operation">Cancel</span>
        </span>
        <button id="saveBtn" class="next-link btn btn-primary" (click)="save()" [disabled]="isSaveDisabled()">
          <span i18n="Button heading to save trigger">Save</span>
          <span class="icon icon-right"><i class="fa fa-angle-right"></i></span>
        </button>
      </div>
    </form>
  </div>
</div>
