<div *ngIf="isReady()" class="rn-page">
  
  <rn-text-input [inputControl]="companyTypeIdCtl" [inputLabel]="'Company Type ID'"></rn-text-input>

  <rn-text-input [inputControl]="companyTypeNameCtl" [inputLabel]="'Company Type Name'"></rn-text-input>

  <div class="input-container input-container--multiselect">
    <label>Industries</label>
    <div class="rn-multi-select">
      <mat-form-field class="select" appearance="outline">
          <mat-label>Industries</mat-label>
          <mat-select [formControl]="industryCtl" multiple>
            <mat-option class="select-option" *ngFor="let industry of industryOptions" [value]="industry.id">
              {{industry.title['en-us']}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </div>

  <rn-text-input [inputControl]="templateClientCtl" [inputLabel]="'Client'"></rn-text-input>
  
  <div class="btn-bar">
    <button class="btn btn-secondary cancel-btn"
      type="button" (click)="close()">cancel</button>

    <button class="btn btn-primary save-btn" type="button"
      (click)="saveAndClose()">save and exit</button>
  </div>

</div>

<message-dialog #updateErrorDialog></message-dialog>

<message-dialog #loadErrorDialog (affirm)="close()"></message-dialog>