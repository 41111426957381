import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error) {
        const message = error.message ? error.message : error.toString();        
        if (message.indexOf("'nodeName' of null") > 0 || message.indexOf("Node cannot be null or undefined") > 0) {
            return;
        }
        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
    }

}