import React, { useState, ReactNode } from 'react';

export type CustomerStatus = 'online' | 'offline';

export type CustomerStatusContextType = {
  customerStatus: CustomerStatus;
  setCustomerStatus: (customerStatus: CustomerStatus) => void;
};

export type CustomerStatusContextProviderProps = {
  children: ReactNode;
};

export const CustomerStatusContext = React.createContext<
  CustomerStatusContextType | undefined
>(undefined);

export function CustomerStatusProvider({
  children,
}: CustomerStatusContextProviderProps) {
  const [customerStatus, setCustomerStatus] =
    useState<CustomerStatus>('offline');
  return (
    <CustomerStatusContext.Provider
      value={{ customerStatus, setCustomerStatus }}
    >
      {children}
    </CustomerStatusContext.Provider>
  );
}

export const useCustomerStatus = () => {
  const context = React.useContext(CustomerStatusContext);

  if (context === undefined) {
    throw new Error(
      `useCustomerStatus must be used within a CustomerStatusProvider`,
    );
  }

  return context;
};
