<div class="network-container" >
  <div class="banner-container">
    <img class="banner" [src]="productGroup.branding.banner_s3_url" />
  </div>
  <ng-container *ngIf="productGroup.description || productGroup.contact_us_info.length">
    <div class="product-group-info-trigger"
         [ngStyle]="{'background-color': productGroup.branding.color}"
         (click)="viewProductInfo = !viewProductInfo"
         [ngClass]="{'open':viewProductInfo}">
      <span class="triangle"></span>
    </div>
    <div class="product-group-info" [ngStyle]="{'border-top':  '1px solid '+productGroup.branding.color}">
      <div class="brand">
        <h3 class="brand-name">{{productGroup.name}}</h3>
        <p>{{productGroup.description}}</p>
      </div>
      <div class="contact-info-container" [ngStyle]="{'border-bottom':  '2px solid '+productGroup.branding.color}">
        <div class="contact-info" *ngFor="let contact of productGroup.contact_us_info">
          <p>{{contact.display_text}}</p>
          <p class="contact-value">{{contact.value}}</p>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="wrapper" *ngIf="wire">
    <div class="brand">
      <div class="brand-image" [ngStyle]="{'background-image':  'url(\''+productGroup.branding.icon_s3_url+'\')'}"></div>
      <div class="brand-name">
        <h3>{{productGroup.name}}</h3>
        <p>5/1/2017 11:21am</p>
      </div>
      <div class="brand-options">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
      </div>
    </div>
    <div class="card-phone-summary">
      <div class="block1"></div>
      <div class="block2"></div>
      <div class="block2"></div>
      <div class="block2"></div>
    </div>
    <div class="action" *ngFor="let action of wire['actions']" (click)="doAction(action)">

      <div class="">
        <a *ngIf="action.type == 'hyperlink'" href="{{action.value}}" target="_blank">{{action.label}}</a>
        <a *ngIf="action.type == 'call'" href="tel:{{action.value}}">{{action.label}}</a>
      </div>
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  </div>
  <div class="disclaimer" *ngIf="productGroup.disclaimer.wire_feed_enabled == 'true'" [innerHtml]="productGroup.disclaimer.text | safeHtml">

  </div>
</div>
