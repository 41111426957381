<ng-template #greenNotch>
    <svg width="10" height="10" style="position: absolute; right: calc(50% - 5px); bottom: -10px;">
        <polygon points="0,0 5,5 10,0" style="fill: #abd037; stroke: none;"/>            
        <polyline points="0,0 5,5 10,0" style="fill: none; stroke: #CDCECE; stroke-width: 1;"/>
    </svg>
</ng-template>
<ng-template #experienceLibrary>
    <svg width="10" height="10" style="position: absolute; right: calc(50% - 5px); bottom: -10px;">
    </svg>
</ng-template>
<div class="rnDisplayFlex rnWidth100Percent">
    <div *ngFor="let tab of tabs" id="tab.id" [ngClass]="{'exp-lib-admin-tab': experienceLibraryAdminTabs}"class="tab clickable no-text-selection rnFlex1" [class.active]="currentTabId === tab.id" (click)="onTabClick(tab.id)">
        <span>{{tab.label}}</span>
        <ng-container *ngIf="currentTabId === tab.id && experienceLibraryAdminTabs">
            <ng-container *ngTemplateOutlet="experienceLibrary"></ng-container>
        </ng-container>
                <ng-container *ngIf="currentTabId === tab.id && !experienceLibraryAdminTabs">
                    <ng-container *ngTemplateOutlet="greenNotch"></ng-container>
                </ng-container>
    </div>
</div>