import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import * as _ from 'lodash';

@Directive()
@Injectable()
export class JourneySelectService {

  @Output() journeysCleared: EventEmitter<void> = new EventEmitter<void>();

  // To allow the journeyListComponent to communicate that the "selectTheseJourneys" button has 
  // been clicked to agentJourneySelectComponent, which manages the update to the actual client object.
  @Output() journeySelectionFinalized: EventEmitter<string[]> = new EventEmitter<string[]>(); 

  selectedJourneyIDs: string[];
  init: boolean = false;

  constructor() { }

  clearJourneys(): void {
    this.selectedJourneyIDs = [];
    this.journeysCleared.emit();
  }

  finalizeSelections(): void {
    this.journeySelectionFinalized.emit(this.selectedJourneyIDs);
  }

  journeyIsSelected(journeyId: string): boolean {
    if (_.isEmpty(this.selectedJourneyIDs)) { return false; }
    return !_.isEmpty(this.selectedJourneyIDs.filter((id) => {
      return id === journeyId;
    }));
  }

  setSelectedJourneyIDs(newJourneyIDs: Array<string>) {
    this.selectedJourneyIDs = newJourneyIDs;
  }

  removeJourney(journeyID: string): void {
    this.selectedJourneyIDs = _.pull(this.selectedJourneyIDs, journeyID);
    this.toggleInitOn();
  }

  selectJourneys(journeyIDs: Array<string>): void {
    if (journeyIDs.length === 0) {
      this.clearJourneys();
      return;
    }

    this.selectedJourneyIDs = _.union(this.selectedJourneyIDs, journeyIDs);
  }

  toggleInitOff(): void {
    this.init = false;
  }

  toggleInitOn(): void {
    this.init = true;
  }
}
