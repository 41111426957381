import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ProductGroup } from '../models/client';
import { SmsClass, SmsLinkTypes } from '../models/message';
import { CharacterLimits } from '@app/core/utils/characterLimits';

@Injectable()
export class SmsService {
  productGroup: ProductGroup;
  sms: SmsClass;
  allLinkTypes: SmsLinkTypes;

  constructor() {
    this.allLinkTypes = new SmsLinkTypes();
  }

  init(sms, productGroup) {
    this.sms = sms;
    this.productGroup = productGroup;
  }

  updateAuthLink(newAuthLink) {
    this.sms.auth_link = newAuthLink;
  }

  getFullSmsBody(body, disclaimer, showAuthLink: boolean = true) {
    return `${body}
    ${showAuthLink ? this.getMessageURL() : ''}
    ${disclaimer}`;
  }

  remainingCharacterCount(
    body,
    disclaimer,
    showAuthLink: boolean = true,
    characterMax: number = CharacterLimits.SmsTextCharLimit,
  ): number {
    const fullMessageText = `${body} ${
      showAuthLink ? this.getMessageURL() : ''
    } ${disclaimer}`.trim();
    return characterMax - fullMessageText.length;
  }

  /**
   * Used for previewing sms messages in the message builder.
   */
  getMessageURL(): string {
    let url = '';
    if (!this.isInited()) {
      return url;
    }

    const previewAuthToken = '/r/x373d0295d';
    switch (this.sms.auth_link) {
      case this.allLinkTypes.details.auth_link:
      case this.allLinkTypes.custom_tracked_url.auth_link:
      case this.allLinkTypes.top_of_feed.auth_link:
      case this.allLinkTypes.feed_experience.auth_link:
        if (
          _.isEmpty(this.productGroup) ||
          _.isEmpty(this.productGroup.vanity_url)
        ) {
          url = 'https://my.relayit.com' + previewAuthToken;
        } else {
          url = this.productGroup.vanity_url + previewAuthToken;
        }
        break;
      default:
        url = this.sms.auth_link;
        break;
    }

    return url;
  }

  /**
   * This handles (in the context of saving a message) making sure we substitute the
   * custom input field instead of the original auth link.
   */
  getMappedAuthLink() {
    let authLink: string;
    switch (this.sms.auth_link) {
      case this.allLinkTypes.custom_tracked_url.auth_link:
        authLink = this.allLinkTypes.custom_tracked_url.custom_input_field;
        break;
      default:
        authLink = this.sms.auth_link;
        break;
    }
    return authLink;
  }

  private isInited(): boolean {
    return !(_.isEmpty(this.sms) || _.isEmpty(this.productGroup));
  }
}
