import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Client, ContactInfo, ProductGroup } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { ProductInfoComponent } from '@app/client-config/product-group/components/info/product-info.component';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { TitleService } from '@app/core/services/title.service';
import { FormatUtils } from '@app/core/utils/format-utils';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { CharacterLimits } from '@app/core/utils/characterLimits';


@Component({
  selector: 'app-product-groups-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class ProductGroupsEditComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent) messageDialog: MessageDialogComponent;
  @ViewChild('productGroupInfo') pgInfoForm: ProductInfoComponent;

  productGroup: ProductGroup;
  currentGroupNew: boolean;
  client: Client;
  clientId: string;
  productId: string;
  isFormValid: boolean;
  saving: boolean = false;
  headerGroups = {productGroups: true, inWireConsent: true, onboardingAndValidation: true};
  offset = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              public formBuilder: UntypedFormBuilder,
              private titleService: TitleService,
              private router: Router) {
  }

  ngOnInit() {
    this.getClient();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  cancelCurrent(): void {
    this.router.navigateByUrl(`/client/${this.clientId}/product-group`);
  }

  checkIsValid(): boolean {
    const contactFields = this.productGroup.contact_us_info;
    return !this.validateBasicInfo()
      && (contactFields.length === 0 || (contactFields.length > 0 && this.validateContactInfo(contactFields)))
      && !!this.productGroup.help_instructions
      && this.productGroup.help_instructions.length > 0;
  }

  validateBasicInfo(): boolean {
    let invalid = false;
    // this will return true if invalid
    if ((this.productGroup.name?.length ?? 0) === 0 ||
       (this.productGroup.internal_name?.length ?? 0) === 0 ||
       (this.productGroup.id?.length ?? 0) === 0) {
      invalid = true;
    }

    if (this.client.feed_enabled && this.productGroup.name?.length > CharacterLimits.ProductGroupFeedDisplayNameCharLimit) {
      invalid = true;
    }

    if (this.client.feed_enabled && (this.productGroup.description?.length ?? 0) > CharacterLimits.ProductGroupDescriptionCharLimit) {
      invalid = true;
    }

    return invalid;
  }

  // TODO: take these two validation functions for ContactInfo Class and move them into a ContactInfo validation library
  validateContactTypeValue(i: ContactInfo) {
    switch (i.type) {
      case 'telephone':
        return CustomValidators.phoneNumberOrExtToBeTrimmedRegex.test(FormatUtils.formatPhoneNumber(i.value));
      case 'website':
        return CustomValidators.urlWithQuery.test(i.value);
      case 'email':
        return CustomValidators.emailToBeTrimmedRegex.test(i.value);
      case 'twitter':
        return CustomValidators.twitterRegex.test(i.value);
      default:
        return true;
    }
  }

  validateContactInfo(inputs: Array<ContactInfo>): boolean {
    let pass: boolean = true;

    _.forEach(inputs, input => {
      if (input.value && input.value.length > 0 && input.display_text && input.display_text.length > 0) {
        if (!this.validateContactTypeValue(input)) {
          pass = false;
        }
        if (this.client.feed_enabled && input.display_text.length > CharacterLimits.ProductGroupCustomerContactsLabelCharLimit) {
          pass = false;
        }
      } else if (input.type && (!input.value || !input.display_text)) {
        pass = false;
      }
    });

    return pass;
  }

  private onFailedSave(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(`Oops...could not save client${serverMessage}`);
  }

  updateProductGroup(): void {
    this.productGroup = _.assign(this.productGroup, this.pgInfoForm.getData());
  }

  saveProduct(): void {
    if (!this.saving) {
      this.saving = true;

      if (this.currentGroupNew) {
        this.clientService.createProductGroup(this.client.id, this.productGroup).subscribe(
          response => {
            this.saving = false;
            this.router.navigateByUrl(`/client/${this.clientId}/product-group/${response.id}/legal`);
          },
          (error: HttpErrorResponse) => {
            this.saving = false;
            this.onFailedSave(error);
          });
      } else {
        this.updateProductGroup();
        this.clientService.updateProductGroup(this.client.id, this.productGroup).subscribe(
          response => {
            this.saving = false;
            this.router.navigateByUrl(`/client/${this.clientId}/product-group/${response.id}/legal`);
          },
          (error: HttpErrorResponse) => {
            this.saving = false;
            this.onFailedSave(error);
          });
      }
    }
  }

  private getProductGroup(): void {
    this.clientService.getProductGroup(this.client.id, this.productId).subscribe(
      productGroup => {
        this.productGroup = productGroup;
      },
      error => {
        this.messageDialog.showMessage(`Oops...couldn't get a list of product groups for ${this.client.id}.`);
      }
    );
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientId = params['clientId'];
      this.productId = params['productId'];
      this.clientService.getClient(params['clientId']).subscribe(
        client => {
          this.client = client;
          if (this.productId) {
            this.getProductGroup();
            this.isFormValid = true;
            this.currentGroupNew = false;
          } else {
            this.productGroup = ProductGroup.deserialize(new ProductGroup);
            this.productGroup.branding.icon_s3_url = client.branding.icon_s3_url;
            this.productGroup.branding.banner_s3_url = client.branding.banner_s3_url;
            this.productGroup.branding.color = client.branding.color;
            this.isFormValid = false;
            this.currentGroupNew = true;
          }
        },
        error => {
          this.messageDialog.showMessage(`Oops...Could not load client: ${params['clientId']}`);
        },
        () => {
          this.setPrimaryTitle();
        });
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Edit Product Group - ' + this.client.company_name
        : 'Edit Product Group');
  }
}
