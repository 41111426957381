<label for="sms_body">
  {{newFeed.enabled ? 'Notification Text' : 'Message'}}
</label>
<rn-form-input-state-control
  [formReference]="smsFormGroup.controls['sms_body']"
  [showCharacterCount]="false">
  <ng-container>
    <textarea
    [formControl]="smsFormGroup.controls['sms_body']"
    [mentionConfig]="mentionConfig"
    [mention]="dynamicInputs"
    class="form-control sms_body"
    id="sms_body"
    mentionInput
    name="sms_body"
    rows="3"
    ></textarea>
  </ng-container>
</rn-form-input-state-control>

<div [class.has-error]="authLinkHasError()" class="link-selector">
  <span
    *ngIf="authLinkHasError()"
    class="error-msg"
    placement="bottom"
    tooltip="{{ getTooltipData(smsFormGroup.controls['auth_link'].errors) }}"
  >
    <i aria-hidden="true" class="fa fa-exclamation-circle"></i>
  </span>
  <label for="sms_auth_link">Destination</label>
  <div>
    <div *ngIf="isTwoWaySms" class="link-dropdown link-dropdown--text-only">
      Two Way Conversation
    </div>
    <div class="d-flex justify-content-between">
      <select
        *ngIf="!isTwoWaySms"
        [formControl]="selectedLinkType"
        class="form-control link-dropdown"
      >
        <option
          *ngFor="let linkType of this.linkTypes"
          value="{{ linkType.value }}"
        >
          {{ linkType.display_text }}
        </option>
      </select>

      <!-- readonly input for both none & message details link types -->
      <input
        *ngIf="showReadOnlyIntro()"
        [formControl]="smsFormGroup.controls['auth_link']"
        class="form-control sms-intro"
        name="sms_auth_link"
        readonly
        type="text"
      />

      <!-- editable input for url link type -->
      <input
        *ngIf="!showReadOnlyIntro()"
        [formControl]="smsFormGroup.controls['auth_link']"
        [mentionConfig]="mentionConfig"
        [mention]="dynamicInputs"
        class="form-control sms-intro"
        mentionInput
        name="sms_auth_link"
        type="text"
      />
    </div>
  </div>
</div>

<div
  [ngClass]="{
    'has-error':
      !!smsFormGroup.controls['sms_disclaimer'] &&
      !smsFormGroup.controls['sms_disclaimer'].valid &&
      smsFormGroup.controls['sms_disclaimer'].touched
  }"
>
  <span
    *ngIf="showErrorToolTip()"
    class="error-msg"
    placement="bottom"
    tooltip="{{
      getTooltipData(smsFormGroup.controls['sms_disclaimer'].errors)
    }}"
  >
    <i aria-hidden="true" class="fa fa-exclamation-circle"></i>
  </span>
  <label for="sms_disclaimer">Disclaimer</label>
  <input
    [formControl]="smsFormGroup.controls['sms_disclaimer']"
    [mentionConfig]="mentionConfig"
    [mention]="dynamicInputs"
    class="form-control sms-disclaimer"
    id="sms_disclaimer"
    name="sms_disclaimer"
    type="text"
  />
  <div *ngIf="smsCharacterCount() < 0" class="warning-text">
    Multiple {{ newFeed.enabled ? 'notifications' : 'messages' }} will be sent
    to satisfy limit
  </div>
</div>
<div>
  use &#64; to get the dynamic fields.
  <div class="pull-right">
    <div id="sms-character-counter">
      <b>{{ smsCharacterCount() }}</b> of <b>{{ maxCharacterCount }}</b> chars
      remaining
    </div>
  </div>
</div>
<br />

<div
  [ngClass]="{
    'alternate-message': true,
    'has-error': !smsFormGroup.controls['alternate_sms_text'].valid
  }"
>
  <app-radio-btn
    [(btnModel)]="smsModel.is_alternate_message"
    [btnLabel]="
      newFeed.enabled ? 'Alternate Notification Text' : 'Alternate SMS Text'
    "
    [btnName]="'has-alternate-sms'"
    [btnWidth]="'100%'"
    [btnDisplayTrueFirst]="true"
    (btnModelChange)="toggleAlternateSMS()"
  ></app-radio-btn>

  <!-- textarea -->
  <ng-container *ngIf="smsModel.is_alternate_message">
    <span
      *ngIf="showAlternateInputError()"
      class="error-msg"
      placement="bottom"
      tooltip="{{
        getTooltipData(smsFormGroup.controls['alternate_sms_text'].errors)
      }}"
    >
      <i aria-hidden="true" class="fa fa-exclamation-circle"></i>
    </span>

    <textarea
      [formControl]="smsFormGroup.controls['alternate_sms_text']"
      [mentionConfig]="mentionConfig"
      [mention]="alternateInputs"
      class="form-control"
      mentionInput
      name="alt-sms"
    ></textarea>
    <p>include {{ '@{auth-link}' }} and any disclaimer</p>
  </ng-container>
</div>
<br />
