import { Ordering, Direction } from '../../core/utils/ordering';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-column-sort-icon',
  templateUrl: './column-sort-icon.component.html',
  styleUrls: ['./column-sort-icon.component.scss']
})
export class ColumnSortIconComponent implements OnInit {

  @Input() ordering: Ordering;
  @Input() fieldName: string;
  dir = Direction;
  
  constructor() { }

  ngOnInit() {
  }
}
