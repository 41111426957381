<div class="stop-container">
  <div class="hex" [class.show]="showHexButtons">
    <img class="hex-btn conditions" [class.expand]="showHexButtons" src="assets/hex/Picker-Trigger.png" (click)="addStep('APITrigger')"/>
    <img class="hex-btn delay" [class.expand]="showHexButtons" src="assets/hex/Picker-Delay.png" (click)="addStep('WorkflowDelayer')" />
    <img class="cancel-btn" src="assets/hex/icon-delay.png" (click)="showHexButtons = false"/>
    <img class="wrapper-img" src="assets/hex/Picker-HexFrame.png" />
  </div>
  <div class="hex-root stop">
    <img src="assets/hex/hex-stop.png" (click)="showHexButtons = true" />
  </div>
</div>