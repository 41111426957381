import React from 'react';
import { useStyletron } from 'styletron-react';
import { Button } from 'baseui/button';
import { ConfigurationFooter } from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import {
  ButtonOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { SfdcAppExchangeConfiguration, SfdcConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { CertificateDownload } from '@app/api-mgmt/components/push-api/components/certificate-modal/CertificateDownload';

export const CertificateModal = ({
  clientId,
  integration,
  onCancelClicked,
}: {
  clientId?: string,
  integration?: SfdcConfiguration | SfdcAppExchangeConfiguration;
  onCancelClicked?: () => void;
}) => {
  const [css] = useStyletron();

  const onClose = () => {
    onCancelClicked();
  };

  return (
    <>
      <Modal
        isOpen
        onClose={onClose}
        overrides={{
          Dialog: {
            style: {
              marginTop: '75px',
              width: '45%',
            },
          },
        }}
      >
        <ModalHeader style={ModalHeaderOverride.style}>
          Add New Push Integration
        </ModalHeader>
        <ModalBody>
          <div
            className={css({
              margin: 'auto',
            })}
          >
            <CertificateDownload
              publicKey={integration.public_key} 
              integrationName={integration.name}
              clientId={clientId}/>
            <ConfigurationFooter>
              <div style={{ float: 'right' }}>
                <Button
                  type="reset"
                  onClick={onClose}
                  overrides={{
                    BaseButton: ButtonOverride,
                  }}
                >
                  <span>OK</span>
                </Button>
              </div>
            </ConfigurationFooter>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
