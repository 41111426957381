import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { SharedDateTimeService, TimeWindowHour } from '@app/core/services/shared-datetime.service';

@Component({
  selector: 'app-time-window-select',
  templateUrl: './time-window-select.component.html',
  styleUrls: ['./time-window-select.component.scss']
})
export class TimeWindowSelectComponent implements OnInit {
  @Input() startTime: string;
  @Input() endTime:   string;
  @Input() startTimeLabel: string;
  @Input() endTimeLabel: string;

  @Output() startTimeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() endTimeChange: EventEmitter<string> = new EventEmitter<string>();

  hours: TimeWindowHour[];

  constructor(public sharedDateTimeService: SharedDateTimeService) {
    this.hours = this.sharedDateTimeService.getTimeWindowHours();
  }

  ngOnInit() {
  }

  onTimeWindowStartChange(event: string) {
    this.startTimeChange.emit(event);

    if (this.startTime === this.endTime) {
      this.endTime = this.sharedDateTimeService.getNextTimeWindowHour(this.startTime);
      this.endTimeChange.emit(this.endTime);
    }
  }

  onTimeWindowEndChange(event: string) {
    this.endTimeChange.emit(event);

    if (this.startTime === this.endTime) {
      this.startTime = this.sharedDateTimeService.getPreviousTimeWindowHour(this.endTime);
      this.startTimeChange.emit(this.startTime);
    }
  }
}
