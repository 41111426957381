// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';

// Data Models
import { ProductGroup } from '../../../../core/models/client';

@Component({
  selector: 'app-product-contact-info-row',
  templateUrl: './product-contact-info-row.component.html',
  styleUrls: ['./product-contact-info-row.component.scss']
})
export class ProductContactInfoRowComponent implements OnInit {
  @Input() product: ProductGroup;

  constructor() { }

  ngOnInit() {
  }

}
