<div class="root-container">
    <h3>Short Code Response Messages</h3>
    <p>When a Relay managed short code receives the keywords "stop" or "help" from an <i>onboarded phone number</i>, it responds with the <i>Client</i> configured "stop" or "help" response.</p>
    <p>When a Relay managed short code receives the keywords "stop" or "help" from an <i>unknown phone number</i>, it responds with the <i>Short Code</i> configured "stop" or "help" response.</p>
    <p>Enter a Short Code to review/edit existing response language or to add new response language.</p>
    <div class="controls-container">
        <div class="shortcode-search-container">
            <label>Short Code:</label>
            <input [formControl]="shortcodeSearchControl" name="shortCodeSearchControl" type="text">
            <button (click)="onSearchClick()" [disabled]="isSearchButtonDisabled()">Search</button>
        </div>
        <div *ngIf="isShortCodeResponseDisplayVisible()" class="response-container">
            <ng-container>
                <div *ngFor="let formGroup of this.shortcodeResponsesArray.controls">
                    <rn-keyword-response-component [keywordGroup]="formGroup"></rn-keyword-response-component>
                </div>
            </ng-container>
        </div>
        <div class="button-container">
            <button (click)="onSaveClick()" *ngIf="isSaveButtonVisible()"
                    [disabled]="isSaveButtonDisabled()">Save
            </button>
            <button (click)="onResetClick()">Reset</button>
        </div>
    </div>
</div>
<message-dialog #saveConfirmationDialog>
</message-dialog>
