import { SessionService } from './session.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Prevents a logged-in user from visiting the login page.
 */
@Injectable()
export class LoginGuardService  {

  constructor(private sessionService: SessionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.sessionService.loggedIn$.pipe(
      map((loggedIn) => !loggedIn)
    );
  }
}
