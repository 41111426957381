<div *ngIf="showDialog" class="dialog-background">
    <div class="schedule-launch-dialog dialog-content">
        <div class="title-bar">
            <span class="pull-left">{{title}}</span>
            <i id="close-dialog-icon" class="fa fa-times close-icon pull-right clickable" (click)="close()"></i>
        </div>
        
        <div class="dialog-body">
            <p class="instructions">
                <span>Set the Time and Date you would like this {{thingBeingLaunched}} to start.</span>
                <ng-container *ngIf="timeZone else genericTimeZone">
                    <span i18n="Scheduled launch time zone information"> Times are in {{timeZone}} Time.</span>
                </ng-container>
                <ng-template #genericTimeZone>
                    <span i18n="Scheduled launch time zone information - generic"> Times are in local time.</span>
                </ng-template>
            </p>
            <div class="calendar-header">
                <button id="now-btn" class="btn btn-primary" i18n="Label for button to set experience launch time to now" (click)="now()">Now</button>
            </div>
            <owl-date-time-inline id="schedule-time-picker"
                [(ngModel)]="scheduleTime"
                (ngModelChange)="handleModelChange(scheduleTime)"
                [hour12Timer]="true">
            </owl-date-time-inline> 
            <br>
            <br>
            <p class="instructions invalid-input" *ngIf="showValidTimeError">
                <strong>Invalid entry. The date/time selected must fall after the current day and time.</strong>
            </p>
            <br>
            <button id="set-launch-btn" class="btn btn-warning btn-launch pull-right" (click)="setLaunch()">
                <ng-content select="[schedule-button-content]"></ng-content>
            </button>
            <div class="clearfix"></div>
        </div>
    </div>
</div>