import { JourneyListModule } from '../journey-list/journey-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelaySharedModule } from '../shared/shared.module';
import { JourneyLaunchedComponent } from './journey-launched/journey-launched.component';
import { WorkflowLauncherComponent } from './workflow-launcher/workflow-launcher.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TestLauncherComponent } from './test-launcher/test-launcher.component';
import { TriggerSelectorComponent } from './trigger-selector/trigger-selector.component';
import { CxBuilderModule } from '../cx-builder/cx-builder.module';
import { LauncherListComponent } from './launcher-list/launcher-list.component';
import { InputParamsComponent } from './input-params/input-params.component';
import { QuickLaunchComponent } from './quick-launch/quick-launch.component';
import { FileLauncherModule } from '@app/file-launcher/file-launcher.module';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatContextService } from './test-launcher/chat-context.service';

@NgModule({
  imports: [
    CommonModule,
    CxBuilderModule,
    FormsModule,
    JourneyListModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    RelaySharedModule,
    RouterModule,
    FileLauncherModule,
    TabsModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTooltipModule,
  ],
  providers: [ChatContextService],
  declarations: [
    InputParamsComponent,
    JourneyLaunchedComponent,
    LauncherListComponent,
    TestLauncherComponent,
    TriggerSelectorComponent,
    WorkflowLauncherComponent,
    QuickLaunchComponent,
  ],
  exports: [LauncherListComponent, WorkflowLauncherComponent],
})
export class LauncherModule {}
