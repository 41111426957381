import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Customer } from '@app/core/models/customer';

@Component({
  selector: 'app-input-params',
  templateUrl: './input-params.component.html',
  styleUrls: ['./input-params.component.scss']
})
export class InputParamsComponent implements OnInit {
  private suspendEvents: boolean;
  @Input() inputParams: Array<string>;
  @Input() selectedUser: Customer;
  @Output() statusChanged: EventEmitter<void> = new EventEmitter<void>();
  enteredInputGroup: UntypedFormGroup;
  enteredInputParams = new Object();
  disabledParams = ['CCID', 'account_firstname', 'account_lastname', 'account_product_group', 'account_secondary_account_id'];

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.setInitialValues();
  }

  isInputDisabled(inputParam: string) {
    if (this.disabledParams.indexOf(inputParam) >= 0 || inputParam.startsWith('ext_')) {
      return true;
    } else {
      return false;
    }
  }

  onKeyUp(e) {
    this.statusChanged.emit();
  }

  setInitialValues(params = new Object()) {
    const controlsConfig = new Object();
    this.enteredInputParams = params;

    for (let i = 0; i < this.inputParams.length; i++) {
      const inputParam = this.inputParams[i];
      let value = this.enteredInputParams[inputParam] || null;
      if (inputParam === 'CCID') {
        value = this.selectedUser.ccid;
      } else if (inputParam === 'account_firstname') {
        value = this.selectedUser.first_name;
      } else if (inputParam === 'account_lastname') {
        value = this.selectedUser.last_name;
      } else if (inputParam === 'account_secondary_account_id') {
        value = this.selectedUser.secondary_account_id;
      } else if (inputParam.startsWith('ext_')) {
        if (this.selectedUser.ext) {
          const propertyName = inputParam.substr(4, inputParam.length - 4);
          value = this.selectedUser.ext[propertyName];
        }
      }

      controlsConfig[inputParam] = [value, Validators.required];
      this.enteredInputParams[inputParam] = value;
    }
    this.enteredInputGroup = this.formBuilder.group(controlsConfig);
    this.enteredInputGroup.valueChanges.subscribe((res) => Object.assign(this.enteredInputParams, res));
  }

  isValid() {
    return this.enteredInputGroup.valid;
  }

  resetForm() {
    this.enteredInputParams['CCID'] = '';
    this.enteredInputGroup.controls['CCID'].setValue('');
  }
}
