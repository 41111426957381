<div class="input-container">
    <ng-container *ngIf="client.onboarding.csr.capture_written_consent === 'true'">
        <p>Express written consent terms and conditions</p>
        <i *ngIf="!consentFormGroup.controls['written_consent_language'].valid && consentFormGroup.controls['written_consent_language'].touched" class="fa fa-exclamation-circle textarea-width" aria-hidden="true" tooltip="{{getTooltipData(inputControl?.errors)}}"></i>
        <textarea [formControl]="consentFormGroup.controls['written_consent_language']"
                [ngClass]="{'has-error': !consentFormGroup.controls['written_consent_language'].valid && consentFormGroup.controls['written_consent_language'].touched}" 
                placeholder="Please enter consent terms and conditions"></textarea>
    </ng-container>

    <ng-container *ngIf="client.onboarding.csr.capture_written_consent === 'false'">
        <p>Express consent terms and conditions</p>
        <i *ngIf="!consentFormGroup.controls['express_consent_language'].valid && consentFormGroup.controls['express_consent_language'].touched" class="fa fa-exclamation-circle textarea-width" aria-hidden="true" tooltip="{{getTooltipData(inputControl?.errors)}}"></i>
        <textarea [formControl]="consentFormGroup.controls['express_consent_language']"
                [ngClass]="{'has-error': !consentFormGroup.controls['express_consent_language'].valid && consentFormGroup.controls['express_consent_language'].touched}" 
                placeholder="Please enter consent terms and conditions"></textarea>
    </ng-container>
</div>