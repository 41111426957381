import React from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ChevronRight, Check } from 'baseui/icon';
import { FilterClosedReasonProps } from '../../relay-messenger-list/RelayMessenger.list';
import { FilterMethods } from '../../relay-messenger-conversations/RelayMessengerConversations';
import { FilterVariantButton } from './FilterVariantButton';
import { useClosedReasons } from '@app/two-way/relay-messenger/context/ClosedReasonsContext';

interface FilterClosedReasonItemProps {
  filterClosedReason: FilterClosedReasonProps;
  close: () => void;
  clearFilterState: () => void;
  clearSortingState: () => void;
}

export const FilterClosedReasonAction = ({
  filterClosedReason,
  close,
  clearFilterState,
  clearSortingState,
}: FilterClosedReasonItemProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const { isClosedReasonsError, closedReasons } = useClosedReasons();

  return (
    <StatefulPopover
      content={() => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          {closedReasons &&
            closedReasons.map((reason, index) => (
              <FilterVariantButton
                key={index}
                value={reason.reason}
                filterOptionClicked={
                  filterClosedReason?.filterOptionClosedReasonClicked
                }
                filterByOption={filterClosedReason?.filterByClosedReason}
                setFilterByOption={filterClosedReason?.setFilterByClosedReason}
                close={close}
                dataTestId={`filter-closed-reason-${index}`}
              />
            ))}
          {isClosedReasonsError && !closedReasons && (
            <Block
              className={css({
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              })}
            >
              There was a problem loading Closed Reasons. Please try again
              later.
            </Block>
          )}
        </Block>
      )}
      placement={PLACEMENT.rightTop}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          onClick={() => {
            filterClosedReason?.setFilterOptionClosedReasonClicked(
              FilterMethods.ClosedReason,
            );
            clearFilterState();
            clearSortingState();
          }}
          value={FilterMethods.ClosedReason}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: filterClosedReason?.filterOptionClosedReasonClicked
                  ? '#3693BF'
                  : 'black',
                backgroundColor:
                  filterClosedReason?.filterOptionClosedReasonClicked
                    ? '#E1EFF5'
                    : 'transparent',
                fontWeight: filterClosedReason?.filterOptionClosedReasonClicked
                  ? 'bolder'
                  : 'inherit',
                textAlign: 'center',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'filter-closed-reason-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            filterClosedReason?.filterByClosedReason ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {FilterMethods.ClosedReason}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
