<div class="journey-launched-box">
  <div class="journey-launched-icon"></div>
  <h3 *ngIf="errors < recipients || fileJobSuccess" i18n="First part of message shown when an experience is successfully launched">All set!</h3>
  <ng-container *ngIf="!scheduleTime">
    <h3 *ngIf="errors < recipients || fileJobSuccess" i18n="Second part of message show when an experience is successfully launched">Your experience is on its way!</h3>
    <h3 *ngIf="recipients && errors">Error count: {{ errors }} of {{ recipients }}</h3>
  </ng-container>
  <ng-container *ngIf="scheduleTime">
    <h3 i18n="Second part of message show when an experience is successfully scheduled">Your experience will launch</h3>
    <h3 id="schedule-time">{{ scheduleTime | date: 'short' }}</h3>
  </ng-container>
</div>
<br/>
<div>
  <div *ngIf="leftButtonLabel" class="pull-left">
    <div class="btn btn-lg btn-primary" (click)="leftButtonClick.emit()" id="left-button">
      {{leftButtonLabel}}
    </div>
  </div>
  <div *ngIf="rightButtonLabel" class="pull-right">
    <div class="btn btn-lg btn-primary" (click)="rightButtonClick.emit()" id="right-button">
      {{rightButtonLabel}}
    </div>
  </div>
</div>
