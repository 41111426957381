import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { WorkflowClass } from '../../core/models/message';

@Component({
  selector: 'journey-arrow',
  templateUrl: './journey-arrow.component.html',
  styleUrls: ['./journey-arrow.component.scss']
})
export class JourneyArrowComponent implements OnInit {
    @Input() arrowType: string;
    @Input() nextMessage: any = new WorkflowClass(null, null);
    @Output() onNewStep = new EventEmitter;    
    showHexButtons: boolean;
    id = Math.random();
  constructor() { }

  ngOnInit() {

  }
  addStep(type: string) {
      this.showHexButtons = false;

      this.onNewStep.emit({
          type: type,
          currentComponent: this.nextMessage,
          insert: true
      });
  }
}
