<div class="list-row">
<div class="row customer"  [ngClass]="customer.deactivated && 'deactivated'" (click)="onRowClick()">
  <div class="colw colw-20 name">
    {{customer.name.toLowerCase()}}
  </div>
  <div class="colw colw-20">
    {{customer.ccid}}
  </div>
  <div class="colw colw-20">
    <div *ngFor="let consent of consents">
      <span *ngIf="consent.channel_type === 'sms'">{{formatPhoneNumber(consent.channel_addr)}}</span>
    </div>
  </div>
  <div class="colw colw-20">
    {{customer.product_group_id}}
  </div>
  <div class="colw colw-16 action-column">
    <button class="btn btn-sm btn-primary" id="send_journey" *ngIf="customerCanReceiveMessage()"
      (click)="launchJourney(customer)">
      <span i18n="Button for sending an experience">Send Experience</span>
      <span class="icon icon-right"><i class="fa fa-paper-plane-o"></i></span>
    </button>
    <i *ngIf="!displayDetails" class="fa fa-caret-right expand-collapse"></i>
    <i *ngIf="displayDetails" class="fa fa-caret-down expand-collapse"></i>
  </div>
</div>
<div class="row detail customer-details-container" *ngIf="displayDetails">
  <app-spinner *ngIf="isLoadingDetails"></app-spinner>
  <ng-container *ngIf="!isLoadingDetails">
    <ng-container *ngIf="!canViewPhoneMgmtEdit">
      <div class="customer-details">
        <button *ngIf="permissionService.checkPermissions(permissions.roc_customer_view)" class="btn btn-sm"
          (click)="goToCustomerDetails(customer.ccid)">Customer Details
          <span i18n="Button for viewing customer details"></span>
          <span class="icon icon-right"><i class="fa fa-"></i></span>
        </button>
      </div>
      <div class="channels" *ngFor="let consent of consents;let i = index">
        <div *ngIf="i==0" class="channel">
          <span>Channel</span><span>Current consent type</span>
        </div>
        <div class="channel" *ngIf="consent.channel_type === 'sms'">
          <span>{{consent.channel_addr | phoneNumber}}</span>
          <span> {{consent.consent_type}}</span>
          <button class="btn btn-sm btn-warning" id="stop_consent"
            *ngIf="canEditConsent && consent.consent_type !== 'stop'"
            (click)="confirmStopConsent(customer, consent, $event)">
            <span i18n="Button for stopping consent">Stop Consent</span>
            <span class="icon icon-right"><i class="fa fa-hand-paper-o"></i></span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="canViewPhoneMgmtEdit">
      <div class="pm-edit-send-journey col-12" *ngIf="permissionService.checkPermissions(permissions.roc_customer_view)">
        <a href="javascript:void(0)" (click)="goToCustomerDetails(customer.ccid)">Customer Details</a>
      </div>
      <div class="phone-management-edit col-12" *ngIf="!isLoadingDetails">
        <app-customer-consent-list [customerConsents]="consents"
          (phoneNumberAdded)="onCustomerChannelAdd(customer.id, customer.ccid, $event)"
          (phoneNumberDeleted)="onCustomerChannelDelete(customer.id, customer.ccid, $event)"
          (consentUpdated)="onCustomerConsentChange(customer.id, customer.ccid, $event)"></app-customer-consent-list>
      </div>
    </ng-container>
  </ng-container>
</div>
</div>

<message-dialog #stopConsentDialog (affirm)="stopConsent($event)" [showCancel]="true"></message-dialog>
<message-dialog #serverErrorDialog (affirm)="serverErrorDialog.close()"></message-dialog>
