import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ClientService } from '@app/core/services/client.service';
import { Client } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { TitleService } from '@app/core/services/title.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { FeatureService } from '@app/core/services/feature.service';
import * as _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SmsResponseLanguage } from '@app/core/models/sms-response-types';
import { SmsCustomKeyword } from '@app/core/models/sms-custom-keyword';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { LanguageEnum } from '@app/core/models/language.enum';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
  selector: 'app-client-inputs-new',
  templateUrl: './client-inputs-new.component.html',
  styleUrls: ['./client-inputs-new.component.scss'],
})
export class ClientInputsNewComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @Input() client: Client;

  shortCode: string;
  stopKeyword: SmsResponseLanguage;
  helpKeyword: SmsResponseLanguage;
  unrecognizedKeyword: SmsResponseLanguage;
  maxLength: number;
  inputs: Array<Object>;

  headerGroups = {
    messageInputs: true,
    feUpload: true,
    roleMapping: true,
    clientHelpsite: true,
    asciiArmor: true,
    clientShortCodeLongCode: true,
  };

  featureFlags = FeatureFlags;
  dataInputsArray: UntypedFormArray;
  ssoRoleMapsArray: UntypedFormArray;
  shortCodeControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.digitsOnly,
  ]);
  shortCodeGroup: UntypedFormGroup = new UntypedFormGroup({
    short_code: this.shortCodeControl,
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private titleService: TitleService,
    private router: Router,
    public featureService: FeatureService,
    public newFeed: NewFeedTransitionService,
  ) {
    this.client = new Client();
  }

  ngOnInit() {
    this.getClient();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  addDataInput(dataInput: string = 'input_'): void {
    const dataInputValidator = Validators.pattern(/^(input_[a-zA-Z0-9-_.]*$)/);
    this.dataInputsArray.push(
      new UntypedFormControl(dataInput, [
        dataInputValidator,
        CustomValidators.isUnique(this.dataInputsArray),
      ]),
    );
  }

  removeDataInput(index): void {
    this.dataInputsArray.removeAt(index);
  }

  /**
   * SSO Role Maps Form
   */

  initSSORoleMapsForm(client: Client): void {
    this.ssoRoleMapsArray = new UntypedFormArray([]);
    _.each(client.sso_config.role_id_value_map, (roleMap) => {
      this.addSSORoleMap(roleMap);
    });
  }

  addSSORoleMap(
    roleMap = { client_role: 'client_role', relay_role: 'relay_role' },
  ): void {
    this.ssoRoleMapsArray.push(
      new UntypedFormGroup({
        client_role: new UntypedFormControl(roleMap.client_role),
        relay_role: new UntypedFormControl(roleMap.relay_role),
      }),
    );
  }

  removeSSORoleMap(index): void {
    this.ssoRoleMapsArray.removeAt(index);
  }

  /**
   * Form Utils + Validation
   */

  trackInput(index) {
    return index;
  }

  isInited() {
    return this.dataInputsArray && this.ssoRoleMapsArray;
  }

  isValid() {
    return (
      this.isInited() &&
      this.dataInputsArray.valid &&
      this.ssoRoleMapsArray.valid &&
      this.shortCodeGroup.valid
    );
  }

  /**
   * Return language options for Unrecognized response. Only support English response
   */
  get languageOptionsForUnrecognizedResponse(): string[] {
    return [LanguageEnum.ENGLISH];
  }

  /**
   * Saving
   */

  saveAndContinue(): void {
    this.updateModel();

    forkJoin({
      clientSaveResult: this.clientService.updateClient(this.client),
      shortCodeResult: this.clientService.updateClientShortCode(
        this.client.id,
        this.shortCode,
      ),
    })
      .pipe(
        switchMap((mainResult) => {
          // only update the sms keywords if the shortCodeResult is successful
          if (mainResult.shortCodeResult) {
            const keywords = this.getSmsKeywords(this.shortCodeGroup.value);
            return this.clientService
              .updateCustomSMSKeywords(this.client.id, this.shortCode, keywords)
              .pipe(
                map((keywordResult) => ({
                  clientShortCode: mainResult,
                  customKeywords: keywordResult,
                })),
              );
          } else {
            // just return the clientShortCode combo result
            return of({ clientShortCode: mainResult, customKeywords: '' });
          }
        }),
      )
      .subscribe(
        (finalResult) => {
          this.onSuccessfulSave(finalResult.clientShortCode.clientSaveResult);
        },
        (err: HttpErrorResponse) => this.onFailedSaved(err),
      );
  }

  /**
   * Other
   */

  previousStep(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/edit/validation`);
  }

  toggleHeader(headerGroup: string) {
    if (this.headerGroups[headerGroup] !== undefined) {
      this.headerGroups[headerGroup] = !this.headerGroups[headerGroup];
    }
  }

  /**
   * Data Inputs Form
   */

  private initDataInputsForm(client: Client): void {
    this.dataInputsArray = new UntypedFormArray([]);
    _.each(client.ext_dynamic_fields, (dataInput) => {
      this.addDataInput(dataInput);
    });
  }

  private updateModel() {
    this.client.ext_dynamic_fields = this.dataInputsArray.getRawValue();
    this.client.sso_config.role_id_value_map =
      this.ssoRoleMapsArray.getRawValue();
    this.shortCode = this.shortCodeControl.value;
  }

  private onSuccessfulSave(res: Client): void {
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/onboarding`);
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(
      `Oops...could not save client: ${serverMessage}`,
    );
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      const clientId = params['clientId'];
      forkJoin({
        client: this.clientService.getClient(clientId),
        smsShortCode: this.clientService
          .getClientShortCode(clientId)
          .pipe(catchError((error) => of({ shortcode: '', error: error }))), // can fail if no shortcode exists
      })
        .pipe(take(1))
        .subscribe(
          ({ client, smsShortCode }) => {
            this.client = client;
            this.shortCode = smsShortCode.shortcode;
            this.stopKeyword = client.sms_keywords.stop;
            this.helpKeyword = client.sms_keywords.help;
            this.unrecognizedKeyword = client.sms_keywords.unrecognized;
            this.maxLength = client.sms_max_length;

            this.initDataInputsForm(client);
            this.initSSORoleMapsForm(client);
            this.shortCodeControl.patchValue(this.shortCode);
          },
          () => {
            this.messageDialog.showMessage(
              `Oops...Could not load client: ${params['clientId']}`,
            );
          },
          () => {
            this.setPrimaryTitle();
          },
        );
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Client - ' + this.client.company_name
        : 'Configure Client',
    );
  }

  onMaxLengthChanged(event: Event) {
    // @ts-ignore
    this.maxLength = event.target.value;
  }

  onShortCodeChange(event: Event) {
    // @ts-ignore
    this.shortCode = event.target.value;
  }

  /**
   * Translates from the data held in FormControl back to an array of objects
   * to then translate to send to the service.
   * @param value The output of a .value call on the FormArray owned by the control.
   * @private
   */
  private getSmsKeywords(value: any): SmsCustomKeyword[] {
    return value.custom_sms_keywords
      .filter((keyword) => {
        return keyword.checked;
      })
      .map((keyword) => {
        const csk = keyword.custom_sms_keyword;
        return SmsCustomKeyword.deserialize(csk.keyword, csk);
      });
  }
}
