// TODO: this is 90% similar to the tab-bar component which is used in 
// the performance and monitor components.  Good candidate for consolidation?
import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'rn-progress-tabs',
  templateUrl: './progress-tabs.component.html',
  styleUrls: ['./progress-tabs.component.scss']
})

export class ProgressTabsComponent {
  @Input() tabs: { id: string, text: string }[];
  @Input() selectedTabId: string;
  @Input() clickable: boolean = false;
  @Output() tabClicked: EventEmitter<String> = new EventEmitter();

  constructor() { }

  isActive(tabId: string): boolean {
    return this.selectedTabId === tabId;
  }

  tabIdAttr(tabId: string): string {
    return `status-tab--${tabId}`;
  }

  triggerTabClicked(tabId: string): void {
    if (this.clickable) {
      this.tabClicked.emit(tabId);
      this.selectedTabId = tabId;
    }
  }
}
