<!-- Row View -->
<ng-container *ngIf="messageView === 'normal'">
  <div *ngIf="fetchingAsset">
    <app-spinner></app-spinner>
  </div>
  <div class="col-sm-12" *ngIf="!fetchingAsset">
    <p class="journey-description">{{journeyContent.description}}</p>
    <div class="message-container">
      <p *ngIf="copyType === 'message'">Please select a message to copy:</p>
      <ul [ngStyle]="{'width.px': ulWidth}">
        <li *ngFor="let step of filteredComponents">
          <journey-step-preview
            [step]="step"
            [selectionType]="selectionType"
            [copyType]="copyType"
            [copyMsgParams]="copyMsgParams ? copyMsgParams : null">
          </journey-step-preview>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<!-- Grid View -->
<ng-container *ngIf="messageView === 'grid'">
  <div *ngIf="fetchingAsset">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!fetchingAsset">
    <p class="journey-description">{{journeyContent.description}}</p>
    <div class="container-fluid" class="grid-details">
      <div class="row">
        <div class="col-sm-2">Nickname</div>
        <div class="col-sm-2 center">Date Created</div>
        <div class="col-sm-2 center">Date Modified</div>
        <div class="col-sm-3 center">ID</div>
      </div>
      <ng-container *ngFor="let step of journeyContent.components">
        <ng-container
          *ngIf="(step.type === 'MessageSender') || (step.type === 'MessageResponse') || (step.type === 'APITrigger')">
          <div class="row" [class.header-row]="step.type === 'APITrigger'">
            <div class="col-sm-2 message-name" [class.indent]="step.type !== 'APITrigger'">{{step.nickname}}<span
              *ngIf="step.type === 'APITrigger'" class="trigger-icon">&nbsp;</span></div>
            <div class="col-sm-2 center">{{step.date_created | date:'shortDate'}}</div>
            <div class="col-sm-2 center">{{step.date_modified | date:'shortDate'}}</div>
            <ng-container *ngIf="step.type === 'APITrigger'">
              <div class="col-sm-3 center">
                <input class="message-name"
                       id="{{step.name}}"
                       value="{{step.name}}"
                       spellcheck="false"
                       readonly>
              </div>
              <div class="col-sm-2 copy-msg-wrapper">
                <rn-copied-message [copyFromElement]="true" [customBtnText]="'Copy ID to Clipboard'" [inputData]="step.name"></rn-copied-message>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
