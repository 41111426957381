<div class="key-row">
    <div>
        <div>SHA-1: {{this.shaHash}}</div>
        <div class="key-created-detail">Added on {{sftpKey.created_at | date : 'short'}}</div>
    </div>
    <div style="flex-grow: 1"></div>
    <div (click)="raiseDeleteClicked(sftpKey)">
        <i aria-hidden="true" class="fa fa-trash" title="Delete Key"></i>
    </div>
</div>
