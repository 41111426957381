<div class="list-popup dialog-content">
  <!-- UI for old experience transport -- soon to be gone -->
  <ng-container *ngIf="!isUsingNewExperienceTransport()">
    <label>Upload experience package</label>
    <p>Upload an experience package for validation and import.</p>
    <p>
      File must be a .zip file, and be of an experience which doesn't currently
      exist in this environment.
    </p>

    <div class="container-fluid row" *ngIf="showExperiencePackageDetails()">
      <div class="row colhdrs">
        <div class="colw colw-20 column-heading no-text-selection">
          <span i18n="File Name">File Name</span>
        </div>
        <div class="colw colw-20 column-heading no-text-selection">
          <span i18n="Modified At">Modified At</span>
        </div>
        <div class="colw colw-30 column-heading no-text-selection">
          <span i18n="Validation Message">Validation Result</span>
        </div>
        <div class="colw colw-5 column-heading no-text-selection">
          <span i18n="Actions">Actions</span>
        </div>
      </div>
      <div class="list-row">
        <div class="colw colw-20">{{ experienceFile.name }}</div>
        <div class="colw colw-20">
          {{ experienceFile.lastModified | date: 'short' }}
        </div>
        <div class="colw colw-30 error-msg" *ngIf="!this.isValid">
          {{ validationMessage }}
        </div>
        <div class="colw colw-30" *ngIf="this.isValid">
          <i class="fa fa-check-circle"></i>
        </div>
        <div class="colw colw-5">
          <div id="delete-consent-btn" class="btn btn-sm delete">
            <i
              class="fa fa-trash fa-lg action-button pointer"
              aria-hidden="true"
              (click)="removeExperiencePackage()"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="!showExperiencePackageDetails()">
      <rn-upload-drop-area
        [acceptedFileTypes]="'application/zip'"
        (fileCaptured)="handleDrop($event)"
      >
        <span select-btn-text>SELECT FILE</span>
        <p description-text><b>Supported File Formats:</b> .zip</p>
      </rn-upload-drop-area>
    </div>
    <div
      class="container-fluid d-flex justify-content-center"
      id="submitContainer"
    >
      <button
        id="submit-btn"
        class="btn btn-primary"
        (click)="submitExperience()"
        [disabled]="!isValid"
      >
        <span class="btn-label">Submit Experience</span>
      </button>
    </div>
  </ng-container>

  <!-- UI for new experience transport -->
  <ng-container *ngIf="isUsingNewExperienceTransport()">
    <div class="import-container px-3 text-left">
      <i
        class="pl-2 pb-2 fa-regular fa-close"
        id="closeImportIcon"
        (click)="backToExperienceBuilder()"
      ></i>
      <!-- intial view -->
      <ng-container *ngIf="!showExperiencePackageDetails()">
        <h3 class="import-header">Upload an experience .zip file</h3>
        <div>Upload an exported experience .zip file.</div>
        <div>This experience must currently not exist in this environment.</div>
        <div>
          <rn-upload-drop-area
            [acceptedFileTypes]="'application/zip'"
            (fileCaptured)="handleDropNewImport($event)"
          >
            <span select-btn-text>SELECT FILE</span>
            <p description-text><b>Supported File Formats:</b> .zip</p>
          </rn-upload-drop-area>
        </div>
      </ng-container>

      <!-- Success validation view -->
      <ng-container *ngIf="showExperiencePackageDetails()">
        <ng-container *ngIf="isValid">
          <div class="d-flex align-items-end">
            <div class="pr-4">
              <i
                class="fa-regular fa-circle-check fa-5x"
                id="importSuccessIcon"
              ></i>
            </div>
            <h3>Experience is ready for import.</h3>
          </div>
          <div>
            The following file has passed validation and is ready to import into
            this environment. This cannot be undone. Please confirm this is
            correct file.
          </div>
          <div class="py-2">
            <strong>File name:</strong> {{ experienceFile.name }}
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-6 pr-1">
              <input
                class="d-none"
                id="reUploadFileAfterSuccess"
                type="file"
                (change)="fileReUploaded($event)"
                [accept]="'application/zip'"
              />
              <label
                select-button
                class="btn btn-lg btn-outline-secondary w-100"
                for="reUploadFileAfterSuccess"
                i18n="Label for file selection button"
              >
                Upload new file
              </label>
            </div>
            <div class="col-6 pl-1">
              <button
                class="btn btn-lg btn-primary d-block w-100"
                (click)="submitExperienceNewImport()"
              >
                Import Experience
              </button>
            </div>
          </div>
        </ng-container>

        <!-- Error validation view -->
        <ng-container *ngIf="!isValid">
          <div class="d-flex align-items-center">
            <div class="pr-4">
              <i
                class="fa-regular fa-square-exclamation fa-5x"
                id="importErrorIcon"
              ></i>
            </div>
            <h3>This experience has an error and cannot be imported</h3>
          </div>
          <div>
            The experience has failed to import for the following reason(s)
          </div>
          <div class="pb-2">
            <strong>File name:</strong> {{ experienceFile.name }}
          </div>
          <ul *ngFor="let item of validationErrorMessages">
            <li>
              <strong>{{ item }}</strong>
            </li>
          </ul>
          <div class="pt-2">
            <input
              class="d-none"
              id="reUploadFile"
              type="file"
              (change)="fileReUploaded($event)"
              [accept]="'application/zip'"
            />
            <label
              select-button
              class="btn btn-lg btn-primary d-block"
              for="reUploadFile"
              i18n="Label for file selection button"
            >
              Upload new file
            </label>
          </div>
        </ng-container>
      </ng-container>

      <div
        class="loader-container"
        [class.show-loader]="isLoading"
      >
        <div class="loader">
          <div class="spinner">
            <div class="container">
              <div class="hex0"></div>
              <div class="hex120"></div>
              <div class="hex240"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<message-dialog></message-dialog>
