import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConsentEnum } from '@app/core/models/consent-type';

@Component({
  selector: 'rn-consent-select',
  template: `
    <rn-select 
      [name]="name" 
      [options]="consentOptions"
      [disabled]="disabled"
      [selectedOption]="consentSelected" 
      (selectChanged)="onSelectChange($event)"></rn-select>
  `
})
export class ConsentSelectComponent {
  @Input() name: string;
  @Input() consentSelected: ConsentEnum;
  @Input() disabled: boolean = false;
  @Output() consentSelectedChange = new EventEmitter<ConsentEnum>();
  public consentOptions: string[] = Object.keys(ConsentEnum).map(k => ConsentEnum[k]); // Object.values does not seem to be polyfilled

  public onSelectChange(event) {
    const val = event.target.value;
    if (val) {
      this.consentSelectedChange.emit(val as ConsentEnum);
    }
  }
}
