import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TitleService } from '@app/core/services/title.service';
import { Router } from '@angular/router';
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';
import { ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';

@Component({
  selector: 'app-setup-auto-response',
  templateUrl: './setup-auto-response.component.html',
  styleUrls: ['./setup-auto-response.component.scss'],
})
export class SetupAutoResponseComponent implements OnInit, OnDestroy {
  @ViewChild('autoResponseErrorMessage', { static: true })
  autoResponseMessage: MessageDialogComponent;
  twoWayConfig: TwoWayConfig;
  autoResponseForm: UntypedFormGroup;
  defaultProductGroup: ProductGroup;
  currentClient: Client;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private twoWaySetupService: TwoWaySetupService,
    private newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleService.activate(
      this.currentClient.feed_enabled
        ? 'Relay Messenger Setup'
        : 'Two Way Messaging Setup',
      'Auto Responses',
    );

    this.twoWaySetupService.getTwoWayConfig().subscribe((twoWayConfig) => {
      this.twoWayConfig = twoWayConfig;
      this.initAutoResponseForm();

      if (!twoWayConfig.after_hours_blackout_window) {
        this.twoWaySetupService.getClient().subscribe((client) => {
          this.twoWayConfig.after_hours_blackout_window =
            client.blackout_window;
        });
      }
    });

    this.twoWaySetupService
      .getDefaultProductGroup()
      .subscribe((defaultProductGroup) => {
        this.defaultProductGroup = defaultProductGroup;
      });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  get defaultAutoResponseCtl(): AbstractControl {
    return this.autoResponseForm.get('default_auto_response');
  }

  get afterHoursAutoResponseCtl(): AbstractControl {
    return this.autoResponseForm.get('after_hours_auto_response');
  }

  isLoading(): boolean {
    return this.autoResponseForm === undefined;
  }

  saveAndContinue() {
    if (!this.autoResponseForm.valid) {
      return;
    }

    this.updateConfigWithFormData();
    this.twoWaySetupService.saveTwoWayConfig(this.twoWayConfig).subscribe(
      (response) => {
        this.router.navigateByUrl(
          `/two-way/setup/${
            this.newFeed.enabled ? 'notification' : 'sms'
          }-ping`,
        );
      },
      (error) => {
        this.autoResponseMessage.showMessage(
          `We're sorry, there was an error saving your information.`,
        );
      },
    );
  }

  private updateConfigWithFormData() {
    const formData = this.autoResponseForm.getRawValue();
    this.twoWayConfig.default_auto_response = formData['default_auto_response'];
    this.twoWayConfig.after_hours_auto_response =
      formData['after_hours_auto_response'];
  }

  private initAutoResponseForm() {
    this.autoResponseForm = this.formBuilder.group({
      default_auto_response: [
        this.twoWayConfig.default_auto_response,
        Validators.required,
      ],
      after_hours_auto_response: [
        this.twoWayConfig.after_hours_auto_response,
        Validators.required,
      ],
    });
  }
}
