import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductGroup } from '@app/core/models/client';
import { Journey } from '@app/core/models/journey';
import { Background } from '@app/core/utils/background';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { PhoneComponent } from '@app/shared/phone/phone.component';
import { WirePreviewService } from '@app/core/services/wire-preview.service';
import { LoggerService } from '@app/core/services/logger.service';
import { SessionService } from '@app/security/session.service';


@Component({
  selector: 'app-journey-preview',
  templateUrl: './journey-preview.component.html',
  styleUrls: ['./journey-preview.component.scss']
})

export class JourneyPreviewComponent implements OnInit, OnDestroy {
  @ViewChildren(PhoneComponent) phoneComponents: QueryList<PhoneComponent>;
  @ViewChild(MessageDialogComponent) messageDialog: MessageDialogComponent;
  journey: Journey;
  productGroup: ProductGroup;
  hasIWCU: boolean;

  constructor(private route: ActivatedRoute,
              private wirePreviewService: WirePreviewService,
              private sessionService: SessionService,
              private router: Router) {
  }

  ngOnInit(): void {
    Background.previewOn();
    const journeyId = this.route.snapshot.params['journeyId'];

    this.wirePreviewService.getDataFromJourneyId(journeyId)
        .subscribe((data) => {
          this.journey = Journey.deserialize(data.journey);
          this.productGroup = data.productGroup;
          this.hasIWCU = data.hasIWCU;
        }, 
        (error) => {
          if (!this.sessionService.hasUserInLocalStorage()) {
            this.router.navigateByUrl('/logout');
            return ;
          }
          LoggerService.log('FeatureService', `error calling wirePreviewService.getDataFromJourneyId: ${error}`);
          this.messageDialog.showMessage('Oops...there was an error getting the experience.'); 
        });
  }

  ngOnDestroy(): void {
    Background.previewOff();
  }

  close(): void {
    window.close();
  }
}
