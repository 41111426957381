import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import * as JSZip from 'jszip';
import { ExperienceLibraryService } from '@app/core/services/experience-library.service';
import { SessionService } from '@app/security/session.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'rn-experience-import',
  templateUrl: './experience-import.component.html',
  styleUrls: ['./experience-import.component.scss'],
})
export class ExperienceImportComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  experienceFile: File = null;
  validationMessage: string = '';
  validationErrorMessages: string[] = [];
  experienceData: string = '';
  checksum: string = '';
  isValid: boolean = false;
  isLoading: boolean = false;

  constructor(
    private experienceLibraryService: ExperienceLibraryService,
    private router: Router,
    private sessionService: SessionService,
    private featureService: FeatureService,
  ) {}

  ngOnInit(): void {}

  async handleDrop(file: File) {
    await this.validateExperiencePackage(file);
  }

  async validateExperiencePackage(file: File) {
    this.experienceData = '';
    this.validationMessage = '';
    this.isValid = false;
    this.experienceFile = file;
    const zip = new JSZip();

    try {
      const loadedZip = await zip.loadAsync(file);
      const generatedData = await loadedZip.generateAsync({type: 'base64'});
      this.experienceLibraryService.validateExportedExperience(this.sessionService.getCurrentUsersClient().id, generatedData)
        .pipe(take(1))
        .subscribe(result => {
            if (result['error'] !== undefined) {
              this.validationMessage = result['error'];
            } else {
              this.experienceData = generatedData;
              this.isValid = true;
            }
          },
          error => {
            this.messageDialog.showMessage(`An error occurred: ${error.error}`);
          });
    } catch (e) {
      this.messageDialog.showMessage(`An error occurred: ${e}`);
    }
  }

  fileReUploaded(event) {
    if (event.target['files'].length > 0) {
      this.handleDropNewImport(event.target['files'][0]);
      event.target.value = '';
    }
  }

  async handleDropNewImport(file: File) {
    await this.validateExperiencePackageNewImport(file);
  }

  async validateExperiencePackageNewImport(file: File) {
    this.experienceData = '';
    this.checksum = '';
    this.validationMessage = '';
    this.isValid = false;
    this.experienceFile = file;
    this.isLoading = true;
    const zip = new JSZip();

    try {
      const loadedZip = await zip.loadAsync(file);
      const generatedData = await loadedZip.generateAsync({ type: 'base64' });
      this.experienceLibraryService
        .validateExportedExperience(
          this.sessionService.getCurrentUsersClient().id,
          generatedData,
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.isValid = true;
            this.experienceData = generatedData;
            this.checksum = result?.checksum || '';
            this.validationErrorMessages = [];
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            if (error.status === 413) {
              this.validationErrorMessages = [
                'The experience package is too large. Please upload a zip file smaller than 6MB.'
              ];
            }
            else if (
              error.error['error'] !== undefined &&
              Array.isArray(error.error['error'])
            ) {
              this.validationErrorMessages = error.error['error'].map(
                (e) => e.message,
              );
            }
            this.isValid = false;
            this.isLoading = false;
          },
        );
    } catch (e) {
      this.isLoading = false;
      this.messageDialog.showMessage(`An error occurred: ${e}`);
    }
  }

  async submitExperience() {
    this.experienceLibraryService.postExportedExperience(this.sessionService.getCurrentUsersClient().id, this.experienceData).pipe(take(1))
      .subscribe(
        result => {
          if (result['error'] !== undefined) {
            this.messageDialog.showMessage(`An error occurred while uploading the experience: ${result['error']}`);
          } else {
            this.router.navigateByUrl(`/cx-builder/experience-builder/${result.result}`);
          }
        },
        error => {
          this.messageDialog.showMessage(`An error occurred: ${error.code}`);
        }
      );
  }

  submitExperienceNewImport() {
    this.isLoading = true;
    this.experienceLibraryService
      .importExperience(
        this.sessionService.getCurrentUsersClient().id,
        this.experienceData,
        this.checksum,
      )
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.isLoading = false;
          this.router.navigateByUrl(
            `/cx-builder/experience-builder/${result.experienceId}`,
          );
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.messageDialog.showMessage(`An error occurred: ${error.status}`);
        },
      );
  }

  removeExperiencePackage() {
    this.validationMessage = '';
    this.experienceFile = null;
    this.experienceData = '';
    this.isValid = false;
  }

  showExperiencePackageDetails() {
    return this.experienceFile !== null;
  }

  backToExperienceBuilder() {
    this.router.navigateByUrl('/cx-builder');
  }

  isUsingNewExperienceTransport() {
    return this.featureService.checkFlag(FeatureFlags.new_experience_transport);
  }
}
