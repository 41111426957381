import { RelayMessengerFeatureFlag } from '@app/two-way/relay-messenger/relay-messenger.component';
import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';

export type DashboardContainerProps = {
  clientId: string;
  dashboardUrl: string;
};

const engine = new Styletron({ prefix: 'new-two-way-' }); // generate classNames with a prefix of 'new-two-way';


export const TwoWayDashboardContainer = ({
  dashboardUrl
}: DashboardContainerProps) => {
    return (
      <div id='dashboard-container'>
        <iframe src={dashboardUrl}
        style={{
          height: '2000px',
          width: '100%',
          borderWidth: '0px',
        }}></iframe>
      </div>
    );
};
