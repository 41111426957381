import { Component, OnDestroy, OnInit } from '@angular/core';
import { Client, ProductGroup } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { TitleService } from '@app/core/services/title.service';

@Component({
  templateUrl: './product-groups-list.component.html',
  styleUrls: ['./product-groups-list.component.scss']
})
export class ProductGroupsListComponent implements OnInit, OnDestroy {

  productGroups: ProductGroup[] = [];
  client: Client;
  headerGroups = {productGroups: true, inWireConsent: true, onboardingAndValidation: true};
  readonly limit = 100;
  offset = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private messageDialog: MessageDialogComponent,
              private titleService: TitleService,
              private router: Router) {
  }

  ngOnInit() {
    this.getClient();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  assignID(s: string) {
    return s.replace(/\s+/g, '');
  }

  navigateToPrevious(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/edit/onboarding`);
  }

  navigateToReports(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/report`);
  }

  toggleHeader(headerGroup: string) {
    if (this.headerGroups[headerGroup] !== undefined) {
      this.headerGroups[headerGroup] = !this.headerGroups[headerGroup];
    }
  }

  private getProductGroups(): void {
    this.clientService.getProductGroupsByClient(this.client.id, this.limit, this.offset).subscribe(
      productGroups => {
        this.productGroups = this.productGroups.concat(productGroups);
      },
      error => {
        this.messageDialog.showMessage(`Oops...couldn't get a list of product groups for ${this.client.id}.`);
      }
    );
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientService.getClient(params['clientId']).subscribe(
        client => {
          this.client = client;
          this.getProductGroups();
        },
        error => {
          this.messageDialog.showMessage(`Oops...Could not load client: ${params['clientId']}`);
        },
        () => {
          this.setPrimaryTitle();
        });
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Product Groups - ' + this.client.company_name
        : 'Configure Product Groups');
  }
}
