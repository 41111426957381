import { Validators } from '@angular/forms';
import { Conversions } from '../utils/conversions';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { FormatUtils } from '@app/core/utils/format-utils';
import * as _ from 'lodash';


// Default values for client.validation.information_fields
// This is also coming from the api /validation_fields endpoint
// in some parts of the app.
export enum VerificationFieldType {
  ssn_last4 = 'ssn_last4',
  date_of_birth = 'date_of_birth',
  first_name = 'first_name',
  last_name = 'last_name',
  postal_code = 'postal_code'
}

export class VerificationField {
  field: VerificationFieldType;
  prompt: string;
  labels: { [key: string]: string };

  static deserialize(input: VerificationField): VerificationField {
    return new VerificationField(input.field, input.prompt, input.labels);
  }

  constructor(field: VerificationFieldType, prompt: string, labels: { [key: string]: string }) {
    this.field = field;
    this.prompt = prompt;
    this.labels = labels
  }
}

export class ValidationClass {
    max_attempts: number;
    fields: VerificationField[];
    information_fields: VerificationField[] = ValidationClass.defaultInformationFields();
    enabled?: string;
    customer_lookup_location: string;
    look_up_by_secondary_account_id: boolean;

    static deserialize(input: ValidationClass): ValidationClass {
      const validation = new ValidationClass();
      validation.max_attempts = input['max_attempts'];
      validation.enabled = input['enabled'] == null ? 'false' : Conversions.toString(input['enabled']);
      validation.fields = input['fields'] ? input['fields'].map(field => VerificationField.deserialize(field)) : validation.fields;
      validation.information_fields = input['information_fields'] || validation.information_fields;
      validation.customer_lookup_location = input['customer_lookup_location'];
      validation.look_up_by_secondary_account_id = input['look_up_by_secondary_account_id'] ?? false;
      return validation;
    }

    /**
     * Generates a list of all potential Verification Field objects by plucking the values out of
     * the VerificationFieldType enum and converting them to VerificationField objects.
     */
    static defaultInformationFields(): VerificationField[] {
      return _.map(_.values(VerificationFieldType), (field) => {
        return new VerificationField(field, FormatUtils.formatInputField(field), field.labels);
      });
    }
  }

  export class VerificationClass {
    max_attempts: number;
    fields: VerificationField[];
    enabled?: string;
    customer_type: string;

    static deserialize(input: VerificationClass): VerificationClass {
      const verification = new VerificationClass();
      verification.max_attempts = input['max_attempts'];
      verification.enabled = input['enabled'] == null ? 'false' : Conversions.toString(input['enabled']);
      verification.fields = input['fields'] == null ? [] : input['fields'].map(field => VerificationField.deserialize(field));
      verification.customer_type = input['customer_type'];
      return verification;
    }
  }

  export const VerificationFormBuilder = {
    'ssn_last4': {
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4), CustomValidators.validateNegativePattern(/([A-Za-z\D])/)],
      type: 'password',
      attr: {'maxlength': '4'}
    },
    'date_of_birth': {
      validators: [Validators.required],
      type: 'date',
      attr: {'maxlength': '4'}
    },
    'gender': {
      validators: [Validators.required],
      type: 'string'
    },
    'first_name': {
      validators: [Validators.required],
      type: 'string'
    },
    'middle_name': {
      validators: [Validators.required],
      type: 'string'
    },
    'last_name': {
      validators: [Validators.required],
      type: 'string'
    },
    'postal_code': {
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5), CustomValidators.validateNegativePattern(/([A-Za-z\D])/)],
      type: 'number',
      attr: {'maxlength': '5'}
    }
};

export const VerificationFormBuilderNotRequired = {
  'ssn_last4': {
    validators: [Validators.maxLength(4)],
    type: 'password',
    attr: {'maxlength': '4'}
  },
  'date_of_birth': {
    validators: [],
    type: 'date',
    attr: {'maxlength': '4'}
  },
  'gender': {
    validators: [],
    type: 'string'
  },
  'first_name': {
    validators: [],
    type: 'string'
  },
  'middle_name': {
    validators: [],
    type: 'string'
  },
  'last_name': {
    validators: [],
    type: 'string'
  },
  'postal_code': {
    validators: [CustomValidators.digitsOnly, Validators.maxLength(5)],
    type: 'number',
    attr: {'maxlength': '5'}
  }
};
