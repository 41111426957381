<div *ngIf="inputControl" [class.input-container--full-width]="isFullWidth" [class.input-container--wide]="isWide"
     class="input-container">
    <div style="display: inline-grid; grid-template-columns: auto auto; grid-template-rows: auto; grid-column-gap: 5px"
    *ngIf="inputSubLabel || inputLabel || helpContent">
        <label *ngIf="inputLabel" [for]="formatInputID(inputLabel)" class="input-label">{{inputLabel}}</label>
        <div *ngIf="inputSubLabel" class="helper-text">{{inputSubLabel}}</div>
        <rn-help-info-popup class="info-popup" *ngIf="helpContent">
            {{helpContent}}
        </rn-help-info-popup>
    </div>

    <i *ngIf="isInvalidOrEmpty()" [ngStyle]="{'margin-left.px': isWide ? '455' : null }" aria-hidden="true"
       class="fa fa-exclamation-circle"
       container="body"
       tooltip="{{getTooltipData(inputControl?.errors)}}"></i>

    <!-- note: container="body" is required to correctly position tooltips.
         see: https://github.com/valor-software/ngx-bootstrap/issues/3075#issuecomment-363461060  -->
    <input (blur)="emitInputEvent($event)"
           (keyup)="emitInputEvent($event)"
           [class.input--has-error]="isInvalidOrEmpty()"
           [formControl]="inputControl"
           [id]="formatInputID(inputLabel)"
           [placeholder]="placeholderText"
           [readonly]="readOnlyAttr()"
           [type]="type"
           class="input form-control"
           spellcheck="false">

    <div *ngIf="maxLength !== null" class="char-count">
        <span
            ><strong
            [ngClass]="{
                'over-length-limit': remainingLength < 0
            }"
            >{{ remainingLength }}</strong
            >
            of <strong>{{ maxLength }}</strong> characters remaining.</span
        >
    </div>

</div>

<ng-content select="[input-info-box]"></ng-content>
