import { Component, Input } from '@angular/core';

export class BoxMenuItem {
  labelText: string;
  onClickHandler: (any) => void;
}

@Component({
  selector: 'box-menu',
  templateUrl: './box-menu.component.html',
  styleUrls: ['./box-menu.component.scss']
})
export class BoxMenuComponent {

  @Input() menuItems: BoxMenuItem[] = [];
  @Input() selected: boolean = false;
  @Input() invalid: boolean = false;
  showDropDown: boolean = false;
  
  constructor() { }
  
  onToggleMenu(): void {
    this.showDropDown = !this.showDropDown;
  }
  
  onCloseMenu(): void {
    this.showDropDown = false;
  }
}
