<div *ngIf="customerConsents" class="container-fluid list consents">
  <div class="row colhdrs" *ngIf="showGridHeaders()">
    <div class="colw colw-20 column-heading no-text-selection">
      <span i18n="Customer mobile phone number heading">Mobile no.</span>
    </div>
    <div class="colw colw-20 column-heading no-text-selection">
      <span i18n="Customer consent status heading">Consent Status</span>
    </div>
    <div class="colw colw-20 column-heading no-text-selection">
      <span i18n="Customer consent actions">Actions</span>
    </div>
  </div>
  <ng-container *ngIf="hasConsents()">
    <div>
      <div *ngFor="let consent of customerConsents" class="list-row">
        <div *ngIf="invalidConsentChangeErr" class="error consent-error">
          <strong>{{ invalidConsentChangeErr }}</strong>
        </div>
        <div class="row customer">
          <div class="colw colw-20">
            {{consent.channel_addr | phoneNumber}}
          </div>
          <div class="colw colw-20" *ngIf="isMatchingConsent(consent, consentBeingEdited)">
            <rn-select
              [name]="consentBeingEdited.consent_type"
              [options]="getConsentTypes(consent)"
              [selectedOption]="consentBeingEdited?.consent_type"
              (selectChanged)="consentBeingEdited.consent_type = getSelectedValue($event)"
            ></rn-select>
          </div>
          <div class="colw colw-20 consentType" *ngIf="!isMatchingConsent(consent, consentBeingEdited)">
            {{consent.consent_type}}
          </div>
          <div class="colw colw-20" *ngIf="isMatchingConsent(consent, consentBeingEdited)">
            <button id="save-btn" class="btn btn-primary consent" (click)="onConsentChange(consent.consent_type)">
              <span id="save-button">Save</span></button>
            <button id="cancel-btn" class="btn btn-remove consent" (click)="onCancelEditConsent()">
              <span id="cancel-button">Cancel</span>
            </button>
          </div>
          <div class="colw colw-20" *ngIf="!isMatchingConsent(consent, consentBeingEdited)">
            <div id="edit-consent-btn" class="btn btn-sm edit" *ngIf="canEditConsent">
              <i class="fa fa-pencil fa-lg action-button pointer" aria-hidden="true"
                 (click)="onStartEditConsent(consent)"></i>
            </div>
            <div id="delete-consent-btn" class="btn btn-sm delete" *ngIf="canDeleteConsent">
              <i class="fa fa-trash fa-lg action-button pointer" aria-hidden="true"
                 (click)="onConfirmChannelDelete(consent.channel_addr)"></i>
            </div>
            <div id="add-consent-btn" class="btn btn-sm edit"
                 *ngIf="canAddConsentGrid(consent)">
              <i class="fa fa-plus fa-lg action-button pointer" aria-hidden="true"
                 (click)="onStartAddPhoneNumber()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row customer" *ngIf="showNoConsentMessage()">
    <div class="row">
      <span>You haven't added any mobile numbers as of yet.</span>
    </div>
    <div class="row" *ngIf="canAddConsent">
      <button class="btn btn-primary" (click)="onStartAddPhoneNumber()">
        <span>Add new mobile no.</span>
      </button>
    </div>
  </div>
  <div class="row consent" *ngIf="channelBeingAdded">
    <div *ngIf="invalidAddPhoneErr" class="error consent-error">
      <strong>{{ invalidAddPhoneErr }}</strong>
    </div>
    <div class="colw colw-20">
      <input id="client-trigger-id" [(ngModel)]="channelBeingAdded.channel_addr" (change)="forceNumeric(channelBeingAdded.channel_addr)"/>
    </div>
    <div class="colw colw-20">
      <rn-select
        id="new-consent-type-select"
        [name]="channelBeingAdded.consent_type"
        [selectedOption]="channelBeingAdded?.consent_type"
        [options]="getConsentTypes(channelBeingAdded.consent_type)"
        (selectChanged)="channelBeingAdded.consent_type = getSelectedValue($event)"
      ></rn-select>
    </div>
    <div class="colw colw-20">
      <button id="save-new-btn" class="btn btn-primary consent" (click)="onAddChannel()">
        <span id="save-new-button">Save</span>
      </button>
      <button id="cancel-new-btn" class="btn btn-remove consent" (click)="onCancelAddPhone()">
        <span id="cancel-new-button">Cancel</span>
      </button>
    </div>
  </div>
</div>

<message-dialog #confirmChannelDeleteDialog (affirm)="onRemoveChannel()" [showCancel]="true">
</message-dialog>
