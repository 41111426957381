import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsComponent } from '@app/legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '@app/legal/privacy-policy/privacy-policy.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TermsAndConditionsComponent,
    PrivacyPolicyComponent
  ]
})

export class LegalModule { }
