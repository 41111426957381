<div class="item-details">
  <div class="item-details__section">
    <div class="item-details__detail-label">Job Id:</div>
    <div class="item-details__detail">{{job.id}}</div>
    <ng-container *ngIf="job.job_created_at">
      <div class="item-details__detail-label">Created At:</div> 
      <div class="item-details__detail">{{job.displayableDate(job.job_created_at) }}</div>
    </ng-container>
    <div class="item-details__detail-label">Launched By:</div> 
    <div class="item-details__detail">{{job.launched_by}}</div>
  </div>
  
  <div *ngIf="showProgress()" class="item-details__section item-details__section--progress">
    <div class="item-details__section-heading">Progress</div>

    <ng-container *ngIf="job.job_failure_reason">
      <div class="item-details__detail-label">Errors</div>
      <div class="item-details__detail">{{job.job_failure_reason}}</div>
    </ng-container>

    <ng-container *ngIf="job.isSchedulable()">
      <div class="item-details__detail-label">Scheduled to Run At</div>
      <div *ngIf="job.status === 'idle'" class="item-details__detail">Not Yet Scheduled</div>
      <div *ngIf="job.status === 'scheduled'" class="item-details__detail">{{job.displayableDate(job.scheduled_at)}}</div>
    </ng-container>

    <ng-container *ngIf="!job.isSchedulable()">
      <div class="item-details__detail-label">Records Validated</div>
      <div class="item-details__detail">{{job.current_validation_line}} out of {{job.total_records}}</div>
    </ng-container>

    <button *ngIf="showSchedule" class="item-details__button item-details__button--schedule" (click)="schedule()">
      <ng-container *ngIf="job.status === 'scheduled'">Reschedule</ng-container>
      <ng-container *ngIf="job.status === 'idle'">Schedule</ng-container>
    </button>  
    <button *ngIf="showCancel" class="item-details__button item-details__button--cancel" (click)="cancel()">Cancel</button>  
  </div>

  <div *ngIf="showStats()" class="item-details__section item-details__section--stats">
    <div class="item-details__section-heading">Stats</div>
    <ul class="item-details__stats-list">
      <li>Records Loaded:  <strong>{{job.total_records}}</strong></li>
      <li>Records Accepted:  <strong>{{job.records_accepted}}</strong></li>
      <li>Records Rejected:  <strong>{{job.records_rejected}}</strong></li>
      <ng-container *ngIf="job.showSmsStats()">
        <li>Sms Attempted:  <strong>{{job.sms_attempted}}</strong></li>
        <li>Sms Not Attempted:  <strong>{{job.sms_not_attempted}}</strong></li>
      </ng-container>
    </ul>
  </div>

  <div class="item-details__section item-details__section--downloads">
    <div class="item-details__section-heading">Downloads</div>
    <p *ngIf="noUrlsAvailable()">Not yet available</p>
    <button class="item-details__action-button" [class.item-details__action-button--visible]="hasUrlFor('original_file')" (click)="openFile('original_file')">Original File</button>
    <button class="item-details__action-button" [class.item-details__action-button--visible]="hasUrlFor('accept_file')" (click)="openFile('accept_file')">Accept File</button>
    <button class="item-details__action-button" [class.item-details__action-button--visible]="hasUrlFor('reject_file')" (click)="openFile('reject_file')">Reject File</button>
  </div>
</div>
