import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'schedule-launch-dialog',
    templateUrl: './schedule-launch-dialog.component.html',
    styleUrls: ['./schedule-launch-dialog.component.scss']
})
export class ScheduleLaunchDialogComponent implements OnInit {

    @Input() title: string = 'Schedule';
    @Input() thingBeingLaunched: 'journey' | 'file' = 'journey';
    @Input() showDialog: boolean = false;
    @Output() showDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() scheduleTime: Date;
    @Output() scheduleTimeChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() confirmTime: EventEmitter<Date> = new EventEmitter<Date>();
    timeZone: string;
    
    showValidTimeError = false;
    
    constructor() { 
        this.determineTimeZone();
    }

    ngOnInit() {
    }
    
    close() {
        this.scheduleTime = undefined;
        this.scheduleTimeChange.emit(this.scheduleTime);
        this.showDialog = false;
        this.showDialogChange.emit(this.showDialog);
    }
    
    /**
     * Determine a descriptive string for the user's time zone such as Eastern, Central, or Pacific.
     * 
     * This is done by taking the first word of the locale-specific long time zone description.
     */
    private determineTimeZone() {
        const localeTimeSplit = new Date().toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ');
        if (localeTimeSplit) {
            this.timeZone = localeTimeSplit[2];
        }
    }
    
    now() {
        this.scheduleTime = new Date();
        this.scheduleTimeChange.emit(this.scheduleTime);
    }
    
    setLaunch() {
        // don't allow scheduling of times in the past
        if (this.scheduleTime < new Date()) {
            this.showValidTimeError = true;
            return;
        }

        this.showDialog = false;
        this.showDialogChange.emit(this.showDialog);
        this.confirmTime.emit(this.scheduleTime);
    }
    
    handleModelChange(scheduleTime: Date) {
        this.scheduleTimeChange.emit(scheduleTime);
        this.showValidTimeError = false;
    }
}
