import { Pipe, PipeTransform } from '@angular/core';
import { VideoUtils } from '@app/core/utils/video-utils';

@Pipe({
    name: 'embedyoutubeUrl'
})
export class EmbedYoutubeUrlPipe implements PipeTransform {

    constructor() {}

    transform(value: any, args?: any): any {
        return VideoUtils.convertToEmbeddedFormat(value);
    }
}
