<div
  [ngClass]="{
    messageBox: true,
    'messageBox--selectable': copyType === 'message'
  }"
  *ngIf="step.type === 'MessageSender' || step.type === 'MessageResponse'"
  (click)="toggleCopyChoice($event)"
>
  <div class="messageTop">
    <div class="messageNumber">{{ step.step }}</div>
    <span class="title">{{ step.nickname }}</span>
    <div
      class="pull-right"
      *ngIf="selectionType === 'copy' && copyType === 'message'"
    >
      <div class="btn">
        <i
          aria-hidden="true"
          *ngIf="!choiceToggle"
          class="fa fa-caret-right"
        ></i>
        <i aria-hidden="true" *ngIf="choiceToggle" class="fa fa-times"></i>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!choiceToggle; else elseBlock">
    <div class="media">
      <img
        *ngIf="step.wire['media_type'] === 'image' && step.wire['image_url']"
        [src]="step.wire['image_url']"
      />
      <iframe
        sandbox="allow-scripts allow-same-origin"
        class="media-video"
        *ngIf="step.wire['media_type'] === 'video' && step.wire['video_url']"
        [src]="step.wire['video_url'] | embedyoutubeUrl | safeUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <p
      *ngIf="!step.isNew && step.wire.is_shown === 'true'"
      class="message-text"
      [innerHtml]="step.wire.text | safeHtml"
    ></p>
    <p
      *ngIf="!step.isNew && step.wire.is_shown !== 'true'"
      class="message-text"
    >
      {{ step.sms.text }}
    </p>
    <div class="no-content-message">
      <h5 *ngIf="step.isNew" class="center strong"><i>No Content</i></h5>
    </div>
    <ng-container *ngIf="!newFeed.enabled">
      <ng-container *ngFor="let action of step.wire['actions']">
        <app-step-action
          [actionLabel]="action.label"
          [actionType]="action.type"
          [actionId]="action.id"
        ></app-step-action>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="newFeed.enabled">
      <ng-container *ngFor="let action of step.wire['actions']; let i = index">
        <app-step-action
          *ngIf="i === 0 && !!action.primary"
          [actionLabel]="action.label"
          [actionType]="action.type"
          [actionId]="action.id"
          isPrimary="true"
        ></app-step-action>

        <app-step-action
          *ngIf="i !== 0 || !action.primary"
          [actionLabel]="action.label"
          [actionType]="action.type"
          [actionId]="action.id"
        ></app-step-action>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #elseBlock>
    <p class="copy-text">
      Would you like to copy '{{ step.nickname }}' to your experience?
    </p>
    <button class="btn btn-primary copy-msg-btn" (click)="copyMsgToJourney()">
      Copy
    </button>
    <div class="cancel-link-area">
      <span class="cancel-link" (click)="toggleCopyChoice($event)">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span i18n="Cancel the current operation">Cancel</span>
      </span>
    </div>
  </ng-template>
</div>

<message-dialog
  #messageDialogWithKickback
  (affirm)="navigateBackToJourneyBuilder()"
></message-dialog>
