<div class="select">
  <label class="select__label" *ngIf="labelText">{{ labelText }}</label>
  <select
    name="name"
    class="select__select-input"
    (change)="listenForChange($event)"
    [disabled]="disabled"
  >
    <option *ngIf="placeholderText" value="" [selected]="selectedOption === ''">
      {{ placeholderText }}
    </option>
    <option
      *ngFor="let option of options"
      value="{{ option.value || option }}"
      [selected]="
        option.value
          ? option.value === selectedOption
          : option === selectedOption
      "
    >
      {{ option.label || option | titleText }}
    </option>
  </select>
  <i class="fa fa-caret-down select__icon"></i>
</div>
