import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SftpCustomValidators } from '@app/sftp-mgmt/util/sftp-validators';
import { Client } from '@app/core/models/client';

@Component({
  selector: 'rn-sftp-new-key',
  templateUrl: './sftp-new-key.component.html',
  styleUrls: ['./sftp-new-key.component.scss']
})
export class SftpNewKeyComponent implements OnInit {
  @Input() client: Client;
  @Output() onSaveClicked = new EventEmitter<string>();
  @Output() onCancelClicked = new EventEmitter<any>();

  sshKeyFieldPlaceholder: string = `---- BEGIN SSH2 PUBLIC KEY ----\nComment: "2048-bit RSA, converted by username@computername.local fro"\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXX\n---- END SSH2 PUBLIC KEY ----`
  sshKeyCtl = new UntypedFormControl(null, [Validators.required, SftpCustomValidators.isValidSSH2FormatValidator]);

  constructor() { }

  ngOnInit(): void {
    this.reset();
  }

  saveKey(): void {
    if (this.sshKeyCtl.invalid) {
      return;
    }

    this.onSaveClicked.emit(this.sshKeyCtl.value);
  }

  reset() {
    this.sshKeyCtl.reset(null);
  }

  handleCancelClicked() {
    this.reset();
    this.onCancelClicked.emit();
  }
}
