import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'rn-control-tag-detail',
  templateUrl: './control-tag-detail.component.html',
  styleUrls: ['./control-tag-detail.component.scss']
})
export class ControlTagDetailComponent {
  @Input() tag: UntypedFormGroup;
  @Input() group: UntypedFormGroup; // parent group
  @Output() remove: EventEmitter<void> = new EventEmitter();

  toggleAutoResponse(event, tag) {
    const autoResponseCtl = tag.controls.tag_autoresponse;

    // if there is no autoresponse field yet, add one.  If there is one, remove it.
    if (_.isEmpty(autoResponseCtl)) {
      tag.addControl('tag_autoresponse', new UntypedFormControl('', Validators.required));
      return;
    } 

    if (autoResponseCtl.disabled) {
      autoResponseCtl.enable(); 
    } else {
      autoResponseCtl.disable();
      autoResponseCtl.markAsUntouched();
    } 
  }

  removeTag() {
    this.remove.emit();
  }
}
