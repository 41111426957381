import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { JourneyContent } from '@app/core/models/journey';
import { SessionService } from '@app/security/session.service';
import { ClientService } from '@app/core/services/client.service';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Client } from '@app/core/models/client';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from '@app/core/utils/custom-validators';
import * as _ from 'lodash';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-journey-metadata-dialog',
  templateUrl: './journey-metadata-dialog.component.html',
  styleUrls: ['./journey-metadata-dialog.component.scss']
})
export class JourneyMetadataDialogComponent implements OnInit {

  @Input() btnConfig: Object = {cancel: 'Cancel', save: 'get Started'};
  @Input() journeyContentPropertiesInput: JourneyContent;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<JourneyContent>();
  @Output() onCampaignChanged = new EventEmitter<void>();
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog;

  offset = 0;
  campaigns = {
    'all': [],
    'display': 'none',
    'match': []
  };
  campaignHighlighted: number = null;
  journeyContentProperties: JourneyContent = new JourneyContent();
  journeyGroup: UntypedFormGroup;
  client: Client;
  clientId: string;
  featureFlags = FeatureFlags;

  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;

  constructor(public sessionService: SessionService,
              private clientService: ClientService,
              private fb: UntypedFormBuilder,) {
  }

  ngOnInit() {
    if (this.journeyContentPropertiesInput) {
      this.journeyContentProperties = this.journeyContentPropertiesInput;
    }

    this.client = this.sessionService.currentUser.client;
    this.clientId = this.sessionService.currentUser.client.id;

    this.initForm();
    this.getCampaigns();
  }

  initForm(): void {
    // Structure Mirrors JourneyContent class
    this.journeyGroup = this.fb.group({
      'name': [this.journeyContentProperties.name, [Validators.required, Validators.pattern(/\S+/)]],
      'product_group': [this.journeyContentProperties.product_group, Validators.required],
      'bypass_csr_validation': [this.journeyContentProperties.bypass_csr_validation],
      'campaign': [this.journeyContentProperties.campaign],
      'description': [this.journeyContentProperties.description],
    });
  }

  getCampaigns() {
    this.clientService.getCampaignsByClient(this.clientId).subscribe(
      campaigns => {
        campaigns.forEach((value: any) => {
          this.campaigns.all.push({'value': value, 'highlighted': false});
          this.campaigns.match = this.campaigns.all;
        });
        if (this.journeyContentProperties.campaign) {
          this.campaigns.match = this.campaigns.match.filter(c => c.value.match(this.journeyContentProperties.campaign));
        }
      },
      (error: HttpErrorResponse) => {
        let reason;
        if (error && error.error && error.error.reason) {
          reason = error.error.reason;
        }
        let message = 'Oops...could not get campaigns.';
        if (reason) {
          message = `Oops...could not get campaigns: ${reason}`;
        }
        this.messageDialog.showMessage(message);
      }
    );
  }

  selectCampaign(event: any) {
    const e = event;
    const target = e.target;

    if (e.type === 'mouseenter') {
      this.campaignHighlighted = this.campaigns.match.findIndex(o => o.value === target.innerText);
      this.campaigns.match[this.campaignHighlighted].highlighted = true;
    } else if (e.type === 'mouseleave' && this.campaignHighlighted !== null) {
      this.campaigns.match[this.campaignHighlighted].highlighted = false;
      this.campaignHighlighted = null;
    }

    if (e.type === 'keyup') {
      if (this.campaignHighlighted > this.campaigns.match.length) {
        return 'block';
      }
      if (e.keyCode === 40 && this.campaignHighlighted === null) {
        this.campaignHighlighted = 0;
        this.campaigns.match[this.campaignHighlighted].highlighted = true;
      } else if (e.keyCode === 40) {
        this.campaigns.match[this.campaignHighlighted].highlighted = false;
        if (this.campaignHighlighted < this.campaigns.match.length - 1) {
          this.campaignHighlighted++;
        }
        this.campaigns.match[this.campaignHighlighted].highlighted = true;
      }

      if (e.keyCode === 38 && this.campaignHighlighted === null) {
        return 'none';
      } else if (e.keyCode === 38 && this.campaignHighlighted === 0) {
        this.campaigns.match[this.campaignHighlighted].highlighted = false;
        this.campaignHighlighted = null;
      } else if (e.keyCode === 38) {
        this.campaigns.match[this.campaignHighlighted].highlighted = false;
        this.campaignHighlighted--;
        this.campaigns.match[this.campaignHighlighted].highlighted = true;
      }

      return 'block';
    }
  }

  setCampaign(campaign: any) {
    if (this.journeyContentProperties.campaign !== campaign.value) {
      this.journeyContentProperties.campaign = campaign.value;
    }
    this.campaigns.match[this.campaignHighlighted].highlighted = false;
    this.campaigns.match = this.campaigns.match.filter(c => c.value.match(new RegExp(campaign)));
    this.campaignHighlighted = null;
    this.campaigns.display = 'none';
  }

  toggleCampaignDropdown(event: any) {
    const e = event;
    const target = e.target;
    const regex = '(' + target.value + ')';
    let display = this.campaigns.display;

    e.preventDefault();

    if (e.type === 'dblclick' && display === 'none') {
      display = 'block';
    } else if ((e.type === 'dblclick' || e.type === 'click') && display === 'block') {
      display = 'none';
    }

    if (e.type === 'keyup') {
      display = 'block';

      this.campaigns.match = this.campaigns.all.filter(c => c.value.match(new RegExp(regex, 'gi')));

      if (this.campaigns.match && this.campaigns.match.length > 0) {
        if (e.keyCode === 40 || e.keyCode === 38) {
          display = this.selectCampaign(e);
        }
        if (e.keyCode === 13 && this.campaignHighlighted !== null) {
          this.setCampaign(this.campaigns.match[this.campaignHighlighted]);
          return;
        }
      }
    }

    if (this.campaigns.match.length < 1) {
      display = 'none';
    }

    this.campaigns.display = display;
  }

  getTooltipData(errors: Object) {
    if (errors['maxlength']) {
      return `this input has a maximun length of ${errors['maxlength'].requiredLength}`;
    }
    if (errors['required']) {
      return `this input is required`;
    }
    if (typeof (errors) === 'string') {
      return errors;
    }
  }

  handleChange(val: boolean) {
    this.journeyGroup.controls['bypass_csr_validation'].setValue(!val);
  }

  cancel() {
    this.onCancel.emit();
  }

  updateModel() {
    let formData = this.journeyGroup.getRawValue();
    formData = _.omitBy(formData, _.isNil); // remove keys where value is null, because the api doesn't like it
    this.journeyContentProperties = _.assign(this.journeyContentProperties, formData);
  }

  save() {
    this.updateModel();
    if (this.journeyGroup.valid) {
      this.onSave.emit(this.journeyContentProperties);
    } else {
      CustomValidators.markAllAsTouched(this.journeyGroup); // replace with this.journeyGroup.markAllAsUntouched when we get to ng8
    }
  }
}
