<div class="phone">
  <div class="phone-top">
    <div class="sensor"></div>
    <div class="camera"></div>
    <div class="speaker"></div>
  </div>
  <div class="screen" [style]="{ 'overflow-y': overflowY }">
    <ng-content></ng-content>
  </div>
  <div class="phone-bottom">
    <div class="home-button"></div>
  </div>
</div>
