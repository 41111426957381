<div class="image-input">
    <label i18n="Portal icon label text">{{label}}</label>
    <br>    

    <span id="{{imgID}}" class="clickable" (click)="showAddAssetDialog = true" id="portal-icon-add">
        <img [attr.src]="imgSrc" [attr.width]="imgDimensions.width" [attr.height]="imgDimensions.height" class="outlined-img">
        <i class="fa fa-camera fa-2x"></i>
    </span>

    <p class="tip-text">
        <svg width="15" height="60" style="float: left;">
            <polyline points="8,0 8,24, 2,30 8,36 8,80" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
        </svg>
        <span [innerHtml]="tipText | safeHtml"></span>
    </p>

</div>

<ng-container *ngIf="showAddAssetDialog">
    <add-media-asset *ngIf="type === 'icon'"
        [clientId]="client.id"
        [canSelect]="true"
        [assetTypes]="['brand']"
        [maxFileSize]="1048576"
        [minWidth]="95"
        [minHeight]="95"
        (onSave)="onAddPortalIcon($event)" 
        (onSelect)="onAddPortalIcon($event)" 
        (onClose)="onClosePortalIconDialog()">
    </add-media-asset>

    <add-media-asset *ngIf="type === 'banner'"
        [clientId]="client.id"
        [canSelect]="true"
        [assetTypes]="['banner']" 
        [maxFileSize]="1048576"
        [minWidth]="1200"
        [minHeight]="375" 
        (onSave)="onAddPortalBanner($event)" 
        (onSelect)="onAddPortalBanner($event)" 
        (onClose)="onClosePortalBannerDialog()">
    </add-media-asset>
</ng-container>