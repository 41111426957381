import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export type EnvString = 'dev' | 'qa' | 'staging' | 'production';

@Injectable()
export class EnvService {
  static isDev(): boolean {
    const env = environment.environment;
    return EnvService.isLocal() || env === 'dev' || env === 'qa';
  }

  static isLocal(): boolean {
    return window.location.hostname === 'localhost';
  }

  constructor() {}

  getEnv(): EnvString {
    return environment.environment as EnvString;
  }
}