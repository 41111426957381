import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { FeedHeader } from '../feed-header/FeedHeader';
import { ProductGroup } from '@app/core/models/client';

@Component({
  selector: 'product-group-preview-react',
  templateUrl: './product-group-preview-react-container.component.html',
  styleUrls: ['./product-group-preview-react-container.component.scss'],
})
export class ProductGroupPreviewReactContainerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  constructor() {}

  reactWrapperId: string;
  @Input() messageId?: string;
  @Input() productGroupName: ProductGroup['name'];
  @Input() showProductGroup: ProductGroup['hide_pg_wire_display_name'];
  @Input() productGroupDescription: ProductGroup['description'];
  @Input() bannerUrl: ProductGroup['branding']['banner_s3_url'];
  @Input() logoUrl: ProductGroup['branding']['icon_s3_url'];
  @Input() color: ProductGroup['branding']['color'];
  @Input() fdic?: ProductGroup['fdic'];
  @Input() showFDIC?: boolean;

  ngOnInit() {
    // adding a messageId (if available) for experience preview pages (or it will only render on the first <Phone></Phone>)
    this.reactWrapperId = `product-group-preview-${this.messageId || ''}`;
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }
  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(FeedHeader, {
          productGroupDescription: this.productGroupDescription,
          showProductGroup: this.showProductGroup,
          productGroupName: this.productGroupName,
          bannerUrl: this.bannerUrl || '',
          logoUrl: this.logoUrl,
          color: this.color,
          isPreview: true,
          showFDIC: !!this.showFDIC,
          fdic: this.fdic || undefined,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }
}
