import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import {
  BasicAuthConfiguration,
  NoAuthConfiguration,
  OAuthConfiguration,
} from '@app/api-mgmt/components/push-api/types/push-api-models';
import { EventSubscriptionsInput } from '../event-subscriptions-input/EventSubscriptionsInput';
import { Redirect } from '../configurations-display/Redirect';

export const EventsEditor = ({
  eventlist,
  setEventlist,
  isValid,
  integration,
  onSubmit,
}: {
  eventlist: string[];
  setEventlist: (eventlist: string[] & string) => void;
  isValid?: boolean;
  integration?:
    | BasicAuthConfiguration
    | OAuthConfiguration
    | NoAuthConfiguration;
  onSubmit: (activate: boolean) => void;
}) => {
  const [css] = useStyletron();

  const checkIfActive = () => {
    if (integration && integration.active === true) {
      return true;
    }
  };

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <Redirect
        link="https://clientdocs.relayzone.com/#_push_event_webhook"
        value="View Relay Integration Documentation"
      />
      <EventSubscriptionsInput
        initialState={eventlist}
        onUpdate={(events) =>
          setEventlist(events as unknown as string[] & string)
        }
        existingIntegration={integration ? true : false}
      />
    </div>
  );
};
