import React from 'react';
import { SIZE, Tag } from 'baseui/tag';

export type ToastProps = {
  text: string;
  onActionClick: () => void;
  dataTestId?: string;
};

export const SortingToast = ({
  text,
  onActionClick,
  dataTestId,
}: ToastProps) => {
  return (
    <React.Fragment>
      <Tag
        overrides={{
          Text: {
            style: ({}) => ({
              maxWidth: 'auto',
              textOverflow: 'none',
              color: '#37474F',
              fontSize: '10px',
            }),
            props: { 'data-testid': dataTestId },
          },
          Root: {
            style: ({}) => ({
              backgroundColor: '#D7DADB',
              borderRightColor: '#D7DADB',
              borderLeftColor: '#D7DADB',
              borderTopColor: '#D7DADB',
              borderBottomColor: '#D7DADB',
            }),
          },
          ActionIcon: {
            style: ({}) => ({
              width: '8px',
            }),
            props: { 'data-testid': `${dataTestId}-delete` },
          },
        }}
        onActionClick={onActionClick}
        size={SIZE.small}
      >
        {text}
      </Tag>
    </React.Fragment>
  );
};
