<div class="client-config-report">

    <div *ngIf="currentProductGroup === null">
        <!-- this should be a component -->
        <div class="title-container">
            <h3>Congratulations!</h3>
            <i>Here are the details on what you created:</i>
        </div>
        <div class="client-info">
            <div>
            <img [src]="client.branding.icon_s3_url"/>
            </div>
            <div>
            <h4>{{client.company_name}}</h4>
            <p><i>{{client.primary_contact.email}}</i></p>
            <p><i>{{client.primary_contact.mobile_number}}</i></p>

            </div>
            <div class="dashed-line"></div>
            <div>
            <h4>{{productGroups.length}} Product{{productGroups.length > 1?'s':''}}</h4>
            </div>
        </div>
        <div> <!-- The following is causing issues with scrolling/reloading behavior:
            data-infinite-scroll
            debounce
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollThrottle]="throttle"
            (scrolled)="onScrollDown()" -->
            <div class="product-groups" *ngFor="let product of productGroups" (click)="navigateToReport(product)">
                <div class="phone-container">
                    <generic-phone>
                        <wire-header [productGroup]="product"></wire-header>
                        <network-preview [productGroup]="product"></network-preview>
                    </generic-phone>
                </div>
                <div class="product-group-info">
                <div class="pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                <h4 [ngStyle]="{'color': product.color}">{{product.name}}</h4>
                <h5 [ngStyle]="{'color': product.color}">{{product.id}}</h5>
                <hr/>
                <p><i>{{client.primary_contact.email}}</i></p>
                </div>
            </div>
        </div>
    </div>

    <div class="title-container" *ngIf="currentProductGroup !== null">
        <!-- this should be a component -->
    <h3 i18n="Introductory for creating a new product group - line 1">Congratulations!</h3>
    <i i18n="Introductory for creating a new product group - line 2">Here are the details on what you created:</i>
    </div>

    <div class="report-wrapper" *ngIf="currentProductGroup !== null">
        <app-product-report [product]="currentProductGroup" [client]="client"></app-product-report>
        <div class="report-footer">
            <button class="btn btn-primary" (click)="currentProductGroup = null">
                <span class="icon icon-left">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
                <span class="btn-label">Close</span>
            </button>
        </div>
    </div>

</div>

<message-dialog></message-dialog>
