<div *ngIf="isLoadingKeys()" class="sftp-mgmt__spinner">
    <app-spinner></app-spinner>
</div>


<div class="sftp-mgmt__title clearfix">
    <div class="key-list-title">SFTP Keys for: {{this.client.id}}</div>

    <rn-help-info-popup>
        <div *ngIf="isLoadingUser()" class="sftp-mgmt__spinner">
            <app-spinner></app-spinner>
        </div>
        <div *ngIf="!isLoadingUser()">
            <div>Client: {{client.id}}</div>
            <div>User Name: {{sftpUser.username}}</div>
            <div>Added On: {{sftpUser.created_at | date : 'short'}}</div>
        </div>
    </rn-help-info-popup>

    <div *ngIf="!showAddKeyComponent && !isLoadingKeys()" class="sftp-mgmt__toggle">
        <button (click)="toggleAddKeyComponent()" [disabled]="" class="btn btn-primary add-key-btn"
                id="trigger-new-sftp-btn">
            <span class="btn-label">Add SFTP Key</span>
        </button>
    </div>
</div>

<div *ngIf="!isLoadingKeys() && !hasKeys()" class="sftp-mgmt__message">There are no SSH keys
    for {{client.company_name}}</div>

<div *ngIf="!isLoadingKeys()" class="add-key-container">
    <div *ngIf="showAddKeyComponent">
        <rn-sftp-new-key (onCancelClicked)="toggleAddKeyComponent()"
                         (onSaveClicked)="handleOnSaveClicked($event)"
                         [client]="client"></rn-sftp-new-key>
    </div>
    <div *ngIf="hasKeys()" class="key-list-container">
        <ng-container *ngFor="let sftpKey of sftpKeys; let i = index;">
            <rn-sftp-key-list-element (onDeleteClicked)="promptForDeleteKey(sftpKey.id)"
                                      [sftpKey]="sftpKey"></rn-sftp-key-list-element>
        </ng-container>
    </div>
</div>


<message-dialog #confirmationDialog (affirm)="onDeleteConfirmed.emit(keyIdToDelete)" [showCancel]="true"
                [showClose]="true"></message-dialog>
