<div class="intro-line-1" i18n="Introductory for product group list - line 1">
  {{ this.client?.feed_enabled ? 'Legal Content' : 'Legal information' }}
</div>

<!--Wire-->
<ng-container *ngIf="!this.client?.feed_enabled">
  <client-header
    [headerLabel]="'Legal information'"
    [headerGroup]="'boringLegalStuff'"
    (click)="headerGroups.boringLegalStuff = !headerGroups.boringLegalStuff"
  ></client-header>

  <div
    class="collapse-container boring-legal-stuff-container"
    [formGroup]="legalFormGroup"
    *ngIf="
      headerGroups.boringLegalStuff && client && currentGroup && legalFormGroup
    "
  >
    <div class="disclaimer-toggle-container">
      <app-radio-btn
        id="message-details-disclaimer-flag"
        [btnLabel]="'Message details disclaimer'"
        [btnName]="'message_details_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.message_details_enabled"
      ></app-radio-btn>
      <app-radio-btn
        id="wire-feed-enabled-flag"
        [btnLabel]="'Wire feed disclaimer'"
        [btnName]="'wire_feed_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.wire_feed_enabled"
      ></app-radio-btn>

      <app-radio-btn
        id="password-tc-enabled-flag"
        [btnLabel]="'Password Page Ts&Cs Enabled'"
        [btnName]="'password_tc_enabled_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.password.tc_enabled"
      ></app-radio-btn>

      <app-radio-btn
        id="password-tc-mandatory-flag"
        *ngIf="currentGroup.password.tc_enabled"
        [btnLabel]="'Password Page Ts&Cs Mandatory'"
        [btnName]="'password_mandatory_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.password.tc_mandatory"
      ></app-radio-btn>
    </div>

    <hr class="divider" />

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Disclaimer that appears at end of every message/feed"
            >Disclaimer</label
          >
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-disclaimer'"
          [formControl]="legalFormGroup.controls['disclaimer.text']"
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="60" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,80"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >A disclaimer appears at the end of a customer's message feed and
            after each message.</span
          >
        </p>
      </div>
    </div>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for express consent"
            >Express consent Ts&Cs</label
          >
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-express-consent'"
          [formControl]="
            legalFormGroup.controls['consent.express_consent_ts_cs']
          "
          i18n-placeholder="
            Placeholder text for express consent terms and conditions
          "
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="80" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,80"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Terms and Conditions that will be shown to a customer when offering
            Express Consent. Standard Terms and Conditions will be used if no
            custom consent language is provided.</span
          >
        </p>
      </div>
    </div>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for express written consent"
            >Express written consent Ts&Cs</label
          >
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-express-written-consent'"
          [formControl]="
            legalFormGroup.controls['consent.express_written_consent_ts_cs']
          "
          i18n-placeholder="
            Placeholder text for express consent terms and conditions
          "
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="105" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,105"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Terms and Conditions that will be shown to a customer when offering
            Express Written Consent. Standard Terms and Conditions will be used
            if no custom consent language is provided.</span
          >
        </p>
      </div>
    </div>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for in wire consent upgrade"
            >In Wire Consent Upgrade Ts&Cs</label
          >
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [class.has-error]="!iwcuFormCtl.valid && iwcuFormCtl.touched"
          [elementId]="'tiny-editor-in-wire-upgrade'"
          [formControl]="iwcuFormCtl"
          i18n-placeholder="
            Placeholder text for in {{this.client?.feed_enabled ? 'feed' :
            'wire'}} consent upgrade terms and conditions
          "
        >
        </app-tiny-editor>
        <i
          *ngIf="!iwcuFormCtl.valid && iwcuFormCtl.touched"
          class="fa fa-exclamation-circle textarea-width"
          aria-hidden="true"
          tooltip="IWCU Terms & Conditions are required for clients with IWCU switched on"
        ></i>
        <p class="tip-text">
          <svg width="15" height="105" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,105"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Terms and Conditions that will be shown to a customer when offering
            In Wire Consent Upgrade.</span
          >
        </p>
      </div>
    </div>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for in wire consent upgrade"
            >Password Page Ts&Cs</label
          >
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-password-tcs'"
          [formControl]="legalFormGroup.controls['password.tcs']"
          i18n-placeholder="
            Placeholder text for password page terms and conditions
          "
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="105" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,105"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Terms and Conditions that will be shown on the password page.</span
          >
        </p>
      </div>
    </div>

    <ng-container *ngIf="legalFormGroup">
      <rn-text-input
        [inputLabel]="'Client terms'"
        [inputControl]="legalFormGroup.controls['terms']"
        [placeholderText]="'URL'"
        [isWide]="true"
      ></rn-text-input>
      <rn-text-input
        [inputLabel]="'Client privacy policy'"
        [inputControl]="legalFormGroup.controls['privacy_policy']"
        [placeholderText]="'URL'"
        [isWide]="true"
      ></rn-text-input>
    </ng-container>
  </div>
</ng-container>

<!--New Feed-->
<ng-container *ngIf="this.client?.feed_enabled">
  <ng-container
    *ngIf="this.client && this.currentGroup && this.client.fdic_enabled"
  >
    <client-header
      [headerLabel]="'FDIC Signage'"
      [headerGroup]="'fdicSignage'"
      (click)="headerGroups.fdicSignage = !headerGroups.fdicSignage"
    ></client-header>

    <!--FDIC inputs go here-->
    <div
      class="collapse-container fdic-container row"
      [formGroup]="fdicSignageFormGroup"
      *ngIf="
        headerGroups.fdicSignage &&
        client &&
        currentGroup &&
        fdicSignageFormGroup
      "
    >
      <div class="col-6">
        <div class="input">
          <div class="label-tip">
            <label for="global-fdic-signage-select">Feed FDIC signage</label>
          </div>
          <p class="helper-text">
            This will be displayed statically, at the top of the feed.
          </p>
          <select
            id="global-fdic-signage-select"
            class="form-control select-container"
            name="global-fdic-signage-select"
            [formControl]="globalFdicSignageCtl"
          >
            <option
              *ngFor="let option of this.globalFdicSignageOptions"
              [value]="option.value"
              [selected]="globalFdicSignageCtl.value === option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <br />
        <div class="input-container">
          <div class="input">
            <label i18n="Label for non-deposit language"
              >Non-deposit language</label
            >
            <p class="helper-text">
              This can be displayed globally, at a message level, and/or within
              defined link pop-ups.
            </p>
            <rn-form-input-state-control
              [formReference]="nonDepositLanguageCtl"
              [showCharacterCount]="this.client.feed_enabled"
              [totalLength]="getCharacterLimit('fdicNonDepositLanguage')"
            >
              <ng-container>
                <textarea
                  placeholder="Non-deposit products are not insured by the FDIC, are not deposits, and may lose value."
                  id="non-deposit-language-input"
                  i18n-placeholder="
                    Non-deposit products are not insured by the FDIC, are not
                    deposits, and may lose value.
                  "
                  spellcheck="false"
                  (blur)="handleNonDepositLanguageFocusOut()"
                  [formControl]="nonDepositLanguageCtl"
                ></textarea>
              </ng-container>
            </rn-form-input-state-control>
          </div>
        </div>
        <div class="label-tip">
          <label for="fdic-show-signage-on-authentication"
            >Enable FDIC-insured signage on signup, login, and verification
            screens</label
          >
        </div>
        <p class="helper-text">
          Official FDIC-insured signage will be displayed at the top of all
          screens related to login.
        </p>
        <app-radio-btn
          id="fdic-show-signage-on-authentication"
          [btnWidth]="'auto'"
          [btnLabel]="''"
          [btnName]="'fdic_show_signage_on_authentication'"
          [(btnModel)]="currentGroup.fdic.show_signage_on_authentication"
          style="text-align: left"
        ></app-radio-btn>
      </div>
      <div class="col"></div>
      <div class="col-5">
        <generic-phone>
          <product-group-preview-react
            [productGroupDescription]="currentGroup.description"
            [productGroupName]="currentGroup.name"
            [showProductGroup]="!currentGroup.hide_pg_wire_display_name"
            [bannerUrl]="currentGroup.branding.banner_s3_url"
            [logoUrl]="currentGroup.branding.icon_s3_url"
            [color]="currentGroup.branding.color"
            [fdic]="currentFDICValues()"
            [showFDIC]="true"
          ></product-group-preview-react>
        </generic-phone>
      </div>
    </div>
  </ng-container>
  <client-header
    [headerLabel]="'Disclaimer Settings'"
    [headerGroup]="'boringLegalStuff'"
    (click)="headerGroups.boringLegalStuff = !headerGroups.boringLegalStuff"
  ></client-header>

  <div
    class="collapse-container boring-legal-stuff-container"
    [formGroup]="legalFormGroup"
    *ngIf="
      headerGroups.boringLegalStuff && client && currentGroup && legalFormGroup
    "
  >
    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Disclaimer that appears at end of every message/feed"
            >Disclaimer</label
          >
        </div>

        <div style="display: flex">
          <rn-form-input-state-control
            [formReference]="legalFormGroup.controls['disclaimer.text']"
            [showCharacterCount]="true"
            [totalLength]="getCharacterLimit('disclaimer')"
          >
            <ng-container>
              <app-tiny-editor
                class="tiny-editor-mentions"
                [elementId]="'tiny-editor-disclaimer'"
                [formControl]="legalFormGroup.controls['disclaimer.text']"
              >
              </app-tiny-editor>
            </ng-container>
          </rn-form-input-state-control>
          <p class="tip-text">
            <svg width="15" height="60" style="float: left">
              <polyline
                points="8,0 8,24, 2,30 8,36 8,80"
                style="fill: none; stroke: #5b5a59; stroke-width: 1"
              />
            </svg>
            <span
              >Will appear in all Feed locations selected below.
              <p>
                <strong
                  >Character limit:
                  {{ getCharacterLimit('disclaimer') }}.</strong
                >
              </p></span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="disclaimer-toggle-container" style="margin-top: 40px">
      <div class="label-tip">
        <label>Disclaimer Display Locations</label>
      </div>

      <app-radio-btn
        id="feed-menu-disclaimer-flag"
        [btnLabel]="'Feed Menu'"
        [btnName]="'message_details_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.feed_menu_enabled"
      ></app-radio-btn>
      <p class="helper-text">
        Will display at the bottom of the expanded Feed menu.
        <a
          class="view-example-link"
          (click)="this.feedMenuExampleDialog.showMessage()"
          >View example.</a
        >
      </p>

      <app-radio-btn
        id="feed-view-disclaimer-flag"
        [btnLabel]="'Feed View'"
        [btnName]="'feed_view_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.feed_view_enabled"
      ></app-radio-btn>
      <p class="helper-text">
        Will display sticky to the bottom of the feed. Can be dismissed.
        <a
          class="view-example-link"
          (click)="this.feedViewExampleDialog.showMessage()"
          >View example.</a
        >
      </p>

      <app-radio-btn
        id="message-details-disclaimer-flag"
        [btnLabel]="'Message Details'"
        [btnName]="'message_details_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.message_details_enabled"
      ></app-radio-btn>
      <p class="helper-text">
        Will display at the bottom of the message details page.
        <a
          class="view-example-link"
          (click)="this.messageDetailsExampleDialog.showMessage()"
          >View example.</a
        >
      </p>

      <app-radio-btn
        id="message-level-disclaimer-flag"
        [btnLabel]="'Message Level'"
        [btnName]="'message_level_disclaimer_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.disclaimer.message_level_enabled"
      ></app-radio-btn>
      <p class="helper-text">
        Will enable the capability to display a disclaimer inside a message.
        <a
          class="view-example-link"
          (click)="this.messageLevelExampleDialog.showMessage()"
          >View example.</a
        >
      </p>
      <p class="helper-text-bold">
        This disclaimer can be enabled and edited within individual messages in
        Experience Builder.
      </p>
    </div>
  </div>

  <client-header
    [headerLabel]="'Terms & Conditions Settings'"
    [headerGroup]="'termsAndConditions'"
    (click)="headerGroups.termsAndConditions = !headerGroups.termsAndConditions"
    *ngIf="this.client?.feed_enabled"
  ></client-header>

  <div
    class="collapse-container terms-and-conditions-container"
    [formGroup]="legalFormGroup"
    *ngIf="
      headerGroups.termsAndConditions &&
      client &&
      currentGroup &&
      legalFormGroup
    "
  >
    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for express consent"
            >Express consent Ts&Cs</label
          >
        </div>

        <div style="display: flex">
          <rn-form-input-state-control
            [formReference]="
              legalFormGroup.controls['consent.express_consent_ts_cs']
            "
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('expressConsentTsCs')"
          >
            <ng-container>
              <app-tiny-editor
                class="tiny-editor-mentions"
                [elementId]="'tiny-editor-express-consent'"
                [formControl]="
                  legalFormGroup.controls['consent.express_consent_ts_cs']
                "
                i18n-placeholder="
                  Placeholder text for express consent terms and conditions
                "
              >
              </app-tiny-editor>
            </ng-container>
          </rn-form-input-state-control>

          <p class="tip-text">
            <svg width="15" height="80" style="float: left">
              <polyline
                points="8,0 8,24, 2,30 8,36 8,80"
                style="fill: none; stroke: #5b5a59; stroke-width: 1"
              />
            </svg>
            <span
              >Terms and Conditions that will be shown to a customer when
              offering Express Consent. Standard Terms and Conditions will be
              used if no custom consent language is provided.</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for express written consent"
            >Express written consent Ts&Cs</label
          >
        </div>

        <div style="display: flex">
          <rn-form-input-state-control
            [formReference]="
              legalFormGroup.controls['consent.express_written_consent_ts_cs']
            "
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('expressWrittenConsentTsCs')"
          >
            <ng-container>
              <app-tiny-editor
                class="tiny-editor-mentions"
                [elementId]="'tiny-editor-express-written-consent'"
                [formControl]="
                  legalFormGroup.controls[
                    'consent.express_written_consent_ts_cs'
                  ]
                "
                i18n-placeholder="
                  Placeholder text for express consent terms and conditions
                "
              >
              </app-tiny-editor>
            </ng-container>
          </rn-form-input-state-control>

          <p class="tip-text">
            <svg width="15" height="105" style="float: left">
              <polyline
                points="8,0 8,24, 2,30 8,36 8,105"
                style="fill: none; stroke: #5b5a59; stroke-width: 1"
              />
            </svg>
            <span
              >Terms and Conditions that will be shown to a customer when
              offering Express Written Consent. Standard Terms and Conditions
              will be used if no custom consent language is provided.</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="input-container" style="margin-bottom: 40px">
      <div class="input">
        <div class="label-tip">
          <label i18n="Terms and conditions for in feed consent upgrade"
            >In Feed Consent Upgrade Ts&Cs</label
          >
        </div>

        <div style="display: flex">
          <rn-form-input-state-control
            [formReference]="iwcuFormCtl"
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('inFeedConsentUpgradeTsCs')"
          >
            <ng-container>
              <app-tiny-editor
                class="tiny-editor-mentions"
                [class.has-error]="!iwcuFormCtl.valid && iwcuFormCtl.touched"
                [elementId]="'tiny-editor-in-wire-upgrade'"
                [formControl]="iwcuFormCtl"
                i18n-placeholder="
                  Placeholder text for in {{this.client?.feed_enabled ? 'feed' :
                  'wire'}} consent upgrade terms and conditions
                "
              >
              </app-tiny-editor>
            </ng-container>
          </rn-form-input-state-control>

          <p class="tip-text">
            <svg width="15" height="105" style="float: left">
              <polyline
                points="8,0 8,24, 2,30 8,36 8,105"
                style="fill: none; stroke: #5b5a59; stroke-width: 1"
              />
            </svg>
            <span
              >Terms and Conditions that will be shown to a customer when
              offering In Feed Consent Upgrade.</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="
      client && currentGroup && legalFormGroup && currentGroup.password?.enabled
    "
  >
    <client-header
      [headerLabel]="'Password Page Legal Settings'"
      [headerGroup]="'passwordLegalSettings'"
      (click)="
        headerGroups.passwordLegalSettings = !headerGroups.passwordLegalSettings
      "
      id="password-legal-settings-header"
    >
    </client-header>
    <div
      class="collapse-container password-legal-settings-container"
      [formGroup]="legalFormGroup"
      *ngIf="headerGroups.passwordLegalSettings"
    >
      <app-radio-btn
        id="password-tc-enabled-flag"
        [btnLabel]="'Display Terms & Conditions'"
        [btnName]="'password_tc_enabled_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.password.tc_enabled"
      ></app-radio-btn>
      <p class="helper-text">
        Will display Terms and Conditions on the Password page.
      </p>

      <div
        class="input-container"
        *ngIf="currentGroup.password.tc_enabled"
        style="margin-left: 30px"
      >
        <div class="input">
          <div class="label-tip">
            <label i18n="Terms and conditions for in feed consent upgrade"
              >Password Page Ts&Cs</label
            >
          </div>

          <div style="display: flex">
            <rn-form-input-state-control
              [formReference]="legalFormGroup.controls['password.tcs']"
              [showCharacterCount]="this.client.feed_enabled"
              [totalLength]="getCharacterLimit('passwordTsCs')"
            >
              <ng-container>
                <app-tiny-editor
                  class="tiny-editor-mentions"
                  [elementId]="'tiny-editor-password-tcs'"
                  [formControl]="legalFormGroup.controls['password.tcs']"
                  i18n-placeholder="
                    Placeholder text for password page terms and conditions
                  "
                >
                </app-tiny-editor>
              </ng-container>
            </rn-form-input-state-control>

            <p class="tip-text">
              <svg width="15" height="105" style="float: left">
                <polyline
                  points="8,0 8,24, 2,30 8,36 8,105"
                  style="fill: none; stroke: #5b5a59; stroke-width: 1"
                />
              </svg>
              <span
                >Terms and Conditions that will be shown on the password
                page.</span
              >
            </p>
          </div>
        </div>
        <app-radio-btn
          id="password-tc-mandatory-flag"
          [btnLabel]="'Require Accept & Agree'"
          [btnName]="'password_mandatory_disclaimer_flag'"
          [btnWidth]="'460px'"
          [(btnModel)]="currentGroup.password.tc_mandatory"
        ></app-radio-btn>
        <p class="helper-text">
          Customer will be required to accept and agree to Terms and Conditions
          before setting a password.
        </p>
      </div>
      <app-radio-btn
        id="consent-upgrade-enabled-flag"
        [btnLabel]="'Display Consent Upgrade'"
        [btnName]="'consent_upgrade_enabled_flag'"
        [btnWidth]="'460px'"
        [(btnModel)]="currentGroup.password.consent_upgrade_enabled"
      ></app-radio-btn>
    </div>
  </ng-container>

  <client-header
    [headerLabel]="'Additional Legal Settings'"
    [headerGroup]="'additionalLegalSettings'"
    (click)="
      headerGroups.additionalLegalSettings =
        !headerGroups.additionalLegalSettings
    "
  ></client-header>

  <div
    class="collapse-container additional-legal-settings-container"
    [formGroup]="legalFormGroup"
    *ngIf="
      headerGroups.additionalLegalSettings &&
      client &&
      currentGroup &&
      legalFormGroup
    "
  >
    <ng-container *ngIf="legalFormGroup">
      <rn-text-input
        [inputLabel]="'Client terms'"
        [inputControl]="legalFormGroup.controls['terms']"
        [placeholderText]="'URL'"
        [isWide]="true"
      ></rn-text-input>
      <rn-text-input
        [inputLabel]="'Client privacy policy'"
        [inputControl]="legalFormGroup.controls['privacy_policy']"
        [placeholderText]="'URL'"
        [isWide]="true"
      ></rn-text-input>
    </ng-container>
  </div>

  <message-dialog
    #feedMenuExampleDialog
    [showAffirm]="false"
    [showClose]="true"
    class="view-example-dialog-window"
  >
    <div class="view-example-dialog-text">
      <h3>Feed Menu Disclaimer Location</h3>
      <p>
        This disclaimer is located at the bottom of the feed navigation when it
        is expanded from the navication icon. It is displayed in a scrollable
        area.
      </p>
    </div>
    <img src="/assets/disclaimer-example-images/Feed_Menu_Disclaimer.png" />
  </message-dialog>

  <message-dialog
    #feedViewExampleDialog
    [showAffirm]="false"
    [showClose]="true"
    class="view-example-dialog-window"
  >
    <div class="view-example-dialog-text">
      <h3>Feed View Disclaimer Location</h3>
      <p>
        This disclaimer is located at the bottom of the Feed in a sticky
        scrollable area. It can be dismissed by customers.
      </p>
    </div>
    <img src="/assets/disclaimer-example-images/Feed_View_Disclaimer.png" />
  </message-dialog>

  <message-dialog
    #messageDetailsExampleDialog
    [showAffirm]="false"
    [showClose]="true"
    class="view-example-dialog-window"
  >
    <div class="view-example-dialog-text">
      <h3>Message Details Disclaimer Location</h3>
      <p>
        This disclaimer is located at the bottom of the Message Details page.
      </p>
    </div>
    <img
      src="/assets/disclaimer-example-images/Message_Details_Disclaimer.png"
    />
  </message-dialog>

  <message-dialog
    #messageLevelExampleDialog
    [showAffirm]="false"
    [showClose]="true"
    class="view-example-dialog-window"
  >
    <div class="view-example-dialog-text">
      <h3>Message Level Disclaimer Location</h3>
      <p>
        This disclaimer is located within a message and will be visible on both
        the Feed and the Message Details page. These disclaimers need to be
        activated per message and can be edited in Experience Builder at the
        message level.
      </p>
    </div>
    <img src="/assets/disclaimer-example-images/Message_Level_Disclaimer.png" />
  </message-dialog>
</ng-container>

<div class="button-container clearfix" *ngIf="currentGroup">
  <button id="prev-btn" class="btn btn-primary" (click)="cancelCurrent()">
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label" i18n="Label for cancel button"> Previous </span>
  </button>

  <button
    id="next-btn"
    class="btn btn-primary btn-with-icon"
    (click)="saveProduct()"
    [class.inactive]="!legalFormGroup.valid || (client.fdic_enabled && !fdicSignageFormGroup.valid)"
  >
    <span class="btn-label" i18n="Label for save button"> Save And Next </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
