import React, { useEffect } from 'react';
import { PushAPIService } from '@app/api-mgmt/components/push-api/push-api-service';
import { useStyletron } from 'styletron-react';
import { Checkbox } from 'baseui/checkbox';
import { CheckboxCheckedOverride, CheckboxUncheckedOverride } from '@app/react-baseweb/baseweb-custom-theme/customTheme';

export interface EventSubscriptionsInputProps {
  initialState?: string[];

  existingIntegration?: boolean;
  /**
   * Called when the user updates the Event Subscription selection
   */
  onUpdate?: (value: string[]) => void;
}

function arraysEqual(a: string[], b: string[]) {
  return b.every((element) => a.includes(element));
}

export const EventSubscriptionsInput = ({
  initialState,
  existingIntegration,
  onUpdate,
}: EventSubscriptionsInputProps) => {
  const [css] = useStyletron();

  const [eventList, setEventList] = React.useState<string[]>([]);
  const [selections, setSelections] = React.useState(initialState);
  const [allSelected, setAllSelected] = React.useState(
    arraysEqual(selections, eventList),
  );

  async function fetchEventList() {
    const fetchedEvents = await PushAPIService.getEventSubscriptionList();
    setEventList(fetchedEvents);
  }

  // fetch event list initially
  useEffect(() => {
    fetchEventList();
  }, []);

  // propagate changes to selections externally via onUpdate
  useEffect(() => {
    onUpdate?.(selections);
  }, [selections]);

  // ensure that the state of the "select all" checkbox is accurate
  useEffect(() => {
    setAllSelected(arraysEqual(selections, eventList));
  }, [selections, eventList]);

  // initialize form with all events on for new, not yet created integration
  useEffect(() => {
    if (existingIntegration === false) {
      setSelections(eventList);
    }
  }, [eventList]);

  function handleChange(key: string) {
    if (selections.includes(key)) {
      setSelections(selections.filter((s) => s !== key));
    } else {
      setSelections(selections.concat(key));
    }
  }

  function toggleAll() {
    if (allSelected) {
      setSelections([]);
    } else {
      setSelections(eventList);
    }
  }

  return (
    <>
      <div
        className={css({
          paddingTop: '1em',
          paddingBottom: '1em',
          width: '100%',
        })}
      >
        <label>Events</label>
        <span style={{ fontSize: '16px' }}> (Optional)</span>
        <div style={{  }}>
          <div style={{ }}>
            <Checkbox
              checked={allSelected}
              onChange={toggleAll}
              overrides={{
                Checkmark: allSelected ? CheckboxCheckedOverride : CheckboxUncheckedOverride,
              }}
            >
              Select All
            </Checkbox>
          </div>
          <div style={{ }}>
            <div style={{ display: 'inline-block', margin: '0 1em -5px 1em' }}>
              {eventList.slice(0, eventList.length / 2).map((eventName, index) => {
                return (
                    <Checkbox
                      key={eventName}
                      checked={selections.includes(eventName)}
                      onChange={() => handleChange(eventName)}
                      overrides={{
                        Checkmark: selections.includes(eventName)
                          ? CheckboxCheckedOverride
                          : CheckboxUncheckedOverride,
                      }}
                    >
                      {eventName}
                    </Checkbox>
                );
              })}
            </div>
            <div style={{ display: 'inline-block', margin: '-5px 1em 0 1em' }}>
            {eventList.slice(eventList.length / 2, eventList.length).map((eventName, index) => {
              return (
                  <Checkbox
                    key={eventName}
                    checked={selections.includes(eventName)}
                    onChange={() => handleChange(eventName)}
                    overrides={{
                      Checkmark: selections.includes(eventName)
                        ? CheckboxCheckedOverride
                        : CheckboxUncheckedOverride,
                    }}
                  >
                    {eventName}
                  </Checkbox>
              );
            })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
