import { 
    Directive, 
    Input, 
    Host, 
    Self, 
    Optional 
} from '@angular/core';

@Directive({
    selector: '[disableAutoComplete]',
    host: {
        "autocomplete": "new-password"
    }
})
export class DisableAutocompleteDirective {
    constructor(
      @Host() @Self() @Optional() public host : Input) { }
      
}