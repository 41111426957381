import {
  Message as MessageComponent,
  StyleProvider,
  defaultBrandingData,
  BrandingData,
  DisclaimerContainer,
} from '@relaynetwork/design-system';
import { Message } from '@relaynetwork/rn-node-feed-api-types';
import React, { useMemo } from 'react';

type WirePreviewProps = {
  message: Message;
  brandingColor: string;
  disclaimerConfig: BrandingData['disclaimer'];
  isDestinationMessageDetail?: boolean;
  previewMode: 'expanded' | 'collapsed';
};

function WirePreview({
  message,
  brandingColor,
  disclaimerConfig,
  isDestinationMessageDetail = false,
  previewMode,
}: WirePreviewProps) {
  const brandingData = useMemo(
    () => ({
      ...defaultBrandingData,
      color: brandingColor,
      disclaimer: disclaimerConfig,
    }),
    [brandingColor],
  );
  return (
    <StyleProvider brandingData={brandingData}>
      <MessageComponent message={message} isPreview previewMode={previewMode} />
      {isDestinationMessageDetail &&
        brandingData.disclaimer.messageDetailsEnabled && (
        <DisclaimerContainer content={brandingData.disclaimer.text ?? ''} expandMaxHeight={previewMode === 'expanded'} />
        )}
    </StyleProvider>
  );
}

export default WirePreview;
