export class JourneyListUtils {
    isOutOfBoxExperienceEnabled: boolean;

    get columnNameClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-14' : 'colw-16';
    }

    get columnProductGroupClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-11' : 'colw-14';
    }

    get columnCreatedByClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-11' : 'colw-14';
    }

    get columnCampaignClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-8' : 'colw-10';
    }

    get columnDateCreatedClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-8' : 'colw-10';
    }

    get columnDateModifiedClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-8' : 'colw-10';
    }

    get columnStatusClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-10' : 'colw-12';
    }

    get columnActionIconsClassWidth(): string {
        return (this.isOutOfBoxExperienceEnabled) ? 'colw-10' : 'colw-13';
    }
}
