
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError,  map, switchMap, flatMap, mergeMap } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '../core/models/client';
import { LoginQR, LoginTOTP, User } from '../core/models/user';
import { LoadingMaskService } from '../core/services/loading-mask.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@app/core/services/logger.service';
import { AnalyticsService } from '@app/core/services/analytics.service';

// adds rn_user to global window object
declare global {
  interface Window {
    rn_user: any;
    rn_user_email_address: string;
  }
}

/**
 * As a general note, this service is used by a lot of components AND services,
 * so broken tests are often a result of this service not being properly mocked.
 */
@Injectable()
export class SessionService {

  loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();
  allClients: Client[] = [];
  sessionTimeoutEvent = new EventEmitter<boolean>();
  currentUser: User;
  // For getting the value of the current client 
  selectedClient: BehaviorSubject<string> = new BehaviorSubject(this.getCurrentClientFromLocalStorage());
  // for triggering events when the current client actually changes (and getting the diff)
  clientChanged: EventEmitter<{ current: string, prev: string }> = new EventEmitter();
  private readonly url = `${environment.authURLBase}/sessions`;
  private readonly portalUrl = `${environment.portalURLBase}`;

  constructor(private router: Router,
              private loadingMaskService: LoadingMaskService,
              private http: HttpClient,
              private analyticsService: AnalyticsService) {
      this.clientChanged.subscribe((client) => {
        this.analyticsService.track('UserAction', 'ClientChanged', { new_client_id: client });
      });
  }

  /**
   * Checks for session timeout redirect to /login if it has timed out.
   *
   * Returns true if is OK to proceed with normal handling (i.e. no session time out detected).
   */
  checkSessionTimeout(response: Response) {
    if (response.status === 401 || response.status === 403) {
      this.logout();
      this.sessionTimeoutEvent.emit(true);
      this.loadingMaskService.loadingOff(true);
      this.router.navigateByUrl('/logout');
      return false;
    } else {
      return true;
    }
  }

  getCurrentUser(): Observable<User> {
    if (this.currentUser) {
      LoggerService.log('SessionService ', 'has currentUser');
      return of(this.currentUser);
    } else {
      if (!this.hasUserInLocalStorage()) {
        LoggerService.log('SessionService ', 'no user in local storage');
        return of(undefined); // when there is no user
      }
      LoggerService.log('SessionService ', 'requesting user');
      const user = this.getUserFromLocalStorage();
      const userId = user['id'];
      const clientId = user['clientId'];
      const url = `${this.portalUrl}/client/${clientId}/users/${userId}`;
      const options = {withCredentials: true};

      return this.http.get(url, options).pipe(
        map(response => {
          const thisUser = User.deserialize(response['portal_user']);
          localStorage.setItem('clientId', clientId);
          return thisUser;
        }),
        flatMap(thisUser => {
          this.setWalkMeVariable(thisUser);
          this.currentUser = thisUser;
          const storedClient = this.getCurrentClientFromLocalStorage();
          const clientLookupID = storedClient ?? this.currentUser.clientId;
  
          return this.getClient(clientLookupID)
        }),
        switchMap((client) =>  {
          return of(this.handleClient(client));
        })
      ).pipe(catchError((error) => {
        this.deleteSession();
        LoggerService.log('SessionService', 'Error logging user in');
        return of(undefined);
      }));
    }
  }

  /**
   * Checks for session timeout redirect to /login if it has timed out.
   *
   * Returns true if is OK to proceed with normal handling (i.e. no session time out detected).
   */
  checkSessionTimeoutXhr(xhr: XMLHttpRequest) {
    if (xhr.status === 401 || xhr.status === 403) {
      this.logout();
      this.sessionTimeoutEvent.emit(true);
      this.router.navigateByUrl('/logout');
      return false;
    } else {
      return true;
    }
  }

  resetPassword(token: string, password: string) { 
    return this.http.post(`${this.portalUrl}/reset-password`, {token, password});
  }

  login(email_address: string, password: string): Observable<User | LoginQR | LoginTOTP> {
    return this.http.post(`${this.portalUrl}/login`, {email_address, password}).pipe(
      map((response) => {
        if (response && (response['qr'] || response['totp'])) {
          return response as LoginQR | LoginTOTP;
        } else {
          return this.deserializeUser(response)
        }
      }),
      flatMap((resp) => {
        if (resp instanceof User) {
          return this.setUpUser(resp);
        }
        return of(resp)
      })
    )
  }

  verifyMFACode(code: string): Observable<User> {
    return this.http.post(`${this.portalUrl}/verify`, {code}).pipe(
      map((response) => this.deserializeUser(response)),
      mergeMap((user)=> this.setUpUser(user))
    )
  }

  resetMFASecret(email_address: string): Observable<any> {
    return this.http.post(`${this.portalUrl}/reset-mfa`, {email_address})
  }

  ssoLogin(authToken: string, clientId: string): Observable<any> {
    return this.http
      .post(`${environment.portalURLBase}/client/${clientId}/sso`, {
        code: authToken,
      })
      .pipe(
        map((response) => this.deserializeUser(response)),
        mergeMap((user) => this.setUpUser(user)),
      );
  }

  forgotPassword(emailAddress): Observable<any> {
    return this.http.post(`${environment.portalURLBase}/forgot-password`, {emailAddress});
  }

  resetPasswordAdmin(emailAddress): Observable<any> {
    return this.http.post(`${environment.portalURLBase}/client/${this.currentUser.clientId}/users/forgot-password`, { emailAddress });
  }

  reloadClient() {
    this.getClient(this.currentUser.client.id)
        .subscribe(client => this.currentUser.client = client);
  }

  getCurrentUsersClient(): Client {
    return this.currentUser.client;
  }

  getClient(clientId: string): Observable<Client> {
    // Don't use client service for this, so we can avoid circular dependencies of @Injectables.
    const url = `${environment.configURLBase}/client/${clientId}`;
    const options = {withCredentials: true};
    return this.http.get(url, options).pipe(
      map(response => Client.deserialize(response['client']))
    );
  }

  hasUserInLocalStorage(): boolean {
    return this.getUserFromLocalStorage() != null;
  }

  logout() {
    this.loggedIn.next(false);
    this.deleteCurrentClientFromLocalStorage();
    this.allClients = [];
    localStorage.setItem('clientId', null);
    this.deleteSession();
  }

  deleteSession() {
    this.deleteUserInLocalStorage();
    const url = `${this.portalUrl}/logout`;
    this.http.post(url, {}, ).subscribe();
  }

  setClient(client: Client) {
    const prevClient = this.currentUser.client ? this.currentUser.client.id : null;
    this.currentUser.client = client;
    this.loggedIn.next(true);
    this.selectedClient.next(client.id);
    this.setCurrentClientInLocalStorage(client.id);
    this.setUserInLocalStorage(this.currentUser);
    this.analyticsService.trackClientId(client.id);
    this.clientChanged.emit({current: client.id, prev: prevClient});
  }

  private deserializeUser(response: any): User {
    if (response && response['user']) {
      return User.deserialize(response['user']);
    } else {
      throw new Error('Error in response');
    }
  }

  private setUpUser(user: User): Observable<User> {
    this.currentUser = user;
    this.setWalkMeVariable(this.currentUser);
    const storedClient = this.getCurrentClientFromLocalStorage();
    const clientLookupID = storedClient ?? this.currentUser.clientId;
    return this.getClient(clientLookupID).pipe(
      map(client => {
        this.setClient(client);
        this.setUserInLocalStorage(this.currentUser);
        return this.currentUser;
      })
    )
  }

  private setWalkMeVariable(userData: User): void {
    window.rn_user = {};
    window.rn_user.secondary_clients = userData.secondary_clients;
    window.rn_user.clientId = userData.clientId;
    window.rn_user.client_id = userData.client_id;
    window.rn_user.created_at = userData.created_at;
    window.rn_user.updated_at = userData.updated_at;
    window.rn_user.first_name = userData.first_name;
    window.rn_user.last_name = userData.last_name;
    window.rn_user.id = userData.id;
    window.rn_user.last_login = userData.last_login;
    window.rn_user.permissions = userData.permissions;
    window.rn_user.role_id = userData.role_id;
    window.rn_user.email_address = userData.email_address;
    window.rn_user.abbreviatedName = userData.abbreviatedName;
    window.rn_user_email_address = userData.email_address;
  }

  private setCurrentClientInLocalStorage(clientId) {
    this.analyticsService.trackClientId(clientId);
    localStorage.setItem('current_client', clientId);
  }

  private getCurrentClientFromLocalStorage() {
    return localStorage.getItem('current_client');
  }

  private deleteCurrentClientFromLocalStorage() {
    localStorage.removeItem('current_client');
  }

  private setUserInLocalStorage(user: User) {
    this.analyticsService.trackUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  private deleteUserInLocalStorage() {
    this.analyticsService.trackUser();
    localStorage.removeItem('user');
  }

  private getUserFromLocalStorage(): Object  {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : userData;
  }

  private handleClient(client: Client): User {
    this.setClient(client);
    this.loggedIn.next(true);

    this.setUserInLocalStorage(this.currentUser);

    return this.currentUser;
  }
}
