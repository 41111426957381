import axios from 'axios';
import { environment } from '@env/environment';
import { AuditEntry } from './types/audit-entry';

export const client = axios.create({
  baseURL: environment.pushAPIURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
  responseType: 'json',
});

export const AuditEntriesService = {
  queryAuditEntries: async (
    clientId: string,
    uid: string,
    order_by: string,
    reverse: boolean,
    limit?: number,
    offset?: number,
  ) => {
    const path = `/${clientId}/configuration/${uid}/changelog?order_by=${order_by}&order_reverse=${
      reverse ? 'true' : 'false'
    }&limit=${limit}&offset=${offset}`;
    const payload = await client.get<AuditEntry[]>(path);
    return payload.data ?? [];
  },
};
