export class Customer {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  ccid: string;
  secondary_account_id: string;
  date_of_birth: string;
  telephone: string;
  notification_channels: ChannelAddress[];
  telephones: ChannelAddress[];
  consent_type: string;
  selected: boolean = false;
  deactivated: boolean;
  ssn_last4: string;
  consents: Consent[] = [];
  addresses: CustomerAddress[];
  ext: CustomerExt;
  client_id: string;
  product_group_name: string;
  emails: CustomerEmail[];
  product_group_id: string;
  created_at: Date;
  updated_at: Date;

  static deserialize(input: Customer): Customer {
    const customer = new Customer();
    customer.id = input.id;
    customer.first_name = input.first_name;
    customer.last_name = input.last_name;
    customer.name =
      input.first_name + ' ' + (input.last_name ? input.last_name : null);
    customer.ccid = input.ccid;
    customer.secondary_account_id = input.secondary_account_id;
    customer.date_of_birth = input.date_of_birth;
    if (input.notification_channels && input.notification_channels.length) {
      const firstSMS = input.notification_channels.find(
        (c) => c.channel_type === 'sms'
      );
      if (firstSMS) {
        customer.telephone = firstSMS.channel_addr;
      }
    }
    if (input.notification_channels && input.notification_channels.length) {
      customer.notification_channels = input.notification_channels.map((ch) =>
        ChannelAddress.deserialize(ch)
      );
    } else if (input.telephones && input.telephones.length) {
      customer.notification_channels = input.telephones.map((ch) =>
        ChannelAddress.deserialize(ch)
      );
    }
    customer.consent_type =
      input.notification_channels && input.notification_channels.length
        ? input.notification_channels[0].consent_type
        : undefined;
    customer.selected = input.selected;
    customer.deactivated = input.deactivated ?? false;
    customer.ssn_last4 = input.ssn_last4;
    customer.consents = input.consents
      ? input.consents.map((consent) => Consent.deserialize(consent))
      : [];
    customer.addresses = input.addresses
      ? input.addresses.map((address) => CustomerAddress.deserialize(address))
      : [];
    customer.ext = input.ext;
    customer.client_id = input.client_id;
    customer.product_group_name = input.product_group_name;
    customer.emails = input.emails;
    customer.product_group_id = Customer.parseProductGroupId(
      input['product_group_id']
    );
    customer.created_at = input.created_at;
    customer.updated_at = input.updated_at;
    return customer;
  }

  static hasTelephone(customer: Customer): boolean {
    return (
      customer.notification_channels?.length > 0
    );
  }

  static parseProductGroupId(productGroupIdInput: any) {
    if (Array.isArray(productGroupIdInput) && productGroupIdInput.length >= 1) {
      return productGroupIdInput[0];
    } else {
      return 'default';
    }
  }
}

export class CustomerEmail {
  consent_type: ConsentType;
  email_address: string;
  static deserialize(input: CustomerEmail): CustomerEmail {
    const customerEmail = new CustomerEmail();
    customerEmail.consent_type = input.consent_type;
    customerEmail.email_address = input.email_address;
    return customerEmail;
  }
}

export class Consent {
  public client_id: string;
  public channel_addr: string;
  public channel_type: string;
  public consent_type: ConsentType;
  public source_channel: ConsentSourceChannelType;
  public created_at: string;

  static deserialize(input: Consent): Consent {
    const consent = new Consent();
    consent.channel_addr = input.channel_addr;
    consent.channel_type = input.channel_type;
    consent.client_id = input.client_id;
    consent.consent_type = input.consent_type;
    consent.source_channel = input.source_channel;
    consent.created_at = input.created_at;
    return consent;
  }
}

export type ConsentSourceChannelType = 'api' | 'csr';

export type ConsentType = 'written' | 'express' | 'stop';

export type ConsentParts = Pick<Consent, 'channel_addr' | 'consent_type'>;

export class CustomerDetail {
  created_at: Date;
  updated_at: Date;
  data: CustomerDetailData;
  type: string;
  version: number;

  static deserialize(input: CustomerDetail): CustomerDetail {
    const customerDetail = new CustomerDetail();
    customerDetail.created_at = new Date(input.created_at);
    customerDetail.updated_at = new Date(input.updated_at);
    customerDetail.data = CustomerDetailData.deserialize(input.data);
    customerDetail.type = input.type;
    customerDetail.version = input.version;
    return customerDetail;
  }
}

export class CustomerDetailEngagement {
  client_channel: string;
  date: Date;
  channel: string;
  client_user_id: string;

  static deserialize(
    input: CustomerDetailEngagement
  ): CustomerDetailEngagement {
    const customerDetailEngagement = new CustomerDetailEngagement();
    customerDetailEngagement.client_channel = input.client_channel;
    customerDetailEngagement.date = new Date(input.date);
    customerDetailEngagement.channel = input.channel;
    customerDetailEngagement.client_user_id = input.client_user_id;
    return customerDetailEngagement;
  }
}

export class CustomerDetailConsent {
  channel: string;
  current: CustomerDetailConsentDetail;
  history: CustomerDetailConsentDetail[];
  channel_type: ChannelType;

  static deserialize(input: CustomerDetailConsent): CustomerDetailConsent {
    const customerDetailConsent = new CustomerDetailConsent();
    customerDetailConsent.channel = input.channel;
    customerDetailConsent.current = CustomerDetailConsentDetail.deserialize(
      input.current
    );
    customerDetailConsent.history = input.history.map((history) =>
      CustomerDetailConsentDetail.deserialize(history)
    );
    customerDetailConsent.channel_type = input.channel_type;
    return customerDetailConsent;
  }
}

export class CustomerDetailConsentDetail {
  date: Date;
  client_channel: string;
  modified_by: string;
  type: ConsentType;

  static deserialize(
    input: CustomerDetailConsentDetail
  ): CustomerDetailConsentDetail {
    const customerDetailConsentDetail = new CustomerDetailConsentDetail();
    customerDetailConsentDetail.date = new Date(input.date);
    customerDetailConsentDetail.client_channel = input.client_channel;
    customerDetailConsentDetail.modified_by = input.modified_by;
    customerDetailConsentDetail.type = input.type;
    return customerDetailConsentDetail;
  }
}

export type ChannelType = 'sms' | 'email';

export class EngagementHistory {
  client_channel: string;
  date: Date;
  channel: string;
  type: string;
  client_user_id: string;

  static deserialize(input: EngagementHistory): EngagementHistory {
    const engagementHistory = new EngagementHistory();
    engagementHistory.client_channel = input.client_channel;
    engagementHistory.date = new Date(input.date);
    engagementHistory.channel = input.channel;
    engagementHistory.type = input.type;
    engagementHistory.client_user_id = input.client_user_id;
    return engagementHistory;
  }
}

export class Deactivation {
  client_channel: string;
  date: Date;
  channel: string;
  client_user_id: string;

  static deserialize(input: Deactivation): Deactivation {
    const deactivation = new Deactivation();
    deactivation.client_channel = input.client_channel;
    deactivation.date = new Date(input.date);
    deactivation.channel = input.channel;
    deactivation.client_user_id = input.client_user_id;
    return deactivation;
  }
}

export class CustomerDetailData {
  engagement: CustomerDetailEngagement;
  product_group_id: string;
  verification_pending: boolean;
  client_customer_id: string;
  last_message_received_key: string;
  disabled: boolean;
  type: string;
  consents: CustomerDetailConsent[];
  last_message_received_date: Date;
  engagement_history: EngagementHistory[];
  phone_numbers: string[];
  client_id: string;
  validated: boolean;
  primary_phone_number: string;
  connection_id: string;
  deactivation: Deactivation;
  last_event_captured: string;

  static deserialize(input: CustomerDetailData): CustomerDetailData {
    const customerDetailData = new CustomerDetailData();
    customerDetailData.engagement = CustomerDetailEngagement.deserialize(
      input.engagement
    );
    customerDetailData.product_group_id = input.product_group_id;
    customerDetailData.verification_pending = input.verification_pending;
    customerDetailData.client_customer_id = input.client_customer_id;
    customerDetailData.last_message_received_key =
      input.last_message_received_key;
    customerDetailData.disabled = input.disabled;
    customerDetailData.type = input.type;
    customerDetailData.consents = input.consents.map((consent) =>
      CustomerDetailConsent.deserialize(consent)
    );
    customerDetailData.last_message_received_date = new Date(
      input.last_message_received_date
    );
    customerDetailData.engagement_history = input.engagement_history.map(
      (history) => EngagementHistory.deserialize(history)
    );
    customerDetailData.phone_numbers = input.phone_numbers;
    customerDetailData.client_id = input.client_id;
    customerDetailData.validated = input.validated;
    customerDetailData.primary_phone_number = input.primary_phone_number;
    customerDetailData.connection_id = input.connection_id;
    customerDetailData.deactivation = input.deactivation;
    customerDetailData.last_event_captured = input.last_event_captured;
    return customerDetailData;
  }
}

export class ChannelAddress {
  country_code: string;
  channel_addr: string;
  phone_number: string;
  channel_type: string;
  consent_type: ConsentType;

  static deserialize(input: ChannelAddress): ChannelAddress {
    const customerPhone = new ChannelAddress();
    customerPhone.country_code = input.country_code;
    if (input.channel_addr) {
      customerPhone.channel_addr = input.channel_addr;
    } else if (input.phone_number) {
      customerPhone.channel_addr = input.phone_number;
    }
    customerPhone.phone_number = input.phone_number;
    customerPhone.channel_type = input.channel_type;
    customerPhone.consent_type = input.consent_type;
    return customerPhone;
  }
}

export class CustomerExt {
  engagement_type: string;
  consent_type: ConsentType;
  template_code: string;

  static deserialize(input: CustomerExt): CustomerExt {
    const customerExt = new CustomerExt();
    customerExt.engagement_type = input.engagement_type;
    customerExt.consent_type = input.consent_type;
    customerExt.template_code = input.template_code;
    return customerExt;
  }
}

export class CustomerAddress {
  country: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  state_province: string;
  postal_code: string;

  static deserialize(input: CustomerAddress): CustomerAddress {
    const customerAddress = new CustomerAddress();
    customerAddress.country = input.country;
    customerAddress.address1 = input.address1;
    customerAddress.address2 = input.address2;
    customerAddress.address3 = input.address3;
    customerAddress.address4 = input.address4;
    customerAddress.city = input.city;
    customerAddress.state_province = input.state_province;
    customerAddress.postal_code = input.postal_code;
    return customerAddress;
  }
}
