export class URLUtils {
  static readonly urlWithoutProtocolRegex =
    /^([-A-Za-z0-9]+\.)*([-A-Za-z0-9]+){1}\.([-A-Za-z0-9]+){1}(\/\S*)*$/;
  static readonly urlRegex =
    /^(https?:\/\/)?\w+\.[a-zA-Z]{2,}((\/|\.)\w+)*\/?$/;
  static readonly stackOverflowURLRegex =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/;
  static readonly urlWithQuery =
    /((([A - Za - z]{3, 9 }:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  static readonly prodURL = 'https://my.relayit.com';

  static readonly doubleSlash = new RegExp(/([^:]\/)\/+/g);
  static readonly trailingSlash = new RegExp(/\/$/);

  /**
   * Returns either the staging wire URL or the production wire URL. Defaults to production.
   */
  static getWireURLDefaultToProd(): string {
    if (window.location.hostname.indexOf('staging') > -1) {
      return window.location.origin.replace(/portal/gi, 'wire');
    } else {
      return URLUtils.prodURL;
    }
  }

  /**
   * Supports returning the environment-specific url, regardless of which is passed in.
   * @param environment
   */
  static getWireURLAllEnvironments(environment: string): string {
    let url = '';
    switch (environment) {
      case 'dev':
        url = 'https://wire-dev.relaydev.com';
        break;
      case 'qa':
        url = 'https://emerald-wire-qa.relaystaging.com';
        break;
      case 'staging':
        url = 'https://v3wire.relaystaging.com';
        break;
      case 'production':
        url = 'https://my.relayit.com';
        break;
      default:
        // default to dev
        url = 'https://wire-dev.relaydev.com';
        break;
    }
    return url;
  }

  /**
   * First regex removes trailing slash, second regex removes double slashes
   * @param vanityUrl
   * @returns sanitzied vanity url without trailing or double slash
   */
  static formatVanityURL(vanityUrl: string | null): string {
    let strippedUrl = vanityUrl;
    strippedUrl = strippedUrl?.replace(this.doubleSlash, '$1');
    // if its just the protocol http:// or https:// don't do anything
    // otherwise remove trailing slashes
    if (!vanityUrl?.match(/^(https?:\/+)$/)) {
      strippedUrl = strippedUrl?.replace(this.trailingSlash, '');
    }
    return strippedUrl;
  }

  static formatMessageURL(inURL: string): string {
    let outURL = inURL.trim();
    if (!(outURL.indexOf('@') === 0)) {
      const isUrlWithoutProtocol = outURL.match(
        URLUtils.urlWithoutProtocolRegex,
      );
      if (isUrlWithoutProtocol) {
        outURL = 'http://' + outURL;
      }
    }
    return outURL;
  }
}
