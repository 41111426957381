<ng-container *ngIf="isLoaded">
  <div *ngIf="!hasError" routerLink="add-industry" class="add-industry-wrapper">
    <rn-text-input [inputLabel]="'Industry ID'" [placeholderText]="'Industry ID'"
      [inputControl]="industryInfoGroup.controls['industry_id']" [newValue]="false" [readOnly]="!isNewIndustry">
    </rn-text-input>
    <rn-text-input [inputLabel]="'Industry Name'" [placeholderText]="'Industry Name'"
      [inputControl]="industryInfoGroup.controls['industry_name']"></rn-text-input>
    <button id="add-industry-cancel-btn" class="btn btn-secondary industry-btn" type="button"
      (click)="cancelAndClose()">cancel</button>
    <button id="add-industry-save-btn" class="btn btn-primary industry-btn" type="button" (click)="saveAndClose()">save and
      exit</button>
  </div>
  <div *ngIf="hasError" class="not-found" i18n="Message that the user gets when there was an error">
    An error occured with your request, please try again later.
  </div>
</ng-container>
<app-spinner *ngIf="!isLoaded" class="not-found"></app-spinner>
<message-dialog></message-dialog>
