import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { ToolTipData } from '@app/core/utils/tooltip-utils';

@Component({
  selector: 'rn-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})

export class TextInputComponent {
  @Input() inputLabel: string = null;
  @Input() inputSubLabel: string = null;
  @Input() helpContent: string = null;
  @Input() inputControl: UntypedFormControl = null;
  @Input() type = 'text';

  @Input() placeholderText?: string = '';
  @Input() readOnly?: boolean = false;
  @Input() isWide?: boolean = false;
  @Input() isFullWidth?: boolean = false;
  @Input() untouchedIfEmpty?: boolean = false; // used for non-required fields
  @Input() newValue?: boolean = true;
  @Input() trimOnBlur: boolean = false; // only used in UserEditComponent - consider removing

  @Input() maxLength?: number = null;

  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() keyedUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurred: EventEmitter<any> = new EventEmitter<any>();

  customValidators = CustomValidators;

  /**
   * If you need to disable your text input, bind [newValue] to false, then bind
   * [readOnly] to your logic.
   */
  readOnlyAttr(): any {
    if (this.readOnly && !this.newValue) {
      return 'readonly';
    }

    return null;
  }

  isInvalidOrEmpty(): boolean {
    if (this.untouchedIfEmpty) {
      this.customValidators.markAsUntouchedOnEmpty(this.inputControl);
    }

    if (!this.inputControl.valid && this.inputControl.touched && !this.inputControl.disabled) {
      return true;
    }

    return null; // TODO: this should be a boolean
  }

  emitChangeEvent(event: string) {
    this.inputChange.emit(event);
  }

  emitInputEvent(event: any) {
    if (event.type === 'blur') {
      if (this.trimOnBlur && this.inputControl.value) {
        const control = this.inputControl;
        control.setValue(control.value.trim());
      }
      this.blurred.emit(event);
    } else if (event.type === 'keyup') {
      this.keyedUp.emit(event);
    }
  }

  formatInputID(label: string): string {
    if (!label) { return 'unknown-header'; }
    label = label.toLowerCase().replace(/\s/ig, '-') + '-header';
    return label;
  }

  getTooltipData(errors?: Object): string {
    return ToolTipData.getTooltipData(errors);
  }

  get remainingLength() {
    if (this.maxLength !== null) {
      return this.maxLength - (this.inputControl.value?.length ?? 0);
    }
  }
}
