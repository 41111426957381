<div class="file-launcher">
    
  <div class="tab-content" *ngIf="!isCompleted()">
    <div class="tab-inner-content">
      <!-- 1) Upload drop area -->
      <ng-container *ngIf="status === 'waitingForFile'">
        <div *ngIf="headerErrors" class="file-errors">
          <p>There was a problem with your file:</p>
          <ul>
            <li *ngIf="headerErrors.missing_required?.length" class="file-errors__error">
                <div>Missing Required Headers</div>
                <div class="file-errors__headers">{{headerErrors.missing_required.join(', ')}}</div>
            </li>
            <li *ngIf="headerErrors.unknown_header?.length" class="file-errors__error">
                <div>Unknown Headers</div>
                <div class="file-errors__headers">{{headerErrors.unknown_header.join(', ')}}</div>
                <div class="file-errors__info" *ngIf="headersIncludeSpaces(headerErrors.unknown_header)">One or more of your headers includes spaces, which are not allowed.</div>
            </li>

            <li *ngIf="headerErrors.unsupported_ext?.length" class="file-errors__error">
                <div>EXT fields not allowed in {{erroredFiletype}} files</div>
                <div class="file-errors__headers">{{headerErrors.unsupported_ext.join(', ')}}</div>
            </li>

            <li *ngIf="headerErrors.unsupported_input?.length" class="file-errors__error">
                <div>Input fields not allowed in {{erroredFiletype}} files</div>
                <div class="file-errors__headers">{{headerErrors.unsupported_input.join(', ')}}</div>
            </li>
          </ul>
        </div>

        <!-- if we have a trigger id, we assume that this file launcher is locked down 
             to the 'journey_message' filetype, which is the only one that takes a trigger id -->
        <div *ngIf="!triggerId" class="form-group">
          <select [(ngModel)]="selectedFiletype" class="form-control">
            <option value="messaging">Messaging</option> 
            <option value="onboarding">Onboarding</option> 
            <option value="cca">CCA</option> 
            <option value="phone_management">Phone Management</option> 
            <option value="consent_update">Consent Update</option> 
            <option value="deactivation">Deactivation</option> 
          </select>
        </div>

        <rn-upload-drop-area (fileCaptured)="startUpload($event)" [disabled]="!fileName">
          <span select-btn-text>SELECT FILE</span>
          <p description-text>File format must be CSV</p>
        </rn-upload-drop-area>

        <ng-content select="[cancel-link]"></ng-content>
      </ng-container>


      <!-- 2 ) upload progress -->
      <rn-upload-progress *ngIf="uploadStatus && status === 'uploading'" 
        [fileName]="fileName" 
        [progressData]="uploadStatus"></rn-upload-progress>  

      <!-- 3) choose run or schedule -->
      <launch-choice *ngIf="status === 'waitingForAction'"
        [message]="validationMessage | once: fileName: count"
        (launchNow)="runFile()" (launchOnTimer)="openScheduleDialog()">
      </launch-choice>
    </div>
  </div>
  
  <!-- 4) Schedule Dialog  -->
  <schedule-launch-dialog *ngIf="status === 'scheduling'"
    [showDialog]="true"
    [thingBeingLaunched]="'file'"
    [(scheduleTime)]="scheduleTime"
    (confirmTime)="scheduleFile($event)"
    (showDialogChange)="closeScheduleDialog()">
    <ng-container schedule-button-content>
      <span class="btn-label">Schedule</span>
      <span class="icon icon-right"><i aria-hidden="true" class="fa fa-history"></i></span>
    </ng-container>
  </schedule-launch-dialog>

  <!-- 5) Success Messages  -->
  <rn-launch-result *ngIf="isCompleted()"
    [leftButtonLabel]="jobsListOrMonitorBtnTxt"
    (leftButtonClick)="goToMonitorOrJobsList()"
    [rightButtonLabel]="'Upload Again'"
    (rightButtonClick)="reset()">
    <span message *ngIf="status === 'completedRun'">Your File has been added to the Queue and will run shortly</span>
    <span message *ngIf="status === 'completedScheduling'">Your File is scheduled to be added to the Queue at {{scheduleTime | date: 'short' }}.</span>
  </rn-launch-result>
  
  <message-dialog #dialog></message-dialog>
</div>
