import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { SecureHttp } from '@app/security/secure-http';
import { environment } from '@env/environment';
import {
  Category,
  CompanyType,
  ExperienceType,
  ExpLibResponse,
  Industry,
  Outcome,
} from '../../models/categorization-types';
import { CategorizationAncestorUtils, CategoryAncestryDetails } from './utils';
import { OptionsMapNode } from './';

@Injectable()
export class CategorizationService {
  experienceLibraryURLBase: string = environment.experienceLibraryURLBase;
  categorizationAncestorUtils: CategorizationAncestorUtils =
    new CategorizationAncestorUtils();

  constructor(private secureHttp: SecureHttp) {}

  /*****************************************************
   *                    Utility FNs
   *****************************************************/

  /**
   * Utility Function to get CompanyTypes for Industry
   * @param allCompanyTypes
   * @param industryAncestry
   * @returns an array of CompanyTypes that match a given Industry ID
   */
  getCompanyTypesForIndustryFullAncestorPath(
    allCompanyTypes: CompanyType[],
    industryAncestry: CategoryAncestryDetails,
  ): CompanyType[] {
    return this.categorizationAncestorUtils.getChildrenForParentAncestor(
      allCompanyTypes,
      industryAncestry,
    ) as CompanyType[];
  }

  /**
   * Utility Function to get Outcomes for Company Type
   * @param allOutcomes
   * @param companyAncestry
   * @returns an array of Outcomes that match a given Company Type
   */
  getOutcomesForCompanyTypeFullAncestorPath(
    allOutcomes: Outcome[],
    companyAncestry: CategoryAncestryDetails,
  ): Outcome[] {
    return this.categorizationAncestorUtils.getChildrenForParentAncestor(
      allOutcomes,
      companyAncestry,
    ) as Outcome[];
  }

  /**
   * Utility function to get Experience Types based on the Outcome Ancestor ID
   * Allows for filtering including CT and IT
   */
  getExperienceTypesForOutcomeFullAncestorPath(
    allExperienceTypes: ExperienceType[],
    outcomeAncestry: CategoryAncestryDetails,
  ): ExperienceType[] {
    return this.categorizationAncestorUtils.getChildrenForParentAncestor(
      allExperienceTypes,
      outcomeAncestry,
    ) as ExperienceType[];
  }

  /**
   * Utility function to get an object that can be used to display all possible categorization options/paths
   * @param clientId needed for api calls
   * @param includeOutcomes boolean.  prevents api call for outcomes if you only need industries and company types
   * @returns an object like so:
   * [
   *   {
   *     name: 'industryName',
   *     id: '10',
   *     children: [
   *       {
   *         name: 'CompanyTypeName',
   *         id: '20',
   *         children: []
   *       },
   *       {
   *         name: 'CompanyTypeName',
   *         id: '21',
   *         children: [
   *           {
   *             name: 'OutcomeName',
   *             id: '30',
   *           }
   *         ]
   *       }
   *     ]
   *   }
   * ]
   */
  getOptionsMap(
    clientId: string,
    includeOutcomes: boolean = true,
  ): Observable<OptionsMapNode[]> {
    return forkJoin({
      industries: this.getIndustries(clientId),
      companyTypes: this.getCompanyTypes(clientId),
      outcomes: this.getOutcomes(clientId),
    }).pipe(
      map((results) => {
        const industries = results.industries.data ?? [];
        const companyTypes = results.companyTypes.data ?? [];
        const outcomes = includeOutcomes ? results.outcomes.data : []; // only fetch data for outcomes if requested

        // loop through all industries and get children...
        const optionsMap = industries.map((industry) => {
          // loop through all company types and get children...
          const companyTypeChildren =
            this.categorizationAncestorUtils.getChildrenForParent(
              companyTypes,
              industry.id,
            );
          const companyTypeNodes = companyTypeChildren.map((companyType) => {
            // loop through all outcomes, convert to OptionsMapNodes...
            const outcomeChildren =
              this.categorizationAncestorUtils.getChildrenForParent(
                outcomes,
                companyType.id,
              );
            const outcomeNodes = outcomeChildren.map((child) => {
              return this.buildOptionsMapNode(child, child.id, []);
            });

            // convert company types to OptionsMapNodes.  Add children (outcome nodes)
            const companyTypeNode = this.buildOptionsMapNode(
              companyType,
              companyType.id,
              outcomeNodes,
            );

            if (includeOutcomes) {
              companyTypeNode.children = outcomeNodes;
            }

            return companyTypeNode;
          });

          // convert industries to OptionsMapNodes.  Add children (company type nodes)
          return this.buildOptionsMapNode(
            industry,
            industry.id,
            companyTypeNodes,
          );
        });

        // return finished options map
        return optionsMap;
      }),
    );
  }

  buildOptionsMapNode(
    category: Category,
    id: string,
    children: OptionsMapNode[],
  ) {
    return { name: category?.title['en-us'], id, children } as OptionsMapNode;
  }

  /*****************************************************
   *                    INDUSTRIES
   *****************************************************/

  /**
   * Retrieve existing industries for the given client.
   *
   * @param clientId
   * @returns the existing industries
   */

  getIndustries(clientId: string): Observable<ExpLibResponse<Industry[]>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/industries`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Retrieve existing industry for the given client.
   *
   * @param clientId
   * @param industryId the ID of the requested industry
   * @returns the existing industry
   */
  getIndustry(
    clientId: string,
    industryId: string,
  ): Observable<ExpLibResponse<Industry>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/industries/${industryId}`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Create new industry.
   *
   * @param clientId
   * @param industry the industry to be created
   * @returns the newly created industry
   */
  addIndustry(
    clientId: string,
    industry: Industry,
  ): Observable<ExpLibResponse<Industry>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/industries`;
    return this.secureHttp.post(url, industry).pipe(take(1));
  }

  /**
   * Update an existing industry.
   *
   * @param clientId
   * @param industryId
   * @param industryDetails
   * @returns the updated industry
   */
  updateIndustry(
    clientId: string,
    industryId: string,
    industryDetails: Industry,
  ): Observable<ExpLibResponse<Industry>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/industries/${industryId}`;
    return this.secureHttp.put(url, industryDetails).pipe(take(1));
  }

  /*****************************************************
   *                    COMPANY TYPES
   *****************************************************/

  /**
   * Retrieve existing industries for the given client.
   *
   * @param clientId
   * @returns the existing companyTypes
   */
  getCompanyTypes(clientId: string): Observable<ExpLibResponse<CompanyType[]>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/company-types`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Retrieve existing companyType for the given client.
   *
   * @param clientId
   * @param companyTypeId the ID of the requested companyType
   * @returns the existing companyType
   */
  getCompanyType(
    clientId: string,
    companyTypeId: string,
  ): Observable<ExpLibResponse<CompanyType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/company-types/${companyTypeId}`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Create new companyType.
   *
   * @param clientId
   * @param companyType the companyType to be created
   * @returns the newly created companyType
   */
  addCompanyType(
    clientId: string,
    companyType: CompanyType,
  ): Observable<ExpLibResponse<CompanyType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/company-types/`;
    return this.secureHttp.post(url, companyType).pipe(take(1));
  }

  /**
   * Update an existing Company type
   *
   * @param clientId
   * @param companyTypeId
   * @param companyTypeDetails
   * @returns the updated companyType
   */
  updateCompanyType(
    clientId: string,
    companyTypeId: string,
    companyTypeDetails: CompanyType,
  ): Observable<ExpLibResponse<CompanyType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/company-types/${companyTypeId}`;
    return this.secureHttp.put(url, companyTypeDetails).pipe(take(1));
  }

  /*****************************************************
   *                    OUTCOMES
   *****************************************************/

  /**
   * Retrieve existing Outcomes for the given client.
   *
   * @param clientId
   * @returns the existing outcomes
   */
  getOutcomes(clientId: string): Observable<ExpLibResponse<Outcome[]>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/outcomes`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Retrieve existing outcome for the given client.
   *
   * @param clientId
   * @param outcomeId the ID of the requested outcome
   * @returns the existing outcome
   */
  getOutcome(
    clientId: string,
    outcomeId: string,
  ): Observable<ExpLibResponse<Outcome>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/outcomes/${outcomeId}`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Create new outcome.
   *
   * @param clientId
   * @param outcome the outcome to be created
   * @returns the newly created outcome
   */
  addOutcome(
    clientId: string,
    outcome: Outcome,
  ): Observable<ExpLibResponse<Outcome>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/outcomes/`;
    return this.secureHttp.post(url, outcome).pipe(take(1));
  }

  /**
   * Update an existing Outcome
   *
   * @param clientId
   * @param outcomeId
   * @param outcomeDetails
   * @returns the updated outcome
   */
  updateOutcome(
    clientId: string,
    outcomeId: string,
    outcomeDetails: Outcome,
  ): Observable<ExpLibResponse<Outcome>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/outcomes/${outcomeId}`;
    return this.secureHttp.put(url, outcomeDetails).pipe(take(1));
  }

  /*****************************************************
   *                    EXPERIENCE TYPES
   *****************************************************/

  /**
   * Retrieve existing ExperienceTypes for the given client.
   *
   * @param clientId
   * @returns the existing experience types
   */
  getExperienceTypes(
    clientId: string,
  ): Observable<ExpLibResponse<ExperienceType[]>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/experience-types`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Retrieve existing outcome for the given client.
   *
   * @param clientId
   * @param outcomeId the ID of the requested outcome
   * @returns the existing outcome
   */
  getExperienceType(
    clientId: string,
    outcomeId: string,
  ): Observable<ExpLibResponse<ExperienceType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/experience-types/${outcomeId}`;
    return this.secureHttp.get(url).pipe(take(1));
  }

  /**
   * Create new experienceType.
   *
   * @param clientId
   * @param experienceType the experienceType to be created
   * @returns the newly created experienceType
   */
  addExperienceType(
    clientId: string,
    experienceType: ExperienceType,
  ): Observable<ExpLibResponse<ExperienceType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/experience-types/`;
    return this.secureHttp.post(url, experienceType).pipe(take(1));
  }

  /**
   * Update an existing ExperienceType
   *
   * @param clientId
   * @param experienceTypeId
   * @param experienceTypeDetails
   * @returns the updated experienceType
   */
  updateExperienceType(
    clientId: string,
    experienceTypeId: string,
    experienceTypeDetails: ExperienceType,
  ): Observable<ExpLibResponse<ExperienceType>> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/experience-types/${experienceTypeId}`;
    return this.secureHttp.put(url, experienceTypeDetails).pipe(take(1));
  }
}
