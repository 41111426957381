export class ToolTipData {
  static getTooltipData(errors?: Object): string {
    if (errors) {
      if (errors === null) {
        return `This field is invalid.`;
      }
      if (errors['maxlength']) {
        return `This input has a maximum length of ${errors['maxlength'].requiredLength}.`;
      }
      if (errors['minlength']) {
        return `This input has a minimum length of ${errors['minlength'].requiredLength}.`;
      }
      if (errors['pattern']) {
        return `This field is improperly formatted.`;
      }
      if (errors['required']) {
        return `This field is required.`;
      }
      if (errors['custom']) {
        return errors['custom']['message'];
      }
      if (typeof errors === 'string') {
        return errors;
      }
    } else {
      return `This field is invalid.`;
    }
  }
}
