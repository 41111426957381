import React, { useEffect } from 'react';
import {
  IntroLineContainer,
  IntroLineHeader,
  AddButtonContainer,
} from '@app/api-mgmt/components/push-api/styles/PushAPIHomeStyles';
import { PushAPIService as pushapi } from '@app/api-mgmt/components/push-api/push-api-service';
import {
  Integration,
  ModifiableIntegration,
} from '@app/api-mgmt/components/push-api/types/push-api-models';
import { ConfigurationsDisplay } from '@app/api-mgmt/components/push-api/components/configurations-display/ConfigurationsDisplay';
import { ActivationModal } from '@app/api-mgmt/components/push-api/components/configurations-display/ActivationModal';
import { Button } from 'baseui/button';
import { ButtonOverride } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '@app/api-mgmt/components/push-api/styles/AddConfigurationButtonStyles';
import { useClientInfo } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import { EditModal } from '@app/api-mgmt/components/push-api/components/configurations-display/EditModal';
import { DeleteModal } from '@app/api-mgmt/components/push-api/components/configurations-display/DeleteModal';
import { AddIntegrationModal } from '@app/api-mgmt/components/push-api/components/add-integration-modal/AddIntegrationModal';
import { AuditEntriesDisplay } from '@app/api-mgmt/components/push-api/components/audit-trail-display/AuditTrailDisplay';
import { CertificateModal } from '@app/api-mgmt/components/push-api/components/certificate-modal/CertificateModal';
import { EventFiltersModal } from '@app/api-mgmt/components/push-api/components/configurations-display/EventFiltersModal';

export const PushAPIHome = () => {
  const [configurations, setConfigurations] = React.useState<Integration[]>([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState(null);
  const [{ clientId }] = useClientInfo();
  async function updateConfigurationSet() {
    setConfigurations(await pushapi.getConfigSet(clientId));
  }

  useEffect(() => {
    updateConfigurationSet();
  }, []);

  const [isAddNewOpen, setIsAddNewOpen] = React.useState(false);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isAuditOpen, setIsAuditOpen] = React.useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] =
    React.useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] =
    React.useState(false);
  const [isEventFiltersModalOpen, setIsEventFiltersModalOpen] =
    React.useState(false);

  async function addNewConfiguration(value: ModifiableIntegration) {
    const newInteagraions = await pushapi.createConfig(clientId, value);
    setConfigurations(newInteagraions);

    return newInteagraions;
  }

  async function updateIntegration(value: ModifiableIntegration) {
    await pushapi.updateConfig(clientId, value);
    await updateConfigurationSet();
  }

  async function deleteIntegration() {
    await pushapi.deleteConfig(clientId, selectedIntegration.uid);
    await updateConfigurationSet();
  }

  async function toggleIntegrationActive(newState: boolean) {
    await pushapi.updateConfig(clientId, {
      ...selectedIntegration,
      active: newState,
    });
    setIsActivationModalOpen(false);
    await updateConfigurationSet();
  }

  function editConfigurationModal(config: ModifiableIntegration) {
    setSelectedIntegration(config);
    setIsEditOpen(true);
  }

  function deleteConfigurationModal(config: ModifiableIntegration) {
    setSelectedIntegration(config);
    setIsDeleteOpen(true);
  }

  function auditConfigurationModal(config: ModifiableIntegration) {
    setSelectedIntegration(config);
    setIsAuditOpen(true);
  }

  function onToggleActivationModal(config: ModifiableIntegration) {
    setSelectedIntegration(config);
    setIsActivationModalOpen(true);
  }

  function eventFiltersClicked(config: ModifiableIntegration) {
    setSelectedIntegration(config);
    setIsEventFiltersModalOpen(true);
  }

  return (
    <>
      {!isAuditOpen && (
        <>
          <IntroLineContainer>
            <IntroLineHeader>Push API Integration</IntroLineHeader>
            <AddButtonContainer>
              <Button
                overrides={{ BaseButton: ButtonOverride }}
                onClick={() => setIsAddNewOpen(true)}
              >
                <span>ADD INTEGRATION</span>
                <ButtonIconRight>
                  <i className="fa fa-plus-circle"></i>
                </ButtonIconRight>
              </Button>
            </AddButtonContainer>
          </IntroLineContainer>
          {configurations && (
            <ConfigurationsDisplay
              configurations={configurations}
              editConfigurationClicked={editConfigurationModal}
              toggleConfigurationClicked={onToggleActivationModal}
              deleteConfigurationClicked={deleteConfigurationModal}
              auditConfigurationClicked={auditConfigurationModal}
              eventFiltersClicked={eventFiltersClicked}
            />
          )}
        </>
      )}

      {isAuditOpen && (
        <>
          <div
            style={{
              margin: '20px',
            }}
          >
            <a
              onClick={() => {
                setIsAuditOpen(false);
              }}
              style={{
                padding: '0px 25px 0px 25px',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#73a5c6',
                cursor: 'pointer',
              }}
            >
              &lt; Return to Push API Integration
            </a>
            <IntroLineContainer>
              <IntroLineHeader>
                Audit Trail for '{selectedIntegration.name}'
              </IntroLineHeader>
            </IntroLineContainer>

            <AuditEntriesDisplay
              uid={selectedIntegration?.uid}
              clientId={clientId}
            ></AuditEntriesDisplay>
          </div>
        </>
      )}

      {isAddNewOpen && (
        <AddIntegrationModal
          onIntegrationAddClicked={async (newIntegration) => {
            // business logic to add new integrations
            const newIntegrations = await addNewConfiguration(newIntegration);
            setIsAddNewOpen(false);
            setSelectedIntegration(
              newIntegrations.find((c) => c.name === newIntegration.name),
            );
            if (
              newIntegration.type === 'sfdc' ||
              newIntegration.type === 'sfdc-app-exchange'
            ) {
              setIsCertificateModalOpen(true);
            }
          }}
          onCancelClicked={() => setIsAddNewOpen(false)}
        />
      )}

      {isCertificateModalOpen && (
        <CertificateModal
          clientId={clientId}
          integration={selectedIntegration}
          onCancelClicked={() => setIsCertificateModalOpen(false)}
        ></CertificateModal>
      )}

      {isEditOpen && (
        <EditModal
          integration={selectedIntegration}
          integrationNames={configurations.map(
            (integration) => integration.name,
          )}
          onCancelClicked={() => setIsEditOpen(false)}
          onIntegrationUpdate={(newIntegration) => {
            // business logic for pushing updates
            updateIntegration(newIntegration);
            setIsEditOpen(false);
          }}
        />
      )}

      {isDeleteOpen && (
        <DeleteModal
          name={selectedIntegration.name}
          onCloseClicked={() => setIsDeleteOpen(false)}
          onDeleteClicked={() => {
            // business logic for deleting integration
            deleteIntegration();
            setIsDeleteOpen(false);
          }}
        />
      )}

      {isActivationModalOpen && (
        <ActivationModal
          integrationName={selectedIntegration.name}
          integrationActive={selectedIntegration.active}
          onToggleClicked={async (newState: boolean) => {
            // business logic to set the active flag to the new state
            toggleIntegrationActive(newState);
          }}
          onCancelClicked={() => setIsActivationModalOpen(false)}
        ></ActivationModal>
      )}

      {isEventFiltersModalOpen && (
        <EventFiltersModal
          integrationName={selectedIntegration.name}
          eventFilters={selectedIntegration.event_filters}
          onCancelClicked={() => setIsEventFiltersModalOpen(false)}
        ></EventFiltersModal>
      )}
    </>
  );
};
