import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry, CompanyType, ExpLibResponse } from '@app/core/models/categorization-types';
import { CategorizationService } from '@app/core/services/categorization.service';
import { SortableComponent } from '@app/core/sortable-component';
import { SortingUtils } from '@app/experience-library/utils/categorization-sorting-utils';
import { SessionService } from '@app/security/session.service';
@Component({
  selector: 'rn-company-type-list',
  templateUrl: './company-type-list.component.html',
  styleUrls: ['./company-type-list.component.scss'],
})
export class CompanyTypeListComponent extends SortableComponent implements OnInit {
  industries: Industry[]; 
  companyTypes: CompanyType[]; // do not change!  Use sortedCompanyTypes to sort/modify
  sortedCompanyTypes: CompanyType[];
  clientId: string;
  hasError: boolean = false;

  constructor(
    private categorizationService: CategorizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;

    this.categorizationService.getCompanyTypes(this.clientId).subscribe((companyTypesResult: ExpLibResponse<CompanyType[]>) => {
      if (!companyTypesResult.data) { this.hasError = true };
      this.companyTypes = companyTypesResult.data;
      this.resetSort();
    }, (error) => {
      this.hasError = true;
    });

    this.categorizationService.getIndustries(this.clientId).subscribe((industriesResult: ExpLibResponse<Industry[]>) => {
      this.industries = industriesResult.data;
    }, (error) => {
      this.hasError = true;
    });
  }

  companyTypesLoaded(): boolean {
    return this.companyTypes !== undefined;
  }

  industriesLoaded(): boolean {
    return this.industries !== undefined;
  }

  navigateToAdd(): void {
    this.router.navigate(['add-company-type'], { relativeTo: this.activatedRoute });
  }

  navigateToEdit(companyTypeId: string): void {
    this.router.navigate([`edit-company-type/${companyTypeId}`], { relativeTo: this.activatedRoute });
  }

  fetchData() {
    if (this.ordering === undefined) {
      this.resetSort();
    } else if (this.ordering.orderBy === 'id') {
      this.sortedCompanyTypes = SortingUtils.sortById(this.sortedCompanyTypes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'title') {
      this.sortedCompanyTypes = SortingUtils.sortByTitle(this.sortedCompanyTypes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'created_at') {
      this.sortedCompanyTypes = SortingUtils.sortByDate(this.sortedCompanyTypes, this.ordering.direction, 'created_at');
    } else if (this.ordering.orderBy === 'updated_at') {
      this.sortedCompanyTypes = SortingUtils.sortByDate(this.sortedCompanyTypes, this.ordering.direction, 'updated_at');
    }
  }

  private resetSort(): void {
    // map is required to ensure that the sorted array isn't an in-place reference to the original,
    // because Array.sort does an in-place modification
    this.sortedCompanyTypes = this.companyTypes.map(item => item);
  }
}
