import { Injectable } from '@angular/core';
import { SecureHttp } from '@app/security/secure-http';
import { SessionService } from '@app/security/session.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ShortcodeSmsResponseTypes } from '@app/core/models/sms-response-types';

@Injectable()
export class ShortcodeService {
  constructor(private http: SecureHttp,
              private sessionService: SessionService,
              private rawHttp: HttpClient) {
  }

  /**
   * We use rawHttp here because if the shortcode doesn't have a configuration yet it
   * will throw a 404.
   * @param shortcode
   */
  getShortCodeResponses(shortcode: number): Observable<any> {
    const url = `${environment.configURLBase}/sms/shortcodes/${shortcode}/sms_keywords`;
    return this.rawHttp.get(url);
  }

  updateShortCodeResponses(shortcode: number, responses: ShortcodeSmsResponseTypes) {
    const url = `${environment.configURLBase}/sms/shortcodes/${shortcode}/sms_keywords`;
    return this.http.post(url, {sms_keywords: responses});
  }
}
