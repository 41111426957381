import { NavbarStateService } from '@app/core/services/navbar-state.service';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export class SearchableField {
  displayName: string;
  fieldName: string;

  constructor(displayName: string, fieldName: string) {
    this.displayName = displayName;
    this.fieldName = fieldName;
  }
}

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() isFixed: boolean = true;
  @Input() isMediaLibrary: boolean = false;
  @Input() searchableFields: Array<SearchableField>;
  @Output() onRequestSearch = new EventEmitter<SearchCriteria>();
  @Output() onClearSearch = new EventEmitter<void>();
  searchText: string = '';
  priorSearchText: string;
  showSearchableFields = false;
  searchableFieldsChecked = new Object();

  constructor(public navbarStateService: NavbarStateService) { }

  ngOnInit() {
    this.searchableFields.forEach(f => {this.searchableFieldsChecked[f.fieldName] = true; });
  }

  checkForEnter(event) {
    if (event.keyCode === 13) {
      this.submitSearchRequest();
    }
  }

  clearSearch() {
    this.priorSearchText = undefined;
    this.searchText = '';
    this.onClearSearch.emit();
  }

  displaySearchableFields(event) {
    this.showSearchableFields = true;
    event.stopPropagation();
  }

  hideSearchableFields() {
    this.showSearchableFields = false;
  }

  toggleSearchableFields(event) {
    this.showSearchableFields = !this.showSearchableFields;
    event.stopPropagation();
  }

  toggleSearchField(field: string) {
    this.searchableFieldsChecked[field] = !this.searchableFieldsChecked[field];
  }

  get truncatedPriorSearchText() {
    const maxSize = 75;
    if (this.priorSearchText && this.priorSearchText.length > maxSize) {
      return `${this.priorSearchText.substr(0, maxSize)} ...`;
    } else {
      return this.priorSearchText;
    }
  }

  submitSearchRequest() {
    if (this.searchText && this.searchText.match(/\S+/)) {
      this.priorSearchText = this.searchText;
      const searchCriteria = new SearchCriteria();
      searchCriteria.searchPhrase = this.searchText.trim();
      for (const searchableField in this.searchableFieldsChecked) {
        if (this.searchableFieldsChecked.hasOwnProperty(searchableField) && this.searchableFieldsChecked[searchableField]) {
          searchCriteria.searchFields.push(searchableField);
        }
      }
      this.onRequestSearch.emit(searchCriteria);
    }
  }
}
