import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { ModalHeaderOverride } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { EventFilters } from '../../types/push-api-models';

const formatEventFilters = (eventFilters: EventFilters) => {
  return Object.entries(eventFilters).map(([event, filters]) => (
    <>
      <hr style={{ borderColor: '#908E8F', borderWidth: '1px' }} />
      <div
        key={event}
        style={{
          fontSize: '16px',
          color: '#908E8F',
          fontWeight: 700,
          paddingBottom: '10px',
        }}
      >
        {event}
        {Object.entries(filters).map(([field, conditions], index) => (
          <div
            key={field}
            style={{
              fontSize: '14px',
              color: '#908E8F',
              fontWeight: 400,
              marginLeft: '10px',
              marginTop: index === 0 ? '10px' : '0px',
            }}
          >
            {conditions.equals &&
              conditions.equals.map((equalCondition, index) => (
                <div key={index}>
                  "{field}" equals "{equalCondition}"
                </div>
              ))}
            {conditions.startsWith && (
              <div>
                "{field}" starts with "{conditions.startsWith}"
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  ));
};

export const EventFiltersModal = ({
  integrationName,
  eventFilters,
  onCancelClicked,
}: {
  integrationName: string;
  eventFilters: EventFilters;
  onCancelClicked: () => void;
}) => {
  return (
    <>
      <Modal isOpen onClose={onCancelClicked}>
        <ModalHeader style={ModalHeaderOverride.style}>
          Event Filters for '{integrationName}'
        </ModalHeader>
        <ModalBody>
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            {formatEventFilters(eventFilters)}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
