<div class="loading" *ngIf="!error && loading">
  <app-spinner></app-spinner>
</div>

<div class="error" *ngIf="error && !loading">
  <h3>{{ error }}</h3>
  <button class="btn btn-primary" (click)="retryUnassigned()">Retry</button>
</div>

<div *ngIf="!error && !loading">
  <h4>Unassigned Conversations ({{ unassignedChats.length }})</h4>

  <rn-channel-list-headings
    [prefix]="'two-way-unassigned-channels-sort-by-'"
    [headings]="headings"
    [ordering]="ordering"
    (headingClicked)="onColumnHeadingClick($event)"
  >
  </rn-channel-list-headings>

  <ng-container *ngFor="let chat of unassignedChats">
    <div
      *ngIf="chat.attrs && chat.attrs.customer && chat.attrs.tags"
      class="channel"
    >
      <rn-channel-list-item
        [fname]="chat.attrs?.customer?.first_name"
        [lname]="chat.attrs?.customer?.last_name"
        [tagName]="getTagNameById(chat.attrs.tags)"
        [created]="chat.created_at"
        [updated]="chat.updated_at"
      >
        <button
          content-2
          class="btn btn-primary"
          (click)="join(chat.channel_sid)"
        >
          Join
        </button>
      </rn-channel-list-item>
    </div>
  </ng-container>
</div>
