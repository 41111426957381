import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Specific to Angular Material Elements
 * 
 * Used to show <mat-error> elements within <mat-form-field> elements
 * https://material.angular.io/components/input/overview#custom-error-matcher
 * 
 * IN TS
 * import {ShowOnInvalidAndDirtyErrorStateMatcher} from '@app/core/utils/material-error-state-matcher';
 * matcher = new ShowOnInvalidAndDirtyErrorStateMatcher();
 * 
 * IN TEMPLATE
 * <mat-form-field>
 *   <input matInput placeholder="name" name="name" [formControl]="nameControl" [errorStateMatcher]="matcher">
 *   <mat-error *ngIf="nameControl.invalid">Name is required</mat-error>
 * </mat-form-field>
 */

/** Error when invalid control is dirty or touched*/
export class ShowOnInvalidAndDirtyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}