<div class="dialog-background under-title"></div>

<div class="modal-container">
  <div class="modal-header clearfix">
    <label>Remove experience from CSR</label>
    <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>
  </div>

  <div class="modal-body-container">
    <img class="modal-body-img" src="../../../../assets/hex/hex-stop-green.png">
    <p class="modal-body-text">Are you sure you want to remove this experience from the CSR list?</p>

    <div class="modal-btn-container clearfix">
      <button id="cancel-btn" class="btn btn-primary btn-with-icon" (click)="cancel()">
        <span class="icon icon-left">
          <i class="fa fa-times"></i>
        </span>
        <span class="btn-label">CANCEL</span>
      </button>

      <button id="remove-btn" class="btn btn-primary btn-with-icon" (click)="remove()">
        <span class="btn-label">REMOVE EXPERIENCE</span>
        <span class="icon icon-right">
          <i class="fa fa-stop-circle-o" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
</div>