export enum ContentInstance {
  live,
  draft
}

export enum DisplayOption {
  preferLiveToDraft,
  live,
  draft
}
