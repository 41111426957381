import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rn-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Output() searchTerm = new EventEmitter<string>();
  searchString: string;

  constructor() { }

  ngOnInit() {
  }

  search(): void {
    this.searchTerm.emit(this.searchString);
    this.searchString = undefined;
  }


}
