import { SimpleChanges } from '@angular/core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface JotformEventResponse {
  data: string // `setHeight:${JotformHeight}:${JotformId}` (convert to template literal in TS 4.1.x)
}

@Component({
  selector: 'rn-power-ups-preview',
  templateUrl: './power-ups-preview.component.html',
  styleUrls: ['./power-ups-preview.component.scss']
})
export class PowerUpsPreviewComponent implements OnInit, OnChanges {
  intrinsicHeight: number = null; // intrinsic height of form.  Defined by Jotform, which sends us the height in an event.
  intrinsicWidth: number = 450; // intrinsic width of form.  This is the default max-width applied to the '.form-all' class in Jotform's css
  
  @Input() formId: string;
  @Input() width: number = this.intrinsicWidth; // in px.  Width determines height
  @Input() height: number; // in px.  optional.
  @Input() overlay: boolean = true; // Adds an invisible overlay so users can't interact with the form
  @Input() border: boolean = false; // adds border to container
  @Input() scrolling: boolean = false; // enable scrolling
  formUrl: SafeResourceUrl;
  hipaaFooterHeight: number = 64

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setFormUrl();

    // the jotform Iframe triggers a 'message' event when the form is fetched
    window.addEventListener('message', (evt) => { 
      this.getIframeHeight(evt); 
    }, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formId'] && !changes['formId'].firstChange) {
      this.setFormUrl();
    }
  }

  // if the intrinsicHeight has not yet been set, then we are still loading
  loading(): boolean {
    return !this.intrinsicHeight;
  }

  
  containerHeight(): number {
    if (this.height) { // if the form is tall, cut the container off at max height
      return this.height;
    } else { // if the form is short, scaledHeight SHOULD return a short height, but doesn't seem to be
      return this.scaledHeight();
    }
  }

  // returns height of the form, when the form is scaled down to the this.witdh
  scaledHeight(): number {
    if (this.loading()) { 
      return 0; 
    }
    return (this.width * this.intrinsicHeight) / this.intrinsicWidth;
  }

  /*
   * using scale to grow/shrink the iframe without triggering responsive changes in the form
   * https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/
   */
  scaleFactor(): number {
    return Math.min(this.width / this.intrinsicWidth, 1);
  }

  private setFormUrl(): void {
    this.intrinsicHeight = null; // reset to trigger loading
    this.formUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/powerupsform/${this.formId}`);
  }

  /*
   * responds to the 'message' event the jotform iframe triggers
   * get the formId from event
   * if jotform sends back a height of 0, set to high number. Otherwise pull height from event
   */
  private getIframeHeight(evt: JotformEventResponse): void {
    const MINIMUM_PREVIEW_HEIGHT = 1000; //arbitrary value if setHeight = 0. Anything below 750ish will start cutting forms off
    if (evt.data && /setHeight:\d+:\d+/.test(evt.data)) { // `setHeight:${JotformHeight}:${JotformId}`
      const args = evt.data.split(':');
      if (this.formId === args[2]) {
        this.intrinsicHeight = args[1] === "0" ? MINIMUM_PREVIEW_HEIGHT : parseInt(args[1]) + this.hipaaFooterHeight;
      }
    }
  }
}