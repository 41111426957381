import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SessionService } from '@app/security/session.service';
import React from 'react';
import ReactDOM from 'react-dom';
import { firstValueFrom, map } from 'rxjs';
import WirePreview from '../wire-preview-react/WirePreview';
import { Journey } from '@app/core/models/journey';
import { MessageClass } from '@app/core/models/message';
import { BrandingData } from '@relaynetwork/design-system';
import { ProductGroup } from '@app/core/models/client';
import { MediaService } from '@app/core/services/media.service';
import { MediaAsset } from '@relaynetwork/rn-node-feed-api-types';
@Component({
  selector: 'wire-preview-react',
  templateUrl: './wire-preview-react-container.component.html',
  styleUrls: ['./wire-preview-react-container.component.scss'],
})
export class WirePreviewReactContainerComponent
  implements OnInit, AfterViewInit, OnDestroy, DoCheck, OnChanges
{
  constructor(
    private sessionService: SessionService,
    private mediaService: MediaService,
  ) {}

  reactWrapperId: string;
  clientId: string;
  oldMessage: string;
  @Input() message: MessageClass;
  @Input() components: Journey['live']['components'];
  @Input() brandingColor: string;
  @Input() disclaimerConfig: BrandingData['disclaimer'];
  @Input() productGroup: ProductGroup;
  @Input() previewMode: 'expanded' | 'collapsed';

  ngOnInit() {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.reactWrapperId = `${this.message.name}-wire-preview`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
  }

  ngDoCheck() {
    if (JSON.stringify(this.message) !== this.oldMessage) {
      this.oldMessage = JSON.stringify(this.message);
      this.render();
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }

  getMediaAssetForMessage(assetId: string): Promise<MediaAsset> {
    return firstValueFrom(
      this.mediaService.getMediaAsset(assetId, true).pipe(
        map((response) => ({
          ...response,
          id: response.asset_id,
        })),
      ),
    ) as unknown as Promise<MediaAsset>;
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  protected getRootDomNode() {
    return document.getElementById(this.reactWrapperId);
  }

  protected render() {
    if (this.getRootDomNode() && this.isMounted()) {
      ReactDOM.render(
        React.createElement(WirePreview, {
          message: Object.assign({}, this.message),
          brandingColor: this.brandingColor,
          disclaimerConfig: this.disclaimerConfig,
          isDestinationMessageDetail:
            this.message.sms.auth_link === '@{auth-link}',
          previewMode: this.previewMode,
          components: this.components,
          productGroup: this.productGroup,
          clientId: this.clientId,
          getMediaAssetForMessage: this.getMediaAssetForMessage.bind(this),
        }),
        this.getRootDomNode(),
      );
    }
  }
}
