import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { SecureHttp } from '@app/security/secure-http';

@Injectable()
export class HtmlUtilService {

  constructor(private http: SecureHttp) {
  }

  validatingHTML(clientId: string, htmlText: string): Observable<any> {
    const url = `${environment.messagingURLBase}/client/${clientId}/html_validation`
    return this.http.post(url, {html_text: htmlText});
  }
}
