import { Injectable } from '@angular/core';
import { JobStatus, JobFiletype } from '@app/jobs-list/models/job';
import * as _ from 'lodash';

class FilterGroup {
  name: string;
  filters: string[];

  constructor(name, filters) {
    this.name = name;
    this.filters = filters;
  }

  matches(filterName) {
    return _.includes(this.filters, filterName);
  }
}

@Injectable()
export class FilterGroupingService {
  filterGroups: FilterGroup[] = [];

  constructor() {
    this.filterGroups.push(new FilterGroup('status', _.values(JobStatus)));
    this.filterGroups.push(new FilterGroup('filetype', _.values(JobFiletype)));
  }

    /**
   * @returns values stored in this.filterGroups in this format:
   * {
   *    'group1': ['filtername1', 'filtername2'],
   *    'group2': ['filtername3', 'filtername4'],
   * }
   */
  filterGroupSeeds() {
    return _.reduce(this.filterGroups, (result, filterGroup) => {
      result[filterGroup.name] = filterGroup.filters;
      return result;
    }, {});
  }

  /**
   * @param filterNames ex: [ 'filtername1', 'filtername2', 'filtername3', 'filtername4']
   * @returns passed-in filternames grouped based on the mapping defined in filterGroups
   * {
   *    'group1': ['filtername1', 'filtername2'],
   *    'group2': ['filtername3', 'filtername4'],
   * }
   */
  groupFilters(filterNames: string[]) { 
    const groupedFilters = this.initGroupsSkeleton();
    _.each(filterNames, (filterName) => {
      const group = this.getGroupForFilter(filterName);
      groupedFilters[group].push(filterName);
    });
    return groupedFilters;
  }

  /**
   * @returns object with groups for keys and empty arrays as values
   * ex: { 'group1': [], 'group2': [], 'generic': [] }
   */
  private initGroupsSkeleton() {
    return _.reduce(this.filterGroups, (result, filterGroup) => {
      result[filterGroup.name] = [];
      return result;
    }, { generic: [] });
  }

  /**
   * @return name of the group that matches the passed filterName, or 'generic' if there is no match
   */
  private getGroupForFilter(filterName: string): string {
    const matchingGroup = _.find(this.filterGroups, (filterGroup) => {
      return filterGroup.matches(filterName);
    });
    return _.isEmpty(matchingGroup) ? 'generic' : matchingGroup.name;
  }
}
