import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserEditPageComponent } from './user-edit-page/user-edit-page.component';
import { RelaySharedModule } from '../shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { UserResetComponent } from '@app/user/user-reset/user-reset.component';
import { UserListComponent } from '@app/user/user-list/user-list.component';
import { UserListPageComponent } from './user-list-page/user-list-page.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    InfiniteScrollModule,
    RouterModule,
    TooltipModule
  ],
  declarations: [
    UserEditComponent,
    UserResetComponent,
    UserListComponent,
    UserListPageComponent,
    UserEditPageComponent,
  ], 
  exports: [
    UserResetComponent,
    UserListComponent,
    UserEditComponent,
  ]
})
export class UserModule {
}
