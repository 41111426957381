import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import {
  Integration,
  BasicAuthConfiguration,
  NoAuthConfiguration,
  OAuthConfiguration,
  SfdcConfiguration,
  SfdcAppExchangeConfiguration,
  OtherConfiguration,
  ModifiableIntegration,
  SfmcConfiguration,
} from '@app/api-mgmt/components/push-api/types/push-api-models';
import { BasicAuthEditor } from '@app/api-mgmt/components/push-api/components/basic-auth-editor/BasicAuthEditor';
import { NoAuthEditor } from '@app/api-mgmt/components/push-api/components/no-auth-editor/NoAuthEditor';
import { OAuthEditor } from '@app/api-mgmt/components/push-api/components/oauth-editor/OAuthEditor';
import { SfdcEditor } from '@app/api-mgmt/components/push-api/components/sfdc-editor/SfdcEditor';
import { LegacyDisplay } from '@app/api-mgmt/components/push-api/components/legacy-config-display/LegacyDisplay';
import {
  ModalHeaderOverride,
  ButtonCancelOverride,
  ButtonOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { SfdcAppExchangeEditor } from '@app/api-mgmt/components/push-api/components/sfdc-app-exchange-editor/SfdcAppExchangeEditor';
import { SfmcEditor } from '@app/api-mgmt/components/push-api/components/sfmc-editor/SfmcEditor';
import { Button } from 'baseui/button';

export const EditModal = ({
  integration,
  integrationNames,
  onCancelClicked,
  onIntegrationUpdate,
}: {
  integration: Integration;
  integrationNames?: string[];
  onCancelClicked: () => void;
  onIntegrationUpdate: (newIntegration: ModifiableIntegration) => void;
}) => {
  const [isConfirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [isEdited, setIsEdited] = useState(false);

  // used to determine whether the user should be prompted with a confirmation modal before exiting
  const handleWasEdited = () => {
    setIsEdited(true);
  };
  
  const checkCloseSource = (e?) => {
    if (e?.closeSource && e?.closeSource === 'backdrop') return;
    if (isEdited) {
      setConfirmModalOpen(true);
    } else {
      onCancelClicked();
    }
  };

  return (
    <>
      <Modal
        isOpen
        onClose={(e) => checkCloseSource(e)}
        overrides={{
          Dialog: {
            style: {
              marginTop: '75px',
              width: '45%',
              visibility: isConfirmModalOpen ? 'hidden' : 'visible',
            },
          },
        }}
      >
        <ModalHeader style={ModalHeaderOverride.style}>
          Edit {integration.name ?? 'New Integration'}
        </ModalHeader>

        {integration.type === 'other' && (
          <ModalBody>
            <LegacyDisplay
              onCancelClicked={onCancelClicked}
              config={integration as OtherConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'basic-auth' && (
          <ModalBody>
            <BasicAuthEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              onWasEdited={handleWasEdited}
              integration={integration as BasicAuthConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'no-auth' && (
          <ModalBody>
            <NoAuthEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              onWasEdited={handleWasEdited}
              integration={integration as NoAuthConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'oauth' && (
          <ModalBody>
            <OAuthEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              onWasEdited={handleWasEdited}
              integration={integration as OAuthConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'sfdc' && (
          <ModalBody>
            <SfdcEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              integration={integration as SfdcConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'sfdc-app-exchange' && (
          <ModalBody>
            <SfdcAppExchangeEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              integration={integration as SfdcAppExchangeConfiguration}
            />
          </ModalBody>
        )}

        {integration.type === 'sfmc' && (
          <ModalBody>
            <SfmcEditor
              onSaveClicked={onIntegrationUpdate}
              onCancelClicked={onCancelClicked}
              integration={integration as SfmcConfiguration}
            />
          </ModalBody>
        )}
      </Modal>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        overrides={{
          Dialog: {
            style: {
              marginTop: '75px',
              width: '35%',
            },
          },
        }}
      >
        <ModalHeader style={ModalHeaderOverride.style}>
          Are you sure you want to exit this Push API Integration setup?
        </ModalHeader>
        <ModalBody>
          Please complete all required fields to save your configuration. If you
          exit without saving you will need to start over again.{' '}
        </ModalBody>
        <ModalFooter>
          <div style={{ float: 'right', paddingBottom: '20px' }}>
            <Button
              onClick={onCancelClicked}
              overrides={{
                Root: {
                  ...ButtonCancelOverride,
                  style: {
                    ...ButtonCancelOverride.style,
                    backgroundColor: 'white',
                  },
                },
              }}
              kind="secondary"
            >
              Exit without saving
            </Button>
            <Button
              onClick={() => setConfirmModalOpen(false)}
              overrides={{
                BaseButton: {
                  ...ButtonOverride,
                  style: {
                    ...ButtonOverride.style,
                    height: '42px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  },
                },
              }}
            >
              CONTINUE EDITING
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
