import { Injectable } from '@angular/core';
import { MessageClass } from 'app/core/models/message';
import { ProductGroup } from 'app/core/models/client';
import { Journey } from 'app/core/models/journey';
import * as _ from 'lodash';

@Injectable()
export class IWCUHelperService {

  constructor() { }


  messageHasIWCU(message: MessageClass): boolean {
    return _.filter(message.wire.actions, action => action.type === 'consent_upgrade').length > 0;
  }

  journeyHasIWCU(journey: Journey): boolean {
    let hasIWCU = false;

    _.each(journey.draft.components, component => {
      if (!!component.wire && !!component.wire.actions) {
        if (_.find(component.wire.actions, action => action.type === 'consent_upgrade') != null) {
          hasIWCU = true;
        }
      }
    });
    
    return hasIWCU;
  }

  cleanIWCU(productGroup: ProductGroup, message: MessageClass): MessageClass {
    const hasIWCU = _.filter(message.wire.actions, action => action.type === 'consent_upgrade').length > 0;
    if (!productGroup.consent.in_wire_upgrade.enabled && hasIWCU) {
      message = this.clearIWCUActions(message);
    }

    return message;
  }

  clearIWCUActions(message: MessageClass): MessageClass {
    message.wire.actions = _.filter(message.wire.actions, action => action.type !== 'consent_upgrade');
    return message;
  }

  checkJourneyForIWCUCleanup(productGroup: ProductGroup, journey: Journey): boolean {
    let hasIWCU = false;
    if (productGroup.consent.in_wire_upgrade.enabled) {
      return hasIWCU;
    }
    hasIWCU = this.journeyHasIWCU(journey);
    return hasIWCU;
  }

  clearJourneyIWCUActions(journey: Journey): Journey {
    _.each(journey.draft.components, component => {
      if (component.wire != null) {
        _.remove(component.wire.actions, action => action.type === 'consent_upgrade');
      }
    });
    return journey;
  }
}
