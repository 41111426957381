import { environment } from '@env/environment';
import { SessionService } from '@app/security/session.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Client, GpgInfo } from '@app/core/models/client';
import { UploadComponent } from '@app/shared/upload/upload.component';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ClientService } from '@app/core/services/client.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-key-config',
  templateUrl: './key-config.component.html',
  styleUrls: ['./key-config.component.scss']
})
export class KeyConfigComponent implements OnInit {
  currentClient: Client;
  keyConfigFormGroup: UntypedFormGroup;

  // Non-form elements, for display only
  fileContents: string | ArrayBuffer;
  fileName: string;

  uploadComplete: boolean = false;
  uploadErrored: boolean = false;
  parseErrored: boolean = false;

  @ViewChild('messageDialogDeleteKey', {static: true}) messageDialogDeleteKey: MessageDialogComponent;
  @ViewChild(UploadComponent, {static: false}) uploader: UploadComponent;

  constructor(private fb: UntypedFormBuilder,
              private sessionService: SessionService,
              private configService: ClientService) {

    this.keyConfigFormGroup = this.fb.group({
      'file': ['', Validators.required],
      'uid': ['', Validators.required],
      'fingerprint': ['', Validators.required],
    });
  }

  get currentGPGInfo(): GpgInfo {
    return this.sessionService.getCurrentUsersClient().gpg_info;
  }

  get gpgKeyIsValid(): boolean {
    return this.currentGPGInfo?.gpg_key_name != null &&
      this.currentGPGInfo?.gpg_key_location != null &&
      this.currentGPGInfo?.gpg_key_fingerprint != null;
  }

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  selectLocalFile(event) {
    if (event.target['files'].length > 0) {
      this.resetForm();

      const fileReader = new FileReader();
      const rawFile = event.target['files'][0];

      fileReader.onload = async () => {
        await this.handleLoadedFile(fileReader, rawFile.name, rawFile);
        this.keyConfigFormGroup.updateValueAndValidity();
        this.keyConfigFormGroup.markAsTouched();
      };

      fileReader.readAsBinaryString(rawFile);
    }
  }

  onSubmit() {
    this.upload();
    this.keyConfigFormGroup.setErrors(null);
  }

  handleUploadComplete() {
    this.uploadComplete = true;
    this.sessionService.reloadClient();
    this.keyConfigFormGroup.reset();
  }

  handleUploadAborted() {
    this.uploadErrored = true;
  }

  handleUploadError() {
    this.uploadErrored = true;
  }

  handleParseError() {
    this.parseErrored = true;
  }

  isSubmitDisabled(): boolean {
    return this.keyConfigFormGroup.invalid;
  }

  onDeleteKey(event: Event) {
    this.messageDialogDeleteKey.showMessage('Are you sure you want to delete this?', event);
  }

  async deleteKey() {
    this.configService.deleteGPGKey(this.currentClient.id)
      .pipe(take(1))
      .subscribe(
        () => {
          this.resetForm();
        }
      );
  }

  resetForm() {
    this.uploadComplete = false;
    this.uploadErrored = false;
    this.sessionService.reloadClient();
    this.keyConfigFormGroup.reset();
    this.fileName = '';
    this.fileContents = '';
  }

  upload() {
    this.uploadComplete = false;
    this.uploadErrored = false;
    const url = `${environment.configURLBase}/client/${this.currentClient.id}/gpg-key`;
    const data = this.keyConfigFormGroup.getRawValue();

    // set up FormData Object to send with the Post
    const uploadRequestBody = new FormData();
    uploadRequestBody.append('uid', data['uid']);
    uploadRequestBody.append('fingerprint', data['fingerprint']);

    this.uploader.startUpload(url, data['file'], uploadRequestBody);
  }

  async handleLoadedFile(fileReader: FileReader, fileName: string, rawFile: any) {
    const fileContents = fileReader.result.toString();
    this.configService.getGPGDetails(this.currentClient.id, fileContents)
      .pipe(take(1))
      .subscribe(
        ({uid, fingerprint}) => {
          this.keyConfigFormGroup.controls['uid'].patchValue(uid);
          this.keyConfigFormGroup.controls['fingerprint'].patchValue(fingerprint);
          this.fileContents = fileContents;
          this.fileName = fileName;
          this.keyConfigFormGroup.get('file').setValue(rawFile);
        },
        () => {
          this.handleParseError();
        }
      );
  }
}
