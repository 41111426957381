import { Component, Input } from '@angular/core';

@Component({
  selector: 'rn-apikey-item',
  templateUrl: './apikey-item.component.html',
  styleUrls: ['./apikey-item.component.scss']
})
export class ApikeyItemComponent {
  isHidden: boolean = true;
  showCopyMsg: boolean = false; 
  @Input() text: string;

  getObscuredText(): string {
    return this.isHidden ? `${this.text.slice(0, 4)}************************************` : this.text;
  }

  get matTooltipText(): string {
    return this.isHidden ? 'Show' : 'Hide';
  }

  handleToggle(value = !this.isHidden) {
    this.isHidden = value;
  }
}
