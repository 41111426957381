/**
 * Base URLs for (hosted) services that are used by each environment.
 */
export const baseEnv = {
  authURLBase: '/platform/v3/web/portal/cdm/auth',
  cdmURLBase: '/platform/v3/web/portal/cdm',
  apiURLBase: '/platform/v3/web/portal',
  jobURLBase: '/platform/v3/web/portal',
  configURLBase: '/platform/v3/web/portal/config',
  mediaURLBase: '/platform/v3/web/portal/media',
  messagingURLBase: '/rn-portal/1.0/v3/platform/v3/web/portal/messaging',
  twoWayURLBase: '/rn-two-way-api/1.0/platform/v3/web/portal/twoway',
  searchURLBase: '/rn-search-api/1.0/platform/v3/web/portal/search',
  adminUrlBase: '/platform/v3/admin',
  powerUpsApiURLBase: '/platform/v3/rest/powerups',
  ssoAdminURLBase: '/platform/v3/rest/sso',
  apiKeyAdminURLBase: '/platform/v3/rest/apikey',
  experienceLibraryURLBase: '/rn-experience-library/1.0',
  helpsitesURLBase: '/platform/v3/rest/helpsites',
  relayIQURLBase: '/rn-iq-config/1.0/relayiq',
  pushAPIURLBase: '/rn-push-api-config/1.0',
  powerUpApiURLBase: '/rn-powerups-api/1.0',
  portalURLBase: '/rn-portal/1.0',
};
