export enum Direction {
  Asc = 1,
  Desc
}

export class Ordering {
  orderBy: string;
  direction: Direction;
  
  constructor(orderBy: string, direction: Direction = Direction.Asc) {
    this.orderBy = orderBy;
    this.direction = direction;
  }
      
  getQueryString() {
    const dir = this.direction === Direction.Asc ? 'ASC' : 'DESC';
    return `order-by=${this.orderBy}&order-direction=${dir}`;    
  }
  
  getQueryStringUnderscorish() {
    const dir = this.direction === Direction.Asc ? 'ASC' : 'DESC';
    return `order_by=${this.orderBy}&order_direction=${dir}`;    
  }
  
  getDirectionString() {
    return this.direction === Direction.Asc ? 'ASC' : 'DESC';
  }
}
