import { Component, Input } from '@angular/core';

@Component({
  selector: 'rn-input-info-box',
  templateUrl: './input-info-box.component.html',
  styleUrls: ['./input-info-box.component.scss']
})
export class InputInfoBoxComponent {
  @Input() text: string = null;
  @Input() svgHeight: number = null;
  @Input() svgPosition: string = null;
}
