import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SftpKey } from '@app/sftp-mgmt/models/sftp-key';

@Component({
  selector: 'rn-sftp-key-list-element',
  templateUrl: './sftp-key-list-element.component.html',
  styleUrls: ['./sftp-key-list-element.component.scss']
})
export class SftpKeyListElementComponent implements OnInit {
  @Input() sftpKey: SftpKey;
  @Output() onDeleteClicked = new EventEmitter<SftpKey>();
  shaHash: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.sha1(this.sftpKey.ssh_pub_key).then((hashedResult) => this.shaHash = hashedResult);
  }

  raiseDeleteClicked(target: SftpKey) {
    this.onDeleteClicked.emit(target);
  }

  async sha1(message) {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
}
