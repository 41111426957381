import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Client, ProductGroup } from '@app/core/models/client';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { URLUtils } from '@app/core/utils/url-utils';
import { debounceTime } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-new-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  @Input() client: Client;
  @Input() currentGroup: ProductGroup;
  @Input() currentGroupNew: boolean = false;

  productGroupInfoForm: FormGroup;
  headerGroups = { productGroupInfo: true, productGroupDesign: true };

  constructor(public newFeed: NewFeedTransitionService) {}

  ngOnInit() {
    // form partially matches the ProductGroup Model in client.ts
    this.productGroupInfoForm = this.client.feed_enabled
      ? new FormGroup({
        'name': new FormControl(
          this.currentGroup.name,
          [Validators.required, Validators.maxLength(CharacterLimits.ProductGroupFeedDisplayNameCharLimit)]
        ),
        'internal_name': new FormControl(
          this.currentGroup.internal_name,
          [Validators.required, Validators.maxLength(256)]
        ),
        'wire_header_name': new FormControl(
          this.currentGroup.wire_header_name
        ),
        'description': new FormControl(
          this.currentGroup.description,
          [Validators.maxLength(CharacterLimits.ProductGroupDescriptionCharLimit)]
        ),
        'id': new FormControl(
          this.currentGroup.id,
          [Validators.required, CustomValidators.apiCompatibleID]
        ),
        'vanity_url': new FormControl(
          this.currentGroup.vanity_url,
          [CustomValidators.validURL]
        ),
        'is_vanity_url_enabled': new FormControl(
          this.currentGroup.is_vanity_url_enabled
        )
        })
      : new FormGroup({
        'name': new FormControl(this.currentGroup.name, [Validators.required]),
        'internal_name': new FormControl(this.currentGroup.internal_name, [Validators.required, Validators.maxLength(256)]),
        'wire_header_name': new FormControl(this.currentGroup.wire_header_name),
        'description': new FormControl(this.currentGroup.description),
        'id': new FormControl(this.currentGroup.id, [Validators.required, CustomValidators.apiCompatibleID]),
        'vanity_url': new FormControl(this.currentGroup.vanity_url, CustomValidators.validURL),
        'is_vanity_url_enabled': new FormControl(this.currentGroup.is_vanity_url_enabled)
      });

    this.productGroupInfoForm.valueChanges.subscribe(() => {
      this.currentGroup = _.assign(this.currentGroup, this.productGroupInfoForm.getRawValue());
    });

    const vanityUrl = this.productGroupInfoForm.get('vanity_url');
    vanityUrl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
      const sanitizedUrl = URLUtils.formatVanityURL(value);
      // prevent infinite loop
      vanityUrl.patchValue(sanitizedUrl, {emitEvent: false});
      // becuase this form decided to use two different models for some reason
      // they need to be kept in sync manually otherwise they update at different points in the 
      // UI lifecycle leading to a delay. This is stupid and everything should just use the same model
      // but I don't have time to rewrite this entire page right now
      this.currentGroup.vanity_url = sanitizedUrl;
    });
  }

  // For Parent components using @ViewChild to access form data
  getData(): any {
    return this.productGroupInfoForm.getRawValue();
  }

  get vanityUrl(): string {
    return this.productGroupInfoForm.controls['vanity_url'].value;
  }

  setVanityUrl(event: any) {
    event.stopPropagation();
    this.productGroupInfoForm.controls['is_vanity_url_enabled'].setValue(!!this.vanityUrl && this.vanityUrl.length > 0)
    this.currentGroup.vanity_url = this.productGroupInfoForm.controls['vanity_url'].value; // needed ONLY for the url preview
  }

  goToVanityURL(e): void {
    e.preventDefault();
    if (this.vanityUrl.indexOf('//') > -1) {
      window.open(this.vanityUrl);
    } else {
      window.open('//' + this.vanityUrl);
    }
  }

  getCharacterLimit(field: string): number {
    switch (field) {
      case 'feedDisplayName':
        return CharacterLimits.ProductGroupFeedDisplayNameCharLimit;
      case 'description':
        return CharacterLimits.ProductGroupDescriptionCharLimit;
      case 'customerContactsLabel':
        return CharacterLimits.ProductGroupCustomerContactsLabelCharLimit;
    }
  }
}
