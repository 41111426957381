<div class="container-fluid grid">
  
  <div *ngIf="!hasError" class="row">
    <button id="add-experience-types-btn" class="btn btn-primary btn-sm add-btn" (click)="navigateToAdd()">
      Add new
    </button>
  </div>

  <ng-container *ngIf="experienceTypesLoaded() && industriesLoaded() && !hasError">
    <!-- List Headings -->
    <div class="row grid-header">
      <div id="experience-type-sort-by-id" class="col-sm-1 column-heading no-text-selection" (click)="onColumnHeadingClick('id')">
        <span i18n="Company type id column heading">Experience Type ID</span>
        <app-column-sort-icon id="sort-icon-experience-type-id" [ordering]="ordering" [fieldName]="'id'"></app-column-sort-icon>
      </div>
      <div id="experience-type-sort-by-title" class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('title')">
        <span i18n="Company type column heading">Experience Type</span>
        <app-column-sort-icon id="sort-icon-experience-type-name" [ordering]="ordering" [fieldName]="'title'"></app-column-sort-icon>
      </div>
      <div class="col-sm-2 column-heading no-text-selection">
        <span i18n="Experience Type industries column heading">Industries</span>
      </div>
      <div class="col-sm-2 column-heading no-text-selection">
        <span i18n="Experience Type industries column heading">Company Types</span>
      </div>
      <div class="col-sm-2 column-heading no-text-selection">
        <span i18n="Experience Type industries column heading">Outcomes</span>
      </div>
      <div id="experience-type-sort-by-date-created" class="col-sm-1 column-heading no-text-selection" (click)="onColumnHeadingClick('created_at')">
        <span i18n="Experience Type date created column heading">Date Created</span>
        <app-column-sort-icon id="sort-icon-date-created" [ordering]="ordering" [fieldName]="'created_at'"></app-column-sort-icon>
      </div>
      <div id="experience-type-sort-by-date-updated" class="col-sm-1 column-heading no-text-selection" (click)="onColumnHeadingClick('updated_at')">
        <span i18n="Experience Type date modified column heading">Date Modified</span>
        <app-column-sort-icon id="sort-icon-date-modified" [ordering]="ordering" [fieldName]="'updated_at'"></app-column-sort-icon>
      </div>
    </div>

    <!-- List Items -->
    <ng-container *ngFor="let experienceType of sortedExperienceTypes">
      <rn-experience-type-list-item id="experience-type-list-item-{{experienceType.id}}" (editClicked)="navigateToEdit($event)" [experienceType]="experienceType" [industryOptions]="industries" [companyTypeOptions]="companyTypes" [outcomeOptions]="outcomes"></rn-experience-type-list-item>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="experienceTypesLoaded() && experienceTypes.length === 0 && !hasError" class="not-found"
  i18n="Message that the user gets when there are no company types to show in the list">There were no company types found.
</div>

<div *ngIf="hasError" class="not-found"
  i18n="Message that the user gets when there was an error loading company types">There was an error loading company types. 
</div>
