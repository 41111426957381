import React from 'react';

export const CertificateDownload = ({
  clientId,
  integrationName,
  publicKey,
}: {
  clientId?: string;
  integrationName: string;
  publicKey?: string;
}) => {
  const downloadCertFile = () => {
    const file = new Blob([publicKey], {
      type: 'text/plain:charset=UTF-8',
    });
    const href = window.URL.createObjectURL(file);
    const download = document.createElement('a');
    download.href = href;
    download.download = `${clientId}.crt`;
    download.click();
    window.URL.revokeObjectURL(href);
  };

  return (
    <div style={{ margin: '20px' }}>
      A .crt file has been generated for {integrationName}.&nbsp;
      <a id="download" onClick={downloadCertFile}>
        <b style={{ cursor: 'pointer' }}>You can download it here.</b>
      </a>
      &nbsp; Once downloaded, use the .crt file to upload into Salesforce for
      your Connected App.
    </div>
  );
};
