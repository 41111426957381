<div [ngClass]="{'has-error':!inputControl?.valid && inputControl?.touched}">
  <i *ngIf="!inputControl?.valid && inputControl?.touched" class="fa fa-exclamation-circle" aria-hidden="true" tooltip="{{getTooltipData(inputControl?.errors)}}"></i>
  <label  i18n="Experience product line">Product Group</label>

  <select id="journeyGroup" class="form-control"
          [formControl]="inputControl"
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollThrottle]="throttle"
          (change)="productGroupSelected($event.target.value)"
          (scrolled)="onScrollDown()"
          (ngModelChange)="emitChangeEvent($event)"
          data-infinite-scroll
          debounce>


      <option selected value="" [attr.disabled]="inputControl?.dirty ? 'disabled' : null"></option>
      <option value="all" *ngIf="productGroups.length > 1">All Product Groups</option>
      <option class="form-control" *ngFor="let productGroup of productGroups" [ngValue]="productGroup.id">
          {{productGroup.internal_name}}
      </option>
  </select>
</div>

<message-dialog></message-dialog>
