import { Background } from '@app/core/utils/background';
import { SessionService } from '../session.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  email_address: string = '';
  password: string = '';
  showPassword: boolean;
  loginFormGroup: UntypedFormGroup;
  loginInProgress = false;
  token: string;
  error: string;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
  ) {
    this.loginFormGroup = formBuilder.group({
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/),
        ],
      ],
    });
  }

  ngOnInit() {
    this.token =
      this.activeRoute.snapshot?.queryParams?.token ||
      this.activeRoute.snapshot.params['auth-token'];
    Background.loginOn();
  }

  ngOnDestroy() {
    Background.loginOff();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  resetPassword() {
    if (this.loginFormGroup.valid && !this.loginInProgress) {
      this.loginInProgress = true;
      const password = this.loginFormGroup.controls['password'].value;
      const login$ = this.sessionService
        .resetPassword(this.token, password)
        .subscribe({
          next: () => this.router.navigateByUrl('/login'),
          error: (error) => this.handleError(error),
        });
      this.subscriptions.push(login$);
    }
  }

  checkForEnter(event) {
    if (event.keyCode === 13) {
      this.resetPassword();
    }
  }

  goHome(): void {
    if (this.sessionService.loggedIn) {
      this.router.navigateByUrl('/logout');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  private handleError(error: any): void {
    this.loginInProgress = false;
    this.error = error?.error?.reason || error?.error?.message;
    if (!this.error) {
      this.router.navigateByUrl('/forgot-password');
    }
  }
}
