<div *ngIf="uploadInProgress" class="upload-progress-area">
    <div>
        <span id="fileNamePrefix">{{fileNamePrefix}}</span><span id="fileNameField">{{fileName}}</span>
        <i class="fa fa-times-circle pull-right" (click)="abortUpload()"></i>
    </div>
    <div class="upload-progress-text">
        <span class="uploaded-kb">{{uploadedUnits}}</span><span i18n="Abbreviation for kilobytes used for upload progress">kb</span>
        <span i18n="'of' in upload progress, for example '5kb of 100kb'">of</span>
        <span class="total-kb">{{totalUnits}}</span><span i18n="Abbreviation for kilobytes used for upload total">kb</span>
    </div>
    <div class="upload-progress-bar">
        <div class="upload-progress-bar-complete" [style.width.%]="percentComplete">
        </div>
    </div>
</div>
