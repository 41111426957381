import React, { useEffect, useState } from 'react';
import { SelectTitle } from '@app/api-mgmt/components/push-api/styles/ConfigurationSelectStyles';
import { OptionsT, Select, Value } from 'baseui/select';
import {
  SelectIconsOverride,
  SelectOverride,
  SelectValueOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';

export const SelectBox = ({
  placeholder,
  title,
  value,
  options,
  onChange,
  validate,
  isSubmitted,
}: {
  placeholder?: string;
  title?: string;
  value?: Value;
  options?: Value | OptionsT;
  onChange?: (value: Value) => void;
  validate?: (value: Value) => string;
  isSubmitted?: boolean;
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSubmitted) {
      setErrorMessage(validate(value));
    }
  });

  async function updateField(value: Value) {
    if (validate !== undefined) {
      setErrorMessage(validate(value));
    }
    onChange(value);
  }

  return (
    <>
      <SelectTitle>
        <label>{title}</label>
      </SelectTitle>
      <Select
        overrides={{
          ValueContainer: SelectValueOverride,
          ControlContainer: {
            style: { ...SelectOverride.style, height: '32px' },
          },
          ClearIcon: {
            props: {
              overrides: {
                Svg: SelectIconsOverride
              }
            }
          },
          SelectArrow: {
            props: {
              overrides: {
                Svg: SelectIconsOverride,
              }
            }
          },
        }}
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={(events) => updateField(events.value)}
      />
      {(errorMessage !== '' || isSubmitted) && (
        <>
          <i
            className="fa fa-exclamation-circle"
            style={{
              fontSize: '14px',
              marginRight: '5px',
              color: '#AD3B22',
              display: 'inline-block',
            }}
          ></i>
          <div
            style={{
              marginBottom: '10px',
              display: 'inline-block',
              fontStyle: 'italic',
              color: '#AD3B22',
            }}
          >
            {errorMessage}
          </div>
        </>
      )}
    </>
  );
};
