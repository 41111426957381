import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { Router } from '@angular/router';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import {UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-setup-users',
  templateUrl: './setup-users.component.html',
  styleUrls: ['./setup-users.component.scss'],
})
export class SetupUsersComponent implements OnInit, OnDestroy {
  userIDToEdit: string;
  @ViewChild('editUserDialog') editUserDialog: MessageDialogComponent;
  @ViewChild('twoWayMaskingDialog') twoWayMaskingDialog: MessageDialogComponent;
  currentClient: Client;
  twoWayAgentMaskingForm: UntypedFormGroup;
  twoWayConfig: TwoWayConfig;
  featureFlags = FeatureFlags;
  isRelayMessengerR2Enabled: boolean;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private sessionService: SessionService,
    private twoWaySetupService: TwoWaySetupService,
    private featureService: FeatureService,
  ) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleService.activate(
      this.currentClient.feed_enabled
        ? 'Relay Messenger Setup'
        : 'Two Way Messaging Setup',
      'Users',
    );

    this.twoWaySetupService.getTwoWayConfig().subscribe((twoWayConfig) => {
      this.twoWayConfig = twoWayConfig;
      this.initTwoWayAgentMaskingForm();
    });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  isLoading() {
    return false;
  }

  saveAndContinue() {
      if (!this.twoWayAgentMaskingForm.valid) {
        this.twoWayMaskingDialog.showMessage(`Agent display name must be 30 characters or less.`);
      } else {
        this.twoWaySetupService.saveTwoWayConfig(this.twoWayConfig).subscribe(
          (response) => {
            this.router.navigateByUrl(`/two-way/setup/auto-response`);
          },
          (error) => {
            this.twoWayMaskingDialog.showMessage(`We're sorry, there was an error saving your information.`)
          }
          )
      }
  }

  editUser(id: string) {
    this.editUserDialog.showMessage();
    this.userIDToEdit = id;
  }

  editUserClosed() {
    this.userIDToEdit = null;
    this.editUserDialog.close();
  }

  initTwoWayAgentMaskingForm() {
    this.twoWayAgentMaskingForm = new UntypedFormGroup({
      mask_agent_display_name: new UntypedFormControl(
        this.twoWayConfig.mask_agent_display_name || false,
      ),
      agent_display_name: new UntypedFormControl(
        this.twoWayConfig.agent_display_name || '',
        [Validators.maxLength(30)],
      ),
    });
    this.twoWayAgentMaskingForm.valueChanges.subscribe((val) => {
      this.twoWayConfig.agent_display_name = val['agent_display_name'];
    });
  }

  toggleMaskAgentDisplayName(): void {
      this.twoWayConfig.mask_agent_display_name = !this.twoWayConfig.mask_agent_display_name;
        this.twoWayAgentMaskingForm.get('agent_display_name').setValue('');
  }
}
