<div class="filters">
  <div *ngFor="let filter of appliedFilters; let i = index;" class="filter-container">
    <span class="filter" [id]="'applied-filter-'+i" [ngStyle]="{'background-color': getFilterColor(filter)}">
      {{filter.replace('journey', 'experience') | titleText}}<i class="fa fa-close" (click)="removeFilter(filter)"></i>
    </span>
  </div>
  <div *ngIf="showClearBtn" class="filter-container">
    <button *ngIf="appliedFilters.length > 1" class="filter filter--clear" (click)="clearFilters()" id='clear-all'>Clear All</button>
  </div>
</div>
