<div *ngIf="journey && productGroup" class="preview-area" id="preview-area">
  <div class="preview-header">
    <span id="journey-name">{{journey?.latestContent?.name}}</span>
    <button id="close-btn" class="btn btn-primary" i18n="Close preview tab button" (click)="close()">Close</button>
  </div>

  <ul>
    <!-- iterates all components in journey -->
    <ng-container *ngFor="let step of journey?.latestContent?.components; let i = index">
      
      <!-- if type is a MessageSender with an SMS, show phone with SMS and an arrow -->
      <ng-container *ngIf="step.type=='MessageSender' && step.sms.is_shown === 'true'">
        <li [id]="step.name">
          <phone [message]="step" [screen]="'sms'" [isJourneyPreview]="true" [components]="journey?.latestContent?.components"></phone>
        </li>
        <li *ngIf="step.wire.is_shown === 'true'">
          <journey-arrow [arrowType]="'straight-right'"></journey-arrow>
        </li>
      </ng-container>

      <!-- if type is a WorkflowEnder, show an arrow to the right -->
      <li *ngIf="step.type === 'WorkflowEnder'">
        <journey-arrow [arrowType]="'straight-right'"></journey-arrow>
      </li>
      
      <li [id]="step.name">
        <!-- if type is a WorkflowStarter or an APITrigger, show "play" icon -->
        <div class="start" *ngIf="step.type=='WorkflowStarter' || step.type === 'APITrigger'">
          <start></start>
        </div>
        
        <!--
          if type is a MessageSender with a visible wire, show Phone with Wire Message.
          PhoneComponent will also recursively render any "branch" messages (MessageResponse types)
          with this message as a parent/grandparent/etc.
        -->
        <div class="messages" *ngIf="step.type=='MessageSender' && step.wire.is_shown === 'true'">
          <!-- The actual wire message that goes with the sms-->
          <phone [message]="step" [screen]="'wire'" [productGroup]="productGroup" [recursive]="true"
                  [journeyContent]="journey?.latestContent" [stretch]="true" [isJourneyPreview]="true"
                  [hasIWCU]="hasIWCU"
                  [components]="journey?.latestContent?.components"></phone>
          
          <!-- display A phone for the first action that has a form -->
          <div class="form-preview" *ngIf="step.hasFormActions()">
            <phone *ngFor="let formAction of step.formActions()" [message]="step" [screen]="'form'" [productGroup]="productGroup" [recursive]="false"
                    [journeyContent]="journey?.latestContent" [stretch]="true" [isJourneyPreview]="true"
                    [hasIWCU]="hasIWCU" [components]="journey?.latestContent?.components" [formLabel]="formAction.label" [formId]="formAction.value"></phone>
          </div>
        </div>


        <!-- if type is a WorkflowDelayer with a visible wire, show "Time Delay" text & delay time span  -->
        <div class="delay" *ngIf="step.type === 'WorkflowDelayer'">
          <delay [delay]="step"></delay>
        </div>

        <!-- if type is a WorkflowEnder, show "stop" icon -->
        <div class="stop" *ngIf="step.type === 'WorkflowEnder'">
          <stop></stop>
        </div>
      </li>

      <!-- if type is a WorkflowStarter or an APITrigger, show an arrow to the right after "play" icon -->
      <li *ngIf="step.type === 'WorkflowStarter' || step.type === 'APITrigger'">
        <journey-arrow [arrowType]="'straight-right'"></journey-arrow>
      </li>
    </ng-container>
  </ul>

  <div class="preview-footer">
    <div *ngIf="journey?.latestContent?.product_group_name" class="footer-item">
        <span i18n="Experience product group label">Product Group: </span>
        <span id="product-group-name">{{journey?.latestContent?.product_group_name}}</span>
    </div>

    <div *ngIf="journey?.latestContent?.campaign" class="footer-item">
        <span i18n="Experience campaign label">Campaign: </span>
        <span id="campaign">{{journey?.latestContent?.campaign}}</span>
    </div>

    <div *ngIf="journey?.created_at" class="footer-item">
        <span i18n="Experience date created label">Date Created: </span>
        <span id="date-created">{{journey?.created_at | date: 'shortDate'}}</span>
    </div>

    <div *ngIf="journey?.updated_at" class="footer-item">
        <span i18n="Experience date modified label">Date Modified: </span>
        <span id="date-modified">{{journey?.updated_at | date: 'shortDate'}}</span>
    </div>

    <div *ngIf="journey?.created_by_name" class="footer-item">
        <span i18n="Experience created by label">Created By: </span>
        <span id="created-by">{{journey?.created_by_name}}</span>
    </div>
  </div>
</div>
<rn-timer-popup></rn-timer-popup>