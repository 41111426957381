import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-onboarding-toggle',
  templateUrl: './onboarding-toggle.component.html',
  styleUrls: ['./onboarding-toggle.component.scss']
})
export class OnboardingToggleComponent implements OnInit {
  @Input() btnLabel: string;
  @Input() btnName:  string;
  @Input() btnModel: string;
  @Input() helperText: string;

  @Output() btnModelChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  emitChangeEvent(event: string) {
    this.btnModelChange.emit(event);
  }

  formatBtnID(label: string, onOrOff: string): string {
    label = label.toLowerCase().replace(/\s/ig, '-');
    return `${label}-${onOrOff}`;
  }

}
