import { Journey } from '../models/journey';
import { WorkflowClass } from '../models/message';

export class Renumberer {

  public families: WorkflowClass[];

  constructor(private journey: Journey) {
    this.families = this.journey.draft.components.filter(c => c.type === "APITrigger");
  }

  renumber(component: any, prefixDepth: number, suffixDepth: number = 0) {
      const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const components = this.journey.draft.components;

      function numberChildren(children, prefix) {
          for (let j = 0; j < children.length; j++) {
              const child = children[j];
              const letter = alphabet[suffixDepth % 26];
              const letterRepeat = Math.floor(suffixDepth / 26) + 1;

              child.step = `${prefix}.${letter.repeat(letterRepeat)}`;

              suffixDepth += 1;

              const grandchildren = components.filter(c => (c.type === "MessageResponse" && c.parent === child.name));
              if (grandchildren.length) {
                  numberChildren(grandchildren, prefix);
              }
          }
      }

      if (!component) {
          component = components.find(c => (c.type === "WorkflowStarter"));
      }

      if (component.type === "WorkflowEnder") {
          const familyIndex = this.families.findIndex(c => c.name === component.family);
          if (familyIndex > -1 && familyIndex < (this.families.length - 1)) {
              const nextFamily = this.families[familyIndex + 1];
              if (nextFamily) {
                this.renumber(nextFamily, prefixDepth);
              }
          } else {
              return;
          }
      }

      if (component.type === "MessageSender") {
          const children = components.filter(c => (c.type === "MessageResponse" && c.parent === component.name));
          component.step = `${prefixDepth}.${alphabet[suffixDepth++]}`;
          if (children.length) {
              numberChildren(children, prefixDepth);
          }
      }

      if (component.type === "APITrigger" || component.type === "WorkflowDelayer") {
          suffixDepth = 0;
          component.step = (++prefixDepth).toString();
      }

      const c2 = components.find(c => c.name === component.to);
      if (c2) {
        this.renumber(c2, prefixDepth, suffixDepth);
      }
  }
}
