export class CharacterLimits {
  public static readonly MessageTextCharLimitWire = 5000;
  public static readonly MessageAltTextCharLimitWire = 5000;
  public static readonly MessageTextCharLimitFeed = 5000;
  public static readonly MessageAltTextCharLimitFeed = 5000;
  public static readonly MessageLevelDisclaimerCharLimit = 1200;
  public static readonly ActionLabelCharLimitPrimary = 25;
  public static readonly ActionLabelCharLimitSecondary = 60;
  public static readonly SmsTextCharLimit = 160;
  public static readonly SmsAltTextCharLimit = 160;

  /* New Feed */
  // Client Config > Product Group > Basics
  public static readonly ProductGroupFeedDisplayNameCharLimit = 71;
  public static readonly ProductGroupDescriptionCharLimit = 93;
  public static readonly ProductGroupCustomerContactsLabelCharLimit = 24;

  // Client Config > Product Group > Legal
  public static readonly DisclaimerCharacterLimit = 1200;
  public static readonly ExpressConsentTsCsCharLimit = 1200;
  public static readonly ExpressWrittenConsentTsCsCharLimit = 1200;
  public static readonly InFeedConsentUpgradeTsCsCharLimit = 1200;
  public static readonly PasswordPageTsCsCharLimit = 1200;
  public static readonly FdicNonDepositLanguageCharLimit = 130;

  // Client Config > Product Group > Onboarding
  public static readonly SignUpPageSuccessCopy = 750;
  public static readonly SignUpPageFailureCopy = 750;
  public static readonly SignUpPageSubmitButtonLabel = 28;
  public static readonly PasswordRequiredMessageText = 750;
  public static readonly PasswordRequiredSubmitButtonLabel = 28;
  public static readonly SignUpPageTermsAndConditions = 80;
  public static readonly SignUpPageTermsAndConditionsValidationError = 80;
}
