<client-header
  [headerLabel]="'Verification'"
  [headerGroup]="'verification'"
  (click)="headerGroups.verification = !headerGroups.verification"
>
</client-header>

<div
  class="collapse-container"
  *ngIf="headerGroups.verification && client.validation.fields"
>
  <div class="label-tip">
    <label>Verification Fields</label>
  </div>

  <div
    class="validation-fields"
    *ngFor="let fieldGroup of verificationFieldsArray.controls; let i = index"
    [formGroup]="verificationFieldsArray.controls[i]"
  >
    <span class="validation-field-value">{{
      fieldGroup.controls['field'].value
    }}</span>
    <span
      class="validation-field-value"
      [class.has-error]="!fieldGroup.controls['prompt'].valid"
    >
    <div class="tooltip-container">
      <input type="text" name="prompt-{{ i }}" [formControl]="fieldGroup.controls['prompt']"/>       
      <div class="icon-container">
        <i *ngIf="fieldGroup.controls['field'].value === 'postal_code'" class="fa fa-question-circle icon-color" aria-hidden="true" (mouseover)="showTooltip = true" (mouseout)="showTooltip = false"></i>
      </div>
        <div *ngIf="showTooltip && fieldGroup.controls['field'].value === 'postal_code'" class="tooltip">
          <p>Verification by postal code will only verify the first 5 digits if the customer has a 9 digit postal zip on file.</p>
        </div>
    </div>
    </span>
  </div>

  <ng-container [formGroup]="verificationConfigForm">
    <rn-text-input
      [inputLabel]="'Header'"
      [inputControl]="verificationConfigForm.controls['header']"
      [placeholderText]="'Account Confirmation'"
      [isWide]="true"
    >
      <rn-input-info-box
        input-info-box
        [svgHeight]="40"
        [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
        [text]="
          'Header for account verification. Will default to:<br>Account Confirmation'
        "
      ></rn-input-info-box>
    </rn-text-input>

    <div class="input-container">
      <div class="input">
        <div class="label-tip">
          <label>Verification Message</label>
        </div>

        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-express-consent'"
          [formControl]="verificationConfigForm.controls['body']"
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="80" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,80"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Text to show user. Will default to:<br />Please enter the
            information below to confirm your account and access your
            messages.</span
          >
        </p>
      </div>
    </div>

    <rn-text-input
      [inputLabel]="'Button Label'"
      [inputControl]="verificationConfigForm.controls['button_label']"
      [placeholderText]="'Submit'"
      [isWide]="true"
    >
      <rn-input-info-box
        input-info-box
        [svgHeight]="40"
        [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
        [text]="'Button for account verification. Will default to:<br>Submit'"
      ></rn-input-info-box>
    </rn-text-input>
  </ng-container>
</div>
