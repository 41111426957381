import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlTag } from '@app/core/services/control-tags.service';
import * as _ from 'lodash';

@Component({
  selector: 'tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss']
})
export class TagSelectComponent implements OnInit {

  @Input() tags: any[];
  @Output() filter: EventEmitter<any[]> = new EventEmitter();

  loading = true;
  error: string;

  // all the tags
  filterTags: ControlTag[];

  // all selected tags
  selectedTags: ControlTag[] = [];

  // the one being added or removed to selectedTags
  selectedTag: ControlTag;
  
  constructor() { }

  ngOnInit() {
    this.filterTags = _.cloneDeep(this.tags);
  }

  addTag(): void {
    const tag = _.find(this.filterTags, (t) => t.tag_id === this.selectedTag);
    if (tag) {
      this.selectedTags.push(tag);
      this.filter.emit(this.selectedTags);
    }
    _.remove(this.filterTags, (t) => t.tag_id === this.selectedTag);
  }

  removeTag(id: string): void {
    _.remove(this.selectedTags, (t) => t.tag_id === id);
    this.filter.emit(this.selectedTags);
    this.filterTags.push(_.find(this.tags, (t) => t.tag_id === id));
  }

}

