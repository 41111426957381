import React from 'react';
import { useStyletron } from 'baseui';
import { Check } from 'baseui/icon';
import { KIND as ButtonKind, SIZE as ButtonSize } from 'baseui/button';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ModalButton, ModalFooter } from 'baseui/modal';
import { AgentModalVariant } from '../agent-modal/AgentModal';
import { Participant } from '@twilio/conversations';

export type AgentConfirmModalProps = {
  closeModal: () => void;
  successModal: () => void;
  cancelBtnText: string;
  successBtnText: string;
  variant?: AgentModalVariant;
  backBtnText?: string;
  backToPrevStep?: () => void;
  dataTestId?: string;
  agentName?: string;
  assignedAgents?: Participant[];
};

// This modal is design to be used for the agent invite/assign actions, as a second step, to confirm that action
export const AgentConfirmModal = ({
  closeModal,
  successModal,
  cancelBtnText,
  successBtnText,
  backBtnText,
  backToPrevStep,
  dataTestId,
  agentName,
  variant = 'invite',
  assignedAgents,
}: AgentConfirmModalProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  const getAgentMessageReAssign = (assignedAgents: Participant[], variant: AgentModalVariant) => { 
    if (!Array.isArray(assignedAgents) || assignedAgents.length === 0) return '';

    if (variant === 're-assign') {
      if (assignedAgents.length === 1) {
        const singleAgent = assignedAgents[0].attributes['agent'].first_name;
        return `Note that ${singleAgent} (currently assigned to this conversation) will be removed and no longer have access to this conversation.`;
      } else if (assignedAgents.length >= 2) {
        const agentNames = assignedAgents.map(agent => agent.attributes['agent'].first_name);
        const formattedNames = agentNames.join(' and ');
        return `Note that ${formattedNames} (currently assigned to this conversation) will be removed and no longer have access to this conversation.`;
      }
    }
    return '';
  }

  return (
    <Block>
      <div
        className={css({
          fontWeight: '400',
          color: '#37474F',
          paddingLeft: '10px',
        })}
      >
        Are you sure you want to {variant} <strong>{agentName}</strong> to the
        conversation? Once{' '}
        {variant === 'assign'
          ? 'assigned'
          : variant === 'invite'
          ? 'invited'
          : 're-assigned'}
        , <strong>{agentName}</strong> will be able to read and respond to the
        conversation.
        <br></br>
       
          {variant === 're-assign' ? getAgentMessageReAssign(assignedAgents, variant) : ''}
        
      </div>
      <ModalFooter
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          alignItems: 'center',
        })}
      >
        {backBtnText && (
          <ModalButton
            kind={ButtonKind.tertiary}
            size={ButtonSize.mini}
            autoFocus={false}
            overrides={{
              BaseButton: {
                style: {
                  height: '40px',
                  color: '#5192BB',
                  fontWeight: '400',
                  marginRight: 'auto !important',
                },
              },
            }}
            onClick={backToPrevStep}
          >
            {backBtnText}
          </ModalButton>
        )}
        <Block
          className={css({
            display: 'flex',
            gap: '10px',
          })}
        >
          <ModalButton
            kind={ButtonKind.tertiary}
            size={ButtonSize.mini}
            autoFocus={false}
            overrides={{
              BaseButton: {
                style: {
                  height: '40px',
                  color: '#5192BB',
                  fontWeight: '400',
                },
              },
            }}
            onClick={closeModal}
          >
            {cancelBtnText}
          </ModalButton>
          <ModalButton
            size={ButtonSize.mini}
            kind={ButtonKind.secondary}
            endEnhancer={
              <div className={css({ display: 'flex', alignItems: 'center' })}>
                <div
                  className={css({
                    marginRight: '8px',
                    height: '35px',
                    width: '1px',
                    backgroundColor: '#427a9d',
                  })}
                ></div>
                <Check size={24} />
              </div>
            }
            autoFocus={false}
            overrides={{
              BaseButton: {
                style: {
                  height: '40px',
                  color: customTheme.colors.white,
                  backgroundColor: '#5192BB',
                  outline: '#427a9d 1px solid',
                  fontWeight: '400',
                  textTransform: 'uppercase',
                  ':hover': {
                    backgroundColor: '#f6f6f6',
                    color: '#5192BB',
                  },
                },
                props: {
                  'data-testid': dataTestId,
                },
              },
            }}
            onClick={successModal}
          >
            {successBtnText}
          </ModalButton>
        </Block>
      </ModalFooter>
    </Block>
  );
};
