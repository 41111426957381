<app-journey-metadata-dialog (onSave)="onNew($event)" (onCancel)="onBack()"></app-journey-metadata-dialog>

<div *ngIf="showDialog" class="dialog-background">
  <div class="dialog-content">

    <p *ngIf="message != null">{{message}}</p>

    <button (click)="showDialog=false" type="button"
            class="btn btn-default">OK
    </button>
  </div>
</div>
