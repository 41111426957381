import React from 'react';
import { Radio, RadioGroup } from 'baseui/radio';

export type RnRadioGroupOption = {
  value: string;
  label: string;
};

export const RnRadioGroup = ({
  value,
  options,
  onChange,
}: {
  value: string;
  options: RnRadioGroupOption[];
  onChange?: (value) => void;
}) => {
  return (
    <RadioGroup
      name=""
      onChange={(event) => onChange(event.target.value)}
      value={value}
      align="horizontal"
    >
      {options.map((option, index) => {
        return (
          <Radio
            value={option.value}
            overrides={{
              Label: { style: { color: '#5B5A59', fontSize: '14px' } },
            }}
            key={index}
          >
            {option.label}
          </Radio>
        );
      })}
    </RadioGroup>
  );
};
