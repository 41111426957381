import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PowerUpsLiveForm } from '@app/core/models/power-ups-live-form';
import { SessionService } from '@app/security/session.service';
import { PowerUpsPreviewComponent } from '@app/shared/power-ups-preview/power-ups-preview.component';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { take } from 'rxjs/operators';
import { PowerUpsService } from '@app/core/services/power-ups.service';

@Component({
  selector: 'rn-power-ups-action',
  templateUrl: './power-ups-action.component.html',
  styleUrls: ['./power-ups-action.component.scss'],
})
export class PowerUpsActionComponent {
  @Input() formReference: UntypedFormGroup;
  @ViewChild('powerUpsSelectDialog')
  powerUpsSelectDialog: MessageDialogComponent;
  @ViewChild('powerUpsDisplayForm') powerUpsDisplayForm: MessageDialogComponent;
  @ViewChild('powerUpPreview') powerUpPreview: PowerUpsPreviewComponent;
  selectedPowerUp: PowerUpsLiveForm;
  formTitle: string = '';
  clientId: string = '';
  formError: boolean = false;
  formDeleted: boolean = false;

  constructor(
    private powerUpsService: PowerUpsService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.getFormTitle();
  }

  showPowerUpSelect(): void {
    this.powerUpsSelectDialog.showMessage();
  }

  showPowerUpDisplayForm(): void {
    this.powerUpsDisplayForm.showMessage();
  }

  saveNewPowerUp(newPowerUpId: string): void {
    this.formReference.get('value').setValue(newPowerUpId);
    this.powerUpsSelectDialog.close();
    this.getFormTitle();
  }

  hasFormId(): boolean {
    return this.formId !== undefined && this.formId.length > 0;
  }

  getFormTitle(): void {
    if (this.hasFormId()) {
      this.formError = false;
      this.powerUpsService
        .getLiveForm(this.clientId, this.formId)
        .pipe(take(1))
        .subscribe(
          (selectedPowerUp: PowerUpsLiveForm) => {
            this.selectedPowerUp = selectedPowerUp;
            this.formTitle = this.selectedPowerUp.title;
            this.formDeleted =
              this.selectedPowerUp.status === 'DELETED' ? true : false;
          },
          (error) => {
            this.formError = true;
          }
        );
    }
  }

  hasFormTitle(): boolean {
    return this.formTitle !== undefined && this.formTitle.length > 0;
  }

  replaceButtonText(): string {
    return this.hasFormId() ? 'Replace' : 'Add Power-Up';
  }

  get formId(): string {
    return this.formReference.get('value').value;
  }

  closeMessageDialog(): void {
    this.powerUpsSelectDialog.close();
  }
}
