import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'launch-choice',
    templateUrl: './launch-choice.component.html',
    styleUrls: ['./launch-choice.component.scss']
})
export class LaunchChoiceComponent implements OnInit {

    @Input() message: string;
    @Output() launchNow: EventEmitter<void> = new EventEmitter<void>();
    @Output() launchOnTimer: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {
    }
}
