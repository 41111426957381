<div class="grid-row">
    <div class="row">
        <div class="col-sm-1 experience-type-id-col truncate">
            <span class="experience-type-id">{{experienceType.id}}</span>
        </div>
        <div class="col-sm-2 experience-type-name-col truncate">
            <span class="experience-type-name">{{experienceType.title["en-us"]}}</span>
        </div>

        <div class="col-sm-2 experience-type-category-col">
            <span class="industries truncate">
                {{getFirstItemName(experienceType, categoryIndex.Industries)}}
            </span>
            <div *ngIf="hasAdditionalItems(experienceType, categoryIndex.Industries)" class="additional-industries-bubble">
                <span class="additional-industries-bubble__count">
                    +{{getAdditionalCount(experienceType, categoryIndex.Industries)}}
                </span>
            </div>
        </div>

        <div class="col-sm-2 experience-type-category-col">
            <span class="companyTypes truncate">
                {{getFirstItemName(experienceType, categoryIndex.CompanyTypes)}}
            </span>
            <div *ngIf="hasAdditionalItems(experienceType, categoryIndex.CompanyTypes)" class="additional-industries-bubble">
                <span class="additional-industries-bubble__count">
                    +{{getAdditionalCount(experienceType, categoryIndex.CompanyTypes)}}
                </span>
            </div>
        </div>

        <div class="col-sm-2 experience-type-category-col">
            <span class="outcomes truncate">
                {{getFirstItemName(experienceType, categoryIndex.Outcomes)}}
            </span>
            <div *ngIf="hasAdditionalItems(experienceType, categoryIndex.Outcomes)" class="additional-industries-bubble">
                <span class="additional-industries-bubble__count">
                    +{{getAdditionalCount(experienceType, categoryIndex.Outcomes)}}
                </span>
            </div>
        </div>

        <div class="col-sm-1 truncate">
            <span>{{experienceType.created_at | date:'shortDate'}}</span>
        </div>

        <div class="col-sm-1 truncate">
            <span>{{experienceType.updated_at | date:'shortDate'}}</span>
        </div>

        <div class="color-sm-1 action-column">
            <i id="edit-experience-type-btn-{{experienceType.id}}" class="fa fa-pencil" aria-hidden="true" (click)="emitEditEvent()"></i>
            <div *ngIf="hasDetailsToShow(experienceType)" class="expander-div">
                <i *ngIf="!detailsOpen" (click)="toggleDetails()" class="fa fa-caret-right expander-image"></i>
                <i *ngIf="detailsOpen" (click)="toggleDetails()" class="fa fa-caret-down expander-image"></i>
            </div>
        </div>
    </div> 
</div>

<ng-container *ngIf="hasDetailsToShow(experienceType) && detailsOpen">
    <div class="experience-type-detail">
        <div class="row grid-row">
            <div class="col-sm-3"></div>
            <div class="col-sm-2">
                <div *ngFor="let industry of getAdditionalItems(experienceType, categoryIndex.Industries)" class="additional-industry">
                    {{industry}}
                </div>
            </div>
            <div class="col-sm-2">
                <div *ngFor="let companyType of getAdditionalItems(experienceType, categoryIndex.CompanyTypes)" class="additional-industry">
                    {{companyType}}
                </div>
            </div>
            <div class="col-sm-2">
                <div *ngFor="let outcome of getAdditionalItems(experienceType, categoryIndex.Outcomes)" class="additional-industry">
                    {{outcome}}
                </div>
            </div>
        </div> 
    </div>
</ng-container>
