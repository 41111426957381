export class GuidUtils {
  
  static createRandomGuid(): string {
    
    const bytes = new Array<number>();
    for (let i = 0; i < 16; i++) {
      bytes.push(Math.floor(Math.random() * 256));
    }
    
    const chunk1 = GuidUtils.toHexString(bytes.slice(0, 4));
    const chunk2 = GuidUtils.toHexString(bytes.slice(4, 6));
    const chunk3 = GuidUtils.toHexString(bytes.slice(6, 8));
    const chunk4 = GuidUtils.toHexString(bytes.slice(8, 10));
    const chunk5 = GuidUtils.toHexString(bytes.slice(10, 17));
    
    const guid = `${chunk1}-${chunk2}-${chunk3}-${chunk4}-${chunk5}`;
    
    return guid;
  }
       
  private static toHexString(bytes: Array<number>) {
    return bytes.map(byte => GuidUtils.padHexByte(byte.toString(16))).reduce((b1, b2) => b1 + b2);
  }
  
  private static padHexByte(b: string) {
    if (b.length < 2) {
      return '0' + b;
    } else {
      return b;
    }
  }
}
