<client-header [allowExpand]=false [headerLabel]="'Existing Key Information'"></client-header>

<div class="existing-key-info">
    <div *ngIf="gpgKeyIsValid; else noGpgKey">
        <div class="read-only-container">
            <label>Name:</label>
            <p>{{currentGPGInfo.gpg_key_name}}</p>
        </div>
        <div class="read-only-container">
            <label>Location:</label>
            <p>{{currentGPGInfo.gpg_key_location}}</p>
        </div>
        <div class="read-only-container">
            <label>Fingerprint:</label>
            <p>{{currentGPGInfo.gpg_key_fingerprint}}</p>
        </div>
    </div>

    <ng-template #noGpgKey>
        <div class="read-only-container">
            <label>No GPG key exists.</label>
        </div>
    </ng-template>
</div>

<div>
    <div *ngIf="gpgKeyIsValid">
        <client-header [allowExpand]=false [headerLabel]="'Key Delete'"></client-header>
        <div class="key-config">
            <button (click)="onDeleteKey($event)" class="btn btn-primary">Delete
            </button>
        </div>
    </div>
    <div *ngIf="!gpgKeyIsValid">
        <client-header [allowExpand]=false [headerLabel]="'Key Upload'"></client-header>
        <div class="key-config">
            <table class="key-config__table">
                <tbody>
                <tr>
                    <td class="key-config__cell-display">
                        <form (ngSubmit)="onSubmit()" [formGroup]="keyConfigFormGroup">

                            <label>Key File (*.asc)</label>
                            <br/>
                            <input (change)="selectLocalFile($event)" accept=".asc" id="fileUploadInput"
                                   style="display:none"
                                   type="file">
                            <label class="btn btn-primary" for="fileUploadInput">
                                <span i18n="Button label for selecting a gpg key file">Select GPG Key File</span>
                                <span class="icon icon-right">
                            <i aria-hidden="true" class="fa fa-key"></i>
                        </span>
                            </label>

                            <span class="key-config-container__filename">{{fileName}}</span>


                            <div class="read-only-container">
                                <label>Name:</label>
                                <p>{{keyConfigFormGroup.controls['uid'].value}}</p>
                            </div>

                            <div class="read-only-container">
                                <label>Fingerprint:</label>
                                <p>{{keyConfigFormGroup.controls['fingerprint'].value}}</p>
                            </div>

                            <div>
                                <label>Key Contents:</label>
                                <textarea class="key-config__file-display" disabled
                                          rows="12">{{fileContents}}</textarea>
                            </div>

                            <button [disabled]="isSubmitDisabled()" class="btn btn-primary" type="submit">Submit
                            </button>
                        </form>
                    </td>
                </tr>
                </tbody>
            </table>


            <div class="key-config__progress-area">
                <upload (uploadAborted)="handleUploadAborted()"
                        (uploadComplete)="handleUploadComplete()"
                        (uploadError)="handleUploadError()"
                        [fileNamePrefix]="''">
                </upload>

                <div class="key-config__result-message  key-config__result-message--success {{uploadComplete ? 'key-config__result-message--active' : ''}}">
          <span class="icon icon-left">
            <i aria-hidden="true" class="fa fa-check-circle"></i>
          </span>
                    Key Successfully Uploaded
                </div>

                <div class="key-config__result-message  key-config__result-message--error  {{uploadErrored ? 'key-config__result-message--active' : ''}}">
          <span class="icon icon-left">
            <i aria-hidden="true" class="fa fa-times-circle"></i>
          </span>
                    Key Failed To Upload, Please Check To Make Sure Your Information Is Correct
                </div>
                <div class="key-config__result-message  key-config__result-message--error  {{parseErrored ? 'key-config__result-message--active' : ''}}">
      <span class="icon icon-left">
        <i aria-hidden="true" class="fa fa-times-circle"></i>
      </span>
                    Unable to read details from key file, please check your file.
                </div>
            </div>
        </div>

    </div>
</div>

<message-dialog #messageDialogDeleteKey (affirm)="deleteKey()" [showCancel]="true">
</message-dialog>
