<client-header
  [headerLabel]="'Product group information'"
  [headerGroup]="'productGroupInfo'"
  (click)="headerGroups.productGroupInfo = !headerGroups.productGroupInfo"
></client-header>
<div class="collapse-container" *ngIf="headerGroups.productGroupInfo">
  <rn-text-input
    [inputLabel]="
      this.client.feed_enabled
        ? 'Product group feed display name'
        : 'Product group wire display name'
    "
    [inputControl]="productGroupInfoForm.controls['name']"
    [placeholderText]="
      this.client.feed_enabled
        ? 'Product group feed display name'
        : 'Product group wire display name'
    "
    [isWide]="true"
    [maxLength]="this.client.feed_enabled ? getCharacterLimit('feedDisplayName') : null"
  >
    <rn-input-info-box
      input-info-box
      [svgHeight]="60"
      [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
      [text]="
        this.client.feed_enabled
          ? 'The Product Group Feed Display Name is the name your customers will see when they access your messages.'
          : 'The Product Group Wire Display Name is the name your customers will see when they access your messages.'
      "
    ></rn-input-info-box>
  </rn-text-input>

  <div>
    <rn-checkbox
      [labelText]="'Hide from navigation bar'"
      [inputName]="'hide_pg_wire_display_name'"
      [(checkboxModel)]="currentGroup.hide_pg_wire_display_name"
    ></rn-checkbox>
  </div>

  <ng-container *ngIf="!this.client.feed_enabled">
    <rn-text-input
      [inputLabel]="'Header Name'"
      [inputControl]="productGroupInfoForm.controls['wire_header_name']"
      [placeholderText]="'Header Name'"
      [isWide]="true"
    >
      <rn-input-info-box
        input-info-box
        [svgHeight]="60"
        [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
        [text]="
          'This is the text displayed in the header of the message and customer feed.'
        "
      ></rn-input-info-box>
    </rn-text-input>
  </ng-container>

  <rn-text-input
    [inputLabel]="'Product group internal name'"
    [inputControl]="productGroupInfoForm.controls['internal_name']"
    [placeholderText]="'Product group internal name'"
    [isWide]="true"
  >
    <rn-input-info-box
      input-info-box
      [svgHeight]="60"
      [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
      [text]="
        'The Product Group Internal Name is the name you will see within the CX Builder.'
      "
    ></rn-input-info-box>
  </rn-text-input>

  <div class="input-container" style="display: flex">
    <div class="input">
      <label i18n="Label for product description">Product description</label>
      <rn-form-input-state-control
        [formReference]="productGroupInfoForm.controls['description']"
        [showCharacterCount]="this.client.feed_enabled"
        [totalLength]="getCharacterLimit('description')">
        <ng-container>
          <textarea
            id="product-description"
            placeholder="Add a brief description here"
            id="product-description-input"
            i18n-placeholder="Placeholder text for product description"
            spellcheck="false"
            [formControl]="productGroupInfoForm.controls['description']"
          ></textarea>
        </ng-container>
      </rn-form-input-state-control>
    </div>
  </div>

  <div class="product-id-container" *ngIf="!currentGroupNew">
    <div class="read-only-container">
      <label class="client-id-label">Product ID</label>
      <p class="read-only-id">
        {{ productGroupInfoForm.controls['id'].value }}
      </p>
    </div>
    <span class="tip-text">
      <span
        >For API onboarding, and
        <strong>once set will not be able to be changed</strong>.</span
      >
    </span>
  </div>

  <rn-text-input
    *ngIf="currentGroupNew"
    [inputLabel]="'Product group ID'"
    [inputControl]="productGroupInfoForm.controls['id']"
    [readOnly]="true"
    [newValue]="currentGroupNew"
    [placeholderText]="'New unique product ID'"
    [isWide]="true"
  >
    <rn-input-info-box
      input-info-box
      [svgHeight]="60"
      [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
      [text]="
        'For API onboarding, and <strong>once set will not be able to be changed</strong>.'
      "
    ></rn-input-info-box>
  </rn-text-input>

  <div class="vanity-url">
    <rn-text-input
      [inputLabel]="'Vanity URL'"
      [inputControl]="productGroupInfoForm.controls['vanity_url']"
      [placeholderText]="'Vanity URL'"
      [isWide]="true"
      (blurred)="setVanityUrl($event)"
    >
      <rn-input-info-box
        input-info-box
        [svgHeight]="60"
        [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
        [text]="
          'Make sure your Vanity URL is acquired.  All notifications for this product group will use the vanity URL in its auth links.'
        "
      ></rn-input-info-box>
    </rn-text-input>

    <label *ngIf="!!currentGroup.vanity_url">Check URL</label>
    <div>
      <a (click)="goToVanityURL($event)">{{ currentGroup.vanity_url }}</a>
    </div>
  </div>
  <br />

  <app-product-help-text [productGroup]="currentGroup"></app-product-help-text>
  <app-contact-info-inputs
    [productGroup]="currentGroup"
  ></app-contact-info-inputs>
</div>

<client-header
  [headerLabel]="'Product group design'"
  (click)="headerGroups.productGroupDesign = !headerGroups.productGroupDesign"
></client-header>
<div
  class="collapse-container portal-images-container"
  *ngIf="headerGroups.productGroupDesign"
>
  <div class="image-input-container">
    <app-image-input
      [client]="client"
      [imgModel]="currentGroup"
      [label]="'Icon'"
      [type]="'icon'"
      [tipText]="'Must be at least 95px wide by 95px tall and fewer than 1MB.'"
    ></app-image-input>

    <app-image-input
      [client]="client"
      [imgModel]="currentGroup"
      [label]="'Banner'"
      [type]="'banner'"
      [tipText]="
        'Must be at least 1200px wide by 375px tall and fewer than 1MB.'
      "
    ></app-image-input>

    <div class="input-container" id="color-container">
      <div class="input">
        <label i18n="Product group banner label text">Color</label>
        <color-picker
          [(color)]="currentGroup.branding.color"
          (colorChanged)="currentGroup.branding.color = $event"
        ></color-picker>
        <p class="tip-text clearfix" i18n="Tip text for product group banner">
          <span
            >A dark shade of gray will be used if no color is provided.</span
          >
        </p>
      </div>
    </div>
  </div>
  <div class="preview-container">
    <generic-phone>
      <ng-container *ngIf="!this.client.feed_enabled; else ProductGroupReactPreview">
        <wire-header [productGroup]="currentGroup"></wire-header>
        <network-preview [productGroup]="currentGroup"></network-preview>
      </ng-container>
      <ng-template #ProductGroupReactPreview>
        <product-group-preview-react
          [productGroupDescription]="currentGroup.description"
          [productGroupName]="currentGroup.name"
          [showProductGroup]="!currentGroup.hide_pg_wire_display_name"
          [bannerUrl]="currentGroup.branding.banner_s3_url"
          [logoUrl]="currentGroup.branding.icon_s3_url"
          [color]="currentGroup.branding.color"
        ></product-group-preview-react>
      </ng-template>
    </generic-phone>
  </div>
</div>
