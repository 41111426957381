<div class="journey-metadata-dialog">

  <div class="title-bar">
    <span i18n="Title bar for experience metadata dialog">Let's get started</span>
  </div>

  <div class="dialog-body">

    <rn-text-input [inputLabel]="'Your Experience\'s Name'" [isFullWidth]="true" [inputControl]="journeyGroup.controls['name']"></rn-text-input>

    <app-journey-metadata-product-select [inputControl]="journeyGroup.controls['product_group']"
                                         [(inputJourney)]="journeyContentProperties"></app-journey-metadata-product-select>
   
    <div *ngIf="client.onboarding.csr.enabled === 'true'">
      <label i18n="Label for Validation Required">Validation Required</label>
      <app-radio-btn class="validation-button" [btnModel]="!journeyGroup.controls['bypass_csr_validation'].value" (btnModelChange)="handleChange($event)" [btnLabel]="''" [btnWidth]="'95px'"></app-radio-btn>
    </div>

    <div>
      <label i18n="Experience campaign heading">Campaign</label>
      <span>(optional)</span>
      <input id="journey-campaign" class="form-control" autocomplete="off"
             [formControl]="journeyGroup.controls['campaign']"
             [class.list-border]="campaigns.display === 'block'"
             (dblclick)="toggleCampaignDropdown($event)"
             (click)="toggleCampaignDropdown($event)"
             (keyup)="toggleCampaignDropdown($event)"
             (focus)="toggleCampaignDropdown($event)">

      <div id="campaign-dropdown" [ngStyle]="{display: campaigns.display}">
        <div class="campaign" *ngFor="let campaign of campaigns.match"
             [class.highlight]="campaign.highlighted"
             [ngStyle]="{display: campaigns.display}"
             (mouseenter)="selectCampaign($event)"
             (mouseleave)="selectCampaign($event)"
             (click)="setCampaign(campaign)">{{campaign.value}}
        </div>
      </div>

    </div>
    <label i18n="Experience description heading">Description</label>
    <span>(optional)</span>
    <textarea id="journeyDescription" class="form-control" rows="5" [formControl]="journeyGroup.controls['description']"></textarea>
  </div>

  <div class="footer-bar">
    <span id="cancelBtn" class="cancel-link" (click)="cancel()">
      <span class="icon"><i class="fa fa-angle-left" aria-hidden="true"></i> </span>
        <span i18n="Cancel the current operation">{{btnConfig['cancel']}}</span>
    </span>
    <button id="saveBtn" class="next-link btn btn-primary" (click)="save()">
      <span i18n="Button heading to create a new experience">{{btnConfig['save']}}</span>
      <span class="icon icon-right"><i class="fa fa-angle-right"></i></span>
    </button>
  </div>
</div>

<message-dialog></message-dialog>
