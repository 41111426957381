import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyType, ExpLibResponse, Industry, Outcome } from '@app/core/models/categorization-types';
import { CategorizationService } from '@app/core/services/categorization.service';
import { SortableComponent } from '@app/core/sortable-component';
import { SessionService } from '@app/security/session.service';
import { SortingUtils } from '@app/experience-library/utils/categorization-sorting-utils';

@Component({
  selector: 'rn-outcome-list',
  templateUrl: './outcome-list.component.html',
  styleUrls: ['./outcome-list.component.scss']
})
export class OutcomeListComponent extends SortableComponent implements OnInit {
  outcomes: Outcome[]; 
  companyTypes: CompanyType[];
  industries: Industry[];
  sortedOutcomes: Outcome[];
  clientId: string;
  hasError: boolean = false;
  
  constructor(
    private categorizationService: CategorizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;

    this.categorizationService.getOutcomes(this.clientId).subscribe((outcomesResult: ExpLibResponse<Outcome[]>) => {
      if (!outcomesResult.data) { 
        this.hasError = true;
      };
      this.outcomes = outcomesResult.data;
      this.resetSort();
    }, (error) => {
      this.hasError = true;
    });
    
    this.categorizationService.getCompanyTypes(this.clientId).subscribe((companyTypesResult: ExpLibResponse<CompanyType[]>) => {
      this.companyTypes = companyTypesResult.data;
    }, (error) => {
      this.hasError = true;
    });
    
    this.categorizationService.getIndustries(this.clientId).subscribe((industriesResult: ExpLibResponse<Industry[]>) => {
      this.industries = industriesResult.data;
    }, (error) => {
      this.hasError = true;
    });
  }

  isDataLoaded(data: CompanyType[] | Industry[] | Outcome[]): boolean {
    return data !== undefined;
  }

  navigateToAdd(): void {
    this.router.navigate(['add-outcome'], { relativeTo: this.activatedRoute });
  }

  navigateToEdit(outcomeId: string): void {
    this.router.navigate([`edit-outcome/${outcomeId}`], { relativeTo: this.activatedRoute });
  }

  fetchData() {
    if (this.ordering === undefined) {
      this.resetSort();
    } else if (this.ordering.orderBy === 'id') {
      this.sortedOutcomes = SortingUtils.sortById(this.sortedOutcomes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'title') {
      this.sortedOutcomes = SortingUtils.sortByTitle(this.sortedOutcomes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'created_at') {
      this.sortedOutcomes = SortingUtils.sortByDate(this.sortedOutcomes, this.ordering.direction, 'created_at');
    } else if (this.ordering.orderBy === 'updated_at') {
      this.sortedOutcomes = SortingUtils.sortByDate(this.sortedOutcomes, this.ordering.direction, 'updated_at');
    }
  }

  private resetSort(): void {
    // map is required to ensure that the sorted array isn't an in-place reference to the original,
    // because Array.sort does an in-place modification
    this.sortedOutcomes = this.outcomes.map(item => item);
  }

  get isAllDataLoadedAndNoErrors(): boolean {
    return this.isDataLoaded(this.outcomes) && this.isDataLoaded(this.companyTypes) && this.isDataLoaded(this.industries) && !this.hasError;
  }

  get isDataLoadedAndHasDataAndNoErrors(): boolean {
    return this.isDataLoaded(this.outcomes) && this.outcomes.length === 0 && !this.hasError;
  }
}
