<div class="input-container">
  <p class="agent-script">Agent validation script</p>
  <i *ngIf="!validationFieldsFormGroup.controls['validation_script'].valid && validationFieldsFormGroup.controls['validation_script'].touched" class="fa fa-exclamation-circle textarea-width" aria-hidden="true" tooltip="{{getTooltipData(inputControl?.errors)}}"></i>
  <textarea [formControl]="validationFieldsFormGroup.controls['validation_script']"></textarea>

  <div class="validation-container">
      <p>the script will use the following pieces of information for account validation purposes:
          <strong *ngFor="let field of client.validation.fields">{{field.prompt}}, </strong>
          <strong>CCID.</strong></p>
  </div>
</div>
