<div class="start" *ngIf="arrowType === 'down-right'">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-triangle" width='300' height='100'>
    <line class="vertical-line" stroke-dasharray="5, 5" x1="18%" y1="90%" x2="18%" y2="0" />
    <line stroke-dasharray="5, 5" x1="90%" y1="90%" x2="18%" y2="90%" />
    <path d="M 290,90 276,79 276,99 z" />
  </svg>
</div>
<div class="start" *ngIf="arrowType === 'down-right-short'">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-triangle" width='115' height='95'>
    <line class="vertical-line" stroke-dasharray="5, 5" x1="49%" y1="90%" x2="49%" y2="0" />
    <line stroke-dasharray="5, 5" x1="90%" y1="90%" x2="49%" y2="90%" />    
    <path d="M 110,86 96,75 96,95 z" />
  </svg>
</div>
<div class="continue" *ngIf="arrowType === 'straight-right'">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-triangle" width='100' height='45'>

    <g transform="translate(70, 15)">
      <path class="arrow-line" d="M -70,18 H 17" stroke="#969690" stroke-width="2"></path>
      <path class="arrow-head" d="M 24,17 0,26 0,10 z" fill="#969690" />
    </g>
  </svg>
</div>
<div class="continue-plus" *ngIf="arrowType === 'straight-right-plus'">
  <div class="hex" [class.show]="showHexButtons">
    <img class="hex-btn conditions" [class.expand]="showHexButtons" src="assets/hex/Picker-Trigger.png" (click)="addStep('APITrigger')" />
    <img class="hex-btn delay" [class.expand]="showHexButtons" src="assets/hex/Picker-Delay.png" (click)="addStep('WorkflowDelayer')" />
    <img class="cancel-btn" src="assets/hex/icon-delay.png" (click)="showHexButtons = false" />
    <img class="wrapper-img" src="assets/hex/Picker-CircleFrame.png" />
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-triangle" width='100' height='100'>
    <g transform="translate(70, 17)">
      <path d="M 24,15 0,26 0,9 z" />
    </g>
    
    <line x1="80" y1="33" x2="0" y2="33" />
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="scale(0.7), translate(40, 17)" (click)="showHexButtons = true"  *ngIf="nextMessage?.type !== 'WorkflowEnder'">
      
      <g transform="translate(15, 30)">
        <ellipse id="oval" cx="0  " cy="0" rx="14.0107624" ry="14.2286964" class="plus" [class.grow]="showHexButtons">
        </ellipse>        
      </g>
      <g transform="translate(0,15)">
        <polygon id="plus" fill="#FFFFFF" points="17.36941 21.950631 17.36941 16.6979948 22.1873307 16.6979948 22.1873307 12.4667045 17.36941 12.4667045 17.36941 7.3599748 13.0548541 7.3599748 13.0548541 12.4667045 8.272888 12.4667045 8.272888 16.6979948 13.0548541 16.6979948 13.0548541 21.950631"></polygon>
      </g>
      
      
    </g>
  </svg>
</div>
  