import { InlineDialog } from '@relaynetwork/design-system';
import React from 'react';

const __variant = ['user', 'time', 'user-time'] as const;
export type Variant = typeof __variant[number];

export interface ChatStampProps {
  variant?: Variant;
  user: string;
  timestamp: string;
  style?: React.CSSProperties;
}

export const ChatStamp = ({
  variant = 'user-time',
  user,
  timestamp,
}: ChatStampProps) => {
  const formattedDate = new Date(timestamp).toLocaleString([], {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  switch (variant) {
    case 'user':
      return (
        <InlineDialog
          variant="caption"
          text={user}
          hierarchy="primary"
          textSize="xsmall"
          status="resting"
        ></InlineDialog>
      );
    case 'time':
      return (
        <InlineDialog
          variant="caption"
          text={formattedDate}
          hierarchy="secondary"
          textSize="xsmall"
          status="resting"
        ></InlineDialog>
      );
    case 'user-time':
      return (
        <div>
          <InlineDialog
            variant="caption"
            text={user}
            hierarchy="primary"
            textSize="xsmall"
            status="resting"
          ></InlineDialog>
          <InlineDialog
            variant="caption"
            text={formattedDate}
            hierarchy="secondary"
            textSize="xsmall"
            status="resting"
          ></InlineDialog>
        </div>
      );
  }
};
