import {
  Conversation,
  ConversationUpdateReason,
  JSONValue,
  Message,
  MessageUpdateReason,
  Participant,
  ParticipantUpdateReason,
  User,
  UserUpdateReason,
} from '@twilio/conversations';

export type ConversationUpdatedEvent = {
  conversation: TwilioConversation;
  updateReasons: ConversationUpdateReason[];
};

export type MessageUpdatedEvent = {
  message: Message;
  updateReasons: MessageUpdateReason[];
};

export type ParticipantUpdatedEvent = {
  participant: Participant;
  updateReasons: ParticipantUpdateReason[];
};

export type UserUpdatedEvent = {
  user: User;
  updateReasons: UserUpdateReason[];
};

export type SortDirection = 'backwards' | 'forward';

export class TwilioLaunchedByAttribute {
  portal_id: string;
  lb_name: string;
  lb_source: string;
}

export class TwilioCustomerAttribute {
  id: string;
  first_name: string;
  last_name: string;
}

// Note: tags and tag_id appear to be the same string.
export class TwilioConversationAttribute {
  product_group_id: string;
  tags: string;
  journey_id: string;
  customer: TwilioCustomerAttribute;
  message_id: string;
  client_id: string;
  tag_id: string;
  launched_by: TwilioLaunchedByAttribute;
  customer_id: string;
  last_ping: string;
  isChannelClosed?: boolean;
  initiatedBy?: string;
  conversation_id?: string | null;
  conversation_status?: string | null;
  is_in_blackout_window?: boolean | null;
}

export interface TwilioConversation extends Conversation {
  attributes: TwilioConversationAttribute & JSONValue; // note: this is actually a getter function
}

export class TwilioMedia {
  contentType: string;
  sid: string;
  size: number;
  filename?: string;
}

export interface TwilioMessageAttributes {
  v2: { encrypted: boolean };
  tag_id: string;
}

export interface TwilioMessage extends Message {
  attributes: TwilioMessageAttributes & JSONValue;
}

export const TwoWayConversationMessageEvents = {
  participantJoined: 'participantJoined',
  participantLeft: 'participantLeft',
  participantUpdated: 'participantUpdated',
  messageAdded: 'messageAdded',
  messageRemoved: 'messageRemoved',
  messageUpdated: 'messageUpdated',
  typingEnded: 'typingEnded',
  typingStarted: 'typingStarted',
  updated: 'updated',
} as const;

export const TwoWayConversationClientEvents = {
  stateChanged: 'stateChanged',
  connectionStateChanged: 'connectionStateChanged',
  conversationAdded: 'conversationAdded',
  conversationJoined: 'conversationJoined',
  conversationLeft: 'conversationLeft',
  conversationRemoved: 'conversationRemoved',
  conversationUpdated: 'conversationUpdated',
  participantJoined: 'participantJoined',
  participantLeft: 'participantLeft',
  participantUpdated: 'participantUpdated',
  messageAdded: 'messageAdded',
  messageRemoved: 'messageRemoved',
  messageUpdated: 'messageUpdated',
  pushNotification: 'pushNotification',
  tokenAboutToExpire: 'tokenAboutToExpire',
  tokenExpired: 'tokenExpired',
  typingEnded: 'typingEnded',
  typingStarted: 'typingStarted',
  userSubscribed: 'userSubscribed',
  userUnsubscribed: 'userUnsubscribed',
  userUpdated: 'userUpdated',
} as const;

export enum TwoWayChatStatus {
  unclaimed = 'unclaimed',
  claimed = 'claimed',
  closed = 'closed',
}

export type TwoWayUnassignedChannelAttrs = {
  product_group_id: string;
  tags: string; // uuid.  note this is only ONE uuid.  A channel can only have 1 tag
  createdBy: string;
  dateUpdated: Date;
  journey_id: string;
  customer: TwilioCustomerAttribute;
  message_id: string;
  friendlyName: string; // format is ->  clientid::customerid::tagid
  client_id: string;
  accountSid: string; // twilio account sid
  tag_id: string;
  dateCreated: Date;
  launched_by: TwilioLaunchedByAttribute;
  customer_id: string;
};

/**
 * Two Way unassigned channels come from Our api, not from twilio directly
 */
export type TwoWayUnassignedChannel = {
  updated_at: Date;
  status: TwoWayChatStatus;
  id: string; // relay channel id
  channel_name: string; // format is ->  clientid::customerid::tagid
  attrs: TwoWayUnassignedChannelAttrs;
  claimed_by: string; // relay portal user id
  client_id: string; // relay client id
  created_at: Date;
  service_sid: string; // Twilio service sid
  customer_id: string; // relay customer id
  channel_sid: string; // Twilio channel sid
};

export type TwoWayClosedChannel = {
  updated_at: Date;
  status: TwoWayChatStatus;
  id: string;
  channel_name: string;
  attrs: TwoWayUnassignedChannelAttrs;
  claimed_by: string;
  client_id: string;
  created_at: Date;
  service_sid: string;
  customer_id: string;
  channel_sid: string;
  latest_conversation_closed_at: string;
  latest_conversation_closed_reason: string;
};

// Twilio conversation SDK does not provide for us this type
export type ParticipantConversation = {
  accountSid: string;
  serviceSid: string;
  sid: string;
  uniqueName: string;
  friendlyName: string;
  attributes: TwilioConversationAttribute;
  dateCreated: Date | null;
  dateUpdated: Date | null;
  createdBy: string;
  state: 'inactive' | 'active' | 'closed';
  participantSid: string;
  participantUserSid: string;
  participantIdentity: string;
};
