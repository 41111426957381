<div class="copied-message-container">
  <button
    [ngClass]="{'btn btn-sm btn-primary btn-copy-msg': !iconBtn, 'icon-btn': iconBtn}"
    (click)="copyToClipboard(inputData, $event)"
    matTooltip="{{setMatTooltipMessage()}}"
    matTooltipPosition="above"
    [disabled]="disableBtn"
    >
      <span *ngIf="!iconBtn">{{customBtnText}}</span>
      <span class="icon icon-right">
        <i class="fa fa-clipboard" aria-hidden="true"></i>
      </span>
  </button>
  <span 
    class="copied-message-container__copy-msg" 
    [class.copied-message-container__copy-msg--visible]="isCopyTextIdAndShowMessage(inputData)">
      <i class="fa fa-check"></i>Copied!
  </span>
</div>