<ng-container *ngIf="productGroup">
  <div>
    <div class="intro-line-1" i18n="Introductory for product group list - line 1">Product group information</div>

    <app-new-product-info #productGroupInfo
      [client]="client"
      [currentGroup]="productGroup"
      [currentGroupNew]="currentGroupNew"></app-new-product-info>

  </div>

  <hr>

  <div class="button-container" *ngIf="productGroup !== null">
      <button id="prev-btn" class="btn btn-primary" (click)="cancelCurrent()">
        <span class="icon icon-left">
            <i class="fa fa-arrow-left"></i>
        </span>
        <span class="btn-label" i18n="Label for cancel button">
            Cancel
        </span>
      </button>
      <button id="next-btn" class="btn btn-primary btn-with-icon" (click)="saveProduct()" [class.inactive]="!checkIsValid()">
        <span class="btn-label" i18n="Label for save button">
            Save And Next
        </span>
        <span class="icon icon-right">
            <i class="fa fa-arrow-right"></i>
        </span>
      </button>
  </div>

  <message-dialog></message-dialog>
</ng-container>
