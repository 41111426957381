import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rn-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() labelText?: string; // optional label
  @Input() placeholderText?: string; // optional placeholder option with an empty string value
  @Input() name: string;
  @Input() selectedOption: string;
  @Input() options: string[] | { value: string; label: string }[];
  @Input() disabled: boolean = false;
  @Output() selectChanged: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

  listenForChange(event): void {
    this.selectedOption = event.target.value;
    this.selectChanged.emit(event);
  }

  clear() {
    this.selectedOption = '';
  }
}
