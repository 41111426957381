<div class="grid-row">
  <div class="row">
      <div class="col-sm-1 outcome-id-col truncate">
          <span class="outcome-id">{{outcome.id}}</span>
          </div>

          <div class="col-sm-2 outcome-name-col truncate">
          <span class="outcome-name">{{outcome.title["en-us"]}}</span>
      </div>

      <div class="col-sm-2 outcome-industries-col">
          <span class="industries truncate">
              {{getFirstItemName(outcome, categoryIndex.Industries)}}
          </span>
          <div *ngIf="hasAdditionalItems(outcome, categoryIndex.Industries)" class="additional-industries-bubble">
              <span class="additional-industries-bubble__count">
                  +{{getAdditionalCount(outcome, categoryIndex.Industries)}}
              </span>
          </div>
      </div>
      
      <div class="col-sm-2 outcome-company-types-col">
          <span class="company-types truncate">
              {{getFirstItemName(outcome, categoryIndex.CompanyTypes)}}
          </span>
          <div *ngIf="hasAdditionalItems(outcome, categoryIndex.CompanyTypes)" class="additional-company-types-bubble">
              <span class="additional-company-types-bubble__count">
                  +{{getAdditionalCount(outcome, categoryIndex.CompanyTypes)}}
              </span>
          </div>
      </div>

      <div class="col-sm-2 truncate">
          <span>{{outcome.created_at | date:'shortDate'}}</span>
      </div>

      <div class="col-sm-2 truncate">
          <span>{{outcome.updated_at | date:'shortDate'}}</span>
      </div>

      <div class="color-sm-1 action-column">
          <i id="edit-outcome-btn-{{outcome.id}}" class="fa fa-pencil" aria-hidden="true" (click)="emitEditEvent()"></i>
          <div *ngIf="hasAdditionalItemsAndDetailsClosed" class="expander-div">
              <i *ngIf="!detailsOpen" (click)="toggleDetails()" class="fa fa-caret-right expander-image"></i>
              <i *ngIf="detailsOpen" (click)="toggleDetails()" class="fa fa-caret-down expander-image"></i>
          </div>
      </div>
  </div> 
</div>

<ng-container *ngIf="hasAdditionalItemsAndDetailsOpen">
  <div class="outcome-detail">
      <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-2">
              <div *ngFor="let industry of getAdditionalItems(outcome, categoryIndex.Industries)" class="additional-industry">
                  {{industry}}
              </div>
          </div>
          <div class="col-sm-2">
              <div *ngFor="let companyType of getAdditionalItems(outcome, categoryIndex.CompanyTypes)" class="additional-company-type">
                  {{companyType}}
              </div>
          </div>
      </div> 
  </div>
</ng-container>
