<div *ngIf="isReady()" class="rn-page" [formGroup]="form">
  
  <rn-text-input [inputControl]="experienceTypeIdCtl" [inputLabel]="'Experience Type ID'"></rn-text-input>

  <rn-text-input [inputControl]="experienceTypeNameCtl" [inputLabel]="'Experience Type Name'"></rn-text-input>
  
  <div class="input-container input-container--multiselect">
    <label>Industry, Company Type, and Outcome</label>
    <div class="rn-multi-select">
      <mat-form-field class="select" appearance="outline">
          <mat-select [formControl]="ancestorPathsCtl" multiple> 
            <div *ngFor="let industry of optionsMap;">
              <span class="mat-optgroup-label outer-optgroup">{{industry.name}}</span>
              <div *ngFor="let companyType of industry?.children;">
                <mat-optgroup [label]="companyType.name">
                  <mat-option class="mat-nested-option" *ngFor="let outcome of companyType?.children;" class="select-option" [value]="ancestorPath(industry.id, companyType.id, outcome.id)">
                    {{outcome.name}}
                  </mat-option>
                </mat-optgroup>
              </div>
            </div>
          </mat-select>
      </mat-form-field>
    </div>
  </div>

  <rn-text-input [inputControl]="templateClientCtl" [inputLabel]="'Client'"></rn-text-input>
  
  <div class="btn-bar">
    <button class="btn btn-secondary cancel-btn"
      type="button" (click)="close()">cancel</button>

    <button class="btn btn-primary save-btn" type="button"
      (click)="saveAndClose()">save and exit</button>
  </div>

</div>

<message-dialog #updateErrorDialog></message-dialog>

<message-dialog #loadErrorDialog (affirm)="close()"></message-dialog>