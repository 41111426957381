<div>
  <h4>Customer Information</h4>
  <hr>
  <ng-container *ngIf="userInfo && selectedChannel" >
    <div class="detail-group">
      <p class="label-text">Customer's Name</p>
      <p class="value-text" id="twoway-customerdetail-fullname">{{userInfo.first_name}} {{userInfo.last_name}}</p>
    </div>
    <div class="detail-group">
      <p class="label-text">Account ID (CCID)</p>
      <p class="value-text" id="twoway-customerdetail-ccid">{{userInfo.ccid}}</p>
    </div>
    <div class="detail-group" *ngIf="userInfo.secondary_account_id">
      <p class="label-text">Secondary Account ID</p>
      <p class="value-text" id="twoway-customerdetail-2nd-acct-id">{{userInfo.secondary_account_id}}</p>
    </div>
    <div class="detail-group">
      <p class="label-text">Product Group</p>
      <p class="value-text" id="twoway-customerdetail-pg">{{userInfo.product_group_id}}</p>
    </div>
    <div class="detail-group" *ngIf="userInfo.notification_channels && userInfo.notification_channels.length">
      <p>Notification Channels</p>
      <div class="detail-group" *ngFor="let notification_channel of userInfo.notification_channels; let i = index">
        <p class="label-text">channel address</p>
        <p class="value-text" id="twoway-customerdetail-notichannel-phone-{{i}}">{{notification_channel.channel_addr | phoneNumber}}</p>
        <p class="label-text">consent</p>
        <p class="value-text" id="twoway-customerdetail-notichannel-consent-{{i}}">{{notification_channel.consent_type}}</p>
      </div>
    </div>
    <div class="detail-group">
      <p class="label-text">Last Pinged</p>
      <p *ngIf="userInfo.lastPing" class="value-text" id="twoway-customerdetail-lastping">{{userInfo.lastPing | date : 'short'}}</p>
      <p *ngIf="!userInfo.lastPing" class="value-text">&mdash;</p>
    </div>
    <hr>
  </ng-container>

  <div *ngIf="getUserInfoError">
    <div id="customer-details-error">
      There was an error fetching customer details
    </div>
    <hr>
  </div>
  
  <ng-container *ngIf="selectedChannel">
    <div class="action-buttons">
      <button class="btn btn-primary btn-block" (click)="pingUser()" id="twoway-customerdetail-ping-btn">Ping</button>
      <button class="btn btn-success btn-block" (click)="leave()" id="twoway-customerdetail-leave-btn">Leave</button>
      <button class="btn btn-warning btn-block" (click)="close()" id="twoway-customerdetail-close-btn">Close</button>
      <hr>
      <div class="invite">
        <input type="text" class="form-control" [(ngModel)]="inviteEmail">
        <button class="btn btn-primary btn-block" (click)="invite($event)" id="twoway-customerdetail-invite-btn">Invite</button>
      </div>
    </div>
  </ng-container>
</div>

<message-dialog #leaveDialog [showAffirm]="true" [showCancel]="true" (affirm)="handleLeave()">
</message-dialog>

<message-dialog #closeDialog [showAffirm]="true" [showCancel]="true" (affirm)="handleClose()">
</message-dialog>

<message-dialog #pingDialog>
</message-dialog>

<message-dialog #inviteDialog>
</message-dialog>
