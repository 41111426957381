// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-onboarding-file-generic',
  templateUrl: './product-onboarding-file-generic.component.html',
  styleUrls: ['./product-onboarding-file-generic.component.scss']
})
export class ProductOnboardingFileGenericComponent implements OnInit {
  @Input() label: string;
  @Input() inputModel: any;

  constructor() { }

  ngOnInit() {
  }

}
