import { EventEmitter, Injectable } from '@angular/core';

@Injectable()

export class CsrJourneysModalService {
  openCSRJourneysModal: EventEmitter<void> = new EventEmitter<void>(true);
  closeCSRJourneysModal: EventEmitter<void> = new EventEmitter<void>(true);

  constructor() { }

  open(): void {
    this.openCSRJourneysModal.emit();
  }

  close(): void {
    this.closeCSRJourneysModal.emit();
  }
}
