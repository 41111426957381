/*
 * Place for common stuff between the delay-inspector journey-delay.
 */
export class DelayUtils {

  static allUnits: any;

  static createAllUnits() {
    DelayUtils.allUnits = {
      'Minute': this.createDelayUnits('Minute'),
      'Hour': this.createDelayUnits('Hour'),
      'Day': this.createDelayUnits('Day')
    };
  }

  private static createDelayUnits(selectedEnum: string) {
    const units = [];
    let eNum: number;
    switch (selectedEnum) {
      case 'Minute':
        eNum = 60;
        break;
      case 'Hour':
        eNum = 24;
        break;
      case 'Day':
        eNum = 365;
        break;
    }

    for (let u = 1; u <= eNum; u++) {
      units.push(u);
    }

    return units;
  }
}

// i can't describe enough how much hatred
// i have for throwing an initialization function
// inside a service that has only static functions
DelayUtils.createAllUnits();
