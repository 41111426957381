import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Datacenter } from '@datadog/browser-core'
import { EnvService } from '@app/core/services/env.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { User } from '../models/user';

// Based on Google Analytics event parameters: https://support.google.com/analytics/answer/1033068?hl=en
class TrackEvent {
  category: string;
  label: string;
  action?: string;
  value?: any;

  constructor(data) {
    const { category, label, action, value } = data;
    this.category = category;
    this.label = label;
    this.action = action;
    this.value = value;
  }
}

@Injectable()
export class AnalyticsService {
  private UACodes: object = {
    'production': 'UA-116566934-7',
    'staging': 'UA-116566934-8',
    'qa': 'UA-116566934-9'
  };

  /**
   * Datadog Configuration
   * to test locally, get clientToken and applicationId values out of ssm by running
   * `npm run rumCredentials`, then copy the output and set them in index.html.
   */
  clientToken = _.get(window, 'RN_DD_RUM.clientToken');
  applicationId = _.get(window, 'RN_DD_RUM.applicationId');

  datadogRumInit: Subject<void> = new Subject();

  constructor(private router: Router,
              private envService: EnvService) {}

  init(): void {
    this.initGA();
    this.initDatadogRum();
    this.initRoutingEvents();
  }

  initGA(): void {
    let env = this.envService.getEnv();
    (<any>window).ga('create', this.UACodes[env], 'auto');
    (<any>window).ga('send', 'pageview');
  }

  initDatadogRum(): void {
    datadogRum.init({
      applicationId: this.applicationId,
      clientToken: this.clientToken,
      datacenter: Datacenter.US,
      resourceSampleRate: 100,
      sampleRate: 100
    });

    const env = this.envService.getEnv()
    datadogRum.addRumGlobalContext('env', env);
    
    this.datadogRumInit.next();
  }

  initRoutingEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  } 

  track(category, action, payload: any) {
    this.gaTrack(new TrackEvent({category: category, action: action, value: JSON.stringify(payload)}));
    this.ddTrack(`${category}:${action}`, payload)
  }

  gaTrack(event: TrackEvent): void {
    (<any>window).ga('send', 'event', {
      eventCategory: event.category,
      eventLabel: event.label,
      eventAction: event.action,
      eventValue: event.value
    });
  }

  ddTrack(name: string, payload: {}) {
    datadogRum.addUserAction(name, payload);
  }

  /**
   * track every time we set/get session_id
   * @param session_id
   */
  trackSessionId(session_id: string): void {
    this.datadogRumInit.subscribe(() => {
      datadogRum.addRumGlobalContext('session_id', session_id);
    });
  }

  /**
   * track every time we set/get session_id
   * @param user undefined in the case of un-set user_id events
   */
  trackUser(user: User | {} = {}): void {
    this.datadogRumInit.subscribe(() => {
      datadogRum.addRumGlobalContext('user_id', _.get(user, 'id') || 'no user');
      datadogRum.addRumGlobalContext('roles', _.get(user, 'role_id') || []); // note: role id is a array 
    });
  }

  /**
   * updated on getClient api call
   * @param client_id
   */
  trackClientId(client_id: string): void {
    datadogRum.addRumGlobalContext('client_id', client_id);
  }
}
