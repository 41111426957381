import React from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ChevronRight, Check } from 'baseui/icon';
import {
  SortingDateCreatedProps,
  SortingLastModifiedProps,
  SortingNameProps,
} from '../relay-messenger-list/RelayMessenger.list';

export enum DateCreatedOldestNewestMethods {
  Oldest = 'Oldest',
  Newest = 'Newest',
  DateCreated = 'Date created',
}

interface SortingActionItemProps {
  sortingName: SortingNameProps;
  sortDateCreated: SortingDateCreatedProps;
  sortLastModified: SortingLastModifiedProps;
  close: () => void;
  clearFilterState: () => void;
  clearSortingSelection: () => void;
  dataTestId?: string;
}

export const SortDateAction = ({
  sortingName,
  sortDateCreated,
  sortLastModified,
  close,
  clearFilterState,
  clearSortingSelection,
  dataTestId,
}: SortingActionItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  return (
    <StatefulPopover
      content={() => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          <Button
            onClick={(e) => {
              sortDateCreated.setSortByNewestDateCreated(
                (e.target as HTMLButtonElement).value,
              );
              sortDateCreated.setSortByOldestDateCreated(null);
              sortLastModified.setSortByNewestLastModified(null);
              sortLastModified.setSortByOldestLastModified(null);
              sortingName.setSortByAsc(null);
              sortingName.setSortByDesc(null);
              close();
            }}
            value={DateCreatedOldestNewestMethods.Newest}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  display: 'flex',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                  justifyContent: 'left',
                  color:
                    sortDateCreated.sortingOptionDateCreatedClicked &&
                    sortDateCreated.sortByNewestDateCreated
                      ? '#3693BF'
                      : 'black',
                  fontWeight:
                    sortDateCreated.sortingOptionDateCreatedClicked &&
                    sortDateCreated.sortByNewestDateCreated
                      ? 'bolder'
                      : 'inherit',
                  fontSize: '12px',
                  outline: 'none !important',
                  ':hover': {
                    backgroundColor: '#E1EFF5',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                  },
                },
                props: {
                  'data-testid': `${dataTestId}-newest`,
                },
              },
            }}
            padding={'10px'}
            startEnhancer={() =>
              sortDateCreated.sortingOptionDateCreatedClicked &&
              sortDateCreated.sortByNewestDateCreated ? (
                <Check
                  size={'20px'}
                  color="#3693BF"
                  overrides={{
                    Svg: {
                      style: {
                        display: 'flex',
                      },
                    },
                  }}
                />
              ) : (
                ''
              )
            }
          >
            {DateCreatedOldestNewestMethods.Newest}
          </Button>
          <Button
            onClick={(e) => {
              sortDateCreated.setSortByOldestDateCreated(
                (e.target as HTMLButtonElement).value,
              );
              sortDateCreated.setSortByNewestDateCreated(null);
              sortLastModified.setSortByNewestLastModified(null);
              sortLastModified.setSortByOldestLastModified(null);
              sortingName.setSortByAsc(null);
              sortingName.setSortByDesc(null);
              close();
            }}
            value={DateCreatedOldestNewestMethods.Oldest}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  display: 'flex',
                  color:
                    sortDateCreated.sortingOptionDateCreatedClicked &&
                    sortDateCreated.sortByOldestDateCreated
                      ? '#3693BF'
                      : 'black',
                  fontWeight:
                    sortDateCreated.sortingOptionDateCreatedClicked &&
                    sortDateCreated.sortByOldestDateCreated
                      ? 'bolder'
                      : 'inherit',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                  justifyContent: 'left',
                  fontSize: '12px',
                  outline: 'none !important',
                  ':hover': {
                    backgroundColor: '#E1EFF5',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                  },
                },
                props: {
                  'data-testid': `${dataTestId}-oldest`,
                },
              },
            }}
            padding={'10px'}
            startEnhancer={() =>
              sortDateCreated.sortingOptionDateCreatedClicked &&
              sortDateCreated.sortByOldestDateCreated ? (
                <Check
                  size={'20px'}
                  color="#3693BF"
                  overrides={{
                    Svg: {
                      style: {
                        display: 'flex',
                      },
                    },
                  }}
                />
              ) : (
                ''
              )
            }
          >
            {DateCreatedOldestNewestMethods.Oldest}
          </Button>
        </Block>
      )}
      placement={PLACEMENT.right}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          props: { 'data-testid': 'sorting-date-created-popover' },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          onClick={() => {
            sortDateCreated.setSortOptionDateCreatedClicked(
              DateCreatedOldestNewestMethods.DateCreated,
            );
            sortingName.setSortOptionNameClicked(null);
            sortLastModified.setSortOptionLastModifiedClicked(null);
            clearFilterState();
            clearSortingSelection();
          }}
          value={DateCreatedOldestNewestMethods.DateCreated}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: sortDateCreated.sortingOptionDateCreatedClicked
                  ? '#3693BF'
                  : 'black',
                backgroundColor: sortDateCreated.sortingOptionDateCreatedClicked
                  ? '#E1EFF5'
                  : 'transparent',
                fontWeight: sortDateCreated.sortingOptionDateCreatedClicked
                  ? 'bolder'
                  : 'inherit',
                textAlign: 'left',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'date-created-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            sortDateCreated.sortingOptionDateCreatedClicked &&
            (sortDateCreated.sortByNewestDateCreated ||
              sortDateCreated.sortByOldestDateCreated) ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {DateCreatedOldestNewestMethods.DateCreated}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
