<div class="list-header-container">
  <div class="list-header" *ngIf="listTitle" class="copy-title">
    <span>{{listTitle}}</span>
    <i id="cancel-template-copy" aria-hidden="true" class="fa fa-times"
      routerLink="/cx-builder"></i>
      <app-search-bar [searchableFields]="searchableFields"
        [isFixed]="false"
        (onRequestSearch)="onSearchRequest($event)"
        (onClearSearch)="onSearchClear()">
      </app-search-bar>
  </div>

  <div class="list-header" *ngIf="!listTitle">
    <app-search-bar [searchableFields]="searchableFields"
      [isFixed]="true"
      (onRequestSearch)="onSearchRequest($event)"
      (onClearSearch)="onSearchClear()">
    </app-search-bar>
  </div>
</div>
<div class="container-fluid journey-list"
     data-infinite-scroll
     debounce
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onScrollDown()">
  <div class="row colhdrs">
    <div id="journey-name" class="colw column-heading no-text-selection cursor-pointer colw-28" (click)="onColumnHeadingClick('name')">
      <span i18n="Experience name column heading">Experience Name</span>
      <app-column-sort-icon id="sort-icon-client-name" [ordering]="ordering" [fieldName]="'name'"></app-column-sort-icon>
    </div>
    <div id="journey-outcome" class="colw colw-10 column-heading no-text-selection cursor-pointer colw-13" (click)="onColumnHeadingClick('outcome_name')">
      <span i18n="Experience outcome column heading">Outcome</span>
      <app-column-sort-icon id="sort-icon-client-outcome" [ordering]="ordering" [fieldName]="'outcome_name'"></app-column-sort-icon>
    </div>
    <div id="journey-experience-type" class="colw colw-10 column-heading no-text-selection cursor-pointer colw-13" (click)="onColumnHeadingClick('experience_type_name')">
      <span i18n="Experience type column heading">Experience Type</span>
      <app-column-sort-icon id="sort-icon-client-experience-type" [ordering]="ordering" [fieldName]="'experience_type_name'"></app-column-sort-icon>
    </div>
    <div id="journey-created-at" class="colw column-heading no-text-selection cursor-pointer colw-11" (click)="onColumnHeadingClick('created_at')">
      <span i18n="Experience date created column heading">Date Created</span>
      <app-column-sort-icon id="sort-icon-date-created" [ordering]="ordering" [fieldName]="'created_at'" ></app-column-sort-icon>
    </div>
    <div id="journey-updated-at" class="colw column-heading no-text-selection cursor-pointer colw-11" (click)="onColumnHeadingClick('updated_at')">
      <span i18n="Experience date modified column heading">Date Modified</span>
      <app-column-sort-icon id="sort-icon-date-modified" [ordering]="ordering" [fieldName]="'updated_at'"></app-column-sort-icon>
    </div>
  </div>
  <div *ngIf="isResultsDisplayed">
    <ng-container *ngFor="let experience of experiences; let i = index">
      <div id="experience-{{experience.id.slice(0,8)}}" class="journey" (click)="onExperienceRowClick(experience.id)">
        <div class="row journey-row">
          <div class="colw journey-name left wrdbrk cursor-pointer colw-28">
            <span>{{experience.live.name}}</span>
          </div>
          <div class="colw journey-data-field wrdbrk cursor-pointer colw-13">
            <span>{{experience.live.outcome_names}}</span>
          </div>
          <div class="colw journey-data-field wrdbrk cursor-pointer colw-13">
            <span>{{experience.live.experience_type_names}}</span>
          </div>
          <div class="colw journey-data-field cursor-pointer colw-11">
            <span>{{experience.created_at | date:'shortDate'}}</span>
          </div>
          <div class="colw journey-data-field cursor-pointer colw-17">
            <span>{{experience.updated_at | date:'shortDate'}}</span>
          </div>
          <div class="colw journey-button-row right colw-12 position-relative">
            <button id="use-btn-{{experience.id.slice(0,8)}}" class="btn btn-primary btn-copy-journey" (click)="copyExperience(experience)">
              <span class="btn-label" i18n="Label for use-experience button">use this</span>
              <span class="icon icon-right">
                <i class="fa fa-external-link"></i>
              </span>
            </button>
            <div class="expander-div">
              <i *ngIf="!objDisplayingExperiences[experience.id]" class="fa fa-caret-right expander-image" matTooltip="Expand Experience" matTooltipPosition="above"></i>
              <i *ngIf="objDisplayingExperiences[experience.id]" class="fa fa-caret-down expander-image" matTooltip="Unexpand Experience" matTooltipPosition="above"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row journey-details" *ngIf="objDisplayingExperiences[experience.id]">
        <journey-details
          [journeyRecord]="experience"
          [messageView]="'normal'"
          [displayOption]="getDisplayOption"
          [selectionType]=""
          [copyType]=""
          (copyJourneyRequested)="null"
          [copyMsgParams]=""
          [includeSmsOnlyMessages]="true">
        </journey-details>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!isResultsDisplayed" class="not-found">
    No experiences were found.
  </div>
  <div *ngIf="hasError" class="not-found" i18n="Message that the user gets when there was an error">
    An error occured with your request, please try again later.
  </div>
  <app-spinner *ngIf="!isLoaded" class="not-found"></app-spinner>
</div>
<message-dialog></message-dialog>
