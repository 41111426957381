// angular + modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { RelaySharedModule } from '@app/shared/shared.module'; 

// services
import { FileUploadService } from '@app/file-launcher/services/file-upload.service';

// components
import { FileLauncherPageComponent } from '@app/file-launcher/components/file-launcher-page/file-launcher-page.component'
import { FileLauncherComponent } from '@app/file-launcher/components/file-launcher/file-launcher.component'
import { UploadDropAreaComponent } from '@app/file-launcher/components/upload-drop-area/upload-drop-area.component';
import { LaunchResultComponent } from '@app/file-launcher/components/launch-result/launch-result.component';
import { UploadProgressComponent } from '@app/file-launcher/components/upload-progress/upload-progress.component';
import { LaunchChoiceComponent } from '@app/file-launcher/components/launch-choice/launch-choice.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RelaySharedModule,
  ],
  declarations: [
    FileLauncherPageComponent,
    UploadDropAreaComponent,
    LaunchResultComponent,
    UploadProgressComponent,
    FileLauncherComponent,
    LaunchChoiceComponent,
  ],
  providers: [
    FileUploadService,
  ],
  exports: [
    FileLauncherPageComponent,
    UploadDropAreaComponent,
    LaunchResultComponent,
    UploadProgressComponent,
    FileLauncherComponent,
    LaunchChoiceComponent,
  ]
})

export class FileLauncherModule {
}
