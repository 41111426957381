import React from 'react';
import { useStyletron } from 'baseui';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { ListItemLabel } from '../list-item-label/ListItemLabel';
import { ClosedReasons } from '../closed-reasons-axios-service/closed-reasons-axios-service';
import { Button } from 'baseui/button';
interface ListOfReasonsProps {
  closedReasons: ClosedReasons[];
  setClosedReasons: React.Dispatch<React.SetStateAction<ClosedReasons[]>>;
  isClosedReasonsEnabled: number;
}

export const ListOfReasons = ({
  closedReasons,
  setClosedReasons,
  isClosedReasonsEnabled,
}: ListOfReasonsProps) => {
  const [css] = useStyletron();

  const handleInputBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
    index: number,
  ) => {
    const newValue = (event.target as HTMLInputElement).value;
    setClosedReasons((prevReasons: ClosedReasons[]) =>
      prevReasons.map((reason, i) =>
        i === index ? { ...reason, reason: newValue } : reason,
      ),
    );
  };

  const handleCheckboxChange = (index) => {
    const newClosedReasons = closedReasons.map((reason, i) => {
      if (i === index) {
        return {
          ...reason,
          allowAdditionalDetails: !reason.allowAdditionalDetails,
        };
      }
      return reason;
    });
    setClosedReasons(newClosedReasons);
  };

  return (
    <div
      className={css({
        backgroundColor: isClosedReasonsEnabled
          ? 'transparent'
          : 'rgb(239, 239, 239, 0.66)',
      })}
      data-testid="list-of-reasons"
    >
      <div
        className={css({
          paddingTop: '12px',
          paddingBottom: '12px',
          paddingLeft: '56px',
          paddingRight: '56px',
          display: 'flex',
          flexGrow: 1,
          borderTop: '3px solid #f3f4f4',
          borderBottom: '3px solid #f3f4f4',
          fontWeight: '700',
          color: isClosedReasonsEnabled ? '#908E8F' : '#CDCDCD',
        })}
      >
        <div
          className={css({
            width: '70%',
          })}
        >
          Reason
        </div>
        <div className={css({
        marginLeft: '175px',
      })}
        >Include text box for details</div>
      </div>
      <List
        items={closedReasons}
        onChange={({ oldIndex, newIndex }) => {
          setClosedReasons(
            newIndex === -1
              ? arrayRemove(closedReasons, oldIndex)
              : arrayMove(closedReasons, oldIndex, newIndex),
          );
        }}
        overrides={{
          Root: {
            style: {
              zIndex: isClosedReasonsEnabled ? 0 : -10,
              position: 'relative',
            },
            props: {
              'data-testid': 'root-list',
            },
          },
          Item: {
            style: {
              width: '100%',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#f3f4f4',
              ':hover': {
                borderRightColor: 'transparent',
                borderLeftColor: 'transparent',
                borderTopColor: 'transparent',
                borderBottomColor: 'transparent',
                borderRadius: '5px',
                backgroundColor: 'rgba(54, 147, 191, 0.2)',
              },
            },
            props: {
              'data-testid': 'list-item',
            },
          },
          Label: {
            component: (props) => {
              const {
                $value,
                $index,
              }: { $value: ClosedReasons; $index: number } = props;
              return (
                <>
                <ListItemLabel
                closedReasons={closedReasons}
                  closedReason={$value}
                  index={$index}
                  isClosedReasonsEnabled={isClosedReasonsEnabled}
                  handleInputBlur={handleInputBlur}
                  handleCheckboxChange={handleCheckboxChange}
                />
    
                  <Button
                    disabled={$value.id !== undefined}
                    size="mini"
                    kind="secondary"
                    onClick={() => {
                      setClosedReasons(closedReasons.filter((_, i) => i !== $index))
                    }}
                    overrides={{
                      BaseButton: {
                        style: {
                          marginLeft: '16px',
                          backgroundColor: ($value.id !== undefined) ? 'transparent !important' : 'transparent !important',
                          color: ($value.id !== undefined) ? 'transparent !important' : 'red !important'
                        },
                      },
                    }}
                  >
                       <i className="fa fa-trash" style={{ fontSize: '18px' }}></i>
                    </Button>
               </>
              );
            },
          },
        }}
      />
    </div>
  );
};
