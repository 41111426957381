import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ShortcodeMgmtComponent
} from '@app/shortcode-mgmt/shortcode-mgmt-component/shortcode-mgmt.component';
import { ReactiveFormsModule } from '@angular/forms';
import { KeywordResponseComponentComponent } from './keyword-response-component/keyword-response-component.component';
import { RelaySharedModule } from '@app/shared/shared.module';



@NgModule({
  declarations: [ShortcodeMgmtComponent, KeywordResponseComponentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RelaySharedModule
  ]
})
export class ShortcodeMgmtModule { }
