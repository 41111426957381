import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { SecureHttp } from '@app/security/secure-http';
import { environment } from '@env/environment';
import { PowerUpsLiveForm } from '@app/core/models/power-ups-live-form';

@Injectable()
export class PowerUpsService {
  powerUpsUrlLegacy = environment.powerUpsApiURLBase;
  powerupsUrl = environment.powerUpApiURLBase;

  constructor(private secureHttp: SecureHttp) {}

  /**
   * getLiveForm
   * @param clientId
   * @returns an Observable of PowerUpsLiveForms
   */
  getLiveForm(clientId: string, formId: string): Observable<PowerUpsLiveForm> {
    const url = `${this.powerupsUrl}/jotform/${clientId}/forms/${formId}`;
    return this.secureHttp.get(url).pipe(take(1));
  }
}
