import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry, ExperienceType, ExpLibResponse } from '@app/core/models/categorization-types';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { take } from 'rxjs/operators';
import { CategorizationService, OptionsMapNode } from '@app/core/services/categorization.service';
import { Observable } from 'rxjs';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'rn-experience-type-add-edit',
  templateUrl: './experience-type-add-edit.component.html',
  styleUrls: ['./experience-type-add-edit.component.scss'],
})
export class ExperienceTypeAddEditComponent implements OnInit {
  @ViewChild('updateErrorDialog') updateErrorDialog: MessageDialogComponent;
  @ViewChild('loadErrorDialog') loadErrorDialog: MessageDialogComponent;
  clientId: string;
  experienceTypeId: string | undefined;
  industryOptions: Industry[]; 
  experienceTypes: ExperienceType[];
  form: UntypedFormGroup;
  optionsMap: OptionsMapNode[];


  constructor(
    private sessionService: SessionService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private titleService: TitleService,
    private categorizationService: CategorizationService
  ) {}

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.experienceTypeId = this.activatedRoute.snapshot.params['experience-type-id']

    this.categorizationService.getOptionsMap(this.clientId).subscribe((result) => {
      this.optionsMap = result;
    }, (error) => {
      this.loadErrorDialog.showMessage('There was an error loading industry, company type, and outcome options.  Please try again.')
    });

    this.categorizationService.getIndustries(this.clientId).pipe(
      take(1)
    ).subscribe((response: ExpLibResponse<Industry[]>) => {
      this.industryOptions = response.data;
    }, (error) => {
      this.loadErrorDialog.showMessage('There was an error loading this experience type.  Please try again.')
    });

    if (this.isNew()) {
      this.setupNew();
    } else {
      this.setupExisting();
    }
  }

  get ancestorPathsCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('ancestor_paths');
  }

  get experienceTypeIdCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('id');
  }

  get experienceTypeNameCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('name');
  }

  ancestorPath(industryId, companyTypeId, outcomeId): string {
    return `${industryId}.${companyTypeId}.${outcomeId}`;
  }
  
  isNew(): boolean {
    return this.experienceTypeId === undefined;
  }

  close(): void {
    this.router.navigateByUrl('/experience-library/admin?tab=experience-type-tab')
  }

  saveAndClose(): void {
    if (this.form.invalid) { 
      this.form.markAllAsTouched();
      return; 
    }
    let request: Observable<ExpLibResponse<ExperienceType>>;
    const newExperienceType: ExperienceType = {
      title: {
        'en-us': this.form.get('name').value
      },
      id: this.form.get('id').value,
      ancestor_paths: this.form.get('ancestor_paths').value,
    };

    if (this.isNew()) {
      request = this.categorizationService.addExperienceType(
        this.clientId, 
        newExperienceType
      );
    } else {
      request = this.categorizationService.updateExperienceType(
        this.clientId, 
        newExperienceType.id, 
        newExperienceType
      );
    }

    request.subscribe((result) => {
      this.close();
    }, (error) => {
      this.updateErrorDialog.showMessage(`There was an error ${this.isNew() ? 'creating' : 'updating'} this experience type.  Please try again.`)
    });
  }

  isReady(): boolean {
    return this.form !== undefined;
  }

  private setupNew(): void {
    this.titleService.activate('Add Experience Type');
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      ancestor_paths: new UntypedFormControl([]),
    });
  }

  private setupExisting(): void {
    this.titleService.activate('Edit Experience Type');
    this.categorizationService.getExperienceType(this.clientId, this.experienceTypeId).pipe(
      take(1)
    ).subscribe((experienceType: ExpLibResponse<ExperienceType>) => {
      this.form = new UntypedFormGroup({
        id: new UntypedFormControl(experienceType.data.id), // not required because its disabled
        name: new UntypedFormControl(experienceType.data.title['en-us'], Validators.required),
        ancestor_paths: new UntypedFormControl(experienceType.data.ancestor_paths),
      });
      this.experienceTypeIdCtl.disable();
    }, (error) => { 
      this.loadErrorDialog.showMessage('There was an error loading this experience type. Please try again.')
    });
  }
}
