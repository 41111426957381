import { Permissions } from '@app/core/services/permission.service';
import { AuthGuardService } from '@app/security/auth-guard.service';
import { SetupSummaryComponent } from '@app/two-way/setup/components/setup-summary/setup-summary.component';
import { SetupContainerComponent } from '@app/two-way/setup/components/setup-container/setup-container.component';
import { SetupUsersComponent } from '@app/two-way/setup/components/setup-users/setup-users.component';
import { ControlTagsComponent } from '@app/two-way/control-tags/control-tags.component';
import { SetupSmsPingComponent } from '@app/two-way/setup/components/setup-sms-ping/setup-sms-ping.component';
import { SetupAutoResponseComponent } from '@app/two-way/setup/components/setup-auto-response/setup-auto-response.component';
import { MessagingComponent } from '@app/two-way/messaging/messaging.component';
import { RelayMessengerComponent } from '@app/two-way/relay-messenger/relay-messenger.component';
import { SetupConversationsComponent } from './setup/components/setup-conversations/setup-conversations.component';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { TwoWayDashboardComponent } from './dashboard/components/two-way-dashboard.component';

export const TwoWayRoutes = [
  {
    path: 'two-way/setup',
    component: SetupContainerComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.two_way_setup,
      config: 'two_way_config.enabled',
    },
    children: [
      {
        path: 'users',
        component: SetupUsersComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
      {
        path: 'auto-response',
        component: SetupAutoResponseComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
      {
        path: 'sms-ping',
        component: SetupSmsPingComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
      {
        // same as the above route, but for new Feed
        path: 'notification-ping',
        component: SetupSmsPingComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
      {
        path: 'conversations',
        component: SetupConversationsComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
      {
        path: 'summary',
        component: SetupSummaryComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.two_way_setup,
        },
      },
    ],
  },
  {
    path: 'two-way/control-groups',
    component: ControlTagsComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.two_way_setup,
      config: 'two_way_config.enabled',
    },
  },
  {
    path: 'two-way/messaging',
    component: MessagingComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.two_way_messaging,
    },
  },
  {
    path: 'two-way/relay-messenger',
    component: RelayMessengerComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.two_way_messaging,
    },
  },
{
  path: 'two-way/dashboard',
    component: TwoWayDashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.two_way_setup,
    },
  }
];
