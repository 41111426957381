import { Component, Input } from '@angular/core';

@Component({
  selector: 'rn-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input() status: string;

  constructor() { }

  statusClasses() {
    return `status status--${this.status}`;
  }
}
