<div>
    <app-trigger-selector *ngIf="journey"
        [journey]="journey"
        (triggerSelected)="selectTrigger($event)"
        (selectionCanceled)="cancelSelectedTrigger()">
    </app-trigger-selector>

    <div class="launcher-content" *ngIf="journey">

      <div *ngIf="fetchingUsers">
        <app-spinner class="mask"></app-spinner>
      </div>

      <rn-progress-tabs [tabs]="statusTabs" [selectedTabId]="journeyLaunchState"></rn-progress-tabs>

      <div class="launcher-inner-content" *ngIf="journeyLaunchState != journeyLaunchStates['successfullyLaunched']">
        <div class="mask" *ngIf="!triggerComponent"><div ><h2 style="color:white;text-align:center">Please select a trigger</h2></div></div>
        <tabset #launcherTabs class="launcher-tabs">
          <tab heading="Select from existing  users" (select)="onSelectUsersTab($event)">
            <div class="select-user-tab-content" *ngIf="currentStep === -1">
              <label *ngIf="tab1ValidationError" class="validation-error">{{tab1ValidationError}}</label>
              <p>Search by Name, Mobile Number, or Account ID (CCID).</p>
              <div class="multi-select-wrapper" (click)="focusSearchText()">
                <div class="multi-select">
                  <ul class="selected-users">
                    <li class="user" *ngFor="let user of selectedUsers"><span style="text-transform:capitalize">{{user.name.toLowerCase()}}</span><span class="deselect" (click)="deselectUser(user)"><i class="fa fa-times"></i></span></li>
                  </ul>
                  <input id="customer-search-input" #searchTextInput [(ngModel)]="searchText" (keyup.enter)="submitSearchRequest()"/>
                </div>
                <button id="submit-search" class="submit-search btn btn-primary" (click)="submitSearchRequest()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <div class="selectable-list">
                <div class="list-row list-row--header">
                  <div class="list-column">Name</div>
                  <div class="list-column">Mobile Number</div>
                  <div class="list-column">Account ID (CCID)</div>
                  <div class="list-column">Product Group</div>
                  <div class="list-column column-icon"></div>
                </div>
                <div class="list-row" [ngClass]="{'list-row-disabled' : customerAllStopConsent(user)}" *ngFor="let user of searchList" (click)="onUserRowClick(user)">
                  <div class="list-column">
                    <span>&nbsp;<input [disabled]="customerAllStopConsent(user)" type="checkbox" [(ngModel)]="user.selected" (change)="addToSelectedUsers(user)" (click)="$event.stopPropagation()"/>&nbsp;</span>
                    {{user.name.toLowerCase()}}
                  </div>
                  <div class="list-column">
                    <ng-container *ngFor="let phone of user.notification_channels">
                      <div class="phone-number" *ngIf="phone.channel_type === 'sms'">{{phone.phone_number | phoneNumber}}</div>
                    </ng-container>
                  </div>
                  <div class="list-column">{{user.ccid}}</div>
                  <div class="list-column">{{user.product_group_id}}</div>
                  <div class="list-column column-icon"><i class="fa fa-exclamation-circle" *ngIf="customerAllStopConsent(user)" [matTooltip]="getConsentTooltip(user)" matTooltipPosition="above"></i></div>
                </div>
              </div>
            </div>
            <div *ngIf="currentStep >= 0">
              <div style="text-align:center;">
                <div>{{currentStep + 1}} of {{selectedUsers.length}}</div>
                <div>
                  <span *ngFor="let child of selectedUsers; let i = index" class="dot" [class.active]="i === currentStep" (click)="setFormsByIndex(i)">
                  </span>
                </div>
              </div>
              <app-input-params [(inputParams)]="inputParams" [selectedUser]="selectedUsers[currentStep]" (statusChanged)="checkInputReadyState()"></app-input-params>
              <label for="client_message_tag">Client Message Tag (optional)</label>
              <input type="text" class="form-control client_message_tag" id="client_message_tag" [(ngModel)]="clientMessageTags[currentStep]">
              <div style="text-align:center;">
                <div>
                  <span *ngFor="let child of selectedUsers; let i = index" class="dot" [class.active]="i === currentStep" (click)="setFormsByIndex(i)">
                  </span>
                </div>
                <div>{{currentStep + 1}} of {{selectedUsers.length}}</div>
              </div>
            </div>

            <br/>
            <div class="pull-left">
              <div (click)="getPrevForm()" class="btn btn-primary" *ngIf="currentStep !== - 1">
                <span class="icon icon-left"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                <span i18n="Next button label for launch">previous</span>
              </div>
            </div>
            <div class="pull-right">
              <div (click)="getNextForm()" class="btn btn-primary" *ngIf="currentStep !== (selectedUsers.length - 1)">
                <span i18n="Next button label for launch">Next</span>
                <span class="icon icon-right"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
              </div>
              <div class="btn btn-lg btn-warning" (click)="onLaunchButtonClicked()" id="launch-button"
                   [class.inactive]="journeyLaunchState !== journeyLaunchStates.readyToLaunch"
                   *ngIf="currentStep === (currentStep >= 0 && (selectedUsers.length - 1))">
                <span i18n="Launch button label">Launch</span>
                <span class="icon icon-right"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
              </div>
            </div>
            <div class="clearfix"></div>
          </tab>
          <!-- the condition on the container will only show/hide the tab -->
          <tab *ngIf="hasRequiredPermission()" heading="Upload a file" (select)="onSelectUploadTab()">
            <!-- this condition will hide the actual upload UI. -->
            <rn-file-launcher *ngIf="triggerComponent" [triggerId]="triggerComponent.name"></rn-file-launcher>
          </tab>
        </tabset>
        <br/>
        <div class="bottom-buttons">
          <div class="pull-left">
            <div class="cancel-link" (click)="goBack()">
              <span class="icon"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                <span i18n="Label for cancel button">Cancel</span>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="launcher-inner-content" *ngIf="journeyLaunchState == journeyLaunchStates['successfullyLaunched'] && currentTab === tabs.upload">
        <journey-launched
            [launchResult]="journeyLaunchResponse"
            [leftButtonLabel]="'Monitor'"
            (leftButtonClick)="monitor()"
            [rightButtonLabel]="'Launch Again'"
            (rightButtonClick)="launchAgain()"
            [scheduleTime]="scheduleTime">
        </journey-launched>
      </div>
      <div class="launcher-inner-content" *ngIf="journeyLaunchState == journeyLaunchStates['successfullyLaunched'] && currentTab === tabs.selectUser">
        <journey-launched
            [launchResult]="journeyLaunchResponse"
            [rightButtonLabel]="'Launch Again'"
            (rightButtonClick)="launchAgain()"
            [scheduleTime]="scheduleTime">
        </journey-launched>
      </div>
    </div>
</div>

<schedule-launch-dialog
    [title]="'Schedule Launch'"
    [(showDialog)]="showSchedulingDialog"
    [(scheduleTime)]="scheduleTime"
    (confirmTime)="onConfirmScheduledLaunchTime()">

    <ng-container schedule-button-content>
        <span class="btn-label" i18n="Label for button that immediately launches an experience">Schedule Launch</span>
        <span class="icon icon-right" ><i class="fa fa-paper-plane" aria-hidden="true"></i></span>
    </ng-container>

</schedule-launch-dialog>

<message-dialog #errorDialog></message-dialog>

<message-dialog #blackoutWarningDialog id="blackoutWarningDialog" [showCancel]="true" (affirm)="onAffirmScheduleInBlackoutWindow()" (cancel)="onCancelScheduleInBlackoutWindow(!isOnSelectUserTab())">
    <p style="text-align: left;">
        You've chosen a time that is in the blackout window ({{blackoutStartDisplay}} to {{blackoutEndDisplay}} Eastern Time. {{weekendBlackoutMessage}}).
        If you use this time, customers cannot receive the SMS for this launch until {{blackoutEndDisplay}} Eastern Time after the scheduled time.
    </p>

    <p>
      {{isOnSelectUserTab() ? 'Would you like to proceed?' : 'Would you like to proceed with this time? Click cancel to choose another time.'}}
    </p>
</message-dialog>
