import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ApiKeyService } from '@app/api-mgmt/components/apikey-mgmt/services/api-key.service';
import { CreateKeyResponse, GetKeysResponse } from '@app/core/models/api-key';

@Component({
  selector: 'rn-apikey-mgmt',
  templateUrl: './apikey-mgmt.component.html',
  styleUrls: ['./apikey-mgmt.component.scss'],
})
export class ApikeyMgmtComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  existingKeys: string[] = [];
  clientId: string;

  constructor(
    private titleService: TitleService,
    private sessionService: SessionService,
    private apiKeyService: ApiKeyService
  ) {}

  ngOnInit() {
    this.titleService.activate('API Management');
    this.clientId = this.sessionService.getCurrentUsersClient().id;

    this.apiKeyService.getKeys(this.clientId).subscribe(
      (g: GetKeysResponse) => {
        this.existingKeys = g.apikeys;
      },
      () => {
        this.existingKeys = [];
      }
    );
  }

  hasExistingKeys(): boolean {
    return this.existingKeys && this.existingKeys.length > 0;
  }

  createApiKey(): void {
    this.apiKeyService.createKey(this.clientId).subscribe(
      (c: CreateKeyResponse) => {
        this.existingKeys.push(c.key);
      },
      () => {
        this.messageDialog.showMessage('Failed to create an API key.');
      }
    );
  }
}
