import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@app/core/utils/clipboard';

let timer;

@Component({
  selector: 'rn-copied-message',
  templateUrl: './copied-message.component.html',
  styleUrls: ['./copied-message.component.scss'],
})

export class CopiedMessageComponent implements OnInit {
  @Input() inputData: string; // Data that needs to be copied to clipboard
  @Input() customBtnText: string = 'Copy to Clipboard';
  @Input() iconBtn: boolean = false; // set to true to display only copy icon, set to false to display "Copy to Clipboard" button and copy icon with relevant css styling
  @Input() disableBtn: boolean = false; // There is a need to disable copy button in some instances
  @Input() copyFromElement: boolean = false; // This helps to distinguish between Clipboard.copyFromElement(data) and Clipboard.copy(data)
  showCopyMsg: boolean = false;
  copyTextId: string = '';

  ngOnInit() {
    clearTimeout(timer);
  }

  copyToClipboard(data: string, event): void {
    event.stopPropagation();

    if (this.copyFromElement) {
      Clipboard.copyFromElement(data);
    } else {
      Clipboard.copy(data);
    }
    this.copyTextId = data;
    this.showCopyMsg = true;

    timer = setTimeout(() => {
      this.showCopyMsg = false;
      this.copyTextId = '';
    }, 800);
  }

  isCopyTextIdAndShowMessage(data: string): boolean {
    return this.showCopyMsg && data === this.copyTextId;
  }

  setMatTooltipMessage(): string {
    return this.iconBtn ? 'Copy' : '';
  }
}
