import React from 'react';
import { useStyletron } from 'styletron-react';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import {
  validateName,
  validateUri,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { NoAuthConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { HTTPMethodSelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/HTTPMethodSelectBox';

export const NoAuthSettings = ({
  authSettings,
  updateAuthSettings,
  integrationNames,
  integration,
}: {
  authSettings: any;
  updateAuthSettings: (value: any) => void;
  integrationNames: string[];
  integration?: NoAuthConfiguration;
}) => {
  const [css] = useStyletron();

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <>
        <Redirect
          link="https://clientdocs.relayzone.com/#_push_event_webhook"
          value="View Relay Integration Documentation"
        />
        <InputBox
          title="Name"
          value={authSettings.name}
          placeholder="Integration Name"
          validate={(value) => {
            return validateName(value, integration.name, integrationNames);
          }}
          onChange={(value) => updateAuthSettings({ name: value })}
          preValidate={isExistingIntegration}
        />
        <InputBox
          placeholder="https://www.example.com"
          title="URI/Endpoint"
          value={authSettings.uri}
          validate={(value) => {
            return validateUri(value);
          }}
          onChange={(value) => updateAuthSettings({ uri: value })}
          preValidate={isExistingIntegration}
        />
        <HTTPMethodSelectBox
          placeholder="Select HTTP Method"
          title="HTTP Method"
          value={authSettings.method}
          onChange={(value) => updateAuthSettings({ method: value })}
        />
      </>
    </div>
  );
};
