import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from '@app/core/models/client';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';

@Component({
  selector: 'rn-client-helpsite-generator',
  templateUrl: './client-helpsite-generator.component.html',
  styleUrls: ['./client-helpsite-generator.component.scss'],
})
export class ClientHelpsiteGeneratorComponent implements OnInit {
  @Input() client: Client;
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveClicked: EventEmitter<string> = new EventEmitter<string>();
  formGroup: UntypedFormGroup;
  touFormGroup: UntypedFormGroup;
  privacyFormGroup: UntypedFormGroup;
  clientSupportControl: UntypedFormControl;

  // Due to how the toggle button is configured we have to maintain these
  // as the model component, and update the form group based off of events.
  showClientPrivacyUrl: boolean;
  showClientTermsOfUse: boolean;
  includeRelayPrivacy: boolean;
  includeRelayTermsOfUse: boolean;

  constructor() {}

  ngOnInit(): void {
    this.showClientPrivacyUrl = true;
    this.showClientTermsOfUse = true;
    this.includeRelayPrivacy = true;
    this.includeRelayTermsOfUse = true;

    this.privacyFormGroup = new UntypedFormGroup(
      {
        'show-client-privacy-url': new UntypedFormControl(this.showClientPrivacyUrl), // validation handled by group validator
        'client-privacy-policy-url': new UntypedFormControl('', [
          CustomValidators.standardValidURL,
          CustomValidators.beginsWithHttpOrHttps,
        ]), // validation handled by group validator
        'include-relay-privacy': new UntypedFormControl(this.includeRelayPrivacy),
      },
      [
        CustomValidators.toggledOnRequiresValue({
          toggleControlName: 'show-client-privacy-url',
          valueControlName: 'client-privacy-policy-url',
        }),
        CustomValidators.atLeastOneControlHasTrueValue(),
      ]
    );

    this.touFormGroup = new UntypedFormGroup(
      {
        'client-terms-of-use-url': new UntypedFormControl('', [
          CustomValidators.standardValidURL,
          CustomValidators.beginsWithHttpOrHttps,
        ]), // validation handled by group validator
        'show-client-terms-of-use-url': new UntypedFormControl(
          this.showClientTermsOfUse
        ), // validation handled by group validator
        'include-relay-terms-of-use': new UntypedFormControl(
          this.includeRelayTermsOfUse
        ),
      },
      [
        CustomValidators.toggledOnRequiresValue({
          toggleControlName: 'show-client-terms-of-use-url',
          valueControlName: 'client-terms-of-use-url',
        }),
        CustomValidators.atLeastOneControlHasTrueValue(),
      ]
    );

    this.clientSupportControl = new UntypedFormControl('', [
      Validators.required,
      CustomValidators.standardValidURL,
      CustomValidators.beginsWithHttpOrHttps,
    ]);

    this.formGroup = new UntypedFormGroup(
      {
        'client-name-short': new UntypedFormControl('', Validators.required),
        'client-name-long': new UntypedFormControl('', Validators.required),
        'client-short-long-code': new UntypedFormControl('', Validators.required),
        'client-support-text': new UntypedFormControl('', Validators.required),
        'client-support-phone': new UntypedFormControl('', Validators.required),
        'client-support-url': this.clientSupportControl,
        'privacy-form': this.privacyFormGroup,
        'terms-of-use-form': this.touFormGroup,
      },
      undefined,
      undefined
    );

    this.formGroup.updateValueAndValidity();
  }

  save() {
    const helpSiteHtml = document.getElementById('helpSitePreview').outerHTML;
    this.saveClicked.emit(helpSiteHtml);
  }

  handleTOUToggle(event: string | boolean) {
    this.touFormGroup.get('show-client-terms-of-use-url').patchValue(event);
    this.touFormGroup.markAsDirty();
    this.formGroup.markAsDirty();
  }

  handlePrivacyToggle(event: string | boolean) {
    this.privacyFormGroup.get('show-client-privacy-url').patchValue(event);
    this.privacyFormGroup.markAsDirty();
    this.formGroup.markAsDirty();
  }

  isSaveButtonDisabled() {
    return !this.formGroup.valid;
  }

  cancel() {
    this.cancelClicked.emit();
  }

  handleRelayTOUToggle(event: string | boolean) {
    this.touFormGroup.get('include-relay-terms-of-use').setValue(event);
    this.touFormGroup.markAsDirty();
    this.formGroup.markAsDirty();
  }

  handleRelayPrivacyToggle(event: string | boolean) {
    this.privacyFormGroup.get('include-relay-privacy').setValue(event);
    this.privacyFormGroup.markAsDirty();
    this.formGroup.markAsDirty();
  }

  showInvalidUrlSupportSite() {
    return this.clientSupportControl.errors?.['noProtocol'];
  }

  showInvalidUrlPrivacy() {
    return (
      (this.privacyFormGroup.controls['client-privacy-policy-url'].errors?.[
        'urlOrInput'
      ] &&
        !this.privacyFormGroup.errors?.['toggledButNoValue']) ||
      this.privacyFormGroup.controls['client-privacy-policy-url'].errors?.[
        'noProtocol'
      ]
    );
  }

  showInvalidUrlTOU() {
    return (
      (this.touFormGroup.controls['client-terms-of-use-url'].errors?.[
        'urlOrInput'
      ] &&
        !this.touFormGroup.errors?.['toggledButNoValue']) ||
      this.touFormGroup.controls['client-terms-of-use-url'].errors?.[
        'noProtocol'
      ]
    );
  }

  showNeedAtLeastOneURL(formGroup: UntypedFormGroup) {
    return formGroup.errors?.['noTrueValueControls'];
  }

  showToggledButNoValue(formGroup: UntypedFormGroup) {
    return formGroup.errors?.['toggledButNoValue'];
  }
}
