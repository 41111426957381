import React, { useState, ReactNode } from 'react';

export type CustomerInfo = {
  firstName?: string;
  lastName?: string;
  customerId?: string;
  productGroupId?: string;
  channelSid?: string;
  tagId?: string;
  dateUpdated?: string;
  dateCreated?: string;
  tagName?: string;
  closedReason?: string;
  closedReasonAdditionalDetail?: string;
};

export type CustomerInfoContextType = {
  customerInfo: CustomerInfo;
  setCustomerInfo: (customerInfo: CustomerInfo) => void;
};

export type CustomerInfoContextProviderProps = {
  children: ReactNode;
} & CustomerInfo;

export const CustomerInfoContext = React.createContext<
  CustomerInfoContextType | undefined
>(undefined);

export function CustomerInfoProvider({
  channelSid,
  children,
}: CustomerInfoContextProviderProps) {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    channelSid: channelSid ?? null,
  });
  return (
    <CustomerInfoContext.Provider value={{ customerInfo, setCustomerInfo }}>
      {children}
    </CustomerInfoContext.Provider>
  );
}

export const useCustomerInfo = () => {
  const context = React.useContext(CustomerInfoContext);

  if (context === undefined) {
    throw new Error(
      `useCustomerInfo must be used within a CustomerInfoProvider`,
    );
  }

  return context;
};
