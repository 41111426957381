import React from 'react';
import { useStyletron } from 'baseui';
// import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { Button, SHAPE, SIZE } from 'baseui/button';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { ParagraphSmall } from 'baseui/typography';
import { Check, Filter } from 'baseui/icon';
import {
  AgentNameAscendingDescendingMethods,
  SortAgentNameAction,
} from './SortAgentName';
import { FilterGroupNameAction } from './FilterGroupName';
import { ControlGroup } from '../relay-messenger-axios-service/relay-messenger-axios-service';

export enum AgentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export type AgentFilterSortingPopoverProps = {
  isAgentFilterSortingPopoverOpen: boolean;
  setIsAgentFilterSortingPopoverOpen: (value: boolean) => void;
  filterByOption: AgentStatus.Active | AgentStatus.Inactive;
  setFilterByOption: (value: AgentStatus.Active | AgentStatus.Inactive) => void;
  sortingName:
    | AgentNameAscendingDescendingMethods.Ascending
    | AgentNameAscendingDescendingMethods.Descending
    | AgentNameAscendingDescendingMethods.Name;
  setSortingName: (
    value:
      | AgentNameAscendingDescendingMethods.Ascending
      | AgentNameAscendingDescendingMethods.Descending,
  ) => void;
  clearFilterState: () => void;
  clearSortingSelection: () => void;
  groups: ControlGroup[];
};

export const AgentFilterSortingPopover = ({
  isAgentFilterSortingPopoverOpen,
  setIsAgentFilterSortingPopoverOpen,
  filterByOption,
  setFilterByOption,
  sortingName,
  setSortingName,
  clearFilterState,
  clearSortingSelection,
  groups,
}: AgentFilterSortingPopoverProps) => {
  const [css] = useStyletron();
  const agentStatuses = Object.values(AgentStatus);
  // const customTheme = theme as CustomTheme;

  return (
    <StatefulPopover
      content={({ close }) => (
        <Block padding={'10px'}>
          <ParagraphSmall
            className={css({
              color: 'rgba(55, 71, 79, 0.25) !important',
              fontFamily: 'Source Sans Pro !important',
              fontSize: '10px !important',
              fontStyle: 'normal !important',
              fontWeight: '600 !important',
              lineHeight: 'normal !important',
            })}
          >
            FILTER BY
          </ParagraphSmall>
          {agentStatuses.map((status) => (
            <Button
              key={status}
              onClick={() => {
                setFilterByOption(status);
                close();
              }}
              overrides={{
                BaseButton: {
                  style: {
                    width: '100%',
                    display: 'flex',
                    color:
                      filterByOption && filterByOption === status
                        ? '#3693BF'
                        : 'black',
                    backgroundColor:
                      filterByOption &&
                      filterByOption === status
                        ? '#E1EFF5'
                        : 'transparent',
                    fontWeight: filterByOption &&
                    filterByOption === status ? 'bolder' : 'inherit',
                    textAlign: 'left',
                    fontSize: '12px',
                    outline: 'none !important',
                    justifyContent: 'left',
                    ':hover': {
                      backgroundColor: 'none',
                    },
                    ':active': {
                      backgroundColor: 'none',
                    },
                  },
                  props: {
                    'data-testid': 'filter-unread-btn',
                  },
                },
              }}
              startEnhancer={() =>
                filterByOption && filterByOption === status ? (
                  <Check size={'30px'} color="#3693BF" />
                ) : (
                  ''
                )
              }
            >
              {status}
            </Button>
          ))}
          <FilterGroupNameAction
          groups={groups}
          filterByOption={filterByOption}
          setFilterByOption={setFilterByOption}
          close={close}
          clearFilterState={clearFilterState}
          clearSortingSelection={clearSortingSelection}
          ></FilterGroupNameAction>
          <hr className={css({ marginTop: '5px', marginBottom: '5px' })} />
          <ParagraphSmall
            className={css({
              color: 'rgba(55, 71, 79, 0.25) !important',
              fontFamily: 'Source Sans Pro !important',
              fontSize: '10px !important',
              fontStyle: 'normal !important',
              fontWeight: '600 !important',
              lineHeight: 'normal !important',
            })}
          >
            SORT BY
          </ParagraphSmall>

          <SortAgentNameAction
            sortingName={sortingName}
            setSortingName={setSortingName}
            close={close}
            clearFilterState={clearFilterState}
            clearSortingSelection={clearSortingSelection}
          />
        </Block>
      )}
      overrides={{
        Inner: {
          style: {
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          props: { 'data-testid': 'sorting-filtering-popover' },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
          },
        },
      }}
      placement={PLACEMENT.bottomRight}
      onClose={() => setIsAgentFilterSortingPopoverOpen(false)}
      onOpen={() => {
        setIsAgentFilterSortingPopoverOpen(true);
      }}
    >
      <Button
        size={SIZE.compact}
        shape={SHAPE.circle}
        onClick={() =>
          setIsAgentFilterSortingPopoverOpen(!isAgentFilterSortingPopoverOpen)
        }
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isAgentFilterSortingPopoverOpen
                ? '#3693BF'
                : '#FFFFFF',
              color: isAgentFilterSortingPopoverOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'sort-filtering-actions-btn' },
          },
        }}
      >
        <Filter size={28} />
      </Button>
    </StatefulPopover>
  );
};
