import { ClientInfoProvider } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { theme } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { PushAPIHome } from '@app/api-mgmt/components/push-api/components/push-api-home/PushAPIHome';

export type PushAPIReactContainerProps = {
  clientId: string;
};

// generate classNames with a prefix of 'push-api-ui'
const engine = new Styletron({ prefix: 'push-api-ui-' });

/**
 * A middleware container that is rendered by the Angular push-api component.
 * Sets up the providers (much like one would do in the top-level App component
 * of a full-React app).
 */
export const PushAPIReactContainer = ({
  clientId
}: PushAPIReactContainerProps) => {
  return (
    <ClientInfoProvider
      clientId={clientId}
    >
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme} zIndex={202}>
          <PushAPIHome />
        </BaseProvider>
      </StyletronProvider>
    </ClientInfoProvider>
  );
};
