import { RelaySharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MediaListComponent } from './media-list/media-list.component';
import { MediaLibraryContainer } from './media-library-container/media-library-container.component';
import { AddMediaAssetComponent } from './add-media-asset/add-media-asset.component';
import { EditMediaAssetDetailsComponent } from './edit-media-asset-details/edit-media-asset-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrandingList } from './branding-list/branding-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    InfiniteScrollModule,
    ButtonsModule.forRoot(),
  ],
  declarations: [ MediaListComponent, AddMediaAssetComponent, EditMediaAssetDetailsComponent, MediaLibraryContainer, BrandingList],
  exports: [ MediaListComponent, AddMediaAssetComponent, EditMediaAssetDetailsComponent, BrandingList ] 
})
export class MediaLibraryModule { }
