import React from 'react';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SessionService } from '@app/security/session.service';
import ReactDOM from 'react-dom';
import { TitleService } from '@app/core/services/title.service';
import { SetupConversationsContainer } from './components/setup-conversations-container/SetupConversationsContainer';
import { Client } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';

@Component({
  selector: 'app-setup-conversations',
  templateUrl: './setup-conversations.component.html',
  styleUrls: ['./setup-conversations.component.scss'],
})
export class SetupConversationsComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  currentClient: Client;
  clientId: string = '';
  userId: string = '';
  reactWrapperId: string;
  twoWayConfig: TwoWayConfig;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService, // private clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.twoWayConfig = this.currentClient.two_way_config;
    this.titleService.activate('Setup Conversations');
    this.reactWrapperId = 'setup-conversations-wrapper-id';
  }

  protected getRootDomNode() {
    return document.getElementById(this.reactWrapperId);
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
    this.titleService.deactivate();
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(SetupConversationsContainer, {
          clientId: this.clientId,
          twoWayConfig: this.twoWayConfig,
        }),
        this.getRootDomNode(),
      );
    }
  }
}
