<div *ngIf="loaded && message">
  <div class="phone-container">
    <phone
      *ngIf="message.wire.is_shown === 'true'"
      [screen]="screen"
      [message]="message"
      [hasIWCU]="hasIWCU"
      [productGroup]="productGroup"
      [components]="journey.draft.components"
    ></phone>
    <div
      class="bubble"
      *ngIf="
        message.type === 'MessageSender' && message.sms.is_shown === 'true'
      "
    >
      {{ getSmsBody() }}
    </div>
  </div>
  <div class="messaging-panel arrow-left-center">
    <div class="card panel-default">
      <div class="card-header">
        <div
          class="col-sm-12"
          [ngClass]="{
            'has-error':
              !messageGroup.controls['message_name'].valid &&
              messageGroup.controls['message_name'].dirty
          }"
        >
          <span
            class="error-msg"
            *ngIf="
              !messageGroup.controls['message_name'].valid &&
              messageGroup.controls['message_name'].dirty
            "
            tooltip="{{
              getTooltipData(messageGroup.controls['message_name'].errors)
            }}"
            placement="bottom"
          >
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </span>
          <span class="fa fa-pencil"></span>
          <input
            type="text"
            trim="blur"
            [formControl]="messageGroup.controls['message_name']"
            id="message-nickname-input"
            style="color: inherit"
          />
        </div>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <div>
              <label>Experience</label>
              <div class="btn-group pull-right">
                <div
                  class="btn btn-sm btn-default"
                  [(ngModel)]="message.wire.is_shown"
                  (ngModelChange)="toggleWire()"
                  name="wire_is_shown_on"
                  ngDefaultControl
                  btnRadio="true"
                >
                  On
                </div>
                <div
                  class="btn btn-sm btn-default"
                  [(ngModel)]="message.wire.is_shown"
                  (ngModelChange)="toggleWire()"
                  name="wire_is_shown_off"
                  ngDefaultControl
                  btnRadio="false"
                >
                  Off
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div [class.hidden]="message.wire.is_shown === 'false'">
              <div>
                <label>Client Message Tag</label>
                <input
                  name="client_message-id"
                  class="form-control client_message_tag"
                  type="text"
                  [(ngModel)]="message.client_message_tag"
                />
              </div>
              <ng-container *ngIf="showOutcomeExpTypeDropdowns">
                <div [ngClass]="outcomeFormStatus">
                  <div class="select-required">
                    <label for="outcome-select">Outcome</label>
                    <i
                      *ngIf="isOutcomeCtlError"
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                      tooltip="This field is required"
                    ></i>
                  </div>
                  <select
                    id="outcome-select"
                    class="form-control"
                    name="outcome-select"
                    [formControl]="outcomeCtl"
                  >
                    <option
                      [value]="''"
                      [selected]="showOutcomeTypeDefaultOption(outcomeCtl)"
                    >
                      Select an Outcome
                    </option>
                    <option
                      *ngFor="let outcome of outcomes"
                      [value]="outcome.id"
                      [selected]="outcomeCtl?.value === outcome.id"
                    >
                      {{ outcome.title['en-us'] }}
                    </option>
                  </select>
                </div>

                <div [ngClass]="experienceTypeFormStatus">
                  <div class="select-required">
                    <label for="experience-type-select">Experience Type</label>
                    <i
                      *ngIf="isExperienceTypeCtlError"
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                      tooltip="This field is required"
                    ></i>
                  </div>
                  <select
                    id="experience-type-select"
                    class="form-control"
                    name="experience-type-select"
                    [formControl]="experienceTypeCtl"
                  >
                    <option
                      [value]="''"
                      [selected]="
                        showExperienceTypeDefaultOption(
                          outcomeCtl,
                          experienceTypeCtl
                        )
                      "
                    >
                      Select an Experience Type
                    </option>
                    <option
                      *ngFor="let experienceType of experienceTypes"
                      [value]="experienceType.id"
                      [selected]="
                        experienceTypeCtl?.value === experienceType.id
                      "
                    >
                      {{ experienceType.title['en-us'] }}
                    </option>
                  </select>
                </div>
              </ng-container>
              <div *ngIf="!newFeed.enabled">
                <label i18n="Message brand label">Branding</label>
                <div>
                  <select
                    class="form-control wire_branding"
                    placeholder="brand name"
                    name="wire_brand"
                    [(ngModel)]="message.wire.branding"
                    (ngModelChange)="onBrandSelected($event)"
                  >
                    <option
                      [ngValue]="null"
                      i18n="Default option for message branding"
                    >
                      default
                    </option>
                    <option
                      *ngFor="let brand of brands"
                      [ngValue]="brand.asset_id"
                    >
                      {{ brand.shortname }}
                    </option>
                    <option
                      value="create"
                      i18n="Option to create a brand on the message builder"
                    >
                      Create Brand
                    </option>
                  </select>
                </div>
              </div>
              <ng-container
                *ngIf="
                  newFeed.enabled &&
                  !message.parent
                "
              >
                <br />
                <!-- Feed Message Level Branding -->
                <app-radio-btn
                  (btnModelChange)="toggleMessageLevelBranding($event)"
                  [(btnModel)]="messageLevelBranding"
                  [btnLabel]="'Message Level Branding'"
                  [btnName]="'has-message-level-branding'"
                  [btnWidth]="'100%'"
                  [btnDisplayTrueFirst]="true"
                ></app-radio-btn>
                <div
                  *ngIf="messageLevelBranding"
                >
                  <select
                    class="form-control wire_branding"
                    placeholder="brand name"
                    name="feed_brand"
                    [(ngModel)]="message.message_level_branding_text"
                    (ngModelChange)="onBrandSelected($event)"
                  >
                    <option
                      [ngValue]="this.productGroup.id"
                      i18n="Default option for message branding"
                    >
                      default (from Product Group)
                    </option>
                    <option
                      *ngFor="let brand of brands"
                      [ngValue]="brand.asset_id"
                      [selected]="
                        feedMessageLevelBrandingCtl?.value?.brand_name ===
                        brand.shortname
                      "
                    >
                      {{ brand.shortname }}
                    </option>
                    <option
                      value="create"
                      i18n="Option to create a brand on the message builder"
                    >
                      Create Brand
                    </option>
                  </select>
                </div>
                <!-- FDIC Enabled & only for MessageSender aka no parent property -->
                <div *ngIf="fdicEnabled && !message.parent">
                  <label i18n="Message Level FIDC Signage" for="fdic-select">Message Level FDIC Signage</label>
                  <select
                    class="form-control"
                    id="fdic-select"
                    name="fdic"
                    [(ngModel)]="message.fdic"
                  >
                    <option
                      [ngValue]="null"
                      i18n="Default option to not display FDIC signage"
                    >
                      Do not display
                    </option>
                    <option
                      value="fdic-insured"
                      id="fdic-insured-option"
                      i18n="Option to display FDIC-insured signage"
                      [disabled]="!messageLevelBranding"
                    >
                      Display FDIC-insured signage
                      {{
                        !messageLevelBranding
                          ? ' (enabled Message Level Branding)'
                          : ''
                      }}
                    </option>
                    <option
                      value="non-deposit"
                      i18n="Option to display non deposit signage"
                    >
                      Display non-deposit signage
                    </option>
                    ks
                  </select>
                </div>
              </ng-container>
              <div *ngIf="!newFeed.enabled">
                <app-radio-btn
                  [btnLabel]="'Display Banner'"
                  [btnName]="'show-banner'"
                  [(btnModel)]="message.wire.show_banner"
                  [btnWidth]="'100%'"
                  [btnDisplayTrueFirst]="true"
                ></app-radio-btn>
              </div>
              <br />
              <hr />
              <div *ngIf="!message.wire.media_type">
                <div class="d-flex justify-content-center">
                  <div class="btn btn-primary" (click)="addMediaAsset()">
                    <span class="icon icon-left"
                      ><i class="fa fa-plus-circle"></i
                    ></span>
                    <span i18n="Link for adding an image or video to a message"
                      >Add Image or Video</span
                    >
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  message.wire.media_type === 'image' ||
                  message.wire.media_type === 'brand' ||
                  message.wire.media_type === 'video'
                "
                class="message-media"
              >
                <div>
                  <label i18n="Message brand label">Image or Video</label
                  ><i
                    id="remove-media-icon"
                    class="fa fa-minus-circle pull-right delete-action"
                    (click)="removeMediaAsset()"
                  ></i>
                </div>
                <div class="media-info-section">
                  <div class="media-info media-preview">
                    <img
                      *ngIf="
                        message.wire.media_type === 'image' ||
                        message.wire.media_type === 'brand'
                      "
                      src="{{ message.wire.image_url }}"
                    />
                    <div
                      *ngIf="
                        message.wire.media_type === 'video' &&
                        message.wire['video_url']
                      "
                      class="video"
                    >
                      <app-video
                        [videoUrl]="message.wire['video_url']"
                      ></app-video>
                    </div>
                  </div>
                  <div class="media-info media-url">
                    <div
                      *ngIf="
                        message.wire.media_type === 'image' ||
                        message.wire.media_type === 'brand'
                      "
                    >
                      <label i18n="Image URL label">Image URL</label>
                      <div id="wire-image-url">
                        {{ message.wire.image_url }}
                      </div>
                    </div>
                    <div *ngIf="message.wire.media_type === 'video'">
                      <label i18n="Video URL label">Video URL</label>
                      <div id="wire-video-url">
                        {{ message.wire.video_url }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <!-- Message Content -->
              <label>Message Content</label>
              <rn-form-input-state-control
                [formReference]="messageGroup.controls['message_text']"
                [showCharacterCount]="true"
                [totalLength]="wireOrFeedMessageTextLimit"
              >
                <ng-container *ngIf="message">
                  <!-- TODO: use reactive or template forms - not both. See: https://angular.io/api/forms/FormControlDirective#use-with-ngmodel -->
                  <app-tiny-editor
                    class="tiny-editor-mentions"
                    [elementId]="'tiny-editor-message-wire-text'"
                    [formControl]="messageGroup.controls['message_text']"
                    [config]="tinyEditorConfig"
                    [isRawEditorEnabled]="this.showRawEditor"
                  >
                  </app-tiny-editor>
                </ng-container>
              </rn-form-input-state-control>
              <div>use &#64; to get the dynamic fields.</div>

              <!-- Alternate Message Text -->
              <app-radio-btn
                (btnModelChange)="toggleAlternateMessageText($event)"
                [(btnModel)]="message.wire.is_alternate_message"
                [btnLabel]="'Alternate Message Text'"
                [btnName]="'has-alternate-message'"
                [btnWidth]="'100%'"
                [btnDisplayTrueFirst]="true"
              ></app-radio-btn>

              <div>
                This message will be sent to your users in the event there is an
                issue with the data for the personalized fields
              </div>

              <div class="clearfix"></div>
              <div style="position: relative; padding: 5px 0">
                <rn-form-input-state-control
                  [formReference]="
                    messageGroup.controls['alternate_message_text']
                  "
                  [showCharacterCount]="isAlternateMessageTextEnabled"
                  [totalLength]="wireOrFeedMessageTextLimit"
                >
                  <ng-container *ngIf="message.wire.is_alternate_message">
                    <app-tiny-editor
                      class="tiny-editor-mentions"
                      [elementId]="'tiny-editor-alternate-message-text'"
                      [formControl]="
                        messageGroup.controls['alternate_message_text']
                      "
                      [isRawEditorEnabled]="this.showRawEditor"
                    >
                    </app-tiny-editor>
                  </ng-container>
                </rn-form-input-state-control>
              </div>

              <hr />
              <div [formGroup]="actionFormArray">
                <div
                  class="actions"
                  id="msg-action-0"
                  *ngIf="newFeed.enabled && primaryAction"
                >
                  <message-builder-action
                    [actionInput]="primaryAction"
                    [i]="0"
                    [formReference]="actionFormArray.controls[0]"
                    [actionFormArray]="actionFormArray"
                    [nextMoveUpIndex]="nextMoveUpActionIndex(primaryAction, 0)"
                    [nextMoveDownIndex]="
                      nextMoveDownActionIndex(primaryAction, 0)
                    "
                    [numberOfActions]="message.wire.actions.length"
                    [journey]="journey"
                    [productGroup]="productGroup"
                    [dynamicInputs]="dynamicInputs"
                    (delete)="deleteAction(primaryAction, 0)"
                    (moveUp)="(null)"
                    (moveDown)="(null)"
                  >
                  </message-builder-action>

                  <div>use &#64; to get the dynamic fields.</div>

                  <hr />
                </div>
                <div
                  class="add-actions"
                  id="primary-action-elements-list"
                  *ngIf="newFeed.enabled && isAbleToAddPrimaryAction"
                >
                  <label
                    ><i class="fa fa-plus-circle" aria-hidden="true"></i>
                    Primary Action Element</label
                  >
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addPrimaryAction('hyperlink')"
                    id="add-primary-link-element-btn"
                    matTooltip="Link"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addPrimaryAction('call')"
                    id="add-primary-call-element-btn"
                    matTooltip="Click to Call"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addPrimaryAction('email')"
                    id="add-primary-email-element-btn"
                    matTooltip="Click to Email"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <img src="/assets/icons/icon-EmailAction.png" />
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addPrimaryAction('message')"
                    id="add-primary-message-element-btn"
                    matTooltip="Message Branch"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-code-fork turn90" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addPrimaryAction('two_way')"
                    *ngIf="twoWayService.showTwoWay()"
                    id="add-primary-two-way-element-btn"
                    [matTooltip]="tooltipMessage"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addPrimaryAction('form_info_capture')"
                    *ngIf="
                      featureService.checkFlag(
                        featureFlags.jotform_info_capture
                      )
                    "
                    id="add-primary-power-up-element-btn"
                    matTooltip="Power-Ups"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-wpforms"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    *ngIf="hasIWCU"
                    [class.disabled]="!userCanAddIWCUAction()"
                    (click)="addPrimaryAction('consent_upgrade')"
                    id="add-primary-consent-upgrade-element-btn"
                    [matTooltip]="
                      newFeed.enabled
                        ? 'In Feed Consent Upgrade'
                        : 'In Wire Consent Upgrade'
                    "
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-angle-double-up" aria-hidden="true"></i>
                  </div>
                  <hr />
                </div>
                <ng-container *ngIf="!newFeed.enabled">
                  <div
                    class="actions"
                    id="msg-action-{{ i }}"
                    *ngFor="
                      let action of message.wire.actions;
                      let i = index;
                      trackBy: actionById
                    "
                  >
                    <ng-container *ngIf="action.type !== 'disclaimer'">
                      <message-builder-action
                        [actionInput]="action"
                        [formReference]="actionFormArray.controls[i]"
                        [i]="i"
                        [nextMoveUpIndex]="nextMoveUpActionIndex(action, i)"
                        [nextMoveDownIndex]="nextMoveDownActionIndex(action, i)"
                        [actionFormArray]="actionFormArray"
                        [numberOfActions]="message.wire.actions.length"
                        [journey]="journey"
                        [productGroup]="productGroup"
                        [dynamicInputs]="dynamicInputs"
                        (delete)="deleteAction(action, i)"
                        (moveUp)="moveActionUp(action, i)"
                        (moveDown)="moveActionDown(action, i)"
                      >
                      </message-builder-action>

                      <div>use &#64; to get the dynamic fields.</div>

                      <hr />
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="newFeed.enabled">
                  <ng-container
                    *ngFor="
                      let action of message.wire.actions;
                      let i = index;
                      trackBy: actionById
                    "
                  >
                    <div
                      class="actions"
                      id="msg-action-{{ i }}"
                      *ngIf="
                        !action.primary &&
                        isActionTypeSupportedInNewFeed(action.type) &&
                        action.type !== 'disclaimer'
                      "
                    >
                      <message-builder-action
                        [actionInput]="action"
                        [formReference]="actionFormArray.controls[i]"
                        [i]="i"
                        [nextMoveUpIndex]="nextMoveUpActionIndex(action, i)"
                        [nextMoveDownIndex]="nextMoveDownActionIndex(action, i)"
                        [actionFormArray]="actionFormArray"
                        [numberOfActions]="message.wire.actions.length"
                        [journey]="journey"
                        [productGroup]="productGroup"
                        [dynamicInputs]="dynamicInputs"
                        (delete)="deleteAction(action, i)"
                        (moveUp)="moveActionUp(action, i)"
                        (moveDown)="moveActionDown(action, i)"
                      >
                      </message-builder-action>

                      <div>use &#64; to get the dynamic fields.</div>

                      <hr />
                    </div>
                  </ng-container>
                </ng-container>

                <div class="add-actions" id="non-primary-action-elements-list">
                  <label
                    ><i class="fa fa-plus-circle" aria-hidden="true"></i>
                    {{
                      newFeed.enabled
                        ? 'Secondary Action Elements'
                        : 'Add message action'
                    }}</label
                  >
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addLinkElement()"
                    id="add-link-element-btn"
                    matTooltip="Link"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addCallElement()"
                    id="add-call-element-btn"
                    matTooltip="Click to Call"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addEmailElement()"
                    id="add-email-element-btn"
                    matTooltip="Click to Email"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <img src="/assets/icons/icon-EmailAction.png" />
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addMessageElement()"
                    id="add-message-element-btn"
                    matTooltip="Message Branch"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-code-fork turn90" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    (click)="addCollapsibleElement()"
                    id="add-collapsible-element-btn"
                    matTooltip="Collapsible Section"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa re-collapsible" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addTwoWayElement()"
                    *ngIf="twoWayService.showTwoWay()"
                    id="add-two-way-element-btn"
                    [matTooltip]="tooltipMessage"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addPlaidElement()"
                    *ngIf="
                      featureService.checkFlag(
                        featureFlags.plaid_integration
                      ) && !newFeed.enabled
                    "
                    id="add-plaid-element-btn"
                    matTooltip="Plaid"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-money" aria-hidden="true"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    *ngIf="
                      featureService.checkFlag(
                        featureFlags.email_data_capture
                      ) && !newFeed.enabled
                    "
                    (click)="addEmailDataCaptureElement()"
                    id="add-email-data-capture-element-btn"
                    matTooltip="Email Capture"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa-solid fa-at"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addAppleChatElement()"
                    *ngIf="
                      featureService.checkFlag(featureFlags.apple_chat) &&
                      !newFeed.enabled
                    "
                    id="add-apple-chat-element-btn"
                    matTooltip="Apple Chat"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-apple"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary response-btn"
                    (click)="addFormInfoCaptureElement()"
                    *ngIf="
                      featureService.checkFlag(
                        featureFlags.jotform_info_capture
                      )
                    "
                    id="add-power-up-element-btn"
                    matTooltip="Power-Ups"
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-wpforms"></i>
                  </div>
                  <div
                    class="btn btn-lg btn-primary"
                    *ngIf="hasIWCU"
                    [class.disabled]="!userCanAddIWCUAction()"
                    (click)="addIWCUElement()"
                    id="add-consent-upgrade-element-btn"
                    [matTooltip]="
                      newFeed.enabled
                        ? 'In Feed Consent Upgrade'
                        : 'In Wire Consent Upgrade'
                    "
                    matTooltipPosition="above"
                    matTooltipClass="actions-tooltip-style"
                  >
                    <i class="fa fa-angle-double-up" aria-hidden="true"></i>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <ng-container *ngIf="isMsgLevelDisclaimerEnabled">
            <br />
            <div class="form-group child-group" [formGroup]="actionFormArray">
              <label>Message Level Dislaimer</label>
              <div
                class="btn-group pull-right"
                btnRadioGroup
                [ngModel]="isDisclaimerOn"
                [ngModelOptions]="{ standalone: true }"
              >
                <div
                  id="toggle-disclaimer-on"
                  class="btn btn-sm btn-default"
                  name="disclaimer-on"
                  btnRadio="on"
                  ngDefaultControl
                  (click)="turnOnDisclaimer()"
                >
                  On
                </div>
                <div
                  id="toggle-disclaimer-off"
                  class="btn btn-sm btn-default"
                  name="disclaimer-off"
                  btnRadio="off"
                  (click)="turnOffDisclaimer()"
                >
                  Off
                </div>
              </div>
              <div
                *ngIf="disclaimerAction && isDisclaimerOn === 'on'"
                id="message-disclaimer"
              >
                <p>
                  Changes made here will not apply to global disclaimer.
                  <strong
                    >Character limit:
                    {{
                      CharacterLimits.MessageLevelDisclaimerCharLimit
                    }}</strong
                  >
                </p>
                <message-builder-action
                  [actionInput]="disclaimerAction"
                  [formReference]="
                    actionFormArray.controls[message.wire.actions.length - 1]
                  "
                  [i]="message.wire.actions.length - 1"
                  [nextMoveUpIndex]="-1"
                  [nextMoveDownIndex]="message.wire.actions.length"
                  [actionFormArray]="actionFormArray"
                  [numberOfActions]="message.wire.actions.length"
                  [journey]="journey"
                  [productGroup]="productGroup"
                  [dynamicInputs]="[]"
                  (delete)="(null)"
                  (moveUp)="(null)"
                  (moveDown)="(null)"
                >
                </message-builder-action>
                <div class="disclaimer-helper">
                  <p>
                    <span
                      >{{
                        CharacterLimits.MessageLevelDisclaimerCharLimit -
                          disclaimerAction.value.length
                      }}
                    </span>
                    remaining characters
                  </p>
                  <p class="disclaimer-default-group-message">
                    Using disclaimer for
                    <strong>{{ productGroup.name }}</strong> by default.
                  </p>
                </div>
              </div>

              <div class="clearfix"></div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              newFeed.enabled && !message.parent
            "
          >
            <br />
            <div class="form-group">
              <div class="message_expiration">
                <label>Message Expiration</label>
                <div class="message_expiration_toggles pull-right">
                  <div>
                    <div class="btn-group">
                      <div
                        name="type"
                        [formControl]="feedMessageExpirationTypeCtl"
                        class="btn btn-sm btn-default"
                        [(ngModel)]="feedMessageExpirationTypeCtl.value"
                        ngDefaultControl
                        btnRadio="custom"
                        id="message-expiration-on"
                        (click)="setMessageExpiration('custom')"
                      >
                        On
                      </div>
                      <div
                        name="type"
                        [formControl]="feedMessageExpirationTypeCtl"
                        class="btn btn-sm btn-default"
                        [(ngModel)]="feedMessageExpirationTypeCtl.value"
                        ngDefaultControl
                        btnRadio="none"
                        id="message-expiration-off"
                        (click)="setMessageExpiration('none')"
                      >
                        Off
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <div
                    *ngIf="isMessageExpirationType('custom')"
                    class="message_expiration_enabled"
                  >
                    <p>
                      Set the amount of time until this message is permanently
                      removed from a customer’s Feed.
                    </p>
                    <div class="d-flex input_container">
                      <input
                        name="amount"
                        id="number-days-weeks"
                        class="form-control input_number"
                        [formControl]="feedMessageExpirationAmountCtl"
                        placeholder="##"
                        type="number"
                        pattern="[0-9]*"
                        min="1"
                        max="99"
                        maxlength="2"
                        [ngClass]="{
                          invalid_input:
                            feedMessageExpirationAmountCtl.invalid &&
                            (feedMessageExpirationAmountCtl.dirty ||
                              feedMessageExpirationAmountCtl.touched)
                        }"
                      />
                      <i
                        id="invalid-icon"
                        *ngIf="
                          feedMessageExpirationAmountCtl.invalid &&
                          (feedMessageExpirationAmountCtl.dirty ||
                            feedMessageExpirationAmountCtl.touched)
                        "
                        class="fa fa-exclamation-circle invalid-icon"
                        [matTooltip]="
                          feedMessageExpirationAmountCtl.errors?.max
                            ? 'Entry cannot exceed 2 digits.'
                            : 'Value is required.'
                        "
                        matTooltipPosition="above"
                        matTooltipClass="tooltip"
                      >
                      </i>
                      <select
                        id="days-weeks-dropdown"
                        class="form-control days_weeks_select"
                        [formControl]="feedMessageExpirationUnitCtl"
                      >
                        <option
                          *ngFor="let value of daysWeeks"
                          [value]="value"
                          name="unit"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <div>
                        <i
                          class="fa fa-question-circle question_mark_icon"
                          aria-hidden="true"
                          matTooltip="Does not include blackout periods."
                          matTooltipPosition="above"
                          matTooltipClass="tooltip"
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="isMessageExpirationType('none')"
                    class="message_expiration_disabled"
                  >
                    <p class="message_expiration_desc">
                      Set the amount of time until this message is hidden from a
                      Feed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="message.type === 'MessageSender'">
            <br />
            <div class="form-group child-group">
              <label>{{
                newFeed.enabled ? 'Notification' : 'Text Message'
              }}</label>
              <div class="btn-group pull-right">
                <div
                  class="btn btn-sm btn-default"
                  [(ngModel)]="message.sms.is_shown"
                  (ngModelChange)="toggleSMS()"
                  name="sms_is_shown_on"
                  ngDefaultControl
                  btnRadio="true"
                >
                  On
                </div>
                <div
                  class="btn btn-sm btn-default"
                  [(ngModel)]="message.sms.is_shown"
                  (ngModelChange)="toggleSMS()"
                  name="sms_is_shown_off"
                  ngDefaultControl
                  btnRadio="false"
                >
                  Off
                </div>
              </div>
              <div class="clearfix"></div>
              <rn-sms-builder
                *ngIf="
                  message.sms &&
                  message.sms.is_shown === 'true' &&
                  currentClient
                "
                [smsModel]="message.sms"
                [smsFormGroup]="messageGroup"
                [maxCharacterCount]="currentClient.sms_max_length"
                [wireMessageIsShown]="message.wire.is_shown === 'true'"
                [customInputs]="currentClient.ext_dynamic_fields"
              ></rn-sms-builder>
            </div>

            <br />

            <div class="form-group">
              <div class="blackout">
                <label>Custom Blackout Window</label>
                <div class="blackout_toggles pull-right">
                  <div>
                    <div class="btn-group">
                      <div
                        class="btn btn-sm btn-default"
                        [(ngModel)]="
                          message.sms.blackout_settings.blackout_type
                        "
                        name="sms_blackout_default"
                        ngDefaultControl
                        btnRadio="default"
                        id="default-blackout"
                        (click)="setBlackout('default')"
                      >
                        default
                      </div>
                      <div
                        class="btn btn-sm btn-default"
                        [(ngModel)]="
                          message.sms.blackout_settings.blackout_type
                        "
                        name="sms_blackout_default"
                        ngDefaultControl
                        btnRadio="none"
                        (click)="setBlackout('none')"
                        id="no-blackout"
                      >
                        No Blackout
                      </div>
                      <div
                        class="btn btn-sm btn-default"
                        [(ngModel)]="
                          message.sms.blackout_settings.blackout_type
                        "
                        name="sms_blackout_default"
                        ngDefaultControl
                        btnRadio="custom"
                        (click)="setBlackout('custom')"
                        id="custom-blackout"
                      >
                        Set custom
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  [class]="
                    'blackout_custom ' +
                    message.sms.blackout_settings.blackout_type
                  "
                >
                  <div
                    *ngIf="isBlackoutType('custom') && blackoutWindowExists()"
                  >
                    <div class="input-container">
                      <div class="input">
                        <label i18n="Blackout window start label"
                          >Blackout start</label
                        >
                        <select
                          i18n="Blackout window start select"
                          [(ngModel)]="
                            message.sms.blackout_settings.blackout_window
                              .start_time
                          "
                          (ngModelChange)="onCustomBlackoutStartChange()"
                          name="blackout_start"
                          class="form-control"
                          id="custom-blackout-start"
                        >
                          <ng-container *ngFor="let hour of hours">
                            <option [attr.value]="hour.isoHour">
                              {{ hour.displayHour }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    <div class="input-container">
                      <div class="input">
                        <label i18n="Blackout window end label"
                          >Blackout end</label
                        >
                        <select
                          i18n="Blackout window end select"
                          [(ngModel)]="
                            message.sms.blackout_settings.blackout_window
                              .end_time
                          "
                          (ngModelChange)="onCustomBlackoutEndChange()"
                          name="blackout_end"
                          class="form-control"
                          id="custom-blackout-end"
                        >
                          <ng-container *ngFor="let hour of hours">
                            <option [attr.value]="hour.isoHour">
                              {{ hour.displayHour }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="weekendSwitchesAreSet() && blackoutWindowExists()"
                    >
                      <div
                        class="input-container input-container--contains-switch"
                      >
                        <div class="input clearfix">
                          <app-radio-btn
                            [btnLabel]="'Blackout on Saturday?'"
                            [btnWidth]="'300px'"
                            [btnName]="'blackout-on-saturday'"
                            [(btnModel)]="
                              message.sms.blackout_settings.blackout_window
                                .include_saturday
                            "
                          ></app-radio-btn>
                        </div>
                      </div>
                      <div
                        class="input-container input-container--contains-switch"
                      >
                        <div class="input clearfix">
                          <app-radio-btn
                            [btnLabel]="'Blackout on Sunday?'"
                            [btnWidth]="'300px'"
                            [btnName]="'blackout-on-sunday'"
                            [(btnModel)]="
                              message.sms.blackout_settings.blackout_window
                                .include_sunday
                            "
                          ></app-radio-btn>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="clearfix"></div>
                  <p *ngIf="isBlackoutType('none')">
                    No Blackout Window will be applied.
                  </p>

                  <p></p>

                  <p
                    *ngIf="
                      blackoutWindowExists() &&
                      (isBlackoutType('custom') || isBlackoutType('default'))
                    "
                  >
                    <i id="custom-blackout-desc">
                      Users will only receive notifications from
                      {{
                        clientService.getDisplayHour(
                          message.sms.blackout_settings.blackout_window.end_time
                        )
                      }}
                      until
                      {{
                        clientService.getDisplayHour(
                          message.sms.blackout_settings.blackout_window
                            .start_time
                        )
                      }}
                      Eastern Standard Time. {{ weekendBlackoutMessage }}
                    </i>
                  </p>
                  <ng-container *ngIf="isBlackoutType('custom')">
                    <p>
                      <i
                        i18n="Description for blackout window start/end values"
                      >
                        Note: The blackout window start and end times cannot be
                        set equal. Attempting to do so will cause the other to
                        be adjusted by one hour.
                      </i>
                    </p>
                    <p>
                      <i
                        >Note: Users will not receive messages on Saturday or
                        Sunday when “On” is selected.</i
                      >
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>

          <br />

          <div class="form-group">
            <div
              class="row message-footer d-flex justify-content-between align-items-center"
            >
              <div
                class="btn"
                (click)="navigateBackToJourneyBuilder()"
                id="cancel-btn"
              >
                <div class="cancel-mesage">Cancel</div>
              </div>
              <button
                class="btn btn-primary save-mesage"
                (click)="checkInputParams(messageGroup)"
                id="save-btn"
              >
                <div>
                  Save<span class="icon icon-right"
                    ><i class="fa fa-angle-right"></i
                  ></span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<add-media-asset
  *ngIf="showAddAssetDialog"
  [clientId]="this.sessionService.currentUser.client.id"
  [assetTypes]="addMediaAssetType"
  [canSelect]="mediaAssetCanSelect"
  [maxFileSize]="1048576"
  (onSave)="onMediaAssetAdded($event)"
  (onClose)="onCloseAddMediaAssetDialog()"
  (onSelect)="onMediaAssetSelected($event)"
  [createBrand]="true"
>
</add-media-asset>

<message-dialog
  #inputWarningMessageDialog
  [showCancel]="true"
  (affirm)="submitForm(messageGroup)"
>
</message-dialog>

<message-dialog
  #messageDialogWithKickback
  (affirm)="navigateBackToJourneyBuilder()"
>
</message-dialog>

<message-dialog #messageDialogNoKickback> </message-dialog>

<message-dialog #iwcuWarning [showCancel]="true" (affirm)="cleanIWCUAndSave()">
</message-dialog>
