import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyType, Industry, Outcome } from '@app/core/models/categorization-types';
import { CategorizationListItem, CategoryIndex } from '@app/experience-library/utils/categorization-list-item';

@Component({
  selector: 'rn-outcome-list-item',
  templateUrl: './outcome-list-item.component.html',
  styleUrls: ['./outcome-list-item.component.scss']
})
export class OutcomeListItemComponent extends CategorizationListItem   {
  @Input() outcome: Outcome;
  @Input() companyTypeOptions: CompanyType[];
  @Input() industryOptions: Industry[];
  @Output() editClicked = new EventEmitter();
  detailsOpen: boolean = false;
  categoryIndex = CategoryIndex;

  constructor() { 
    super();
  }

  emitEditEvent(): void {
    this.editClicked.emit(this.outcome.id);
  }

  toggleDetails(): void {
    this.detailsOpen = !this.detailsOpen;
  }

  getItemNameForId(itemId: string, index: CategoryIndex): string {
    if(this.industryOptions && index === CategoryIndex.Industries) {
    return this.industryOptions.find((c => c.id === itemId)).title['en-us'];
    } else if(this.companyTypeOptions && index === CategoryIndex.CompanyTypes) {
    return this.companyTypeOptions.find((c => c.id === itemId)).title['en-us'];
    } else {
    return '';
    }
  }

  get hasAdditionalItemsAndDetailsOpen(): boolean {
    return (this.hasAdditionalItems(this.outcome, this.categoryIndex.CompanyTypes) || this.hasAdditionalItems(this.outcome, this.categoryIndex.Industries)) && this.detailsOpen;
  }
  
  get hasAdditionalItemsAndDetailsClosed(): boolean {
    return this.hasAdditionalItems(this.outcome, this.categoryIndex.CompanyTypes) || this.hasAdditionalItems(this.outcome, this.categoryIndex.Industries);
  }
}
