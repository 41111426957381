import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
  ClosedReasons,
  ClosedReasonsService,
} from '@app/two-way/setup/components/setup-conversations/components/closed-reasons-axios-service/closed-reasons-axios-service';

interface ClosedReasonsContextType {
  closedReasons: ClosedReasons[];
  fetchClosedReasons: (clientId: string) => Promise<void>;
  isClosedReasonsError: boolean;
  isClosedReasonsEnabled: boolean;
}

interface ProviderProps {
  children: ReactNode;
}

const defaultState: ClosedReasonsContextType = {
  closedReasons: [],
  fetchClosedReasons: async () => {},
  isClosedReasonsError: false,
  isClosedReasonsEnabled: false,
};
const ClosedReasonsContext =
  createContext<ClosedReasonsContextType>(defaultState);

export const useClosedReasons = () => useContext(ClosedReasonsContext);

export const ClosedReasonsProvider = ({ children }: ProviderProps) => {
  const [isClosedReasonsEnabled, setIsClosedReasonsEnabled] =
    useState<boolean>(false);
  const [closedReasons, setClosedReasons] = useState<ClosedReasons[]>(null);
  const [isClosedReasonsError, setIsClosedReasonsError] = useState(false);

  const fetchClosedReasons = async (clientId: string) => {
    if (clientId) {
      try {
        const client = await ClosedReasonsService.getClient(clientId);
        if (
          client &&
          client.client &&
          client.client.two_way_config &&
          client.client.two_way_config.close_reasons_enabled
        ) {
          setIsClosedReasonsEnabled(true);
          const closedReasonsRes = await ClosedReasonsService.getClosedReasons(
            clientId,
          );
          setClosedReasons(closedReasonsRes);
        }
      } catch (error) {
        setIsClosedReasonsError(true);
      }
    }
  };
  return (
    <ClosedReasonsContext.Provider
      value={{
        closedReasons,
        isClosedReasonsError,
        fetchClosedReasons,
        isClosedReasonsEnabled,
      }}
    >
      {children}
    </ClosedReasonsContext.Provider>
  );
};
