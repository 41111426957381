import { environment } from '@env/environment';
import axios from 'axios';

export interface ClosedReasons {
  id?:string;
  clientId: string;
  enabled: boolean;
  allowAdditionalDetails: boolean;
  reason: string;
  archived: boolean;
  ordinal: number;
  isInErrorState?: boolean;
}

export const client = axios.create({
  baseURL: environment.twoWayURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
});

export const configClient = axios.create({
  baseURL: environment.configURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
});

export const ClosedReasonsService = {
  /** Create Closed Reason
   *
   * @param clientId the clientId.
   * @param enabled the enabled.
   * @param allowAdditionalDetails the allowAdditionalDetails.
   * @param reason the reason.
   * @param archived the archived.
   * @param ordinal the ordinal.
   */
  createClosedReasons: async (
    clientId: string,
    enabled,
    allowAdditionalDetails,
    reason,
    archived,
    ordinal
  ): Promise<ClosedReasons> => {
    const url = `/client/${clientId}/conversation-closed-reasons`;
    const reqBody = {
      clientId,
      enabled,
      allowAdditionalDetails,
      reason,
      archived,
      ordinal
    };
    const { data } = await client.post(url, reqBody);
    return data;
  },
  /** Update Closed Reason
   *
   * @param clientId the clientId.
   * @param enabled the enabled.
   * @param allowAdditionalDetails the allowAdditionalDetails.
   * @param reason the reason.
   * @param archived the archived.
   */
  updateClosedReasons: async (
    clientId: string,
    id: string,
    enabled,
    allowAdditionalDetails,
    reason,
    archived,
    ordinal,
  ): Promise<ClosedReasons> => {
    const url = `/client/${clientId}/conversation-closed-reasons/${id}`;
    const reqBody = {
      clientId,
      enabled,
      allowAdditionalDetails,
      reason,
      archived,
      ordinal,
    };
    const { data } = await client.put(url, reqBody);
    return data;
  },

  /** Get Closed Reasons
   *
   *  @param clientId the clientId.
   */
  getClosedReasons: async (clientId: string): Promise<ClosedReasons[]> => {
    const url = `/client/${clientId}/conversation-closed-reasons`;
    const {
      data: { data },
    } = await client.get(url);
    return data.map((closedReason) => {
      return {
        id: closedReason.id,
        clientId: closedReason.clientId,
        enabled: closedReason.enabled,
        allowAdditionalDetails: closedReason.allow_additional_details,
        reason: closedReason.reason,
        updatedAt: closedReason.updated_at,
        createdAt: closedReason.created_at,
        archived: closedReason.archived,
        ordinal: closedReason.ordinal,
      }
    })
  },

  /** Get Client Config
   *
   * @param clientId the clientId.
   */
  getClient: async(clientId: string): Promise<any> => {
    const url = `/client/${clientId}`;
    const { data } = await configClient.get(url);
    return data;
  }
};
