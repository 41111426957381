import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { Observable, of } from 'rxjs';
import { ClientService } from '@app/core/services/client.service';
import { Client, ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TwoWaySetupService {
  private clientStore$: Observable<Client>;
  private defaultProductGroup$: Observable<ProductGroup>;


  constructor(private clientService: ClientService,
              private sessionService: SessionService,
  ) {
    this.sessionService.selectedClient.subscribe(() => {
      this.resetData();      
    });
  }
  
  resetData() {
    this.clientStore$ = this.refreshClient();
    this.defaultProductGroup$ = this.setDefaultProductGroup();
  }

  /**
   * used for auto-response previews only
   */
  getDefaultProductGroup(): Observable<ProductGroup> {
    return this.defaultProductGroup$;
  }

  /**
   * used to get defaults for after-hours-auto-response blackoutWindows
   */
  getClient(): Observable<Client> {
    return this.clientStore$;
  }

  getTwoWayConfig(): Observable<TwoWayConfig> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        return of(client['two_way_config']);
      })
    );
  }

  saveTwoWayConfig(newTwoWayConfig: TwoWayConfig): Observable<TwoWayConfig> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        client['two_way_config'] = newTwoWayConfig; // updates client with new two way config
        return this.updateTwoWayConfig(newTwoWayConfig);
      }),
      switchMap((response) => {
        return of(response);
      })
    );
  }

  private refreshClient(): Observable<Client> {
    return this.clientService.getClient(this.clientId, true).pipe(
      switchMap((response: Client) => {
        return of(response);
      })
    )
  }

  private setDefaultProductGroup(): Observable<ProductGroup> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        return this.clientService.getProductGroup(client.id, 'default', true);
      })
    );
  }

  private updateTwoWayConfig(TwoWayConfig: TwoWayConfig): Observable<TwoWayConfig> {
    return this.clientService.updateClientPartial(this.clientId, 'two_way_config', TwoWayConfig);
  }

  private get clientId(): string {
    return this.sessionService.getCurrentUsersClient().id;
  }
}
