<p><strong>{{formGroup.get('client-name-long')?.value}} Helpsite</strong></p>

<p><strong>What is Relay?</strong></p>

<p>Relay is a secure messaging service that {{formGroup.get('client-name-short')?.value}} uses to deliver account,
    service and/or product
    information to its customers. Relay helps {{formGroup.get('client-name-short')?.value}} provide a greater level of
    support to you through
    proactive, personalized, and timely communications on your own secure space. </p>

<p>Relay Network LLC., an independent company, provides this communication service
    for {{formGroup.get('client-name-long')?.value}}.</p>

<p><strong>What are the benefits of this service?</strong></p>

<p>This complimentary service helps you stay informed and get things done quickly and
    easily. {{formGroup.get('client-name-short')?.value}} will
    notify you with a text when you have important account or product-related messages that need your attention. This is
    a
    web-based service, so you can access your messages from any device - smartphone, tablet, or desktop computer.</p>

<p><strong>What are the types of communications I may receive?</strong></p>

<p>You may receive account, service, or product information and notifications.</p>

<p>Message Notifications sent via SMS. Message Frequency Varies. Message and Data Rates May Apply. You may Opt-Out by
    replying STOP to any SMS Notification received. Relay Network supports the following carriers: Verizon Wireless;
    T-Mobile®; Metro PCS; Sprint; AT&amp;T; Virgin Mobile; US Cellular; Interop; nTelos; C-Spire; Carolina West
    Wireless;
    Cellcom; ClearSky; and Google Voice. Carriers are not liable for delayed or undelivered messages.</p>

<p><strong>Am I charged for any notifications I receive on my mobile phone?</strong></p>

<p>No. {{formGroup.get('client-name-short')?.value}} offers this service at no charge. Depending on your mobile plan,
    standard message and data rates
    may apply.</p>

<p><strong>Can I opt out of mobile notifications?</strong></p>

<p>Yes. You are in complete control of this service, and you can stop at any time by texting STOP
    to {{formGroup.get('client-short-long-code')?.value}}. For HELP text HELP
    to {{formGroup.get('client-short-long-code')?.value}}.</p>

<p><strong>{{formGroup.get('client-name-short')?.value}} Customer Support</strong></p>

<p>{{formGroup.get('client-support-text')?.value}}</p>
<p>{{formGroup.get('client-support-phone')?.value }}</p>
<p><a href="{{formGroup.get('client-support-url')?.value}}">{{formGroup.get('client-support-url')?.value}}</a></p>

<p><strong>Privacy Policy and Terms of Use</strong></p>

<div>
    <div>
        <div *ngIf="formGroup.get('privacy-form')?.get('show-client-privacy-url')?.value">
            <a
                    href="{{formGroup.get('privacy-form')?.get('client-privacy-policy-url')?.value}}"
                    target="_blank">{{formGroup.get('client-name-short')?.value}}
                Privacy Policy</a></div>
        <div *ngIf="formGroup.get('privacy-form')?.get('include-relay-privacy')?.value"><a
                href="https://my.relayit.com/c/{{client.id}}/privacy" target="_blank">Relay
            Network Privacy Policy</a></div>
    </div>

    <div>
        <div *ngIf="formGroup.get('terms-of-use-form')?.get('show-client-terms-of-use-url')?.value"><a
                href="{{formGroup.get('terms-of-use-form')?.get('client-terms-of-use-url')?.value}}"
                target="_blank">{{formGroup.get('client-name-short')?.value}}
            Terms of Use</a></div>
        <div *ngIf="formGroup.get('terms-of-use-form')?.get('include-relay-terms-of-use')?.value"><a
                href="https://my.relayit.com/c/{{client.id}}/terms" target="_blank">Relay Network Terms of Use</a></div>
    </div>
</div>
