<div class="intro-line-1" i18n="Introductory for product group list - line 1">Let's add the finishing touches</div>

<app-new-product-onboarding
  *ngIf="!!productGroup && !!client"
  [client]="client"
  [productGroup]="productGroup"
  (isValid)="validSignUpForm($event)"
></app-new-product-onboarding>

<app-product-group-features
  *ngIf="!!productGroup && !!client"
  [client]="client"
  [productGroup]="productGroup"
></app-product-group-features>

<app-verification
  #verificationForm
  *ngIf="!!productGroup && !!client && client.verification.enabled === 'true'"
  [client]="client"
  [productGroup]="productGroup"
  (validationStateChange)="validVerificationForm($event)"
></app-verification>

<div class="button-container clearfix" *ngIf="productGroup">

  <button id="prev-btn" class="btn btn-primary" (click)="cancelCurrent()">
    <span class="icon icon-left">
        <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label" i18n="Label for cancel button">
        Previous
    </span>
  </button>

  <button id="next-btn" class="btn btn-primary btn-with-icon" (click)="saveProduct()"
          [class.inactive]="!isValidForm()"	
          [disabled]="!isValidForm()">
    <span class="btn-label" i18n="Label for save button">
        Finish
    </span>
    <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
    </span>
  </button>

</div>


<message-dialog></message-dialog>
