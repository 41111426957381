<div class="rn-page">
  <div class="rn-page__section">
    <div class="rn-page--busy" *ngIf="isLoading(); else main_content">
      <app-spinner [styleRules]="{'transform': 'translateY(250px)'}"></app-spinner>
    </div>

    <ng-template #main_content>
      <div *ngFor="let option of testOptions">
        {{option}}
      </div>
      <div class="form-group" [formGroup]="controlGroupsForm">
        <div class="card panel--left">
          <ul class="control-group-list" formArrayName="groups" name="groups">
            <li>
                <button type="button" class="control-group-button control-group-button--add" (click)="addEmptyGroup()">Add new Group</button>
            </li>
            <li *ngFor="let group of groupsCtl; let i = index" [formGroupName]="i">
              <button type="button" class="control-group-button" [class.control-group-button--selected]="isSelectedGroup(group)" [class.control-group-button--unsaved]="groupHasUnsavedChanges(group)" (click)="switchGroups(group)">{{group.controls.group_name.value || 'New Group *'}}</button>
            </li>
          </ul>
        </div>

        <div class="card panel--right">
          <p *ngIf="!clientHasGroups()">Please create a new group to get started.</p>

          <ul *ngIf="clientHasGroups()" class="control-group-details-list" formArrayName="groups" name="groups">

            <li *ngFor="let group of groupsCtl; let i = index" [formGroupName]="i">
              <div class="control-group-details" [class.control-group-details--hidden]="!isSelectedGroup(group)" [class.control-group-details--unsaved]="groupHasUnsavedChanges(group)">
                <form (submit)="saveGroup($event, group)" (keydown)="preventSubmitOnEnter($event)" [formGroup]="group">
                  <div class="group-name-container" [class.has-error]="(!groupNameIsValid(group) || groupNameIsEmpty(group)) && group.get('group_name').dirty">
                    <label>Group Name</label>
                    <input type="text" class="form-control" name="pending" formControlName="group_name" autocomplete=false/> 
                    <p class="error-text" *ngIf="groupNameIsEmpty(group) && group.get('group_name').dirty">Group name is required</p>
                    <p class="error-text" *ngIf="!groupNameIsValid(group)">This Group name has already been used</p>
                  </div>

                  <!-- Form for adding a tag to a group (new or existing) -->
                  <rn-new-control-tag-form 
                    *ngIf="groupTagsFetched(group) || !isSavedGroup(group)" 
                    [allTagNames]="allTagNames" 
                    [formGroupRecievingTag]="group" 
                    (addTag)="addNewTagEvent($event, group)">
                  </rn-new-control-tag-form>

                  <label>Tags</label>
                  
                  <app-spinner *ngIf="showNewTagLoading"></app-spinner>
                  
                  <ng-container *ngIf="group.controls['tags']">
                    <div class="form-group" formArrayName="tags" name="tags">
                      <li class="control-group-item" *ngFor="let tag of group.controls['tags'].controls; let j = index" [formGroupName]="j">
                        <rn-control-tag-detail [tag]="tag" [group]="group" (remove)="removeTag(group.controls['tags'], j)"></rn-control-tag-detail>
                      </li>
                    </div>
                  </ng-container>

                  <div>
                    <app-spinner *ngIf="showTagLoading(group)"></app-spinner>
                    <p class="error-text" *ngIf="group.controls['tags'] && group.controls['tags'].errors && group.controls['tags'].errors.itemsInArrayAreNotUnique">Tags list contains duplicate tags.</p>
                    <p class="error-text" *ngIf="groupTagsFetched(group) && !groupHasTags(group)">Please add at least 1 tag before saving.</p>
                    <button type="submit" class="btn btn-primary control-group-save-btn">Save Group</button>
                    <p class="error-text" *ngIf="groupHasUnsavedChanges(group)">This Group Has Unsaved Changes</p>
                  </div>
                </form>
              </div>
            </li>
            
          </ul> 
        </div>
      </div>
    </ng-template>
  </div>
</div>

<message-dialog #errorMsg [showCancel]="false"></message-dialog> 

