import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';
import { SessionService } from '@app/security/session.service';
import moment from 'moment-timezone';
import * as _ from 'lodash';


@Injectable()
export class JobService {

  constructor(private sessionService: SessionService,
              private secureHttp: SecureHttp) {
  }
  /** Sign up customer */
  signUpCustomer(ccid, validation: Object, enteredInputParams: Object): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;
    validation['input_parameters'] = this.filterParams(enteredInputParams);
    //if there is date validation we want to format
    if (validation['date_of_birth']) {
      validation['date_of_birth'] = moment(validation['date_of_birth']).format('YYYY-MM-DD');
    }

    //ensure the phone number is formatted properly
    if (validation['phone_number']) {
      validation['phone_number'] = validation['phone_number'].replace(/\D/g, '');
    }
    return this.secureHttp.post(`${environment.cdmURLBase}/client/${clientId}/customer/${encodeURIComponent(ccid)}/csr`, validation);
  }

  /** Launch a journey trigger for a single customer. */
  triggerJourneyStep(journeyId: string, triggerId: string, enteredInputParams: Object, clientMessageTag: string = undefined): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;

    const ccid = enteredInputParams['CCID'];

    const request = {
      input_parameters: this.filterParams(enteredInputParams),
      client_message_tag: clientMessageTag
    };

    return this.secureHttp.post(
      `${environment.messagingURLBase}/client/${clientId}/journey_trigger/${triggerId}/customer/${encodeURIComponent(ccid)}`,
      request);
  }

  /** Launch a journey trigger for a set of customers (all input data is supplied) */
  triggerJourneyStepBatch(journeyId: string, triggerId: string, enteredInputParams: Array<Object>, clientMessageTag: string[] = []) {
    const clientId = this.sessionService.currentUser.client.id;

    const request = {
      batch: _.map(enteredInputParams, (params, i) => {
        const batchEntry = {
          trigger_id: triggerId,
          ccid: params['CCID'],
          input_parameters: this.filterParams(params)
        };
        if (clientMessageTag[i]) {
          batchEntry['client_message_tag'] = clientMessageTag[i];
        }
        return batchEntry;
      })
    };

    return this.secureHttp.post(`${environment.messagingURLBase}/client/${clientId}/journey_trigger`, request);
  }

  /** private methods below public **/
  private filterParams(params: Object) {
    const filteredParams = {};
    for (const property in params) {
      if (params.hasOwnProperty(property) && property.startsWith('input_')) {
        filteredParams[property] = params[property];
      }
    }
    return filteredParams;
  }
}
