<div
  style="position: relative"
  [ngClass]="{
    'has-error': isError ?? !formReference.valid && formReference.touched
  }"
>
  <span
    class="error-msg-icon"
    *ngIf="isError ?? !formReference.valid && formReference.touched"
    tooltip="{{ getTooltipData(formReference.errors) }}"
    placement="bottom"
  >
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
  </span>

  <ng-content></ng-content>

  <div *ngIf="showCharacterCount" class="char-count">
    <span
      ><strong
        [ngClass]="{
          'over-length-limit': remainingLength < 0
        }"
        >{{ remainingLength }}</strong
      >
      of <strong>{{ totalLength }}</strong> characters remaining.</span
    >
  </div>
</div>
