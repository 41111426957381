import { Injectable, EventEmitter } from '@angular/core';

type TimeOutEvent =
  | { type: 'update'; timeStamp: Date }
  | { type: 'delete'; timeStamp: null };

@Injectable()
export class TimeOutLocalStorageService {
  timeOutKey = 'relayTimeout';
  // have to use "static" so both Angular http-client and React axios share the same instance
  static timeOutEventChange = new EventEmitter<TimeOutEvent>();
  constructor() {}

  getTimeoutValue(): string | null {
    return localStorage.getItem(this.timeOutKey);
  }

  setTimeoutValue(durationInSeconds: number): void {
    const newTimeStamp = new Date(Date.now() + durationInSeconds * 1000);
    localStorage.setItem(this.timeOutKey, JSON.stringify(newTimeStamp));
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: this.timeOutKey,
        newValue: JSON.stringify(newTimeStamp),
      }),
    );
  }

  listenToTimeoutChanges(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === this.timeOutKey) {
        const timeoutStamp = this.getTimeoutValue();
        if (timeoutStamp) {
          TimeOutLocalStorageService.timeOutEventChange.emit({
            type: 'update',
            timeStamp: new Date(timeoutStamp),
          });
        } else {
          TimeOutLocalStorageService.timeOutEventChange.emit({
            type: 'delete',
            timeStamp: null,
          });
        }
      }
    });
  }

  deleteTimeoutValue(): void {
    localStorage.removeItem(this.timeOutKey);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: this.timeOutKey,
        newValue: null,
      }),
    );
  }
}
