<div class="message" [innerHtml]="message | safeHtml">
</div>
<br>
<div class="btn-container">
    <button id="launch-now-btn" class="btn btn-warning btn-launch" (click)="launchNow.emit()">
        <span class="btn-label" i18n="Label for button that immediately launches an experience">Launch</span>
        <span class="icon icon-right" >
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </span>
    </button>
    
    <button id="launch-on-timer-btn" class="btn btn-warning btn-launch" (click)="launchOnTimer.emit()">
        <span class="btn-label" i18n="Label for button that schedules an experience launch">Schedule</span>
        <span class="icon icon-right" >
            <i class="fa fa-clock-o" aria-hidden="true"></i>
        </span>
    </button>
</div>