
<img class="journey-node delay" src="assets/hex/delay.png" />
<div #delayBox class="messageBox delay-box with-box-menu clearfix" [class.selected]="selected">

  <box-menu [menuItems]="boxMenuItems" [selected]="selected"></box-menu>

  <div class="messageTop with-box-menu">
    <div class="messageNumber">{{delay.step}}</div>
    <span class="title">Time Delay</span>
  </div>
  <div>
    <select id="delay-enum-select" [(ngModel)]="delay.interval_type" (change)="onIntervalTypeChange()" (click)="onSelectClick($event)">
      <option id="delay-minute" value="Minute" i18n="Option for delay time">Minute</option>
      <option id="delay-hour" value="Hour" i18n="Option for delay time">Hours</option>
      <option id="delay-day"  value="Day" i18n="Option for delay time">Days</option>
    </select>

    <select id="delay-value-select" [(ngModel)]="delay.interval_value" (click)="onSelectClick($event)">
      <option *ngFor="let unit of delayUnits" [ngValue]="unit" id="delay-{{unit}}">{{unit}}</option>
    </select>
  </div>
</div>

