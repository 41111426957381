import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import React from 'react';
import ReactDOM from 'react-dom';
import { PowerUpsConfigReactContainer } from './power-ups-config-react-container/PowerUpsConfigReactContainer';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'rn-client-power-ups-config',
  templateUrl: './client-power-ups-config.component.html',
  styleUrls: ['./client-power-ups-config.component.scss']
})
export class ClientPowerUpsConfigComponent 
  implements OnInit, OnDestroy, OnChanges, AfterViewInit 
{
  clientId: string;
  reactWrapperId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
  ) {}

  ngOnInit(): void {
    this.titleService.activate('Power-Ups');
    this.getClient();
    this.reactWrapperId = 'power-ups-config-wrapper-id';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges() {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(PowerUpsConfigReactContainer, {
          clientId: this.clientId,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }
  
  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientId = params['clientId'];
    });
  }
}
