export const RotationIntervals = {
  _30DAYS: {
    label: '30 Days',
    value: 2592000,
  },
  _60DAYS: {
    label: '60 Days',
    value: 5184000,
  },
  _90DAYS: {
    label: '90 Days',
    value: 7776000,
  },
};
