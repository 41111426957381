import { VideoUtils } from './video-utils';
import {
  AbstractControl,
  ValidatorFn,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { URLUtils } from '@app/core/utils/url-utils';
import { InputParamUtil } from '@app/core/models/input-param-util';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class CustomValidators {
  static readonly digitsOnlyRegex = /^[0-9]+$/;
  static readonly digitsOnly = Validators.pattern(
    CustomValidators.digitsOnlyRegex,
  );

  // e.g.  john@smith.com, john.t.smith@john.t.smith.com
  static readonly emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  static readonly email = Validators.pattern(CustomValidators.emailRegex);

  // e.g.  john@smith.com, john.t.smith@john.t.smith.com
  static readonly emailToBeTrimmedRegex =
    /^\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\s*$/;
  static readonly emailToBeTrimmed = Validators.pattern(
    CustomValidators.emailToBeTrimmedRegex,
  );

  // e.g.  (123) 456-7890, 1(123) 456-7890
  static readonly phoneNumberRegex = /^\d?\(\d\d\d\) \d\d\d-\d\d\d\d$/;
  static readonly phoneNumber = Validators.pattern(
    CustomValidators.phoneNumberRegex,
  );

  // e.g.  (123) 456-7890, 1(123) 456-7890
  static readonly phoneNumberToBeTrimmedRegex =
    /^\s*(\d?\(\d\d\d\) \d\d\d-\d\d\d\d)\s*$/;
  static readonly phoneNumberToBeTrimmed = Validators.pattern(
    CustomValidators.phoneNumberToBeTrimmedRegex,
  );

  // e.g.  (123) 456-7890, 1(123) 456-7890, +1, +789, +0123456
  static readonly phoneNumberOrExtToBeTrimmedRegex =
    /^\s*((\d?\(\d\d\d\) \d\d\d-\d\d\d\d)|(\+[0-9]{1,7}))\s*$/;
  static readonly phoneNumberOrExtToBeTrimmed = Validators.pattern(
    CustomValidators.phoneNumberOrExtToBeTrimmedRegex,
  );

  static readonly phoneNumberRegexComprehensive =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
  static readonly phoneNumberComprehensive = Validators.pattern(
    CustomValidators.phoneNumberRegexComprehensive,
  );

  static readonly urlRegex = URLUtils.stackOverflowURLRegex;
  static readonly validURL = Validators.pattern(CustomValidators.urlRegex);

  static readonly urlWithQuery = URLUtils.urlWithQuery;
  static readonly validURLWithQuery = Validators.pattern(
    CustomValidators.urlWithQuery,
  );

  static readonly twitterRegex = /^@?(\w){1,15}$/;
  static readonly twitter = Validators.pattern(CustomValidators.twitterRegex);

  static readonly apiIDRegex = /^[a-z0-9_]*$/; // lowercase letters, numbers, underscores only
  static readonly apiCompatibleID = Validators.pattern(
    CustomValidators.apiIDRegex,
  );

  static readonly apiMediaIDRegex = /^[a-zA-Z_0-9\-]*$/; // lowercase & capital letters, numbers, dashes, underscores
  static readonly apiCompatibleMediaID = Validators.pattern(
    CustomValidators.apiMediaIDRegex,
  );

  static readonly youtubeLink = Validators.pattern(VideoUtils.isYoutubeUrl);

  /**
   * used for only spaces to get around inputs `'   '`
   */
  static readonly nonSpace = CustomValidators.namedPattern(/\S+/, 'nonSpace');

  /**
   * used for spaces in string `a bc`
   * @param control
   */
  static noSpacesInString(
    control: AbstractControl,
  ): null | { [key: string]: any } {
    if (typeof control.value === 'string' && control.value.includes(' ')) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  static longCodeLeadingOneDigit(
    control: AbstractControl,
  ): null | { [key: string]: any } {
    if (
      typeof control.value === 'string' &&
      !control.value.startsWith('1') &&
      control.value.length > 7
    ) {
      return { cannotBeginWithOneDigit: true };
    }
    return null;
  }

  static whiteListURL(control: AbstractControl): null | Object {
    let valid = false;

    _.each(VideoUtils.whitelist, (urlToCheck: RegExp) => {
      if (!!control.value && urlToCheck.test(new URL(control.value).hostname)) {
        valid = true;
      }
    });

    return valid ? null : { whiteListURL: 'Not a white listed URL' };
  }

  static standardValidURL(control: AbstractControl): null | Object {
    const value = control.value;
    if (value == null) {
      return null;
    }

    const stringValue = value.toString();
    if (!stringValue) {
      return null;
    }

    try {
      new URL(stringValue);
      return null;
    } catch {
      return { pattern: 'URL is invalid' };
    }
  }

  static beginsWithHttpOrHttps(control: AbstractControl): null | Object {
    const value = control.value;
    if (value == null) {
      return null;
    }
    const stringValue = value.toString();
    if (
      !stringValue ||
      stringValue.indexOf('http://') === 0 ||
      stringValue.indexOf('https://') === 0
    ) {
      return null;
    } else {
      return { noProtocol: 'Missing http or https' };
    }
  }

  static namedPattern(regex: RegExp, name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const match = regex.test(control.value);
      if (!match) {
        const errors = {};
        errors[name] = control.value;

        return errors;
      } else {
        return null;
      }
    };
  }

  /**
   * Must be used in the context of a single toggle/form group. If you try to use it across multiple
   * toggle/value pairings it will overwrite each time.
   * @param toggleControlName
   * @param valueControlName
   */
  static toggledOnRequiresValue({
    toggleControlName,
    valueControlName,
  }: {
    toggleControlName: string;
    valueControlName: string;
  }): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const toggleValue = group.get(toggleControlName).value;
      const value = group.get(valueControlName).value;
      return !!(toggleValue && value) || !toggleValue
        ? null
        : { toggledButNoValue: true };
    };
  }

  /**
   * Must be used in the context of a single toggle/form group. If you try to use it across multiple
   * toggle/value pairings it will overwrite each time.
   */
  static atLeastOneControlHasTrueValue(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const atLeastOneIsTrue = Object.keys(group.controls).some((key) => {
        return group.get(key).value === true;
      });
      return atLeastOneIsTrue ? null : { noTrueValueControls: true };
    };
  }

  static validateNegativePattern(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const forbidden = regex.test(control.value);
      return forbidden
        ? { negativePatternMatch: { value: control.value } }
        : null;
    };
  }
  /**
   * marks as valid if there are no @{input_*} params, and invalid if there are params
   * Example usage:
   *     this.messageGroup = this.fb.group({
   *       'alternate_message_text': [message.wire.alternate_text, [CustomValidators.validateNegativeInputPattern(), Validators.maxLength(5000)]],
   *      });
   */
  static validateNegativeInputPattern(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const value = InputParamUtil.removeAutoCompleteSpans(control.value);
        const forbidden = InputParamUtil.inputParamPattern.test(value);
        return forbidden
          ? { negativePatternMatch: { value: control.value } }
          : null;
      } else {
        return null;
      }
    };
  }

  static validateReservedInputURL(control: AbstractControl): {
    [key: string]: any;
  } {
    const pattern = '@{input_url}';
    if (control.value && control.value.indexOf(pattern) > -1) {
      return { invalidPattern: { value: control.value, pattern } };
    } else {
      return null;
    }
  }

  static validateReverseReservedInputURL(control: AbstractControl): {
    [key: string]: any;
  } {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim();
    const pattern = '@{input_';
    if (value && value.indexOf(pattern) > -1 && value !== '@{input_url}') {
      return { invalidPattern: { value: control.value, pattern } };
    } else {
      return null;
    }
  }

  static validateInputParamsAuthlinkAllowed(regex1: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return;
      }

      const authLinkParamPattern = /@{auth[\-_]link}/gi,
        forbidden = control.value.match(regex1);
      let filteredForbiddenInputs = [];

      if (forbidden) {
        filteredForbiddenInputs = forbidden.filter(
          (input) => !input.match(authLinkParamPattern),
        );
      }
      return filteredForbiddenInputs.length > 0
        ? { negativePatternMatch: { value: filteredForbiddenInputs } }
        : null;
    };
  }

  /*
    Returns a validation error if the form control being validated (control) has a value that's
    already present in the passed FormArray (array).
    @param array:  expected to be an array of formControls with string values
  */
  static isUnique(array: UntypedFormArray): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const matches = _.filter(
        array.getRawValue(),
        (item) => item === control.value,
      );
      if (matches && matches.length > 1) {
        return { valueIsNotUnique: control.value };
      } else {
        return null;
      }
    };
  }

  /*
    Returns a validation error if one of the FormGroups in the passed FormArray has a duplicate
    value in the passed key.

    Example form setup:
      new FormArray([
        new FormGroup({
          'unique_key': new FormControl('I am unique!')
        }),
        new FormGroup({
          'unique_key': new FormControl('so am I!')
        })
      ], CustomValidators.itemsAreUniqueByKey('unique_key')); // validator gets added to the FormArray

    @param array: an array of formGroups with a form control name that matches the key param.
    @param key: a key that can be found in all formGroups in array
  */
  static itemsAreUniqueByKey(key: string): ValidatorFn {
    return (array: UntypedFormArray): { [key: string]: any } => {
      /*
        getDuplicatedValues
          given:
            key: 'a'
            array: [{ a: 'apricot' }, {a: 'apple'}, {a: 'apricot'}]
          returns:
            ['apricot']
      */
      const getDuplicatedValues = _.flow(
        (items) => _.groupBy(items, key), // { apricot: [{a: 'apricot'}, {a: 'apricot'}], apple: [{a: 'apple'}]}
        (itemsGroupedByKey) =>
          _.omitBy(itemsGroupedByKey, (group) => group.length === 1), // { apricot: [{a: 'apricot'}, {a: 'apricot'}] }
        (groupsLongerThanOne) => _.keys(groupsLongerThanOne), // [ 'apricot' ]
      );
      const duplicatedValues = getDuplicatedValues(array.getRawValue(), key);

      if (duplicatedValues && duplicatedValues.length > 0) {
        return { itemsInArrayAreNotUnique: duplicatedValues };
      } else {
        return null;
      }
    };
  }

  /*
    Works the same as `itemsAreUniqueByKey` above, but is meant to be used on an external
    formControl, not on the FormArray itself.

    // Form Array that must have unique values in all of the 'name' keys
    existingItems: FormArray = new FormArray(
      new FormControl({ name: apple, color: red }, CustomValidators.itemsAreUniqueByKey('name')),
      new FormControl({ name: strawberry, color: red }, CustomValidators.itemsAreUniqueByKey('name')),
      new FormControl({ name: pear, color: green }, CustomValidators.itemsAreUniqueByKey('name'))
    )

    // independent form control for a new 'name' that will only be valid if the name doesn't already appear in existingItems.
    newItemName: FormControl = new FormControl('', CustomValidators.itemsAreUniqueByKeyExternal('name', existingItems))

    @param key: a key that can be found in all formGroups in array
    @param array: an array of formGroups with a form control name that matches the key param.
  */
  static itemsAreUniqueByKeyExternal(
    key: string,
    array: UntypedFormArray,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!array || !array.length) {
        return null;
      } // if the array is empty or undefined, return a valid response

      const valuesForKeyInArray = array.getRawValue().map((item) => item[key]);

      if (_.includes(valuesForKeyInArray, control.value)) {
        return { itemIsInExternalArray: '' };
      } else {
        return null;
      }
    };
  }

  static markAsUntouchedOnEmpty(control: AbstractControl) {
    if (control.value === '') {
      control.markAsUntouched();
    }
  }

  static htmlNonSpace(control: AbstractControl): { [key: string]: any } {
    // Remove html elements and &nbsp;
    // This regex is intended to handle simple html as found in rich text, not
    // necessarily full blown html.
    const htmlTag = /(<\/?[-a-zA-Z=_;:/.?&'" ]+\/?>)|(&nbsp;)/;

    let remainingText = control.value;

    let match = null;
    do {
      match = htmlTag.test(remainingText);
      if (match) {
        remainingText = remainingText.replace(htmlTag, '');
      }
    } while (match);
    if (remainingText == null || /^\s*$/.test(remainingText)) {
      return { htmlNonSpace: { value: control.value } };
    } else {
      return null;
    }
  }

  static urlValidator(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim();
    if (
      URLUtils.urlRegex.test(value) ||
      InputParamUtil.inputParamPatternFull.test(value)
    ) {
      return null;
    } else {
      return {
        urlOrInput: { value: control.value },
      };
    }
  }

  static urlWithQueryValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    const value = control.value.trim();
    if (
      URLUtils.urlWithQuery.test(value) ||
      InputParamUtil.inputParamPatternFull.test(value)
    ) {
      return null;
    } else {
      return {
        urlOrInput: { value: control.value },
      };
    }
  }

  static isPhoneNumberMatch(phoneNumber: string): boolean {
    if (phoneNumber == null) {
      return false;
    }

    // e.g.  (123) 456-7890, 1(123) 456-7890
    if (phoneNumber.trim().match(/[0-9]{10}([0-9]{1})?/)) {
      return true;
    }

    return false;
  }

  static isPhoneNumberExtMatch(phoneNumber: string): boolean {
    if (phoneNumber == null) {
      return false;
    }

    // e.g.  +1, +789, +0123456
    if (phoneNumber.trim().match(/\+[0-9]{1,7}/)) {
      return true;
    }

    return false;
  }

  /**
   * Marks all controls in a form group as touched
   * source: https://stackoverflow.com/questions/40529817/reactive-forms-mark-fields-as-touched#answer-48405613
   * added - same link with IE 11 work around
   * Replace with FormGroup.markAllAsTouched(); when we get to angular 8
   * @param controlCollection - The form group to touch
   */
  static markAllAsTouched(
    controlCollection: UntypedFormGroup | UntypedFormArray,
  ) {
    Object.keys(controlCollection.controls).forEach((field) => {
      // "any" gets around typing issue
      const control: any = controlCollection.get(field);
      control.markAsTouched();

      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }

  /**
   * Marks all controls in a form group as untouched
   * @param controlCollection - The form group to touch
   */
  static markAllAsUntouched(
    controlCollection: UntypedFormGroup | UntypedFormArray,
  ) {
    Object.keys(controlCollection.controls).forEach((field) => {
      // "any" gets around typing issue
      const control: any = controlCollection.get(field);
      control.markAsUntouched();

      if (control.controls) {
        this.markAllAsUntouched(control);
      }
    });
  }

  static emailActionValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    const value = control.value.trim();
    if (
      CustomValidators.emailRegex.test(value) ||
      InputParamUtil.inputParamPatternFull.test(value)
    ) {
      return null;
    } else {
      return {
        emailOrInput: { value: control.value },
      };
    }
  }

  static phoneNumberValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    if (!control.value) {
      return null;
    }
    const value = control.value.trim();
    if (
      CustomValidators.phoneNumberRegex.test(value) ||
      InputParamUtil.inputParamPatternFull.test(value)
    ) {
      return null;
    } else {
      return {
        phoneNumberOrInput: { value: control.value },
      };
    }
  }

  static iFCURestricionValidator(
    newFeed: boolean,
    numberOfIFCUActions: number,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!newFeed) {
        return of(null);
      }
      if (numberOfIFCUActions > 1) {
        // mark the control as touched so we can see the error on first render
        control.markAsTouched();
        return of({
          customErrorMessage:
            'A message can contain only one Consent Upgrade action.',
        });
      } else {
        return of(null);
      }
    };
  }
}
