import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ColorPickerService } from 'ngx-color-picker';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @Input() color: string;
  @Output() colorChanged: EventEmitter<string> = new EventEmitter();

  colorGroup: UntypedFormGroup;

  constructor(
    private colorPickerService: ColorPickerService,
    private formBuilder: UntypedFormBuilder) { }
  
  ngOnInit() {
    this.colorGroup = this.formBuilder.group({
      'product_color': [this.color ? this.color : null, [Validators.required]]
    });
  }

  updateColor(e: any) {
    if (e.value && e.value.length > 0 && /^#[a-fA-F0-9]{3,6}$/.test(e.value.trim())) {
      this.color = e.value;
      this.onChangeColor(this.color);
    }
  }

  onChangeColor(a) {
    this.colorChanged.emit(a);
  }
}
