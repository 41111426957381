
<div class="close" (click)="navigateToPrevious()"><i class="fa fa-times" aria-hidden="true"></i></div>

<ng-container *ngIf="product">
<div class="phone-container">
    <generic-phone>
        <wire-header [productGroup]="product"></wire-header>
        <network-preview [productGroup]="product"></network-preview>
    </generic-phone>
</div>
<div class="report-container">
    <div class="report">
        <div class="report-header">
            <div class="tools">
                <i class="fa fa-pencil" routerLink="/client/{{client.id}}/product-group/{{product.id}}"></i>
                <a href="javascript:window.print()"><i class="fa fa-file-pdf-o"></i></a>
            </div>
            <h4 [ngStyle]="{'color': product.branding.color}">{{product.name}}</h4>
            <h5 [ngStyle]="{'color': product.branding.color}">{{product.id}}</h5>
            <span>last saved {{product.updated_at | date:'shortDate'}}</span>
        </div>
        <div class="details-panel">
            <div *ngIf="product.description || product.vanity_url">
                <label [ngStyle]="{'color': product.branding.color}" class="report-label">Basic Information</label>
                <hr />
                <div *ngIf="product.vanity_url">
                        <label>Product ID</label>
                        <span>{{product.id}}</span>
                        <hr />
                    </div>
                <div *ngIf="product.vanity_url">
                    <label>Vanity URL</label>
                    <span>{{product.vanity_url}}</span>
                    <hr />
                </div>
                <div *ngIf="product.description">
                    <label>Product Description</label>
                    <span>{{product.description}}</span>
                    <hr />
                </div>
            </div>

            <div *ngIf="product.contact_us_info.length > 0">
                <label [ngStyle]="{'color': product.branding.color}" class="report-label">Contact Information</label>
                <hr />
                <app-product-contact-info-row [product]="product"></app-product-contact-info-row>
            </div>

            <div>
                <label [ngStyle]="{'color': product.branding.color}" class="report-label">Legal Information</label>
                <hr />

                <div>
                    <label>Message details disclaimer</label>
                    <span>{{product.disclaimer.message_details_enabled ? "Enabled" : "Disabled"}}</span>
                    <hr />
                </div>
                <div>
                    <label>{{this.client.feed_enabled ? "Feed disclaimer" : "Wire feed disclaimer"}}</label>
                    <span>{{product.disclaimer.wire_feed_enabled ? "Enabled" : "Disabled"}}</span>
                    <hr />
                </div>
                <app-product-legal-generic [label]="'Disclaimer text'" [inputModel]="product.disclaimer.text"></app-product-legal-generic>
                <app-product-legal-generic [label]="'Express written consent Ts & Cs'" [inputModel]="product.consent.express_written_consent_ts_cs"></app-product-legal-generic>
                <app-product-legal-generic [label]="'Express consent Ts & Cs'" [inputModel]="product.consent.express_consent_ts_cs"></app-product-legal-generic>
                <app-product-legal-generic [label]="'Client Terms'" [inputModel]="product.terms"></app-product-legal-generic>
                <app-product-legal-generic [label]="'Client privacy policy'" [inputModel]="product.privacy_policy"></app-product-legal-generic>
            </div>

            <div *ngIf="product.onboarding.sign_up_page.enabled === 'true'">
                <label [ngStyle]="{'color': product.branding.color}" class="report-label">Sign Up Page</label>
                <hr />

                <app-product-onboarding-fields-row [product]="product" [client]="client"></app-product-onboarding-fields-row>
            </div>
        </div>
    </div>
</div>
<hr />
</ng-container>

