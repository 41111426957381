<div class="intro-line-1">Validation and verification</div>

<ng-container *ngIf="client && inputFields.length > 0">
    <client-header [headerLabel]="'Customer account validation'" [headerGroup]="'onboarding'" (click)="headerGroups.validation = !headerGroups.validation"></client-header>
    <div class="collapse-container" *ngIf="headerGroups.validation">

      <app-onboarding-toggle [btnLabel]="client.feed_enabled ? 'Enable customer validation' :'Will there be customer validation?'" [btnName]="'validation_enabled'"
      [(btnModel)]="client.validation.enabled"></app-onboarding-toggle>

      <p class="helper-text">Customer validation is used by Relay Hosted Onboarding services to determine that a potential customer is in your system.</p>
      
      <ng-container *ngIf="client.validation.enabled === 'true'">
        <div class="select-container">
          <label>Location of customer lookup</label>
          <select class="form-control" [(ngModel)]="client.validation.customer_lookup_location">
            <option value="db"  [attr.selected]="client.validation.customer_lookup_location === 'db' ? true : null">DB/CCA File</option>
            <option value="web" [attr.selected]="client.validation.customer_lookup_location === 'web' ? true : null">Web Service</option>
          </select>
        </div>
        
        <div class="select-container">
          <label>Primary Customer Validation</label>
          <p class="helper-text">Think of this as your customers account number, account ID, or member ID. The value should be known to your customers as it will be required to onboard.</p>
          <select class="form-control" [(ngModel)]="client.validation.look_up_by_secondary_account_id">
            <option [ngValue]="false" [attr.selected]="client.validation.look_up_by_secondary_account_id === false ? true : null">CCID</option>
            <option [ngValue]="true" [attr.selected]="client.validation.look_up_by_secondary_account_id === true ? true : null">Secondary ID</option>
          </select>
        </div>

        
        <div class="select-container">
          <label>Maximum number of attempts</label>
          <input id="max-attempts-field" class="form-control" type="number" min="0" max="99" [value]="client.validation.max_attempts" (change)="onValidationMaxAttemptChange($event)"/>
        </div>
        
        <div class="fields-container">
          <label>Information fields</label>
          <p class="helper-text">These fields will be displayed to CSRs when they've selected an experience trigger that <strong>does not require</strong> validation.  All fields will be optional.</p>
          <rn-validation-fields-list 
          [allFields]="inputFields" 
          [selectedFields]="client.validation.information_fields | clone "
          (listChanged)="informationFieldsListChanged($event)">
        </rn-validation-fields-list>
      </div>
      
      <div class="fields-container">
        <label>Validation fields</label>
        <p class="helper-text">These fields will be displayed to CSRs when they've selected an experience trigger that <strong>requires</strong> validation.  All fields will be required.</p>
        <rn-validation-fields-list
        [allFields]="inputFields"
        [selectedFields]="client.validation.fields | clone"
        (listChanged)="validationFieldsListChanged($event)">
      </rn-validation-fields-list>
    </div>
  </ng-container>
</div>
<ng-container  *ngIf="client.feed_enabled" >
  
  <client-header id="password-settings-header" [headerLabel]="'Password settings'" [headerGroup]="'onboarding'" (click)="headerGroups.passwordSettings = !headerGroups.passwordSettings"></client-header>
  <div class="collapse-container" *ngIf="headerGroups.passwordSettings">
    <app-radio-btn
    [btnLabel]="'Enfore password rotation'"
    [btnWidth]="'900px'"
    [btnName]="'enfore-password-rotation'"
    [btnModel]="client.password_configuration.rotation_enabled"
    (btnModelChange)="toggleEnforcePasswordRotation()"
    >
    </app-radio-btn>
    <p class="helper-text">Customers will be required to set a new password at a determined frequency.</p>

    <!-- only visible when password rotation is ON -->
    <ng-container *ngIf="client.password_configuration.rotation_enabled" >
      <label for="rotation-interval-select">Rotation Frequency</label>
      <select
        id="rotation-interval-select"
        name="rotation-interval-select"
        class="form-control rotation-interval-select"
        (change)="changeRotationFrequency($event)"
      >
        <option
          *ngFor="let rotationOption of rotationIntervalOptions"
          [value]="rotationOption.value"
          [selected]="client.password_configuration.rotation_interval === rotationOption.value"
        >
          {{rotationOption.label}}
        </option>
      </select>
    </ng-container>

  </div>
</ng-container>
<client-header [headerLabel]="'Mobile message verification'" [headerGroup]="'onboarding'" (click)="headerGroups.verification = !headerGroups.verification"></client-header>
<div class="collapse-container" *ngIf="headerGroups.verification">
  <app-client-mobile-message-verification [client]="client" [allFields]="inputFields"></app-client-mobile-message-verification> 
    </div>
</ng-container>

<div class="button-container clearfix">
  <button id="prev-btn" class="btn btn-primary btn-with-icon" (click)="previousStep()">
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button class="btn btn-primary" id="next-btn" (click)="saveAndContinue()" [class.inactive]="checkDisabled()" [disabled]="checkDisabled()">
    <span i18n="Label for Next button on new client configuration" id="save-and-next-button">
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
