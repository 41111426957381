import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Client } from '@app/core/models/client';

@Component({
  selector: 'rn-client-helpsite-preview',
  templateUrl: './client-helpsite-preview.component.html',
  styleUrls: ['./client-helpsite-preview.component.scss']
})
export class ClientHelpsitePreviewComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() client: Client;

  constructor() { }

  ngOnInit(): void {
  }
}
