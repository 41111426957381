<div class="dialog-background">
  <div class="edit-media-asset-details-dialog dialog-content">
    <div class="title-bar">
      <span *ngIf="!editBrand" class="pull-left" i18n="Title bar for editing media asset details">Asset Details</span>
      <span *ngIf="editBrand" class="pull-left" i18n="Title bar for editing media asset details">Brand Details</span>
      <i id="close-dialog-icon" class="fa fa-times close-icon" (click)="close()"></i>
    </div>

    <div class="dialog-body">

      <div
        *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'brand' || mediaAsset.asset_type === 'banner'"
        id="image-preview" class="image-preview" (click)="requestUpdate()">
        <div class="image-preview-icon-wrapper">
          <img id="preview-image" src="{{mediaAsset.getS3UrlCacheBuster()}}" (load)="previewImageLoaded($event)">
          <i class="fa fa-camera fa-2x"></i>
        </div>
      </div>

      <div *ngIf="mediaAsset.asset_type === 'video'" class="video">
        <app-video *ngIf="!videoUrlEdittable || formGroup.controls['video_url'].valid; else invalidVideo"
                   [videoUrl]="mediaAsset.url" [height]="315" [width]="560"></app-video>
        <ng-template #invalidVideo>
          <iframe sandbox="allow-scripts allow-same-origin" id="youtube-player" width="560" height="315" frameborder="0"
                  allowfullscreen></iframe>
        </ng-template>
      </div>

      <hr>

      <div class="left-form-side">
        <div
          [ngClass]="{'has-error':!formGroup.controls['short_name'].valid && formGroup.controls['short_name'].touched}">
          <label i18n="Asset display name">Display Name</label>
          <input id="display-name" class="form-control" type="text" [formControl]="formGroup.controls['short_name']">
        </div>

        <div
          *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'banner'"
          [ngClass]="{'has-error':!formGroup.controls['alternate_text'].valid && formGroup.controls['alternate_text'].touched}">
          <label>Alternate Text</label>
          <input id="alternate-text" class="form-control" type="text"
                 [formControl]="formGroup.controls['alternate_text']">
        </div>

        <div>
          <label i18n="Asset ID">Asset ID</label>
          <input id="asset-id" class="form-control" type="text" value="{{mediaAsset.asset_id}}" readonly>
        </div>

        <div
          *ngIf="!editBrand"
          [ngClass]="{'has-error':!formGroup.controls['description'].valid && formGroup.controls['description'].touched}">
          <label i18n="Asset description">Description</label>
          <textarea id="description" class="form-control" type="text"
                    [formControl]="formGroup.controls['description']"></textarea>
        </div>

        <div
        *ngIf="editBrand"
        [ngClass]="{'has-error':!formGroup.controls['branding_color'].valid && formGroup.controls['branding_color'].touched}">
        <label i18n="Brand Color">Brand Color</label>
        <br />
        <color-picker
        [(color)]="mediaAsset.branding_color"
        (colorChanged)="setColor($event)"
      ></color-picker>
      </div>
      </div>

      <div class="right-form-side">
        <div
          *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'brand' || mediaAsset.asset_type === 'banner'">
          <div id="file-name">{{mediaAsset.getFileName()}}</div>
          <div><span id="file-type">{{mediaAsset.getFileExtension()}}</span> <span i18n="File label for file extension">File</span>
          </div>
          <div>
            <span id="image-width">{{imageWidth}}</span>
            <span i18n="Designator for by in image resolution, as in 1000 x 500">x</span>
            <span id="image-height">{{imageHeight}}</span>
          </div>
          <div *ngIf="mediaAsset.file_size" id="file-size">{{mediaAsset.getFileSizeKb()}} kb</div>
        </div>

        <label
          *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'brand' || mediaAsset.asset_type === 'banner'"
          i18n="File URL label">File URL</label>
        <ng-container *ngIf="mediaAsset.asset_type === 'video'">
          <label i18n="Video URL label">Video URL</label>
          <i *ngIf="!videoUrlEdittable" (click)="editVideoUrl()" id="edit-video-url-icon" class="fa fa-pencil"
             aria-hidden="true"></i>
        </ng-container>
        <br>
        <div
          *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'brand' || mediaAsset.asset_type === 'banner'"
          id="s3_url" class="wrapped-url">
          {{mediaAsset.s3_url.substring(0, 55)}}<span i18n="Ellipsis for media asset URL">...</span>
        </div>
        <div *ngIf="mediaAsset.asset_type === 'video' && !videoUrlEdittable" id="video_url" class="wrapped-url">
          {{mediaAsset.url}}
        </div>
        <div *ngIf="mediaAsset.asset_type === 'video' && videoUrlEdittable" id="video_url" class="wrapped-url">
          <div
            [ngClass]="{'has-error':!formGroup.controls['video_url'].valid && formGroup.controls['video_url'].touched}">
            <input id="video-url-input" class="form-control" [formControl]="formGroup.controls['video_url']"
                   placeholder="Video URL" i18n-placeholder="Video URL placeholder text" spellcheck="false">
          </div>
        </div>
        <rn-copied-message [inputData]="getMediaAssetUrl()"></rn-copied-message>
        <br>
        <br>
        <p *ngIf="mediaAsset.asset_type === 'video' && videoUrlEdittable" class="youtube-instructions"
           i18n="Instructions for you tube video instructions">
          Enter the new URL of the YouTube video. This can be found by visiting the video in YouTube, clicking the Share
          button,
          and copying the URL shown, such as https://youtu.be/rxYykEb2anM.
        </p>
        <br>
      </div>

      <div class="footer-bar">
        <div class="pull-left">
          <div id="cancel-link" class="cancel-link" (click)="close()">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span i18n="Link to cancel experience test launch">Cancel</span>
          </div>
        </div>
        <div class="pull-right">
          <button id="save-btn" class="btn btn-primary" (click)="saveChanges()" [class.inactive]="!formGroup.valid"
                  [disabled]="!formGroup.valid">
            <span i18n="OK button to close dialog for adding media asset">Save Changes</span>
            <span class="icon icon-right"><i class="fa fa-floppy-o"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<message-dialog></message-dialog>
