import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ProductGroup } from '../../../core/models/client';
import { WireClass } from '../../../core/models/message';
import { WirePreviewComponent } from '../wire-preview/wire-preview.component';

@Component({
  selector: 'network-preview',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
    @Input() productGroup: ProductGroup;
    wire: WireClass;
    viewProductInfo: boolean = false;
    constructor() {
        
    }

    ngOnInit() {
        this.wire = new WireClass();
        this.wire.text = "";
        this.wire.actions = [];
    }

}
