import { EnvService } from '@app/core/services/env.service';
import { datadogRum } from '@datadog/browser-rum';

class Logger {
  file: string;
  data: any;
  date: string;
  constructor(file: string, data: any) {
    this.file = file;
    try {
      this.data = JSON.stringify(data);
    } catch (e) {
      this.data = data;
    }
    this.date = new Date().toISOString();
  }
}

export class LoggerService {
  static fgColor = '#3d593d';

  static log(file: string, data?: any) {
    if (EnvService.isDev()) {
      const log: Logger = new Logger(file, data);
      console.info(
        `%c → ${log.file}: ${log.date}\n${log.data}`,
        `color: ${this.fgColor}`,
      );
    }
    // using datadogRum directly here (instead of using analytics service) to avoid having to make this an instance method.
    // If datadogRum has not been initialized yet, this may not work.
    datadogRum.addUserAction(`logger.${file}`, { data: data });
  }
}
