<div>
  <app-trigger-selector *ngIf="journey"
                        [journey]="journey"
                        (triggerSelected)="selectTrigger($event)"
                        (selectionCanceled)="cancelSelectedTrigger()">
  </app-trigger-selector>

  <div class="launcher-content" *ngIf="journey">
    <div class="launcher-inner-content" *ngIf="journey && triggerInputParams">
      <ng-container *ngIf="!journeyLaunched">

        <div class="parameters-box form-group">
          <div *ngIf="enteredInputGroup" [formGroup]="enteredInputGroup">
            <ng-container *ngFor="let inputCtlName of triggerInputParams;">
              <div class="input-param">
                <div class="param-name">
                  {{inputCtlName === 'CCID' ? 'Account ID (CCID)' : inputCtlName }}
                </div>
                <input type="text" class="form-control" id="{{inputCtlName}}-input" [formControlName]="inputCtlName">
              </div>
            </ng-container>
          </div>

          <label for="client_message_tag">Client Message Tag (optional)</label>
          <input type="text" class="form-control" id="client_message_tag" [(ngModel)]="clientMessageTag">
        </div>
        <div class="bottom-buttons">
          <div class="pull-left">
            <div class="cancel-link" (click)="goBack()">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span i18n="Link to cancel experience test launch">Cancel</span>
            </div>
          </div>
          <div class="pull-right">
            <div class="btn btn-lg btn-warning launch-button inactive" id="launch-button" (click)="launchJourney()"
                 [class.inactive]="isLauncherButtonDisabled()">
              <span i18n="Button to test launch an experience">Launch</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </ng-container>
      <journey-launched *ngIf="journeyLaunched"
                        [launchResult]="launchResult"
                        [rightButtonLabel]="launchSuccessButtonLabel"
                        (rightButtonClick)="launchSuccessButtonClick()">
      </journey-launched>
    </div>
  </div>
</div>

<div class="list-popup" *ngIf="selectJourney">
  <app-cx-builder [selectionType]="'customer-list-launch'"></app-cx-builder>
</div>

<message-dialog></message-dialog>
