import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';
import { MediaService } from '@app/core/services/media.service';
import { MediaAsset } from '@app/core/models/media';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NavbarStateService } from '@app/core/services/navbar-state.service';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import {
  SearchableField,
  SearchBarComponent,
} from '@app/shared/search-bar/search-bar.component';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SessionService } from '@app/security/session.service';
import { SortableComponent } from '@app/core/sortable-component';
import { TitleService } from '@app/core/services/title.service';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { PageManager } from '@app/core/utils/page-manager';

@Component({
  selector: 'app-branding-list',
  templateUrl: './branding-list.component.html',
  styleUrls: ['./branding-list.component.scss'],
})
export class BrandingList
  extends SortableComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @ViewChild(SearchBarComponent, { static: true })
  searchBar: SearchBarComponent;
  brandingAssets: MediaAsset[] = [];
  pageManager: PageManager = new PageManager();
  searchCriteria: SearchCriteria;
  loaded = false;
  showAddAssetDialog = false;
  showAssetDetailsDialog = false;
  showUpdateAssetDialog = false;
  addMediaAssetType: string[] = ['brand'];
  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  showNoSearchFieldsMessage = false;
  offset = 0;
  permissions = Permissions;
  searchableFields = [
    new SearchableField('Display Name', 'shortname'),
    new SearchableField('Asset ID', 'asset_id'),
    new SearchableField('Updated By', 'created_by_name'),
  ];
  newMediaAsset: MediaAsset;
  mediaAssetToEdit: MediaAsset;

  constructor(
    private titleService: TitleService,
    public navbarStateService: NavbarStateService,
    public mediaService: MediaService,
    public sessionService: SessionService,
    public loadingMaskService: LoadingMaskService,
    private ps: PermissionService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.ps.checkPermissions(Permissions.media_asset_create)) {
      this.titleService.activate('Media & Branding');
    } else {
      this.titleService.activate('Media & Branding');
    }
    this.reloadBrandingAssets();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  private getBrandingAssets() {
    const offset = this.offset;
    this.mediaService
      .getAllBrandingAssets(
        this.limit,
        this.offset,
        this.ordering,
        this.searchCriteria,
        this.offset !== 0,
      )
      .subscribe({
        next: (branding) => {
          this.pageManager.addPage(offset, branding);
          this.brandingAssets = this.pageManager.flattenPages();
        },
        error: (error) => {
          if (error.status !== 401) {
            this.messageDialog.showMessage(
              'Ooops...there was error loading branding assets.',
            );
          }
        },
        complete() {
          this.loaded = true;
        },
      });
  }

  clearNewMediaAsset() {
    if (this.newMediaAsset) {
      if (this.searchCriteria) {
        this.searchBar.clearSearch();
      } else {
          this.brandingAssets.unshift(this.newMediaAsset);
        // Use an artificial page of offset -1 to hold newly created media assets
        let newAssetPage = this.pageManager.pages.get(-1);
        if (!newAssetPage) {
          newAssetPage = [];
        }
          newAssetPage.unshift(this.newMediaAsset);
        this.pageManager.pages.set(-1, newAssetPage);
      }

      this.newMediaAsset = undefined;
    }
  }

  fetchData() {
    this.pageManager = new PageManager();
    this.brandingAssets = [];
    this.loaded = false;
    this.offset = 0;
    this.getBrandingAssets();
  }

  onClearSearch() {
    this.showNoSearchFieldsMessage = false;
    this.searchCriteria = undefined;
    this.reloadBrandingAssets();
  }

  onRequestSearch(searchCriteria: SearchCriteria) {
    this.showNoSearchFieldsMessage = false;
    this.searchCriteria = searchCriteria;
    this.searchCriteria.branding = true;
    if (this.searchCriteria.searchFields.length === 0) {
      this.showNoSearchFieldsMessage = true;
      this.pageManager = new PageManager();
      this.brandingAssets = [];
      this.offset = 0;
    } else {
      this.reloadBrandingAssets();
    }
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getBrandingAssets();
  }

  openAddNewMediaAssetDialog() {
    this.addMediaAssetType = ['brand'];
    this.showAddAssetDialog = true;
  }

  onCloseAddMediaAssetDialog() {
    this.showAddAssetDialog = false;
  }
  onBrandingAssetClick(branding: MediaAsset) {
    if (this.ps.checkPermissions(Permissions.media_asset_edit)) {
      this.mediaAssetToEdit = Object.assign(new MediaAsset(), branding);
      this.showAssetDetailsDialog = true;
    }
  }

  onCloseMediaAssetDetailsDialog() {
    this.showAssetDetailsDialog = false;
    this.clearNewMediaAsset();
  }

  onCloseUpdateMediaAssetDialog() {
    this.showUpdateAssetDialog = false;
    this.showAssetDetailsDialog = true;
  }

  onUpdateRequest() {
    this.showAssetDetailsDialog = false;
    this.showUpdateAssetDialog = true;
  }

  onMediaAssetAdded(mediaAsset: MediaAsset) {
    this.newMediaAsset = mediaAsset;
    this.showAddAssetDialog = false;
    this.showAssetDetailsDialog = true;
    this.mediaAssetToEdit = Object.create(mediaAsset);
  }

  onMediaAssetDetailsUpdated(mediaAsset: MediaAsset) {
    this.showAssetDetailsDialog = false;
    this.clearNewMediaAsset(); // clearNewMediaAsset also adds NEW assets to the page manager.
    this.replaceMediaAsset(mediaAsset); // asset must exist before you try to replace it.  hence, gotta run this 2nd
  }

  replaceMediaAsset(mediaAsset: MediaAsset) {
    this.pageManager.forEachPage((page) => {
      const index = page.findIndex((m) => m.asset_id === mediaAsset.asset_id);
      if (index !== -1) {
        page[index] = mediaAsset;
      }
    });
    this.brandingAssets = this.pageManager.flattenPages();
  }

  reloadBrandingAssets() {
    this.loaded = false;
    this.pageManager = new PageManager();
    this.brandingAssets = [];
    this.offset = 0;
    this.getBrandingAssets();
  }
}
