<div class="inspector" [class.collapsed-size]="toolbarService.collapsed">
    <div class="header header-with-number">
        <span id="message-number" class="message-number">{{message.step}}</span>
        <span class="inspector-title" i18n="Header for message inspector">Message Step</span>
        <i class="fa fa-times clickable" aria-hidden="true" (click)="close.emit()"></i>
    </div>
    <div class="body">
        <div class="label" i18n="Label for message nickname">Nickname</div>
        <div id="edit-message-btn" class="btn btn-sm btn-primary edit" (click)="editMessage()">
            <span class="icon icon-left"><i class="fa fa-pencil" aria-hidden="true"></i></span>
            <span i18n="Label for message edit button">edit</span>
        </div>
        <div id="message-nickname" class="value">{{message.nickname}}</div>
        <div class="label">Component ID</div>
        <div id="message-id" class="value">{{message.name}}</div>
        <rn-copied-message [inputData]="message.name"></rn-copied-message>
        <hr>

        <div class="label">Contents</div>
        <div *ngFor="let action of message?.wire?.actions">
            <span class="value" i18n="Label for message action">message action - </span><span>{{action.label}}</span>
        </div>
        <br *ngIf="!message?.wire?.actions || message.wire.actions.length == 0">
        <br>

        <div>
            <div class="label" i18n="Label for experience notes">Notes</div>
            <div>
                <textarea id="journey-notes" [(ngModel)]="message.notes"></textarea>
            </div>
        </div>
    </div>
</div>
