import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelaySharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';

import { ApiKeyService } from '@app/api-mgmt/components/apikey-mgmt/services/api-key.service';

import { ApikeyMgmtComponent } from '@app/api-mgmt/components/apikey-mgmt/apikey-mgmt.component';
import { ApikeyItemComponent } from '@app/api-mgmt/components/apikey-mgmt/components/apikey-item/apikey-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiMgmtComponent } from '@app/api-mgmt/components/api-mgmt/api-mgmt.component';
import { PushApiComponent } from '@app/api-mgmt/components/push-api/components/push-api.component';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@NgModule({
  declarations: [
    ApikeyMgmtComponent,
    ApiMgmtComponent,
    ApikeyItemComponent,
    PushApiComponent,
  ],
  imports: [CommonModule, RelaySharedModule, MatTooltipModule, RouterModule],
  providers: [ApiKeyService, MessageDialogComponent],
})
export class ApiMgmtModule {}
