import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { Select, Value } from 'baseui/select';
import {
  ButtonCancelFormConfigOverride,
  ButtonConfigurationOverride,
  CheckboxCheckedOverride,
  CheckboxUncheckedOverride,
  DropdownContainerOverride,
  ModalHeaderOverride,
  PopoverOverrides,
  SelectFilterComponentsOverride,
  SelectIconsContainerOverride,
  SelectIconsOverride,
  SelectInputOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import {
  FormConfigRequest,
  RelayUnconfiguredForm,
} from '@app/client-config/client-form/client-power-ups-config/types/form-config';
import { FormConfigService } from '@app/client-config/client-form/client-power-ups-config/services/form-config-service';
import { useClientInfo } from '@app/client-config/client-form/client-power-ups-config/context/ClientInfoContext';
import { ConfigurationFooter } from '@app/client-config/client-form/client-power-ups-config/styles/ConfigurationModalStyles';
import { Button } from 'baseui/button';
import { ButtonIconRight } from '@app/react-baseweb/styled-containers/styledContainers';
import { Checkbox } from 'baseui/checkbox';
import { useStyletron } from 'baseui';

export const AddConfigurationModal = ({
  onSaveClicked,
  onCancelClicked,
}: {
  onSaveClicked: (newConfiguration: FormConfigRequest) => void;
  onCancelClicked: () => void;
}) => {
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [unconfiguredForms, setUnconfiguredForms] = useState<
    RelayUnconfiguredForm[]
  >([]);
  const [formOptions, setFormOptions] = useState<Value>([]);
  const [formValue, setFormValue] = useState<Value>([]);
  const [downloadPdfs, setDownloadPdfs] = useState(false);
  const [{ clientId }] = useClientInfo();
  const [css] = useStyletron();
  const formConfigService = new FormConfigService();

  useEffect(() => {
    async function fetchForms() {
      const data = await formConfigService.getUnconfiguredForms(clientId);
      setUnconfiguredForms(data);
      const dropdownOptions = [];

      data.forEach((value) => {
        value.title &&
          dropdownOptions.push({
            label: value.title,
            id: value.id,
          });
      });

      setFormOptions(dropdownOptions);
    }

    fetchForms();
  }, []);

  const setFormData = (value) => {
    setFormValue(value);

    if (value[0]?.id) {
      setSelectedForm(
        unconfiguredForms.find((form) => form.id === value[0].id),
      );
    } else {
      setSelectedForm(undefined);
    }
  };

  const getLabel = ({ option }: any) => {
    return (
      <React.Fragment>
        <i
          className="fa fa-check"
          style={
            option.label === formValue[0]?.label
              ? {
                  fontSize: '15px',
                  paddingRight: '10px',
                }
              : { fontSize: '15px', paddingRight: '10px', visibility: 'hidden' }
          }
        ></i>
        {option.label}
      </React.Fragment>
    );
  };

  const cancel = () => {
    // callback to close the modal
    onCancelClicked();
  };

  const checkCloseSource = (e?) => {
    // if user clicks outside of modal (the backdrop), the modal will not close
    if (e?.closeSource && e?.closeSource === 'backdrop') return;
    // otherwise, close modal
    onCancelClicked();
  };

  const onSubmit = async () => {
    if (selectedForm) {
      onSaveClicked({
        form_name: selectedForm.title,
        client_id: clientId,
        external_id: selectedForm.id,
        external_source: 'jotform',
        download_pdfs: downloadPdfs,
      });
    }
  };

  return (
    <Modal
      isOpen
      onClose={(e) => checkCloseSource(e)}
      overrides={{
        Dialog: {
          style: {
            marginTop: '75px',
            width: '45%',
          },
        },
      }}
    >
      <ModalHeader style={ModalHeaderOverride.style}>
        Add a Power-Ups Form Configuration
      </ModalHeader>
      <ModalBody>
        <div>
          Please choose and configure a Power-Ups form that has been published
          to this client folder in Jotform. A form can only be configured once.
        </div>
        <div
          className={css({
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '20px',
            marginBottom: '10px',
          })}
        >
          Power-Ups Form
        </div>
        <Select
          overrides={{
            ControlContainer: SelectFilterComponentsOverride,
            ClearIcon: {
              props: {
                overrides: {
                  Svg: SelectIconsOverride
                }
              }
            },
            SelectArrow: {
              props: {
                overrides: {
                  Svg: SelectIconsOverride,
                }
              }
            },
            IconsContainer: SelectIconsContainerOverride,
            Dropdown: DropdownContainerOverride,
            Popover: {
              props: {
                overrides: {
                  Inner: PopoverOverrides,
                  Body: PopoverOverrides,
                },
              },
            },
            DropdownListItem: DropdownContainerOverride,
            ValueContainer: SelectInputOverride,
          }}
          options={formOptions}
          value={formValue}
          placeholder="Choose a Form"
          onChange={({ value }) => setFormData(value)}
          getOptionLabel={getLabel}
        />
        <div className={css({ marginTop: '20px' })}>
          <Checkbox
            checked={downloadPdfs}
            onChange={(e) => {
              setDownloadPdfs((e.target as HTMLInputElement).checked);
            }}
            overrides={{
              Checkmark: downloadPdfs
                ? CheckboxCheckedOverride
                : CheckboxUncheckedOverride,
            }}
          >
            <span
              className={css({
                fontSize: '16px',
                fontWeight: '700',
                color: '#5B5A59',
              })}
            >
              Enable PDF creation
            </span>
            <div
              className={css({
                fontSize: '14px',
                fontWeight: '400',
                color: '#7D7D7D',
              })}
            >
              <i>Allow automatic creation of PDF files for form results.</i>
            </div>
          </Checkbox>
        </div>
        <ConfigurationFooter>
          <div style={{ float: 'right' }}>
            <Button
              onClick={cancel}
              kind="tertiary"
              overrides={{ Root: ButtonCancelFormConfigOverride }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSubmit()}
              overrides={{ BaseButton: ButtonConfigurationOverride }}
            >
              <span style={{ marginRight: '8px'}}>SAVE</span>
              <ButtonIconRight>
                <i className="fas fa-save"></i>
              </ButtonIconRight>
            </Button>
          </div>
        </ConfigurationFooter>
      </ModalBody>
    </Modal>
  );
};
