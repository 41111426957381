import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { SessionService } from '@app/security/session.service';
import { Client, ProductGroup } from '@app/core/models/client';
import { CustomerService } from '@app/core/services/customer.service';
import { CustomerLookupLaunchService } from '@app/core/services/customer-lookup-launch.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from '@app/core/utils/custom-validators';
import {
  VerificationFormBuilder,
  VerificationFormBuilderNotRequired,
} from '@app/core/models/validation-verification';
import { FormatUtils } from '@app/core/utils/format-utils';
import { Journey } from '@app/core/models/journey';
import { MessageClass } from '@app/core/models/message';
import { JourneyService } from '@app/core/services/journey.service';
import { Customer } from '@app/core/models/customer';
import { LoggerService } from '@app/core/services/logger.service';
import { Conversions } from '@app/core/utils/conversions';
import { WirePreviewService } from '@app/core/services/wire-preview.service';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import {
  VerificationFieldType,
  VerificationField,
} from '@app/core/models/validation-verification';
import * as _ from 'lodash';

@Component({
  selector: 'app-quick-launch-content',
  templateUrl: './quick-launch-content.component.html',
  styleUrls: ['./quick-launch-content.component.scss'],
})
export class QuickLaunchContentComponent implements OnInit {
  @Input() client: Client;
  @Input() validationModel: any = {};
  @Output() launch = new EventEmitter<[any, string]>();
  @ViewChild('consentMessage', { static: true })
  messageDialog: MessageDialogComponent;
  @ViewChild('validationMessage', { static: true })
  validationMessage: MessageDialogComponent;
  ccid: string;
  colors: string[] = this.clientService.selectJourneyColors;
  expressWrittenConsent = 'true';
  selectedJourneyId: string;
  validationGroup: UntypedFormGroup;
  _validationGroup: UntypedFormGroup;
  allValidationGroup: UntypedFormGroup;
  allValidationModel = [];
  allValidationFields: any;
  journeys: Journey[];
  message: MessageClass;
  screen: 'wire' | 'sms' = 'wire';
  preview: boolean;
  haveEligibleTriggers = false;
  hasOnlyArchived = false;
  bypassValidation: boolean;
  productGroup: ProductGroup;
  error: string;
  components: any;

  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private journeyService: JourneyService,
    private customerLookupService: CustomerLookupLaunchService,
    private wirePreviewService: WirePreviewService,
  ) {}

  ngOnInit() {
    if (!this.client) {
      this.client = this.sessionService.currentUser.client;
    } else {
      this.preview = true;
    }

    this.activeRoute.queryParams.subscribe(param => {
      if (param.hasOwnProperty('ccid')) {
        this.ccid = param['ccid'];
      }
      if (param.hasOwnProperty('telephone')) {
        this.validationModel.phone_number = param['telephone'];
        this.checkPhoneNumber();
      }
    });
    this.fetchJourneys();
  }

  selectJourney(journeyId): void {
    this.selectedJourneyId = journeyId;
    this.message = this.journeys.find(
      (j) => j.id === this.selectedJourneyId,
    ).live.components.find(
      (c) => c instanceof MessageClass,
    );
    this.components = this.journeys.find(
          (j) => j.id === this.selectedJourneyId,
        ).live.components;
    
    this.bypassValidation = this.journeys.find(
      (j) => j.id === this.selectedJourneyId,
    ).live.bypass_csr_validation;
    this.getProductGroup();
    this.initClientValidation();
  }

  /**
   * Checks the phone number validation
   * formats phone number
   */
  checkPhoneNumber() {
    if (this.validationGroup) {
      this.validationGroup.controls['phone_number']
        .patchValue(FormatUtils.formatPhoneNumber(this.validationGroup.controls['phone_number'].value));
    }
  }

  isValid(): boolean {
    if (this.expressWrittenConsent === 'true' && !this.bypassValidation) {
      return this.validationGroup.valid;
    } else if (this.expressWrittenConsent === 'true' && this.bypassValidation) {
      return this.allValidationGroup.valid && this.validationGroup.valid;
    } else {
      return !this.validationGroup.controls['ccid'].valid;
    }
  }

  locateCustomer() {
    if (this.expressWrittenConsent === 'true' && !this.bypassValidation) {
      // use validationGroup
      this.validationModel = this.validationGroup.getRawValue();
      this.validationModel['product_group_id'] = this.productGroup.id;
      this.customerService.locateCustomer(this.validationGroup.controls['ccid'].value, this.validationModel).subscribe(
          (customer) => this.validationSuccess(customer),
        (error: HttpErrorResponse) => this.validationFailure(error));
    } else if (this.expressWrittenConsent === 'true' && this.bypassValidation) {
      // use allValidationGroup
        Object.assign(this.validationModel, this.allValidationGroup.getRawValue());
      const customer = new Customer();
      customer.ccid = this.validationGroup.controls['ccid'].value;
      this.customerLookupService.customer = customer;
      this.launch.emit([this.validationModel, this.selectedJourneyId]);
    } else {
      this.customerService.registerCSRDecline(this.validationGroup.controls['ccid'].value, this.validationModel).subscribe(
        () => this.messageDialog.showMessage('Thank you - your screen will now be reset.  No information has been saved about this customer, and they will not be sent a message.'),
        () => this.messageDialog.showMessage(`There was an error updating the consent.`)
        );
    }
  }

  startOver() {
    this.router.navigateByUrl('/safe-redirect?redirect=/onboarding');
  }

  getProductGroup() {
    const pgId = this.journeys.find(j => j.id === this.selectedJourneyId).live.product_group;
    this.wirePreviewService.getDataFromIds(pgId, this.client.id).subscribe((data) => {
        this.productGroup = data.productGroup;
      });
  }

  private initClientValidation(): void {
    // build or rebuild form
    this.validationGroup = this.formBuilder.group({
      'ccid': [null, [Validators.required, CustomValidators.noSpacesInString]],
      'phone_number': [null, [Validators.required, CustomValidators.phoneNumber]],
      'consent': [this.expressWrittenConsent, Validators.required]
    });

    // preserve phone if we have it
    if (!this.validationModel.phone_number) {
      this.validationModel = {phone_number: ''};
    } else {
      this.validationModel = {phone_number: this.validationModel.phone_number};
    }
    // check for eligible triggers
    if (this.client.validation.enabled === 'true'
    && this.client.onboarding.csr.enabled === 'true'
    && this.client.onboarding.csr.eligible_triggers
    && this.client.onboarding.csr.eligible_triggers.length > 0) {
      this.haveEligibleTriggers = true;
    }

    // build form
    if (this.haveEligibleTriggers && !this.bypassValidation) {
      this.client.validation.fields.forEach(v => {
        this.validationModel[v.field] = '';
        this.validationGroup.addControl(v.field, new UntypedFormControl(null, VerificationFormBuilder[v.field].validators));
      });
    }

    this.validationGroup.valueChanges.subscribe((val) => {
      this.validationModel = this.validationGroup.getRawValue();
      this.ccid = this.validationGroup.get('ccid').value;
      if (this.validationGroup.get('ccid').value) {
        this.validationGroup
          .get('ccid')
          .patchValue(this.validationGroup.get('ccid').value.trim(), {
            emitEvent: false,
            onlySelf: true,
          });
      }
    });

    // preserve for resetting
    this._validationGroup = _.cloneDeep(this.validationGroup);
  }

  /**
   * return true if the fieldName is present in the fieldSet.
   *
   * @param fieldName the name of the field the markup wants to render
   * @param fieldSet should be either client.validation.fields or client.validation.information_fields,
   * depending on whether bypassValidation is true or not.
   */
  shouldShowField(fieldName: VerificationFieldType, fieldSet: VerificationField[]): boolean {
    return _.includes(_.map(fieldSet, field => field.field), fieldName);
  }

  private fetchJourneys(): void {
    this.error = undefined;
    const $sub = [];
    _.forEach(this.client.onboarding.csr.eligible_triggers, (id) => {
      $sub.push(this.journeyService.getJourneyById(id));
    });
    forkJoin($sub).subscribe(
      (res: Journey[]) => this.fetchJourneySuccess(res),
      (err) => {
        LoggerService.log('QuickLaunchComponent', err);
        this.error = 'There was an error fetching your experiences.';
      }
    );
  }

  private fetchJourneySuccess(journeys: Journey[]): void {
    this.journeys = journeys.filter(j => j.deleted !== true).sort((a, b) => a.live.name.toLowerCase() < b.live.name.toLowerCase() ? -1 : 1);
    if (this.journeys.length > 0) {
      this.selectedJourneyId = this.journeys[0].id;
      this.bypassValidation = this.journeys[0].live.bypass_csr_validation;
      this.initClientValidation();
      this.initAllValidation();
      this.getProductGroup();
    } else {
      this.hasOnlyArchived = true;
    }
  }

  private initAllValidation(): void {
    this.allValidationGroup = this.formBuilder.group({});
    this.clientService.getValidationFields().subscribe(
      fields => this.fetchValFieldsSuccess(fields),
      err => this.fetchValFieldsFailure(err)
    );
  }

  private fetchValFieldsSuccess(fields): void {
    this.allValidationFields = fields;
    _.forEach(fields, v => {
      this.allValidationModel.push({prompt: FormatUtils.formatInputField(Conversions.toString(v)), field: v});
      this.allValidationGroup.addControl(v, new UntypedFormControl(null, VerificationFormBuilderNotRequired[v].validators));
    });
  }

  private fetchValFieldsFailure(err): void {
    this.messageDialog.showMessage(`Oops...could not load input fields: ${err}`);
    LoggerService.log('ClientValidationAndVerificationComponent', `getInputFields() error: ${err}`);
  }

  private validationSuccess(customer: Customer): void {
    this.customerLookupService.customer = customer;
    this.launch.emit([this.validationModel, this.selectedJourneyId]);
  }

  private validationFailure(error: HttpErrorResponse): void {
    if (error.error && error.error.reason) {
      const reason = error.error.reason;
      this.validationMessage.showMessage(reason);
      if (reason.indexOf('The following fields did not match:') > -1) {
        const fields = reason.split(': ')[1];
        const fieldsArr = fields.split(',');
        fieldsArr.forEach(c => {
          this.validationGroup.controls[c.replace(/-/g, '_').trim()].setErrors(() => 'validation failed');
        });
      }
    } else {
      LoggerService.log('QuickLaunchContentComponent', `validationFailure() error: ${error}`);
      LoggerService.log('QuickLaunchContentComponent', `validationFailure() error message: ${error.message}`);
    }
  }
}
