<div class="progress-tabs">
  <ul class="progress-tabs__list">
    <li *ngFor="let tab of tabs" [id]="tabIdAttr(tab.id)" class="progress-tabs__item" [class.progress-tabs--current]="isActive(tab.id)" (click)="triggerTabClicked(tab.id)">
      <div class="progress-tabs__tab" [class.progress-tabs__tab--clickable]="clickable">
        <span class="progress-tabs__tab-text">
          {{tab.text}}
        </span>
      </div>
    </li>
  </ul>
</div>
