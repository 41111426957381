import { RelaySharedModule } from '../shared/shared.module';
import { JourneyListComponent } from './journey-list/journey-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AllJourneysComponent } from './all-journeys/all-journeys.component';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { JourneyDetailsComponent } from './journey-details/journey-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JourneyStepPreviewComponent } from './journey-step-preview/journey-step-preview.component';
import { JourneySummaryComponent } from './journey-summary/journey-summary.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    InfiniteScrollModule,
    RelaySharedModule,
    ButtonsModule.forRoot(),
    MatTooltipModule,
  ],
  declarations: [
    JourneyListComponent,
    AllJourneysComponent,
    JourneyDetailsComponent,
    JourneyStepPreviewComponent,
    JourneySummaryComponent,
  ],
  exports: [JourneyListComponent, JourneyDetailsComponent],
})
export class JourneyListModule {}
