import React from 'react';
import { Button } from 'baseui/button';

interface ActionItemProps {
  text: string;
  color?: string;
  onClick?: () => void;
  dataTestId?: string;
}

export const ActionItem = ({
  text,
  color,
  onClick,
  dataTestId,
}: ActionItemProps) => {
  return (
    <Button
      onClick={onClick}
      overrides={{
        BaseButton: {
          style: () => ({
            width: '100%',
            display: 'block',
            color,
            backgroundColor: 'transparent',
            textAlign: 'left',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            fontSize: '12px',
            outline: 'none',
            ':hover': {
              backgroundColor: '#E1EFF5',
            },
            ':active': {
              backgroundColor: 'transparent',
            },
          }),
          props: {
            'data-testid': dataTestId,
          },
        },
      }}
    >
      {text}
    </Button>
  );
};
