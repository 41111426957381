<ng-container *ngIf="!selectedTrigger">
  <p class="trigger-selector-instructions" i18n="Trigger selection instructions">
    Choose a starting point to launch:
  </p>
  <div *ngFor="let trigger of triggers; let i = index">
    <div id="trigger-{{trigger.name}}" (click)="selectTrigger(event, trigger)" class="trigger">
      <ng-container #lightingBolt1>
        <img class="icon-image" src="/assets/hex/hex-cloud.png">
      </ng-container>
      <div class="trigger-details">
        <span class="trigger-name">
            <ng-container *ngIf="trigger.step">
                {{trigger.step}} -
            </ng-container>
            <span id="nickname-{{trigger.name}}">{{trigger.nickname ? trigger.nickname : 'unknown' }}</span>
        </span>
        <br>
        <div id="trigger-id-{{trigger.name}}">{{trigger.name}}</div>
        <div *ngIf="!!trigger.nickname" id="trigger-id-{{trigger.nickname}}">
          <strong>Nickname: </strong>{{trigger.nickname}}</div>
        <div *ngIf="!!trigger.client_trigger_id" id="trigger-id-{{trigger.client_trigger_id}}">
          <strong>Client Trigger ID: </strong>{{trigger.client_trigger_id}}</div>
      </div>
      <hr>
    </div>
  </div>
  <div class="journey-info">
    <div i18n="Experience info heading on launcher" class="journey-info-heading">Experience Info</div>
    <hr>
    <p>
      <span i18n="Experience launcher 'created by' label" class="journey-info-label">Created by:</span>
      <span id="created-by" class="journey-info-data">{{journey?.created_by_name}}</span>
    </p>
    <p>
      <span i18n="Experience launcher 'date created' label" class="journey-info-label">Date created:</span>
      <span id="date-created" class="journey-info-data">{{journey?.created_at | date:'shortDate'}}</span>
    </p>
    <p>
      <span i18n="Experience launcher 'date modified' label" class="journey-info-label">Date modified:</span>
      <span id="date-modified" class="journey-info-data">{{journey?.updated_at | date:'shortDate'}}</span>
    </p>
    <p>
      <span i18n="Experience launcher 'product group' label" class="journey-info-label">Product group:</span>
      <span id="product-group" class="journey-info-data">{{journey?.live?.product_group_name}}</span>
    </p>
    <p>
      <span i18n="Experience launcher 'campaign' label" class="journey-info-label">Campaign:</span>
      <span id="campaign" class="journey-info-data">{{journey?.live?.campaign}}</span>
    </p>
    <p>
      <span i18n="Experience launcher 'description' label" class="journey-info-label">Description:</span>
      <span id="description" class="journey-info-data">{{journey?.live?.description}}</span>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="selectedTrigger">
  <div id="selected-trigger">
    <i id="go-back-icon" class="fa fa-caret-left fa-2x" aria-hidden="true" (click)="cancelSelection()"></i>
    <i id="close-icon" class="fa fa-times fa-lg" aria-hidden="true" (click)="cancelSelection()"></i>
    <ng-container #lightingBolt2>
      <img class="icon-image" src="/assets/hex/hex-cloud.png">
    </ng-container>
    <div class="trigger-details">
            <span class="trigger-name">
                <ng-container *ngIf="selectedTrigger.step">
                    {{selectedTrigger.step}} -
                </ng-container>
                <span id="nickname-{{selectedTrigger.name}}">{{selectedTrigger.nickname ? selectedTrigger.nickname : 'unknown' }}</span>
            </span>
      <br>
      <span id="trigger-id-{{selectedTrigger.name}}">{{selectedTrigger.name}}</span>
    </div>
    <hr>
  </div>
  <div class="phone-container" *ngIf="message">
    <div *ngIf="message.sms.is_shown === 'true'" class="bubble">
      {{getSMSText()}}
    </div>
    <ng-container *ngIf="message.wire.is_shown === 'true'">
      <div *ngIf="journey.live.product_group === 'all' && !message.wire.branding">
        <phone [screen]="'wire'" [message]="message"
               [productGroup]="allProductGroup"
               [components]="journey.live.components"></phone>
      </div>
      <div *ngIf="!(journey.live.product_group === 'all' && !message.wire.branding)">
        <phone [screen]="'wire'" [message]="message"
               [productGroup]="productGroup"
               [components]="journey.live.components"></phone>
      </div>
    </ng-container>
  </div>
</ng-container>
