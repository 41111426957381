<client-header [allowExpand]="false" [headerLabel]="'Add an SFTP Key'"></client-header>
<div class="sftp-mgmt-layout">
    <div class="sftp-mgmt-layout__item">
        <form (ngSubmit)="saveKey()">
            <div>
                <label>SSH key contents:</label>
                <textarea [class.sftp-mgmt__has-error]="sshKeyCtl.invalid && sshKeyCtl.touched"
                          [formControl]="sshKeyCtl"
                          [placeholder]="sshKeyFieldPlaceholder"
                          class="sftp-mgmt__key-textarea" rows="12"></textarea>
                <p *ngIf="sshKeyCtl.invalid && sshKeyCtl.touched && !sshKeyCtl.value" class="sftp-mgmt__error-msg">This
                    field is required</p>
                <p *ngIf="sshKeyCtl.invalid && sshKeyCtl.touched && sshKeyCtl.value" class="sftp-mgmt__error-msg">
                    Invalid
                    SSH key format</p>
                <div>
                    <button (click)="handleCancelClicked()" class="btn btn-default cancel-btn"
                            id="cancel-sftp-key-btn">
                        <span class="btn-label">Cancel</span>
                    </button>
                    <button [disabled]="sshKeyCtl.invalid && sshKeyCtl.touched" class="btn btn-primary upload-btn"
                            id="save-sftp-key-btn"
                            type="submit">
                        <span class="btn-label">Upload</span>
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="sftp-mgmt-layout__item">
        <rn-sftp-key-upload-instructions [client]="client"></rn-sftp-key-upload-instructions>
    </div>
</div>
