<app-search-bar
  [isFixed]="false"
  [searchableFields]="searchableFields"
  (onRequestSearch)="onRequestSearch($event)"
  (onClearSearch)="onClearSearch()"
  [isMediaLibrary]="true"
>
  <div class="add-branding-action" (click)="openAddNewMediaAssetDialog()">
    <div class="btn btn-primary">
      <span class="icon icon-right"><i class="fa fa-plus-circle"></i></span
      ><span class="button-text">Add New Branding</span>
    </div>
  </div>
</app-search-bar>

<div class="container-fluid branding-list">
  <div class="row colhdrs">
    <div
      id="branding-file"
      class="colw colw-15 column-heading no-text-selection"
      (click)="onColumnHeadingClick('shortname')"
    >
      <span i18n="Branding library list asset heading">Asset</span>
    </div>
    <div
      id="branding-name"
      class="colw colw-18 column-heading no-text-selection"
      (click)="onColumnHeadingClick('shortname')"
    >
      <span
        i18n="Branding library list file heading"
        class="colw colw-18 column-heading no-text-selection"
        >Display Name</span
      >
      <app-column-sort-icon
        id="sort-icon-branding-file"
        [ordering]="ordering"
        [fieldName]="'shortname'"
      ></app-column-sort-icon>
    </div>
    <div
      id="branding-color"
      class="colw colw-22 column-heading no-text-selection"
      (click)="onColumnHeadingClick('created_by_name')"
    >
      <span i18n="branding library list created by heading">Brand Color</span>
    </div>
    <div
      id="branding-asset-id"
      class="colw colw-20 column-heading no-text-selection"
      (click)="onColumnHeadingClick('asset_type')"
    >
      <span i18n="branding library list asset type heading">Asset Id</span>
    </div>
    <div
      id="branding-date-modified"
      class="colw colw-22 column-heading no-text-selection"
      (click)="onColumnHeadingClick('updated_at')"
    >
      <span i18n="branding library list date modified heading"
        >Last Modified</span
      >
      <app-column-sort-icon
        id="sort-icon-branding-updated-at"
        [ordering]="ordering"
        [fieldName]="'updated_at'"
      ></app-column-sort-icon>
    </div>
  </div>

  <ng-container *ngIf="brandingAssets">
    <div
      class="infinite-scroll"
      data-infinite-scroll
      debounce
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown()"
    >
      <div
        class="row branding-asset"
        *ngFor="let branding of brandingAssets"
        (click)="onBrandingAssetClick(branding)"
        [id]="'row-' + branding.asset_id"
      >

      <div class="colw colw-15">
          <div class="colw thumbnail-width">
            <img
              *ngIf="
                branding.asset_type === 'image' ||
                branding.asset_type === 'brand' ||
                branding.asset_type === 'banner'
              "
              class="branding-thumbnail"
              src="{{ branding.getS3UrlCacheBuster() }}"
            />
          </div>
        </div>
        <div class="colw colw-18" id="short-name">
          {{ branding.shortname }}
        </div>
        <div class="colw colw-22 color-class">
          <div
            class="color color-class"
            [ngStyle]="{ 'background-color': branding.branding_color}"
            ></div>
            <div class="color-class color-hex-code">{{branding.branding_color || 'N/A'}}</div>
          </div>
        <div class="colw colw-20">
          <div class="branding-asset__copy-url-col">
            <rn-copied-message
              [copyFromElement]="true"
              [customBtnText]="'Copy Asset ID to Clipboard'"
              [inputData]="branding.asset_id"
            ></rn-copied-message>
          </div>
          <div>
            <input
              class="branding-url"
              id="{{ branding.asset_id }}"
              value="{{ branding.asset_id }}"
            />
          </div>
        </div>

        <div class="colw colw-16" id="updated-at">
          {{ branding.updated_at | date: 'short' }}
          <p>By: {{ branding.updated_by }}</p>
        </div>
        <i class="fa fa-pencil"></i>
      </div>
    </div>
  </ng-container>
</div>
<div
  *ngIf="brandingAssets.length == 0 && !showNoSearchFieldsMessage"
  class="not-found"
  i18n="
    Message that the user gets when there are no branding assets to show in the
    media library
  "
>
  There were no branding assets found.
</div>
<div
  *ngIf="showNoSearchFieldsMessage"
  class="not-found"
  i18n="
    Message that the user gets when they tried to search with no search fields
    selected
  "
>
  There were no branding assets found. Please select at least one search field
  and retry.
</div>

<add-media-asset *ngIf="showAddAssetDialog"
  [clientId]="this.sessionService.currentUser.client.id"
  [assetTypes]="['brand']"
  [createBrand]="true"
  [maxFileSize]="1048576"
  (onSave)="onMediaAssetAdded($event)"
  (onClose)="onCloseAddMediaAssetDialog()">
</add-media-asset>

<!--  This instance of the add-media-asset component is for uploading new image content to an existing brand asset -->
<add-media-asset *ngIf="showUpdateAssetDialog"
    [assetToUpdate]="mediaAssetToEdit"
    [clientId]="this.sessionService.currentUser.client.id"
    [assetTypes]="['brand']"
    [maxFileSize]="1048576"
    (onSave)="onMediaAssetUpdated($event)"
    (onClose)="onCloseUpdateMediaAssetDialog()">
</add-media-asset>

<edit-media-asset-details *ngIf="showAssetDetailsDialog"
  [editBrand]="true"
  [mediaAsset]="mediaAssetToEdit"
  (onSave)="onMediaAssetDetailsUpdated($event)"
  (onClose)="onCloseMediaAssetDetailsDialog()"
  (onUpdateRequest)="onUpdateRequest()">
</edit-media-asset-details>

<message-dialog></message-dialog>