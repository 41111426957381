import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { VideoUtils } from '@app/core/utils/video-utils';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})

export class VideoComponent implements OnInit, OnChanges {
  @Input() videoUrl: string;
  @Input() showUrl?: boolean = false;
  @Input() width?: string = '';
  @Input() height?: string = '';

  videoUtils = VideoUtils;  
  wistiaRegex: RegExp = VideoUtils.wistiaRegex;
  
  constructor() {}

  ngOnInit() {
    if (this.isWistiaUrl()) {
      this.injectWistiaHeadScript();
    }
  }

  ngOnChanges() {
    if (this.isWistiaUrl()) {
      this.injectWistiaHeadScript();
    }
  }

  videoSrc() {
    return this.videoUtils.convertToEmbeddedFormat(this.videoUrl);
  }

  /* 
    Gross, but better then including the tag on every pageload 
  */
  injectWistiaHeadScript() {
    if (!document.getElementById('#wistia-script-tag')) {
      const wistiaScriptTag = document.createElement('script');
      const headTag = document.querySelectorAll('head')[0];
      wistiaScriptTag.id = '#wistia-script-tag';
      wistiaScriptTag.type = 'text/javascript';
      wistiaScriptTag.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScriptTag.charset = 'ISO-8859-1';
      headTag.appendChild(wistiaScriptTag);
    }
  }

  isWistiaUrl(): boolean {
    return this.wistiaRegex.test(this.videoUrl);
  }

  wistiaVideoId(): string {
    return this.wistiaRegex.exec(this.videoUrl)[1];
  }

  getWistiaVideoClasses(): string {
    return `wistia_embed videoFoam=true wistia_async_${this.wistiaVideoId()}`;
  }
}
