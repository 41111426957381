<div id="login-page-content">
  <div>
    <img id="relay-logo" src="/assets/relay-logo-full.png">
  </div>
  <div *ngIf="!ssoScreen" id="login-form-and-help">
    <form id="login-form">
      <ng-container *ngIf="!qrCode && !isTotp">
        <p id="instructions" i18n="Password prompt on login page">Please enter your email address and password below</p>
        <p *ngIf="error" id="login-failure">
          <i class="fa fa-exclamation-circle"></i>
          <span class="login-failure-msg" i18n="Login failure message">{{error}}</span>
        </p>
        <div class="input-with-icon"
              [ngClass]="{'has-error':!loginFormGroup.controls['email_address'].valid && loginFormGroup.controls['email_address'].touched}">
          <input id="user-name-input" class="form-control" placeholder="Email Address"
                  i18n-placeholder="Placeholder text for username field on login page"
                  [formControl]="loginFormGroup.controls['email_address']"
                  (keypress)="checkForEnter($event)">
          <i class="fa fa-user"></i>
        </div>
        <div class="input-with-icon"
              [ngClass]="{'has-error':!loginFormGroup.controls['password'].valid && loginFormGroup.controls['password'].touched}">
          <input id="password-input" type="password" class="form-control" placeholder="Password"
                  i18n-placeholder="Placeholder text for password field on login page"
                  [formControl]="loginFormGroup.controls['password']"
                  (keypress)="checkForEnter($event)">
          <i class="fa fa-lock"></i>
        </div>
        <button class="submit-login btn" (click)="attemptLogin()" *ngIf="!requestInFlight">
          <div class="submit-label" i18n="Label for submit login information button">Submit</div>
          <span class="icon icon-right">
            <i class="fa fa-arrow-right"></i>
          </span>
        </button>
        <br>
        <p class="generic-link" routerLink="/forgot-password">Reset Password</p>
        <div class="login-spinner" *ngIf="requestInFlight">
          <app-spinner></app-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="qrCode || isTotp">
        <p id="instructions" i18n="Multifactor code prompt on login page">
          {{isTotp ? 'Enter your sign in code below.' : 'Please setup your authenticator application by scanning the QR code and then entering the provided code below.'}}
        </p>
        <img id="mfa-qr-img-code" src="{{qrCode}}" *ngIf="qrCode"/>
        <div class="input-with-icon"
          [ngClass]="{'has-error':!mfaCodeFormControl.valid && mfaCodeFormControl.touched}">
          <input id="mfacode-input" type="text" 
            class="form-control" placeholder="Enter Code"
            autocomplete="off"
            i18n-placeholder="Placeholder text for multifactor code field on login page"
            [formControl]="mfaCodeFormControl"
            autofocus
          >
          <!-- Only visible (controlled by css in "_login.scss") when the input is invalid (wrong code or empty)  -->
          <span id="mfa-invalid-code-msg">
            <i class="fa fa-exclamation-circle"></i>
            {{actionService.getTooltipData(mfaCodeFormControl['errors'])}}
          </span>
          <!--  -->
        </div>
        <button class="submit-login btn" type="submit" (click)="attemptVerify()" *ngIf="!requestInFlight">
          <div class="submit-label" i18n="Label for submit login information button">Submit</div>
          <span class="icon icon-right">
            <i class="fa fa-arrow-right"></i>
          </span>
        </button>
        <div class="login-spinner" *ngIf="requestInFlight">
          <app-spinner></app-spinner>
        </div>
      </ng-container>
    </form>
  </div>

  <div *ngIf="ssoScreen" id="login-form-and-help">
    <div id="login-form">
      <p id="instructions" i18n="Password prompt on login page">Logging in through SSO for client {{clientId}}</p>
      <div *ngIf="!ssoError" class="login-spinner">
        <app-spinner></app-spinner>
      </div>
      <div *ngIf="ssoError" class="login-spinner">
        {{ssoError}}
      </div>
    </div>
  </div>
  

  <div id="welcome-text" i18n="Welcome text for login prompt">
    Welcome to CX builder<span>&trade;</span>
  </div>
</div>
