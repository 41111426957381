import { Injectable } from '@angular/core';
import { SftpKey } from '@app/sftp-mgmt/models/sftp-key';
import { SftpUser } from '@app/sftp-mgmt/models/sftp-user';

import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';

// Type for easier mocking in specs
export interface SftpMgmtServiceT {
  setup?(clientId: string): Observable<{message: string}>
  getKeys?(clientId: string): Observable<SftpKey[]>
  createKey?(clientId: string, ssh2Key: string): Observable<{message: string}>
  getUser?(clientId: string): Observable<SftpUser[]>
  createUser?(clientId: string): Observable<SftpUser>
  deleteKey?(clientId: string, keyId: number): Observable<{}>
}

@Injectable()
export class SftpMgmtService {

  constructor(
    private secureHttp: SecureHttp
  ) {}

  setup(clientId: string): Observable<{message: string}> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp`;
    return this.secureHttp.post(url, {});
  }

  getKeys(clientId: string): Observable<SftpKey[]> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp/keys`;
    return this.secureHttp.get(url, {});
  }

  createKey(clientId: string, ssh2Key: string): Observable<{message: string}> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp/keys`;
    return this.secureHttp.post(url, { ssh2Key: ssh2Key });
  }

  getUser(clientId: string): Observable<SftpUser[]> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp/users`;
    return this.secureHttp.get(url, {});
  }

  createUser(clientId: string): Observable<SftpUser> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp/users`;
    return this.secureHttp.post(url, {});
  }

  deleteKey(clientId: string, keyId: number): Observable<{}> {
    const url = `${environment.adminUrlBase}/client/${clientId}/sftp/keys`;
    return this.secureHttp.delete(url, { body: {keyId: keyId }});
  }
}
