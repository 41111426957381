import { MessageClass, MessageDelayerClass, WorkflowClass } from './message';

export class Journey {
  client_id?: string;
  is_active: boolean = true;
  id: string;
  created_by: string = '';
  created_by_name: string = '';
  created_at: Date = new Date();
  updated_at: Date = new Date();
  live: JourneyContent;
  live_version: number;
  draft: JourneyContent;
  draft_version: number;
  deleted: boolean;

  static deserialize(input: Object) {
    const journey = new Journey({showSmsByDefault: true, meta_data: null}); // showSmsByDefault will be overwritten on the child components below
    journey.client_id = input['client_id'];
    journey.is_active = input['is_active'];
    journey.created_by = input['created_by'];
    journey.created_by_name = input['created_by_name'];
    journey.created_at = new Date(input['created_at']);
    journey.updated_at = new Date(input['updated_at']);
    journey.id = input['id'];
    journey.draft_version = input['draft_version'];
    journey.live_version = input['live_version'];
    journey.deleted = input['deleted'] ? input['deleted'] : false;

    journey.live = undefined;
    if (input['live']) {
      journey.live = new JourneyContent().deserialize(input['live']);
    }

    journey.draft = undefined;
    if (input['draft']) {
      journey.draft = new JourneyContent().deserialize(input['draft']);
    }
    return journey;
  }

  /**
   * Creates new Journeys.
   * @param showSmsByDefault - ensures we default the sms.is_shown according to client details. If at all possible, should be pulled from client config, or use true.
   * @param meta_data
   */
  constructor({showSmsByDefault, meta_data = null}: { showSmsByDefault: boolean, meta_data: JourneyContent }) {
    const content = new JourneyContent();
    if (meta_data) {
      content.name = meta_data.name;
      content.product_group = meta_data.product_group;
      content.product_group_name = meta_data.product_group_name;
      content.product_group_internal_name = meta_data.product_group_internal_name;
      content.description = meta_data.description;
      content.client_id = meta_data.client_id;
      content.campaign = meta_data.campaign;
      content.bypass_csr_validation = meta_data.bypass_csr_validation;
    }
    content.components = [];
    const workflowEnder = new WorkflowClass('WorkflowEnder', null);
    const messageClass = new MessageClass({showSmsByDefault}); // creates .wire and .sms
    messageClass.to = workflowEnder.name;
    messageClass.wire.product_group = meta_data ? meta_data.product_group_name : 'default';
    const workflowStarter = new WorkflowClass('APITrigger', messageClass.name);
    workflowEnder.family = workflowStarter.name;
    content.components.push(workflowStarter, messageClass, workflowEnder);
    this.draft = content;
    this.live = content;
  }

  /**
   * Get the latest saved content regardless of whether it's draft or live content.
   */
  get latestContent() {
    if (this.draft) {
      return this.draft;
    } else {
      return this.live;
    }
  }
}

export class JourneyContent {
  /**
   * @type {MessageClass | MessageDelayerClass | WorkflowClass}
   */
  components: any[] = [];
  input_parameters: InputParameter[] = [];
  updated_at = new Date();
  created_at = new Date();
  name: string;
  product_group: string;
  product_group_name: string;
  product_group_internal_name: string;
  client_id: string; // TODO: Talk with Nick in order to get this property moved to the top level
  campaign: string;
  description: string;
  notes: string;
  bypass_csr_validation: boolean = false;
  labels: string[];

  serialize(): Object {
    const obj = JSON.parse(JSON.stringify(this));

    // Convert strings with 'true' or 'false' to actual booleans.
    for (let i = 0; i < this.components.length; i++) {
      const component = this.components[i];
      if ((component.type === 'MessageSender') || (component.type === 'MessageResponse')) {
        obj.components[i] = (component as MessageClass).serialize();
      }
      obj.components[i].showAction = undefined;
    }
    return obj;
  }

  public deserialize(input: Object) {
    const journeyContent = new JourneyContent();

    function getComponents(compParent) {
      const components = [];
      if (compParent.components) {
        for (let i = 0; i < compParent.components.length; i++) {
          if (compParent.components[i]) {
            if (compParent.components[i].type === 'MessageSender' || compParent.components[i].type === 'MessageResponse') {
              components.push(MessageClass.deserialize(compParent.components[i]));
            } else if (compParent.components[i].type === 'WorkflowDelayer') {
              components.push(MessageDelayerClass.deserialize(compParent.components[i]));
            } else {
              components.push(WorkflowClass.deserialize(compParent.components[i]));
            }
          }
        }
      }
      return components;
    }

    journeyContent.name = input['name'];
    journeyContent.product_group = input['product_group'];
    journeyContent.product_group_name = input['product_group_name'];
    journeyContent.product_group_internal_name = input['product_group_internal_name'] ? input['product_group_internal_name'] : input['product_group_name'];
    journeyContent.campaign = input['campaign'];
    journeyContent.description = input['description'];
    if (input['updated_at']) {
      journeyContent.updated_at = new Date(input['updated_at']);
    }
    if (input['created_at']) {
      journeyContent.created_at = new Date(input['created_at']);
    }
    journeyContent.notes = input['notes'];
    journeyContent.components = getComponents(input);
    journeyContent.bypass_csr_validation = input['bypass_csr_validation'] ?? false;
    journeyContent.labels = input['labels'] || [];
    journeyContent.input_parameters = [];

    if (input['input_parameters']) {

      for (let i = 0; i < input['input_parameters'].length; i++) {

        if (input['input_parameters'][i]['type'] === 'string') {
          journeyContent.input_parameters.push(InputParameter.deserialize(input['input_parameters'][i]));
        } else if (input['input_parameters'][i]['type'] === 'select') {
          journeyContent.input_parameters.push(SelectInputParameter.deserialize(input['input_parameters'][i]));
        }
      }
    }
    return journeyContent;
  }
}

export class InputParameter {
  name: string;
  type: string;
  required: boolean;

  static deserialize(input: Object) {
    const inputParameter = new InputParameter();
    inputParameter.name = input['name'];
    inputParameter.type = input['type'];
    inputParameter.required = input['required'];
    return inputParameter;
  }
}

export class SelectInputParameter extends InputParameter {
  options: Array<string>;
  placeholder: string;

  static deserialize(input: Object) {
    const selectInputParameter = new SelectInputParameter();
    selectInputParameter.name = input['name'];
    selectInputParameter.type = input['type'];
    selectInputParameter.required = input['required'];
    selectInputParameter.options = input['options'];
    selectInputParameter.placeholder = input['placeholder'];
    return selectInputParameter;
  }
}
