import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss']
})
export class ClientHeaderComponent implements OnInit {
  @Input() headerLabel: string = null; // the actual text in the header
  @Input() headerGroup: string = null; // this value will be emitted out to parent components listening to the open/close/toggle emitters
  @Input() allowExpand: boolean = true; // set to false to disallow dropdown and just have the heading
  @Input() showElement: boolean = true; // set to false to close dropdown initially

  @Output() openHeader: EventEmitter<any> = new EventEmitter();
  @Output() closeHeader: EventEmitter<any> = new EventEmitter();
  @Output() toggleHeader: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  formatLabelID(label: string): string {
    label = label.toLowerCase().replace(/\s/ig, '-') + '-header';
    return label;
  }

  toggleShowElement(): void {
    this.showElement = !this.showElement;

    this.toggleHeader.emit(this.headerGroup);

    if (this.showElement) {
      this.openHeader.emit(this.headerGroup);
    } else {
      this.closeHeader.emit(this.headerGroup);
    }
  }
}
