import React, { useEffect, useState } from 'react';
import { InputTitle } from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import { Input } from 'baseui/input';
import {
  InputOverride,
  InputRootOverride,
  InputToggleOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { StatefulTooltip } from 'baseui/tooltip';

export const InputBox = ({
  title,
  value,
  subtitle,
  placeholder,
  onChange,
  validate,
  hideText,
  isOptional,
  tooltipText,
  readOnly,
  preValidate,
}: {
  title: string;
  value: string;
  subtitle?: string;
  placeholder: string;
  onChange: (value: string) => void;
  validate?: (value: string) => string;
  hideText?: boolean;
  isOptional?: boolean;
  tooltipText?: string;
  readOnly?: boolean;
  preValidate?: boolean;
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  hideText = hideText ?? false;
  isOptional = isOptional ?? false;
  tooltipText = tooltipText ?? undefined;
  subtitle = subtitle ?? '';
  preValidate = preValidate ?? false;

  async function updateField(value: string) {
    if (validate !== undefined) {
      setErrorMessage(validate(value));
    }
    onChange(value);
  }

  useEffect(() => {
    if (preValidate) {
      updateField(value);
    }
  }, []);

  return (
    <>
      <InputTitle>
        <label>{title}</label>
        {isOptional && <span style={{ fontSize: '16px' }}> (Optional)</span>}
        {tooltipText && (
          <StatefulTooltip
            accessibilityType={'tooltip'}
            content={tooltipText}
            showArrow
            placement="top"
          >
            <i
              className="fa fa-question-circle"
              style={{ fontSize: '16px', marginLeft: '10px', color: '#908E8F' }}
            ></i>
          </StatefulTooltip>
        )}
        {subtitle && <span style={{ fontSize: '16px' }}> ({subtitle})</span>}
      </InputTitle>
      <Input
        readOnly={readOnly}
        overrides={{
          Root:
            errorMessage === ''
              ? InputRootOverride
              : {
                style: {
                  ...InputRootOverride.style,
                  borderTopColor: '#AD3B22',
                  borderRightColor: '#AD3B22',
                  borderBottomColor: '#AD3B22',
                  borderLeftColor: '#AD3B22',
                },
              },
          Input:
            errorMessage === ''
              ? InputOverride
              : {
                style: { ...InputOverride.style },
              },
          MaskToggleButton:
            errorMessage === ''
              ? InputToggleOverride
              : {
                style: {
                  color: 'black',
                },
              },
        }}
        type={hideText ? 'password' : 'text'}
        value={value}
        onChange={(events) =>
          updateField((events.target as HTMLButtonElement).value)
        }
        placeholder={placeholder}
        onBlur={(events) =>
          updateField((events.target as HTMLButtonElement).value)
        }
      />
      {(errorMessage !== '') && (
        <>
          <i
            className="fa fa-exclamation-circle"
            style={{
              fontSize: '14px',
              marginRight: '5px',
              color: '#AD3B22',
              display: 'inline-block',
            }}
          ></i>
          <div
            style={{
              marginBottom: '10px',
              display: 'inline-block',
              fontStyle: 'italic',
              color: '#AD3B22',
            }}
          >
            {errorMessage}
          </div>
        </>
      )}
    </>
  );
};
