import { Component, Input } from '@angular/core';
import { Client, ProductGroup } from '@app/core/models/client';

@Component({
  selector: 'app-password-preview',
  templateUrl: './password-preview.component.html',
  styleUrls: ['./password-preview.component.scss']
})
export class PasswordPreviewComponent {

  @Input() productGroup: ProductGroup;
  @Input() client: Client;

  viewProductInfo: boolean = false;

  constructor() { }
}
