import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LoggerService } from '@app/core/services/logger.service';
import { SessionService } from '@app/security/session.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TwoWayPackerService } from '@app/core/services/two-way-packer.service';
import * as _ from 'lodash';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import {
  SortDirection,
  TwilioConversation,
  TwoWayConversationClientEvents,
  TwoWayConversationMessageEvents,
} from '../twilio-conversation.types';
import {
  Message,
  Paginator as ChatPaginator,
  Participant,
} from '@twilio/conversations';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SecureHttp } from '@app/security/secure-http';
import { environment } from '@env/environment';

@Component({
  selector: 'message-window',
  templateUrl: './message-window.component.html',
  styleUrls: ['./message-window.component.scss'],
})
export class MessageWindowComponent implements OnInit {
  @ViewChild('divToScroll') divToScroll: ElementRef;

  @Input() selectedChannel: string;
  @Input() $selectedChannel: BehaviorSubject<string>;

  @Output() textAreaHasFocus: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  loading: boolean = true;
  error: string;

  twoWayMessage: string;
  messages: any[] = [];
  typing: boolean;
  typingMessage: string;
  clientId: string;
  private id: string;

  constructor(
    private twoWayConversationService: TwoWayConversationService,
    private sessionService: SessionService,
    public newFeed: NewFeedTransitionService,
    private http: SecureHttp,
  ) {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
  }

  ngOnInit() {
    this.initListeners();
    this.getId();
    this.$selectedChannel.subscribe((channel_sid) => {
      if (this.selectedChannel !== channel_sid) {
        this.selectedChannel = channel_sid;
        this.messages = [];
        this.getId();
      }
    });
  }

  isCurrentUser(identity: string): boolean {
    return identity.indexOf(this.sessionService.currentUser.id) > -1;
  }

  sendTyping(e): void {
    if (e.code == 'Enter' && this.twoWayMessage.length > 0) {
      e.preventDefault();
      this.sendMessage();
    }
    this.twoWayConversationService.sendTyping();
  }

  private getAgentId(clientId: string, userId: string): string {
    return `agent::${clientId}::${userId}`;
  }

  sendMessage(): void {
    this.twoWayConversationService
      .getId(this.clientId, this.selectedChannel)
      .toPromise()
      .then(async (id) => {
        if (this.newFeed.enabled === true) {
          const author = this.getAgentId(
            this.clientId,
            this.sessionService.currentUser.id,
          );
          this.messages.push({
            author: author,
            displayName: await this.twoWayConversationService.getDisplayName(
              author,
            ),
            body: this.twoWayMessage,
            timestamp: new Date().toISOString(),
          });

          const messageBodyToEncrypt = {
            message: this.twoWayMessage,
            channelSid: this.selectedChannel,
          };
          const url = `${environment.twoWayURLBase}/client/${this.clientId}/agent/channel/message`;
          this.http.post(url, messageBodyToEncrypt, null, true).subscribe({
            next: (response) => {
              this.twoWayConversationService.sendMessage(
                response.message,
                true,
              );
              this.twoWayMessage = '';
            },
            error: (err) => {
              LoggerService.log('MessageWindow', err);
            },
          });
        } else {
          this.twoWayConversationService.sendMessage(
            TwoWayPackerService.pack(this.twoWayMessage, id),
          );
          this.twoWayMessage = '';
        }
      })
      .catch((err) => {
        this.handleError(err);
        return;
      });
  }

  disableSend(): boolean {
    return !this.twoWayMessage || this.twoWayMessage.length === 0;
  }

  emitFocus(e: FocusEvent) {
    this.textAreaHasFocus.emit(true);
  }

  emitFocusOut(e: FocusEvent) {
    this.textAreaHasFocus.emit(false);
  }

  private getId(): void {
    this.twoWayConversationService
      .getId(this.clientId, this.selectedChannel)
      .subscribe(
        (id) => {
          this.id = id;
          this.getConversationMessages();
        },
        (err) => this.handleError(err),
      );
  }

  private getConversationMessages(
    pageSize: number = 30,
    anchor: number = 0,
    direction: SortDirection = 'forward',
  ): void {
    this.twoWayConversationService
      .getMessages(pageSize, anchor, direction)
      .then((res: ChatPaginator<Message>) => {
        this.addConversationMessages(res).then(() => {
          if (res.hasNextPage) {
            this.getConversationMessages(
              pageSize,
              anchor + pageSize,
              direction,
            );
          } else {
            this.twoWayConversationService.setAllMessagesConsumed();
            this.loading = false;
            setTimeout(() => this.setWindowPosition());
          }
        });
      });
  }

  private decryptMessage(messages: any[]) {
    const arrayMessagesToDecrypt = {
      channelSid: this.selectedChannel,
      messages,
    };
    const url = `${environment.twoWayURLBase}/client/${this.clientId}/agent/channel/message/decrypt`;
    return this.http.post(url, arrayMessagesToDecrypt, null, true);
  }

  private getDecryptedText(decryptedArr: any[], encryptedText: string) {
    const { text } = decryptedArr.find((mess) => mess.cypher === encryptedText);
    return text;
  }

  private async addConversationMessages(
    messages: ChatPaginator<Message>,
  ): Promise<void> {
    let decryptedArrayMessages;
    if (this.newFeed.enabled === true) {
      const encryptedTextMessagesArr = messages.items
        .filter((message) => message.attributes['v3'])
        .map((message) => ({
          cypher: message.body,
        }));
      let { decryptedMessages } = await firstValueFrom(
        this.decryptMessage(encryptedTextMessagesArr),
      );
      decryptedArrayMessages = decryptedMessages;
    }
    for (let message of messages.items) {
      const displayName = await this.twoWayConversationService.getDisplayName(
        message.author,
      );
      if (_.get(message, 'attributes.v2.encrypted')) {
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: TwoWayPackerService.unpack(message.body, this.id),
          timestamp: message.dateUpdated,
        });
      } else if (
        this.newFeed.enabled === true &&
        _.get(message, 'attributes.v3.encrypted')
      ) {
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: this.getDecryptedText(decryptedArrayMessages, message.body),
          timestamp: message.dateUpdated,
        });
      } else {
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: message.body,
          timestamp: message.dateUpdated,
        });
      }
    }
  }

  private setWindowPosition(): void {
    this.divToScroll.nativeElement.scroll({
      top: this.divToScroll.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  private initListeners(): void {
    this.twoWayConversationService.clientEmitter.subscribe((event) =>
      this.channelConversationEventRouter(event),
    );
  }

  private channelConversationEventRouter(event: any): void {
    switch (event.event_type) {
      case TwoWayConversationMessageEvents.messageAdded:
        this.messageConversationAddedHandler(event.event);
        return;
      case TwoWayConversationMessageEvents.typingStarted:
        this.typingConversationStartedHandler(event.event);
        return;
      case TwoWayConversationMessageEvents.typingEnded:
        this.typingConversationEndedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationRemoved:
        this.conversationByeHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationLeft:
        this.conversationByeHandler(event.event);
        return;
    }
  }

  private async messageConversationAddedHandler(
    message: Message,
  ): Promise<void> {
    this.twoWayConversationService.setAllMessagesConsumed();
    if (message.conversation.sid === this.selectedChannel) {
      const displayName = await this.twoWayConversationService.getDisplayName(
        message.author,
      );
      if (_.get(message, 'attributes.v2.encrypted')) {
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: TwoWayPackerService.unpack(message.body, this.id),
          timestamp: message.dateUpdated,
        });
      } else if (
        this.newFeed.enabled === true &&
        _.get(message, 'attributes.v3.encrypted') &&
        message.author !==
          this.getAgentId(this.clientId, this.sessionService.currentUser.id)
      ) {
        const encryptedMessage = [];
        encryptedMessage.push({
          cypher: message.body,
        });
        const { decryptedMessages } = await firstValueFrom(
          this.decryptMessage(encryptedMessage),
        );
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: decryptedMessages[0].text,
          timestamp: message.dateUpdated,
        });
      } else if (
        message.author !==
        this.getAgentId(this.clientId, this.sessionService.currentUser.id)
      ) {
        this.messages.push({
          author: message.author,
          displayName: displayName,
          body: message.body,
          timestamp: message.dateUpdated,
        });
      }
      setTimeout(() => this.setWindowPosition());
    }
  }

  private async typingConversationStartedHandler(
    event: Participant,
  ): Promise<any> {
    if (event.conversation.sid === this.selectedChannel) {
      const displayName = await this.twoWayConversationService.getDisplayName(
        event.identity,
      );
      this.typingMessage = `${displayName} is typing...`;
    }
    return new Promise<void>((resolve) => resolve());
  }

  private typingConversationEndedHandler(event: Participant): void {
    if (event.conversation.sid === this.selectedChannel) {
      this.typingMessage = undefined;
    }
  }

  private conversationByeHandler(event: TwilioConversation): void {
    if (!event || event.sid === this.selectedChannel) {
      this.selectedChannel = undefined;
    }
  }

  private handleError(err: any): void {
    LoggerService.log('MessageWindowComponent', err);
    this.loading = false;
    this.error = 'Error getting messages for this channel';
  }
}
