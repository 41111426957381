import React, { useState, ReactNode } from 'react';

export type RefreshConversationsContextType = {
  refreshConversations: boolean;
  setRefreshConversations: (refreshConversations: boolean) => void;
};

export type RefreshConversationsContextProviderProps = {
  children: ReactNode;
};

export const RefreshConversationsContext = React.createContext<
  RefreshConversationsContextType | undefined
>(undefined);

export function RefreshConversationsProvider({
  children,
}: RefreshConversationsContextProviderProps) {
  const [refreshConversations, setRefreshConversations] = useState(false);

  return (
    <RefreshConversationsContext.Provider
      value={{ refreshConversations, setRefreshConversations }}
    >
      {children}
    </RefreshConversationsContext.Provider>
  );
}

export const useRefreshConversations = () => {
  const context = React.useContext(RefreshConversationsContext);

  if (context === undefined) {
    throw new Error(
      `useRefreshConversations must be used within a RefreshConversationsProvider`,
    );
  }

  return context;
};
