<div class="sms-custom-keywords">
  <label>Custom {{ this.client?.feed_enabled ? 'Notification' : 'SMS' }} Keywords</label>
  <div>
    <div
      *ngFor="let control of customKeywordArray.controls; index as i"
      [formGroup]="this.shortCodeGroup"
      class="checkbox-inline"
    >
      <input
        type="checkbox"
        [formControl]="getFormControl(control, 'checked')"
        id="{{
          getFormGroup(control, 'custom_sms_keyword').get('keyword').value
        }}"
      />
      <label
        for="{{
          getFormGroup(control, 'custom_sms_keyword').get('keyword').value
        }}"
        class="sms-custom-keywords-label"
        >{{
          getFormGroup(control, 'custom_sms_keyword').get('keyword').value
        }}</label
      >
    </div>
  </div>
</div>
