import React from 'react';
import { Button, KIND, SIZE } from 'baseui/button';
import { AgentAvatar } from '../agent-avatar/AgentAvatar';
import { styled, useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { BlockProps } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { AgentInfo } from './RelayMessengerAdditionalInfo';
import { AgentStatus } from '../relay-messenger-axios-service/relay-messenger-axios-service';

export type MultipleAgentsViewProps = {
  firstAgentFullName: string;
  firstAgentStatus: AgentStatus;
  userLeft: boolean;
  secondAgentInfo: AgentInfo;
  secondAgentStatus: AgentStatus;
  openLeaveModal: () => void;
  openRemoveModal: (agentId) => void;
  setIsAssignModalOpen?: (isOpen: boolean) => void;
  agentConversationsClicked: boolean;
  firstAgentId?: string;
};

const itemProps: BlockProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const VerticalLine = styled('div', {
  height: '90%',
  width: '1px',
  backgroundColor: '#cccccc',
});

// display two agents with leave button for current user
export const MultipleAgentsView = ({
  firstAgentFullName,
  firstAgentStatus,
  userLeft,
  openLeaveModal,
  openRemoveModal,
  setIsAssignModalOpen,
  agentConversationsClicked,
  secondAgentInfo,
  secondAgentStatus,
  firstAgentId,
}: MultipleAgentsViewProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  return (
    <>
      <FlexGrid
        flexGridColumnCount={3}
        flexGridColumnGap="scale100"
        flexGridRowGap="scale200"
        alignContent={'space-around'}
      >
        <FlexGridItem {...itemProps}>
          <AgentAvatar
            name={firstAgentFullName ? firstAgentFullName : 'Loading...'}
            status={firstAgentStatus}
            multipleAgents={true}
          ></AgentAvatar>
          {!agentConversationsClicked && (
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              disabled={userLeft ? true : false}
              overrides={{
                BaseButton: {
                  style: () => ({
                    width: 'auto',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    height: '40px',
                    borderTopLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderTopRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    color: '#BE0000',
                    backgroundColor: customTheme.colors.white,
                    boxShadow: 'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                    marginTop: '10px',
                  }),
                  props: {
                    'data-testid': 'leave-conversation-button',
                  },
                },
              }}
              onClick={openLeaveModal}
            >
              Leave
            </Button>
          )}
          {agentConversationsClicked && (
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              disabled={userLeft ? true : false}
              overrides={{
                BaseButton: {
                  style: () => ({
                    width: 'auto',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    height: '40px',
                    borderTopLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderTopRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    color: '#BE0000',
                    backgroundColor: customTheme.colors.white,
                    boxShadow: 'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                  }),
                  props: {
                    'data-testid': 'leave-conversation-button',
                  },
                },
              }}
              onClick={() => openRemoveModal(firstAgentId)}
            >
              Remove
            </Button>
          )}
        </FlexGridItem>
        <FlexGridItem {...itemProps} justifyContent={'center'} height={'auto'}>
          <VerticalLine />
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <AgentAvatar
            name={
              secondAgentInfo == null
                ? 'Loading...'
                : `${secondAgentInfo.first_name} ${secondAgentInfo.last_name}`
            }
            status={secondAgentStatus}
            multipleAgents={true}
          ></AgentAvatar>
          {agentConversationsClicked && (
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              disabled={userLeft ? true : false}
              overrides={{
                BaseButton: {
                  style: () => ({
                    width: 'auto',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    height: '40px',
                    borderTopLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomLeftRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderTopRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    borderBottomRightRadius:
                      customTheme.borders.agentInfoButtonBorderRadius,
                    color: '#BE0000',
                    backgroundColor: customTheme.colors.white,
                    boxShadow: 'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                  }),
                  props: {
                    'data-testid': 'leave-conversation-button',
                  },
                },
              }}
              onClick={() => openRemoveModal(secondAgentInfo.id)}
            >
              Remove
            </Button>
          )}
        </FlexGridItem>
      </FlexGrid>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          marginTop: '15px',
        })}
      >
        {agentConversationsClicked && (
          <Button
            kind={KIND.secondary}
            size={SIZE.mini}
            disabled={userLeft ? true : false}
            overrides={{
              BaseButton: {
                style: () => ({
                  width: 'auto',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  height: '40px',
                  marginLeft: '40px',
                  borderTopLeftRadius:
                    customTheme.borders.agentInfoButtonBorderRadius,
                  borderBottomLeftRadius:
                    customTheme.borders.agentInfoButtonBorderRadius,
                  borderTopRightRadius:
                    customTheme.borders.agentInfoButtonBorderRadius,
                  borderBottomRightRadius:
                    customTheme.borders.agentInfoButtonBorderRadius,
                  color: '#3693BF',
                  backgroundColor: customTheme.colors.white,
                  boxShadow: 'rgba(54, 147, 191, 0.23) 0px 0px 5px 0px',
                }),
                props: {
                  'data-testid': 're-assign-conversation-button',
                },
              },
            }}
            onClick={() => setIsAssignModalOpen(true)}
          >
            Re-assign
          </Button>
        )}
      </div>
    </>
  );
};
