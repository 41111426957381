<div class="rn-page">
    <div class="sftp-mgmt__title clearfix">
        <h3 class="sftp-mgmt__heading">Client Hosted SFTP Set Up</h3>
    </div>

    <div *ngIf="form" class="sftp-mgmt-layout">
        <form (ngSubmit)="update()">
            <div class="sftp-mgmt-layout__item">
                <rn-text-input [helpContent]="'Specify hostname or IP address of server'"
                               [inputControl]="getFormControl('host')"
                               [inputLabel]="'Host URL'"
                               [isWide]="true"></rn-text-input>
                <rn-text-input [helpContent]="'Port number you want to connect to (usually port 22)'"
                               [inputControl]="getFormControl('port')"
                               [inputLabel]="'Port'"
                               [isWide]="true"></rn-text-input>
                <rn-text-input [inputControl]="getFormControl('username')" [inputLabel]="'Username'"
                               [isWide]="true"></rn-text-input>
                <rn-text-input [inputControl]="getFormControl('password')" [inputLabel]="'Password'"
                               [inputSubLabel]="'(optional)'"
                               [isWide]="true"
                               [type]="passwordType"></rn-text-input>
                <div (click)="togglePassword()">show/hide</div>
                <rn-text-input
                        [helpContent]="'Server path where Relay team can upload the files for pickup'"
                        [inputControl]="getFormControl('location')"
                        [inputLabel]="'Folder Location'"
                        [inputSubLabel]="'(for receiving reports)'" [isWide]="true" [readOnly]="pushReports">
                </rn-text-input>
                <rn-checkbox (checkboxModelChange)="updatePushRejects($event)"
                             labelText="Push reject files along with reports"></rn-checkbox>
                <rn-text-input
                        *ngIf="pushReject"
                        [helpContent]="'Server path where Relay team can upload the files for pickup'"
                        [inputControl]="getFormControl('reject_files_location')"
                        [inputLabel]="'Folder Location'"
                        [inputSubLabel]="'(for reject files)'" [isWide]="true"
                        [readOnly]="pushReject">
                </rn-text-input>

                <button [disabled]="!form.valid" class="btn btn-primary sftp-mgmt__btn" type="submit">
                    <span class="btn-label">Save</span>
                </button>
            </div>
        </form>

        <div class="sftp-mgmt-layout__item">
            <label>Relay SSH Key</label>
            <textarea class="sftp-mgmt__key-textarea" disabled rows="6">{{relayPublicKey}}</textarea>
        </div>

    </div>
</div>

<message-dialog #dialog></message-dialog>
