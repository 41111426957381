import { Component, OnInit } from '@angular/core';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent implements OnInit {

  constructor(private loadingMaskService: LoadingMaskService) { 
    this.loadingMaskService.loadingOn();
  }

  ngOnInit() {
  }
}
