import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ClientService } from '@app/core/services/client.service';
import { SessionService } from '@app/security/session.service';
import { PushSFTP } from '@app/core/models/client';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'rn-external-sftp-mgmt',
  templateUrl: './external-sftp-mgmt.component.html',
  styleUrls: ['../sftp-mgmt/sftp-mgmt.component.scss']
})
export class ExternalSftpMgmtComponent implements OnInit {

  @ViewChild('dialog', {static: true}) dialog: MessageDialogComponent;

  form: UntypedFormGroup;
  passwordType = 'password';
  clientId: string;
  pushReports: boolean;
  pushReject: boolean;
  relayPublicKey = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQDS/b2U6+XxCRxLFtwdMUq5IPIid+wChI+0GU6WwERZo9OGjdVleiSk+g9K9zs/5TIzNpq9ey3QQXBX8wZBHVmWm3FQo+PRLWlV55LF/EPdVtlSY2P+4MdFiDBrNWGVwYZXDRQ9ARYsX2wY9eI4lAd7IEVf07n7S1w4OtwNPeJzeKTTmCYLe8gctZVCvPqma1BXfolCzk7k1VeVM1oLc1W3LJLzEn3jAdgbRlEoqJIf/YzFgxlDfjZex6XczREHPINrX2zjazesJ3k2FODqfB6L1HwU9L4TsWopcbhj1Jn6r9NN2Qzfr6pQZGwfVY93cQjg0M+jCxvcW0XwqNHQBS9v';

  initConfig: PushSFTP;

  private defaultPassword = 'password-hidden-for-protection';

  constructor(private formBuilder: UntypedFormBuilder,
              private sessionService: SessionService,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.clientService.getSFTPPush(this.clientId)
      .pipe(take(1))
      .subscribe(
        (config: PushSFTP) => this.setupForm(config),
        () => this.dialog.showMessage('There was an error loading the SFTP information. Please try again.')
      );
  }

  updateForm(event: string, control: AbstractControl): void {
    control.setValue(event);
  }

  updatePushRejects(event: boolean): void {
    this.pushReject = event;
    if (!event) {
      this.form.controls['reject_files_location'].setValue(null);
    }
  }

  togglePassword(): void {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  getFormControl(name: string): UntypedFormControl {
    return <UntypedFormControl>this.form.controls[name];
  }

  update(): void {
    let config: PushSFTP;

    // pull form values
    const {host, location, password, port, reject_files_location, ssh_key, username} = this.form.value;

    // correct ssh_key type, remove password
    config = {host, location, port, reject_files_location, ssh_key: Boolean(ssh_key), username};

    // check to add password
    if (password !== this.defaultPassword) {
      config.password = password;
    }

    config.ssh_key_path = this.initConfig.ssh_key_path;
    config.ssh_key = this.initConfig.ssh_key;

    this.clientService.updateSFTPPush(this.clientId, config)
      .pipe(take(1))
      .subscribe(
        () => this.dialog.showMessage('Your information was uploaded successfully.'),
        err => {
          const reason = err?.error?.reason;
          this.dialog.showMessage(`There was an error uploading your information. ${reason}`);
        }
      )
  }

  private setupForm(pushSFTP: PushSFTP): void {
    this.initConfig = pushSFTP;
    let password;

    if (pushSFTP.is_password_set) {
      password = this.defaultPassword;
    }

    this.form = this.formBuilder.group({
      'host': [pushSFTP.host, Validators.required],
      'port': [pushSFTP.port, Validators.required],
      'username': [pushSFTP.username, Validators.required],
      'password': [password],
      'location': [pushSFTP.location, Validators.required],
      'reject_files_location': [pushSFTP.reject_files_location]
    });

    this.pushReports = !!pushSFTP.location;
    this.pushReject = !!pushSFTP.reject_files_location;
  }

}
