import { AuthGuardService } from './auth-guard.service';
import { LoginGuardService } from './login-guard.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import { SecureHttp } from './secure-http';
import { SessionService } from './session.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { KeyConfigComponent } from './key-config/key-config.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SafeRedirectComponent } from './safe-redirect/safe-redirect.component';
import { RelaySharedModule } from '@app/shared/shared.module';
import { SecurityComponent } from '@app/security/security.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    RelaySharedModule
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SafeRedirectComponent,
    KeyConfigComponent,
    SecurityComponent
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    KeyConfigComponent
  ],
  providers: [
    AuthGuardService,
    LoginGuardService,
    SecureHttp,
    SessionService
  ]
})
export class SecurityModule {
}
