<div class="border-container" [class.border-container--has-border]="border">
    <div id="JotFormIFrameContainer-{{formId}}" 
        class="power-ups-iframe-container" 
        [class.power-ups-iframe-container--overlay]="overlay"
        [class.power-ups-iframe-container--scrolling]="scrolling"
        style.width="{{width}}px"
        style.height="{{containerHeight()}}px">
        <app-spinner *ngIf="loading()"></app-spinner>
        <iframe
        *ngIf="!!formUrl"
        #jotformIframe
        id="JotFormIFrame-{{formId}}"
        class="power-ups-iframe"
        [class.power-ups-iframe--hidden]="loading()"
        [tabindex]="overlay ? -1 : 1"
        title="JotForm"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        [src]="formUrl"
        frameborder="0"
        scrolling="no"
        style.width="{{intrinsicWidth}}px"
        style.height="{{intrinsicHeight}}px"
        style.transform="scale({{scaleFactor()}})"
        ></iframe>
    </div>
</div>