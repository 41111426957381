<div *ngIf="links && isRelayGreen()" class="breadcrumbs_green">
  <ul class="breadcrumbs_green__list">
    <li *ngFor="let link of links" class="breadcrumbs_green__item">
        <a class="breadcrumbs_green__link" [class.breadcrumbs_green__link--current]="isCurrent(link)" [routerLink]="link.path">
          <span class="breadcrumbs_green__link-text">
            {{link.text}}
          </span>
        </a>
    </li>
  </ul>
</div>

<div *ngIf="links && isLightGray()" class="breadcrumbs_light_gray">
  <ul class="breadcrumbs_light_gray__list">
    <li *ngFor="let link of links" class="breadcrumbs_light_gray__item">
        <a class="breadcrumbs_light_gray__link" [class.breadcrumbs_light_gray__link--current]="isCurrent(link)" [routerLink]="link.path">
          <span class="breadcrumbs_light_gray__link-text">
            {{link.text}}
          </span>
        </a>
    </li>
  </ul>
</div>
