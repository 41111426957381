import { Ordering, Direction } from '@app/core/utils/ordering';

/**
 * All components that are sortable should inherit from this base class.
 */
export abstract class SortableComponent {
    ordering: Ordering;

    // Make a property equal to the Direction enum so html templates can do dir.Asc and dir.Desc.
    dir = Direction;

    /**
     * Event handler for clicking a column heading.
     */
    onColumnHeadingClick(col: string) {
        if (this.ordering && this.ordering.orderBy === col) {
            if (this.ordering.direction === Direction.Asc) {
                this.ordering.direction = Direction.Desc;
            } else if (this.ordering.direction === Direction.Desc) {
                this.ordering = undefined;
            }
        } else {
            const value = Array.isArray(col) ? col[0] : col; // The 'role_id' field is an array 
            this.ordering = new Ordering(col, Direction.Asc);
        }
        this.fetchData();
    }

    abstract fetchData(): void;
}
