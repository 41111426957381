<div class="stop-language-config">
    <div>
        <select class="form-control" [ngModel]="this.selectedResponseLanguage"
                (ngModelChange)="onLanguageChanged($event)" [disabled]="isLanguageControlDisabled">
            <option *ngFor="let language of this.dropdownOptions" value={{language}} [disabled]="isLanguageOptionDisabled(language)">
                {{languageMap[language]}}
            </option>
        </select>
    </div>
    <div [formGroup]="this.smsLanguageGroup">
        <textarea class="form-control" formControlName="response_template" rows="5" (change)="onMessageChanged()"></textarea>
    </div>
    <div class="clearfix">
        <div *ngIf="this.showErrorDiv" class="left-container">
            <div *ngIf="this.showRequiredMessage">
                <small>A message must be provided.</small>
            </div>
            <div *ngIf="this.showMaxLengthMessage">
                <small>Message is too long.</small>
            </div>
        </div>
        <div class="right-container">
            <small>{{currentTextLength}}</small>
        </div>
    </div>
</div>
