<div class="inspector" [class.collapsed-size]="toolbarService.collapsed">
    <div class="header header-with-number">
        <span id="message-number" class="message-number">{{delayer.step}}</span>
        <span class="inspector-title" i18n="Header for time delay">Time Delay</span>
        <i class="fa fa-times clickable" aria-hidden="true" (click)="close.emit()"></i>
    </div>
    <div class="body">
        <div> <!-- time delay enum -->
          <select id="delay-enum-select" [(ngModel)]="delayer.interval_type" (change)="onIntervalTypeChange()">
            <option id="delay-day"  value="Day" i18n="Option for delay time">Days</option>
            <option id="delay-hour" value="Hour" i18n="Option for delay time">Hours</option>
          </select>
        </div>

        <div> <!-- time delay value -->
          <select id="delay-value-select" [(ngModel)]="delayer.interval_value">
            <option *ngFor="let unit of delayUnits" [ngValue]="unit" id="delay-{{unit}}">{{unit}}</option>
          </select>
        </div>

        <div>
            <div class="label" i18n="Label for delay notes">Notes</div>
            <div>
                <textarea id="delay-notes" [(ngModel)]="delayer.notes"></textarea>
            </div>
        </div>
    </div>
</div>
