import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rn-help-info-popup',
  templateUrl: './help-info-popup.component.html',
  styleUrls: ['./help-info-popup.component.scss']
})
export class HelpInfoPopupComponent implements OnInit {
  showHelpInfo: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
