import { LoadingMaskService } from '../../core/services/loading-mask.service';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ContentChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnDestroy, OnInit {
  @Input() showClose: boolean = false;
  @Input() showCancel: boolean = false;
  @Input() showAffirm: boolean = true;
  @Input() affirmButtonText: string = 'OK';
  @Input() affirmButtonClass: string = 'btn-default';
  @Input() cancelButtonText: string = 'Cancel';
  @Input() cancelButtonClass: string = 'btn-default';
  @Input() wide: boolean = false;
  @Output() affirm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  option: any = null;
  showDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  message = '';

  constructor(private loadingMaskService: LoadingMaskService) {}

  ngOnInit() {
    this.showDialog$.subscribe((dialogShown) => {
      if (dialogShown) {
        this.preventBodyScroll();
      } else {
        this.allowBodyScroll();
      }
    });
  }

  ngOnDestroy() {
    this.allowBodyScroll();
  }

  set showDialog(showDialog: boolean) {
    this.showDialog$.next(showDialog);
  }

  get showDialog() {
    return this.showDialog$.value;
  }

  showMessage(message?: string, e: any = null): void {
    this.loadingMaskService.loadingOff(true);
    this.message = message;
    this.showDialog = true;
    this.option = e;
  }

  affirmAction(): void {
    this.affirm.emit(this.option);
    this.showDialog = false;
  }

  cancelAction(): void {
    this.cancel.emit(null);
    this.showDialog = false;
  }

  close(): void {
    this.showDialog = false;
  }

  preventBodyScroll() {
    document.getElementsByTagName('body')[0].classList.add('body--fixed');
  }

  allowBodyScroll() {
    document.getElementsByTagName('body')[0].classList.remove('body--fixed');
  }
}
