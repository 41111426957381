<div class="intro-line-1" i18n="Introductory for creating a new client - step 1, line 1">Basic Configuration</div>

<ng-container *ngIf="clientInfoValidGroup">
  <client-header [headerLabel]="'Set client ID'" (click)="headerGroups.clientID = !headerGroups.clientID"></client-header>
  <div class="collapse-container" *ngIf="headerGroups.clientID" style="padding-bottom: 5px;">

    <rn-text-input [inputLabel]="'Client ID'" [inputControl]="clientInfoValidGroup.controls['client_id']" (blurred)="handleClientIdFieldBlur()" [placeholderText]="'Client ID'">

      <rn-input-info-box input-info-box [svgHeight]="60" [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
      [text]="'Your client ID is your main identifier to our system, and <strong>once set will not be able to be changed</strong>.'"></rn-input-info-box>
    
    </rn-text-input>

  </div>

  <client-header [headerLabel]="'Company information'"  (click)="headerGroups.companyInformation = !headerGroups.companyInformation"></client-header>
  <div class="collapse-container" *ngIf="headerGroups.companyInformation">

    <rn-text-input [inputLabel]="'Company name'" [inputControl]="clientInfoValidGroup.controls['company_name']"
                  [placeholderText]="'Company name'"></rn-text-input>

    <rn-text-input [inputLabel]="'First name'" [inputControl]="primaryContactGroup.controls['first_name']"
                  [placeholderText]="'First name'"></rn-text-input>

    <rn-text-input [inputLabel]="'Last name'" [inputControl]="primaryContactGroup.controls['last_name']"
                  [placeholderText]="'Last name'"></rn-text-input>

    <rn-text-input [inputLabel]="'Email address'" [inputControl]="primaryContactGroup.controls['email']"
                  [placeholderText]="'Email address'" (blurred)="trimEmail($event)"></rn-text-input>

    <rn-text-input [inputLabel]="'Mobile number'" [inputControl]="primaryContactGroup.controls['mobile_number']" 
                  [placeholderText]="'Mobile number'" (keyedUp)="formatPhoneNumber()"></rn-text-input>
  
    <ng-container *ngIf="showIndustrySelect()">
      <div class="select-container">
        <label for="industry-select">Select Industry</label>
        <select id="industry-select" class="form-control" name="industry-select" [formControl]="industryCtl">
          <option [value]="''" [selected]="industryCtl.value === ''">Select an Industry</option>
          <option *ngFor="let industry of industries" [value]="industry.id" [selected]="industryCtl.value === industry.id">
            {{industry.title['en-us']}}
          </option>
        </select>
      </div>

      <div class="select-container">
        <label for="company-type-select">Select Company Type</label>
        <select id="company-type-select" class="form-control" name="company-type-select" [formControl]="companyTypeCtl">
          <option [value]="''" [selected]="companyTypeCtl.value === '' || industryCtl.value === ''">Select a Company Type</option>
          <option *ngFor="let companyType of relevantCompanyTypes" [value]="companyType.id" [selected]="companyTypeCtl.value === companyType.id">
            {{companyType.title['en-us']}}
          </option>
        </select>
      </div>
    </ng-container>
  </div>
</ng-container>

<hr>

<div class="button-container">
  <button class="btn btn-primary" id="next-btn" (click)="saveAndContinue()" [class.inactive]="!clientInfoValidGroup.valid" [disabled]="!clientInfoValidGroup.valid">
    <span i18n="Label for Next button on new client configuration" id="save-and-next-button">
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
