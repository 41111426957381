import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { TitleService } from '@app/core/services/title.service';
import { SearchableField } from '@app/shared/search-bar/search-bar.component';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SortableComponent } from '@app/core/sortable-component';
import { CustomerService } from '@app/core/services/customer.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Customer } from '@app/core/models/customer';
import { PageManager } from '@app/core/utils/page-manager';
import { CustomerLookupLaunchService } from '@app/core/services/customer-lookup-launch.service';
import { Router } from '@angular/router';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { Client } from '@app/core/models/client';
import { SessionService } from '@app/security/session.service';
import { FeatureService } from '@app/core/services/feature.service';
import { take } from 'rxjs/operators';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
})
export class CustomerListComponent extends SortableComponent implements OnInit {
  @ViewChild('serverErrorDialog', { static: true }) serverErrorDialog: MessageDialogComponent;
  @ViewChild('alertChannelDeletedDialog', { static: true }) alertChannelDeletedDialog: MessageDialogComponent;
  client: Client;
  customers: Customer[];
  searchCriteria: SearchCriteria;
  displayDetails = {};
  offset = 0;
  loaded = false;
  permissions = Permissions;
  searchableFields = [
    new SearchableField('Customer name', 'full_name'),
    new SearchableField('Account ID (CCID)', 'ccid'),
    new SearchableField('Mobile number', 'notification_channels.phone_number'),
    new SearchableField('Product Group Id', 'product_group_id'),
  ];
  pageManager: PageManager = new PageManager();
  public canViewPhoneMgmtEdit: boolean;

  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  openRowId: string = '';

  constructor(
    private featureService: FeatureService,
    private titleService: TitleService,
    private customerService: CustomerService,
    private router: Router,
    public permissionService: PermissionService,
    private sessionService: SessionService,
    private customerLookupLaunchService: CustomerLookupLaunchService
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.activate('Customer lookup', '');
    this.client = this.sessionService.currentUser.client;
    this.canViewPhoneMgmtEdit = this.featureService.checkFlag(
      FeatureFlags.phone_management_edit_ui
    );
  }

  fetchData() {
    this.reloadData();
  }

  /**
   * NOTE: Does not load customer consent details; these must be loaded individually (see this.reloadCustomerData)
   */
  getCustomerList() {
    const offset = this.offset;
    const shouldHideMask = this.offset !== 0;
    const customerSearchCriteria = {
      limit: this.limit,
      offset: this.offset,
      ordering: this.ordering,
      searchCriteria: this.searchCriteria,
      hideMask: shouldHideMask,
    };
    this.customerService
      .getCustomerBySearch(customerSearchCriteria)
      .pipe(take(1))
      .subscribe(
        (assets) => {
          this.pageManager.addPage(offset, assets);
          this.customers = this.pageManager.flattenPages();
        },
        (error) => {
          if (error.status !== 401) {
            this.serverErrorDialog.showMessage(
              'Ooops...there was error loading customers.'
            );
          }
        },
        () => {
          this.loaded = true;
        }
      );
  }

  launchJourney(customer: Customer) {
    this.customerLookupLaunchService.customer = customer;

    if (
      this.permissionService.checkPermissions(
        this.permissions.ui_journey_launcher
      )
    ) {
      this.router.navigateByUrl(`/launcher/launchforuser/${customer.ccid}`);
    } else if (
      this.permissionService.checkPermissions(this.permissions.ui_quick_launch)
    ) {
      const id = this.client.validation.look_up_by_secondary_account_id
        ? customer.secondary_account_id
        : customer.ccid;
      let url = `/onboarding?ccid=${id}`;
      if (customer.telephone) {
        url = `${url}&telephone=${customer.telephone}`;
      }
      this.router.navigateByUrl(url);
    }
  }

  onClearSearch() {
    this.searchCriteria = undefined;
    this.customers = undefined;
    this.offset = 0;
  }

  onRequestSearch(searchCriteria: SearchCriteria) {
    this.searchCriteria = searchCriteria;

    if (this.searchCriteria.searchFields.length === 0) {
      this.pageManager = new PageManager();
      this.customers = [];
    } else {
      this.reloadData();
    }
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getCustomerList();
  }

  reloadData() {
    this.loaded = false;
    this.pageManager = new PageManager();
    this.customers = [];
    this.offset = 0;
    this.getCustomerList();
  }

  onConsentChanged(updatedNumber: string) {
    this.serverErrorDialog.showMessage(
      `Mobile no. ${updatedNumber} was successfully updated.`
    );
    this.reloadData();
  }

  onPhoneAdded(addedNumber: string) {
    this.serverErrorDialog.showMessage(
      `Mobile no. ${addedNumber} was successfully added.`
    );
  }

  onPhoneRemoved(removedNumber: string) {
    this.serverErrorDialog.showMessage(
      `Mobile no. ${removedNumber} was successfully removed.`
    );
  }

  onRowOpened(openRowId: string) {
    this.openRowId = openRowId;
  }
}
