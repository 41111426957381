import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'titleText'
})
export class TitleTextPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (!value) { return; }
    
    value = value.split('_').join(' ');
    const arrValue = value.split(' ');
    const arrHolder = [];
    _.forEach(arrValue, v => arrHolder.push(v.charAt(0).toUpperCase() + v.slice(1)));
    return arrHolder.join(' ');
  }
}
