import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey } from '@app/core/models/journey';
import { TemplateExperience } from '@app/core/models/template-experience';
import { TitleService } from '@app/core/services/title.service';
import { SortableComponent } from '@app/core/sortable-component';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SearchableField } from '@app/shared/search-bar/search-bar.component';
import { DisplayOption } from '@app/journey-list/list-options';
import { SessionService } from '@app/security/session.service';
import { LoggerService } from '@app/core/services/logger.service';
import { ExperienceLibraryService } from '@app/core/services/experience-library.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { PageManager } from '@app/core/utils/page-manager';
import { take } from 'rxjs/operators';

@Component({
  selector: 'rn-experience-library',
  templateUrl: './experience-library.component.html',
  styleUrls: ['./experience-library.component.scss'],
})
export class ExperienceLibraryComponent extends SortableComponent implements OnInit, OnDestroy {
  @Input() listTitle: string = null;
  clientId: string;
  isLoaded: boolean = false;
  hasError: boolean = false;
  isResultsDisplayed: boolean = true;
  showSearchableFields: boolean = false;
  isPageSettingsDisplayed: boolean = false;
  isNormalDisplayed: boolean = true;
  txtNormalDisplayed: string = 'true';
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  limit = 20;
  offset = 0;
  pageManager = new PageManager();
  experiences: Journey[] = [];
  objDisplayingExperiences = {};
  searchCriteria = new SearchCriteria();
  searchableFields = [
    new SearchableField('Experience Name', 'name'),
    new SearchableField('Outcome', 'outcome_name'),
    new SearchableField('Experience Type', 'experience_type_name'),
  ];
  @ViewChild(MessageDialogComponent, {static: true}) messageDialog: MessageDialogComponent;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: TitleService, private sessionService: SessionService, private experienceLibraryService: ExperienceLibraryService) {
    super();
  }

  get getDisplayOption(): DisplayOption {
    return DisplayOption.live;
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.titleService.activate('Experience Library');
    this.retrieveExperiences();
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }

  isNotNullOrUndefined(val: any): boolean {
    return (val !== undefined && val !== null);
  }

  navigateToAdmin(): void {
    this.router.navigate(['admin'], {relativeTo: this.activatedRoute});
  }

  /**
   * Deserialize and store template experiences from a service request into the
   * page manager and in a cannoical list of all the templates that have been
   * fetched.
   *
   * @param experiences the templates to deserialize
   * @param offset the offset at whcih the service request was made, used to
   * identify this batch of templates in the page manager
   */
  convertExperiences(experiences: Journey[], offset: number): void {
    const deserializedExperiences: Journey[] = [];

    let convertedExperience: Journey;
    for (const experience of experiences) {
      try {
        convertedExperience = Journey.deserialize(experience);
        deserializedExperiences.push(convertedExperience);
      } catch (error) {
        LoggerService.log('experience-library.component', `could not parse experience ${this.clientId}: ${JSON.stringify(error)}`);
      }
    }
    this.pageManager.addPage(offset, deserializedExperiences);
    this.experiences = this.pageManager.flattenPages();
    if (Object.keys(this.objDisplayingExperiences).length === 0 && experiences[0]) {
      this.objDisplayingExperiences[experiences[0].id] = true;
    }
  }

  /**
   * Process the template experiences from a service request.
   *
   * @param experiences the templates to process
   * @param offset the offset at which the service request was made, used to
   * identify this batch of templates in the page manager
   */
  processExperiences(experiences: Journey[], offset: number): void {
    this.isLoaded = true;
    this.hasError = false;
    if ((this.isNotNullOrUndefined(experiences) && experiences.length > 0)) {
      this.isResultsDisplayed = true;
      this.convertExperiences(experiences, offset);
    } else if (this.experiences.length > 0) {
      this.isResultsDisplayed = true;
    } else {
      this.isResultsDisplayed = false;
      this.experiences = [];
    }

    if (this.isResultsDisplayed) {
      this.experiences.map(experience => {
        this.addOutcomeAndExperienceType(experience.live);
      });
    }
  }

  addOutcomeAndExperienceType(content) {
    const messageComponents = content?.components.filter(
      (m) =>
        m.type === 'MessageSender' &&
        m.outcome_name !== undefined &&
        m.experience_type_name !== undefined
    ) ?? [];

    if (messageComponents.length > 0) {
      const uniqueOutcomeNames = [
        ...new Set(messageComponents.map((m) => m.outcome_name))
      ];

      const uniqueExperienceTypeNames = [
        ...new Set(messageComponents.map((m) => m.experience_type_name))
      ];

      content.outcome_names = uniqueOutcomeNames.join(', ');
      content.experience_type_names = uniqueExperienceTypeNames.join(', ');
    }
  }

  processExperiencesError(error): void {
    this.isLoaded = true;
    this.hasError = true;
    this.messageDialog.showMessage('An error occured with your request, please try again later.');
    LoggerService.log('experience-library.component', `templates for clientId ${this.clientId} error: ${JSON.stringify(error)}`);
  }

  retrieveExperiences(): void {
    /**
     * The offset needs to be immutable from the start of retrieval of this
     * batch of templates all the way through to the time we store the results
     * in the page manager.
     */
    this.isLoaded = false;
    const offset = this.offset;
    if (!this.searchCriteria) {
      this.searchCriteria = new SearchCriteria();
    }
    this.searchCriteria.filtersIfFieldExists = this.searchCriteria.filtersIfFieldExists.filter((field) => field.fieldName !== 'deleted');

    this.experienceLibraryService.getExperiences(this.clientId, this.limit, this.offset, this.searchCriteria, this.ordering, true).subscribe(
      (templateExperience: TemplateExperience) => {
        this.processExperiences(templateExperience.templates, offset)
      },
      (error) => {
        this.processExperiencesError(error)
      }
    );
  }

  reloadExperiences(): void {
    this.pageManager = new PageManager();
    this.experiences = [];
    this.offset = 0;
    this.retrieveExperiences();
  }

  onScrollDown() {
    this.offset += this.limit;
    this.retrieveExperiences();
  }

  /**
   * Needed by {@link SortableComponent}.
   *
   * In the future, the code in {@link reloadExperiences} could simply be moved
   * into this method and then everywhere it's called, we just call
   * `this.fetchData()`.
   */
  fetchData() {
    this.reloadExperiences();
  }

  /**
   * When clicking the "x" to clear search, reset the text box and restore the
   * list of experiences being displayed to all of the ones we've fetched.
   */
  onSearchClear() {
    this.searchCriteria = undefined;
    this.reloadExperiences();
  }

  onSearchRequest(searchCriteria: SearchCriteria): void {
    this.searchCriteria = searchCriteria;
    if (this.searchCriteria.searchFields.length === 0) {
      this.pageManager = new PageManager();
      this.experiences = [];
      this.offset = 0;
    } else {
      this.reloadExperiences();
    }
  }

  onSearchToggle() {
    this.showSearchableFields = !this.showSearchableFields;
  }

  onExperienceRowClick(experienceId: string): void {
    if (this.objDisplayingExperiences[experienceId]) {
      this.objDisplayingExperiences[experienceId] = false;
    } else {
      this.objDisplayingExperiences = {};
      this.objDisplayingExperiences[experienceId] = true;
    }
  }

  getFilteredComponents(experience: Journey): any[] {
    return experience.live.components.filter(component => !component.wire || component.wire.is_shown === 'true');
  }

  processCopyExperience(copiedExperienceId: string): void {
    this.isLoaded = true;
    this.router.navigateByUrl(`/cx-builder/experience-builder/${copiedExperienceId}`);
  }

  processCopyExperienceError(error: any): void {
    this.isLoaded = true;
    this.messageDialog.showMessage('An error occured copying the experience, please try again later.');
    LoggerService.log('experience-library.component', `copy experience for clientId ${this.clientId} error: ${JSON.stringify(error)}`);
  }

  copyExperience(experience: Journey) {
    this.isLoaded = false;
    this.experienceLibraryService.postCopyExperience(this.clientId, experience)
      .pipe(take(1))
      .subscribe({
        next: (copiedExperienceId: string) => {
          this.processCopyExperience(copiedExperienceId)
        },
        error: (error: any) => {
          this.processCopyExperienceError(error)
        }
      });
  }

  convertExperienceDisplay(): void {
    this.isNormalDisplayed = !this.isNormalDisplayed;
  }
}
