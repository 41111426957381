<app-search-bar
    [searchableFields]="searchableFields"
    (onRequestSearch)="onRequestSearch($event)"
    (onClearSearch)="onClearSearch()"
    [isMediaLibrary]="true"
    [isFixed]="false">
</app-search-bar>

<div class="container-fluid media-list">
    <div class="row colhdrs">
        <div id="media-file" class="colw colw-22 column-heading no-text-selection" (click)="onColumnHeadingClick('shortname')">
            <span i18n="Media library list file heading">Asset Name</span>
            <app-column-sort-icon id="sort-icon-media-file" [ordering]="ordering" [fieldName]="'shortname'"></app-column-sort-icon>
        </div>
        <div id="media-created-by" class="colw colw-16 column-heading no-text-selection" (click)="onColumnHeadingClick('created_by_name')">
            <span i18n="Media library list created by heading">Created By</span>
            <app-column-sort-icon id="sort-icon-media-created-by" [ordering]="ordering" [fieldName]="'created_by_name'"></app-column-sort-icon>
        </div>
        <div id="media-asset-type" class="colw colw-14 column-heading no-text-selection" (click)="onColumnHeadingClick('asset_type')">
            <span i18n="Media library list asset type heading">Asset Type</span>
            <app-column-sort-icon id="sort-icon-media-asset-type" [ordering]="ordering" [fieldName]="'asset_type'"></app-column-sort-icon>
        </div>
        <div id="media-date-modified" class="colw colw-14 column-heading no-text-selection" (click)="onColumnHeadingClick('updated_at')">
            <span i18n="Media library list date modified heading">Date Modified</span>
            <app-column-sort-icon id="sort-icon-media-updated-at" [ordering]="ordering" [fieldName]="'updated_at'"></app-column-sort-icon>
        </div>
        <div id="media-url" class="colw colw-30 column-heading no-text-selection">
            <span i18n="Media library list URL heading">URL</span>
        </div>
    </div>

    <ng-container *ngIf="mediaAssets">
        <div class="infinite-scroll" data-infinite-scroll
              debounce
              [infiniteScrollDistance]="scrollDistance"
              [infiniteScrollThrottle]="throttle"
              (scrolled)="onScrollDown()">
            <div class="row media-asset" *ngFor="let mediaAsset of mediaAssets" (click)="onMediaAssetClick(mediaAsset)" [id]="'row-' + mediaAsset.asset_id">
                <div class="colw colw-22">
                    <div class="colw thumbnail-width">
                        <img *ngIf="mediaAsset.asset_type === 'image' || mediaAsset.asset_type === 'brand' || mediaAsset.asset_type === 'banner'" class="media-thumbnail" src="{{mediaAsset.getS3UrlCacheBuster()}}">
                        <img *ngIf="mediaAsset.asset_type === 'video' && mediaAsset.videoThumbnailUrl" class="media-thumbnail" [src]="mediaAsset.videoThumbnailUrl">
                    </div>
                    <div class="name-container">
                        <div class="short-name">{{mediaAsset.shortname}}</div>
                        <div class="file-name">{{mediaAsset.getFileName()}}</div>
                    </div>
                </div>
                <div class="colw colw-16" id="created-by">
                    {{mediaAsset.created_by}}
                </div>
                <div class="colw colw-14" id="asset-type">
                    {{mediaAsset.asset_type}}
                </div>
                <div class="colw colw-14" id="updated-at">
                    {{mediaAsset.updated_at | date:'shortDate'}}
                </div>
                <div class="colw colw-30">
                    <div class="media-asset__copy-url-col">
                        <rn-copied-message [copyFromElement]="true" [customBtnText]="'Copy URL to Clipboard'" [inputData]="mediaAsset.asset_id"></rn-copied-message>
                        <i class="fa fa-pencil"></i>
                    </div>
                    <div>
                        <input class="media-url" id="{{mediaAsset.asset_id}}" value="{{ mediaAsset.asset_type === 'video' ? mediaAsset.url : mediaAsset.s3_url}}">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="loaded && mediaAssets.length == 0 && !showNoSearchFieldsMessage" class="not-found" i18n="Message that the user gets when there are no media assets to show in the media library">
    There were no media assets found.
</div>
<div *ngIf="showNoSearchFieldsMessage" class="not-found" i18n="Message that the user gets when they tried to search with no search fields selected">
    There were no media assets found.  Please select at least one search field and retry.
</div>

<!--  This instance of the add-media-asset component is for uploading a media asset. -->
<add-media-asset *ngIf="showAddAssetDialog"
    [clientId]="this.sessionService.currentUser.client.id"
    [assetTypes]="['banner', 'brand', 'image', 'video']"
    [maxFileSize]="1048576"
    (onSave)="onMediaAssetAdded($event)"
    (onClose)="onCloseAddMediaAssetDialog()">
</add-media-asset>

<!--  This instance of the add-media-asset component is for uploading new image content to an existing media asset -->
<add-media-asset *ngIf="showUpdateAssetDialog"
    [assetToUpdate]="mediaAssetToEdit"
    [clientId]="this.sessionService.currentUser.client.id"
    [assetTypes]="['brand', 'image', 'video']"
    [maxFileSize]="1048576"
    (onSave)="onMediaAssetUpdated($event)"
    (onClose)="onCloseUpdateMediaAssetDialog()">
</add-media-asset>

<edit-media-asset-details *ngIf="showAssetDetailsDialog"
    [mediaAsset]="mediaAssetToEdit"
    (onSave)="onMediaAssetDetailsUpdated($event)"
    (onClose)="onCloseMediaAssetDetailsDialog()"
    (onUpdateRequest)="onUpdateRequest()">
</edit-media-asset-details>

<message-dialog></message-dialog>
