
import {tap, catchError, timeout} from 'rxjs/operators';
import { SessionService } from './session.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoadingMaskService } from '../core/services/loading-mask.service';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@app/core/services/logger.service';
/**
 * This class provides a drop-in replacement for the Angular Http class that
 * implements cross-cutting security concerns such as detection of session expiration.
 */
@Injectable()
export class SecureHttp {

  readonly timeoutMillis = 30000;

  constructor(private http: HttpClient, public sessionService: SessionService, private loadingMaskService: LoadingMaskService) {
  }

  /**
   * Ensures you have a any having withCredentials = true.
   */
  private addWithCredentials(options: any): any {
    if (!options) {
      options = {};
    }
    options.withCredentials = true;
    return options;
  }

  private handleError(err, extra = {}) {
      this.loadingMaskService.loadingOff(true);
      if (this.sessionService.checkSessionTimeout(err)) {
        if (err.name === 'TimeoutError' || err.status === 500) {
          this.loadingMaskService.throwError('Something went wrong with the request. Please try again.');
        }
      }

      LoggerService.log('SecureHttpService', {
        client_id: this.sessionService.selectedClient.value ?? null,
        error: err, 
        ...extra,
      });
      
      return throwError(err);
  }

  /**
   * Wrapper for Http.get that includes withCredentials=true in the options and checks for session timeout.
   */
  get(url: string, options?: any, supressMask?: boolean, timeoutMillis?: number): Observable<any> {
    if (!supressMask) {
        this.loadingMaskService.loadingOn();
    }
    options = this.addWithCredentials(options);

    let result = this.http.get(url, options).pipe(
      timeout(timeoutMillis ? timeoutMillis : this.timeoutMillis),
      catchError((err) => { 
        return this.handleError(err, { 
          method: 'GET', 
          url,
          options,
        }); 
      })
    );
    
    result = result.pipe(tap(x => {
        return supressMask ? null : this.loadingMaskService.loadingOff();
    }, null, null));
    return result;
  }

  /**
   * Wrapper for Http.post that includes withCredentials=true in the options and checks for session timeout.
   */
  post(url: string, body: any, options?: any, supressMask?: boolean): Observable<any> {
    if (!supressMask) {
        this.loadingMaskService.loadingOn();
    }
    options = this.addWithCredentials(options);
    let result = this.http.post(url, body, options).pipe(
      timeout(this.timeoutMillis),
      catchError((err) => { 
        return this.handleError(err, { 
          method: 'POST', 
          url,
          options,
          body
        }); 
      })
    );
    result = result.pipe(tap(x => {
        return supressMask ? null : this.loadingMaskService.loadingOff();
    }, null, null));
    return result;
  }

  /**
   * Wrapper for Http.put that includes withCredentials=true in the options and checks for session timeout.
   */
  put(url: string, body: any, options?: any, supressMask?: boolean): Observable<any> {
    if (!supressMask) {
        this.loadingMaskService.loadingOn();
    }
    options = this.addWithCredentials(options);
    
    let result = this.http.put(url, body, options).pipe(
      timeout(this.timeoutMillis),
      catchError((err) => { 
        return this.handleError(err, { 
          method: 'PUT', 
          url,
          options,
          body,
        }); 
      })
    );

    result = result.pipe(
      tap(x => supressMask ? null : this.loadingMaskService.loadingOff(), null, null)
    );
    return result;
  }

  /**
   * Wrapper for Http.delete that includes withCredentials=true in the options and checks for session timeout.
   */
  delete(url: string, options?: any, supressMask?: boolean): Observable<any> {
    if (!supressMask) {
        this.loadingMaskService.loadingOn();
    }
    options = this.addWithCredentials(options);
    let result = this.http.delete(url, options).pipe(
      timeout(this.timeoutMillis),
      catchError((err) => { 
        return this.handleError(err, { 
          method: 'DELETE',
          url,
          options,
        }); 
      })
    );
    
    result = result.pipe(
      tap(x => supressMask ? null : this.loadingMaskService.loadingOff(), null, null)
    );

    return result;
  }
}
