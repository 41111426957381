import { ClientInfoProvider } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import React, { useState } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import axios from 'axios';
import { BaseProvider } from 'baseui';
import { theme } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

export type NotificationAdminProps = {
  clientId: string;
};

const engine = new Styletron({ prefix: 'notification-admin-' });

export const NotificationAdmin = ({ clientId }: NotificationAdminProps) => {
  const [jobId, setJobId] = useState('');
  const [triggerId, setTriggerId] = useState('');
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/rn-portal/1.0/client/${clientId}/admin/notifications?jobId=${jobId}&triggerId=${triggerId}`,
      );
      setData([...response.data.couchbase.rows, ...response.data.fengine.rows]);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNotifications = async () => {
    await axios.delete(
      `/rn-portal/1.0/client/${clientId}/admin/notifications?jobId=${jobId}&triggerId=${triggerId}`,
    );
    setIsOpen(false);
    setData([]);
  };

  return (
    <ClientInfoProvider clientId={clientId}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <>
            <h1>Client: {clientId}</h1>
            <FormControl label="File Engine Job Id">
              <Input
                type="text"
                value={jobId}
                onChange={(e) => setJobId(e.currentTarget.value)}
                clearOnEscape
                clearable
              />
            </FormControl>
            <FormControl label="Experience Trigger Id">
              <Input
                type="text"
                value={triggerId}
                onChange={(e) => setTriggerId(e.currentTarget.value)}
                clearOnEscape
                clearable
              />
            </FormControl>
            <ButtonGroup>
              <Button onClick={getNotifications} isLoading={isLoading}>
                Submit
              </Button>
              <Button
                onClick={() => setIsOpen((s) => !s)}
                disabled={data.length === 0 ? true : false}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.white,
                      backgroundColor: $theme.colors.backgroundNegative,
                    }),
                  },
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
            {data.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Job Id</th>
                    <th>Trigger Id/Client Trigger Id</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td>{item.source}</td>
                      <td>{item.jobId}</td>
                      <td>{item.triggerId || item.clientTriggerId}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>

          <Modal
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
            overrides={{
              Dialog: {
                style: {
                  marginTop: '75px',
                  width: '30%',
                },
              },
            }}
          >
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalBody>
              <div>
                ALL SCHEDULED NOTIFICATIONS FOR{' '}
                <strong>{clientId.toUpperCase()}</strong> WILL BE CANCELLED FOR
                THE FOLLOWING
              </div>
              <ul>
                {jobId ? (
                  <li>
                    File Engine Job Id: <strong>{jobId}</strong>
                  </li>
                ) : (
                  ''
                )}
                {triggerId ? (
                  <li>
                    Experience Trigger Id: <strong>{triggerId}</strong>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              <div>THIS ACTION CANNOT BE UNDONE.</div>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                autoFocus
                kind="secondary"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </ModalButton>
              <ModalButton
                onClick={deleteNotifications}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.white,
                      backgroundColor: $theme.colors.backgroundNegative,
                    }),
                  },
                }}
              >
                Confirm
              </ModalButton>
            </ModalFooter>
          </Modal>
        </BaseProvider>
      </StyletronProvider>
    </ClientInfoProvider>
  );
};
