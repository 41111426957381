<div *ngIf="isReady()" class="rn-page">
  
  <rn-text-input [inputControl]="outcomeIdCtl" [inputLabel]="'Outcome ID'"></rn-text-input>

  <rn-text-input [inputControl]="outcomeNameCtl" [inputLabel]="'Outcome Name'"></rn-text-input>

  <div class="input-container input-container--multiselect">
    <label>Industry and Company Type</label>
    <div class="rn-multi-select">
      <mat-form-field class="select" appearance="outline">
          <mat-select [formControl]="ancestorPathsCtl" multiple> 
            <div *ngFor="let industry of optionsMap;">
              <mat-optgroup [label]="industry.name">
                <mat-option class="mat-nested-option" *ngFor="let companyType of industry?.children;" class="select-option" [value]="ancestorPath(industry.id, companyType.id)">
                  {{companyType.name}}
                </mat-option>
              </mat-optgroup>
            </div>
          </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-bar">
    <button class="btn btn-secondary cancel-btn"
      type="button" (click)="close()">cancel</button>

    <button class="btn btn-primary save-btn" type="button"
      (click)="saveAndClose()">save and exit</button>
  </div>

</div>

<message-dialog #updateErrorDialog></message-dialog>

<message-dialog #loadErrorDialog (affirm)="close()"></message-dialog>