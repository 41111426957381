<div class="journey-list-content">
  <div  *ngIf="listTitle || copyType || selectionType ==='CSR'" class="copy-title">
    <span *ngIf="listTitle">{{listTitle}}</span>
    <i *ngIf="copyType==='message'" id="cancel-message-copy-select" aria-hidden="true" class="fa fa-times" (click)="navigateBack()"></i>
    <i *ngIf="copyType==='journey'" id="cancel-journey-copy-select" aria-hidden="true" class="fa fa-times" routerLink="/cx-builder"></i>
    <i *ngIf="selectionType === 'CSR'" id="cancel-journey-csr-select" aria-hidden="true" class="fa fa-times" (click)="cancelAndClearSelectService()"></i>
  </div>

  <div class="list-header-container">
    <div class="list-header">
      <app-search-bar [searchableFields]="searchableFields"
                      [isFixed]="!isCopyList()"
                      (onRequestSearch)="onRequestSearch($event)"
                      (onClearSearch)="onClearSearch()">
      </app-search-bar>
    </div>
  </div>

  <div class="container-fluid journey-list"
       data-infinite-scroll
       debounce
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollThrottle]="throttle"
       (scrolled)="onScrollDown()">

    <div class="btn-row clearfix">
      <div class="search-options">
        <div class="option-dropdown">
          <div class="button-options" (click)="showOptions = !showOptions">
          <span class="gear">
            <i class="fa fa-cogs"></i>
          </span>
            <span class="dropdown">
            <i class="fa fa-caret-down" *ngIf="showOptions"></i>
              <i class="fa fa-caret-right" *ngIf="!showOptions"></i>
          </span>
          </div>
        </div>
        <div class="options-holder" [ngClass]="{'show': showOptions}">
          <div class="options">
          <div>
            <input id="grid-view" type="checkbox" [(ngModel)]="showGrid" (change)="handleMessageView()">
            <label for="grid-view">Grid View</label>
          </div>
          <div *ngIf="permissionService.checkPermissions(permissions.journey_delete)">
            <input id="archived-view" type="checkbox" [(ngModel)]="showArchived" (change)="showArchivedJourneys()">
            <label for="archived-view" >Show Archived</label>
          </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showCheckboxes">
        <button class="btn btn-primary clear-btn margin-top-negative-1px" (click)="clearAllJourneys()">
          Clear All
        </button>
        <button class="btn btn-primary multi-select-confirm margin-top-negative-1px" (click)="confirmMultiSelect()">
          Select These Experiences
        </button>
      </ng-container>
    </div>

    <div class="row colhdrs">
      <div id="journey-name" [ngClass]="journeyListUtils.columnNameClassWidth" class="colw column-heading no-text-selection cursor-pointer" (click)="onColHeaderClick('name')">
        <span i18n="Experience name column heading">Experience Name</span>
        <app-column-sort-icon id="sort-icon-client-name" [ordering]="ordering"
                              [fieldName]="'name'"></app-column-sort-icon>
      </div>
      <div id="journey-outcome" *ngIf="isOutOfBoxExperienceEnabled" class="colw colw-7 column-heading no-text-selection cursor-pointer" (click)="onColHeaderClick('outcome_name')">
        <span i18n="Experience outcome column heading">Outcome</span>
        <app-column-sort-icon id="sort-icon-client-outcome" [ordering]="ordering"
                              [fieldName]="'outcome_names'"></app-column-sort-icon>
      </div>
      <div id="journey-experience-type" *ngIf="isOutOfBoxExperienceEnabled" class="colw colw-7 column-heading no-text-selection cursor-pointer" (click)="onColHeaderClick('experience_type_name')">
        <span i18n="Experience type column heading">Experience Type</span>
        <app-column-sort-icon id="sort-icon-client-experience-type" [ordering]="ordering"
                              [fieldName]="'experience_type_names'"></app-column-sort-icon>
      </div>
      <div id="journey-created-by" [ngClass]="journeyListUtils.columnCreatedByClassWidth"  class="colw column-heading no-text-selection cursor-pointer"
           (click)="onColHeaderClick('created_by_name')">
        <span i18n="Experience created by column heading">Created By</span>
        <app-column-sort-icon id="sort-icon-created-by-name" [ordering]="ordering"
                              [fieldName]="'created_by_name'"></app-column-sort-icon>
      </div>
      <div id="journey-product-group" [ngClass]="journeyListUtils.columnProductGroupClassWidth"  class="colw column-heading no-text-selection cursor-pointer"
           (click)="onColHeaderClick('product_group_internal_name')">
        <span i18n="Experience product group column heading">Product Group Internal Name</span>
        <app-column-sort-icon id="sort-icon-product-group-internal-name" [ordering]="ordering"
                              [fieldName]="'product_group_internal_name'"></app-column-sort-icon>
      </div>
      <div id="journey-campaign" [ngClass]="journeyListUtils.columnCampaignClassWidth" class="colw column-heading no-text-selection cursor-pointer"
           (click)="onColHeaderClick('campaign')">
        <span i18n="Experience campaign column heading">Campaign</span>
        <app-column-sort-icon id="sort-icon-campaign-name" [ordering]="ordering"
                              [fieldName]="'campaign'"></app-column-sort-icon>
      </div>
      <div id="journey-created-at" [ngClass]="journeyListUtils.columnDateCreatedClassWidth" class="colw column-heading no-text-selection cursor-pointer"
           (click)="onColHeaderClick('created_at')">
        <span i18n="Experience date created column heading">Date Created</span>
        <app-column-sort-icon id="sort-icon-created-at-name" [ordering]="ordering"
                              [fieldName]="'created_at'"></app-column-sort-icon>
      </div>
      <div id="journey-updated-at" [ngClass]="journeyListUtils.columnDateModifiedClassWidth" class="colw column-heading no-text-selection cursor-pointer"
           (click)="onColHeaderClick('updated_at')">
        <span i18n="Experience date modified column heading">Date Modified</span>
        <app-column-sort-icon id="sort-icon-updated-at-name" [ordering]="ordering"
                              [fieldName]="'updated_at'"></app-column-sort-icon>
      </div>
      <div id="journey-status" [ngClass]="journeyListUtils.columnStatusClassWidth" class="colw column-heading no-text-selection">
        <span i18n="Experience status column heading">Status</span>
      </div>
    </div>

    <ng-container *ngFor="let journeyRecord of journeys; let i=index">
      <div *ngIf="!(this.selectionType === 'customer-list-launch' && this.journeyRecord.draft)"
           id="journey-{{journeyRecord.id.slice(0,8)}}" class="journey"
           [class.active]="journeyVersionsAreExpanded(journeyRecord)" (click)="onRowClick(journeyRecord.id)">
        <ng-container *ngIf="journeyHasDualVersions(journeyRecord) && !suppressDrafts() && journeyVersionsAreExpanded(journeyRecord)">
          <journey-summary #liveSummary [journeyRecord]="journeyRecord"
                            [expanded]="displayJourney[journeyRecord.id]"
                            [isCheckedJourney]="journeyIsChecked(journeyRecord)"
                            [isSelectedVersion]="true"
                            [selectionType]="selectionType"
                            [showActions]="true"
                            [showCheckbox]="showCheckboxes"
                            [showVersionToggle]="true"
                            [version]="'live'"
                            [showCopyBtn]="copyType === 'journey'"
                            (copyJourneyRequested)="copyJourney($event)"
                            (versionToggled)="toggleVersionSibling($event)"
                            (journeyChecked)="updateCheckedJourneysArray($event)"
                            (fallbackJourneySelected)="selectFallbackJourney($event)"
                            [index]=i>
          </journey-summary>
          <journey-summary #draftSummary [journeyRecord]="journeyRecord"
                            [expanded]="displayJourney[journeyRecord.id]"
                            [version]="'draft'"
                            [selectionType]="selectionType"
                            [showCheckbox]="showCheckboxes"
                            [showVersionToggle]="true"
                            (copyJourneyRequested)="copyJourney($event)"
                            (versionToggled)="toggleVersionSibling($event)"
                            (journeyChecked)="updateCheckedJourneysArray($event)"
                            [index]=i>
          </journey-summary>
        </ng-container>

        <ng-container *ngIf="!(journeyHasDualVersions(journeyRecord) && !suppressDrafts() && journeyVersionsAreExpanded(journeyRecord))">
          <journey-summary  [journeyRecord]="journeyRecord"
                            [showCopyBtn]="copyType === 'journey'"
                            [draftsSuppressed]="suppressDrafts()"
                            [expanded]="displayJourney[journeyRecord.id]"
                            [isCheckedJourney]="journeyIsChecked(journeyRecord)"
                            [launcherMode]="launcherMode"
                            [selectionType]="selectionType"
                            [showActions]="true"
                            [showCheckbox]="showCheckboxes"
                            [showVersionToggle]="false"
                            [version]="preferredVersion(journeyRecord)"
                            (copyJourneyRequested)="copyJourney($event)"
                            (journeyChecked)="updateCheckedJourneysArray($event)"
                            (fallbackJourneySelected)="selectFallbackJourney($event)"
                            (exportExperienceError)="exportedJourneyErrored($event)"
                            [index]=i>
          </journey-summary>
        </ng-container>

        <div class="row journey-details" *ngIf="displayJourney[journeyRecord.id]">
          <journey-details
            [journeyRecord]="journeyRecord"
            [messageView]="messageView"
            [displayOption]="detailsDisplayOption"
            [selectionType]="selectionType"
            [copyType]="copyType"
            (copyJourneyRequested)="copyJourney($event)"
            [copyMsgParams]="copyMsgParams ? copyMsgParams : null"
            [includeSmsOnlyMessages]="includeSmsOnlyMessages">
          </journey-details>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="loaded && journeys.length == 0" class="not-found"
       i18n="Message that the user gets when there are no experiences to show in the list">There were no experiences found.
  </div>

  <app-spinner class="not-found" *ngIf="!loaded"></app-spinner>
</div>

<message-dialog></message-dialog>
