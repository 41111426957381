<app-onboarding-toggle id="agent_toggle" [btnLabel]="'Agent'"  [btnName]="'agent-onboarding-btn'" (click)="showConfigGroup = !showConfigGroup"
                       [(btnModel)]="client.onboarding.csr.enabled" [helperText]="' must have experiences created before enabling.'"></app-onboarding-toggle>

<div class="agent-container">
  <div class="collapsible-container" *ngIf="client.onboarding.csr.enabled === 'true'">
    <p class="consent-header">If you will be using the Agent/CSR Onboarding, the default consent level Agents will collect is Express Written Consent. Toggle this off if you would like Agents to only collect Express Consent.</p>
 
    <div class="question-container">
      <div class="select-container clearfix">
        <label>Set consent level</label>
        <select class="form-control capture-consent-select" [(ngModel)]="client.onboarding.csr.capture_written_consent">
          <option value="true">Express Written Consent</option>
          <option value="false">Express Consent</option>
        </select>
      </div>

      <div class="script-header">
        <div>
          <label>User agent script</label>
        </div>
        <span class="journey-select helper-text">An onboarding agent will have access to certain experiences (up to a maximum of 250) created for a product.  Please select experiences using the button below.</span>
      </div>

      <div class="question-row">
        <span class="question-num">1.</span>
        <div class="question">
          <app-agent-journey-select id="journeys" [(client)]="client"></app-agent-journey-select>
        </div>
      </div>

      <div class="question-row">
        <span class="question-num">2.</span>      
        <div class="question">
          <app-agent-express-consent id="consent-question" [client]="client"></app-agent-express-consent>
        </div>
      </div>

      <div class="question-row">
        <span class="question-num">3.</span>
        <div class="question">
          <app-agent-validation id="validation-question" [client]="client"></app-agent-validation>
        </div>
      </div>
    </div>
    <div class="preview-container clearfix">
      <button class="btn btn-primary" (click)="preview = true">
        <span id="csr-preview-button">
          PREVIEW
        </span>
        <span class="icon icon-right">
          <i class="fa fa-binoculars"></i>
        </span>
      </button>
    </div>
  </div>

</div>
<div class="dialog-background" *ngIf="preview" style="display: block;">  
  <div class="preview">
    <div class="title-bar">
      Preview
      <span class="close-preview" (click)="preview=false"><i class="fa fa-times"></i></span>
    </div>
    <div class="body">
      <app-quick-launch-content [client]="client"></app-quick-launch-content>
    </div>
  </div>  
</div>
