import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client, ProductGroup, PGVerification } from '@app/core/models/client';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { VerificationField } from '@app/core/models/validation-verification';
import { CustomValidators } from '@app/core/utils/custom-validators';
import * as _ from 'lodash';


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  @Input() client: Client;
  @Input() productGroup: ProductGroup;
  @Output() validationStateChange: EventEmitter<boolean> = new EventEmitter(); 

  headerGroups = { verification: true };
  showTooltip: boolean = false;
  // TODO: kinda confusing to have 2 forms in one component...  
  verificationConfigForm: UntypedFormGroup; // VERIFICATION
  verificationFieldsArray: UntypedFormArray; // VALIDATION 

  ngOnInit() {
    this.initConfigForm();
    this.initVerificationFieldsForm();

    // submit an initial event to broadcast initial validation state
    this.validationStateChange.emit(this.verificationFieldsArray.valid);

    this.verificationFieldsArray.valueChanges.subscribe(() => {
      this.validationStateChange.emit(this.verificationFieldsArray.valid)
    });
  }

  getVerificationFields(): VerificationField[] {
    return this.verificationFieldsArray.getRawValue() as VerificationField[];
  }

  getVerificationConfig(): PGVerification {
    return this.verificationConfigForm.getRawValue() as PGVerification;
  }

  markAllAsTouched() {
    CustomValidators.markAllAsTouched(this.verificationConfigForm);
    CustomValidators.markAllAsTouched(this.verificationFieldsArray);
  }

  get validationFieldValues(): string[] {
    return _.values(this.verificationFieldsArray.controls);
  }

  // verificationConfigForm Group shape matches the PGVerificationModel in models/client
  private initConfigForm(): void {
    this.verificationConfigForm = new UntypedFormGroup({
      'body': new UntypedFormControl(this.productGroup.onboarding.verification.body),
      'header': new UntypedFormControl(this.productGroup.onboarding.verification.header),
      'button_label': new UntypedFormControl(this.productGroup.onboarding.verification.button_label),
    }); 
  }
  
  // formGroups in the verificationFieldsArray 
  private initVerificationFieldsForm(): void {
    this.verificationFieldsArray = new UntypedFormArray([]);

    _.each(this.client.verification.fields, (field: VerificationField) => {
      this.verificationFieldsArray.push(
        new UntypedFormGroup({
          'field': new UntypedFormControl(field.field),
          'prompt': new UntypedFormControl(field.prompt, Validators.required)
        })
      )
    });
  }
}
