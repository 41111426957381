<div class="dialog-background">
  <div class="add-media-asset-dialog dialog-content">
    <div class="title-bar">
      <span *ngIf="!assetToUpdate && !createBrand" class="pull-left"
            i18n="Title bar for adding new media asset">Add your media asset</span>
      <span *ngIf="!assetToUpdate && createBrand" class="pull-left"
            i18n="Title bar for creating new brand">Create a new brand</span>
      <span *ngIf="assetToUpdate && assetToUpdate.asset_type !== 'brand'" class="pull-left" i18n="Title bar for updating media asset content">Update your media asset</span>
      <span *ngIf="assetToUpdate && assetToUpdate.asset_type === 'brand'" class="pull-left" i18n="Title bar for updating brand asset content">Update your brand</span>
      <i class="fa fa-times close-icon" (click)="close()"></i>
    </div>

    <div class="dialog-body">
      <ng-container *ngIf="assetTypes.length > 1">
        <label i18n="Media asset type">Asset Type</label>
        <select id="asset-type" class="form-control light"
                [(ngModel)]="selectedAssetType"
                (change)="onAssetTypeChanged()"
                [attr.disabled]="selectionPreview ? '' : null">
          <option *ngIf="assetTypes.indexOf('brand') >= 0" i18n="Brand media asset type" value="brand">Brand</option>
          <option *ngIf="assetTypes.indexOf('banner') >= 0" i18n="Image media asset type" value="banner">Banner</option>
          <option *ngIf="assetTypes.indexOf('image') >= 0" i18n="Image media asset type" value="image">Image</option>
          <option *ngIf="assetTypes.indexOf('image') >= 0" i18n="Video media asset type" value="video">Video</option>
        </select>
      </ng-container>

      <div class="turn-video-selection-on-container">
        <button *ngIf="selectedAssetType === 'video' && canSelect && !selectionOn && !selectionPreview"
                class="btn btn-primary" (click)="turnOnSelection()" id="turn-video-selection-on-btn">
          <span i18n="Button label for selecting a local file asset">Select Asset</span>
          <span class="icon icon-right">
                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                    </span>
        </button>
      </div>

      <div *ngIf="selectedAssetType === 'video' && !videoPreviewOn">
        <p class="youtube-instructions" i18n="Instructions for you tube video instructions">
          Accepting URLs from YouTube, Vimeo, Wistia, Optimum, and Brightcove.
        </p>
        <div [ngClass]="{'has-error':!mediaAssetGroup.controls['video_url'].valid && mediaAssetGroup.controls['video_url'].touched}">
          <input id="videoUrlInput" class="form-control"
                 [formControl]="mediaAssetGroup.controls['video_url']"
                 (focus)="onVideoUrlFocus()" (blur)="onVideoUrlBlur()"
                 placeholder="Video URL" i18n-placeholder="Video URL placeholder text"
                 spellcheck="false">
        </div>
        <span class="has-error"
          *ngIf="mediaAssetGroup.controls['video_url'].errors
          && mediaAssetGroup.controls['video_url'].touched
          && mediaAssetGroup.controls['video_url'].errors['whiteListURL']
        ">
          {{mediaAssetGroup.controls['video_url'].errors['whiteListURL']}}
        </span>
      </div>

      <div *ngIf="!imagePreviewOn && !selectionOn && selectedAssetType !== 'video'" class="upload-drag-drop-area"
           (drop)="dropFile($event)" (dragover)="dragOver($event)">
        <p>Drag your asset here</p>
        <p>or</p>
        <div>
          <ng-container *ngIf="!canSelect; else allowSelect">
            <input type="file" id="fileUploadInput" (change)="selectLocalFile($event)" style="display:none"
                   accept="image/*">
            <label id="fileUploadInput" class="btn btn-primary" for="fileUploadInput">
              <span i18n="Button label for selecting a local file asset">Select Asset</span>
              <span class="icon icon-right">
                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                            </span>
            </label>
          </ng-container>
          <ng-template #allowSelect>
            <label id="fileUploadInput" class="btn btn-primary" (click)="turnOnSelection()">
              <span i18n="Button label for selecting a local file asset">Select Asset</span>
              <span class="icon icon-right">
                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                            </span>
            </label>
          </ng-template>
        </div>
      </div>

      <div *ngIf="selectionOn">
        <div class="browse-btn-container">
          <input type="file" id="fileUploadInput" (change)="selectLocalFile($event)" style="display:none"
                 accept="image/*">
          <label
            *ngIf="selectedAssetType === 'image' || selectedAssetType === 'brand' || selectedAssetType === 'banner'"
            id="fileUploadInput" class="btn btn-primary" for="fileUploadInput">
            <span i18n="Button label for selecting a local file asset">Browse</span>
            <span class="icon icon-right">
                            <i class="fa fa-file-image-o" aria-hidden="true"></i>
                        </span>
          </label>
        </div>
        <div class="search-container">
          <input id="search-field" class="search-field" type="search" placeholder="Search"
                 i18n-placeholder="Placeholder for search input" [(ngModel)]="searchText"
                 (keypress)="checkForEnter($event)">
          <button id="submit-search" class="btn btn-primary submit-search" (click)="submitSearchRequest()">
            <i class="fa fa-search"></i>
          </button>
          <i *ngIf="searchCriteria" id="clear-search" class="fa fa-times-circle" aria-hidden="true"
             (click)="clearSearch()"></i>
        </div>
        <div class="selector-area"
              debounce
              data-infinite-scroll
              infiniteScroll
              [infiniteScrollContainer]="mediaScrollArea"
              [infiniteScrollDistance]="scrollDistance"
              [infiniteScrollThrottle]="throttle"
              (scrolled)="onScrollDown()">
          <ng-container *ngFor="let selectableAsset of selectableMediaAssets">
            <div id="selectable-asset-{{selectableAsset.asset_id}}" class="selectable-asset"
                 [class.selected]="selectedAsset && selectedAsset.asset_id === selectableAsset.asset_id"
                 (click)="selectExistingAsset(selectableAsset)">
              <img
                *ngIf="selectableAsset.asset_type === 'image' || selectableAsset.asset_type === 'brand' || selectableAsset.asset_type === 'banner'"
                src="{{selectableAsset.s3_url}}">
              <img *ngIf="selectableAsset.asset_type === 'video'"
                   [src]="selectableAsset.videoThumbnailUrl">
            </div>
          </ng-container>
        </div>
        <div *ngIf="selectableMediaAssets.length == 0" class="not-found"
             i18n="Message that the user gets when there are no media assets to select from">
          There were no media assets found.
        </div>
      </div>

      <div *ngIf="selectedAssetType !== 'video'" class="image-preview" [class.active]="imagePreviewOn">
        <img id="previewImage" [src]="previewImage ? previewImage : ''">
      </div>

      <div *ngIf="selectedAssetType === 'video' && videoPreviewOn" class="video"
            [class.active]="videoPreviewOn">
        <app-video [videoUrl]="currentVideoUrl" [showUrl]="true"></app-video>
      </div>

      <upload [fileNamePrefix]="'Asset file name: '"
              (uploadComplete)="saveComplete($event)"
              (uploadAborted)="saveAborted()"
              (uploadError)="handleUploadError()">
      </upload>

      <ng-container *ngIf="!uploadStarted">
        <div
          [ngClass]="{'has-error':!mediaAssetGroup.controls['short_name'].valid && mediaAssetGroup.controls['short_name'].touched}">
          <label class="form-label" i18n="Asset display name">Display Name</label>
          <input id="displayName" class="form-control" type="text" [formControl]="mediaAssetGroup.controls['short_name']" [attr.readonly]="selectedAsset">
        </div>

        <div *ngIf="selectedAssetType === 'image' || selectedAssetType === 'banner'"
          [ngClass]="{'has-error':!mediaAssetGroup.controls['alternate_text'].valid && mediaAssetGroup.controls['alternate_text'].touched}">
          <label class="form-label">Alternate Text</label>
          <input id="altText" class="form-control" type="text"
                [(ngModel)]="altText" [formControl]="mediaAssetGroup.controls['alternate_text']"
                [attr.readonly]="selectedAsset">
        </div>

        <div
          [ngClass]="{'has-error':!mediaAssetGroup.controls['user_asset_id'].valid && mediaAssetGroup.controls['user_asset_id'].touched}">
          <label class="form-label" i18n="Asset ID">Asset ID</label> <span *ngIf="!assetToUpdate" i18n="Indication of an optional field">(optional)</span>
          <input id="assetId" class="form-control" type="text" [formControl]="mediaAssetGroup.controls['user_asset_id']"
                 [attr.readonly]="selectedAsset || assetToUpdate">
        </div>

        <div *ngIf="createBrand"
        [ngClass]="{'has-error':!mediaAssetGroup.controls['branding_color'].valid && mediaAssetGroup.controls['branding_color'].touched}">
        <label class="form-label" i18n="Brand Color">Brand Color</label>
        <br />
        <color-picker
        [(color)]="brandingColor"
        (colorChanged)="setColor($event)"
      ></color-picker>
      </div>
      </ng-container>

      <ng-container *ngIf="uploadComplete">
        <h3>Your image has been uploaded!</h3>
      </ng-container>

      <div class="footer-bar">
        <div class="pull-left">
          <div class="cancel-link" (click)="close()">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span i18n="Link to cancel test launch">Cancel</span>
          </div>
        </div>
        <div class="pull-right">
          <button
            *ngIf="selectedAssetType !== 'video' && loadedFile && loadedImageValid && !uploadStarted || selectionPreview && !selectedAsset || selectedAssetType === 'video' && videoPreviewOn && !selectedAsset"
            id="saveBtn" class="btn btn-primary" (click)="save()" [class.inactive]="!isValid" [disabled]="!isValid">
            <span i18n="Button heading to add a new media asset">Save</span>
          </button>
          <button *ngIf="selectedAssetType === 'video' && !videoPreviewOn && !selectedAsset" id="nextBtn"
                  class="btn btn-primary" (click)="nextStepForVideo()" [class.inactive]="!isValid"
                  [disabled]="!isValid">
            <span i18n="Button heading go to next step in the process of adding video">Next</span>
          </button>
          <button *ngIf="selectedAsset && !selectionPreview" id="selectBtn" class="btn btn-primary"
                  (click)="previewSelection()" [class.inactive]="!isValid" [disabled]="!isValid">
            <span i18n="Button heading to select an existing asset">Select</span>
          </button>
          <button *ngIf="selectedAsset && selectionPreview" id="confirmBtn" class="btn btn-primary"
                  (click)="confirmSelection()" [class.inactive]="!isValid" [disabled]="!isValid">
            <span i18n="Button heading to confirm selected asset">Confirm</span>
          </button>
          <button *ngIf="loadedFile && !loadedImageValid" id="okBtn" class="btn btn-primary" (click)="resetState()">
            <span i18n="retry button to for dialog for adding media asset if an error happened">Retry</span>
          </button>
          <button *ngIf="uploadComplete" id="retryBtn" class="btn btn-primary" (click)="close()">
            <span i18n="OK button to close dialog for adding media asset">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<message-dialog></message-dialog>
