<div>
  <label>Sign Up Page Enabled</label>
  <span>{{product.onboarding.sign_up_page.enabled === 'true' ? "Enabled" : "Disabled"}}</span>
  <hr />
</div>

<div *ngIf="product.onboarding.sign_up_page.message_text">
  <label>Message text</label>
  <span>{{product.onboarding.sign_up_page.message_text}}</span>
  <hr />
</div>

<div *ngIf="product.onboarding.sign_up_page.consent_type">
  <label>Consent type</label>
  <span>{{product.onboarding.sign_up_page.consent_type}}</span>
  <hr />
</div>

<div *ngIf="product.onboarding.sign_up_page.failure_text">
  <label>Submit button label</label>
  <span>{{product.onboarding.sign_up_page.failure_text}}</span>
  <hr />
</div>

<div>
  <label>Success copy</label>
  <span>{{product.onboarding.sign_up_page.success_text}}</span>
  <hr />
</div>

<div>
  <label>Failure copy</label>
  <span>{{product.onboarding.sign_up_page.failure_text}}</span>
  <hr />
</div>

<div>
  <label>Validation fields</label>
  <span>
    <p>
      {{product.onboarding.sign_up_page.ccid_input_label}}
    </p>
    <p>
      Mobile Number
    </p>
    <p *ngFor="let field of client.validation.fields">
      {{field.prompt}}
    </p>
  </span>
  <hr />
</div>
