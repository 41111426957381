import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { CompanyType, ExperienceType, Industry, Outcome } from '@app/core/models/categorization-types';
import { CategorizationListItem, CategoryIndex } from '@app/experience-library/utils/categorization-list-item';
@Component({
  selector: 'rn-experience-type-list-item',
  templateUrl: './experience-type-list-item.component.html',
  styleUrls: ['./experience-type-list-item.component.scss']
})
export class ExperienceTypeListItemComponent extends CategorizationListItem {
  @Input() experienceType: ExperienceType;
  @Input() industryOptions: Industry[];
  @Input() companyTypeOptions: CompanyType[];
  @Input() outcomeOptions: Outcome[];
  @Output() editClicked = new EventEmitter();
  detailsOpen: boolean = false;
  categoryIndex = CategoryIndex; // have to declare this as a class var to make it available in the template

  constructor() { 
    super();
  }

  getItemNameForId(itemId: string, index: CategoryIndex): string {
    if(this.industryOptions && index === CategoryIndex.Industries) {
      return this.industryOptions.find((c => c.id === itemId)).title['en-us'];
    } else if(this.companyTypeOptions && index === CategoryIndex.CompanyTypes) {
      return this.companyTypeOptions.find((c => c.id === itemId)).title['en-us'];
    } else if(this.outcomeOptions && index === CategoryIndex.Outcomes) {
      return this.outcomeOptions.find((c => c.id === itemId)).title['en-us'];
    } else {
      return '';
    }
  }

  emitEditEvent(): void {
    this.editClicked.emit(this.experienceType.id);
  }

  toggleDetails() {
    this.detailsOpen = !this.detailsOpen;
  }

  hasDetailsToShow(experienceType: ExperienceType): boolean {
    return this.hasAdditionalItems(experienceType, CategoryIndex.Industries) || this.hasAdditionalItems(experienceType, CategoryIndex.CompanyTypes) || this.hasAdditionalItems(experienceType, CategoryIndex.Outcomes);
  }
}
