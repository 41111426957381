
<div *ngIf="productGroups.length > 0">
  <div class="intro-line-1" i18n="Introductory for product group list - line 1">Product group information</div>

  <client-header [headerLabel]="'New product groups'" [headerGroup]="'productGroups'" (openHeader)="toggleHeader($event)" (closeHeader)="toggleHeader($event)"></client-header>
  <div class="create-panel" *ngIf="headerGroups.productGroups">
    <div class="product" *ngFor="let product of productGroups" [ngStyle]="{'border-top-color': product.color, 'border-bottom-color': product.color }" id="{{assignID(product.name)}}-product-button" routerLink="/client/{{client?.id}}/product-group/{{product?.id}}">
      <img [src]="product?.branding?.banner_s3_url" width="220" height="71" />
      <div class="product-title" [ngStyle]="{'color': product.color}" title="{{product.name}}">{{product.name}}</div>
      <div class="product-subtitle" title="{{product.internal_name}}">{{product.internal_name}}</div>
    </div>
    <div class="product new" id="new-product-button" routerLink="/client/{{client?.id}}/product-group/new">
      <div class="product-add"><i class="fa fa-plus-square" aria-hidden="true"></i></div>
      <div class="product-title">Create new</div>
    </div>
  </div>
</div>

<div *ngIf="productGroups.length === 0">
  <div class="intro-line-1" i18n="Introductory for creating a new product group - line 1">Do. Or do not.  There is no try.</div>
  <div class="intro-line-2" i18n="Introductory for creating a new product group - line 2">This will be easier than lifting a space ship out of a swamp.</div>

  <div class="create-panel">
    <div class="product new" id="new-product-button" routerLink="/client/{{client?.id}}/product-group/new">
      <div class="product-add"><i class="fa fa-plus-square" aria-hidden="true"></i></div>
      <div class="product-title">Create new</div>
    </div>
  </div>

</div>

<hr>

<div class="button-container clearfix">
    <button id="prev-btn" class="btn btn-primary btn-with-icon" (click)="navigateToPrevious()">
      <span class="icon icon-left">
        <i class="fa fa-arrow-left"></i>
      </span>
      <span class="btn-label">Previous</span>
    </button>
    <button class="btn btn-primary" id="next-btn" (click)="navigateToReports()">
      <span i18n="Label for Next button on new client configuration" id="finish-button">
        Finish
      </span>
      <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
      </span>
    </button>
</div>

<message-dialog></message-dialog>
