import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Check } from 'baseui/icon';
import { KIND as ButtonKind, SIZE as ButtonSize } from 'baseui/button';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ModalButton, ModalFooter } from 'baseui/modal';
import { Select, Value } from 'baseui/select';
import {Textarea} from 'baseui/textarea';
import { ClosedReasons } from '@app/two-way/setup/components/setup-conversations/components/closed-reasons-axios-service/closed-reasons-axios-service';

export type ActionsModalProps = {
  closeModal: () => void;
  successModal: (reason?, reasonValue?, reasonText?) => void;
  modalSubTitle: string;
  cancelBtnText: string;
  successBtnText: string;
  dataTestId?: string;
  reasons?: ClosedReasons[];
};
export const ActionsModal = ({
  closeModal,
  successModal,
  modalSubTitle,
  cancelBtnText,
  successBtnText,
  dataTestId,
   reasons= []
}: ActionsModalProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const [reasonValue, setReasonValue] = useState<Value>([])
  const [reasonList, setReasonList] = useState([]);
  const [reasonId, setReasonId] = useState(null);
  const [reasonName, setReasonName] = useState(null);
  const [reasonText, setReasonText] = useState('')
  const [showTextArea, setShowTextArea] = useState(false)


  useEffect(() => {
    if (reasons && reasons.length > 0) {
      setReasonList([...reasons])
    }
  }, [])

  useEffect(() => {
    if(reasonValue && reasonValue.length === 1) {
      if (reasonValue[0] && reasonValue[0].allowAdditionalDetails) {
        setShowTextArea(true);
        setReasonText('')
      } else if (reasonValue[0] && !reasonValue[0].allowAdditionalDetails) {
        setShowTextArea(false);
      }
      setReasonId(reasonValue[0].id)
      setReasonName(reasonValue[0].reason)
    }
  }, [reasonValue])

  return (
    <Block>
      <div
        className={css({
          fontWeight: '400',
          color: '#37474F',
          paddingLeft: '10px',
        })}
      >
        {modalSubTitle}
        {reasons && reasons.length > 0 && (
          <div className={css({ marginTop: '20px' })}>
            <Select
              options={reasonList}
              labelKey="reason"
              required
              onChange={({ value }) => setReasonValue(value)}
              value={reasonValue}
              overrides={{
                Root: {
                  props: {
                    'data-testid': 'select-close-reason',
                  },
                },
                OptionContent: {
                  props: {
                    'data-testid': `close-reasons`,
                  },
                },
              }}
            />
            
            {showTextArea && (
               <div className={css({ marginTop: '20px' })}>
              <Textarea
              value={reasonText}
              onChange={e => setReasonText(e.currentTarget.value)}
              maxLength={256}
            />
            </div>
            )}
          </div>
        )}
      </div>
      <ModalFooter
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          alignItems: 'center',
        })}
      >
        <Block
          className={css({
            display: 'flex',
            gap: '10px',
          })}
        >
          <ModalButton
            kind={ButtonKind.tertiary}
            size={ButtonSize.mini}
            autoFocus={false}
            overrides={{
              BaseButton: {
                style: {
                  height: '40px',
                  color: '#5192BB',
                  fontWeight: '400',
                },
              },
            }}
            onClick={closeModal}
          >
            {cancelBtnText}
          </ModalButton>
          <ModalButton
            size={ButtonSize.mini}
            kind={ButtonKind.secondary}
            endEnhancer={
              <div className={css({ display: 'flex', alignItems: 'center' })}>
                <div
                  className={css({
                    marginRight: '8px',
                    height: '35px',
                    width: '1px',
                    backgroundColor: '#427a9d',
                  })}
                ></div>
                <Check size={24} />
              </div>
            }
            autoFocus={false}
            overrides={{
              BaseButton: {
                style: {
                  height: '40px',
                  color: customTheme.colors.white,
                  backgroundColor: '#5192BB',
                  outline: '#427a9d 1px solid',
                  fontWeight: '400',
                  textTransform: 'uppercase',
                  ':hover': {
                    backgroundColor: '#f6f6f6',
                    color: '#5192BB',
                  },
                },
                props: {
                  'data-testid': dataTestId,
                },
              },
            }}
            disabled={reasonList && reasonList.length > 0 && reasonValue.length === 0}
            onClick={() => successModal(reasonId, reasonName, reasonText)}
          >
            {successBtnText}
          </ModalButton>
        </Block>
      </ModalFooter>
    </Block>
  );
};
