export class BlackoutWindow {
  start_time = '20:00:00';
  end_time = '11:00:00';
  timezone = 'EST5EDT';
  include_saturday = false;
  include_sunday = false;

  static deserialize(input: Object): BlackoutWindow {
    const blackoutWindow = new BlackoutWindow();
    blackoutWindow.start_time = input['start_time'];
    blackoutWindow.end_time = input['end_time'];
    blackoutWindow.timezone = input['timezone'];
    blackoutWindow.include_saturday = input['include_saturday'] || false;
    blackoutWindow.include_sunday = input['include_sunday'] || false;
    return blackoutWindow;
  }

  get ianaTimeZone() {
    let ianaTimeZone = null;

    if (this.timezone === 'EST5EDT') {
      ianaTimeZone = 'America/New_York';
    } else if (this.timezone === 'CST6CDT') {
      ianaTimeZone = 'America/Chicago';
    } else if (this.timezone === 'MST7MDT') {
      ianaTimeZone = 'America/Phoenix';
    } else if (this.timezone === 'PST8PDT') {
      ianaTimeZone = 'America/Los_Angeles';
    } else {
      ianaTimeZone = 'America/New_York';
    }
    return ianaTimeZone;
  }
}
