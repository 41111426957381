import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { LoggerService } from '@app/core/services/logger.service';
import { UserService } from '@app/core/services/user.service';
import { BehaviorSubject, from as fromPromise } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChannelAddress, Customer } from '@app/core/models/customer';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import {
  TwilioConversation,
  TwoWayConversationClientEvents,
} from '../twilio-conversation.types';
import { HttpErrorResponse } from '@angular/common/http';

interface ButtonEvent {
  currentTarget: {
    disabled: boolean;
  };
}

// Customer Object merged with data from the search api's customer Data endpoint
class TwoWayCustomer extends Customer {
  lastPing?: string; // datetime
  middle_name?: string;
  is_generic?: boolean;
  gender?: string;
}

@Component({
  selector: 'two-way-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
  @ViewChild('inviteDialog', { static: true })
  inviteDialog: MessageDialogComponent;
  @ViewChild('leaveDialog', { static: true })
  leaveDialog: MessageDialogComponent;
  @ViewChild('closeDialog', { static: true })
  closeDialog: MessageDialogComponent;
  @ViewChild('pingDialog', { static: true }) pingDialog: MessageDialogComponent;

  // todo - put in service
  @Input() $selectedChannel: BehaviorSubject<string>;

  getUserInfoError: boolean;
  userInfo: TwoWayCustomer;
  inviteEmail: string;
  selectedChannel: string;
  leaveMessage: string = `You are about to leave this conversation. The conversation will be moved to the unassigned conversations list.`;
  closeMessage: string =
    'You are about to close this conversation. The conversation will be permanently closed unless it is reopened by the customer.';

  constructor(
    private sessionService: SessionService,
    private twoWayConversationService: TwoWayConversationService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.$selectedChannel.subscribe((channel) => this.getUserInfo(channel));
    this.initListeners();
  }

  pingUser(): void {
    this.twoWayConversationService
      .pingUser(
        this.sessionService.getCurrentUsersClient().id,
        this.selectedChannel,
      )
      .subscribe({
        next: () => {
          this.pingDialog.showMessage(`You have sent a ping`);
          this.getUserInfo(this.selectedChannel);
        },
        error: (err: HttpErrorResponse) => {
          LoggerService.log('CustomerDetailsComponent', err);
          if (err.error?.code === 'PING_SUPPRESSION') {
            this.pingDialog.showMessage('Customer was already pinged recently');
          } else if (err.error?.code === 'BAD_CONSENT') {
            this.pingDialog.showMessage(
              'Customer has not given consent to recieve notifications',
            );
          } else {
            this.pingDialog.showMessage('There was an error sending the ping');
          }
        },
      });
  }

  handleLeave(): void {
    // .subscribe that takes separate callback arguments will be removed in v8. Details: https://rxjs.dev/deprecations/subscribe-arguments
    this.twoWayConversationService
      .leaveChannel(
        this.sessionService.currentUser.client.id,
        this.selectedChannel,
      )
      .subscribe({
        error: (err) => {
          this.leaveDialog.showMessage(
            `There was an issue leaving the conversation: ${err.error}`,
          );
          LoggerService.log('CustomerDetailsComponent', err);
        },
      });
  }

  handleClose(): void {
    this.twoWayConversationService
      .closeChannel(
        this.sessionService.currentUser.client.id,
        this.selectedChannel,
      )
      .subscribe({
        error: (err) => {
          this.closeDialog.showMessage(
            `There was an issue closing the conversation: ${err.error}`,
          );
          LoggerService.log('CustomerDetailsComponent', err);
        },
      });
  }

  leave(): void {
    this.leaveDialog.affirmButtonText = 'LEAVE CONVERSATION';
    this.leaveDialog.showMessage(`${this.leaveMessage}`);
  }

  close(): void {
    this.closeDialog.affirmButtonText = 'CLOSE CONVERSATION';
    this.closeDialog.showMessage(`${this.closeMessage}`);
  }

  invite(event: ButtonEvent): void {
    if (!this.inviteEmail) {
      return;
    }
    this.inviteToConversation(event);
  }

  private inviteToConversation(event: ButtonEvent) {
    const btn = event.currentTarget;
    btn.disabled = true;
    this.twoWayConversationService
      .inviteToChannel(
        this.sessionService.currentUser.client.id,
        this.selectedChannel,
        this.inviteEmail,
      )
      .subscribe({
        next: () => {
          this.inviteDialog.showMessage(
            `You have invited ${this.inviteEmail} to the conversation`,
          );
          this.inviteEmail = undefined;
          btn.disabled = false;
        },
        error: (err) => {
          const errMsg = err.error.error;
          switch (errMsg) {
            case 'new agent already in channel':
              this.inviteDialog.showMessage(
                `Error: This agent has already been invited to this channel`,
              );
              break;
            case 'new agent not in tag group':
              this.inviteDialog.showMessage(
                `Error: This agent is not in this tag group`,
              );
              break;
            case 'new agent not found':
              this.inviteDialog.showMessage(`Error: New agent is not found`);
              break;
            default:
              this.inviteDialog.showMessage(
                `There was an error inviting ${this.inviteEmail} to the conversation`,
              );
          }
          this.inviteEmail = undefined;
          LoggerService.log('CustomerDetailsComponent', err);
          btn.disabled = false;
        },
      });
  }

  smsNotificationChannels(): ChannelAddress[] {
    // excludes the rare "email" channel_type
    return this.userInfo.notification_channels.filter(
      (channel) => channel.channel_type === 'sms',
    );
  }

  private initListeners(): void {
    this.twoWayConversationService.clientEmitter.subscribe((event) =>
      this.conversationEventRouter(event),
    );
  }

  private getUserInfo(channel): void {
    this.getUserInfoError = undefined;
    this.userInfo = undefined;
    if (!channel) {
      return;
    }
    this.selectedChannel = channel;
    fromPromise(this.twoWayConversationService.getCustomerInfo())
      .pipe(
        // UserService calls a cdm endpoint that returns a Customer object that doesn't have all the
        // information that's available.
        switchMap((res) =>
          this.userService.getCustomerDetails(
            this.sessionService.getCurrentUsersClient().id,
            res['id'],
          ),
        ),
        switchMap((res) => {
          this.userInfo = res['customer'];
          // So we have to call this endpoint to get the rest of it.
          return this.userService.getCustomerDetailsByCcid(
            this.sessionService.getCurrentUsersClient().id,
            this.userInfo.ccid,
          );
        }),
        switchMap((res) => {
          this.userInfo = TwoWayCustomer.deserialize({
            ...this.userInfo,
            ...res['customer'],
          });
          return fromPromise(this.twoWayConversationService.getLastPinged());
        }),
      )
      .subscribe({
        next: (res) => (this.userInfo.lastPing = res),
        error: () => (this.getUserInfoError = true),
      });
  }

  private conversationEventRouter(event: any): void {
    switch (event.event_type) {
      case TwoWayConversationClientEvents.conversationLeft:
        this.conversationByeHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationRemoved:
        this.conversationByeHandler(event.event);
        return;
    }
  }

  private conversationByeHandler(event: TwilioConversation): void {
    if (!event || event.sid === this.selectedChannel) {
      this.selectedChannel = undefined;
    }
  }
}
