import React, { forwardRef } from 'react';
import { Button, ButtonProps } from 'baseui/button';

interface RelayButtonProps extends ButtonProps {
  dataTestid: string;
}

export const RelayButton = forwardRef<HTMLButtonElement, RelayButtonProps>(
  ({ dataTestid, children, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        {...rest}
        overrides={{
          BaseButton: {
            style: ({ $isSelected }) => {
              return {
                fontFamily: 'DIN-Condensed, sans-serif',
                fontSize: '14px',
                textTransform: 'uppercase',
                lineHeight: '1.33em',
                fontWeight: '400',
                paddingTop: '5px',
                paddingBottom: '5px',
                marginLeft: '-3px',
                width: '53px',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: $isSelected ? '#3693bf' : '#aaa',
                color: '#FFFFFF',
                ':hover': {
                  backgroundColor: $isSelected ? '#3693bf' : '#aaa',
                  zIndex: $isSelected ? 1 : 0,
                },
                ':active': {
                  backgroundColor: $isSelected ? '#3693bf' : '#aaa',
                },
                ':focus': {
                  outline: 'none',
                },
              };
            },
            props: {
              'data-testid': dataTestid,
            },
          },
        }}
      >
        {children}
      </Button>
    );
  },
);
