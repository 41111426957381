import axios from 'axios';
import { environment } from '@env/environment';
import {
  FormConfig,
  FormConfigRequest,
  RelayUnconfiguredForm,
} from '@app/client-config/client-form/client-power-ups-config/types/form-config';

export const client = axios.create({
  baseURL: environment.powerUpApiURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
  responseType: 'json',
});

let configSet: FormConfig[] = [];
let cacheClientId: string = '';
let cacheTimestamp = Date.now();

export class FormConfigService {
  async tryUpdateCache(clientId: string) {
    if (clientId !== cacheClientId || Date.now() - cacheTimestamp > 60000) {
      await this.updateCache(clientId);
    }
  }

  async updateCacheValue(
    clientId: string,
    configurations: FormConfig[],
  ) {
    configSet = configurations;
    cacheClientId = clientId;
    cacheTimestamp = Date.now();
  }

  async updateCache(clientId: string) {
    try {
      const response = await client.get<FormConfig[]>(
        `/form-config/${clientId}/forms`,
      );
      configSet = response.data;
      cacheClientId = clientId;
      cacheTimestamp = Date.now();
    } catch {
      alert('Could not get form configurations.');
    }
  }

  async getFormConfigSet(clientId: string) {
    await this.tryUpdateCache(clientId);
    return configSet;
  }

  async getFormConfig(clientId: string, uid: string) {
    try {
      await this.tryUpdateCache(clientId);
      const response = await client.get<FormConfig>(
        `/form-config/${clientId}/forms/${uid}`,
      );
      await this.updateCache(clientId);
      return response.data;
    } catch {
      alert('Could not get form configuration.');
    }
  }

  async getUnconfiguredForms(clientId: string) {
    try {
      await this.tryUpdateCache(clientId);
      const response = await client.get<RelayUnconfiguredForm[]>(
        `/${clientId}/forms/unconfigured`,
      );
      return response.data;
    } catch {
      alert('Could not get unconfigured forms.');
    }
  }

  async writeFormConfig(
    clientId: string,
    configuration: FormConfigRequest,
  ) {
    try {
      await this.tryUpdateCache(clientId);
      await client.post<FormConfig>(
        `/form-config/${clientId}/forms`,
        configuration,
      );
      await this.updateCache(clientId);
    } catch {
      alert('Could not create form configuration.');
    }
  }

  async setFormConfig(
    clientId: string,
    uid: string,
    configuration: FormConfigRequest,
  ) {
    try {
      await this.tryUpdateCache(clientId);
      await client.put<FormConfig>(
        `/form-config/${clientId}/forms/${uid}`,
        configuration,
      );
      await this.updateCache(clientId);
    } catch {
      alert('Could not update form configuration.');
    }
  }

  async deleteFormConfig(clientId: string, uid: string) {
    try {
      await this.tryUpdateCache(clientId);
      await client.delete(`/form-config/${clientId}/forms/${uid}`);
      await this.updateCache(clientId);
    } catch {
      alert('Could not delete form configuration.');
    }
  }
}
