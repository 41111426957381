export const validateName = (
  text: string,
  currentIntegrationName: string,
  integrationNames: string[],
) => {
  if (!text || text.trim() === '') {
    return 'Name is required';
  } else if (
    integrationNames
      ?.filter((n) => n !== undefined)
      .some(
        (p) =>
          p !== currentIntegrationName &&
          p.toLocaleLowerCase() === text.toLocaleLowerCase(),
      )
  ) {
    return 'Name must be unique within a client ID';
  } else {
    return '';
  }
};

export const validateUri = (text: string) => {
  const uriRegExp = /^https:\/\/([a-zA-Z0-9-!@#$%^&*_+\-={};':"\\|,.<>\/?])*$/;
  if (!text) {
    return 'URI/Endpoint is required';
  } else if (!uriRegExp.test(text)) {
    return 'URI/Endpoint must have a valid format: "https://"';
  } else {
    return '';
  }
};

export const validateApiKeyHeader = (text: string) => {
  if (!text || text.trim() === '') {
    return 'API Key Header is required';
  } else {
    return '';
  }
};

export const validateApiKey = (text: string) => {
  if (!text || text.trim() === '') {
    return 'API Key is required';
  } else {
    return '';
  }
};

export const validateUsername = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Username is required';
  } else {
    return '';
  }
};

export const validatePassword = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Password is required';
  } else {
    return '';
  }
};

export const validateHeaders = (text: string) => {
  let headersJson: string;
  try {
    headersJson = JSON.parse(text);
    if (
      Object.keys(headersJson).every((s) => typeof s === 'string') &&
      Object.values(headersJson).every((s) => typeof s === 'string')
    ) {
      return '';
    } else {
      return 'Header keys and values must all be strings.';
    }
  } catch (e) {
    return 'Headers must be a valid JSON object';
  }
};

export const validateHeadersArray = (
  allHeaders: Array<{ source: string; key: string; value: string }>,
) => {
  // if (allHeaders.length === 0) {
  //   return '';
  // }

  const validSources = ['headers', 'sensitiveHeaders'];
  const filteredHeaders = allHeaders.filter((header) =>
    validSources.includes(header.source),
  );

  if (filteredHeaders.length > 10) {
    return 'The should be no more than 10 sets of headers';
  }

  for (let header of filteredHeaders) {
    if (!header.key || !header.value) {
      return 'Header Name and Header Value should not be empty';
    }

    const keyRegExp = /^[a-zA-Z0-9_-]*$/;
    if (!keyRegExp.test(header.key)) {
      return 'Can only contain: a-z, A-Z, 0-9, _, -';
    }

    const valueRegExp = /^[a-zA-Z0-9_:;.,\\/"'?!(){}[\]@<>=\-+*#$&`|~^% ]*$/;
    if (!valueRegExp.test(header.value) || header.value.length > 1000) {
      return 'Can only contain: a-z, A-Z, and 0-9 and special characters: :;.,\\/"\'?!(){}[]@<>=-+*#$&`|~^% ';
    }

    const duplicateKey = filteredHeaders.find(
      (otherHeader) =>
        otherHeader !== header &&
        otherHeader.key === header.key &&
        otherHeader.source !== header.source,
    );
    if (duplicateKey) {
      return 'This header name already exists';
    }
  }

  return '';
};

export const validateHeaderName = (
  text: string,
  headers: Array<{ key: string; value: string }>,
  currentIndex: number,
) => {
  const headerNameRegExp = /^[a-zA-Z0-9_-]*$/;
  if (!headerNameRegExp.test(text)) {
    return 'Can only contain: a-z, A-Z, 0-9, _, -';
  } else if (text.length > 500) {
    return 'Header Name must have no more than 500 characters';
  } else if (
    headers.some(
      (header, index) =>
        index !== currentIndex &&
        header.key.toLowerCase() === text.toLowerCase(),
    )
  ) {
    return 'This header name already exists';
  } else {
    return '';
  }
};

export const validateHeaderValue = (text: string) => {
  const headerValueRegExp = /^[a-zA-Z0-9_:;.,\\/"'?!(){}[\]@<>=\-+*#$&`|~^% ]*$/;
  if (!headerValueRegExp.test(text)) {
    return 'Can only contain: a-z, A-Z, and 0-9 and special characters: :;.,\\/"\'?!(){}[]@<>=-+*#$&`|~^% ';
  } else if (text.length > 1000) {
    return 'Header Value must have no more than 1000 characters';
  } else {
    return '';
  }
};

export const validateBearerTokenUri = (text: string) => {
  const uriRegExp = /^https:\/\/([a-zA-Z0-9-!@#$%^&*_+\-={};':"\\|,.<>\/?])*$/;
  if (!text) {
    return 'Authentication URL is required';
  } else if (!uriRegExp.test(text)) {
    return 'Authentication URL must have a valid format: "https://"';
  } else {
    return '';
  }
};

export const validateClientSecret = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Client Secret is required';
  } else {
    return '';
  }
};

export const validateClientIdentity = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Client ID is required';
  } else {
    return '';
  }
};

export const validateGrantType = (value: string) => {
  if (!value) {
    return 'Grant Type is required';
  } else {
    return '';
  }
};

export const validateSaleforceUsername = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Saleforce Username is required';
  } else {
    return '';
  }
};

export const validateSaleforceConsumerKey = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Saleforce Consumer Key is required';
  } else {
    return '';
  }
};

export const validateJwtSigningSecret = (text: string) => {
  if (!text || text.trim() === '') {
    return 'JWT Signing Secret is required';
  } else {
    return '';
  }
};

export const validateSalesforceClientId = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Client ID is required';
  } else {
    return '';
  }
};

export const validateSalesforceClientSecret = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Client Secret is required';
  } else {
    return '';
  }
};

export const validateRestBaseUri = (text: string) => {
  const uriRegExp = /^https:\/\/([a-zA-Z0-9-!@#$%^&*_+\-={};':"\\|,.<>\/?])*$/;
  if (!text) {
    return 'REST Base URI is required';
  } else if (!uriRegExp.test(text)) {
    return 'REST Base URI must have a valid format: "https://"';
  } else {
    return '';
  }
};

export const validateAuthBaseUri = (text: string) => {
  const uriRegExp = /^https:\/\/([a-zA-Z0-9-!@#$%^&*_+\-={};':"\\|,.<>\/?])*$/;
  if (!text) {
    return 'Authentication Base URI is required';
  } else if (!uriRegExp.test(text)) {
    return 'Authentication Base URI must have a valid format: "https://"';
  } else {
    return '';
  }
};

export const validateAccountId = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Account ID is required';
  } else {
    return '';
  }
};

export const validateAuthHash = (text: string) => {
  if (!text || text.trim() === '') {
    return 'Auth Hash is required';
  } else {
    return '';
  }
};
