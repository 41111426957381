<div class="rn-page">
    <ng-container *ngIf="permissionService.checkPermissions(permissions.ui_sftp_mgmt_setup)">
        <div class="sftp-mgmt__title clearfix">
            <h3 class="sftp-mgmt__heading">Internal SFTP Set Up</h3>
            <div *ngIf="permissionService.checkPermissions(permissions.client_edit)" class="sftp-mgmt__toggle">
                <app-radio-btn (btnModelChange)="updateSftpAutoFileProcessing($event)"
                               [(btnModel)]="sftpAutoFileProcessing"
                               [btnLabel]="'Automated File Processing'"
                               [btnName]="'auto_file_processing'"
                               [btnWidth]="'300px'">
                </app-radio-btn>
            </div>
        </div>
    </ng-container>

    <client-header [allowExpand]="false" [headerLabel]="'SFTP Keys'"></client-header>
    <div *ngIf="hasKeyLoadingError" class="sftp-mgmt__message">There was an error fetching keys
        for {{client.company_name}}</div>

    <rn-sftp-key-list #keyListComponent (onDeleteConfirmed)="deleteKey(client.id, $event)"
                      (onSaveClicked)="handleSaveKey($event)"
                      [client]="client"
                      [hasUserLoadingError]="hasUserLoadingError"
                      [hasKeyLoadingError]="hasKeyLoadingError" [resultsDialog]="resultsDialog" [sftpKeys]="sftpKeys"
                      [sftpUser]="sftpUser"></rn-sftp-key-list>

    <message-dialog #resultsDialog></message-dialog>
</div>
