import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaService } from '@app/core/services/media.service';
import { SessionService } from '@app/security/session.service';
import React from 'react';
import ReactDOM from 'react-dom';
import WirePreview from '../wire-preview-react/WirePreview';
import {
  Message,
  buildFeedMessageStructure,
  MediaAsset,
} from '@relaynetwork/rn-node-feed-api-types';
import { Journey } from '@app/core/models/journey';
import { MessageClass, MessageExpirationClass } from '@app/core/models/message';
import { firstValueFrom, map } from 'rxjs';
import { BrandingData } from '@relaynetwork/design-system';
import { ProductGroup } from '@app/core/models/client';
import { ExpirationUnit } from '@relaynetwork/rn-node-feed-api-types/dist/routes/messages';

@Component({
  selector: 'wire-preview-react',
  templateUrl: './wire-preview-react-container.component.html',
  styleUrls: ['./wire-preview-react-container.component.scss'],
})
export class WirePreviewReactContainerComponent
  implements OnInit, AfterViewInit, OnDestroy, DoCheck
{
  constructor(
    private mediaService: MediaService,
    private sessionService: SessionService,
  ) {}
  clientId: string;
  reactWrapperId: string;
  messageToRender: Message;
  oldMessage: string;
  messageExpiration: MessageExpirationClass;
  @Input() message: MessageClass;
  @Input() components: Journey['live']['components'];
  @Input() brandingColor: string;
  @Input() disclaimerConfig: BrandingData['disclaimer'];
  @Input() productGroup: ProductGroup;
  @Input() previewMode: 'expanded' | 'collapsed';

  ngOnInit() {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.reactWrapperId = `${this.message.name}-wire-preview`;
  }

  getMediaAssetForMessage(assetId: string): Promise<MediaAsset> {
    return firstValueFrom(
      this.mediaService.getMediaAsset(assetId, true).pipe(
        map((response) => ({
          ...response,
          id: response.asset_id,
        })),
      ),
    ) as unknown as Promise<MediaAsset>;
  }
  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  renderMessage() {
    let messageExpiration;
    if (this.message.parent) {
      messageExpiration = this.components.find(
        (component) => this.message.parent === component.name,
      )?.message_expiration;
    } else {
      messageExpiration = {
        unit:
          this.message.message_expiration?.unit === 'days'
            ? ExpirationUnit.Day
            : ExpirationUnit.Week,
        amount: this.message.message_expiration?.amount || null,
        type: this.message.message_expiration?.type || null,
      };
    }
    buildFeedMessageStructure(
      this.clientId,
      this.components,
      this.message.name,
      (_, assetId) => this.getMediaAssetForMessage(assetId),
      this.productGroup,
      this.productGroup?.consent.in_wire_upgrade.ts_cs,
      messageExpiration,
    )
      .then((resp) => {
        this.messageToRender = resp;
        this.render();
      })
      .catch(console.warn);
  }
  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId && !!this.messageToRender;
  }

  ngDoCheck() {
    if (JSON.stringify(this.message) !== this.oldMessage) {
      this.oldMessage = JSON.stringify(this.message);
      this.renderMessage();
    }
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(WirePreview, {
          message: this.messageToRender,
          brandingColor: this.brandingColor,
          disclaimerConfig: this.disclaimerConfig,
          isDestinationMessageDetail:
            this.message.sms.auth_link === '@{auth-link}',
          previewMode: this.previewMode,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.renderMessage();
  }
}
