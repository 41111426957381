<div class="input-container" *ngIf="productGroup">
  <div class="input-control"
       [ngClass]="{'has-error': !helpTextForm.controls['help_instructions'].valid && helpTextForm.controls['help_instructions'].touched}">
    <label i18n="Label for product group help text">Product Group Help Text</label>
    <span class="error-msg"
          *ngIf="!helpTextForm.controls['help_instructions'].valid && helpTextForm.controls['help_instructions'].touched"
          placement="bottom">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
    <textarea spellcheck="false"
              id="product-group-help-text"
              [formControl]="helpTextForm.controls['help_instructions']"></textarea>
  </div>

  <div class="input-control">
    <label i18n="Label for product group help text">Product Group Spanish Help Text</label>
    <textarea spellcheck="false"
              id="product-group-spanish-help-text"
              [formControl]="helpTextForm.controls['help_instructions_spanish']"></textarea>
  </div>
</div>
