<div class="password-container">
  <app-banner-preview [productGroup]="productGroup" [viewProductInfo]="viewProductInfo"></app-banner-preview>
  <div class="wrapper">
    <form class="form-group">
      <div class="form-desc">
        <p class="message-text"
           *ngIf="!!productGroup.password.text"
           [innerHtml]="productGroup.password.text | safeHtml"></p>
      </div>
      <div class="form-input">
        <label>
          Password
        </label>
        <span>
            <input readonly type="text">
        </span>
      </div>

      <div *ngIf="productGroup.consent.in_wire_upgrade.enabled_on_set_password" class="form-input">
        <hr>
        <p
          class="consent-label"
          [innerHtml]="productGroup.consent.in_wire_upgrade.ts_cs | safeHtml">
        </p>
        <span class="checkbox">
          <input type="checkbox" id="upgrade_consent">
          I accept the terms and conditions
        </span>
        <hr>
      </div>

      <button type="submit">
        {{productGroup.password.label}}
        <i aria-hidden="true" class="fa fa-angle-right"></i>
      </button>
    </form>
  </div>
</div>
