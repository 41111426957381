import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpLibResponse, Industry } from '@app/core/models/categorization-types';
import { ActivatedRoute, Router } from '@angular/router';
import { CategorizationService } from '@app/core/services/categorization.service';
import { SessionService } from '@app/security/session.service';
import { SortableComponent } from '@app/core/sortable-component';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { LoggerService } from '@app/core/services/logger.service';
import { SortingUtils } from '@app/experience-library/utils/categorization-sorting-utils';

@Component({
  selector: 'rn-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.scss'],
})
export class IndustryListComponent extends SortableComponent implements OnInit {
  industries: Industry[];
  sortedIndustries: Industry[];
  isLoaded: boolean = false;
  hasError: boolean = false;
  clientId: string;
  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private sessionService: SessionService, 
    private categorizationService: CategorizationService
  ) {
    super();
  }
  
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  ngOnInit() {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.getIndustries();
  }

  navigateToAdd() {
    this.router.navigate(['add-industry'], { relativeTo: this.activatedRoute });
  }

  navigateToEdit(industryId: string) {
    this.router.navigate([`edit-industry/${industryId}`], { relativeTo: this.activatedRoute });
  }

  /**
   * Retrieve all industries from experience library service
   */

  getIndustries() {
    this.categorizationService.getIndustries(this.clientId).subscribe(
      (industries: ExpLibResponse<Industry[]>) => {
        this.industries = industries.data;
        this.resetSort();
        this.isLoaded = true;
      }, 
      (error) => {
        this.processIndustriesError(error);
        this.isLoaded = true;
      });
  }

  industriesLoaded(): boolean {
    return this.industries !== undefined;
  }

  noIndustriesFound(): boolean {
    return (this.isLoaded && !this.hasError && this.industries.length === 0);
  }

  processIndustriesError(error): void {
    this.hasError = true;
    this.messageDialog.showMessage('An error occured with your request, please try again later.');
    LoggerService.log('industry.component', `industries for clientId ${this.clientId} error: ${JSON.stringify(error)}`);
  }

  fetchData() {
    if (this.ordering === undefined) {
      this.resetSort();
    } else if (this.ordering.orderBy === 'id') {
      this.sortedIndustries = SortingUtils.sortById(this.sortedIndustries, this.ordering.direction);
    } else if (this.ordering.orderBy === 'title') {
      this.sortedIndustries = SortingUtils.sortByTitle(this.sortedIndustries, this.ordering.direction);
    } else if (this.ordering.orderBy === 'created_at') {
      this.sortedIndustries = SortingUtils.sortByDate(this.sortedIndustries, this.ordering.direction, 'created_at');
    } else if (this.ordering.orderBy === 'updated_at') {
      this.sortedIndustries = SortingUtils.sortByDate(this.sortedIndustries, this.ordering.direction, 'updated_at');
    }
  }

  private resetSort(): void {
    // map is required to ensure that the sorted array isn't an in-place reference to the original
    this.sortedIndustries = this.industries.map(item => item);
  }
}
