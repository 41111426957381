<div class="select-container clearfix">
  <label>{{ this.client.feed_enabled ? 'Notification' : 'SMS' }} Archive</label>
  <select
    class="form-control capture-consent-select"
    [(ngModel)]="smsArchive"
    (ngModelChange)="onChange()"
  >
    <option value="">None</option>
    <option value="global-relay">Global Relay</option>
  </select>
</div>

<form *ngIf="smsArchive" [formGroup]="form">
  <rn-text-input
    [inputControl]="form.get('host')"
    [inputLabel]="'Host'"
  ></rn-text-input>
  <rn-text-input
    [inputControl]="form.get('user')"
    [inputLabel]="'User'"
  ></rn-text-input>
  <rn-text-input
    [type]="'password'"
    [inputControl]="form.get('pass')"
    [inputLabel]="'Password'"
  ></rn-text-input>
  <rn-text-input
    [type]="'number'"
    [inputControl]="form.get('port')"
    [inputLabel]="'Port'"
  ></rn-text-input>
  <div class="styled-checkbox">
    <input
      type="checkbox"
      id="inputName"
      name="inputName"
      [formControl]="form.get('secure')"
    />
    <label for="inputName">Secure</label>
  </div>
</form>
