export class Conversions {
  
   /** Null/undefined safe conversion of any object to a string. */
  static toString(obj: Object): string {
    
    if (obj !== undefined && obj !== null) {
      return obj.toString();
    } else {
      return null;
    }
  }
}
