export class VideoUtils {

  static readonly whitelist = [/^youtube.com$/, /^www.youtube.com$/, /^youtu.be$/, /^www.youtu.be$/, /^vimeo.com$/, /^www.vimeo.com$/, /^optimum.net$/, /^www.optimum.net$/, /wistia.com$/, /wi.st/, /^players.brightcove.net$/, /^v.idomoo.com$/];

  static readonly sharedFormatUrl = /^https:\/\/youtu\.be\/(\S+)$/;
  static readonly embeddedFormatUrl = /^https:\/\/www\.youtube\.com\/embed\/(\S+)$/;
  static readonly linkFormatUrl = /^https:\/\/www\.youtube\.com\/watch\?v\=(\S+)$/;
  static readonly isYoutubeUrl = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S*)?$/;
  static readonly wistiaRegex = /https?:\/\/(?:.+)?(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(.{10}).*?/;
  static readonly removeVimeoPrivateUrlCode = /(https:\/\/vimeo\.com\/.*)\/.*/;
  static readonly captureQueryParams = /.*(\?.*$)/;

  static readonly hapYakLogoURL = 'assets/hapyak-logo.png';
  static readonly wistiaLogoURL = 'assets/wistia-logo.png';

  static convertToEmbeddedFormat(videoURL: string): string {
    if (videoURL.indexOf('vimeo') > -1) {
      return this.formatVimeoUrl(videoURL);
    }

    if (VideoUtils.isYoutubeUrl.exec(videoURL)) {
      return this.formatYoutubeUrl(videoURL);
    }

    return videoURL;
  }

  static formatVimeoUrl(videoURL: string): string {
    // https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Player-parameters-overview
    // Unlisted video urls have 2 ids separated by '/' and are separated by '?h=' in the corresponding embed code url 
    // Embed parameters or URLParams or Player parameters are appended at the end of unlisted video urls, spearated by '?'
    // The same params are appended aafter video id, spearated by '?', and before the private hash (separated by '&h=') for embed code url
    const vimeoBaseURL = 'https://vimeo.com/';
    if (videoURL.startsWith(vimeoBaseURL)) {
      const videoPath = videoURL.substring(vimeoBaseURL.length);
      const [videoIdsPath = '', urlParams = ''] = videoPath.split('?', 2);
      const [videoId = '', unlistedVideoHash = ''] = videoIdsPath.split('/');
      let unlistedEmbedURL = vimeoBaseURL + videoId;
      if (urlParams) {
        unlistedEmbedURL += '?' + urlParams;
        if (unlistedVideoHash) {
          unlistedEmbedURL += '&h=' + unlistedVideoHash;
        }
      }
      else {
        if (unlistedVideoHash) {
          unlistedEmbedURL += '?h=' + unlistedVideoHash;
        }
      }
      videoURL = unlistedEmbedURL;
    }

    return videoURL.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
  }

  static formatYoutubeUrl(videoURL: string): string {
    const shareMatch = VideoUtils.sharedFormatUrl.exec(videoURL);
    const embedMatch = VideoUtils.embeddedFormatUrl.exec(videoURL);
    const linkMatch = VideoUtils.linkFormatUrl.exec(videoURL);

    if (shareMatch) {
      return `https://www.youtube.com/embed/${shareMatch[1]}`;
    } else if (embedMatch) {
      return videoURL;
    } else if (linkMatch) {
      return `https://www.youtube.com/embed/${linkMatch[1].replace('&', '?')}`;
    } else {
      return undefined;
    }
  }

  static forceHttps(videoURL): string {
    if (!!videoURL) {
      let url: URL = null;
      if(!/^https?:\/\//.test(videoURL)) {
        url = new URL(`https://${videoURL}`);
      }
      else {
        url = new URL(videoURL)
      }
      if (url.protocol !== 'https') {
        url.protocol = 'https';
      }
      return url.href;
    }
  }
}
