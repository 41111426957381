import { Injectable } from '@angular/core';
import { Journey, JourneyContent } from '../../core/models/journey';
import { JourneyService } from '../../core/services/journey.service';
import { ToolbarService } from './toolbar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AutosaveService {

  private journey: Journey;
  private saveInProgress: boolean = false;
  private lastSavedContent: string;
  private intervalId: any;
  private readonly autosaveInterval: number = 10e3;

  constructor(private journeyService: JourneyService, private toolbarService: ToolbarService) {
  }

  get currentContent() {
    if (this.journey) {
      return JSON.stringify(this.journey.latestContent.serialize());
    } else {
      return '';
    }
  }

  get saving() {
    return this.saveInProgress;
  }

  enableAutosave(journey: Journey): void {
    this.journey = journey;
    this.lastSavedContent = JSON.stringify(this.journey.latestContent.serialize());
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
    this.intervalId = window.setInterval(() => this.saveIfNeeded(), this.autosaveInterval);
  }

  disableAutosave(): void {
    this.journey = null;
    this.lastSavedContent = null;
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  isSaveNeeded(): boolean {
    return this.currentContent !== this.lastSavedContent;
  }

  save(supressLoadingMask: boolean = true): void {
    if (!this.journey) {
      return;
    }
    this.setupDraft();
    this.saveInProgress = true;
    this.journeyService.updateJourney(this.journey.id, this.journey, supressLoadingMask).subscribe(
      () => this.handleSaveSuccess(),
      (error: HttpErrorResponse) => this.handleSaveError(error)
    );
  }

  saveIfNeeded(): void {
    if (!this.saveInProgress && this.isSaveNeeded()) {
      this.save();
    }
  }

  private handleSaveSuccess(): void {
    this.lastSavedContent = this.currentContent;
    this.toolbarService.inspectorSave.emit();
    this.saveInProgress = false;
  }

  // note: legacy: we arent calling a message dialog service directly
  private handleSaveError(error: HttpErrorResponse): void {
    const errBody = error.error;
    if (error.status === 409 && errBody.response && errBody.response.indexOf('Mismatch') > -1) {
      this.toolbarService.inspectorUpdateConflict.emit('Oops...could not save experience because a new version is available.');
    } else if (error.status === 409 && errBody.message && errBody.message) {
      this.toolbarService.inspectorUpdateConflict.emit(errBody.message);
    } else if (error.status !== 401) {
      this.toolbarService.inspectorError.emit('Oops...could not save experience.');
    }
    this.saveInProgress = false;
  };

  private setupDraft(): void {
    if (!this.journey.draft) {
      this.journey.draft = Object.assign(new JourneyContent(), this.journey.live);
    }
  }
}
