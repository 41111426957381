import React from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { Check, ChevronRight } from 'baseui/icon';

export enum AgentNameAscendingDescendingMethods {
  Ascending = 'A-Z',
  Descending = 'Z-A',
  Name = 'Name',
}

interface SortingActionItemProps {
  color?: string;
  dataTestId?: string;
  icon?: () => React.ReactNode;
  sortingName: AgentNameAscendingDescendingMethods.Ascending | AgentNameAscendingDescendingMethods.Descending | AgentNameAscendingDescendingMethods.Name;
  setSortingName: (value: AgentNameAscendingDescendingMethods.Ascending | AgentNameAscendingDescendingMethods.Descending | AgentNameAscendingDescendingMethods.Name) => void;
  close: () => void;
  clearFilterState: () => void;
  clearSortingSelection: () => void;
}

export const SortAgentNameAction = ({
  sortingName,
  setSortingName,
  close,
  clearFilterState,
  clearSortingSelection,
}: SortingActionItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  return (
    <StatefulPopover
      content={() => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          <Button
            onClick={() => {
              setSortingName(AgentNameAscendingDescendingMethods.Ascending);
              close();
            }}
            value={AgentNameAscendingDescendingMethods.Ascending}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  display: 'flex',
                  color:
                    sortingName &&
                    sortingName === AgentNameAscendingDescendingMethods.Ascending
                      ? '#3693BF'
                      : 'black',
                  fontWeight:
                    sortingName &&
                    sortingName === AgentNameAscendingDescendingMethods.Ascending
                      ? 'bolder'
                      : 'inherit',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                  justifyContent: 'left',
                  fontSize: '12px',
                  outline: 'none !important',
                  ':hover': {
                    backgroundColor: '#E1EFF5',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                  },
                },
                props: {
                  'data-testid': `agent-name-ascending`,
                },
              },
            }}
            padding={'10px'}
            startEnhancer={() =>
              sortingName && sortingName === AgentNameAscendingDescendingMethods.Ascending ? (
                <Check
                  size={'20px'}
                  color="#3693BF"
                  overrides={{
                    Svg: {
                      style: {
                        display: 'flex',
                      },
                    },
                  }}
                />
              ) : (
                ''
              )
            }
          >
            {AgentNameAscendingDescendingMethods.Ascending}
          </Button>
          <Button
            onClick={(e) => {
              setSortingName(AgentNameAscendingDescendingMethods.Descending);
              close();
            }}
            value={AgentNameAscendingDescendingMethods.Descending}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  display: 'flex',
                  color:
                    sortingName &&
                    sortingName === AgentNameAscendingDescendingMethods.Descending
                      ? '#3693BF'
                      : 'black',
                  fontWeight:
                    sortingName &&
                    sortingName === AgentNameAscendingDescendingMethods.Descending
                      ? 'bolder'
                      : 'inherit',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                  justifyContent: 'left',
                  fontSize: '12px',
                  outline: 'none !important',
                  ':hover': {
                    backgroundColor: '#E1EFF5',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                  },
                },
                props: {
                  'data-testid': `agent-name-descending`,
                },
              },
            }}
            padding={'10px'}
            startEnhancer={() =>
              sortingName && sortingName === AgentNameAscendingDescendingMethods.Descending ? (
                <Check
                  size={'20px'}
                  color="#3693BF"
                  overrides={{
                    Svg: {
                      style: {
                        display: 'flex',
                      },
                    },
                  }}
                />
              ) : (
                ''
              )
            }
          >
            {AgentNameAscendingDescendingMethods.Descending}
          </Button>
        </Block>
      )}
      placement={PLACEMENT.right}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          props: { 'data-testid': 'sorting-name-popover' },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          onClick={() => {
            clearSortingSelection();
          }}
          value={AgentNameAscendingDescendingMethods.Name}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: sortingName
                  ? '#3693BF'
                  : 'black',
                fontWeight: sortingName
                  ? 'bolder'
                  : 'inherit',
                backgroundColor: sortingName
                  ? '#E1EFF5'
                  : 'transparent',
                textAlign: 'left',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'name-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            sortingName &&
            (sortingName === AgentNameAscendingDescendingMethods.Ascending || sortingName === AgentNameAscendingDescendingMethods.Descending) ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {AgentNameAscendingDescendingMethods.Name}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
