// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';

// Data Models
import { ProductGroup } from '../../../../core/models/client';

@Component({
  selector: 'app-product-consumer-password-required',
  templateUrl: './product-consumer-password-required.component.html',
  styleUrls: ['./product-consumer-password-required.component.scss']
})
export class ProductConsumerPasswordRequiredComponent implements OnInit {
  @Input() product: ProductGroup;
  
  constructor() { }

  ngOnInit() {
  }

}
