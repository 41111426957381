<div class="clearfix">
  <div class="tag-name-container">
    <label type="text" for="tag-name">Tag Name</label>
    <div class="tag-name">{{tag.controls['tag_name'].value}}</div>
  </div>

  <div class="auto-response-checkbox-container">
    <label class="auto-response-label" type="text" for="tag-auto-response">Auto-response?</label>
    <input type="checkbox" [checked]="!tag.controls['tag_autoresponse'].disabled ? true : null" (click)="toggleAutoResponse($event, tag)" />
  </div>

  <div class="remove-icon-container">
    <i class="fa fa-minus-circle" (click)="removeTag()"></i>
  </div>
</div>

<ng-container *ngIf="!tag.controls['tag_autoresponse'].disabled">
  <label class="auto-response-label" type="text" for="tag-auto-response">auto response</label>
  <p>Customers receiving messages with the '{{tag.controls['tag_name'].value}}' tag will receive this auto response when they start a conversation during working hours.</p>
  <textarea class="auto-response-field" [class.has-error]="tag.controls['tag_autoresponse'].invalid && tag.controls['tag_autoresponse'].touched" name="tag-auto-response" [formControl]="tag.controls['tag_autoresponse']"></textarea>
</ng-container>
