<div class="row">
    <ng-container *ngFor="let heading of headings">
        <div [id]="prefix + heading" class="col" (click)="headingClick(heading)">
            <ng-container *ngIf="!isBlank(heading)">
                <div class="heading-text">
                    <span>{{heading | titlecase}}</span>
                </div>
                <app-column-sort-icon [id]="'sort-icon-' + prefix + heading" [ordering]="ordering" [fieldName]="heading"></app-column-sort-icon>
            </ng-container>
        </div>
    </ng-container>
</div>