import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry, CompanyType, ExpLibResponse } from '@app/core/models/categorization-types';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { take } from 'rxjs/operators';
import { CategorizationService } from '@app/core/services/categorization.service';
import { Observable } from 'rxjs';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'rn-company-type-add-edit',
  templateUrl: './company-type-add-edit.component.html',
  styleUrls: ['./company-type-add-edit.component.scss'],
})
export class CompanyTypeAddEditComponent implements OnInit {
  @ViewChild('updateErrorDialog') updateErrorDialog: MessageDialogComponent;
  @ViewChild('loadErrorDialog') loadErrorDialog: MessageDialogComponent;
  clientId: string;
  companyTypeId: string | undefined;
  industryOptions: Industry[]; 
  companyTypes: CompanyType[];
  form: UntypedFormGroup;

  constructor(
    private sessionService: SessionService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private titleService: TitleService,
    private categorizationService: CategorizationService
  ) {}

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.companyTypeId = this.activatedRoute.snapshot.params['company-type-id']

    this.categorizationService.getIndustries(this.clientId).pipe(
      take(1)
    ).subscribe((response: ExpLibResponse<Industry[]>) => {
      this.industryOptions = response.data;
    }, (error) => {
      this.loadErrorDialog.showMessage('There was an error loading this company type.  Please try again.')
    });

    if (this.isNew()) {
      this.setupNew();
    } else {
      this.setupExisting();
    }
  }

  get companyTypeIdCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('id');
  }

  get companyTypeNameCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('name');
  }

  get industryCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('industries');
  }

  get templateClientCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('template_client');
  }

  isNew(): boolean {
    return this.companyTypeId === undefined;
  }

  close(): void {
    this.router.navigateByUrl('/experience-library/admin?tab=company-type-tab')
  }

  saveAndClose(): void {
    if (this.form.invalid) { 
      this.form.markAllAsTouched();
      return; 
    }
    let request: Observable<ExpLibResponse<CompanyType>>;
    const newCompanyType: CompanyType = {
      title: {
        'en-us': this.form.get('name').value
      },
      id: this.form.get('id').value,
      ancestor_paths: this.form.get('industries').value,
      template_client: this.form.get('template_client').value
    };

    if (this.isNew()) {
      request = this.categorizationService.addCompanyType(
        this.clientId, 
        newCompanyType
      );
    } else {
      request = this.categorizationService.updateCompanyType(
        this.clientId, 
        newCompanyType.id, 
        newCompanyType
      );
    }

    request.subscribe((result) => {
      this.close();
    }, (error) => {
      this.updateErrorDialog.showMessage(`There was an error ${this.isNew() ? 'creating' : 'updating'} this company type.  Please try again.`)
    });
  }

  isReady(): boolean {
    return this.form !== undefined;
  }

  private setupNew(): void {
    this.titleService.activate('Add Company Type');
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      industries: new UntypedFormControl('', Validators.required),
      template_client: new UntypedFormControl('', Validators.required),
    })
  }

  private setupExisting(): void {
    this.titleService.activate('Edit Company Type');
    this.categorizationService.getCompanyType(this.clientId, this.companyTypeId).pipe(
      take(1)
    ).subscribe((companyType: ExpLibResponse<CompanyType>) => {
      this.form = new UntypedFormGroup({
        id: new UntypedFormControl(companyType.data.id), // not required because its disabled
        name: new UntypedFormControl(companyType.data.title['en-us'], Validators.required),
        industries: new UntypedFormControl(companyType.data.ancestor_paths, Validators.required),
        template_client: new UntypedFormControl(companyType.data.template_client, Validators.required),
      });
      this.companyTypeIdCtl.disable();
    }, (error) => { 
      this.loadErrorDialog.showMessage('There was an error loading this company type. Please try again.')
    });
  }
}
