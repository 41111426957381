<ng-container *ngIf="isLoggedIn && !previewMode">
    <app-side-nav></app-side-nav>
    <app-header></app-header>
</ng-container>
<div [ngClass]="getCssClasses()" [class.loaded]="!loadingMaskComponent.loadingMaskService.loadingMaskOn" [class.loading]="loadingMaskComponent.loadingMaskService.loadingMaskOn">
    <router-outlet></router-outlet>
</div>
<message-dialog></message-dialog>
<app-loading-mask></app-loading-mask>
<rn-timer-popup></rn-timer-popup>
