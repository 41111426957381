<div class="start-container">
  <div class="start">
    <div class="start">
      <img src="assets/hex/conditions.png"/>
    </div>
  </div>
  <div #triggerBox class="messageBox trigger-box with-box-menu clearfix" [class.selected]="selected">

    <box-menu #boxMenu [menuItems]="boxMenuItems" [selected]="selected"></box-menu>

    <div class="messageTop with-box-menu">
      <div class="messageNumber">{{trigger.step}}</div>
      <span class="title">{{trigger.nickname}}</span>
    </div>
    <div>
      <p id="trigger-text" class="message-text">{{displayableTrigger()}}</p>
    </div>
  </div>
</div>
