import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Client, ProductGroup } from '@app/core/models/client';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'app-product-group-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class ProductGroupFeaturesComponent implements OnInit {

  @Input() productGroup: ProductGroup;
  @Input() client: Client;

  @ViewChild(MessageDialogComponent) messageDialog: MessageDialogComponent;

  headerGroups = {features: true};
  passwordForm: UntypedFormGroup;
  iwcuForm: UntypedFormGroup;

  setPasswordSubmitButtonDefaultText: string = "Submit";


  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.passwordForm = this.client.feed_enabled
      ? this.formBuilder.group({
          'text': new UntypedFormControl(
            this.productGroup.password.text,
            [Validators.required, Validators.maxLength(CharacterLimits.PasswordRequiredMessageText)]
          ),
          'label': new UntypedFormControl(
            this.productGroup.password.label,
            [Validators.required, Validators.maxLength(CharacterLimits.PasswordRequiredSubmitButtonLabel)]
          )
        })
      : this.formBuilder.group({
        'text': [this.productGroup.password.text],
        'label': [this.productGroup.password.label, Validators.required]
      });

    this.iwcuForm = this.formBuilder.group({
      'ts_cs': [this.productGroup.consent.in_wire_upgrade.ts_cs]
    });

    // not all the field values are on the form
    this.passwordForm.valueChanges.subscribe(() => {
      this.productGroup.password.text = this.passwordForm.controls['text'].value;
      this.productGroup.password.label = this.passwordForm.controls['label'].value;
    });

    // not all the field values are on the form
    this.iwcuForm.valueChanges.subscribe(() => {
      this.productGroup.consent.in_wire_upgrade.ts_cs = this.iwcuForm.controls['ts_cs'].value
    });
  }

  passwordHasTextError(): boolean {
    return this.productGroup.password.enabled &&
      ((this.productGroup.password.text?.length ?? 0) === 0 &&
        this.passwordForm.controls['text'].dirty);
  }

  iwcuHasFormError(): boolean {
    return !ProductGroup.validInWireConsent(this.productGroup);
  }

  showTC(): boolean {
    return (this.productGroup.consent.in_wire_upgrade.enabled_on_set_password && this.productGroup.password.enabled)
      || this.productGroup.consent.in_wire_upgrade.enabled;
  }

  getCharacterLimit(form: string) {
    switch (form) {
      case 'setPasswordText':
        return CharacterLimits.PasswordRequiredMessageText;
      case 'setPasswordSubmitButtonLabel':
        return CharacterLimits.PasswordRequiredSubmitButtonLabel;
    }
  }
}
