import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, empty, from as fromPromise } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@app/core/services/logger.service';
import { SessionService } from '@app/security/session.service';
import { environment } from '@env/environment';
import {
  TwoWayConversationClientEvents,
  SortDirection,
  ConversationUpdatedEvent,
  ParticipantUpdatedEvent,
  MessageUpdatedEvent,
  UserUpdatedEvent,
  TwilioConversation,
} from '@app/two-way/twilio-conversation.types';
import { SecureHttp } from '@app/security/secure-http';
import {
  Client as TwilioClient,
  Message,
  Paginator,
  Participant,
  User,
} from '@twilio/conversations';
import * as _ from 'lodash';

export type TwilioMemberDetails = {
  id: string; // uuid
  first_name: string;
  last_name: string;
};

@Injectable()
export class TwoWayConversationService {
  clientEmitter: EventEmitter<any> = new EventEmitter();

  private chatClient: TwilioClient;
  private chatClientId: string;
  private channel: TwilioConversation;
  private jwt: string;
  private userInfoCache: any = {};
  private idCache: any = {};

  constructor(
    private secureHttp: SecureHttp,
    private http: HttpClient,
    private sessionService: SessionService,
  ) {}

  /*******************
   * Twilio wrappers *
   *******************/

  getClient(clientId: string): Observable<any> {
    if (
      this.chatClientId === clientId &&
      !!this.chatClient &&
      this.chatClient.connectionState === 'connected'
    ) {
      return of(this.chatClient);
    }
    this.chatClientId = clientId;
    return new Observable((subscriber) => {
      this.chatClient = new TwilioClient(this.jwt);
      this.chatClient.on(
        TwoWayConversationClientEvents.stateChanged,
        (state) => {
          if (state === 'initialized') {
            this.listenToClientMessages();
            subscriber.next();
          } else if (state === 'failed') {
            LoggerService.log(
              'RelayMessengerConversationservice',
              'Failed to initialize twilio client',
            );
            subscriber.error('Failed to initialize twilio client');
          }
        },
      );
    });
  }

  getAssignedChats(): Observable<Paginator<TwilioConversation>> {
    return fromPromise(
      this.chatClient.getSubscribedConversations() as Promise<
        Paginator<TwilioConversation>
      >,
    );
  }

  getCurrentTagId(): string {
    return _.get(this.channel, 'attributes.tags');
  }

  getConversationBySid(channel_sid: string): Promise<TwilioConversation> {
    return this.chatClient.getConversationBySid(
      channel_sid,
    ) as Promise<TwilioConversation>;
  }

  selectChannel(channel_sid: string): Observable<TwilioConversation> {
    return new Observable((subscriber) => {
      this.getConversationBySid(channel_sid)
        .then((channel) => {
          this.channel = channel;
          subscriber.next(channel);
        })
        .catch((err) => {
          LoggerService.log(
            'RelayMessengerConversationservice',
            `Error joining channel: ${err}`,
          );
          subscriber.error(err);
        });
    });
  }

  sendMessage(message: string, v3?: boolean | null): void {
    if (v3) {
      this.channel.sendMessage(message, {
        tag_id: this.getCurrentTagId(),
        v3: { encrypted: true },
      });
    } else {
      this.channel.sendMessage(message, {
        v2: { encrypted: true },
        tag_id: this.getCurrentTagId(),
      });
    }
  }

  sendTyping(): void {
    this.channel.typing();
  }

  setAllMessagesConsumed(): void {
    this.channel.setAllMessagesRead();
    this.channel.updateLastReadMessageIndex(this.channel.lastMessage.index);
  }

  getMessages(
    pageSize: number = 30,
    anchor: number = 0,
    direction: SortDirection = 'backwards',
  ): Promise<Paginator<Message>> {
    return this.channel.getMessages(pageSize, anchor, direction);
  }

  getDisplayName(identity: string): Promise<void> {
    if (this.userInfoCache[identity]) {
      return new Promise((resolve) => resolve(this.userInfoCache[identity]));
    }
    return this.chatClient
      .getUser(identity)
      .then((user) => {
        const name =
          _.get(user, 'attributes.customer.first_name') ||
          _.get(user, 'attributes.agent.first_name') ||
          'system';
        this.userInfoCache[identity] = name;
        return name;
      })
      .catch(() => 'system');
  }

  getCustomerInfo(): Promise<TwilioMemberDetails | void> {
    if (!this.channel) {
      return new Promise<void>((resolve) => resolve());
    }
    return this.channel.getParticipants().then((members) => {
      let customerInfo;

      // We can safely assume that there will only be one member in a channel
      // with a `customer` attrubute.  here should never be more than one customer
      // in a chat.
      _.each(members, (member: Participant) => {
        if (_.get(member, 'attributes.customer')) {
          customerInfo = _.get(member, 'attributes.customer');
        }
      });
      return customerInfo;
    });
  }

  getLastPinged(): Promise<any> {
    if (!this.channel) {
      return new Promise<void>((resolve) => resolve());
    }
    return this.channel
      .getAttributes()
      .then((res) => _.get(res, 'last_ping'))
      .catch((err) => {
        LoggerService.log('RelayMessengerConversationservice', err);
        return undefined;
      });
  }

  shutdownChatClient(): Promise<void> {
    if (this.chatClient) {
      return this.chatClient.shutdown();
    } else {
      return new Promise<void>((resolve) => resolve());
    }
  }

  killChatClient(): void {
    this.chatClient = undefined;
    this.channel = undefined;
  }

  /****************
   * HTTP methods *
   ****************/

  provisionTwoWay(clientId: string): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/provision`;
    return this.secureHttp.post(url, {}, {}, true);
  }

  getAgentToken(clientId: string): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/agent/token`;
    return this.secureHttp
      .post(url, {}, {}, true)
      .pipe(map((res) => (this.jwt = res['jwt'])));
  }

  pingUser(clientId: string, channel_sid: string): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/ping`;
    return this.secureHttp.post(url, { channel_sid }, {}, true);
  }

  getUnassignedChats(
    clientId: string,
    filters: { tags: string[]; launched_by: string[] },
    limit: number = 50,
    offset: number = 0,
  ): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/channels?limit=${limit}&offset=${offset}`;
    return this.secureHttp.post(
      url,
      { tags: filters.tags, launched_by: filters.launched_by },
      {},
      true,
    );
  }

  /**
   * Fetch all unassigned Two-Way chats for a client.
   *
   * @param clientId the client to fetch chats for
   * @param filters narrow the number of chats that are returned
   * @param batchSize since the function makes multiple API calls, this value is how many chats to request per call
   * @param offset recursive calls use this to progress through the total set of chats
   * @param accumulator recursive calls use this to keep track of the current set of chats before making subsequent calls
   * @returns an array of the unassigned chats meeting the provided criteria
   */
  getAllUnassignedChats(
    clientId: string,
    filters: { tags: string[]; launched_by: string[] },
    batchSize: number = 50,
    offset: number = 0,
    accumulator: any[] = [],
  ): Observable<any> {
    const arr = _.cloneDeep(accumulator);

    return fromPromise(
      this.getUnassignedChats(clientId, filters, batchSize, offset)
        .toPromise()
        .then((chats) => {
          arr.push(...chats['data']);

          if (chats['data'].length === batchSize) {
            return this.getAllUnassignedChats(
              clientId,
              filters,
              batchSize,
              arr.length,
              arr,
            ).toPromise();
          } else {
            return arr;
          }
        }),
    );
  }

  joinChannel(channel_sid: string): Observable<Object> {
    const url = `${environment.twoWayURLBase}/client/${
      this.sessionService.getCurrentUsersClient().id
    }/agent/channel/join`;
    return this.secureHttp.post(url, { channel_sid }, {}, true);
  }

  leaveChannel(clientId: string, channel_sid: string): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/agent/channel/leave`;
    return this.secureHttp.post(url, { channel_sid }, {}, true);
  }

  closeChannel(clientId: string, channel_sid: string): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/agent/channel/close`;
    return this.secureHttp.post(url, { channel_sid }, {}, true);
  }

  inviteToChannel(
    clientId: string,
    channel_sid: string,
    email_address: string,
  ): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/agent/invite`;
    return this.secureHttp.post(url, { channel_sid, email_address }, {}, true);
  }

  getId(clientId: string, channel_sid: string): Observable<string> {
    if (this.idCache[channel_sid]) {
      return of(this.idCache[channel_sid]);
    }
    const url = `${environment.twoWayURLBase}/client/${clientId}/channel/${channel_sid}`;
    return this.secureHttp.get(url, {}, true).pipe(
      map((res) => {
        this.idCache[channel_sid] = res['id'];
        return res['id'];
      }),
    );
  }

  getLaunchedBy(clientId: string, limit = 20, offset = 0): Observable<any> {
    const url = `${environment.twoWayURLBase}/client/${clientId}/launched_by?limit=${limit}&offset=${offset}`;
    return this.http.get(url).pipe(
      // temp fix - api returns results both with and without 'data' key.  fix is in-progress.
      map((result) => {
        const res = result['data'] ? result['data'] : result;
        return _.filter(res, (lb) => lb.lb_name && lb.lb_name.length);
      }),
    );
  }

  getAllLaunchedBy(clientId: string, limit = 20, offset = 0): Observable<any> {
    return this.getLaunchedBy(clientId, limit, offset).pipe(
      expand((results, i) =>
        results.length === limit
          ? this.getLaunchedBy(clientId, limit, (i + 1) * limit)
          : empty(),
      ),
    );
  }

  /*************
   * listeners *
   *************/

  private listenToClientMessages(): void {
    this.chatClient.on(
      TwoWayConversationClientEvents.connectionStateChanged,
      (event: any) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.connectionStateChanged,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.conversationAdded,
      (event: TwilioConversation) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.conversationAdded,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.conversationJoined,
      (event: TwilioConversation) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.conversationJoined,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.conversationLeft,
      (event: TwilioConversation) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.conversationLeft,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.conversationRemoved,
      (event: TwilioConversation) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.conversationRemoved,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.conversationUpdated,
      (event: ConversationUpdatedEvent) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.conversationUpdated,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.participantJoined,
      (event: Participant) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.participantJoined,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.participantLeft,
      (event: Participant) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.participantLeft,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.participantUpdated,
      (event: ParticipantUpdatedEvent) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.participantUpdated,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.messageAdded,
      (event: Message) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.messageAdded,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.messageRemoved,
      (event: Message) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.messageRemoved,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.messageUpdated,
      (event: MessageUpdatedEvent) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.messageUpdated,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.pushNotification,
      (event: any) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.pushNotification,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.tokenAboutToExpire,
      () => {
        // do re-auth here
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.tokenAboutToExpire,
        });
      },
    );

    this.chatClient.on(TwoWayConversationClientEvents.tokenExpired, () => {
      // do re-auth here
      this.clientEmitter.emit({
        event_type: TwoWayConversationClientEvents.tokenExpired,
      });
    });

    this.chatClient.on(
      TwoWayConversationClientEvents.typingEnded,
      (event: Participant) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.typingEnded,
          channel_sid: event.conversation.sid,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.typingStarted,
      (event: Participant) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.typingStarted,
          channel_sid: event.conversation.sid,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.userSubscribed,
      (event: User) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.userSubscribed,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.userUnsubscribed,
      (event: User) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.userUnsubscribed,
          event,
        });
      },
    );

    this.chatClient.on(
      TwoWayConversationClientEvents.userUpdated,
      (event: UserUpdatedEvent) => {
        this.clientEmitter.emit({
          event_type: TwoWayConversationClientEvents.userUpdated,
          event,
        });
      },
    );
  }
}
