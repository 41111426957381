import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rn-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() labelText?: string;
  @Input() inputName: string;
  @Input() checkboxModel: boolean;
  @Output() checkboxModelChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  handleChange() {
    this.checkboxModelChange.emit(this.checkboxModel);
  }
}
