import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Journey } from '@app/core/models/journey';
import { JourneyService } from '@app/core/services/journey.service';

@Component({
  selector: 'app-journey-icon',
  templateUrl: './journey-icon.component.html',
  styleUrls: ['./journey-icon.component.scss']
})
export class JourneyIconComponent implements OnInit {
  @Input() clientId: string;
  @Input() backgroundColor: string = '#00BCD4';
  @Input() journeyId: string;
  @Input() isPreview: boolean;
  @Input() selected?: boolean;
  @Input() journeyInput?: Journey;
  @Output() removeJourney: EventEmitter<string> = new EventEmitter();
  @Output() selectJourney: EventEmitter<Journey> = new EventEmitter();

  journey: Journey;

  constructor(private journeyService: JourneyService) { }

  ngOnInit() {
    if (!this.journeyInput) {
      this.getJourney();
    } else {
      this.journey = this.journeyInput;
    }
  }

  getJourney() {
    this.journeyService.getJourneyById(this.journeyId, this.clientId).subscribe(journey => {
      this.journey = journey;
    });
  }

  emitRemove(): void {
    this.removeJourney.emit(this.journeyId);
  }

  tooltip(): string | null {
    return this.journey.deleted ? 'This experience is archived and will not appear on the CSR onboarding page.' : null;
  }
}
