// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import version from '@env/version';
import { baseEnv } from '@env/base-env';

// make a copy of base env so we can safely modify it
const mergedBaseEnv = { ...baseEnv };

// read service-specific flags
for (const service of Object.keys(baseEnv)) {
  const serviceAsEnvVar = service.toUpperCase().replace('URLBASE', '');
  const serviceFlag = `PORTAL_LOCAL_${serviceAsEnvVar}`;

  // check for local override from a specific enviornment variable
  const serviceOverride = process.env[serviceFlag];
  if (serviceOverride) {
    mergedBaseEnv[service] = serviceOverride;
    console.log(service, 'now points to local instance:', serviceOverride);
  }
}

export const environment = Object.assign(
  {
    production: false,
    version: version,
    environment: 'dev',
  },
  mergedBaseEnv,
);
