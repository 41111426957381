<div class="rn-page">
  <div class="help-text-container">
    <h3>Congratulations!</h3>
    <i>Here are the details on what you created:</i>
  </div>
  <div class="rn-page__section">
    <div class="rn-page--busy" *ngIf="isLoading(); else main_content">
      <app-spinner
        [styleRules]="{ transform: 'translateY(250px)' }"
      ></app-spinner>
    </div>

    <ng-template #main_content>
      <ng-container *ngIf="twoWayConfig">
        <h2 *ngIf="currentClient.feed_enabled">Relay Messenger Settings Summary</h2>
        <h2 *ngIf="!currentClient.feed_enabled">Two Way Settings Summary</h2>

        <div class="two-way-summary-container">
          <h3>Auto Responses</h3>
          <div class="inline-col">
            <h4>Default</h4>
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="twoWayConfig.default_auto_response"
              ></two-way-preview>
            </generic-phone>
          </div>

          <div class="inline-col">
            <h4>After Hours</h4>
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="twoWayConfig.after_hours_auto_response"
              ></two-way-preview>
            </generic-phone>
          </div>
        </div>

        <h3>{{ newFeed.enabled ? 'Notification' : 'SMS' }} Ping</h3>
        <h4>{{ newFeed.enabled ? 'Notification' : 'Message' }}</h4>
        <generic-phone>
          <bubble
            [text]="twoWayConfig.sms.body + '\n' + twoWayConfig.sms.disclaimer"
          ></bubble>
        </generic-phone>
      </ng-container>
    </ng-template>
  </div>
</div>
