<div class="sign-up-container">
  <app-banner-preview [productGroup]="productGroup" [viewProductInfo]="viewProductInfo"></app-banner-preview>
  <div class="wrapper">
    <form class="form-group">
      <div class="form-desc">
        <p class="message-text"
           *ngIf="!!productGroup.onboarding.sign_up_page.message_text"
           [innerHtml]="productGroup.onboarding.sign_up_page.message_text | safeHtml"></p>
      </div>
      <div class="form-input">
        <label>
          {{productGroup.onboarding.sign_up_page.ccid_input_label}}
        </label>
        <span>
            <input readonly type="text">
        </span>
        <label>
          {{productGroup.onboarding.sign_up_page.mobile_input_label}}
        </label>
        <span>
            <input readonly type="text">
        </span>
        <div *ngFor="let field of client.validation.fields; let i = index;">
          <label>
            {{field.prompt}}
          </label>
          <span>
            <input readonly type="text" name="prompt-{{i}}">
          </span>
        </div>
      </div>
      <div class="terms">
        <p *ngIf="!!productGroup.consent.express_written_consent_ts_cs"
          [innerHtml]="productGroup.consent.express_written_consent_ts_cs | safeHtml"></p>
        <span>
          <input type="checkbox">
          I accept the terms and conditions
        </span>
      </div>
      <button type="submit">
        {{productGroup.onboarding.sign_up_page.submit_button_label}}
        <i aria-hidden="true" class="fa fa-angle-right"></i>
      </button>
    </form>
  </div>
</div>
