import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SmsResponseLanguage } from '@app/core/models/sms-response-types';
import { LanguageEnum } from '@app/core/models/language.enum';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'rn-client-sms-response',
    templateUrl: './client-sms-response.component.html',
    styleUrls: ['./client-sms-response.component.scss']
})

export class ClientSmsResponseComponent implements OnInit, OnChanges {
    @Input() smsLanguage: SmsResponseLanguage;
    @Input() textLimit: number;
    @Input() allowedLanguages: string[] = [LanguageEnum.ENGLISH, LanguageEnum.SPANISH, LanguageEnum.FRENCH];

    isInitialized: boolean = false;
    selectedResponseLanguage: string;
    dropdownOptions: string[];
    smsLanguageGroup: UntypedFormGroup = new UntypedFormGroup({
        response_template: new UntypedFormControl('')
    });

    /**
     * Used to map from the enum to the dropdown text.
     */
    languageMap = {
        [LanguageEnum.ENGLISH]: 'English Response',
        [LanguageEnum.FRENCH]: 'French Response',
        [LanguageEnum.SPANISH]: 'Spanish Response'
    } as {
        [language: string]: string;
    };

    constructor() {
    }

    /**
     * Returns the response_template for the validation messages.
     */
    get responseTemplate() {
        return this.smsLanguageGroup.get('response_template');
    }

    /**
     * Used for displaying the current message length
     */
    get currentTextLength() {
        return this.responseTemplate?.value.length || 0;
    }

    /**
     * To prevent users from 'hiding' errors, you can't change languages if the current language
     * has an invalid text.
     */
    get isLanguageControlDisabled() {
        return this.responseTemplate.invalid;
    }

    /**
     * Show/hides the parent div for all error messages.
     */
    get showErrorDiv(): boolean {
        return this.responseTemplate.invalid
            && (this.responseTemplate.dirty || this.responseTemplate.touched);
    }

    /**
     * Show/hides the 'required' error message.
     */
    get showRequiredMessage(): boolean {
        return this.responseTemplate.errors?.required;
    }

    /**
     * Show/hides the 'maxLength' error message.
     */
    get showMaxLengthMessage(): boolean {
        return this.responseTemplate.errors?.maxlength;
    }

    /**
     * To decide whether a language option is editable, especially in unrecognized responses we only allow
     * updating English response
     */
    isLanguageOptionDisabled(language: string): boolean {
        return !this.allowedLanguages.includes(language);
    }

    ngOnInit(): void {
        // sets up the initial record so it doesn't break.
        this.responseTemplate.setValidators([Validators.required, Validators.maxLength(this.textLimit)]);
        this.responseTemplate.updateValueAndValidity();
    }

    /**
     * Because this.smsLanguage will be updated asynchronously, and because FormControls don't react to updates externally,
     * we need to remove and re-add the form control.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.updateControls(changes);
    }

    updateControls(changes: SimpleChanges) {
        if (changes['smsLanguage']?.currentValue && !changes['smsLanguage']?.previousValue) {
            const keys = Object.keys(this.smsLanguage);
            this.dropdownOptions = keys;
            this.selectedResponseLanguage = keys[0];
            this.rebuildControlHierarchy();

            this.isInitialized = true;
        }
        if (changes['textLimit']?.currentValue) {
            this.rebuildControlHierarchy(true);
        }
    }

    /**
     * Has the this.isInitialized check to make sure we don't do this prior to getting 'good' data.
     */
    onLanguageChanged(event: string) {
        this.smsLanguage[this.selectedResponseLanguage].response_template = this.responseTemplate.value;
        this.selectedResponseLanguage = event;

        if (this.isInitialized) {
            this.rebuildControlHierarchy()
        }
    }

    onMessageChanged() {
        if (this.responseTemplate.valid) {
            this.smsLanguage[this.selectedResponseLanguage].response_template = this.responseTemplate.value;
        }
    }

    /**
     * Consolidates the logic for reinitializing the control.
     * @param preserveText Text to eventually have appear. Can either be preserved (i.e., when only the text limit changes)
     * or updated.
     * @private
     */
    rebuildControlHierarchy(preserveText: boolean = false) {
        const text = preserveText ?
            this.responseTemplate.value :
            this.smsLanguage[this.selectedResponseLanguage]['response_template'];

        this.responseTemplate.patchValue(text);
        this.responseTemplate.setValidators([Validators.required, Validators.maxLength(this.textLimit)]);
        this.responseTemplate.updateValueAndValidity();
    }
}
