import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit, } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, } from '@angular/forms';
import { TinyEditorConfig } from '@app/core/services/tiny-editor.service';
import { LoggerService } from '@app/core/services/logger.service';
import tinymce from 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';

// mentions_fetch requires a list of entries with this data shape in order to display
// autocomplete suggestions.
type MentionAutocompleteEntry = {
  id: string;
  name: string;
};

@Component({
  selector: 'app-tiny-editor',
  templateUrl: './tiny-editor.component.html',
  styleUrls: ['./tiny-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TinyEditorComponent),
      multi: true,
    },
  ],
})
export class TinyEditorComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() elementId: string;
  @Input() formControl: UntypedFormControl;
  @Input() config?: TinyEditorConfig;
  @Input('value') _value: string;
  @Input() isRawEditorEnabled?: boolean = false;
  editorRef: any;

  constructor() {
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this.setValue(val);
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  fetchInputFields = async () => new Promise((resolve, _reject) => {
    let inputFields = [];
    const menuItems = this.config?.allMenuItems;
    if (menuItems) {
      inputFields = menuItems.map((item) => {
        return {
          id: item.name, // id field is not used by our code but required for the mentions plugin
          name: item.name
        };
      });
    }
    return resolve(inputFields);
  });

  ngOnInit() {
    if (this.config == null) {
      this.config = new TinyEditorConfig();
    }
  }

  ngOnDestroy() {
    tinymce.remove(this.editorRef);
  }

  async ngAfterViewInit() {
    await tinymce.init({
      selector: '#' + this.elementId,
      base_url: '/assets/tinymce',
      suffix: '.min',
      block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4;',
      skin_url: '/assets/tinymce/skins/ui/oxide',
      min_height: this.config.minHeight,
      max_height: this.config.maxHeight,
      menu: {},
      menubar: false,
      plugins: ['table', 'lists', 'link', 'code', 'mentions'],
      extended_valid_elements: 'ul,li,ol,strong,u,em,b,br,i,strike',
      entity_encoding: 'raw',
      toolbar: [
        'blocks fontsizeinput forecolor bold italic underline superscriptSubscript',
        `bullist numlist align link table inputs account ${this.isRawEditorEnabled && 'code'}`
      ],
      formats : {
        underline : {inline : 'u', exact : true}
      },
      contextmenu: false,
      browser_spellcheck: this.config.spellCheck,
      statusbar: this.config.showStatusBar,
      resize: this.config.allowResize,
      content_css: '/assets/tinymce/relay-editor-overrides.css',
      branding: false,
      relative_urls: false,
      
      mentions_min_chars: 0, // minimum chars typed after '@' before display autocomplete suggestions
      mentions_selector: 'span.relay_mention', // must match element type returned by mentions_menu_complete

      // Called when user types '@' and fetches account_ and input_ fields for autocomplete
      // within message builder. As the user types, non-matching suggestions are filtered out
      mentions_fetch: (query, success) => {
        this.fetchInputFields().then((fields: MentionAutocompleteEntry[]) => {
          fields = fields.filter((field) => {
            return field.name.indexOf(query.term.toLowerCase()) !== -1;
          });
          success(fields);
        });
      },

      // Called when user selects an option for autocomplete, either by clicking or pressing enter
      mentions_menu_complete: (editor, inputField) => {
        const span = editor.getDoc().createElement('span');
        span.className = 'relay_mention';
        span.appendChild(editor.getDoc().createTextNode(`@{${inputField.name}}`));
        return span;
      },
      
      init_instance_callback: this.init_instance_callback,
      
      setup: (editor) => {
        this.editorRef = editor;
        this.setupEditorInstance(editor, this.editorRef);
      },
    });
  }
  
  /**
   * Callback method called after the editor has been initialized.
   * Needed to remove the status bar text shown by default.
   *
   * @param mce - the editor instance object reference
   */
  init_instance_callback(mce: any) {
    try {
      const divEl = mce
        .getContainer()
        .getElementsByClassName(
          'mce-branding mce-widget mce-label mce-flow-layout-item mce-last'
        );
      if (divEl?.length === 1) {
        divEl[0].style.display = 'none';
      }
      const spanEl = mce
        .getContainer()
        .getElementsByClassName('mce-path mce-flow-layout-item mce-first');
      if (spanEl?.length === 1) {
        spanEl[0].style.display = 'none';
      }
    } catch (e) {
      LoggerService.log(
        'tiny-editor.component',
        'tiny editor was not initialized properly'
      );
    }
  }

  setupEditorInstance(editor, editorRef) {
    editor.on('keyup change blur', () => {
      const content = editor.getContent();
      this.onEditorContentChange(content);
    });

    let buttonMode = 'Superscript';

    editor.ui.registry.addSplitButton('superscriptSubscript', {
      icon: 'superscript',
      onAction: function (api) {
        editor.execCommand(buttonMode);
        api.setActive(!api.isActive());
      },
      onItemAction: function (api, value) {
        if (api.isActive()) {
          editor.execCommand(buttonMode);
          editor.execCommand(value);
        }
        buttonMode = value;
        api.setIcon(value);
      },
		  fetch: (resolve) => {
        resolve([
          buttonMode === 'Subscript'
          ? {   type: 'choiceitem', icon: 'superscript', value: 'Superscript' }
          : {   type: 'choiceitem', icon: 'subscript', value: 'Subscript' }
        ]);
      },
      onSetup: function (api) {
        editor.on('SelectionChange', () => {
          const nodeName = editor.selection.getNode().nodeName;
          if (nodeName === 'SUP') {
            buttonMode = 'Superscript';
            api.setIcon('Superscript');
            api.setActive(true);
          }
          else if (nodeName === 'SUB') {
            buttonMode = 'Subscript';
            api.setIcon('Subscript');
            api.setActive(true);
          }
          else if (api.isActive()) {
            api.setActive(false);
          }
        });
        return (_api) => {
          editor.off('SelectionChange');
        }
      }
    }); 

    if (this.config.useInputsMenuList) {
      const inputsMenuList = [...this.config.inputsMenuList]
      editor.ui.registry.addMenuButton('inputs', {
        text: '@{input_}',
        tooltip: 'Personalization Options',
        fetch: function (callback) {
          const items = inputsMenuList.map(c => {
            return {
              type: 'menuitem',
              text: c.name,
              value: `@{${c.name}} `,
              onAction: function () {
                editor.insertContent(
                  `<span class="relay_mention" data-mce-mentions-id="${c.name}">@{${c.name}}</span>`
                );
              },
            };
          })
          callback(items);
        },
      });
    }

    if (this.config.useAccountMenuList) {
      const accountMenuList = [...this.config.accountMenuList]
      editor.ui.registry.addMenuButton('account', {
        text: '@{account_}',
        tooltip: 'Account Personalization Options',
        fetch: function (callback) {
          const items = accountMenuList.map(c => {
            return {
              type: 'menuitem',
              text: c.name,
              value: `@{${c.name}} `,
              onAction: function () {
                editor.insertContent(
                  `<span class="relay_mention" data-mce-mentions-id="${c.name}">@{${c.name}}</span>`
                );
              },
            };
          })
          callback(items);
        },
      });
    }

  }

  onEditorContentChange(event: string) {
    if (event !== this.value) {
      this.setValue(event);
      this.onChange(event);
      this.onTouched();
    }
  }

  setValue(val) {
    // textarea needs value set to null to show ''; 'undefined' will show if value is undefined
    if (val === undefined) {
      this._value = null;
    } else {
      this._value = val;
    }
  }

  writeValue(val) {
    this.value = val;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
