<client-header [headerLabel]="'Onboarding'" [headerGroup]="'onboardingChannels'"
               (click)="headerGroups.onboardingChannels = !headerGroups.onboardingChannels">
</client-header>

<div class="collapse-container" *ngIf="headerGroups.onboardingChannels">
  <div class="backup-journey-container">
    <div class="input-container" *ngIf="showSetJourneyButton">
      <div>
        <button id="set-fallback-journey-btn" class="btn btn-primary btn-with-icon" (click)="isJourneySelect=true">
          <span class="btn-label" i18n="Label for set fallback experience button">
              Set Fallback Experience
          </span>
          <span class="icon icon-right">
              <i class="fa fa-plus-circle"></i>
          </span>
        </button>
      </div>
    </div>

    <div *ngIf="!showSetJourneyButton" class="backup-journey-display">
        <div class="row colhdrs">
            <div class="colw colw-30 column-heading">
              <span>Fallback Experience Name</span>
            </div>
            <div class="colw colw-50 column-heading">
              <span>Trigger ID</span>
            </div>
            <div class="colw colw-10"></div>
        </div>
        <div>
            <div class="colw colw-30">
              <label>{{journeyInfo.name}}</label>
            </div>
            <div class="colw colw-50">
                <input id="backup-trigger-id" value="{{journeyInfo.triggerId}}" readonly>
                <rn-copied-message id="copy-trigger-id" [copyFromElement]="true" [inputData]="'backup-trigger-id'"></rn-copied-message>
            </div>
            <div class="colw colw-10" id="remove-trigger-id">
              <span (click)="removeTriggerId()"><i class="fa fa-trash"></i></span>
            </div>
        </div>
    </div>

    <p class="tip-text">
      <svg width="15" height="60" style="float: left;">
        <polyline points="8,0 8,24, 2,30 8,36 8,80" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
      </svg>
      <span>This will be the experience that is sent to newly onboarded customers, in the event that no trigger ID is provided. It is optional, if it is not filled out and no Trigger ID is provided, your customer will be onboarded, and you will need to message them separately.</span>
    </p>
  </div>

  <hr class="divider">

  <ng-container *ngIf="showGenericConnectionsSetting()">
    <app-radio-btn [btnLabel]="'Allow Generic Connections'" [btnName]="'allow_generic_connections'" [btnWidth]="'360px'"
      [tiptext]="'Allowing generic connections.  Enables customers without a CCID to connect to a product group'"
      [(btnModel)]="productGroup.onboarding.generic_connections.enabled">
    </app-radio-btn>
    
    <hr class="divider">
  </ng-container>

  <ng-container *ngIf="isJourneySelect">
    <div class="dialog-background under-title"></div>
    <div class="copy-wrapper">
      <app-journey-list [selectionType]="'CSR'" [listTitle]="'Select a Fallback Journey'"
                        [ngStyle]="{'z-index': 10, 'position': 'relative'}"
                        (fallbackJourneySelected)="onFallbackJourneySelection($event)">
      </app-journey-list>
    </div>
  </ng-container>

  <app-radio-btn [btnLabel]="'Sign-Up Page'" [btnName]="'use_signup_page_onboarding_flag'"
                 [tiptext]="getSignupPageTipText()"
                 [btnWidth]="'360px'" [disabled]="!validationIsOn()"
                 [(btnModel)]="productGroup.onboarding.sign_up_page.enabled">
  </app-radio-btn>

  <div
    class="{{getFormWrapperClass()}}"
    style="padding-left: 5%; padding-top: 2%;"
    *ngIf="productGroup.onboarding.sign_up_page.enabled === 'true'"
  >
    <app-radio-btn
      [btnName]="'include_branding_onboarding_flag'"
      [btnLabel]="'Include Branding'"
      [tiptext]="getBrandingTipText()"
      [btnWidth]="'360px'"
      [(btnModel)]="productGroup.onboarding.sign_up_page.display_branding"
      style="padding-top: 100px"
    >
    </app-radio-btn>

    <div class="generated-url-container">
      <label for="generated-url">Generated URL</label>
      <rn-copied-message id="copy-to-clipboard" [copyFromElement]="true" [customBtnText]="'Copy URL to Clipboard'" [inputData]="'generated-url'"></rn-copied-message>
      <br>
      <input name="generated-url" id="generated-url" value="{{getGeneratedURL()}}" readonly>
    </div>

    <div class="messaging-panel arrow-left-center">
      <div class="card panel-default">
        <div class="card-header">
          <div class="col-sm-12">
            Sign up page {{this.client.feed_enabled ? 'content' : 'composer'}}
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="signUpGroup" >
            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['message_text'].valid && signUpGroup.controls['message_text'].dirty}">

                <label>Message Text</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['message_text']"
                  [totalLength]="getCharacterLimit('signUpMessageText')">
                  <ng-container>
                    <app-tiny-editor
                      class="tiny-editor-mentions"
                      [elementId]="'tiny-editor-message-text'"
                      [formControl]="signUpGroup.controls['message_text']">
                    </app-tiny-editor>
                  </ng-container>
                </rn-form-input-state-control>
              </div>
            </div>

            <div class="form-group">
              <span class="validation-field-header">
                Validation field
              </span>
              <span class="validation-field-header">
                Field label
              </span>
              <div class="validation-fields">
                <span class="validation-field-value">{{client.validation.look_up_by_secondary_account_id ? 'secondary_id' : 'ccid'}}</span>
                <span class="validation-field-value"
                  [ngClass]="{'has-error': !signUpGroup.controls['ccid_input_label'].valid}">
                  <input type="text"
                    [formControl]="signUpGroup.controls['ccid_input_label']">
                </span>
              </div>
              <div class="validation-fields">
                <span class="validation-field-value">mobile_number</span>
                <span class="validation-field-value"
                  [ngClass]="{'has-error': !signUpGroup.controls['mobile_input_label'].valid}">
                  <input type="text"
                    [formControl]="signUpGroup.controls['mobile_input_label']">
                </span>
              </div>
              <div class="validation-fields" *ngFor="let field of client.validation.fields; let i = index;">
                <span class="validation-field-value">
                  {{field.field}}
                </span>
                <span class="validation-field-value"
                    [ngClass]="{'has-error': !signUpGroup.controls[field.field].valid}">
                  <input type="text" name="prompt-{{i}}"
                    [formControl]="signUpGroup.controls[field.field]">
                </span>
              </div>
            </div>

            <div class="form-group">
              <div [ngClass]="{'has-error':!productGroup.consent.express_written_consent_ts_cs}">
                  <span class="error-msg"
                        *ngIf="!productGroup.consent.express_written_consent_ts_cs"
                        tooltip="This field is required"
                        placement="bottom">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  </span>
                <label>Terms and Conditions - Pulled from Express Written Consent</label>
                <p *ngIf="productGroup.consent.express_written_consent_ts_cs" class="message-text" [innerHtml]="productGroup.consent.express_written_consent_ts_cs | safeHtml"></p>
                <p *ngIf="!productGroup.consent.express_written_consent_ts_cs" class="message-text has-error">
                  Express written consent must be set
                </p>
              </div>
            </div>

            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['success_text'].valid && signUpGroup.controls['success_text'].touched}">

                <label>Success Copy</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['success_text']"
                  [showCharacterCount]="this.client.feed_enabled"
                  [totalLength]="getCharacterLimit('signUpSuccessCopy')">
                  <ng-container>
                    <textarea
                      name="success_text"
                      class="form-control"
                      rows="3"
                      [formControl]="signUpGroup.controls['success_text']"></textarea>
                  </ng-container>
                </rn-form-input-state-control>

              </div>
            </div>

            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['failure_text'].valid && signUpGroup.controls['failure_text'].touched}">

                <label>Failure Copy</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['failure_text']"
                  [showCharacterCount]="this.client.feed_enabled"
                  [totalLength]="getCharacterLimit('signUpFailureCopy')">
                  <ng-container>
                    <textarea
                      name="failure_text"
                      class="form-control"
                      rows="3"
                      [formControl]="signUpGroup.controls['failure_text']"></textarea>
                  </ng-container>
                </rn-form-input-state-control>
              </div>
            </div>

            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['submit_button_label'].valid && signUpGroup.controls['submit_button_label'].touched}">

                <label>Submit button label</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['submit_button_label']"
                  [showCharacterCount]="this.client.feed_enabled"
                  [totalLength]="getCharacterLimit('signUpPageSubmitButtonLabel')">
                  <ng-container>
                    <input name="button_label"
                      class="form-control"
                      [formControl]="signUpGroup.controls['submit_button_label']">
                  </ng-container>
                </rn-form-input-state-control>

              </div>
            </div>
            
            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['terms_and_conditions_text'].valid && signUpGroup.controls['terms_and_conditions_text'].touched}">
                <label>Terms and Conditions Checkbox Text</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['terms_and_conditions_text']"
                  [showCharacterCount]="this.client.feed_enabled"
                  [totalLength]="getCharacterLimit('signUpPageTermsAndConditions')">
                  <ng-container>
                    <input name="button_label"
                      class="form-control"
                      [formControl]="signUpGroup.controls['terms_and_conditions_text']">
                  </ng-container>
                </rn-form-input-state-control>
              </div>
            </div>

            <div class="form-group">
              <div
                [ngClass]="{'has-error':!signUpGroup.controls['terms_and_conditions_validation_error'].valid && signUpGroup.controls['terms_and_conditions_validation_error'].touched}">
                <label>Terms and Conditions Error Text</label>
                <rn-form-input-state-control
                  [formReference]="signUpGroup.controls['terms_and_conditions_validation_error']"
                  [showCharacterCount]="this.client.feed_enabled"
                  [totalLength]="getCharacterLimit('signUpPageTermsAndConditionsValidationError')">
                  <ng-container>
                    <input name="button_label"
                      class="form-control"
                      [formControl]="signUpGroup.controls['terms_and_conditions_validation_error']">
                  </ng-container>
                </rn-form-input-state-control>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <generic-phone class="generic-phone" [overflowY]="client.feed_enabled ? 'auto' : 'none'">
      <sign-up-preview
        *ngIf="!client.feed_enabled"
        [productGroup]="productGroup"
        [client]="client"
      ></sign-up-preview>
      <sign-up-page-preview-react
        *ngIf="client.feed_enabled"
        [messageText]="productGroup.onboarding.sign_up_page?.message_text"
        [ccidInputLabel]="
          productGroup.onboarding.sign_up_page?.ccid_input_label
        "
        [mobileInputLabel]="
          productGroup.onboarding.sign_up_page?.mobile_input_label
        "
        [validationFields]="validationFields"
        [logoUrl]="productGroup.branding.icon_s3_url"
        [bannerUrl]="productGroup.branding.banner_s3_url"
        [color]="productGroup.branding.color"
        [productGroupName]="productGroup.name"
        [showProductGroup]="!productGroup.hide_pg_wire_display_name"
        [productGroupDescription]="productGroup.description"
        [expressWrittenConsentTsCs]="
          productGroup.consent?.express_written_consent_ts_cs
        "
        [submitBtnLabel]="
          productGroup.onboarding.sign_up_page?.submit_button_label
        "
        [termsAndConditionsText]="
          productGroup.onboarding.sign_up_page?.terms_and_conditions_text"
        [termsAndConditionsValidationError]="
          productGroup.onboarding.sign_up_page?.terms_and_conditions_validation_error"
        [showBranding]="productGroup.onboarding.sign_up_page.display_branding"
        [hideProductGroupWireDisplayName]="productGroup.hide_pg_wire_display_name"
        [showFdic]="productGroup.fdic.show_signage_on_authentication"
        [fdic]="productGroup.fdic"
      ></sign-up-page-preview-react>
    </generic-phone>

  </div>
</div>
