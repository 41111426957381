import { Component, EventEmitter, Output, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'rn-launch-result',
  templateUrl: './launch-result.component.html',
  styleUrls: ['./launch-result.component.scss']
})
export class LaunchResultComponent {
  @Input() leftButtonLabel: string;
  @Input() rightButtonLabel: string;
  @Input() action: 'run' | 'schedule';
  @Input() scheduleTime: Date;
  @Output() leftButtonClick = new EventEmitter();
  @Output() rightButtonClick = new EventEmitter();

  constructor() {}
}
