import React from 'react';

export type RedirectProps = {
  link?: string;
  value?: string;
};

export const Redirect = ({ link, value }: RedirectProps) => {
  return (
    <div style={{ marginTop: '1.5em', marginBottom: '1em' }}>
      <a href={link} target="_blank" style={{ fontWeight: 'bold' }}>
        {value}
      </a>
    </div>
  );
};
