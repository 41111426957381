import { styled } from 'baseui';
import React from 'react';

const Container = styled('div', {
  display: 'flex',
  cursor: 'pointer',
});

const Circle = styled('div', {
  width: '23px',
  height: '23px',
  backgroundColor: 'rgba(81, 145, 186, 0.31)',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PlusSignContainer = styled('span', {
  color: '#5191ba',
  fontSize: '32px',
  fontWeight: '600',
  paddingBottom: '2px',
});

const TextContainer = styled('span', {
  fontSize: '14px',
  color: '#5191ba',
  fontWeight: '700',
  marginLeft: '10px', 
  alignSelf: 'center',
});

export const AddButton = ({ text }: { text: string }) => {
  return (
    <Container>
      <Circle>
        <PlusSignContainer>+</PlusSignContainer>
      </Circle>
      <TextContainer>{text}</TextContainer>
    </Container>
  );
};
