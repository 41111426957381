import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry, ExperienceType, ExpLibResponse, CompanyType, Outcome } from '@app/core/models/categorization-types';
import { CategorizationService } from '@app/core/services/categorization.service';
import { SortableComponent } from '@app/core/sortable-component';
import { SessionService } from '@app/security/session.service';
import { SortingUtils } from '@app/experience-library/utils/categorization-sorting-utils';

@Component({
  selector: 'rn-experience-type-list',
  templateUrl: './experience-type-list.component.html',
  styleUrls: ['./experience-type-list.component.scss'],
})
export class ExperienceTypeListComponent extends SortableComponent implements OnInit {
  industries: Industry[];
  companyTypes: CompanyType[]; 
  outcomes: Outcome[];
  experienceTypes: ExperienceType[]; // do not change!  Use sortedExperienceTypes to sort/modify
  sortedExperienceTypes: ExperienceType[];
  clientId: string;
  hasError: boolean = false;

  constructor(
    private categorizationService: CategorizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;

    this.categorizationService.getExperienceTypes(this.clientId).subscribe((experienceTypesResult: ExpLibResponse<ExperienceType[]>) => {
      if (!experienceTypesResult.data) { this.hasError = true };
      this.experienceTypes = experienceTypesResult.data;
      this.resetSort();
    }, (error) => {
      this.hasError = true;
    });

    this.categorizationService.getIndustries(this.clientId).subscribe((industriesResult: ExpLibResponse<Industry[]>) => {
      this.industries = industriesResult.data;
    }, (error) => {
      this.hasError = true;
    });
    
    this.categorizationService.getCompanyTypes(this.clientId).subscribe((companyTypesResult: ExpLibResponse<CompanyType[]>) => {
      this.companyTypes = companyTypesResult.data;
    }, (error) => {
      this.hasError = true;
    });

    this.categorizationService.getOutcomes(this.clientId).subscribe((outcomesResult: ExpLibResponse<Outcome[]>) => {
      this.outcomes = outcomesResult.data;
    }, (error) => {
      this.hasError = true;
    });
  }

  experienceTypesLoaded(): boolean {
    return this.experienceTypes !== undefined;
  }

  industriesLoaded(): boolean {
    return this.industries !== undefined;
  }

  navigateToAdd(): void {
    this.router.navigate(['add-experience-type'], { relativeTo: this.activatedRoute });
  }

  navigateToEdit(experienceTypeId: string): void {
    this.router.navigate([`edit-experience-type/${experienceTypeId}`], { relativeTo: this.activatedRoute });
  }

  fetchData() {
    if (this.ordering === undefined) {
      this.resetSort();
    } else if (this.ordering.orderBy === 'id') {
      this.sortedExperienceTypes = SortingUtils.sortById(this.sortedExperienceTypes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'title') {
      this.sortedExperienceTypes = SortingUtils.sortByTitle(this.sortedExperienceTypes, this.ordering.direction);
    } else if (this.ordering.orderBy === 'created_at') {
      this.sortedExperienceTypes = SortingUtils.sortByDate(this.sortedExperienceTypes, this.ordering.direction, 'created_at');
    } else if (this.ordering.orderBy === 'updated_at') {
      this.sortedExperienceTypes = SortingUtils.sortByDate(this.sortedExperienceTypes, this.ordering.direction, 'updated_at');
    }
  }

  private resetSort(): void {
    // map is required to ensure that the sorted array isn't an in-place reference to the original,
    // because Array.sort does an in-place modification
    this.sortedExperienceTypes = this.experienceTypes.map(item => item);
  }
}
