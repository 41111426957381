import React, { useState } from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ChevronRight, Check } from 'baseui/icon';
import { FilterDateCreatedProps } from '../../relay-messenger-list/RelayMessenger.list';
import { FilterCustomButton } from './FlterCustomButton';
import {
  FilterMethodOptions,
  FilterMethods,
  FilterOptionType,
} from '../../relay-messenger-conversations/RelayMessengerConversations';
import { FilterVariantButton } from './FilterVariantButton';

interface FilterDateActionItemProps {
  filterDateCreated: FilterDateCreatedProps;
  close: () => void;
  rangeDate: Date[];
  setRangeDate: (value: Date[]) => void;
  clearFilterState: () => void;
  clearSortingState: () => void;
}

export const FilterDateAction = ({
  filterDateCreated,
  close,
  rangeDate,
  setRangeDate,
  clearFilterState,
  clearSortingState,
}: FilterDateActionItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  // state to highlight custom button if selected
  const [customDateClicked, setCustomDateClicked] =
    useState<FilterOptionType>(null);

  return (
    <StatefulPopover
      content={({ close: innerClose }) => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '170px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          <FilterVariantButton
            value={FilterMethodOptions.PastDay}
            filterOptionClicked={
              filterDateCreated?.filterOptionDateCreatedClicked
            }
            filterByOption={filterDateCreated?.filterByDateCreated}
            setFilterByOption={filterDateCreated?.setFilterByDateCreated}
            close={close}
            dataTestId="filter-date-created-past-day"
          />
          <FilterVariantButton
            value={FilterMethodOptions.PastWeek}
            filterOptionClicked={
              filterDateCreated?.filterOptionDateCreatedClicked
            }
            filterByOption={filterDateCreated?.filterByDateCreated}
            setFilterByOption={filterDateCreated?.setFilterByDateCreated}
            close={close}
            dataTestId="filter-date-created-past-week"
          />
          <FilterVariantButton
            value={FilterMethodOptions.PastMonth}
            filterOptionClicked={
              filterDateCreated?.filterOptionDateCreatedClicked
            }
            filterByOption={filterDateCreated?.filterByDateCreated}
            setFilterByOption={filterDateCreated?.setFilterByDateCreated}
            close={close}
            dataTestId="filter-date-created-past-month"
          />
          <FilterCustomButton
            value={FilterMethodOptions.Custom}
            customDateClicked={customDateClicked}
            filterOptionClicked={
              filterDateCreated?.filterOptionDateCreatedClicked
            }
            filterByValue={filterDateCreated?.filterByDateCreated}
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
            onSuccess={(value) => {
              setCustomDateClicked(value);
              filterDateCreated?.setFilterByDateCreated(value);
            }}
            close={close}
            innerClose={innerClose}
            dataTestId="filter-date-created-custom"
          />
        </Block>
      )}
      placement={PLACEMENT.rightTop}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          onClick={() => {
            filterDateCreated?.setFilterOptionDateCreatedClicked(
              FilterMethods.DateCreated,
            );
            clearFilterState();
            clearSortingState();
          }}
          value={FilterMethods.DateCreated}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: filterDateCreated?.filterOptionDateCreatedClicked
                  ? '#3693BF'
                  : 'black',
                backgroundColor:
                  filterDateCreated?.filterOptionDateCreatedClicked
                    ? '#E1EFF5'
                    : 'transparent',
                fontWeight: filterDateCreated?.filterOptionDateCreatedClicked
                  ? 'bolder'
                  : 'inherit',
                textAlign: 'center',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'filter-date-created-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            filterDateCreated?.filterByDateCreated ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {FilterMethods.DateCreated}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
