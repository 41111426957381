import { Client, Participant } from '@twilio/conversations';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import { TwoWayService } from '../components/relay-messenger-axios-service/relay-messenger-axios-service';

interface AssignedAgentsContextType {
  assignedAgents: Participant[];
  setAssignedAgents: (agents: Participant[]) => void;
  fetchAssignedAgents: (
    twilioClient: Client,
    channelSid: string,
  ) => Promise<void>;
}
const defaultState: AssignedAgentsContextType = {
  assignedAgents: [],
  setAssignedAgents: () => {},
  fetchAssignedAgents: async () => {},
};
const AssignedAgentsContext =
  createContext<AssignedAgentsContextType>(defaultState);
export const useAssignedAgents = () => useContext(AssignedAgentsContext);
interface ProviderProps {
  children: ReactNode;
}
export const AssignedAgentsProvider = ({ children }: ProviderProps) => {
  const [assignedAgents, setAssignedAgents] = useState<Participant[]>([]);
  const fetchAssignedAgents = useCallback(
    async (twilioClient: Client, channelSid: string) => {
      try {
        if (twilioClient && channelSid) {
          const agents = await TwoWayService.getTwilioAgents(
            twilioClient,
            channelSid,
          );
          setAssignedAgents(agents);
        }
      } catch (error) {
        console.error('Failed to fetch assigned agents:', error);
      }
    },
    [],
  );
  return (
    <AssignedAgentsContext.Provider
      value={{ assignedAgents, setAssignedAgents, fetchAssignedAgents }}
    >
      {children}
    </AssignedAgentsContext.Provider>
  );
};
