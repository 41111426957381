import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientService } from './services/client.service';
import { CustomerService } from './services/customer.service';
import { GlobalErrorHandler } from './globalErrorHandler';
import { CustomerLookupLaunchService } from './services/customer-lookup-launch.service';
import { JobService } from './services/job.service';
import { JourneyService } from './services/journey.service';
import { LoadingMaskService } from './services/loading-mask.service';
import { MediaService } from './services/media.service';
import { NavbarStateService } from './services/navbar-state.service';
import { TitleService } from './services/title.service';
import { UserService } from './services/user.service';
import { JourneySelectService } from './services/journey-select.service';
import { LookerService } from './services/looker.service';
import { PermissionService } from './services/permission.service';
import { CustomValidators } from './utils/custom-validators';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { SharedDateTimeService } from '@app/core/services/shared-datetime.service';
import { TinyEditorService } from '@app/core/services/tiny-editor.service';
import { CsrJourneysModalService } from '@app/core/services/csr-journeys-modal.service';
import { WirePreviewService } from '@app/core/services/wire-preview.service';
import { PdfService } from '@app/core/services/pdf.service';
import { EnvService } from '@app/core/services/env.service';
import { FeatureService } from '@app/core/services/feature.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import { SmsService } from '@app/core/services/sms.service';
import { ControlTagsService } from '@app/core/services/control-tags.service';
import { VideoThumbnailService } from '@app/core/services/video-thumbnail.service';
import { ExperienceLibraryService } from '@app/core/services/experience-library.service';
import { CategorizationService } from '@app/core/services/categorization.service';
import { ShortcodeService } from '@app/core/services/shortcode.service';
import { HtmlUtilService } from '@app/core/services/html-util.service';
import { TwoWayConversationService } from './services/two-way-conversation.service';
import { NewFeedTransitionService } from './services/new-feed-transition.service';
import { TimeOutLocalStorageService } from './services/timeOutLocalStorage.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    ClientService,
    CustomerService,
    CustomerLookupLaunchService,
    CustomValidators,
    LookerService,
    JobService,
    JourneyService,
    JourneySelectService,
    LoadingMaskService,
    MediaService,
    NavbarStateService,
    PermissionService,
    TitleService,
    UserService,
    AnalyticsService,
    SharedDateTimeService,
    TinyEditorService,
    CsrJourneysModalService,
    WirePreviewService,
    PdfService,
    EnvService,
    FeatureService,
    TwoWayConversationService,
    TwoWaySharedService,
    SmsService,
    ControlTagsService,
    VideoThumbnailService,
    ExperienceLibraryService,
    CategorizationService,
    ShortcodeService,
    HtmlUtilService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    NewFeedTransitionService,
    TimeOutLocalStorageService,
  ],
})
export class CoreModule {}
