import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent implements OnInit {
  @Input() text: string;
  @Input() twoWay: boolean = false;
  @Input() flipped: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }
}
