import { LanguageEnum } from '@app/core/models/language.enum';

export class SmsCustomKeyword {
    keyword: string;
    locale: LanguageEnum;
    conversation: string;

    static deserialize(keyword: string, input: object): SmsCustomKeyword {
        const customKeyword = new SmsCustomKeyword();
        customKeyword.keyword = keyword;
        customKeyword.locale = input['locale'] || LanguageEnum.ENGLISH;
        customKeyword.conversation = input['conversation'];
        return customKeyword;
    }

    constructor(keyword: string = '', locale: string = '', conversation: string = '') {
        this.keyword = keyword;
        this.conversation = conversation;
        this.locale = <LanguageEnum>locale || LanguageEnum.ENGLISH;
    }
}
