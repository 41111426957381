import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';

@Injectable()
export class NewFeedTransitionService {
  constructor(private sessionService: SessionService) {}

  /**
   * The current user's client's 'New Feed Enabled' boolean, which is defaulted
   * to `false` if unset during deserialization of the {@link Client}.
   */
  get enabled() {
    return this.sessionService.getCurrentUsersClient().feed_enabled;
  }
}
