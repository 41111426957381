import { Injectable } from '@angular/core';
import { DynamicInputsService } from '@app/cx-builder/services/dynamic-inputs.service';

export class TinyEditorConfig {
  //TODO: possible enhancement is to have a dynamic list of menulists,
  //rather than specific lists only for inputs and account items
  inputsMenuList: any[];
  accountMenuList: any[];
  includeInputsMenu: boolean = false;
  includeAccountMenu: boolean = false;
  includeMentionsAutoComplete: boolean = false;
  minHeight: number = 200;
  maxHeight: number = 600;
  spellCheck: boolean = true;
  showStatusBar: boolean = true;
  allowResize: boolean = true;

  get useInputsMenuList(): boolean {
    return this.includeInputsMenu &&
      this.inputsMenuList?.length > 0;
  }

  get useAccountMenuList(): boolean {
    return this.includeAccountMenu &&
      this.accountMenuList?.length > 0;
  }

  get useMentionsAutoComplete(): boolean {
    return this.includeMentionsAutoComplete &&
      (this.useAccountMenuList || this.useInputsMenuList);
  }

  get allMenuItems(): any {
    if (this.useAccountMenuList && this.useInputsMenuList) {
      return this.accountMenuList.concat(this.inputsMenuList);
    } else if (this.useAccountMenuList && !this.useInputsMenuList) {
      return this.accountMenuList;
    } else if (!this.useAccountMenuList && this.useInputsMenuList) {
      return this.inputsMenuList;
    }
    return [];
  }
}

@Injectable()
export class TinyEditorService {

  constructor(private dynamicInputsService: DynamicInputsService) { }

  get accountMenuList(): any {
    return [
      {name: 'account_firstname'},
      {name: 'account_lastname'},
      {name: 'account_product_group'},
      {name: 'account_ccid'},
      {name: 'account_secondary_account_id'}
    ];
  }

  get inputsMenuList(): any {
    const items = this.dynamicInputsService.clientDynamicInputs.map(x => {
      return {name: x};
    });
    return items;
  }
}
