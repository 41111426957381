import { Journey } from '@app/core/models/journey';
import { ComponentBaseClass, MessageClass } from '@app/core/models/message';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WirePreviewService } from '@app/core/services/wire-preview.service';
import { ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';

@Component({
  selector: 'app-trigger-selector',
  templateUrl: './trigger-selector.component.html',
  styleUrls: ['./trigger-selector.component.scss'],
})
export class TriggerSelectorComponent implements OnInit {
  @Input() journey: Journey;
  @Output() triggerSelected = new EventEmitter<ComponentBaseClass>();
  @Output() selectionCanceled = new EventEmitter<ComponentBaseClass>();
  triggers: Array<ComponentBaseClass>;
  selectedTrigger: ComponentBaseClass;
  selectedTriggerIndex: number;
  productGroup: ProductGroup;
  allProductGroup: ProductGroup;
  message: MessageClass;

  constructor(
    private wirePreviewService: WirePreviewService,
    private clientService: ClientService,
  ) {}

  ngOnInit() {
    this.triggers = this.journey.live.components.filter(
      (c) => c.type === 'WorkflowStarter' || c.type === 'APITrigger',
    );
    if (this.triggers.length === 1) {
      this.selectTrigger(null, this.triggers[0]);
    }

    // sets a product group for the phone component
    this.wirePreviewService
      .getDataFromJourney(this.journey)
      .subscribe((data) => {
        this.productGroup = data.productGroup;
      });

    this.getAllProductGroup();
  }

  selectTrigger(event: any, trigger: ComponentBaseClass) {
    this.selectedTrigger = trigger;
    this.selectedTriggerIndex = this.journey.live.components.findIndex(
      (c) => c.name === this.selectedTrigger.name,
    );
    this.getMessage(this.selectedTrigger.to);
    this.triggerSelected.emit(trigger);
  }

  cancelSelection() {
    this.selectedTrigger = undefined;
    this.selectedTriggerIndex = undefined;
    this.selectionCanceled.emit();
  }

  getMessage(messageName: string): void {
    this.message = this.journey.live.components.find(
      (c) => c.name === messageName,
    );
  }

  getAllProductGroup(): void {
    this.clientService
      .getProductGroupForCurrentUser('default')
      .subscribe((productGroup) => {
        this.allProductGroup = new ProductGroup();
        this.allProductGroup.name = 'all';
        this.allProductGroup.branding.icon_s3_url =
          '/assets/icons/asterisk.jpg';
        this.allProductGroup.fdic = productGroup.fdic;
      });
  }

  getSMSText(): string {
    return this.message.sms.text;
  }
}
