<div class="input-autocomplete-container">
  <label type="text" for="new-tag-name">New Tag</label>

  <mat-form-field>
     <input type="text"
      placeholder="Pick a tag"
      aria-label="Number"
      matInput 
      [formControl]="newTagName"
      [matAutocomplete]="auto"
      [errorStateMatcher]="errorStateMatcher">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredAutocompleteOptions()" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="newTagName.invalid && newTagName.touched" class="error">This tag already exists in this group.</mat-error>
  </mat-form-field>
  
  <button type="button" [disabled]="disableAddButton()" (click)="addTagHandler()" class="btn btn-primary btn-add-tag">add tag</button>
</div>
