<div class="input-container" [ngClass]="{'disabled': disabled}">
  <div class="input" [ngStyle]="{'width': btnWidth}">
    <label [ngStyle]="{'margin-left.px': labelIndentWidth ? labelIndentWidth : null}">{{btnLabel}}</label>

    <div class="btn-group config" data-toggle="buttons" *ngIf="!isBoolean">
      <div class="btn-group" *ngIf="!btnDisplayTrueFirst">
        <div class="btn btn-sm btn-default" name="{{btnName}}" [attr.id]="formatBtnID(btnLabel, 'off')" ngDefaultControl
             [(ngModel)]="btnModel" (ngModelChange)="emitChangeEvent($event)"
             btnRadio="false">
              {{btnLabelFalse}}
        </div>

        <div class="btn btn-sm btn-default" name="{{btnName}}" [attr.id]="formatBtnID(btnLabel, 'on')" ngDefaultControl
             [(ngModel)]="btnModel" (ngModelChange)="emitChangeEvent($event)"
             btnRadio="true">
             {{btnLabelTrue}}
        </div>
      </div>
      <div *ngIf="btnDisplayTrueFirst" class="btn-group">
        <div (ngModelChange)="emitChangeEvent($event)" [(ngModel)]="btnModel" [attr.id]="formatBtnID(btnLabel, 'on')" btnRadio="true"
             class="btn btn-sm btn-default" name="{{btnName}}"
             ngDefaultControl>
          {{btnLabelTrue}}
        </div>

        <div (ngModelChange)="emitChangeEvent($event)" [(ngModel)]="btnModel" [attr.id]="formatBtnID(btnLabel, 'off')" btnRadio="false"
             class="btn btn-sm btn-default" name="{{btnName}}"
             ngDefaultControl>
          {{btnLabelFalse}}
        </div>
      </div>
    </div>

    <div class="btn-group config" data-toggle="buttons" *ngIf="isBoolean">
      <div class="btn-group" *ngIf="!btnDisplayTrueFirst">
        <div class="btn btn-sm btn-default" [ngClass]="{'active': !btnModel, 'btn-auto-size': btnAutoSize}" (click)="emitBooleanChangeEvent()"
             [attr.id]="formatBtnID(btnLabel, 'off')">
             {{btnLabelFalse}}
        </div>
        <div class="btn btn-sm btn-default" [ngStyle]="{'opacity': (feedEnabled) ? 0.5 : 1.0 }" [ngClass]="{'active': btnModel, 'btn-auto-size': btnAutoSize}" (click)="emitBooleanChangeEvent()"
             [attr.id]="formatBtnID(btnLabel, 'on')">
             {{btnLabelTrue}}
        </div>
      </div>
      <div class="btn-group" *ngIf="btnDisplayTrueFirst">
        <div class="btn btn-sm btn-default" [ngClass]="{'active': btnModel, 'btn-auto-size': btnAutoSize}" (click)="emitBooleanChangeEvent()"
             [attr.id]="formatBtnID(btnLabel, 'on')">
          {{btnLabelTrue}}
        </div>
        <div class="btn btn-sm btn-default" [ngClass]="{'active': !btnModel, 'btn-auto-size': btnAutoSize}" (click)="emitBooleanChangeEvent()"
             [attr.id]="formatBtnID(btnLabel, 'off')">
             {{btnLabelFalse}}
        </div>
      </div>
    </div>


    <div class="clearfix"></div>
  </div>

  <p class="tip-text" *ngIf="tiptext">
    <svg width="15" height="65" style="float: left;">
      <polyline points="8,0 8,24, 2,30 8,36 8,80" style="fill: none; stroke: #5b5a59; stroke-width:1"/>
    </svg>
    <span [innerHtml]="tiptext | safeHtml"></span>
  </p>
</div>
