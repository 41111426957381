<div class="user-reset">
  <div class="title-bar">
    {{config.title}}
  </div>
  <div class="body">
    <div>
      <div class="center" [class]="config.icon"></div>
    </div>
    <h3>{{config.msg}}</h3>
    <br />
    <hr />
    <div [class]="'btn btn-primary '+ (config.btns.length == 1?'pull-right':'pull-left')" (click)="closeModal(config.btns[0].name)"> 
      <span class="icon icon-left"><i [class]="'fa '+config.btns[0].icon"></i></span>
      {{config.btns[0].name}}
    </div>
    <div *ngIf="config.btns.length > 1" [class]="'pull-right '+ (config.btns[1].class?config.btns[1].class:'btn btn-primary')" (click)="closeModal(config.btns[1].name)">
      {{config.btns[1].name}}
      <span class="icon icon-right"><i [class]="'fa '+config.btns[1].icon"></i></span>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
