import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { CompanyType, Industry } from '@app/core/models/categorization-types';
import { CategorizationListItem, CategoryIndex } from '@app/experience-library/utils/categorization-list-item';

@Component({
  selector: 'rn-company-type-list-item',
  templateUrl: './company-type-list-item.component.html',
  styleUrls: ['./company-type-list-item.component.scss']
})
export class CompanyTypeListItemComponent extends CategorizationListItem {
  @Input() companyType: CompanyType;
  @Input() industryOptions: Industry[];
  @Output() editClicked = new EventEmitter();
  detailsOpen: boolean = false;
  categoryIndex = CategoryIndex;
  
  constructor() {
     super();
  }

  getItemNameForId(itemId: string, index: CategoryIndex): string {
    if(this.industryOptions && index === CategoryIndex.Industries) {
    return this.industryOptions.find((c => c.id === itemId)).title['en-us'];
    } else {
    return '';
    }
  }

  emitEditEvent(): void {
    this.editClicked.emit(this.companyType.id);
  }

  toggleDetails() {
    this.detailsOpen = !this.detailsOpen;
  }
}
