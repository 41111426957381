import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertLinkable'
})
export class ConvertLinkablePipe implements PipeTransform {
  urlRegex: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig; // source: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url#answer-17773849
  telRegex: RegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript#answer-29767609

  constructor() {}

  transform(text: string): any {
    return text.replace(this.urlRegex, this.convertUrl)
               .replace(this.telRegex, this.convertTel);
  }

  private convertUrl(urlStr) {
    const url = /^https?:\/\//.test(urlStr) ? urlStr : `https://${urlStr}`; // add http if it isnt there
    return `<a href="${url}" target="_blank">${urlStr}</a>`;
  }

  private convertTel(telStr) {
    let digits = telStr.replace(/\D/g, '');
    if (digits.length === 10) { // add us country code if there's only 10 digits
      digits = '1' + digits;
    }
    return `<a href="tel:+${digits}">${telStr}</a>`;
  }
}
