import { SessionService } from '@app/security/session.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const bytesPerKb = 1014;

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Input() fileNamePrefix: string;
  @Output() uploadComplete: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() uploadAborted: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() uploadError: EventEmitter<Object> = new EventEmitter<XMLHttpRequest>();

  showDialog = false;
  fileName: string;
  errorMessage: string;
  percentComplete = 0;
  uploadedUnits = 0;
  totalUnits = 0;
  totalBytes = 0;
  uploadInProgress = false;
  xhr: XMLHttpRequest;

  constructor(private sessionService: SessionService) {}

  ngOnInit() {
  }

  public startUpload(url: string, file: File, formData: FormData, method: string = 'POST') {
    if (this.uploadInProgress) {
      return;
    }
    this.uploadInProgress = true;
    this.errorMessage = null;
    this.fileName = file.name;

    this.xhr = new XMLHttpRequest();
    this.xhr.open(method, url);

    this.xhr.onreadystatechange = () => {
      if (this.xhr !== null && this.xhr.readyState === XMLHttpRequest.DONE) {
        if (this.percentComplete !== 100) {
          this.percentComplete = 100;
        }
        if (this.xhr.status === 200) {
          const uploadObj = {upload: this, response: JSON.parse(this.xhr.responseText)};
          setTimeout(() => {
            this.uploadComplete.emit(uploadObj);
            this.uploadInProgress = false;
          }, 500);
        } else {
          // In the upload context, 403 means an Amazon error, like a SQL injection flag
          // In that circumstance we do not want to log out like the sessionService call would, and
          // want to fail back via eventing.
          if (this.xhr.status === 403 || this.sessionService.checkSessionTimeoutXhr(this.xhr)) {
            this.uploadError.emit(this.xhr);
            this.uploadInProgress = false;
          }
        }
      }
    };

    this.xhr.upload.onprogress = (event) => {
      this.percentComplete = event.loaded / event.total * 100;
      if (this.percentComplete > 100) {
        this.percentComplete = 100;
      }
      this.uploadedUnits = Math.ceil(event.loaded / bytesPerKb);
      if (this.uploadedUnits > this.totalUnits) {
        this.uploadedUnits = this.totalUnits;
      }
    };

    this.xhr.withCredentials = true;

    this.totalBytes = file.size;
    this.percentComplete = 0;
    this.uploadedUnits = 0;
    this.totalUnits = Math.ceil(this.totalBytes / bytesPerKb);
    
    formData.append('file', file)
    this.xhr.send(formData);
  }

  abortUpload() {
    if (this.xhr != null) {
      this.xhr.onreadystatechange = null;
      this.xhr.abort();
    }
    if (this.uploadInProgress) {
      const fileName = this.fileName;
      this.errorMessage = `Upload of "${fileName}" was stopped.`;
      this.uploadInProgress = false;
    }
    this.fileName = null;
    this.percentComplete = 0;
    this.uploadedUnits = 0;
    this.totalUnits = 0;
    this.totalBytes = 0;
    this.uploadAborted.emit();
  }
}
