import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { Client, Contact } from '@app/core/models/client';
import { Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { FormatUtils } from '@app/core/utils/format-utils';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SftpMgmtService } from '@app/sftp-mgmt/services/sftp-mgmt.service';
import { SftpUser } from '@app/sftp-mgmt/models/sftp-user';
import { FeatureService } from '@app/core/services/feature.service';
import {
  CategorizationService,
  CategoryAncestryDetails,
} from '@app/core/services/categorization.service';
import { CompanyType, Industry } from '@app/core/models/categorization-types';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-client-new-info',
  templateUrl: './client-info-new.component.html',
  styleUrls: ['./client-info-new.component.scss'],
})
export class ClientInfoNewComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;

  currentClient: Client;
  client: Client;
  clientInfoValidGroup: UntypedFormGroup;
  headerGroups = { clientID: true, companyInformation: true };
  industries: Industry[] = [];
  allCompanyTypes: CompanyType[] = [];
  relevantCompanyTypes: CompanyType[] = [];
  featureFlags = FeatureFlags;

  constructor(
    private clientService: ClientService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private sessionService: SessionService,
    private sftpMgmtService: SftpMgmtService,
    private featureService: FeatureService,
    private categorizationService: CategorizationService
  ) {
    this.client = new Client();
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  // TODO - remove title service
  ngOnInit() {
    this.titleService.activate('Configure new client');

    this.initForm();
    if (this.featureService.checkFlag(this.featureFlags.experience_library)) {
      this.initCategoryOptions();
    }
  }

  // TODO - remove title service
  ngOnDestroy() {
    this.titleService.deactivate();
  }

  initForm() {
    this.clientInfoValidGroup = new UntypedFormGroup({
      client_id: new UntypedFormControl(this.client.id, [
        Validators.required,
        CustomValidators.apiCompatibleID,
      ]),
      company_name: new UntypedFormControl(
        this.client.company_name,
        Validators.required
      ),
      primary_contact: new UntypedFormGroup({
        first_name: new UntypedFormControl(
          this.client.primary_contact.first_name,
          Validators.required
        ),
        last_name: new UntypedFormControl(
          this.client.primary_contact.last_name,
          Validators.required
        ),
        email: new UntypedFormControl(this.client.primary_contact.email, [
          Validators.required,
          CustomValidators.emailToBeTrimmed,
        ]),
        mobile_number: new UntypedFormControl(
          this.client.primary_contact.mobile_number,
          CustomValidators.phoneNumber
        ),
      }),
    });

    if (
      this.featureService.checkFlag(this.featureFlags.experience_library) ===
      true
    ) {
      this.clientInfoValidGroup.addControl('industry', new UntypedFormControl(''));
      this.clientInfoValidGroup.addControl('company_type', new UntypedFormControl(''));

      this.industryCtl.valueChanges.subscribe((newIndustryId) => {
        this.setCompanyTypeOptions(newIndustryId);
        this.companyTypeCtl.setValue('');
      });
    }
  }

  get primaryContactGroup(): UntypedFormGroup {
    return this.clientInfoValidGroup.controls['primary_contact'] as UntypedFormGroup;
  }

  get industryCtl(): UntypedFormControl {
    return <UntypedFormControl>this.clientInfoValidGroup.controls['industry'];
  }

  get companyTypeCtl(): UntypedFormControl {
    return <UntypedFormControl>this.clientInfoValidGroup.controls['company_type'];
  }

  /**
   * Return true if the feature flag is on for experience_library, and there is a
   * form Control set up for both industry and company type
   * @returns boolean
   */
  showIndustrySelect(): boolean {
    return (
      this.featureService.checkFlag(this.featureFlags.experience_library) ===
        true &&
      this.clientInfoValidGroup.controls['industry'] !== undefined &&
      this.clientInfoValidGroup.controls['company_type'] !== undefined
    );
  }

  /**
   * Hydrates the cannonical lists of industries and company types from the API.
   */
  initCategoryOptions(): void {
    forkJoin([
      this.categorizationService.getIndustries(this.currentClient.id),
      this.categorizationService.getCompanyTypes(this.currentClient.id),
    ]).subscribe((responses) => {
      this.industries = responses[0].data;
      this.allCompanyTypes = responses[1].data;
    });
  }

  /**
   * Update the list of company types to show to the user in a dropdown.
   *
   * @param industryId the ID of the industry that the company types must belong
   * to
   */
  setCompanyTypeOptions(industryId: string): void {
    if (industryId === '') {
      this.relevantCompanyTypes = [];
    } else {
      this.relevantCompanyTypes =
        this.categorizationService.getCompanyTypesForIndustryFullAncestorPath(
          this.allCompanyTypes,
          new CategoryAncestryDetails({ industryId })
        );
    }
  }

  handleClientIdFieldBlur() {
    const formatted = this.formatClientId(
      this.clientInfoValidGroup.get('client_id').value
    );
    this.clientInfoValidGroup.get('client_id').setValue(formatted);
  }

  trimEmail() {
    const emailCtl = this.primaryContactGroup.controls['email'];
    emailCtl.setValue(emailCtl.value.trim());
  }

  /**
   * Checks the phone number validation
   * formats phone number
   */
  formatPhoneNumber() {
    const mobileNumberCtl = this.primaryContactGroup.controls['mobile_number'];
    if (!mobileNumberCtl || !mobileNumberCtl.value) {
      return;
    }

    if (
      mobileNumberCtl.value.match(
        /^.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d?.*$/
      )
    ) {
      mobileNumberCtl.setValue(
        FormatUtils.formatPhoneNumber(mobileNumberCtl.value)
      );
      this.changeDetectorRef.detectChanges();
    }

    CustomValidators.markAsUntouchedOnEmpty(mobileNumberCtl);
  }

  saveAndContinue(): void {
    // update client object with form data
    const formData = this.clientInfoValidGroup.getRawValue();
    this.client.id = formData.client_id;
    this.client.company_name = formData.company_name;
    this.client.primary_contact = formData.primary_contact as Contact;
    this.client.industry = formData.industry;
    this.client.company_type = formData.company_type;

    forkJoin(
      this.clientService.createClient(this.client),
      this.sftpMgmtService.setup(this.client.id), // create an sftp directory structure...
      this.sftpMgmtService.createUser(this.client.id) // and an sftp user for this client.
    ).subscribe(
      (res: [Client, { message: string }, SftpUser]) => {
        const [createClientResult, sftpSetupResult, sftpUserCreateResult] = res;
        this.onSuccessfulSave(createClientResult);
      },
      (err: HttpErrorResponse) => this.onFailedSaved(err)
    );
  }

  addClientToAllClients() {
    const client = this.client;
    this.sessionService.allClients.unshift(client);
    this.sessionService.allClients.sort((a, b) => {
      if (!a.company_name || !b.company_name) {
        return 0;
      }
      const clientA = a.company_name.toUpperCase();
      const clientB = b.company_name.toUpperCase();
      if (clientA < clientB) {
        return -1;
      }
      if (clientA > clientB) {
        return 1;
      }
      return 0;
    });
  }

  /**
   *
   * @param {Client} res
   */
  private onSuccessfulSave(res: Client): void {
    this.addClientToAllClients();
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/branding-settings`);
  }

  /**
   *
   * @param {HttpErrorResponse} err
   */
  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(
      `Oops...could not save client${serverMessage}`
    );
  }

  // converts uppercase letters and spaces to lowercase and underscores, and
  // removes all non-word characters (ex: $@#^% and so on)
  private formatClientId(enteredId: string): string {
    return enteredId
      .toLowerCase()
      .replace(/[\s\-]/g, '_')
      .replace(/\W/g, '');
  }
}
