import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MessageClass } from '@app/core/models/message';
import { ProductGroup } from '@app/core/models/client';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { Action } from '@app/core/models/action';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { BrandingData } from '@relaynetwork/design-system';

@Component({
  selector: 'phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})

/**
 * There are 6 places where this is still used:
 * - QuickLaunchContentComponent (CSR Onboarding)
 * - TriggerSelectorComponent (journey test launcher)
 * - MessageBuilderComponent
 * - JourneyPreviewComponent
 * - JourneyPdfPreviewComponent
 * - Recursively rendered PhoneComponent
 *
 * All remaining instances use the WirePreviewComponent
 * All other instances have been replaced by the simpler GenericPhoneComponent
 *
 * 1. @prop {message}, only gets passed to/used in @see {WirePreview}.
 * 2. @prop  {stretch},  If true, phone will stretch vertically to contain all content instead of scrolling.  Used in @see {JourneyPreview} & @see {JourneyPdfPreview}.
 * 3. @prop {recursive}, If true, phone component will recursively create phone components for response messages.  Used in @see {JourneyPreview}.
 * 4. @prop {large},self-explanatory.  passed in by @see {JourneyPdfPreview} used by this component
 * 5. @prop {isJourneyPreview}, used to show a particular arrow in @see {JourneyPreview}. passed to/used in wirePreview.
 * 6. @prop {showPdfMarkup}, if true, will show numbers next to actions.  passed in by @see {JourneyPdfPreview}. passed to/used in wirePreview.
 * 7. @prop {productGroup}, is optional because of SMS messages, which don't need one.
 * 8. @prop {screen}, wire or sms are self-explanatory.  form indicates the form preview.
 * 9. @prop {formId}, for FormPreview only.
 * 10. @prop {components}, list of all components for Feed 2.0 render algo (for preview message with Feed 2.0 only).
 * 11. @prop {previewMode}, conditionally make all elements visible/expanded or collapsed - combining with @prop {stretch} (for preview message with Feed 2.0 only).
 */
export class PhoneComponent implements AfterViewInit, OnInit {
  @Input() message?: MessageClass; /* [1] */
  @Input() productGroup?: ProductGroup; /* [7] */
  @Input() screen: 'wire' | 'sms' | 'form'; /* [8] */
  @Input() stretch: boolean = false; /* [2] */
  @Input() recursive: boolean = false; /* [3] */
  @Input() journeyContent: JourneyContent; /* [1] */
  @Input() hasIWCU?: boolean; /* [1] */
  @Input() large?: boolean = false; /* [4] */
  @Input() isJourneyPreview?: boolean = false; /* [5] */
  @Input() showPdfMarkup?: boolean = false; /* [6] */
  @Input() formLabel: string; /* [9] */
  @Input() formId: number; /* [9] */
  @Input() components?: Journey['live']['components']; /** [10] */
  @Input() previewMode?: 'expanded' | 'collapsed' = 'collapsed'; /** [11] */
  @Output() toggleCollapsible: EventEmitter<void> = new EventEmitter<void>();

  placeholder: string = `https://${window.location.host}/assets/form-placeholder.png`;
  viewMenu: boolean = false;
  headerTitle: string;
  generations = [];
  disclaimerConfig: BrandingData['disclaimer'];

  constructor(public newFeed: NewFeedTransitionService) {}

  ngOnInit() {
    this.disclaimerConfig = this.productGroup
      ? {
          feedMenuEnabled:
            this.productGroup.disclaimer.feed_menu_enabled === 'true'
              ? true
              : false,
          feedViewEnabled:
            this.productGroup.disclaimer.feed_view_enabled === 'true'
              ? true
              : false,
          messageDetailsEnabled:
            this.productGroup.disclaimer.message_details_enabled === 'true'
              ? true
              : false,
          messageLevelEnabled:
            this.productGroup.disclaimer.message_level_enabled === 'true'
              ? true
              : false,
          text: this.productGroup.disclaimer.text,
        }
      : {
          feedMenuEnabled: false,
          feedViewEnabled: false,
          messageDetailsEnabled: false,
          messageLevelEnabled: false,
          text: '',
        };
  }

  ngAfterViewInit() {
    if (this.message && this.message.type === 'MessageSender') {
      this.getDepth(this.message, 0);
    }
  }

  getOrigin(): string {
    return window.location.origin;
  }

  wrapperId(): string | undefined {
    return this.message ? `phone-${this.message.name}` : undefined;
  }

  /**
   * Returns array of form actions
   */
  getFormActions(): Action[] {
    return this.message.wire.actions.filter((action) =>
      ['form_info_capture'].includes(action.type),
    );
  }

  getDepth(msg, depth: number) {
    if (!this.generations[depth]) {
      this.generations[depth] = [];
    }
    const responses = this.getResponseMessages(msg);

    this.generations[depth] = this.generations[depth].concat(responses);
    responses.forEach((r) => {
      this.getDepth(r, depth + 1);
    });
  }

  /**
   * Gets all Components in the components array that have the
   * current Message for this Phone component as a parent
   */
  getAllResponseMessages() {
    if (this.journeyContent && this.journeyContent.components) {
      const comps = this.journeyContent.components;
      const msgs = comps.filter((c) => c.parent === this.message.name);
      return msgs;
    } else {
      return [];
    }
  }

  /**
   * Gets all Components in the components array that have the
   * passed message as a parent
   */
  getResponseMessages(parent) {
    if (this.journeyContent && this.journeyContent.components) {
      const comps = this.journeyContent.components;
      const msgs = comps.filter((f) => f.parent === parent.name);
      return msgs;
    } else {
      return [];
    }
  }

  get headerColor() {
    return this.productGroup && this.productGroup.branding.color
      ? this.productGroup.branding.color
      : '#231F20';
  }

  get logoUrl() {
    return this.productGroup && this.productGroup.branding.icon_s3_url
      ? this.productGroup.branding.icon_s3_url
      : '';
  }

  get messageId() {
    return this.message ? this.message.name : '';
  }

  get powerUpPlaceholderUrl() {
    return `${this.getOrigin()}/assets/power-up-placeholder.png`;
  }
}
