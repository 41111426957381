import { Injectable } from '@angular/core';

@Injectable()
export class ChatContextService {
  private channelSid: string | null;
  constructor() {
    this.channelSid = null;
  }

  public getChannelSid() {
    return this.channelSid;
  }

  public setChannelSid(channelSid: string) {
    this.channelSid = channelSid;
  }

  public unsetChannelSid() {
    this.channelSid = null;
  }
}
