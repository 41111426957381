import { SessionService } from '../../security/session.service';
import { Injectable } from '@angular/core';

/**
 * This class initializes and holds the latest client dynamic inputs to be used in the message builder
 * and the tinymce mentions plugin configuration.
 * 
 * This effectively caches the data, so that if a refresh call fails in the message builder
 * the last good data is still here.
 */
@Injectable()
export class DynamicInputsService {

  clientDynamicInputs: string[] = [];
  
  constructor(sessionService: SessionService) { 
    this.clientDynamicInputs = sessionService.currentUser.client.ext_dynamic_fields;
    sessionService.clientChanged.subscribe((event: any) => {
      this.clientDynamicInputs = sessionService.currentUser.client.ext_dynamic_fields;
    });
  }
}
