import { AbstractControl } from '@angular/forms';
import { Buffer } from 'buffer';

export class SftpCustomValidators {

  // this function is partially duplicated from admin serverless project 
  static isValidSSH2Format = (ssh2Key) => {
    const ssh2DataRegex = /^---- BEGIN SSH2 PUBLIC KEY ----.*"?.*"?(AAAAB3NzaC1yc2EA[a-zA-Z0-9\+\=/]*)---- END SSH2 PUBLIC KEY ----$/; // grab ssh2 data out of a properly-formatted key
    try {
      const ssh2NoWhitespace = ssh2Key.trim().replace(/[\n\r]/g, '');
      const ssh2Data = ssh2DataRegex.exec(ssh2NoWhitespace)[1]; // attempt to grab the data portion out of the string
      Buffer.from(ssh2Data, 'base64'); // attempt to decode from base 64.  If successful, this is likely a valid key
      return true;
    } catch (err) {
      return false;
    }
  }

  static isValidSSH2FormatValidator(control: AbstractControl): { [key: string]: any } {
    if (SftpCustomValidators.isValidSSH2Format(control.value)) {
      return null;
    } else {
      return {
        'invalidSSH2Key': { value: control.value }
      };
    }
  }
}
