import { Pipe, PipeTransform } from '@angular/core';
import { ChannelAddress, Consent } from '@app/core/models/customer';

@Pipe({
  name: 'filterChannels',
})
export class FilterChannelsPipe implements PipeTransform {
  transform<T extends ChannelAddress | Consent>(value: T[], channelType: string = 'sms'): T[] {
    return (value ?? []).filter(v => v.channel_type === channelType);
  }
}
