import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { theme } from '@app/relay-iq/baseweb-custom-theme/customTheme';
import { ClientInfoProvider } from '@app/client-config/client-form/client-power-ups-config/context/ClientInfoContext';
import { PowerUpsConfigHome } from '@app/client-config/client-form/client-power-ups-config/power-ups-config-home/PowerUpsConfigHome';

export type PowerUpsConfigReactContainerProps = {
  clientId: string;
};

// generate classNames with a prefix of 'power-ups-config'
const engine = new Styletron({ prefix: 'power-ups-config-' });

/**
 * A middleware container that is rendered by the Angular push-api component.
 * Sets up the providers (much like one would do in the top-level App component
 * of a full-React app).
 */
export const PowerUpsConfigReactContainer = ({
  clientId
}: PowerUpsConfigReactContainerProps) => {
  return (
    <ClientInfoProvider
      clientId={clientId}
    >
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme} zIndex={202}>
          <PowerUpsConfigHome />
        </BaseProvider>
      </StyletronProvider>
    </ClientInfoProvider>
  );
};