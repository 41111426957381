import { styled } from 'baseui';

export const DocumentationContainer = styled('div', {
  paddingRight: '25px',
  paddingLeft: '25px',
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const DocumentationText = styled('div', {
  marginBottom: '25px',
});

//adapted from apikey-mgmt.component: intro-line-1 and TableContainer
export const IntroLineContainer = styled('h2', {
  color: '#5b5a59',
  marginTop: '25px',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31px',
  paddingRight: '25px',
  paddingLeft: '25px',
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const IntroLineHeader = styled('div', {
  display: 'inline-block',
});

export const AddButtonContainer = styled('div', {
  marginLeft: '25px',
  display: 'inline-block',
});
