import { Category } from '@app/core/models/categorization-types';
import { Direction } from '@app/core/utils/ordering';

export class SortingUtils {
  static sortById<T extends Category = Category>(data: T[], direction: Direction): T[] {
    data = data.sort((a, b) => {
      const valA = parseInt(a.id);
      const valB = parseInt(b.id); 
      return this.compareValuesForSort(valA, valB, direction);
    })

    return data;
  }
  
  static sortByTitle<T extends Category = Category>(data: T[], direction: Direction): T[]{
    data = data.sort((a, b) => {
      const valA = a.title['en-us'].toLowerCase(); 
      const valB = b.title['en-us'].toLowerCase(); 
      return this.compareValuesForSort(valA, valB, direction);
    });

    return data;
  }

  static sortByDate<T extends Category = Category>(data: T[], direction: Direction, field: 'created_at' | 'updated_at'): T[]{
    data = data.sort((a, b) => {
      const valA = a[field];
      const valB = b[field]; 
      return this.compareValuesForSort(valA, valB, direction);
    })

    return data;
  }
  
  static compareValuesForSort(valA: string | number, valB: string | number, direction: Direction): number {
    
    if (valA === valB) { return 0; }

    if (direction === Direction.Asc) {
      return valA > valB ? 1 : -1; 
    } else {
      return valA < valB ? 1 : -1; 
    } 
  }
}
