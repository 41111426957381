<div class='loader' *ngIf="loading === true">
  <div class='spinner'>
    <div class='container'>
      <div class='hex0'></div>
      <div class='hex120'></div>
      <div class='hex240'></div>
    </div>
  </div>
</div>
<ng-container *ngIf="wire && productGroup">
  <div *ngIf="wire.show_banner" class="banner-container">
    <img class="banner" [src]="productGroup.branding.banner_s3_url" alt="Branding banner"/>
  </div>
  <div class="wrapper" *ngIf="loading === false">
    <div class="brand">
      <div class="brand-image" *ngIf="brandingPG" [class.no-brand]="brandingPG.name === 'all' && !wire.branding">
          <img [src]="brandingPG.branding.icon_s3_url"/>
      </div>
      <div class="brand-name">
        <h3>{{brandingPG?.name}}</h3>
        <p>{{dateCreated | date:'short'}}</p>
      </div>
      <div class="brand-options">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
      </div>
    </div>

    <div *ngIf="wire['media_type']" class="{{mediaClasses}}" [style.background-image]="videoPlaceholderUrl">
      <img class="media-image" *ngIf="wire['media_type'] === 'image' && wire['image_url']" [src]="wire['image_url']"/>
      <app-video *ngIf="!showPdfMarkup && wire['media_type'] === 'video' && wire['video_url']" [videoUrl]="wire['video_url']"></app-video>
      <div *ngIf="showPdfMarkup && wire['media_type'] === 'video'" class="media-placeholder">{{videoShortname}}</div>
    </div>
    <div class="card-phone-summary" [innerHtml]="wire['text'] | safeHtml">

    </div>
    <div class="actions">
      <div *ngFor="let action of wire['actions']; let i = index;" (click)="doAction(action, $event)">
        <div class="action" *ngIf="action.type !== 'consent_upgrade' && action.type !== 'disclaimer' && !(action.type === 'two_way' && !twoWayService.showTwoWay())">
          <span class='action-number' *ngIf="showPdfMarkup">{{i + 1}}</span>
          <div id="step-action-{{action.id}}">
            <a class="action-text hyperlink-action" *ngIf="action.type === 'hyperlink'" href="{{action.value}}" target="_blank">{{action.label}}</a>
            <a class="action-text call-action" *ngIf="action.type === 'call'" href="tel:{{action.value}}">{{action.label}}</a>
            <a class="action-text email-action" *ngIf="action.type === 'email'"
              href="mailto:{{action['value']}}?subject={{action['label']}}">{{action.label}}</a>
            <span class="action-text collapsible-action" *ngIf="action.type === 'collapsible'">
              {{action.label}}
            </span>
            <span class="action-text message-action" *ngIf="isSimpleAction(action)">
              {{action.label}}
            </span>
            <span *ngIf="action.type === 'message' && isJourneyPreview" class="step-action">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width='58' height='90'>
                <g transform="translate(35, 52)">
                  <path d="M 20,11 5,20 5,3 z"/>
                </g>
                <line class="top-line" x1="24" y1="0"
                      stroke-width="2"/>
                <line class="down-line" x1="25" x2="25" y1="68" y2="0"
                      stroke-width="2"/>
                <line class="bottom-line" id="" x1="12" y1="0"
                      stroke-width="2"/>
              </svg>
            </span>
          </div>
          <i class="fa fa-angle-right" aria-hidden="true" *ngIf="action.show !== true"></i>
          <i class="fa fa-angle-down" aria-hidden="true" *ngIf="action.show === true"></i>
        </div>
        <div *ngIf="action.type === 'collapsible'" class="collapsible-content" [ngClass]="{'show':action.show}"
            [innerHtml]="action.value | safeHtml"></div>
      </div>

      <div *ngFor="let action of wire['actions']">
        <div class="form-group consent-upgrade"
            *ngIf="hasIWCU && action.type === 'consent_upgrade'">
          <div class="form-input">
            <hr>
            <p
              class="consent-label"
              *ngIf="brandingPG.name !== 'all'"
              [innerHtml]="productGroup.consent.in_wire_upgrade.ts_cs | safeHtml"
            ></p>
            <p
              class="consent-label"
              *ngIf="brandingPG.name === 'all'"
            ><label>Terms and Conditions will show for the user's default product group</label></p>
            <div class="checkbox">
              <input type="checkbox" id="upgrade_consent">
              <label for="upgrade_consent">I accept the terms and conditions</label>
            </div>
            <hr>
          </div>
        </div>

        <div id="step-action-{{action.id}}"  
            *ngIf="hasIWCU && action.type === 'consent_upgrade'">
            <span class="message-action">
              {{action.label}}
            </span>
        </div>
      </div>

    </div>
  </div>
  <div class="disclaimer">
    <div *ngIf="productGroup?.disclaimer.message_details_enabled == 'true'"
        [innerHtml]="productGroup.disclaimer.text | safeHtml">
    </div>
  </div>
</ng-container>
