import React, { useCallback } from 'react';
import { DURATION, useSnackbar } from 'baseui/snackbar';
import { Icon } from '@relaynetwork/design-system';

/**
 *  This is a custom hook for displaying an alert or info message
 * @param msg - The text message to be displayed
 * @param type - The type of icon to be added to the message
 */
export const useAlertMessage = () => {
  const { enqueue } = useSnackbar();

  const successPingIcon = () => (
    <Icon name="Success" size="small" color="#b2cf52" />
  );
  const errorIcon = () => <Icon name="Error" size="small" color="#ff7070" />;

  const showMessage = useCallback(
    (msg: string, type?: 'success' | 'error') => {
      enqueue(
        {
          message: msg,
          startEnhancer:
            type && (type === 'success' ? successPingIcon : errorIcon),
        },
        DURATION.medium,
      );
    },
    [enqueue],
  );
  return showMessage;
};
