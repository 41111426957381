import React, { useState } from 'react';
import { PLACEMENT, Popover } from 'baseui/popover';
import { CalendarComponent } from '../../datepicker/CalendarComponent';
import { Button } from 'baseui/button';
import { ChevronRight, Check } from 'baseui/icon';
import {
  FilterMethodOptions,
  FilterOptionDateCreatedClickedType,
  FilterOptionLastModifiedClickedType,
  FilterOptionType,
} from '../../relay-messenger-conversations/RelayMessengerConversations';

type FilterCustomButtonProps = {
  value: FilterMethodOptions;
  customDateClicked: FilterOptionType;
  filterOptionClicked?:
    | FilterOptionLastModifiedClickedType
    | FilterOptionDateCreatedClickedType;
  filterByValue: FilterOptionType;
  onSuccess: (value: Date[]) => void;
  close: () => void;
  dataTestId: string;
  rangeDate: Date[];
  setRangeDate: (value: Date[]) => void;
  innerClose: () => void;
};

export const FilterCustomButton = ({
  value,
  customDateClicked,
  filterOptionClicked,
  filterByValue,
  onSuccess,
  close,
  dataTestId,
  rangeDate,
  setRangeDate,
  innerClose,
}: FilterCustomButtonProps) => {
  // calendar state
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <Popover
      isOpen={isCalendarOpen}
      content={() => (
        <CalendarComponent
          rangeDate={rangeDate}
          close={() => {
            setIsCalendarOpen(false);
            innerClose();
          }}
          onChange={(dates) => {
            setRangeDate(dates);
            if (Array.isArray(dates) && dates.length === 2) {
              setIsCalendarOpen(false);
              onSuccess(dates);
              close();
            }
          }}
        />
      )}
      onClick={() => setIsCalendarOpen(true)}
      onClickOutside={() => {
        setIsCalendarOpen(false);
        close();
      }}
      placement={PLACEMENT.right}
      overrides={{
        Body: {
          style: {
            marginLeft: '25px',
          },
        },
      }}
    >
      <Button
        value={FilterMethodOptions.Custom}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              display: 'flex',
              color:
                filterOptionClicked && Array.isArray(customDateClicked)
                  ? '#3693BF'
                  : 'black',
              backgroundColor:
                filterOptionClicked && isCalendarOpen
                  ? '#E1EFF5'
                  : 'transparent',
              fontWeight:
                filterOptionClicked && Array.isArray(customDateClicked)
                  ? 'bolder'
                  : 'inherit',
              textAlign: 'left',
              fontSize: '12px',
              outline: 'none !important',
              justifyContent: 'left',
              ':hover': {
                backgroundColor: '#E1EFF5',
              },
              ':active': {
                backgroundColor: 'transparent',
              },
            },
            props: {
              'data-testid': dataTestId,
            },
          },
        }}
        endEnhancer={() => <ChevronRight />}
        startEnhancer={() =>
          filterOptionClicked && Array.isArray(filterByValue) ? (
            <Check
              size={'20px'}
              color="#3693BF"
              overrides={{
                Svg: {
                  style: {
                    display: 'flex',
                  },
                },
              }}
            />
          ) : (
            ''
          )
        }
      >
        {value}
      </Button>
    </Popover>
  );
};
