<p>
    Copy the contents of the SSH2 key provided by {{client.company_name}}.
</p>
<p><strong>Note: The format must be SSH2.</strong> If the key is standard SSH, please follow the instructions below to convert the key format.</p>

<client-header [showElement]="false" [headerLabel]="'Instructions (mac)'" (toggleHeader)="showMacInstructions=!showMacInstructions"></client-header>
<ol class="sftp-mgmt_conversion-instructions-list" *ngIf="showMacInstructions">
    <li>
        <span>Save the SSH pub key file from the client to your desktop</span>
    </li>
    <li>
        <span>Copy the file's name here (without extension): </span>
        <input type="text" [formControl]="sshKeyFilenameCtl">
    </li>
    <li>Open Terminal by searching for "terminal" in Spotlight (<code>cmd</code> + <code>spacebar</code>)</li>
    <li>Paste the following code snippet into the terminal
        <pre class="sftp-mgmt__code-block">{{macInstructions | once: sshKeyFilenameCtl.value}}</pre>
        <rn-copied-message [inputData]="getSshKeyFilename()" [disableBtn]="sshKeyFilenameCtl.value.length === 0"></rn-copied-message>
    </li>
    <li>Hit enter</li>
    <li>Open the file named "{{sshKeyFilenameCtl.value}}.ssh2" that is now saved to your desktop</li>
    <li>Open this file, select all contents</li>
    <li>Paste the contents in the input to the right</li>
    <li>Click “upload”</li>
</ol>
