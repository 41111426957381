import { LoadingMaskService } from '../../core/services/loading-mask.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.scss']
})
export class LoadingMaskComponent implements OnInit {

  constructor(public loadingMaskService: LoadingMaskService) { }

  ngOnInit() {}
}
