import { Injectable, EventEmitter } from '@angular/core';
@Injectable()
export class LoadingMaskService {
  loadingErrorEvent: EventEmitter<string> = new EventEmitter<string>();
  loadingMaskOn = false;
  loadingMaskCount = 0;
  
  constructor() {
      
  }
  loadingOn() {
      this.loadingMaskOn = true;
      this.loadingMaskCount++;
  }
  loadingOff(forceClose = false) {
      if (this.loadingMaskCount > 0) {
          this.loadingMaskCount--;
      }
      if (this.loadingMaskCount === 0 || forceClose === true) {
          this.loadingMaskCount = 0;
          this.loadingMaskOn = false;
      }
  }
  throwError(err: string) {
      this.loadingOff(true);
      this.loadingErrorEvent.emit(err);
  }
}
