import { Component, Input } from '@angular/core';
import { ProductGroup } from '@app/core/models/client';

@Component({
  selector: 'app-banner-preview',
  templateUrl: './banner-preview.component.html',
  styleUrls: ['./banner-preview.component.scss']
})
export class BannerPreviewComponent {
  @Input() productGroup: ProductGroup;
  @Input() viewProductInfo: boolean;
}
