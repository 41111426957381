<div
  class="intro-line-1"
  i18n="Introductory for creating a new client - step 1, line 1"
>
  Let's get these people onboard.
</div>

<ng-container *ngIf="showJourneySelect">
  <div class="dialog-background under-title"></div>
  <div class="copy-wrapper">
    <app-journey-list
      [selectionType]="'CSR'"
      [showCheckboxes]="true"
      [listTitle]="'Select your user Agent/CSR experience(s)'"
      [style.top]="journeyDialogPosition"
    ></app-journey-list>
  </div>
</ng-container>

<client-header
  [headerLabel]="'Client level onboarding'"
  [headerGroup]="'onboarding'"
  (click)="headerGroups.onboarding = !headerGroups.onboarding"
></client-header>

<div class="collapse-container" *ngIf="headerGroups.onboarding && client">
  <div class="toggle-container">
    <app-radio-btn
      class="toggle-container"
      [btnLabel]="'Mobile Analysis'"
      [btnName]="'mobile_analysis-btn'"
      [(btnModel)]="client.mobile_analysis.enabled"
      [tiptext]="
        'Mobile analysis is used to determine if a phone number is assigned to a mobile account.'
      "
    ></app-radio-btn>
    <app-radio-btn
      class="toggle-container"
      [btnLabel]="'Strict Onboarding'"
      [btnName]="'mobile_analysis_prevent_onboarding-btn'"
      [(btnModel)]="client.mobile_analysis.should_prevent_onboarding"
      [tiptext]="
        'Strict onboarding is used to only allow mobile phone numbers to be onboarded.'
      "
      [disabled]="!client.mobile_analysis.enabled"
    ></app-radio-btn>
  </div>
  <div class="onboard-line-1">Onboarding channels</div>
  <div class="onboard-line-2">
    If selecting channels that use API keys, specific file formats, or SFTP
    credentials, the details and specifications will be compiled in the
    Configuration Report.
  </div>
  <div class="toggle-container" *ngIf="client">
    <div class="dash-wrapper">
      <app-onboarding-toggle
        id="api_toggle"
        [btnLabel]="'API'"
        [btnName]="'api-onboarding-btn'"
        [(btnModel)]="client.onboarding.api.enabled"
      ></app-onboarding-toggle>
    </div>
    <div class="dash-wrapper">
      <app-onboarding-toggle
        id="file_toggle"
        [btnLabel]="'File'"
        [btnName]="'file-onboarding-btn'"
        [(btnModel)]="client.onboarding.file.enabled"
      ></app-onboarding-toggle>
    </div>
    <div class="dash-wrapper">
      <app-onboarding-toggle
        id="ivr_toggle"
        [btnLabel]="'IVR'"
        [btnName]="'file-onboarding-btn'"
        [(btnModel)]="client.onboarding.ivr.enabled"
      ></app-onboarding-toggle>

      <app-ivr-config
        [ngClass]="{ hidden: client.onboarding.ivr.enabled === 'false' }"
        [ivrData]="client.onboarding.ivr"
        [productGroupOptions]="productGroupOptions"
        (ivrFormValid)="updateIvrFormStatus($event)"
        (ivrDataUpdated)="updateIvrData($event)"
      ></app-ivr-config>
    </div>
    <div class="dash-wrapper" *ngIf="client.validation.enabled === 'true'">
      <app-agent-onboarding-config
        [(client)]="client"
        [ngStyle]="{ 'border-top-width.px': '1', 'border-top-style': 'dashed' }"
      ></app-agent-onboarding-config>
    </div>
  </div>
</div>

<client-header
  [headerLabel]="'File processing configuration'"
  [headerGroup]="'fileProcessingConfig'"
  (click)="
    headerGroups.fileProcessingConfig = !headerGroups.fileProcessingConfig
  "
></client-header>

<div *ngIf="client && client.job_processing">
  <div class="collapse-container" *ngIf="headerGroups.fileProcessingConfig">
    <app-file-processing-config
      #fileProcessingConfig
      [jobProcessing]="client.job_processing"
      (isValid)="checkIfFileProcessingValid($event)"
    ></app-file-processing-config>
  </div>
</div>

<div *ngIf="client">
  <client-header
    [headerLabel]="'External Config'"
    [headerGroup]="'External Config'"
    (click)="headerGroups.externalConfig = !headerGroups.externalConfig"
  ></client-header>
  <div class="collapse-container" *ngIf="headerGroups.externalConfig">
    <label>Registration URL Match Pattern:</label>
    {{ client.external_config?.registration?.url_match_pattern }}
  </div>
</div>

<div *ngIf="client">
  <client-header
    [headerLabel]="
      this.client.feed_enabled ? 'Notification Archive Config' : 'SMS Archive Config'
    "
    [headerGroup]="
      this.client.feed_enabled ? 'Notification Archive Config' : 'SMS Archive Config'
    "
    (click)="headerGroups.smsArchive = !headerGroups.smsArchive"
  ></client-header>
  <div class="collapse-container" *ngIf="headerGroups.smsArchive">
    <app-client-sms-archive
      [client]="client"
      [smtpCredentials]="smtpCredentials"
      (smtpCredentialsChange)="onSMTPChange($event)"
    ></app-client-sms-archive>
  </div>
</div>

<div class="button-container clearfix">
  <button
    id="prev-btn"
    class="btn btn-primary btn-with-icon"
    (click)="previousStep()"
  >
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button
    class="btn btn-primary"
    id="next-btn"
    (click)="saveAndContinue()"
    [class.inactive]="isDisabled()"
    [disabled]="isDisabled()"
  >
    <span
      i18n="Label for Next button on new client configuration"
      id="save-and-next-button"
    >
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
