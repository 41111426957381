import { styled } from 'baseui';

export const InfoContainer = styled('div', {
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const InfoText = styled('div', {
  marginTop: '25px',
  marginBottom: '10px',
  fontSize: '14px',
  fontWeight: '400',
});

//adapted from apikey-mgmt.component: intro-line-1 and TableContainer
export const IntroLineContainer = styled('h2', {
  color: '#5b5a59',
  marginTop: '25px',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31px',
  paddingRight: '25px',
  paddingLeft: '25px',
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const IntroLineHeader = styled('div', {
  display: 'inline-block',
});

export const AddButtonContainer = styled('div', {
  marginLeft: '25px',
  display: 'inline-block',
});
