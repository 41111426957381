<ng-template #arrow>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-triangle" width='164' height='45'>
    <g transform="translate(140, 15)">
      <path d="M 24,15 0,24 0,8 z" />
    </g>
    <line x1="160" y1="30" x2="0" y2="30" />
  </svg>
</ng-template>

<div class="text-center">
  <ng-container *ngTemplateOutlet="arrow"></ng-container>
  <img src="assets/hex/delay.png" />
  <ng-container *ngTemplateOutlet="arrow"></ng-container>
  <br>
  <div class="delay-text" id="delay-{{delay.name.substr(0,7)}}">
    <span i18n="Time delay preview label">Time Delay:</span>
    <br>
    <span id="delay-interval-value-{{delay.name.substr(0,7)}}">{{delay.interval_value}}</span> 
    <span id="delay-interval-type-{{delay.name.substr(0,7)}}">{{delay.interval_type}}</span>
  </div>
</div>