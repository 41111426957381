// angular + modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelaySharedModule } from '@app/shared/shared.module';

// services
import { SftpMgmtService } from '@app/sftp-mgmt/services/sftp-mgmt.service';

// components
import { InternalSftpMgmtComponent } from '@app/sftp-mgmt/components/internal-sftp-mgmt/internal-sftp-mgmt.component';
import { ExternalSftpMgmtComponent } from '@app/sftp-mgmt/components/external-sftp-mgmt/external-sftp-mgmt.component';
import { SftpMgmtComponent } from '@app/sftp-mgmt/components/sftp-mgmt/sftp-mgmt.component';
import { ClientConfigModule } from '@app/client-config/client-config.module';
import { SecurityModule } from '@app/security/security.module';
import { SftpKeyUploadInstructionsComponent } from './components/sftp-key-upload-instructions/sftp-key-upload-instructions.component';
import { SftpKeyListElementComponent } from './components/sftp-key-list-element/sftp-key-list-element.component';
import { SftpNewKeyComponent } from './components/sftp-new-key/sftp-new-key.component';
import { SftpKeyListComponent } from './components/sftp-key-list/sftp-key-list.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    ClientConfigModule,
    SecurityModule
  ],
  declarations: [
    InternalSftpMgmtComponent,
    ExternalSftpMgmtComponent,
    SftpMgmtComponent,
    SftpKeyUploadInstructionsComponent,
    SftpKeyListElementComponent,
    SftpNewKeyComponent,
    SftpKeyListComponent
  ],
  providers: [
    SftpMgmtService
  ],
  exports: [
  ]
})

export class SftpMgmtModule {
}
