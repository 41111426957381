import React from 'react';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { CustomerActions } from './CustomerActions';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Button, KIND, SIZE } from 'baseui/button';
import { Icon } from '@relaynetwork/design-system';

export type CustomerActionsPopoverProps = {
  isActionsPopoverOpen: boolean;
  setIsActionsPopoverOpen: (value: boolean) => void;
  setIsPingModalOpen: (value: boolean) => void;
  setIsCloseChannelModalOpen: (value: boolean) => void;
  navigateToExperienceLauncher: () => void;
};

export const CustomerActionsPopover = ({
  isActionsPopoverOpen,
  setIsActionsPopoverOpen,
  setIsPingModalOpen,
  setIsCloseChannelModalOpen,
  navigateToExperienceLauncher,
}: CustomerActionsPopoverProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  return (
    <StatefulPopover
      content={({ close }) => (
        <CustomerActions
          closePopover={close}
          setIsPingModalOpen={setIsPingModalOpen}
          setIsCloseChannelModalOpen={setIsCloseChannelModalOpen}
          navigateToExperienceLauncher={navigateToExperienceLauncher}
        />
      )}
      placement={PLACEMENT.bottomRight}
      onClose={() => setIsActionsPopoverOpen(false)}
      onOpen={() => setIsActionsPopoverOpen(true)}
      autoFocus={false}
      overrides={{
        Inner: {
          style: ({}) => ({
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }),
        },
        Body: {
          style: ({}) => ({
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
          }),
        },
      }}
    >
      <Button
        kind={KIND.secondary}
        size={SIZE.mini}
        endEnhancer={() => <Icon name="EllipsisVertical" size="small" />}
        overrides={{
          BaseButton: {
            style: () => ({
              width: 'auto',
              height: '40px',
              borderTopLeftRadius:
                customTheme.borders.agentInfoButtonBorderRadius,
              borderBottomLeftRadius:
                customTheme.borders.agentInfoButtonBorderRadius,
              borderTopRightRadius:
                customTheme.borders.agentInfoButtonBorderRadius,
              borderBottomRightRadius:
                customTheme.borders.agentInfoButtonBorderRadius,
              color: '#3693BF',
              backgroundColor: customTheme.colors.white,
              paddingLeft: '2rem',
              paddingRight: '2rem',
              boxShadow: 'rgba(54, 147, 191, 0.23) 0px 0px 5px 0px',
              ':focus': {
                ...(isActionsPopoverOpen && {
                  backgroundColor: '#286B8A',
                  color: customTheme.colors.white,
                }),
              },
            }),
            props: { 'data-testid': 'customer-actions-btn' },
          },
        }}
      >
        Actions
      </Button>
    </StatefulPopover>
  );
};
