import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rn-channel-list-headings',
  templateUrl: './channel-list-headings.component.html',
  styleUrls: ['./channel-list-headings.component.scss']
})
export class ChannelListHeadingsComponent {
  /* 
    null can be passed in for a heading block with no title. 
    Used to maintain consistency in flexbox table layouts.
  */ 
  @Input() headings: (string | null)[];
  @Input() prefix: string;
  @Input() ordering: string;
  @Output() headingClicked: EventEmitter<string> = new EventEmitter();

  constructor() {}

  headingClick(heading): void {
    this.headingClicked.emit(heading);
  }

  isBlank(heading): boolean {
    return heading === null;
  }
}
