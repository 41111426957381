
<div class="toolbar no-text-selection" [style.width.px]="currentWidth">
  <img id="expand-btn" [class.active]="!this.toolbarService.collapsed" src="assets/icons/expand-toggle.png" class="collapse-toggle" (click)="toggleSize()">
  <img id="collapse-btn" [class.active]="this.toolbarService.collapsed" src="assets/icons/collapse-toggle.png" class="collapse-toggle" (click)="toggleSize()">

  <div class="toolbar-item" [class.active]="inspectorActive" (click)="toggleInspector()">
    <i class="fa fa-binoculars" aria-hidden="true"></i>
    <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label for inspector">Inspect</span>
  </div>

  <ng-container *ngIf="inspectorActive">
    <app-journey-inspector *ngIf="!selectedComponent"
        #activeInspector
        [journeyId]="journeyId"
        [journey]="journey"
        (close)="toggleInspector()">
    </app-journey-inspector>
    <app-message-inspector
        *ngIf="selectedComponent && (selectedComponent.type === 'MessageSender' || selectedComponent.type === 'MessageResponse')"
        #activeInspector
        [journeyId]="journeyId"
        [journey]="journey"
        [message]="selectedComponent"
        (close)="toggleInspector()">
    </app-message-inspector>
    <app-delay-inspector
        *ngIf="selectedComponent && selectedComponent.type === 'WorkflowDelayer'"
        #activeInspector
        [journeyId]="journeyId"
        [journey]="journey"
        [delayer]="selectedComponent"
        (close)="toggleInspector()">
    </app-delay-inspector>
    <app-trigger-inspector
        *ngIf="selectedComponent && selectedComponent.type === 'APITrigger'"
        #activeInspector
        [journeyId]="journeyId"
        [journey]="journey"
        [trigger]="selectedComponent"
        (close)="toggleInspector()"
        (blur)="saveIfNeeded()">
    </app-trigger-inspector>
  </ng-container>

  <img class="toolbar-divider" src="assets/side-navbar-divider.png" [style.width.px]="currentWidth">
  <div *ngIf="ps.checkPermissions(permissions.journey_trigger)" id="test-journey-item" class="toolbar-item" routerLink="/launcher/test/{{journeyId}}" [class.disabled]="!journey?.live">
    <span><i class="fa-solid fa-paper-plane" aria-hidden="true"></i></span>
    <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label testing an experience">Test</span>
  </div>
  <div id="preview-journey-item" class="toolbar-item" (click)="previewJourney()" [class.disabled]="!journeyPreviewable()">
    <span><i class="fa-solid fa-file-magnifying-glass" aria-hidden="true"></i></span>
    <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label testing an experience">Preview</span>
  </div>
  <div id="preview-pdf-item" class="toolbar-item" (click)="pdfPreview()" [class.disabled]="!journeyPreviewable()">
    <span><i class="fa-solid fa-file-pdf" aria-hidden="true"></i></span>
    <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label testing an experience">PDF Preview</span>
  </div>

  <ng-container>
    <div class="modal" [ngClass]="{'show': pdfPreviewDropdown}" (click)="closeModal()">
      <div class="modal-content" (click)="$event.stopPropagation()">
        <div id="expandedPdfOption" class="toolbar-item" (click)="expandedPdfPreview()">
          <span><i class="fa-solid fa-file-pdf" aria-hidden="true"></i></span>
          <span>Expanded PDF</span>
        </div>
        <div id="collapsedPdfOption" class="toolbar-item" (click)="collapsedPdfPreview()">
          <span><i class="fa-solid fa-file-pdf" aria-hidden="true"></i></span>
          <span>Collapsed PDF</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="ps.checkPermissions(permissions.journey_promote)">
    <img class="toolbar-divider" src="assets/side-navbar-divider.png" [style.width.px]="currentWidth">
    <div class="toggle" *ngIf="ps.checkPermissions(permissions.journey_promote)">
      <div id="draft-item" class="toolbar-draft-complete draft" [class.collapsed]="this.toolbarService.collapsed" [class.active]="journey?.draft">
        <i class="fa fa-pause-circle-o" aria-hidden="true"></i>
        <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label for experience that is in draft mode">Draft</span>
      </div>
      <div id="complete-journey-item" class="toolbar-draft-complete complete" (click)="completeJourney()" [class.collapsed]="this.toolbarService.collapsed" [class.active]="!journey?.draft">
        <i class="fa fa-play-circle-o" aria-hidden="true"></i>
        <span class="menu-text" [class.active]="!this.toolbarService.collapsed" i18n="Toolbar label for experience that is complete (not draft)">Complete</span>
      </div>
    </div>
  </ng-container>
  <img class="toolbar-divider" src="assets/side-navbar-divider.png" [style.width.px]="currentWidth">
</div>

<message-dialog></message-dialog>
