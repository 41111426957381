<div class="form-group" *ngIf="listForm" [formGroup]="listForm">
  <div class="fields-group">
    <div>
      <div class="add-item-input-group">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        <div class="input-autocomplete-container">
          <input #autocompleteField  class="form-control add-item-input" name="new-item" formControlName="new_item" autocomplete="off" [attr.disabled]="listAtLimit() ? true : null" />
        </div>
        <button class="btn btn-primary" type="button" (click)="addItem(item)" [attr.disabled]="listAtLimit() ? true : null">ADD</button>
      </div>
      <p *ngIf="isDuplicateItem(newItemCtl.value)" class="error-text">This tag is already in the group</p>
      <div class="editable-list">
        <div class="added-item clearfix" *ngFor="let item of list">
          <span>{{item}}</span>
          <i *ngIf="showRemove" class="fa fa-minus-circle" aria-hidden="true" (click)="removeItem(item)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
