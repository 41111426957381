import React from 'react';
import { ChatBubble } from '../chat-bubble/ChatBubble';
import { ChatStamp } from '../chat-stamp/ChatStamp';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { InlineDialog } from '@relaynetwork/design-system';

const __variant = ['user', 'time', 'user-time'] as const;
export type Variant = typeof __variant[number];

export enum Role {
  Agent = 'Agent',
  Customer = 'Customer',
}

export interface ChatItemProps {
  stampVariant: Variant;
  user: string;
  timestamp: string;
  role?: Role;
  text?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  error?: string;
}

export const ChatItem = ({
  stampVariant,
  user,
  timestamp,
  role,
  text,
  style,
  error,
}: ChatItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  return (
    <div>
      <ChatBubble role={role} text={text}></ChatBubble>
      <Block
        overrides={{
          Block: {
            style: () => ({
              paddingLeft: customTheme.sizing.notificationChatItemPadding,
              marginTop: customTheme.sizing.notificationChatItemMargin,
            }),
          },
        }}
      >
        {error && error.length > 0 ? (
          <InlineDialog
            variant="alert"
            text={error}
            status="error"
            textSize="small"
          ></InlineDialog>
        ) : (
          <ChatStamp
            user={user}
            timestamp={timestamp}
            variant={stampVariant}
            style={style}
          ></ChatStamp>
        )}
      </Block>
    </div>
  );
};
