import React from 'react';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import {
  ButtonCancelOverride,
  ButtonOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '@app/api-mgmt/components/push-api/styles/AddConfigurationButtonStyles';

export const DeleteModal = ({
  name,
  onCloseClicked,
  onDeleteClicked,
}: {
  name: string,
  onCloseClicked: () => void
  onDeleteClicked: () => void
}) => {
  return (
    <>
      <Modal
        isOpen
        onClose={onCloseClicked}>
        <ModalHeader style={ModalHeaderOverride.style}>
          Confirm Integration Deletion
        </ModalHeader>
        <ModalBody style={{}}>
          Are you sure you want to permanently delete the{' '}
          <b>{name}</b> integration?
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind="tertiary"
            onClick={onCloseClicked}
            overrides={{ BaseButton: ButtonCancelOverride }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={onDeleteClicked}
            overrides={{ BaseButton: ButtonOverride }}
          >
            <span>DELETE</span>
            <ButtonIconRight>
              <i className="fa fa-trash"></i>
            </ButtonIconRight>
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
