import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'app-client-config-report',
  templateUrl: './client-config-report.component.html',
  styleUrls: ['./client-config-report.component.scss']
})
export class ClientConfigReportComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  clientId: string;
  client: Client = new Client();
  screen = "feed";
  productGroups = new Array<ProductGroup>();
  currentProductGroup: ProductGroup = null;
  readonly limit = 100;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  offset = 0;

  constructor(
    private titleService: TitleService,
    private clientService: ClientService,
    private activeRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
      this.titleService.activate("Configuration report");
      this.clientId = this.activeRoute.snapshot.params['clientId'];

      this.clientService.getClient(this.clientId).subscribe(
        client => {
          this.client = client;
        },
        error => {
          this.messageDialog.showMessage('Oops...could not load client data.');
        }
      );

      this.getProductGroups();
  }

  getProductGroups() {
    this.clientService.getProductGroupsByClient(this.clientId, this.limit, this.offset).subscribe(
        productGroups => {
          this.productGroups = this.productGroups.concat(productGroups);
        },
        error => {
          this.messageDialog.showMessage('Oops...could not load client product data.');
        }
    );
  }

  onScrollDown() {
      this.offset += this.limit;
      this.getProductGroups();
  }

  navigateToReport(product: ProductGroup) {
    this.router.navigateByUrl(`/client/${this.clientId}/product-group/${product.id}/report`);
  }
}
