import { NgModule } from '@angular/core';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColumnSortIconComponent } from './column-sort-icon/column-sort-icon.component';
import { EmbedYoutubeUrlPipe } from './pipes/embed-youtube-url.pipe';
import { LoadingMaskComponent } from './loading-mask/loading-mask.component';
import { MentionInputDirective } from './mention-input/mention-input.directive';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { NetworkComponent } from './phone/feed-preview/network.component';
import { PhoneComponent } from './phone/phone.component';
import { SafeHtmlPipePipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { UploadComponent } from './upload/upload.component';
import { ValidateOnblurDirective } from './validate-onblur.directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WirePreviewComponent } from './phone/wire-preview/wire-preview.component';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';
import { BlackoutSelectComponent } from './blackout-select/blackout-select.component';
import { BubbleComponent } from './bubble/bubble.component';
import { JourneyIconComponent } from './journey-icon/journey-icon.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { InputFieldPipe } from './pipes/input-field.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { GenericPhoneComponent } from './generic-phone/generic-phone.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { LoginPreviewComponent } from '@app/shared/phone/sign-up-preview/sign-up-preview.component';
import { BannerPreviewComponent } from '@app/shared/phone/banner-preview/banner-preview.component';
import { QuickLaunchContentComponent } from '@app/shared/quick-launch-content/quick-launch-content.component';
import { TrimDirective } from './trim.directive';
import { PasswordPreviewComponent } from './phone/password-preview/password-preview.component';
import { TimeWindowSelectComponent } from './time-window-select/time-window-select.component';
import { TinyEditorComponent } from './tiny-editor/tiny-editor.component';
import { StepActionComponent } from '@app/shared/step-action/step-action.component';
import { VideoComponent } from '@app/shared/video/video.component';
import { WireHeaderComponent } from '@app/shared/wire-header/wire-header.component';
import { SearchInputComponent } from '@app/shared/lists/search-input/search-input.component';
import { TagFilterListComponent } from '@app/shared/lists/tag-filter-list/tag-filter-list.component';
import { TitleTextPipe } from '@app/shared/pipes/title-text.pipe';
import { StatusComponent } from '@app/shared/lists/status/status.component';
import { SelectComponent } from '@app/shared/select/select.component';
import { EditableListComponent } from '@app/shared/editable-list/editable-list.component';
import { SmsBuilderComponent } from '@app/shared/sms-builder/sms-builder.component';
import { CheckboxComponent } from '@app/shared/checkbox/checkbox.component';
import { ConvertLinkablePipe } from '@app/shared/pipes/convert-linkable.pipe';
import { BreadcrumbsComponent } from '@app/shared/breadcrumbs/breadcrumbs.component';
import { ProgressTabsComponent } from '@app/shared/progress-tabs/progress-tabs.component';
import { ScheduleLaunchDialogComponent } from '@app/shared/schedule-launch-dialog/schedule-launch-dialog.component';
import { OncePipe } from '@app/shared/pipes/once.pipe';
import { ClonePipe } from '@app/shared/pipes/clone.pipe';
import { InputInfoBoxComponent } from '@app/shared/input-info-box/input-info-box.component';
import { TextInputComponent } from '@app/shared/text-input/text-input.component';
import { MentionModule, MentionDirective } from 'angular-mentions';
import { DisableAutocompleteDirective } from './disable-autocomplete/disable-autocomplete.directive';
import { ClientHeaderComponent } from '@app/shared/client-header/client-header.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { PowerUpsPreviewComponent } from '@app/shared/power-ups-preview/power-ups-preview.component';
import { ConsentSelectComponent } from '@app/shared/consent-select/consent-select.component';
import { CopiedMessageComponent } from '@app/shared/copied-message/copied-message.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelpInfoPopupComponent } from './help-info-popup/help-info-popup.component';
import { WirePreviewReactContainerComponent } from './phone/wire-preview-react-container/wire-preview-react-container.component';
import { ProductGroupPreviewReactContainerComponent } from '@app/client-config/product-group/components/product-group-preview-react-container/product-group-preview-react-container.component';
import { SetPasswordPagePreviewReactContainerComponent } from '@app/client-config/product-group/components/set-password-page-preview-react-container/set-password-page-preview-react-container.component';
import { FormInputStateControlComponent } from './form-input-state-control/form-input-state-control.component';
import { LoginFeedPreviewReactContainerComponent } from '@app/shared/phone/sign-up-page-preview-react-container/sign-up-page-preview-react-container.component';
import { TimerPopupComponent } from '@app/src/app/shared/timer-popup/timer-popup.component';

@NgModule({
  imports: [
    ButtonsModule.forRoot(),
    ColorPickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ColorPickerModule,
    RouterModule,
    TooltipModule,
    MentionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTooltipModule,
  ],
  declarations: [
    ColorPickerComponent,
    ColumnSortIconComponent,
    ConsentSelectComponent,
    EllipsisPipe,
    EmbedYoutubeUrlPipe,
    LoadingMaskComponent,
    TimerPopupComponent,
    MentionInputDirective,
    MessageDialogComponent,
    NetworkComponent,
    PhoneComponent,
    SafeHtmlPipePipe,
    SafeStylePipe,
    SafeUrlPipe,
    BubbleComponent,
    SearchBarComponent,
    ValidateOnblurDirective,
    TabBarComponent,
    UploadComponent,
    ValidateOnblurDirective,
    TrimDirective,
    WirePreviewComponent,
    RadioBtnComponent,
    BlackoutSelectComponent,
    JourneyIconComponent,
    PhoneNumberPipe,
    InputFieldPipe,
    SpinnerComponent,
    GenericPhoneComponent,
    LoginPreviewComponent,
    LoginFeedPreviewReactContainerComponent,
    BannerPreviewComponent,
    QuickLaunchContentComponent,
    PasswordPreviewComponent,
    TimeWindowSelectComponent,
    TinyEditorComponent,
    StepActionComponent,
    VideoComponent,
    WireHeaderComponent,
    SearchInputComponent,
    TitleTextPipe,
    StatusComponent,
    SelectComponent,
    TagFilterListComponent,
    EditableListComponent,
    SmsBuilderComponent,
    CheckboxComponent,
    ConvertLinkablePipe,
    BreadcrumbsComponent,
    ProgressTabsComponent,
    ScheduleLaunchDialogComponent,
    OncePipe,
    ClonePipe,
    InputInfoBoxComponent,
    TextInputComponent,
    DisableAutocompleteDirective,
    ClientHeaderComponent,
    PowerUpsPreviewComponent,
    CopiedMessageComponent,
    HelpInfoPopupComponent,
    WirePreviewReactContainerComponent,
    ProductGroupPreviewReactContainerComponent,
    SetPasswordPagePreviewReactContainerComponent,
    FormInputStateControlComponent,
  ],
  exports: [
    BlackoutSelectComponent,
    ColorPickerComponent,
    ColumnSortIconComponent,
    ConsentSelectComponent,
    EllipsisPipe,
    EmbedYoutubeUrlPipe,
    InputFieldPipe,
    LoadingMaskComponent,
    TimerPopupComponent,
    MentionInputDirective,
    MessageDialogComponent,
    NetworkComponent,
    PhoneComponent,
    PhoneNumberPipe,
    RadioBtnComponent,
    SafeHtmlPipePipe,
    SafeStylePipe,
    SafeUrlPipe,
    SearchBarComponent,
    ValidateOnblurDirective,
    TrimDirective,
    TabBarComponent,
    UploadComponent,
    ValidateOnblurDirective,
    WirePreviewComponent,
    JourneyIconComponent,
    SpinnerComponent,
    GenericPhoneComponent,
    LoginPreviewComponent,
    LoginFeedPreviewReactContainerComponent,
    BannerPreviewComponent,
    QuickLaunchContentComponent,
    PasswordPreviewComponent,
    TimeWindowSelectComponent,
    TinyEditorComponent,
    StepActionComponent,
    VideoComponent,
    WireHeaderComponent,
    SearchInputComponent,
    TitleTextPipe,
    StatusComponent,
    SelectComponent,
    TagFilterListComponent,
    BubbleComponent,
    EditableListComponent,
    SmsBuilderComponent,
    CheckboxComponent,
    ConvertLinkablePipe,
    BreadcrumbsComponent,
    ProgressTabsComponent,
    ScheduleLaunchDialogComponent,
    OncePipe,
    ClonePipe,
    InputInfoBoxComponent,
    TextInputComponent,
    MentionDirective, // Still not totally sure why I need to export the MentionDirective here...  I'd assume (?) MentionModule does the declaration/export, but maybe it doesn't
    DisableAutocompleteDirective,
    ClientHeaderComponent,
    PowerUpsPreviewComponent,
    CopiedMessageComponent,
    MatTooltipModule,
    HelpInfoPopupComponent,
    ProductGroupPreviewReactContainerComponent,
    SetPasswordPagePreviewReactContainerComponent,
    FormInputStateControlComponent,
  ],
})
export class RelaySharedModule {}
