// Angular Core Imports
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

// Data Models
import { ProductGroup } from '../../core/models/client';
import { JourneyContent } from '../../core/models/journey';

// Services and Utils
import { ClientService } from '../../core/services/client.service';
import { ScrollConstants } from '../../core/utils/scroll-constants';
import { SessionService } from '../../security/session.service';
import { Ordering, Direction } from '../../core/utils/ordering';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-journey-metadata-product-select',
  templateUrl: './journey-metadata-product-select.component.html',
  styleUrls: ['./journey-metadata-product-select.component.scss']
})
export class JourneyMetadataProductSelectComponent implements OnInit {
  @Input() inputJourney: JourneyContent;
  @Input() inputControl: UntypedFormControl;

  @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  readonly limit = 100;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;

  productGroups = new Array<ProductGroup>();
  offset = 0;

  constructor(
      public sessionService: SessionService,
      private clientService: ClientService) { }

  ngOnInit() {
    this.getProductGroups();
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getProductGroups();
  }

  emitChangeEvent(event: string) {
    this.inputModelChange.emit(event);
  }

  getProductGroups() {
    const clientId = this.sessionService.currentUser.client.id;
    this.clientService.getProductGroupsByClient(clientId, this.limit, this.offset, new Ordering('data.name', Direction.Asc), this.offset != 0).subscribe(
        productGroups => {
            this.productGroups = this.productGroups.concat(productGroups);
        },
        error => {
            let message = 'Oops...could not get product groups.';
            const serverMessage = error['reason'];
            if (serverMessage) {
                message = `Oops...could not get product groups: ${serverMessage}.`;
            }
            this.messageDialog.showMessage(message);
        }
    );
  }

  getTooltipData(errors: Object) {
    if (errors['maxlength']) {
        return `This input has a maximun length of ${errors['maxlength'].requiredLength}.`;
    }
    if (errors['required']) {
        return `This field is required.`;
    }
    if (typeof (errors) == "string") {
        return errors;
    }
  }

  productGroupSelected(value: string) {
      if (value && value !== 'all') {
        const offset = this.productGroups.length > 1 ? 2 : 1;
        const productGroup = this.productGroups[+(value.split(':')[0]) - offset];
        this.inputJourney.product_group = this.inputControl.value;
        this.inputJourney.product_group_name = productGroup.name;
        this.inputJourney.product_group_internal_name = productGroup.internal_name;
    } if (value === 'all'){
        this.inputJourney.product_group_name = "All Product Groups";
        this.inputJourney.product_group_internal_name = "All Product Groups";
    }
  }


}
