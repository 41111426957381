<div class="content">
  <h1>Relay Network LLC Privacy Policy</h1>
  <p>
    Relay Network LLC (“Relay”), located at 201 King of Prussia Road, Suite 161,
    Radnor, Pennsylvania, 19087, USA, operates the Relay Network (“Relay
    Network”), a system that enables mobile phone users to manage their various
    business relationships efficiently, effectively and conveniently, by means
    of their mobile devices and online, with companies that are partners with
    Relay and with which those users have an ongoing account relationship as
    members, customers or employees. In this Privacy Policy (“Policy”), we
    sometimes refer to the communication and other services offered by Relay as
    the “Relay Services” or “Services.”
  </p>
  <p>
    For purposes of this Policy, we refer to the organizations that use the
    Relay Services to communicate with customers or members as “Relay Partners”
    or “Partners,” and the persons with whom organizations communicate or
    propose to communicate by means of the Relay Services as: (1) Users, who
    have agreed to receive communications from Partners using Relay Services
    (the “Partner Communications”); and (2) Prospective Users, who have business
    relationships with Relay Partners and are eligible for the Relay Services,
    but have not yet agreed to receive Partner Communications. This Policy
    sometimes refers to Users and Prospective Users collectively as
    “Individuals,” and sometimes refers to persons who are neither Users nor
    Prospective Users as “Persons.”
  </p>
  <p>
    This Policy explains how Relay collects, maintains, uses and discloses
    personally identifiable information (sometimes referred to herein as “PII”)
    concerning Persons who use the Relay Services or communicate with Relay and
    its Partners by means of Partner Communications, Relay’s website, text
    messaging, Relay’s Web- based communications platform (the “Wire”) or other
    media of communication. The Policy does not describe the privacy practices
    of any other person or entity.
  </p>
  <p>
    Relay’s practices concerning its collection, maintenance, use and disclosure
    of PII are set out exclusively in this Policy and in no other document. Any
    other privacy commitments by Relay in its contracts will not be materially
    less restrictive of Relay’s practices than this policy. This Policy might be
    changed from time to time. Your continued use of the Relay Services, or any
    decision to communicate with Relay by any means, constitutes your acceptance
    of the terms of this Policy, including any changes to the Policy.
  </p>
  <p>
    Relay acquires PII from our Partners and from our Users, Prospective Users
    and other Persons. This Policy describes Relay’s privacy practices
    concerning information obtained from all of these sources.
  </p>
  <h3><em>Information Obtained from Relay Partners</em></h3>
  <p>
    Our Partners provide information to Relay concerning Individuals who are
    those Partners’ customers or members. That information might include, but is
    not limited to, names, addresses, telephone numbers, and identification
    numbers pertaining to Users and Prospective Users of the Relay Services.
  </p>
  <p>
    Each Partner’s decision to provide PII to Relay is subject to the Partner’s
    privacy policy, to applicable laws to which the Partner may be subject and
    to the contracts Relay has with Relay Partners governing our communications
    with you. Individuals should consult Partners’ privacy policies for more
    information concerning those Partners’ privacy practices. Relay does not
    control and cannot be responsible for the privacy practices of its Partners.
  </p>
  <p>
    In some cases, Relay may share PII and other information furnished by
    Partners to vendors that assist with provision of Relay Services. We require
    Relay’s vendors to use PII only for the intended purpose, we prohibit those
    vendors from selling or otherwise disclosing such PII to third parties and
    those vendors may be bound by contractual terms similar to those with Relay
    Partners.
  </p>
  <h3>
    <em>Information Obtained from Users, Prospective Users and Others</em>
  </h3>
  <p>
    Relay initiates communications in various ways with Users of Relay Services,
    persons identified by Relay Partners as Prospective Users, and others. Those
    communications include invitations to participate in the Relay Services, and
    information sent to Users who participate in the Relay Services, by means of
    the Relay Wire or otherwise, or Persons who initiate communication with
    Relay or Relay Partners. The media by which Relay sends the Partner
    Communications or conveys information to Users, Prospective Users and other
    Persons include text messages and messages conveyed by means of the Wire.
  </p>
  <p>
    Individuals employ text messaging, electronic mail or submissions to the
    Wire, a Relay Website or a Relay Partner to provide information or make
    inquiries to Relay. As used in this Policy, the terms “Relay Website” or
    “Relay Websites” include the Relay Wire, the websites located at
    <a target="_blank" href="https://relaynetwork.com/">relaynetwork.com</a> and
    <a target="_blank" href="https://my.relayit.com/">my.relayit.com</a>, and
    any other website that is controlled and operated by Relay.
  </p>
  <p>
    Information that Relay acquires from Users, Prospective Users and other
    Persons may include the following:
  </p>
  <ul>
    <li>
      Mobile telephone numbers, carriers and other identifying information
      regarding devices used to facilitate the Services.
    </li>
    <li>
      Information voluntarily transmitted by Users, Prospective Users and other
      Persons to Relay and its Partners, using the communications channels
      furnished by Relay or other media.
    </li>
    <li>
      Names of companies and institutions with which Users and Prospective Users
      do business or have commercial relationships, and associated account
      information, voluntarily furnished to Relay by Users and Prospective Users
      so that Relay can facilitate the sending of service alerts and other
      communications from those businesses and institutions, as further
      described in Relay’s Terms of Service and User Agreement.
    </li>
    <li>
      Clickstream data, consisting of non-personal information transmitted from
      a computer or mobile device when interacting with a Relay Website.
      Clickstream data may include a device’s IP address, the type of Internet
      browser and operating system an Individual is employing, the pages and
      information accessed on a Relay Website, the time spent on a Relay
      Website, and the domain name of a Website from which an Individual linked
      to a Relay Website. Information of this kind permits a computer to
      establish a working connection to a Relay Website and navigate the website
      more effectively.
    </li>
  </ul>
  <p>
    Relay Websites also utilize cookies, which are small strings of digitized
    text that a website transmits to an Individual’s device, and that will send
    certain information to a Relay Website when connected to that website.
    Cookies are used to “remember” user names and passwords, so that you are not
    required to enter that information each time you visit a Relay Website. We
    also use cookies to understand which parts of Relay Websites are most
    popular, where Individuals are going on Relay service and how much time they
    spend there. Cookies are read only by the server that placed them, and are
    unable to execute any code or virus.
  </p>
  <p>
    If you do not wish to have cookies stored on your server, you may instruct
    your browser to block them, or warn you before a cookie is stored. (Please
    refer to your browser instructions or Help screen to learn more about these
    functions.) However, blocking or erasing cookies may prevent you from using
    some of the functionalities of the Relay Websites. Some uses of cookies in
    connection with a Relay Website may be under the control of third party
    entities provided by Relay Partners and Relay cannot be responsible for the
    use of cookies by those third parties that have no contractual relationship
    with Relay.
  </p>
  <p>
    In some cases, Relay may share PII obtained from Individuals to vendors if
    required to assist with provision of Relay service, including the processing
    and fulfillment of inquiries and service requests. Relay’s vendors are
    contractually required to use PII only for the intended purpose, and we
    prohibit those vendors from selling or otherwise disclosing such information
    to third parties. Relay cannot be responsible, however, for the privacy
    policies and practices of third party entity websites that have no
    contractual relationship with Relay but are linked to a Relay Website.
  </p>
  <h3><em>Relay’s Policies Applicable to Data from all Sources</em></h3>
  <p>
    Relay takes appropriate measures to protect the security and confidentiality
    of PII provided by its Partners and obtained from Individuals. Relay uses
    and discloses that personal information only for purposes related to the
    provision of Relay Services, and does not retain that information longer
    than is reasonably necessary to offer or furnish the Relay Services to the
    Individuals to whom the information pertains.
  </p>
  <p>
    You should be aware that the Internet, and all electronic information
    transmission and storage systems, are subject to unauthorized intrusion and
    compromise. No operator of a website or information system can guarantee
    that it will not be penetrated by hackers or suffer a security breach
    through technical or human failure. Relay will protect information as
    required by law and our Relay Partner agreements, but we cannot guarantee
    there will be no loss, corruption or unauthorized disclosure of personal
    information.
  </p>
  <p>
    If permitted by Relay’s contract with its Relay Partner, Relay compiles
    aggregate information into reports that Relay provides to Relay Partners and
    potential partners. Aggregate information is data derived from personal
    information submitted to a Relay website or collected from other sources
    that is not identifiable to individual persons. Relay may, for example,
    create a profile of its customer base according to zip codes and other
    factors. Relay’s reports based upon aggregate information contain no
    information that permits recipients of that information to identify or
    contact individual persons.
  </p>
  <p>
    Relay will disclose personal information concerning Users, Prospective Users
    and others when required by legal process, or as necessary to protect the
    rights and safety of Relay, its customers, or the public.
  </p>
  <p>
    Relay may transfer personal information to a purchaser or successor entity
    in connection with the sale of Relay Network LLC, a subsidiary or line of
    business associated with Relay Network LLC, or substantially all of the
    assets of Relay Network LLC or one of its subsidiaries, affiliates or lines
    of business subject to this Policy.
  </p>
  <p><strong>Note to Parents</strong></p>
  <p>
    Relay’s websites and services are not directed to children and do not
    knowingly collect personal information from children under the age of 13.
    When Relay acquires knowledge that a visitor to a Relay website is under the
    age of 13, Relay will take appropriate measures to avoid collection of
    personal information from that visitor or obtain verifiable parental
    consent.
  </p>
  <p><strong>European Union Notice</strong></p>
  <p>
    This Policy and the data collection, use and sharing practices of Relay are
    not directed at residents of European Union Member States.
  </p>
  <p>
    <strong
      >How can you ask questions about our Privacy Policy and access your
      personal information?</strong
    >
  </p>
  <p>
    If you have questions about this Privacy Policy, wish to access your
    personal information, or request that we not use your personal information
    for a particular purpose, please follow the instructions posted at
    <a target="_blank" href="https://my.relayit.com/terms-and-conditions"
      >https://my.relayit.com/terms-and-conditions</a
    >.
  </p>
  <p>This Privacy Policy was last modified on December 22, 2023.</p>
</div>
