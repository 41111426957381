import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { TwoWayMessagingAdditionalInfo } from '@app/two-way/relay-messenger/components/relay-messenger-additional-info/RelayMessengerAdditionalInfo';
import { useClientInfo } from '../../context/ClientInfoContext';
import {
  Client,
  Conversation,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';
import { AgentAvatar } from '../agent-avatar/AgentAvatar';
import {
  TwoWayChatCard,
  TwoWayChatMessage,
} from '../relay-messenger-card/RelayMessengerChatCard';
import { RelayMessengerConversations } from '../relay-messenger-conversations/RelayMessengerConversations';
import {
  AgentStatus,
  DecryptedMessages,
  TwoWayService,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { TwoWayMessagingCustomerInfo } from '../customer-info/RelayMessengerCustomerInfo';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import { useUserInfo } from '../../context/UserInfoContext';
import { useCustomerStatus } from '../../context/CustomerStatusContext';
import { CustomerStatusBadge } from '../customer-status-badge/CustomerStatusBadge';
import { useTwilioClient } from '../../context/TwilioClientContext';
import * as _ from 'lodash';
import { TwoWayChatInput } from '../relay-messenger-chat-input/RelayMessengerChatInput';
import {
  ParticipantConversation,
  ParticipantUpdatedEvent,
  TwoWayConversationClientEvents,
  TwoWayConversationMessageEvents,
} from '@app/two-way/twilio-conversation.types';
import { Badge } from '../badge/Badge';
import { UserService } from '../user-axios-service/user-axios-service';
import { Customer } from '@app/core/models/customer';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';
import { useNewlyAssignedConvSids } from '../../context/NewlyAssignedConvSidsContext';
import { RelayMessengerAgents } from '../relay-messenger-agents/RelayMessengerAgents';
import { useRefreshConversations } from '../../context/RefreshConversationsContext';
import { useFeatureFlags } from '../../context/FeatureFlagsContext';
import { RelayMessengerAgent } from '../agent-modal/AgentModal';
import { useClosedReasons } from '../../context/ClosedReasonsContext';

const itemProps: BlockProps = {
  height: 'scale1000',
  display: 'flex',
  backgroundColor: '#F5F6F6',
};

enum TwoWayTestId {
  MyConversations = 'my-conversations',
  ClosedConversations = 'closed-conversations',
  UnassignedConversations = 'unassigned-conversations',
}

export type TwoWayCustomer = {
  lastPing?: string; // datetime
  middle_name?: string;
  is_generic?: boolean;
  gender?: string;
} & Customer;

export const RelayMessengerHome = () => {
  const featureFlags = useFeatureFlags();
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();
  const [{ userId, userFullName, userRoleIds }, setUserInfo] = useUserInfo();
  const [agentJWT, setAgentJWT] = useState('');
  const [agentStatus, setAgentStatus] = useState<AgentStatus>('inactive');
  const { setRefreshConversations } = useRefreshConversations();
  const [isMyConversationsClicked, setIsMyConversationsClicked] =
    useState(true);
  const isMyConversationsClickedRef = useRef(isMyConversationsClicked);
  useEffect(() => {
    isMyConversationsClickedRef.current = isMyConversationsClicked;
  }, [isMyConversationsClicked]);
  const [IsMyUnassignedConversationClicked, setIsMyUnassignedConversation] =
    useState(false);
  const [IsMyClosedConversationsClicked, setIsMyClosedConversationsClicked] =
    useState(false);
  const [agentConversationsClicked, setAgentConversationsClicked] =
    useState(false);

  // Agents List
  const [selectedAgentInfo, setSelectedAgentInfo] =
    useState<RelayMessengerAgent>(null);
  const [selectedAgentId, setSelectedAgentId] = useState<string>(null);
  const [selectedAgentFullName, setSelectedAgentFullName] =
    useState<string>(null);

  const [closedConversations, setClosedConversations] = useState([]);
  const [allUnassignedChats, setAllUnassignedChats] = useState([]);
  const [allAssignedChats, setAllAssignedChats] = useState([]);
  const [allAgentsChats, setAllAgentsChats] = useState<
    ParticipantConversation[]
  >([]);
  const { twilioClient, setTwilioClient } = useTwilioClient();
  const [lastMessageLoaded, setLastMessageLoaded] = useState(false);
  const {
    customerInfo: { firstName, lastName, channelSid, customerId },
    setCustomerInfo,
  } = useCustomerInfo();
  const [customer, setCustomer] = useState<TwoWayCustomer | null>(null);
  const { customerStatus } = useCustomerStatus();
  const { setAssignedAgents, fetchAssignedAgents } = useAssignedAgents();
  const selectedChannelRef = useRef(channelSid);
  const [myConversationsActivity, setMyConversationsActivity] = useState(false);
  const { setNewlyAssignedConvSids } = useNewlyAssignedConvSids();
  const [isAgentJoined, setIsAgentJoined] = useState(false);
  const clientRef = useRef<Client>(null);
  const [isPingTriggered, setIsPingTriggered] = useState(false);
  const { fetchClosedReasons } = useClosedReasons();

  useEffect(() => {
    selectedChannelRef.current = channelSid;
  }, [channelSid]);

  const audioRef = useRef<HTMLAudioElement>(null);
  const playNotificationSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const newMessageNotifier = (
    message: Message,
    currentUser: string,
    selectedConversationSid: string,
  ) => {
    if (
      isMyConversationsClickedRef.current &&
      message.conversation.status === 'joined'
    ) {
      if (
        message.author !== currentUser &&
        message.conversation.sid !== selectedConversationSid
      ) {
        // case 1: Assigned conversations tab is selected, current conversation is not selected
        playNotificationSound();
      }
    } else if (message.conversation.status === 'joined') {
      // case 2: Unassigned or Closed conversations tab is selected and ConversationStatus is "joined"
      setMyConversationsActivity(true);
      playNotificationSound();
    }
  };

  // Clear selected agent state
  useEffect(() => {
    if (!agentConversationsClicked) {
      setSelectedAgentInfo(null);
      setSelectedAgentId(null);
      setSelectedAgentFullName(null);
    }
  }, [agentConversationsClicked]);

  const isCurrentUserAnAdmin = userRoleIds?.includes('two_way_admin');

  const newConversationNotifier = () => {
    // case 3: Unassigned or Closed conversations tab is selected
    // Should notify for new conversations only for assigned conversations tab
    if (!isMyConversationsClickedRef.current) {
      setMyConversationsActivity(true);
      playNotificationSound();
    }
  };

  const showCustomerOrAgentInfo: any =
    (isMyConversationsClicked &&
      allAssignedChats?.length &&
      firstName) /* check if customerInfo was cleared after closing the conversation */ ||
    (IsMyUnassignedConversationClicked &&
      allUnassignedChats?.length &&
      firstName) /* For Unassigned conversation tab customer information should be displayed based on the selected conversation only */ ||
    (IsMyClosedConversationsClicked &&
      closedConversations?.length &&
      firstName) ||
    (agentConversationsClicked && allAgentsChats?.length && firstName);
  const showCustomerStatus =
    isMyConversationsClicked || IsMyUnassignedConversationClicked;
  const customerFullName = `${firstName} ${lastName}`;
  const sendTyping = useCallback(async () => {
    if (twilioClient && channelSid) {
      const channel = await TwoWayService.getTwilioConversation(
        twilioClient,
        channelSid,
      );
      channel.typing();
    }
  }, [twilioClient, channelSid]);
  const [message, setMessage] = useState<TwoWayChatMessage | any>([]);
  const [typingMessage, setTypingMessage] = useState('');

  let twoWayMessage: TwoWayChatMessage | any = [];

  const fetchToken = useCallback(async () => {
    const jwt = await TwoWayService.getToken(clientId);
    if (jwt) {
      setAgentJWT(jwt);
    }
  }, [clientId]);

  const sendAgentMessage = useCallback(
    async (message: string) => {
      const author = `agent::${clientId}::${userId}`;
      const channel = await TwoWayService.getTwilioConversation(
        clientRef.current,
        channelSid,
      );
      const displayName = await TwoWayService.getDisplayName(
        author,
        clientRef.current,
      );
      setMessage((val) => [
        ...val,
        {
          author: author,
          displayName: displayName,
          body: message,
          timestamp: new Date().toISOString(),
        },
      ]);
      const messageBody = {
        message: message,
        channelSid: channelSid,
      };
      const encryptMessage = await TwoWayService.sendMessageToEncrypt(
        clientId,
        messageBody,
      );
      await TwoWayService.setAgentStatus(clientId, userId, 'message');
      setAgentStatus('active');
      TwoWayService.sendChannelMessage(channel, encryptMessage.message);
    },
    [clientId, userId, channelSid, clientRef, message, twoWayMessage],
  );

  const fetchAgentStatus = useCallback(async () => {
    const agentStatus = await TwoWayService.getAgentStatus(clientId, userId);
    if (agentStatus) {
      setAgentStatus(agentStatus);
    }
  }, [clientId, userId]);

  useEffect(() => {
    fetchToken();
    fetchAgentStatus();
  }, [clientId, userId]);

  const loadConversation = useCallback(async () => {
    try {
      if (clientId && channelSid && twilioClient) {
        setMessage([]); // setting the messages array to be empty between different conversation clicks and then fetch the current conversation's messages array. The idea is to avoid any scenario where the messages array from a different conversation is shown on the new conversation click.
        setLastMessageLoaded(false); // To load skeleton loader until last message is received or until setLastMessages(true)
        const channel = await TwoWayService.getTwilioConversation(
          twilioClient,
          channelSid,
        );
        let pageSize = 30;
        let anchor = 0;
        twoWayMessage = [];
        getConversationMessage(channel, pageSize, anchor);
      }
    } catch (error) {
      setLastMessageLoaded(true); // setting setLastMessageLoaded value to true so the skeleton loader doesn't go into a continuous loop for current issue to fetch messages, which is failing for specific participants. To be removed once Twilio fixes the permissions issue.
    }
  }, [clientId, channelSid, twilioClient]);

  const getConversationMessage = async (
    channel: Conversation,
    pageSize: number,
    anchor: number,
  ) => {
    try {
      const messages = await TwoWayService.getMessages(
        channel,
        pageSize,
        anchor,
        'forward',
      );
      await addConversationMessages(messages);
      if (messages.hasNextPage) {
        getConversationMessage(channel, pageSize, anchor + pageSize);
      } else {
        setLastMessageLoaded(true);
        if (isMyConversationsClicked) {
          TwoWayService.setAllMessagesConsumed(channel);
        }
      }
    } catch (error) {
      console.error('Error getting messages', error);
      setLastMessageLoaded(true); // to be removed once twilio resolves permissions issue.
    }
  };

  const getDecryptedText = (
    decryptedArr: DecryptedMessages[],
    encryptedText: string,
  ) => {
    const { text } = decryptedArr.find((mess) => mess.cypher === encryptedText);
    return text;
  };

  const messageConversationAddedHandler = useCallback(
    async (message: Message) => {
      const currentUser = `agent::${clientId}::${userId}`;
      const selectedChannelSid = selectedChannelRef.current;
      newMessageNotifier(message, currentUser, selectedChannelSid);

      if (
        selectedChannelRef.current &&
        message.conversation.sid === selectedChannelRef.current &&
        isMyConversationsClickedRef.current
      ) {
        await TwoWayService.setAllMessagesConsumed(message.conversation);
      }
      if (
        selectedChannelRef.current &&
        selectedChannelRef.current === message.conversation.sid &&
        message.author !== currentUser &&
        clientRef.current
      ) {
        const encryptedMessage = [];
        encryptedMessage.push({
          cypher: message.body,
          ...(message.attributes && {
            version: message.attributes['v3'] ? 'v3' : 'v2',
          }),
        });
        const decryptedData = await TwoWayService.decryptMessage(
          encryptedMessage,
          clientId,
          selectedChannelSid,
        );
        const displayName = await TwoWayService.getDisplayName(
          message.author,
          clientRef.current,
        );
        let messageObj = {};
        if (_.get(message, 'attributes.v3.encrypted') && decryptedData) {
          messageObj = {
            author: message.author,
            displayName: displayName,
            body: getDecryptedText(
              decryptedData?.decryptedMessages,
              message.body,
            ),
            timestamp: (message.dateUpdated || '').toString(),
          };
        } else if (_.get(message, 'attributes.v2.encrypted') && decryptedData) {
          messageObj = {
            author: message.author,
            displayName: displayName,
            body: getDecryptedText(
              decryptedData?.decryptedMessages,
              message.body,
            ),
            timestamp: (message.dateUpdated || '').toString(),
          };
        } else {
          messageObj = {
            author: message.author,
            displayName: displayName,
            body: message.body,
            timestamp: message.dateUpdated,
          };
        }
        setMessage((val) => [...val, messageObj]);
      }
    },
    [clientRef, clientId, selectedChannelRef, isMyConversationsClickedRef],
  );

  const addConversationMessages = async (messages: Paginator<Message>) => {
    try {
      let decryptedArrayMessages: any;
      const encryptedTextMessagesArr = messages.items
        .filter(
          (message) => message.attributes['v3'] || message.attributes['v2'],
        )
        .map((message) => ({
          cypher: message.body,
          ...(message.attributes && {
            version: message.attributes['v3'] ? 'v3' : 'v2',
          }),
        }));
      let data = await TwoWayService.decryptMessage(
        encryptedTextMessagesArr,
        clientId,
        channelSid,
      );
      decryptedArrayMessages = data?.decryptedMessages;
      for (let msg of messages.items) {
        const displayName = await TwoWayService.getDisplayName(
          msg.author,
          clientRef.current,
        );
        if (
          _.get(msg, 'attributes.v3.encrypted') ||
          _.get(msg, 'attributes.v2.encrypted')
        ) {
          twoWayMessage.push({
            author: msg.author,
            displayName: displayName,
            body: getDecryptedText(decryptedArrayMessages, msg.body),
            timestamp: (msg.dateUpdated || '').toString(),
          });
        } else {
          twoWayMessage.push({
            author: msg.author,
            displayName: displayName,
            body: msg.body,
            timestamp: (msg.dateUpdated || '').toString(),
          });
        }
        setMessage(twoWayMessage);
      }
    } catch (error) {
      console.error('Error adding message', error);
    }
  };

  useEffect(() => {
    if (clientId && channelSid) {
      loadConversation();
    }
  }, [clientId, channelSid, twilioClient]);

  const channelConversationEventRouter = (event: any) => {
    switch (event.event_type) {
      case TwoWayConversationMessageEvents.messageAdded:
        messageConversationAddedHandler(event.event);
        return;
      case TwoWayConversationMessageEvents.typingStarted:
        typingConversationStartedHandler(event.event);
        return;
      case TwoWayConversationMessageEvents.typingEnded:
        typingConversationEndedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationRemoved:
        if (
          (event &&
            event.event &&
            event.event.sid &&
            selectedChannelRef.current &&
            event.event.sid === selectedChannelRef.current) ||
          !event
        ) {
          selectedChannelRef.current = null;
        }
        return;
      case TwoWayConversationClientEvents.conversationLeft:
        if (
          event &&
          event.event &&
          event.event.sid &&
          selectedChannelRef.current &&
          event.event.sid === selectedChannelRef.current
        ) {
          setCustomerInfo({});
          setConversation();
        }
        if (
          (event &&
            event.event &&
            event.event.sid &&
            selectedChannelRef.current &&
            event.event.sid === selectedChannelRef.current) ||
          !event
        ) {
          selectedChannelRef.current = null;
        }
        setRefreshConversations(true); // refresh conversations list
        return;
      case TwoWayConversationClientEvents.conversationJoined:
        conversationJoinedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationUpdated:
        conversationUpdatedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.participantJoined:
        participantJoinedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.participantUpdated:
        participantUpdatedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.participantLeft:
        participantLeftHandler(event.event);
        return;
    }
  };

  const participantUpdatedHandler = useCallback(
    async (event: ParticipantUpdatedEvent) => {
      const { participant, updateReasons } = event;
      if (
        clientRef.current &&
        participant.conversation.sid === selectedChannelRef.current
      ) {
        if (updateReasons.indexOf('lastReadMessageIndex') > -1) {
          TwoWayService.setAllMessagesConsumed(participant.conversation);
        }
      }
    },
    [selectedChannelRef, clientRef],
  );

  /**
   * Listen for updates to agents state:
   * - agent joining conversation -> should open that conversation in an assigned tab -> consume all messages
   */
  const participantJoinedHandler = useCallback(
    async (participant: Participant) => {
      if (
        clientRef.current &&
        participant.conversation.sid === selectedChannelRef.current
      ) {
        fetchAssignedAgents(clientRef.current, participant.conversation.sid);
        setIsAgentJoined(true);
        TwoWayService.setAllMessagesConsumed(participant.conversation);
      }
    },
    [selectedChannelRef, clientRef, isAgentJoined],
  );

  /**
   * Listen for updates to agents state:
   * - agent leaving conversation
   */
  const participantLeftHandler = useCallback(
    async (participant: Participant) => {
      if (
        clientRef.current &&
        participant.conversation.sid === selectedChannelRef.current
      ) {
        fetchAssignedAgents(clientRef.current, participant.conversation.sid);
      }
    },
    [selectedChannelRef, clientRef],
  );

  /**
   *  Listen for updates to agents state:
   * - agent status change
   */
  const conversationUpdatedHandler = useCallback(
    ({ conversation, updateReasons }) => {
      if (
        selectedChannelRef.current &&
        conversation.sid === selectedChannelRef.current
      ) {
        if (
          updateReasons.includes('dateUpdated') ||
          updateReasons.includes('lastMessage')
        ) {
          fetchAssignedAgents(clientRef.current, selectedChannelRef.current);
        }
      }
    },
    [selectedChannelRef, clientRef],
  );

  /**
   * Keep track of newly assigned conversations agents
   */
  const conversationJoinedHandler = useCallback(
    async (conversation: Conversation) => {
      if (conversation.sid) {
        let currentAgentJoined = false;
        const participants = await conversation.getParticipants();
        if (!participants) return;
        const currentUser = participants.find(
          (participant) =>
            participant.identity === `agent::${clientId}::${userId}`,
        );
        if (
          clientRef.current &&
          conversation.sid === selectedChannelRef.current
        ) {
          // exclude agent that just joined conversation
          currentAgentJoined = true;
        }

        const attributes = conversation.attributes;
        if (participants.length >= 2) {
          const initiatedBy = attributes['initiatedBy'];
          const lastReadTimestamp = currentUser.lastReadTimestamp;
          if (
            currentUser &&
            lastReadTimestamp === null &&
            initiatedBy &&
            initiatedBy !== userId &&
            !currentAgentJoined
          ) {
            setRefreshConversations(true); // update conversations list
            newConversationNotifier();
            setNewlyAssignedConvSids((val) => [...val, conversation.sid]);
          }
        }
      }
    },
    [clientRef.current, userId, selectedChannelRef.current],
  );

  const typingConversationStartedHandler = useCallback(
    async (event: Participant) => {
      if (event.conversation.sid === selectedChannelRef.current) {
        const displayName = await TwoWayService.getDisplayName(
          event.identity,
          clientRef.current,
        );
        setTypingMessage(`${displayName} is typing...`);
      }
    },
    [selectedChannelRef, clientRef],
  );

  const typingConversationEndedHandler = (event: Participant) => {
    if (event.conversation.sid === selectedChannelRef.current) {
      setTypingMessage(null);
    }
  };

  useEffect(() => {
    let clientObj: Client | null = null;
    if (agentJWT.length) {
      clientObj = new Client(agentJWT);
      clientObj.on('stateChanged', (state) => {
        if (state === 'initialized') {
          setTwilioClient(clientObj);
          clientRef.current = clientObj;
          TwoWayService.listenToClientMessages(clientObj, (callback) => {
            channelConversationEventRouter(callback);
          });
        } else if (state === 'failed') {
          console.error('Failed to initialize Twilio client');
        }
      });
    }
    return () => {
      clientObj?.removeAllListeners();
    };
  }, [agentJWT]);

  const setConversation = (channelSid?: string, isAgent?: boolean) => {
    setMyConversationsActivity(false); // clear notification activity
    if (isAgent) {
      setIsAgentJoined(true);
      setIsMyConversationsClicked(false);
    } else {
      setIsMyConversationsClicked(true);
    }
    setIsMyUnassignedConversation(false);
    setIsMyClosedConversationsClicked(false);
    if (channelSid) {
      // join conversation case
      selectedChannelRef.current = channelSid;
      loadConversation();
    }
  };

  const assignedConversationsCount = (
    <span>
      {myConversationsActivity ? (
        <Badge color="negative">{allAssignedChats?.length ?? 0}</Badge>
      ) : (
        `(${allAssignedChats?.length ?? 0})`
      )}
    </span>
  );

  const getUserInfo = useCallback(async () => {
    // UserService calls a cdm endpoint that returns a Customer object that doesn't have all the
    // information that's available.
    if (clientId != null && customerId != null) {
      try {
        const { ccid: customerCcid } = await UserService.getCustomerDetails(
          clientId,
          customerId,
        );
        if (customerCcid != null) {
          // So we have to call this endpoint to get the rest of it.
          const twoWayCustomer = await UserService.getCustomerDetailsByCcid(
            clientId,
            customerCcid,
          );
          setCustomer(twoWayCustomer);
        }
      } catch (error) {
        console.error(`Failed to get customer details: ${error}`);
      }
    }
  }, [clientId, customerId]);

  useEffect(() => {
    getUserInfo();
  }, [clientId, customerId]);

  // add full name to the user info
  const getCurrentUser = useCallback(async () => {
    const identity = `agent::${clientId}::${userId}`;
    try {
      const userInfo = await TwoWayService.getUserInfo(twilioClient, identity);
      setUserInfo({ ...userInfo, userRoleIds }); // don't miss to add userRoleIds, user identity doesn't have this information
    } catch (error) {
      console.error(`Failed to get current user: ${error}`);
    }
  }, [twilioClient, clientId, userId]);

  useEffect(() => {
    if (twilioClient && clientId && userId) {
      getCurrentUser();
    }
  }, [twilioClient, clientId, userId]);

  const loadAgentConversations = async (agentId) => {
    try {
      if (agentId && clientId) {
        const conversations: ParticipantConversation[] =
          await TwoWayService.loadAgentConversation(agentId, clientId);
        setAllAgentsChats(conversations);
      }
    } catch (error) {
      console.error('Error loading agent conversations', error);
    }
  };

  return (
    <>
      <FlexGrid
        flexGridColumnCount={4}
        overrides={{
          Block: {
            style: () => ({
              height: 'calc(100vh - 61px)',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#F5F6F6',
              width: 'calc(100vw - 198px)',
              position: 'fixed',
              bottom: 0,
              right: 0,
              paddingTop: '10px',
              paddingBottom: '10px',
            }),
          },
        }}
        id="relay-messenger-home"
      >
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: `15%`,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }),
            },
          }}
        >
          <div
            className={css({
              marginTop: '20px',
            })}
          >
            <AgentAvatar
              name={userFullName ? userFullName : 'Loading..'}
              status={agentStatus}
            ></AgentAvatar>
          </div>
          <hr
            className={css({
              width: '200px',
              top: '93px',
            })}
          ></hr>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: '20px',
            })}
          >
            <div
              className={css({
                display: 'flex',
              })}
              data-testid={TwoWayTestId.MyConversations}
              onClick={() => {
                setCustomerInfo({});
                setConversation();
                setAgentConversationsClicked(false);
              }}
            >
              <p
                className={css({
                  marginRight: '10px',
                  fontWeight: isMyConversationsClicked ? `700` : `400`,
                })}
              >
                My Conversations
              </p>
              {assignedConversationsCount}
            </div>
            <div
              data-testid={TwoWayTestId.UnassignedConversations}
              className={css({
                display: 'flex',
              })}
              onClick={() => {
                setCustomerInfo({});
                setIsMyUnassignedConversation(true);
                setIsMyConversationsClicked(false);
                setIsMyClosedConversationsClicked(false);
                setIsAgentJoined(false);
                setAgentConversationsClicked(false);
                setAssignedAgents([]);
              }}
            >
              <p
                className={css({
                  marginRight: '10px',
                  fontWeight: IsMyUnassignedConversationClicked ? `700` : `400`,
                })}
              >
                Unassigned
              </p>
              <span>{`(${allUnassignedChats?.length ?? 0})`}</span>
            </div>
            <div
              data-testid={TwoWayTestId.ClosedConversations}
              className={css({
                display: 'flex',
              })}
              onClick={() => {
                setCustomerInfo({});
                setIsMyClosedConversationsClicked(true);
                setIsMyUnassignedConversation(false);
                setIsMyConversationsClicked(false);
                setAgentConversationsClicked(false);
                setIsAgentJoined(false);
                // get closed reasons if enabled
                fetchClosedReasons(clientId);
              }}
            >
              <p
                className={css({
                  marginRight: '10px',
                  fontWeight: IsMyClosedConversationsClicked ? `700` : `400`,
                })}
              >
                Closed
              </p>
              <span>{`(${closedConversations?.length ?? 0})`}</span>
            </div>
            {isCurrentUserAnAdmin && (
              <RelayMessengerAgents
                agentConversationsClicked={agentConversationsClicked}
                onAgentSelected={(agent) => {
                  if (agent && agent.id) {
                    loadAgentConversations(agent.id);
                    setIsMyClosedConversationsClicked(false);
                    setIsMyUnassignedConversation(false);
                    setIsMyConversationsClicked(false);
                    setIsAgentJoined(false);
                    setAgentConversationsClicked(true);
                    setSelectedAgentId(agent.id);
                    setSelectedAgentInfo(agent);
                    setSelectedAgentFullName(agent.fullName);
                    setCustomerInfo({});
                  }
                }}
              />
            )}
          </div>
        </FlexGridItem>
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: `25%`,
                height: `100%`,
                backgroundColor: 'white',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }),
            },
          }}
        >
          <RelayMessengerConversations
            chatsArray={{
              setAllAssignedChats,
              allAssignedChats,
              allUnassignedChats,
              setAllUnassignedChats,
              setClosedConversations,
              closedConversations,
              allAgentsChats,
            }}
            conversationsClicked={{
              isMyConversationsClicked,
              IsMyUnassignedConversationClicked,
              IsMyClosedConversationsClicked,
              agentConversationsClicked,
            }}
            isAgentJoined={isAgentJoined}
            selectedAgentInfo={selectedAgentInfo}
          />
        </FlexGridItem>
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: `38%`,
                height: `100%`,
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '116px',
                position: 'relative',
                backgroundColor: 'white',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                marginLeft: '5px',
              }),
            },
          }}
        >
          {typeof showCustomerOrAgentInfo === 'string' &&
            showCustomerOrAgentInfo.length > 0 && (
              <>
                <TwoWayMessagingCustomerInfo
                  conversationsClicked={{
                    isMyConversationsClicked,
                    IsMyUnassignedConversationClicked,
                    IsMyClosedConversationsClicked,
                  }}
                  setIsMyConversationsClicked={setIsMyConversationsClicked}
                  setAgentStatus={setAgentStatus}
                  setIsPingTriggered={setIsPingTriggered}
                  customerCcid={customer?.ccid}
                />
                {showCustomerStatus && (
                  <CustomerStatusBadge
                    content={`${customerFullName} is ${customerStatus}`}
                    variant={
                      customerStatus === 'online' ? 'primary' : 'secondary'
                    }
                  ></CustomerStatusBadge>
                )}
                {/* temporary removed
            <UserStatusBanner
              content="Agent Joined"
              variant="secondary"
            ></UserStatusBanner> */}
                <TwoWayChatCard
                  chatMessages={message}
                  conversationsClicked={{
                    isMyConversationsClicked,
                    IsMyUnassignedConversationClicked,
                    IsMyClosedConversationsClicked,
                    agentConversationsClicked,
                  }}
                  typingMessage={typingMessage}
                  lastMessageLoaded={lastMessageLoaded}
                ></TwoWayChatCard>
                {isMyConversationsClicked && (
                  <TwoWayChatInput
                    onTyping={sendTyping}
                    onNewMessage={sendAgentMessage}
                  ></TwoWayChatInput>
                )}
              </>
            )}
        </FlexGridItem>
        <FlexGridItem
          {...itemProps}
          overrides={{
            Block: {
              style: () => ({
                width: `20%`,
                height: '100%',
                justifyContent: 'center',
                overflowY: 'auto',
              }),
            },
          }}
        >
          <TwoWayMessagingAdditionalInfo
            conversationsClicked={{
              isMyConversationsClicked,
              IsMyUnassignedConversationClicked,
              IsMyClosedConversationsClicked,
              agentConversationsClicked,
            }}
            setIsUnassignedConversationsClicked={setIsMyUnassignedConversation}
            setIsMyConversationsClicked={setIsMyConversationsClicked}
            setConversationClicked={setConversation}
            setAgentConversationsClicked={setAgentConversationsClicked}
            setIsMyClosedConversationsClicked={
              setIsMyClosedConversationsClicked
            }
            setIsAgentJoined={setIsAgentJoined}
            setIsMyUnassignedConversation={setIsMyUnassignedConversation}
            loadAgentConversations={loadAgentConversations}
            status={agentStatus}
            setAgentStatus={setAgentStatus}
            customer={customer}
            setCustomer={setCustomer}
            allAssignedChats={allAssignedChats}
            allUnassignedChats={allUnassignedChats}
            closedConversations={closedConversations}
            isPingTriggered={isPingTriggered}
            showCustomerOrAgentInfo={
              typeof showCustomerOrAgentInfo === 'string' &&
              showCustomerOrAgentInfo.length > 0 &&
              showCustomerOrAgentInfo
            }
            allAgentsChats={allAgentsChats}
            selectedAgentId={selectedAgentId}
            selectedAgentFullName={selectedAgentFullName}
          ></TwoWayMessagingAdditionalInfo>
        </FlexGridItem>
      </FlexGrid>
      <audio ref={audioRef}>
        <source src="/assets/audio/ping-bing.wav" type="audio/wav" />
        <source src="/assets/audio/ping-bing.ogg" type="audio/ogg" />
      </audio>
    </>
  );
};
