import { Background } from '@app/core/utils/background';
import { SessionService } from '../session.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '@app/core/services/logger.service';
import { User } from '@app/core/models/user';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  email_address: string = '';
  password: string = '';
  now: Date;
  loginFormGroup: UntypedFormGroup;
  mfaFormGroup: UntypedFormGroup;
  loginInProgress = false;
  error: string;
  requestInFlight: boolean;

  ssoCode: string;
  ssoScreen: boolean;
  ssoError: string;
  clientId: string;

  qrCode: string;
  isTotp: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    protected actionService: ActionService,
  ) {}

  ngOnInit() {
    this.loginFormGroup = this.formBuilder.group({
      email_address: [null, [Validators.required]],
      password: [null, Validators.required],
    });
    this.mfaFormGroup = this.formBuilder.group({
      mfaCode: ['', Validators.required],
    });
    Background.loginOn();
    this.reset();
    this.now = new Date();
    this.ssoCode = this.activatedRoute.snapshot.queryParams['code'];
    this.clientId = this.activatedRoute.snapshot.params['clientId'];
    if (this.ssoCode) {
      this.ssoLogin();
    }
  }

  ngOnDestroy() {
    Background.loginOff();
  }

  get mfaCodeFormControl(): UntypedFormControl {
    return <UntypedFormControl>this.mfaFormGroup.controls['mfaCode'];
  }
  attemptVerify() {
    this.mfaFormGroup.markAllAsTouched();
    if (this.mfaFormGroup.valid && !this.requestInFlight) {
      this.requestInFlight = true;

      this.sessionService
        .verifyMFACode(this.mfaCodeFormControl.value)
        .subscribe({
          next: (resp) => {
            this.requestInFlight = false;
            this.router.navigateByUrl('/safe-redirect');
          },
          error: (err) => {
            this.requestInFlight = false;
            this.mfaCodeFormControl.setErrors({
              customErrorMessage: 'Incorrect code. Please try again.',
            });
          },
        });
    }
  }

  attemptLogin() {
    const controls = this.loginFormGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        controls[control].markAsTouched();
      }
    }

    if (this.loginFormGroup.valid && !this.loginInProgress) {
      this.requestInFlight = true;
      this.error = undefined;
      this.loginInProgress = true;
      this.sessionService
        .login(
          this.loginFormGroup.controls['email_address'].value,
          this.loginFormGroup.controls['password'].value,
        )
        .subscribe(
          (loginResp) => {
            this.loginInProgress = false;
            this.requestInFlight = false;
            if (loginResp instanceof User) {
              this.router.navigateByUrl('/safe-redirect');
            } else {
              this.qrCode = loginResp['qr'] ?? '';
              this.isTotp = loginResp['totp'] ?? false;
            }
          },
          (err) => {
            this.parseError(err);
          },
        );
    }
  }

  checkForEnter(event) {
    if (event.keyCode === 13) {
      this.attemptLogin();
    }
  }

  private parseError(err: any) {
    this.loginInProgress = false;
    this.requestInFlight = false;
    const defaultError =
      'The email address and password were not correct. Try again.';
    if (err && err.error && err.error.reason) {
      this.error = err.error.reason;
    } else {
      this.error = defaultError;
    }
  }

  private reset(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  private ssoLogin(): void {
    this.ssoError = null;
    this.ssoScreen = true;

    this.sessionService.ssoLogin(this.ssoCode, this.clientId).subscribe({
      next: (user) => {
        this.ssoScreen = false;
        this.router.navigateByUrl('/safe-redirect');
      },
      error: (err) => {
        LoggerService.log('LoginComponent', err);
        this.ssoError = err?.error?.reason || 'there was an error';
      },
    });
  }
}
