import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  initialize,
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
} from 'launchdarkly-js-client-sdk';
import { LoggerService } from '@app/core/services/logger.service';
import { SessionService } from '@app/security/session.service';
import { EnvService } from '@app/core/services/env.service';
import { User } from '@app/core/models/user';

@Injectable()
export class FeatureService {
  private flags$: BehaviorSubject<LDFlagSet> = new BehaviorSubject(null);
  private ldClient: LDClient;
  private flagsPromise: Promise<LDFlagSet>; // promise is only needed for auth guard, which has to wait for a value before it can continue
  private logoutSub$: Subscription;

  // maps to EnvStrings from env.service
  private readonly envKeys = {
    dev: '5c2fb0a22b9d241e866a0eb1',
    'qa-b': '5c3ccc06c38c013665d11e95',
    qa: '5c3ccbcf543a71368e283757',
    staging: '5c3ccbeb5943ee368e885852',
    'sales-demo': '5c3ccc1ac38c013665d11e9b',
    demo: '5c3ccc1ac38c013665d11e9b',
    production: '5c2e5e9181619c1e9cb2de5f',
  };
  private readonly fakeUser = {
    clientId: undefined,
    client_id: undefined,
    created_at: undefined,
    updated_at: undefined,
    first_name: 'Test',
    selected: false,
    secondary_clients: [],
    last_name: 'User',
    password: undefined,
    role_id: undefined,
    id: undefined,
    email_address: undefined,
    last_login: undefined,
    mobile_number: undefined,
    permissions: undefined,
    client: undefined,
    abbreviatedName: undefined,
  };

  constructor(
    private envService: EnvService,
    private sessionService: SessionService,
  ) {}

  init(user: User): Promise<LDFlagSet> {
    if (!this.logoutSub$) {
      this.logoutSub$ = this.sessionService.loggedIn.subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          this.resetFlags();
        }
      });
    }

    if (!this.flagsPromise) {
      LoggerService.log('FeatureService ', 'initing feature service');
      return this.setUpFS(user);
    } else {
      LoggerService.log('FeatureService ', 'using existing features call');
      return this.flagsPromise;
    }
  }

  /**
   * expose the flags
   * @param {string} flag
   * @returns {boolean}
   */
  checkFlag(flag: string): boolean {
    // Uncomment for local dev - if you're running on the dev apis the dev LD key works, but for all other envs
    // it does not, because of CORS restrictions. hence we always return true, so all features are testable
    // if (EnvService.isLocal() && environment.environment !== 'dev') {
    //   return true;
    // }
    const flags = this.flags$.getValue();
    if (!flags || !flags[flag]) {
      return false;
    } else {
      return flags[flag];
    }
  }

  /**
   * set up the feature switch
   * initialize feature switch
   *
   * @param user
   */
  setUpFS(user: any): Promise<LDFlagSet> {
    this.flagsPromise = new Promise((resolve, reject) => {
      LoggerService.log('FeatureService', 'setting up flags');
      const env = this.envService.getEnv();
      const envKey = this.envKeys[env];
      LoggerService.log('FeatureService', `env flag set: ${envKey}`);
      if (!user) {
        return;
      }

      if (
        user.email_address &&
        (user.email_address.indexOf('mailinator') > -1 ||
          user.email_address.indexOf('team508266.m8r.co') > -1)
      ) {
        this.fakeUser.clientId = user.clientId;
        this.fakeUser.client_id = user.client_id;
        this.fakeUser.email_address = 'fake@team508266.m8r.co';
        user = User.deserialize(this.fakeUser);
      }

      user.key = user.email_address.toLowerCase();

      const userToSend = {
        kind: 'user',
        key: user.key,
        email: user.email_address,
        name: user.email_address,
      };

      try {
        this.ldClient = initialize(envKey, userToSend, {
          hash: user.ld,
        });
      } catch (e) {
        LoggerService.log(
          'FeatureService',
          `error fetching feature switch ${e.toString()}`,
        );
        reject(e);
      }

      this.ldClient.on('ready', () => {
        this.setFlags(this.ldClient.allFlags());
        resolve(this.flags$.getValue());
      });

      this.ldClient.on('change', (flags) => {
        this.changeFlags(flags);
      });
    });

    return this.flagsPromise;
  }

  getFlags() {
    return this.flags$;
  }

  private setFlags(flags: LDFlagSet): void {
    this.flags$.next(flags);
    LoggerService.log('Flags initialized.', flags);
  }

  private resetFlags() {
    this.flags$.next(null);
    this.flagsPromise = undefined;
  }

  private changeFlags(flags: LDFlagChangeset) {
    const temp = this.flags$.getValue();
    let newflags = {};
    for (const o in flags) {
      newflags[o] = flags[o].current;
    }
    const updatedFlags = { ...temp, ...newflags };
    this.flags$.next(updatedFlags);
    this.flagsPromise = Promise.resolve(updatedFlags);
  }
}
