<ng-container *ngIf="formReference">
  <div class="form-preview">
    <div *ngIf="hasFormId() && !formError && !formDeleted" class="form-preview__preview">
      <rn-power-ups-preview #powerUpPreview [formId]="formId" [width]="60"></rn-power-ups-preview>
    </div>
    <div class="form-preview__info">
      <p class="form-name" *ngIf="hasFormTitle() && !formError && !formDeleted">Name: {{ formTitle }}</p>
      <p *ngIf="formError || formDeleted" id="form-error-text">Form cannot be found. Please verify that it exists. If form has been deleted, please replace form or remove this action</p>
      <button class="btn btn-primary btn-preview" (click)="showPowerUpDisplayForm()" *ngIf="hasFormId() && !formError && !formDeleted">preview</button>
      <button class="btn btn-primary btn-replace" (click)="showPowerUpSelect()">{{replaceButtonText()}}</button>
    </div>
  </div>
  
  <message-dialog #powerUpsSelectDialog [showAffirm]="false" [showClose]="true" [wide]="true">
    <rn-power-ups-preview-list (newPowerUpSelected)="saveNewPowerUp($event)" [selectedPowerUpId]="formReference.controls['value'].value" (closeMsgDialog)="closeMessageDialog()"></rn-power-ups-preview-list>
  </message-dialog>
  
  <message-dialog *ngIf="hasFormId()" #powerUpsDisplayForm [showAffirm]="false" [showClose]="true">
    <!-- Using ng-template here, instead of ng-content, because we want the jotform-preview to instantiate 
         when the dialog opens, not on initial page load -->
    <ng-template>
      <div class="preview-dialog-content">
        <rn-power-ups-preview [formId]="formId"></rn-power-ups-preview>
      </div>
    </ng-template>
  </message-dialog>
</ng-container>
