import { NavbarStateService } from '@app/core/services/navbar-state.service';
import { Title, TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { Ordering } from '@app/core/utils/ordering';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  showUserProfile: boolean;
  loadingClientList: boolean;
  fetchedClients: boolean;
  clients: Client[];
  currentClient: Client;
  titleSub: Subscription;
  title: Title;
  searchTerm: string = '';

  constructor(private router: Router,
              public navbarStateService: NavbarStateService,
              private clientService: ClientService,
              private titleService: TitleService,
              private ref: ChangeDetectorRef,
              public sessionService: SessionService) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleSub = this.titleService.titleChange.subscribe((title: Title) => {
      this.title = title;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.titleSub.unsubscribe();
  }

  onButtonClick() {
    if (this.title.buttonHandler) {
      this.title.buttonHandler();
    }
  }

  switchClients(client: Client) {
    this.sessionService.setClient(client);
    this.currentClient = client;
    this.showUserProfile = false;

    // hack to ensure the components actually reload on client change.  not ideal.
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl(`safe-redirect?redirect=${location.pathname}`);
    });
  }

  toggleUserProfile(e) {
    this.showUserProfile = !this.showUserProfile;
    if (!this.fetchedClients) {
      this.loadingClientList = true;
      this.fetchedClients = true;
      const ordering = new Ordering('data.company_name');
      this.clientService.getAllClients(ordering).subscribe((clients) => {
        this.clients = clients;
        this.loadingClientList = false;
      });
    }
    e.stopPropagation();
  }

  hideUserProfile(event) {
    const userProfileMenuSearchInput: string = 'user-profile-menu-search-input';
    const userProfileMenuClearIcon: string = 'user-profile-menu-clear-icon';
    const target = event.target || event.srcElement || event.currentTarget;
    const elementId = target.attributes.id;
    const value = elementId?.nodeValue;

    if(value !== userProfileMenuSearchInput && value !== userProfileMenuClearIcon) {
      this.showUserProfile = false
      this.searchTerm = '';
    };
  }
  
  filteredClients() {
    if (!this.searchTerm) {
      return this.sessionService.allClients;
    }

    return this.sessionService.allClients.filter((client) => client.company_name && client.company_name.toLowerCase().includes(this.searchTerm.toLowerCase()));
  }

  clearSearchBar() {
    this.searchTerm = '';
  }

  get hasNoClientsFromQueryDisplayError(): boolean {
    const filteredClients = this.filteredClients();
    return !this.loadingClientList && filteredClients.length === 0;
  }
  
  get hasClientsDisplaySearchBar(): boolean {
    return !this.loadingClientList && this.clients.length > 1;
  }
}
