<div class="launch-result">

    <div class="launch-result__box">
      <div class="launch-result__hex-icon" [class.launch-result__hex-icon--plane]="false" [class.launch-result__hex-icon--clock]="true"></div>
        <h3>
          <ng-content select="[message]"></ng-content>
        </h3>
    </div>
    
    <br/>
    
    <div class="clearfix">
      <div *ngIf="leftButtonLabel" class="pull-left">
        <div class="btn btn-lg btn-primary" (click)="leftButtonClick.emit()" id="left-button">
          {{leftButtonLabel}}
        </div>
      </div>
      <div *ngIf="rightButtonLabel" class="pull-right">
        <div class="btn btn-lg btn-primary" (click)="rightButtonClick.emit()" id="right-button">
          {{rightButtonLabel}}
        </div>
      </div>
    </div>
  </div>
