import React, { useState, useEffect } from 'react';
import { useClientInfo } from '@app/client-config/client-form/client-power-ups-config/context/ClientInfoContext';
import {
  FormConfig,
  FormConfigRequest,
} from '@app/client-config/client-form/client-power-ups-config/types/form-config';
import { FormConfigService } from '@app/client-config/client-form/client-power-ups-config/services/form-config-service';
import {
  IntroLineContainer,
  IntroLineHeader,
  AddButtonContainer,
  InfoContainer,
  InfoText,
} from '@app/client-config/client-form/client-power-ups-config/styles/PowerUpsConfigHomeStyles';
import { Button } from 'baseui/button';
import { ButtonConfigurationOverride } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { useStyletron } from 'baseui';
import { ConfigurationsDisplay } from '@app/client-config/client-form/client-power-ups-config/components/configurations-display/ConfigurationsDisplay';
import { DeleteModal } from '@app/client-config/client-form/client-power-ups-config/components/configurations-display/DeleteModal';
import { AddConfigurationModal } from '../components/add-configuration-modal/AddConfigurationModal';
import { EditConfigurationModal } from '../components/edit-configuration-modal/EditConfigurationModal';

export const PowerUpsConfigHome = () => {
  const [configurations, setConfigurations] = useState<FormConfig[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] =
    React.useState(null);
  const [isAddNewOpen, setIsAddNewOpen] = React.useState(false);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [{ clientId }] = useClientInfo();
  const [css] = useStyletron();
  const formConfigService = new FormConfigService();

  async function updateConfigurationSet() {
    setConfigurations(await formConfigService.getFormConfigSet(clientId));
  }

  useEffect(() => {
    updateConfigurationSet();
  }, []);

  async function addConfiguration(config: FormConfigRequest) {
    await formConfigService.writeFormConfig(clientId, config);
    await updateConfigurationSet();
  }

  async function deleteConfiguration() {
    await formConfigService.deleteFormConfig(
      clientId,
      selectedConfiguration.uid,
    );
    await updateConfigurationSet();
  }

  async function updateConfiguration(config: FormConfigRequest, uid: string) {
    await formConfigService.setFormConfig(clientId, uid, config);
    await updateConfigurationSet();
  }

  function editConfigurationModal(config: FormConfig) {
    setSelectedConfiguration(config);
    setIsEditOpen(true);
  }

  function deleteConfigurationModal(config: FormConfig) {
    setSelectedConfiguration(config);
    setIsDeleteOpen(true);
  }

  return (
    <>
      <IntroLineContainer>
        <IntroLineHeader>Power-Ups Form Configurations</IntroLineHeader>

        {configurations.length === 0 && (
          <div className={css({ marginTop: '10px' })}>
            <InfoContainer>
              <InfoText>
                No Power-Ups form configurations currently exist.
              </InfoText>
            </InfoContainer>
            <Button
              overrides={{ BaseButton: ButtonConfigurationOverride }}
              onClick={() => setIsAddNewOpen(true)}
            >
              <div
                className={css({ paddingLeft: '6px', paddingRight: '6px' })}
              >
                <span>ADD CONFIGURATION</span>
              </div>
            </Button>
          </div>
        )}

        {configurations && configurations?.length > 0 && (
          <>
            <AddButtonContainer>
              <Button
                overrides={{ BaseButton: ButtonConfigurationOverride }}
                onClick={() => setIsAddNewOpen(true)}
              >
                <div
                  className={css({ paddingLeft: '6px', paddingRight: '6px' })}
                >
                  <span>ADD CONFIGURATION</span>
                </div>
              </Button>
            </AddButtonContainer>
            <ConfigurationsDisplay
              configurations={configurations}
              editConfigurationClicked={editConfigurationModal}
              deleteConfigurationClicked={deleteConfigurationModal}
            />
          </>
        )}

        {isAddNewOpen && (
          <AddConfigurationModal
            onSaveClicked={(newConfiguration) => {
              addConfiguration(newConfiguration);
              setIsAddNewOpen(false);
            }}
            onCancelClicked={() => setIsAddNewOpen(false)}
          />
        )}

        {isEditOpen && (
          <EditConfigurationModal
            configuration={selectedConfiguration}
            onConfigurationUpdate={(editedConfig, uid) => {
              updateConfiguration(editedConfig, uid);
              setIsEditOpen(false);
            }}
            onCancelClicked={() => setIsEditOpen(false)}
          />
        )}

        {isDeleteOpen && (
          <DeleteModal
            name={selectedConfiguration.external_id}
            onCloseClicked={() => setIsDeleteOpen(false)}
            onDeleteClicked={() => {
              deleteConfiguration();
              setIsDeleteOpen(false);
            }}
          />
        )}
      </IntroLineContainer>
    </>
  );
};
