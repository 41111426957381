import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelaySharedModule } from '@app/shared/shared.module';

// Services
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';

// Components
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SetupSummaryComponent } from './setup/components/setup-summary/setup-summary.component';
import { SetupContainerComponent } from '@app/two-way/setup/components/setup-container/setup-container.component';
import { SetupUsersComponent } from '@app/two-way/setup/components/setup-users/setup-users.component';
import { ControlTagsComponent } from '@app/two-way/control-tags/control-tags.component';
import { SetupSmsPingComponent } from '@app/two-way/setup/components/setup-sms-ping/setup-sms-ping.component';
import { SetupAutoResponseComponent } from '@app/two-way/setup/components/setup-auto-response/setup-auto-response.component';
import { MessagingComponent } from './messaging/messaging.component';
import { TwoWayPreviewComponent } from '@app/two-way/setup/components/two-way-preview/two-way-preview.component';
import { ControlTagDetailComponent } from '@app/two-way/control-groups/components/control-tag-detail/control-tag-detail.component';
import { NewControlTagFormComponent } from '@app/two-way/control-groups/components/new-control-tag-form/new-control-tag-form.component';
import { ChannelListItemComponent } from '@app/two-way/channel-list-item/channel-list-item.component';
import { ChannelListHeadingsComponent } from '@app/two-way/channel-list-headings/channel-list-headings.component';
import { RelayMessengerComponent } from '@app/two-way/relay-messenger/relay-messenger.component';

// External Components for setup/users list
import { UserModule } from '@app/user/user.module';
import { UserResetComponent } from '@app/user/user-reset/user-reset.component';
import { UserListComponent } from '@app/user/user-list/user-list.component';
import { UserEditComponent } from '@app/user/user-edit/user-edit.component';
import { MessageWindowComponent } from './message-window/message-window.component';
import { UnassignedComponent } from './unassigned/unassigned.component';
import { AssignedComponent } from './assigned/assigned.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AgentMessageComponent } from './agent-message/agent-message.component';
import { CustomerMessageComponent } from './customer-message/customer-message.component';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { LaunchSelectComponent } from './launch-select/launch-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { TwoWayDashboardComponent } from './dashboard/components/two-way-dashboard.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    RouterModule,
    ButtonsModule.forRoot(),
    TooltipModule,
    InfiniteScrollModule,
    UserModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  declarations: [
    ControlTagsComponent,
    SetupSummaryComponent,
    MessagingComponent,
    SetupContainerComponent,
    SetupUsersComponent,
    SetupSmsPingComponent,
    SetupAutoResponseComponent,
    TwoWayPreviewComponent,
    MessageWindowComponent,
    UnassignedComponent,
    AssignedComponent,
    CustomerDetailsComponent,
    AgentMessageComponent,
    CustomerMessageComponent,
    TagSelectComponent,
    LaunchSelectComponent,
    ControlTagDetailComponent,
    NewControlTagFormComponent,
    ChannelListItemComponent,
    ChannelListHeadingsComponent,
    RelayMessengerComponent,
    TwoWayDashboardComponent,
  ],
  providers: [
    TwoWaySetupService,
    MessageDialogComponent,
    UserResetComponent,
    UserListComponent,
    UserEditComponent,
  ],
  exports: [MatFormFieldModule, MatAutocompleteModule],
})
export class TwoWayModule {}
