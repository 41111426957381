import React from 'react';
import { useStyletron } from 'baseui';
import { Icon } from '@relaynetwork/design-system';

export type MessageCountProps = {
  count?: number;
  variant?: 'default' | 'highlighted';
};

export const MessageCount = ({
  count,
  variant = 'default',
}: MessageCountProps) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        borderRadius: '25px',
        color: variant === 'default' ? '#37474F' : '#000',
        backgroundColor: variant === 'default' ? '#B2CF52' : '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '30px',
      })}
    >
      <Icon name={'Chat'} size="small"></Icon>
      <p
        className={css({
          margin: '0 0 0 5px',
        })}
      >
        {count}
      </p>
    </div>
  );
};
