import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VideoUtils } from '@app/core/utils/video-utils';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { MediaAsset } from '@app/core/models/media';
import * as _ from 'lodash';

@Injectable()
export class VideoThumbnailService {

  constructor(private http: HttpClient) { }

  /**
   * THIS METHOD WILL MODIFY MEDIA ASSETS THAT ARE PASSED IN
   * It will loop through all assets, select video assets that 
   * do not have a value for the 'videoThumnbailUrl' key, and 
   * assigns a value to each.  
   * 
   * @param assets this is expected to be an array of object 
   * references which can safely be modified in-place
   */
  setVideoThumbnailUrls(assets: MediaAsset[] = []): void {
    _.filter(assets, (asset: MediaAsset) => {
      return asset.asset_type === 'video' && !asset.videoThumbnailUrl;
    }).map((videoAssetWithoutThumbnail: MediaAsset) => {
      this.getVideoThumbnailUrl(videoAssetWithoutThumbnail);
    });
  }

  /**
   * Calls the Vimeo api to get the small thumbnail for videoUrl
   * See Vimeo OEmbed Docs: https://developer.vimeo.com/api/oembed/videos
   * @param videoUrl 
   */
  private requestVimeoThumbnail(asset: MediaAsset): Observable<string> { 
    return this.http.get(`https://vimeo.com/api/oembed.json?url=${encodeURI(asset.url)}`, {}).pipe(
      map((response) => {
        return response['thumbnail_url'];
      }),
      retry(3)
    );
  }

  /**
   * THIS METHOD WILL MODIFY THE MEDIA ASSET THAT IS PASSED IN
   * - get/set `asset.videoThumbnailUrl` based on which video provider is being used
   * - if A request needs to be made to get the url, or if an invalid url is passed in, 
   *   assign `asset.videoThumbnailUrl` to a generic thumbnail image url.
   */
  private getVideoThumbnailUrl(asset: MediaAsset): void {
    if (asset.url.indexOf('vimeo') > -1) {
      // set the videoThumbnailUrl to something so it won't continue making requests on 
      // template repaints.  Also gives us a fallback if the request fails.
      asset.videoThumbnailUrl = `assets/generic-play-button.png`; 
      this.requestVimeoThumbnail(asset).subscribe((vimeoThumbnailUrl) => {
        asset.videoThumbnailUrl = vimeoThumbnailUrl;
      });
      return;
    }
    
    if (asset.url.indexOf('hapyak') > -1) {
      asset.videoThumbnailUrl = VideoUtils.hapYakLogoURL;
      return;
    }

    if (asset.url.indexOf('wistia') > -1) {
      asset.videoThumbnailUrl = VideoUtils.wistiaLogoURL;
      return;
    }
    
    const isYoutubeUrl = VideoUtils.isYoutubeUrl.exec(asset.url);
    if (isYoutubeUrl) {
      asset.videoThumbnailUrl = `https://img.youtube.com/vi/${isYoutubeUrl[1]}/default.jpg`;
      return;
    }

    asset.videoThumbnailUrl = `assets/generic-play-button.png`; 
  }
}
