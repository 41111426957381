<div class="rn-page power-ups-pl">
  <div class="power-ups-pl__heading">
    <h2 class="intro-line-1">Add a Power-Up</h2>
  </div>

  <div class="power-ups-pl__panels">
    <div class="power-ups-pl__panel power-ups-pl__panel--left">
      <div class="power-ups-pl__panel-heading-group">
        <div class="power-ups-pl__panel-label">Form List</div>
        <div class="power-ups-pl__panel-search-bar-wrapper">
          <div class="power-ups-pl__panel-search-bar">
            <input class="power-ups-pl__search-input" name="power-ups-search" type="text" placeholder="Search" [(ngModel)]="searchTerm">
            <button class="power-ups-pl__search-clear-icon" [hidden]="searchTerm === ''" (click)="clearSearchBar()">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <ul class="rn-list rn-list-ul-outer">
        <li class="rn-list__row rn-list__row--header"> 
          <div class="rn-list__col">
            <span class="rn-list__heading-1">
              Name
            </span>
          </div> 
        </li> 
        <li class="rn-list__body">

          <p class="power-ups-pl__message" *ngIf="!powerUps">There was an error fetching forms for your client</p>
          <p class="power-ups-pl__message" *ngIf="powerUps && powerUps.length === 0">There are no forms for this client</p>
          <p class="power-ups-pl__message" *ngIf="powerUps && powerUps.length > 0 && filteredPowerUps().length === 0">There are no forms matching your query</p>
          
          <ul id="power-ups-list" *ngIf="powerUps && filteredPowerUps().length > 0" class="rn-list"> 
            <ng-container *ngFor="let powerUp of filteredPowerUps()">  
              <li class="rn-list__row" [class.rn-list__row--selected]="selectedPowerUpId === powerUp.id" (click)="selectPowerUp(powerUp.id)"> 
                <div class="rn-list__col">{{powerUp.title}}</div> 
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
      
    </div>

    <div class="power-ups-pl__panel power-ups-pl__panel--right" *ngIf="selectedPowerUp">
      <div class="power-ups-pl__panel-heading-group">
        <h3 class="power-ups-pl__subheading" *ngIf="!!selectedPowerUp">Previewing {{selectedPowerUp.title}}</h3>
      </div>

      <div id="powerUpsPLiframeContainer" class="power-ups-pl__iframe-container">  
        <rn-power-ups-preview [formId]="selectedPowerUp.id"></rn-power-ups-preview>
      </div>
    </div>
  </div>
  <div class="power-ups-pl__btn-container">  
    <button id="power-ups-preview-list-cancel-btn" class="btn btn-secondary power-ups-pl__btn-container--cancel" type="button" (click)="cancelAndClose()">cancel</button>
    <button id="power-ups-preview-list-save-btn" class="btn btn-primary" type="button" (click)="saveAndClose()">select</button>
  </div>
</div>
