import React, { useEffect, useState } from 'react';
import { InputTitle } from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import { TextAreaOverride } from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { Textarea } from 'baseui/textarea';

export const TextAreaBox = ({
  title,
  value,
  placeholder,
  validate,
  onChange,
  hideText,
  isOptional,
  isSubmitted,
  preValidate,
}: {
  title?: string;
  value?: string;
  placeholder?: string;
  validate?: (value: string) => string;
  onChange?: (value: string) => void;
  hideText?: boolean;
  isOptional?: boolean;
  isSubmitted?: boolean;
  preValidate?: boolean;
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSubmitted) {
      setErrorMessage(validate(value));
    }
  });

  hideText = hideText ?? false;
  isOptional = isOptional ?? false;
  preValidate = preValidate ?? false;

  const updateField = (value: string) => {
    if (validate !== undefined) {
      setErrorMessage(validate(value));
    }
    onChange(value);
  };

  useEffect(() => {
    if (preValidate) {
      updateField(value);
    }
  }, []);

  return (
    <>
      <InputTitle>
        <label>{title}</label>
        {isOptional && <span style={{ fontSize: '16px' }}> (Optional)</span>}
      </InputTitle>
      <Textarea
        overrides={{
          Input:
            errorMessage === '' || isSubmitted
              ? TextAreaOverride
              : {
                  style: {
                    ...TextAreaOverride.style,
                  },
                },
        }}
        type={hideText ? 'password' : 'text'}
        value={value}
        onChange={(events) =>
          updateField((events.target as HTMLButtonElement).value)
        }
        placeholder={placeholder}
        onBlur={(events) =>
          updateField((events.target as unknown as HTMLButtonElement).value)
        }
      />
      {(errorMessage !== '' || isSubmitted) && (
        <>
          <i
            className="fa fa-exclamation-circle"
            style={{
              fontSize: '14px',
              marginRight: '5px',
              color: '#AD3B22',
              display: 'inline-block',
            }}
          ></i>
          <div
            style={{
              marginBottom: '10px',
              display: 'inline-block',
              fontStyle: 'italic',
              color: '#AD3B22',
            }}
          >
            {errorMessage}
          </div>
        </>
      )}
    </>
  );
};
