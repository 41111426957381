import { createTheme, styled } from 'baseui';

export const primitives = {
  primaryFontFamily: 'Source Sans Pro, helvetica, sans-serif',
  // slate
  accent: '#3693BF', // this is btn.primary color in our bootstrap css
  accent0: '#FFFFFF',
  accent1: '#CECECE',
  accent100: '#CCE2EB',
  accent200: '#99C5D7',
  accent300: '#66A9C2',
  accent400: '#338CAE',
  accent500: '#006F9A',
  accent600: '#3FA9DD',
  accent700: '#00435C',
  accent800: '#546e7a', // cancel button
  accent900: '#333', // cancel button hover
};

const overrides = {
  colors: {
    buttonPrimaryFill: primitives.accent,
    buttonPrimaryText: primitives.accent0,
    buttonPrimaryHover: primitives.accent600,
    buttonPrimaryActive: primitives.accent700,
    buttonPrimarySelectedFill: primitives.accent200,
    buttonPrimarySelectedText: primitives.accent,
    buttonPrimarySpinnerForeground: primitives.accent700,
    buttonPrimarySpinnerBackground: primitives.accent300,
    tickFillSelected: primitives.accent,
    tickBorder: primitives.accent1,
  },
};
export const theme = createTheme(primitives, overrides);

export const ButtonOverride = {
  style: {
    // adapted from .btn
    touchAction: 'manipulation',

    // //app.scss: @mixin btn-mixin
    fontFamily: 'Din-Condensed, sans-serif',
    fontWeight: '400',

    // // adapted from app.scss: @mixin btn-primary-mixin, .btn-sm
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',

    borderTopColor: 'rgba(91, 90, 89, 0.2)',
    borderRightColor: 'rgba(91, 90, 89, 0.2)',
    borderBottomColor: 'rgba(91, 90, 89, 0.2)',
    borderLeftColor: 'rgba(91, 90, 89, 0.2)',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',

    transitionProperty: 'background',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',

    // // adapted from mockup & app.scss: &.btn-sm
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingRight: '0.45em',
    paddingLeft: '0.45em',
  },
};

export const ButtonRegenerateOverride = {
  style: {
    ...ButtonOverride.style,
    minWidth: '115px',
    height: '34px',
    color: '#5B5A59',
    backgroundColor: '#d7dadb',
  },
};

export const ButtonConfigurationOverride = {
  style: {
    ...ButtonOverride.style,
    height: '36px',
    color: 'rgb(255, 255, 255)',
    backgroundColor: '#3693BF',
  },
};

export const ButtonSearchOverride = {
  style: {
    ...ButtonOverride.style,
    // height: '32px',
    height: '100%',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    marginTop: '4px',
    marginBottom: '4px',
  },
};

export const SelectOverride = {
  style: {
    height: '48px',

    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',

    borderTopColor: 'rgba(91, 90, 89, 0.2)',
    borderRightColor: 'rgba(91, 90, 89, 0.2)',
    borderBottomColor: 'rgba(91, 90, 89, 0.2)',
    borderLeftColor: 'rgba(91, 90, 89, 0.2)',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',

    paddingTop: '1px',
    paddingBottom: '1px',
    paddingRight: '0.45em',
    paddingLeft: '0.45em',

    backgroundColor: 'white',
  },
};

export const SelectFormConfigOverride = {
  style: {
    ...SelectOverride.style,
    height: '36px',
  },
};

export const SelectValueOverride = {
  style: {
    paddingTop: '2px',
    paddingBottom: '0px',
  },
};

export const SelectFilterComponentsOverride = {
  style: {
    ...SelectOverride.style,
    height: '100%',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
};

export const SelectInputOverride = {
  style: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
};

export const SelectIconsContainerOverride = {
  style: {
    paddingRight: '0px',
  },
};

export const SelectIconsOverride = {
  style: {
    color: primitives.accent,
  },
};

export const DropdownContainerOverride = {
  style: {
    color: 'white',
    backgroundColor: '#626367',
    fontSize: '16px',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export const DropdownContainerWhiteOverride = {
  style: {
    fontSize: '16px',
    
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export const InputRootOverride = {
  style: {
    height: '32px',
    backgroundColor: 'white',

    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',

    borderTopColor: 'rgba(91, 90, 89, 0.2)',
    borderRightColor: 'rgba(91, 90, 89, 0.2)',
    borderBottomColor: 'rgba(91, 90, 89, 0.2)',
    borderLeftColor: 'rgba(91, 90, 89, 0.2)',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',

    marginTop: '4px',
    marginBottom: '4px',
    marginRight: '0px',
    marginLeft: '0px',

    transitionProperty: 'background',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
    ':focus': {
      boxShadow: '0px 0px 4px 0.25px #3FA9DD',
    },
  },
};

export const PopoverOverrides = {
  style: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export const InputCalRootOverride = {
  style: {
    ...InputRootOverride.style,
    borderLeftWidth: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

export const InputSearchRootOverride = {
  style: {
    ...InputRootOverride.style,
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    paddingRight: '0px',
  },
};

export const InputOverride = {
  style: {
    backgroundColor: 'white',
  },
};

export const InputCalOverride = {
  style: {
    ...InputOverride.style,
    paddingLeft: '8px',
  },
};

export const InputToggleOverride = {
  style: {
    background: 'white',
    color: '#5191BA',
  },
};

export const TextAreaOverride = {
  style: {
    height: '200px',
    backgroundColor: 'white',

    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',

    borderTopColor: 'rgba(91, 90, 89, 0.2)',
    borderRightColor: 'rgba(91, 90, 89, 0.2)',
    borderBottomColor: 'rgba(91, 90, 89, 0.2)',
    borderLeftColor: 'rgba(91, 90, 89, 0.2)',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',

    transitionProperty: 'background',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
    ':focus': {
      boxShadow: '0px 0px 4px 0.25px #3FA9DD',
    },
  },
};

export const StyledBodyCellOverride = {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '400',
  paddingTop: '20px',
  paddingBottom: '20px',
  borderBottom: 'solid 1px #dbdbdb',
};

export const StyledBodyCellPausedOverride = {
  ...StyledBodyCellOverride,
  backgroundColor: '#fafafa',
};

export const StyledHeadCellOverride = {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '700',
  boxShadow: 'none',
  border: 'none',
  borderTop: 'solid 1px #dbdbdb',
  borderBottom: 'solid 1px #dbdbdb',
};

export const ButtonGroupOverride = {
  style: {
    // .btn-group
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: '#AAA',

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export const ButtonGroupFirstButtonOverride = {
  style: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: '#AAA',
    height: '35px',
    color: 'white',
    fontFamily: 'Din-Condensed, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    touchAction: 'manipulation',

    ':hover': {
      backgroundColor: 'none',
    },
  },
};

export const ButtonGroupFirstButtonActiveOverride = {
  style: {
    // .btn-group>..btn:last
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#3693BF',
    height: '35px',
    color: 'white',
    boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 13%)',
    fontFamily: 'Din-Condensed, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    touchAction: 'manipulation',

    ':hover': {
      backgroundColor: 'none',
    },
  },
};

export const ButtonGroupLastButtonOverride = {
  style: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#AAA',
    height: '35px',
    color: 'white',
    fontFamily: 'Din-Condensed, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    touchAction: 'manipulation',

    ':hover': {
      backgroundColor: 'none',
    },
  },
};

export const ButtonGroupLastButtonActiveOverride = {
  style: {
    // .btn-group>..btn:last
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#3693BF',
    height: '35px',
    color: 'white',
    boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 13%)',
    fontFamily: 'Din-Condensed, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    touchAction: 'manipulation',

    ':hover': {
      backgroundColor: 'none',
    },
  },
};

export const ModalHeaderOverride = {
  style: {
    fontWeight: '700',
    fontSize: '24px',
    color: '#5B5A59',
  },
};

export const ButtonCancelOverride = {
  style: {
    color: primitives.accent800,

    ':hover': {
      color: primitives.accent900,
    },
  },
};

export const ButtonCancelFormConfigOverride = {
  style: {
    fontSize: '16px',
    color: '#5192BB',
  },
};

export const ButtonReportStatusActiveOverride = {
  style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
    maxHeight: '28px',
    width: '60px',
    maxWidth: '60px', 
    color: '#009B19',
    backgroundColor: '#e5f5e8',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    paddingTop: '2px',
  },
};

export const ButtonReportStatusInactiveOverride = {
  style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
    maxHeight: '28px',
    width: '72px',
    maxWidth: '72px', 
    color: '#626367',
    backgroundColor: '#e4e4e4',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    paddingTop: '2px',
  },
};

export const PopoverBaseButtonOverride = {
  style: {
    height: '40px',
    display: 'block',
    backgroundColor: '#626367',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    outline: 'none',
    paddingTop: '10px',

    ':hover': {
      backgroundColor: '#626367',
    },
  },
};

export const DownloadButtonOverride = {
  style: {
    paddingTop: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingBottom: '0px',
    display: 'block',
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
    },
  },
};

const CheckboxStyling = {
  borderTopWidth: '1px',
  borderRightWidth: '1px',
  borderBottomWidth: '1px',
  borderLeftWidth: '1px',
  borderTopColor: '#d9d9d9',
  borderRightColor: '#d9d9d9',
  borderBottomColor: '#d9d9d9',
  borderLeftColor: '#d9d9d9',
};

export const CheckboxUncheckedOverride = {
  style: CheckboxStyling,
};

export const CheckboxCheckedOverride = {
  style: {
    ...CheckboxStyling,
    backgroundColor: primitives.accent,
  },
};

export const CheckboxDisabledOverride = {
  style: {
    ...CheckboxStyling,
    backgroundColor: '#a9c8dd',
  }
};

export const StatefulPopoverInnerOverride = {
  style: {
    color: 'white',
    outline: '#000000',
    backgroundColor: '#626367',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    cursor: 'pointer',
  },
};

export const StatefulPopoverBodyOverride = {
  style: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export const FormSubmissionDetailHeadCellOverride = {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '700',
  boxShadow: 'none',
  border: 'none',
  borderTop: 'none',
  borderBottom: 'solid 1px #dbdbdb',
};

export const FormSubmissionDetailBodyCellOverride = {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '400',
  paddingTop: '15px',
  paddingBottom: '15px',
  border: 'none',
};

export const TabHighlightOverride = {
  style: {
    backgroundColor: primitives.accent,
  },
};

export const TabBorderOverride = {
  style: {
    width: '500px',
  },
};
