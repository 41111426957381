import React, { useCallback, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { ControlTag } from '@app/core/services/control-tags.service';
import {
  TwoWayService,
  getUpdatedDate,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { MessageCount } from '../message-count/MessageCount';
import { Block } from 'baseui/block';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import { Conversation, Participant, User } from '@twilio/conversations';
import { useCustomerStatus } from '../../context/CustomerStatusContext';
import { useTwilioClient } from '../../context/TwilioClientContext';
import { ChatToShow } from '../relay-messenger-list/RelayMessenger.list';
import { RelayMessengerChatTypes } from '../relay-messenger-conversations/RelayMessengerConversations';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

interface RelayMessengerSingleConversationProps {
  firstLastName: {
    firstName: string;
    lastName: string;
  };
  dateCreated: string;
  dateUpdated: string;
  agentTags: ControlTag[];
  tagId: string;
  id: string;
  tagName: string;
  messagePreview?: string;
  chat?: Conversation;
  chatType?: ChatToShow;
  unreadMessageCount?: number;
  newlyAssigned?: boolean;
  closedDate?: string;
  closedReason?: string;
}

export const RelayMessengerSingleConversation = ({
  firstLastName: { firstName, lastName },
  dateCreated,
  dateUpdated,
  agentTags,
  tagId,
  id,
  tagName,
  messagePreview,
  chat,
  chatType,
  unreadMessageCount = 0,
  newlyAssigned,
  closedDate = '',
  closedReason = '',
}: RelayMessengerSingleConversationProps) => {
  const [css] = useStyletron();
  const formattedDateCreated = new Date(dateCreated).toLocaleDateString();
  const formattedClosedDate = new Date(closedDate).toLocaleDateString();
  const updatedDate = getUpdatedDate(dateUpdated);
  const {
    customerInfo: { channelSid },
  } = useCustomerInfo();
  const { customerStatus, setCustomerStatus } = useCustomerStatus();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { twilioClient } = useTwilioClient();

  const conversationSelected = channelSid === id;
  const reachabilityEnabled = twilioClient?.reachabilityEnabled;

  const getCustomerStatusForAssignedConversations = useCallback(
    async (chat: Conversation) => {
      if (conversationSelected) {
        const twilioConversation =
          chatType === RelayMessengerChatTypes.MyConversations
            ? (chat as Conversation)
            : await TwoWayService.getTwilioConversation(
                twilioClient,
                channelSid,
              );
        if (twilioConversation) {
          try {
            const participants: Participant[] =
              await twilioConversation.getParticipants();
            const user = (
              await participants
                ?.find((participant) => participant.attributes['customer'])
                .getUser()
            ).addListener('updated', ({ user, updateReasons }) => {
              if (
                updateReasons.includes('reachabilityOnline') &&
                user.attributes['customer']
              ) {
                user.isOnline
                  ? setCustomerStatus('online')
                  : setCustomerStatus('offline');
              }
            });
            setSelectedUser(user);

            // Update customer status when conversation is selected
            user.isOnline
              ? setCustomerStatus('online')
              : setCustomerStatus('offline');
          } catch (error) {
            console.error(
              'Error getting customer status for selected conversation',
            );
          }
        }
      }
    },
    [conversationSelected, customerStatus, chat],
  );

  useEffect(() => {
    if (reachabilityEnabled && chat) {
      getCustomerStatusForAssignedConversations(chat);
    }
    return () => {
      if (selectedUser) {
        selectedUser.removeAllListeners();
      }
    };
  }, [conversationSelected, customerStatus, chat, twilioClient]);

  const backgroundColor = newlyAssigned
    ? '#75919F'
    : conversationSelected
    ? '#37474F26'
    : 'transparent';
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  return (
    <>
      <div
        id={id}
        data-testid={id}
        className={css({
          backgroundColor: backgroundColor,
          color: newlyAssigned ? '#FFF' : '#000',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingLeft: '10px',
          paddingRight: '10px',
          ...(newlyAssigned
            ? {
                animationDuration: '2s',
                animationIterationCount: 'forwards',
                animationTimingFunction: 'ease-in-out',
                animationName: {
                  '0%': {
                    backgroundColor: '#75919F',
                  },
                  '50%': {
                    backgroundColor: '#37474F',
                  },
                  '100%': {
                    backgroundColor: '#75919F',
                  },
                },
              }
            : {}),
          ':hover': {
            backgroundColor: newlyAssigned
              ? '#75919F'
              : conversationSelected
              ? '#37474F26'
              : '#E2EFFA80',
            cursor: 'pointer',
          },
        })}
      >
        {newlyAssigned && (
          <div
            className={css({
              textTransform: 'uppercase',
              color: '#fff',
              fontSize: '10px',
            })}
          >
            Newly assigned
          </div>
        )}
        <Block
          overrides={{
            Block: {
              style: () => ({
                display: 'flex',
                justifyContent: 'space-between',
              }),
            },
          }}
        >
          <p
            className={css({ fontWeight: `700` })}
          >{`${firstName} ${lastName}`}</p>
          <p>{updatedDate}</p>
        </Block>
        <Block
          overrides={{
            Block: {
              style: () => ({
                display: 'flex',
                justifyContent: 'space-between',
              }),
            },
          }}
        >
          <p
            className={css({
              width: `80%`,
              whiteSpace: `nowrap`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
              visibility: messagePreview ? 'visible' : 'hidden',
            })}
            data-testid={`${id}-message-preview`}
          >
            {messagePreview}
          </p>
          <div className={css({ marginLeft: '20px' })}>
            {chat &&
              ((channelSid && channelSid !== chat.sid) || !channelSid) &&
              unreadMessageCount > 0 && (
                <MessageCount
                  count={unreadMessageCount}
                  variant={newlyAssigned ? 'highlighted' : 'default'}
                ></MessageCount>
              )}
          </div>
        </Block>
        <Block
          overrides={{
            Block: {
              style: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                margin: '0 auto',
              },
            },
          }}
        >
          <p
            className={css({ marginBottom: 0, width: '40%' })}
          >{`Created: ${formattedDateCreated}`}</p>
          <p
            className={css({ marginBottom: 0, width: '55%' })}
          >{`Tag: ${tagName}`}</p>
          {chatType === RelayMessengerChatTypes.ClosedConversations && (
            <>
              {closedDate && (
                <p className={css({ marginBottom: 0, width: '40%' })}>
                  {`Closed: ${formattedClosedDate}`}
                </p>
              )}
              {closedReason && (
                <p
                  className={css({
                    marginBottom: 0,
                    width: '55%',
                  })}
                >
                  <ResponsiveEllipsis
                    text={`Reason: ${closedReason}`}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </p>
              )}
            </>
          )}
        </Block>
      </div>
      <hr className={css({ border: '1', margin: '0px' })}></hr>
    </>
  );
};
