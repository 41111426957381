<div style="display: grid; grid-template-columns: 1fr 1fr">
    <div class="helpsite-template-layout"
         style="display: grid; grid-template-rows: repeat(11, auto) 1fr; grid-template-columns: 1fr 3fr; padding: 10px">
        <span style="grid-column: 1"><i>All fields are required unless noted.</i></span>
        <form [formGroup]="formGroup" style="grid-column: 1">
            <rn-text-input [inputControl]="formGroup.controls['client-name-short']"
                           [inputLabel]="'Client Name (short):'"
                           [isWide]="true"
                           [placeholderText]="'Client Name (short)'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>

            <rn-text-input [inputControl]="formGroup.controls['client-name-long']"
                           [inputLabel]="'Client Name (long):'"
                           [isWide]="true"
                           [placeholderText]="'Client Name (long)'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>

            <rn-text-input [inputControl]="formGroup.controls['client-short-long-code']"
                           [inputLabel]="'Client Short/Long Code:'"
                           [isWide]="true"
                           [placeholderText]="'Client Short/Long Code'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>

            <rn-text-input [inputControl]="formGroup.controls['client-support-text']"
                           [inputLabel]="'Customer Support Text:'"
                           [isWide]="true"
                           [placeholderText]="'Client Short/Long Code'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>

            <rn-text-input [inputControl]="formGroup.controls['client-support-phone']"
                           [inputLabel]="'Customer Support Phone:'"
                           [isWide]="true"
                           [placeholderText]="'Customer Support Phone'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>

            <rn-text-input [inputControl]="formGroup.controls['client-support-url']"
                           [inputLabel]="'Customer Support URL (include http://)'"
                           [isWide]="true"
                           [placeholderText]="'Customer Support URL (include http://)'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>
            <div *ngIf="formGroup.controls['client-support-url'].invalid">
                <div *ngIf="showInvalidUrlSupportSite()"
                     class="alert alert-danger">
                    Invalid url.
                </div>
            </div>

            <app-radio-btn (btnModelChange)="handleRelayPrivacyToggle($event)"
                           [(btnModel)]="includeRelayPrivacy"
                           [btnAutoSize]="true"
                           [btnDisplayTrueFirst]="false"
                           [btnLabelFalse]="'No'"
                           [btnLabelTrue]="'Yes'"
                           [btnLabel]="'Relay Privacy Policy'"
                           [btnName]="'show-banner'"
                           [btnWidth]="'100%'"
                           class="helpsite-template-layout__item"></app-radio-btn>

            <app-radio-btn (btnModelChange)="handlePrivacyToggle($event)"
                           [(btnModel)]="showClientPrivacyUrl"
                           [btnAutoSize]="true"
                           [btnDisplayTrueFirst]="false"
                           [btnLabelFalse]="'No'"
                           [btnLabelTrue]="'Yes'"
                           [btnLabel]="'Client Privacy Policy (include http://)'"
                           [btnName]="'show-banner'"
                           [btnWidth]="'100%'"
                           class="helpsite-template-layout__item"></app-radio-btn>


            <rn-text-input *ngIf="showClientPrivacyUrl"
                           [inputControl]="privacyFormGroup.controls['client-privacy-policy-url']"
                           [isWide]="true"
                           [placeholderText]="'Customer Privacy Policy URL (include http://)'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>
            <div *ngIf="privacyFormGroup.invalid">
                <div *ngIf="showToggledButNoValue(privacyFormGroup)"
                     class="alert alert-danger">
                    Client must provide Privacy Policy url.
                </div>
                <div *ngIf="showInvalidUrlPrivacy()"
                     class="alert alert-danger">
                    Invalid url.
                </div>
                <div *ngIf="showNeedAtLeastOneURL(privacyFormGroup)"
                     class="alert alert-danger">
                    Either Relay or Client Privacy details must be provided.
                </div>
            </div>


            <app-radio-btn (btnModelChange)="handleRelayTOUToggle($event)"
                           [(btnModel)]="includeRelayTermsOfUse"
                           [btnAutoSize]="true"
                           [btnDisplayTrueFirst]="false"
                           [btnLabelFalse]="'No'"
                           [btnLabelTrue]="'Yes'"
                           [btnLabel]="'Relay Terms Of Use'"
                           [btnName]="'show-banner'"
                           [btnWidth]="'100%'"
                           class="helpsite-template-layout__item"></app-radio-btn>

            <app-radio-btn (btnModelChange)="handleTOUToggle($event)"
                           [(btnModel)]="showClientTermsOfUse"
                           [btnAutoSize]="true"
                           [btnDisplayTrueFirst]="false"
                           [btnLabelFalse]="'No'"
                           [btnLabelTrue]="'Yes'"
                           [btnLabel]="'Client Terms of Use (include http://)'"
                           [btnName]="'show-banner'"
                           [btnWidth]="'100%'"
                           class="helpsite-template-layout__item"></app-radio-btn>

            <rn-text-input *ngIf="showClientTermsOfUse"
                           [inputControl]="touFormGroup.controls['client-terms-of-use-url']"
                           [isWide]="true"
                           [placeholderText]="'Customer Terms of Use URL (include http://)'"
                           class="helpsite-template-layout__item"
            ></rn-text-input>
            <div *ngIf="touFormGroup.invalid">
                <div *ngIf="showToggledButNoValue(touFormGroup)"
                     class="alert alert-danger">
                    Client must provide TOS url.
                </div>
                <div *ngIf="showInvalidUrlTOU()"
                     class="alert alert-danger">
                    Invalid url.
                </div>
                <div *ngIf="showNeedAtLeastOneURL(touFormGroup)"
                     class="alert alert-danger">
                    Either Relay or Client Terms of Use details must be provided.
                </div>
            </div>
        </form>

        <div class="helpsite-template-layout__item_last">
            <button (click)="save()" [disabled]="isSaveButtonDisabled()" class="btn btn-primary" id="save-btn"
                    style="margin: 10px">
                <span class="btn-label">save</span>
            </button>
            <button (click)="cancel()" class="btn btn-primary" id="cancel-btn">
                <span class="btn-label">cancel</span>
            </button>
        </div>
    </div>
    <div *ngIf="!isSaveButtonDisabled()" style="padding: 10px">
        <rn-client-helpsite-preview [client]="client" [formGroup]="formGroup"
                                    id="helpSitePreview"></rn-client-helpsite-preview>
    </div>
</div>
