import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/*
  DESCRIPTION
  
  Used to clone objects getting passed to components as inputs, to ensure 
  that the object's contents will not be modified. 

  USAGE

  <rn-my-component [myinput]="myObject | clone"></rn-my-component>
*/

@Pipe({
  name: 'clone',
  pure: true
})
export class ClonePipe implements PipeTransform {
  transform(value: object): string {
    return _.cloneDeep(value);
  }
}
