import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { distinctUntilChanged, forkJoin, merge, first } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicInputsService } from '@app/cx-builder/services/dynamic-inputs.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { MessageClass } from '@app/core/models/message';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { BlackoutHour } from '@app/core/models/blackout-hour';
import { MediaAsset } from '@app/core/models/media';
import { TitleService } from '@app/core/services/title.service';
import { JourneyService } from '@app/core/services/journey.service';
import { SessionService } from '@app/security/session.service';
import { MediaService } from '@app/core/services/media.service';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { InputParamUtil } from '@app/core/models/input-param-util';
import { Background } from '@app/core/utils/background';
import { Ordering } from '@app/core/utils/ordering';
import { GuidUtils } from '@app/core/utils/guid-utils';
import { Renumberer } from '@app/core/utils/renumberer';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';
import { IWCUHelperService } from '@app/cx-builder/services/builder-helper.service';
import {
  TinyEditorConfig,
  TinyEditorService,
} from '@app/core/services/tiny-editor.service';
import { BlackoutUtils } from '@app/core/utils/blackout-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureService } from '@app/core/services/feature.service';
import { SmsService } from '@app/core/services/sms.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import * as _ from 'lodash';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { Conversions } from '@app/core/utils/conversions';
import { ExperienceType, Outcome } from '@app/core/models/categorization-types';
import {
  CategorizationService,
  CategoryAncestryDetails,
} from '@app/core/services/categorization.service';
import { LoggerService } from '@app/core/services/logger.service';
import { HtmlUtilService } from '@app/core/services/html-util.service';
import { ValidationUtils } from '@app/core/utils/validation-utils';
import { UnSupportedSecondaryActionFeature } from '@app/core/models/action';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { AwsUtils } from '@app/core/utils/aws-utils';

@Component({
  selector: 'app-message-builder',
  templateUrl: './message-builder.component.html',
  styleUrls: ['./message-builder.component.scss'],
})
export class MessageBuilderComponent implements OnInit, OnDestroy {
  @ViewChild('inputWarningMessageDialog', { static: true })
  inputWarningMessageDialog: MessageDialogComponent;
  @ViewChild('messageTextBox') messageBox;
  @ViewChild('messageDialogWithKickback', { static: true })
  messageDialogWithKickback: MessageDialogComponent;
  @ViewChild('messageDialogNoKickback', { static: true })
  messageDialogNoKickback: MessageDialogComponent;
  @ViewChild('iwcuWarning', { static: true })
  iwcuWarning: MessageDialogComponent;

  @Output() deleteMsg = new EventEmitter();
  message: MessageClass; // DO NOT INITIALIZE HERE--needs session service context
  screen: string = 'wire';
  journey: Journey;
  journeyId: string;
  messageId: string;
  productGroup: ProductGroup;
  productGroupCopy: ProductGroup;
  showAddAssetDialog = false;
  dynamicInputs: string[] = [
    'account_ccid',
    'account_firstname',
    'account_lastname',
    'account_product_group',
    'account_secondary_account_id',
  ];
  inputWarningText: string =
    "You are about to save a message that has dynamic inputs without setting up an alternate message. We recommend setting up an alternate message in case this information is not provided for a user's account. If you attempt to launch the experience as is and the information requested is not available for a customer, this message will not be sent to that particular user.";
  messageGroup: UntypedFormGroup;
  actionFormArray: UntypedFormArray;
  hours: BlackoutHour[];

  brands: Array<MediaAsset>;
  previousBrandId: string;
  experienceTypes: ExperienceType[] = [];
  allExperienceTypes: ExperienceType[] = [];
  outcomes: Outcome[] = [];
  addMediaAssetType: string[] = ['image'];
  mediaAssetCanSelect = true;

  messageLevelBranding = false;

  permissions = Permissions;
  hasIWCU: boolean;
  loaded = false;

  currentClient: Client;
  showTooltip: boolean = false;
  daysWeeks = ['days', 'weeks'];

  tinyEditorConfig: TinyEditorConfig;
  featureFlags = FeatureFlags;
  experienceLibFeatureFlag: boolean = false;
  tooltipMessage: string = '';
  /*
  NOTE: the max length validator for sms is pushed into this array in the
  ngOnInit function (ie runtime), not where these validators are used
  */
  smsValidators = [
    Validators.required,
    CustomValidators.nonSpace,
    CustomValidators.validateReservedInputURL,
  ];
  showSmsByDefault: boolean;

  protected readonly CharacterLimits = CharacterLimits;

  static fadeOutMessageAction(index: number) {
    const actionElement = <HTMLElement>(
      document.querySelector('#msg-action-' + index)
    );
    if (actionElement) {
      actionElement.classList.add('fadeOut');
    }
  }

  constructor(
    public sessionService: SessionService,
    public twoWayService: TwoWaySharedService,
    public featureService: FeatureService,
    public clientService: ClientService,
    public newFeed: NewFeedTransitionService,
    private titleService: TitleService,
    private journeyService: JourneyService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private mediaService: MediaService,
    private dynamicInputsService: DynamicInputsService,
    private actionService: ActionService,
    private iwcuHelperService: IWCUHelperService,
    private ps: PermissionService,
    private tinyEditorService: TinyEditorService,
    private smsService: SmsService,
    private categorizationService: CategorizationService,
    private htmlUtilService: HtmlUtilService,
  ) {}

  get showRawEditor(): boolean {
    return this.sessionService.currentUser.role_id.includes(
      'relay-super-admin',
    );
  }

  get weekendBlackoutMessage(): string {
    return BlackoutUtils.weekendBlackoutMessage(
      this.message.sms.blackout_settings.blackout_window,
      'Users will not receive notifications on ',
    );
  }

  get showOutcomeExpTypeDropdowns(): boolean {
    return (
      this.experienceLibFeatureFlag &&
      !!this.currentClient['industry'] &&
      !!this.currentClient['company_type']
    );
  }

  get alternateMessageTextCtl(): UntypedFormControl {
    return <UntypedFormControl>(
      this.messageGroup.controls['alternate_message_text']
    );
  }

  get feedMessageLevelBrandingCtl(): UntypedFormControl {
    return <UntypedFormControl>(
      this.messageGroup.controls['message_level_branding']
    );
  }

  get feedMessageExpirationCtl(): UntypedFormControl {
    return <UntypedFormControl>this.messageGroup.controls['message_expiration'];
  }

  get feedMessageExpirationTypeCtl(): UntypedFormControl {
    return <UntypedFormControl>(
      this.messageGroup.controls['message_expiration'].get('type')
    );
  }

  get feedMessageExpirationAmountCtl(): UntypedFormControl {
    return <UntypedFormControl>(
      this.messageGroup.controls['message_expiration'].get('amount')
    );
  }

  get feedMessageExpirationUnitCtl(): UntypedFormControl {
    return <UntypedFormControl>(
      this.messageGroup.controls['message_expiration'].get('unit')
    );
  }

  get messageTextCtl(): UntypedFormControl {
    return <UntypedFormControl>this.messageGroup.controls['message_text'];
  }

  get outcomeCtl(): UntypedFormControl {
    return <UntypedFormControl>this.messageGroup.controls['outcome_id'];
  }

  get experienceTypeCtl(): UntypedFormControl {
    return <UntypedFormControl>this.messageGroup.controls['experience_type_id'];
  }

  get isOutcomeCtlError(): boolean {
    // using ".status" for checking whether control is valid instead of ".valid" because ".valid" return "FALSE" when control is DISABLED
    // https://stackoverflow.com/questions/51466641/reactive-form-valid-property-return-false-when-form-is-disabled
    return this.outcomeCtl?.status === 'INVALID' && this.outcomeCtl?.touched;
  }

  get isExperienceTypeCtlError(): boolean {
    // using ".status" for checking whether control is valid instead of ".valid" because ".valid" return "FALSE" when control is DISABLED
    // https://stackoverflow.com/questions/51466641/reactive-form-valid-property-return-false-when-form-is-disabled
    return (
      this.experienceTypeCtl?.status === 'INVALID' &&
      this.experienceTypeCtl?.touched
    );
  }

  get outcomeFormStatus(): string {
    return this.isOutcomeCtlError ? 'has-error' : '';
  }

  get experienceTypeFormStatus(): string {
    return this.isExperienceTypeCtlError ? 'has-error' : '';
  }

  get wireOrFeedMessageTextLimit(): number {
    return this.newFeed.enabled
      ? CharacterLimits.MessageTextCharLimitFeed
      : CharacterLimits.MessageTextCharLimitWire;
  }

  get isAlternateMessageTextEnabled() {
    return this.alternateMessageTextCtl.enabled;
  }

  get fdicEnabled(): boolean {
    return this.currentClient.fdic_enabled;
  }

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.tooltipMessage = this.currentClient?.feed_enabled
      ? 'Relay Messenger'
      : 'Two Way Messaging';
    this.experienceLibFeatureFlag = this.featureService.checkFlag(
      this.featureFlags.experience_library,
    );

    this.showSmsByDefault = this.currentClient.show_sms_by_default;
    this.actionFormArray = new UntypedFormArray([]);
    this.hours = this.clientService.getBlackoutHours();

    this.titleService.activate('Message Builder');
    Background.gridsOn();
    this.journeyId = this.route.snapshot.params['journeyId'];
    this.messageId = this.route.snapshot.params['messageId'];
    this.message = new MessageClass({
      showSmsByDefault: this.showSmsByDefault,
    });
    this.loadData();
    this.clientService.getClient(this.currentClient.id).subscribe((client) => {
      this.dynamicInputsService.clientDynamicInputs = client.ext_dynamic_fields;
      this.dynamicInputs = this.dynamicInputs.concat(client.ext_dynamic_fields);
    });
    this.initTinyEditorConfig();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    Background.gridsOff();
  }

  /**
   * True if outcomeCtl is undefined initially or empty string.
   */
  showOutcomeTypeDefaultOption(outcomeCtl: UntypedFormControl): boolean {
    return outcomeCtl?.value ?? '' === '';
  }

  /**
   * True if outcomeCtl or experienceTypeCtl are undefined initially or empty strings.
   */
  showExperienceTypeDefaultOption(
    outcomeCtl: UntypedFormControl,
    experienceTypeCtl: UntypedFormControl,
  ): boolean {
    return (
      (experienceTypeCtl?.value ?? '' === '') ||
      (outcomeCtl?.value ?? '' === '')
    );
  }

  initForm(message: MessageClass) {
    this.messageGroup = this.fb.group({
      message_name: [
        message.nickname,
        [
          Validators.required,
          CustomValidators.nonSpace,
          CustomValidators.validateNegativePattern(
            new RegExp(/^(\s*)(New\sMessage)(\s*)$/),
          ),
          Validators.maxLength(40),
        ],
      ],
      message_text: [
        message.wire.text,
        [
          Validators.required,
          CustomValidators.htmlNonSpace,
          Validators.maxLength(
            this.newFeed.enabled
              ? CharacterLimits.MessageTextCharLimitFeed
              : CharacterLimits.MessageTextCharLimitWire,
          ),
          CustomValidators.validateReservedInputURL,
        ],
      ],
      sms_intro: [null],
      sms_body: [message.sms.body, this.smsValidators],
      sms_disclaimer: [
        message.sms.disclaimer,
        CustomValidators.validateReservedInputURL,
      ],
      auth_link: [message.sms.auth_link], // validators managed in child SmsBuilderComponent
      alternate_sms_text: [
        message.sms.alternate_text,
        CustomValidators.validateInputParamsAuthlinkAllowed(
          InputParamUtil.inputParamPattern,
        ),
      ],
      alternate_message_text: [
        message.wire.alternate_text,
        [
          CustomValidators.validateNegativeInputPattern(),
          Validators.maxLength(
            this.newFeed.enabled
              ? CharacterLimits.MessageAltTextCharLimitFeed
              : CharacterLimits.MessageAltTextCharLimitWire,
          ),
        ],
      ],
      message_level_branding: [message.message_level_branding],
      show_banner: [null],
      message_expiration: this.fb.group({
        type: [null],
        unit: [null],
        amount: [null],
      }),
    });

    if (this.showOutcomeExpTypeDropdowns) {
      this.setOutcomeAndExperienceTypeOptions(message);
    }

    // need to update the model on certain form control changes, so the existing
    // model-based validation continues to work.
    merge(
      this.messageTextCtl.valueChanges,
      this.alternateMessageTextCtl.valueChanges,
      this.messageGroup.controls['message_name'].valueChanges,
    ).subscribe(() => {
      message.nickname = this.messageGroup.controls['message_name'].value;
      message.wire.text = this.messageTextCtl.value;
      message.wire.alternate_text = this.alternateMessageTextCtl.value;
    });
  }

  updateExpirationAmountValidators(type: string) {
    if (type === 'custom') {
      this.feedMessageExpirationAmountCtl.setValidators([
        Validators.required,
        Validators.maxLength(2),
      ]);
    } else {
      this.feedMessageExpirationAmountCtl.clearValidators();
    }
    this.feedMessageExpirationAmountCtl.updateValueAndValidity();
  }

  setOutcomeAndExperienceTypeOptions(message: MessageClass): void {
    forkJoin({
      outcomes: this.categorizationService.getOutcomes(this.currentClient.id),
      experienceTypes: this.categorizationService.getExperienceTypes(
        this.currentClient.id,
      ),
    }).subscribe(
      ({ outcomes, experienceTypes }) => {
        this.outcomes =
          this.categorizationService.getOutcomesForCompanyTypeFullAncestorPath(
            outcomes.data,
            new CategoryAncestryDetails({
              industryId: this.currentClient.industry,
              companyTypeId: this.currentClient.company_type,
            }),
          );
        this.allExperienceTypes = experienceTypes.data; // preserve the full list so we don't hit the server

        // ensures we don't try to set a value that doesn't exist
        const outcomeId = this.outcomes.some(
          (outcome) => outcome.id === message.outcome_id,
        )
          ? message.outcome_id
          : null;
        if (outcomeId) {
          this.setExperienceTypeOptions(outcomeId);
        }

        // must occur after we set the experience type options
        const experienceTypeId = this.experienceTypes.some(
          (expType) => expType.id === message.experience_type_id,
        )
          ? message.experience_type_id
          : null;

        this.messageGroup.addControl(
          'outcome_id',
          new UntypedFormControl(outcomeId || '', Validators.required),
        );
        this.messageGroup.addControl(
          'experience_type_id',
          new UntypedFormControl(experienceTypeId || '', Validators.required),
        );

        // Don't allow editing O/ET if the experience is live already, and has these values
        // defined
        if (this.isEligibleForMessageRanking()) {
          this.experienceTypeCtl.disable();
          this.outcomeCtl.disable();
        }

        this.outcomeCtl.valueChanges
          .pipe(distinctUntilChanged()) // fancy lingo for 'don't bother me unless it actually changes'
          .subscribe((newOutcomeId) => {
            this.handleOutcomeValueChange(newOutcomeId);
          });
      },
      (error) => {
        this.messageDialogNoKickback.showMessage(
          'Could not retrieve outcomes or experience types, please try again later.',
        );
        LoggerService.log(
          'message-builder.component',
          `outcomes/experience types for clientId ${
            this.currentClient.id
          } error: ${JSON.stringify(error)}`,
        );
      },
    );
  }

  /**
   * Once an experience is live with a defined outcome/experience type value,
   * we do not support further modification to the values.
   * @private
   */
  private isEligibleForMessageRanking() {
    const liveJourney = this.journey.live;
    const liveMessage =
      liveJourney?.components.filter(
        (message) => message.name === this.message.name,
      ) ?? [];
    return (
      liveJourney &&
      liveMessage.length === 1 &&
      liveMessage[0].outcome_id &&
      liveMessage[0].experience_type_id
    );
  }

  /**
   * .valueChanges will trigger if the enable/disable state changes, as well as the value.
   * If the outcome value doesn't actually change, we shouldn't reinitialize the control.
   * However, we should always make sure to set the ET options--when the page loads, the message's current
   * Outcome will be set prior to hitting this logic, and will thus be the same when it comes through here.
   * @param newOutcomeId
   * @private
   */
  handleOutcomeValueChange(newOutcomeId: string) {
    this.setExperienceTypeOptions(newOutcomeId);
    this.experienceTypeCtl.setValue('');
  }

  setExperienceTypeOptions(outcomeId: string): void {
    if (ValidationUtils.isStringEmptyOrNull(outcomeId)) {
      this.experienceTypes = [];
    } else {
      this.experienceTypes =
        this.categorizationService.getExperienceTypesForOutcomeFullAncestorPath(
          this.allExperienceTypes,
          new CategoryAncestryDetails({
            industryId: this.currentClient.industry,
            companyTypeId: this.currentClient.company_type,
            outcomeId: outcomeId,
          }),
        );
    }
  }

  toggleSMS(): void {
    switch (this.message.sms.is_shown) {
      case 'true':
        this.messageGroup.controls['sms_intro'].enable();
        this.messageGroup.controls['sms_body'].enable();
        this.messageGroup.controls['alternate_sms_text'].enable();
        this.messageGroup.controls['alternate_sms_text'].enable();
        break;
      case 'false':
        this.messageGroup.controls['sms_intro'].disable();
        this.messageGroup.controls['sms_body'].disable();
        this.messageGroup.controls['alternate_sms_text'].disable();
        this.messageGroup.controls['alternate_sms_text'].disable();
        break;
      default: // call this again to go through the logic again
        // new instance of SmsClass, need to default to client config value
        this.message.sms.is_shown = Conversions.toString(
          this.currentClient.show_sms_by_default,
        );
        this.toggleSMS();
        break;
    }
  }

  /**
   * Handles enabling and disabling the controls which are irrelevant
   * when there isn't a feed message involved. NOTE: this gets triggered
   * directly via an ngModelChanges hook in the .html, and so will trigger up to 3 times
   * on load. Afterwards, it should only trigger when the toggle is swapped.
   * In addition, because it is driven off of ngModelChanges, all logic
   * should reference the actual model values, not the control values.
   */
  toggleWire(): void {
    if (this.message.wire.is_shown === 'false') {
      this.messageTextCtl.disable();
      this.toggleAlternateMessageText(false);
    } else {
      this.messageTextCtl.enable();
      this.toggleAlternateMessageText(this.message.wire.is_alternate_message);
    }

    this.toggleOutcomeExperienceType();
  }

  toggleOutcomeExperienceType() {
    if (!this.showOutcomeExpTypeDropdowns) {
      this.outcomeCtl.disable();
      this.experienceTypeCtl.disable();
    } else {
      if (this.message.wire.is_shown === 'false') {
        if (
          this.outcomeCtl &&
          this.outcomeCtl.enabled &&
          !this.isEligibleForMessageRanking()
        ) {
          // prevents double calls to the value changes logic
          this.experienceTypeCtl.disable();
          this.outcomeCtl.disable({ emitEvent: false }); // disable emitting valueChange event
        }

        // if only one or the other is selected, we should clear out the O/ET.
        if (
          this.outcomeCtl &&
          (this.outcomeCtl.value === '' || this.experienceTypeCtl.value === '')
        ) {
          this.outcomeCtl.setValue('');
          this.experienceTypeCtl.setValue('');
        }
      } else {
        // feed message is enabled
        if (
          this.outcomeCtl &&
          this.outcomeCtl.disabled &&
          !this.isEligibleForMessageRanking()
        ) {
          // prevents double calls to the value changes logic
          this.experienceTypeCtl.enable();
          this.outcomeCtl.enable({ emitEvent: false }); // disable emitting valueChange event
        }
      }
    }
  }

  /**
   * Handles toggling the alternate message text control (NOT
   * the toggle itself). This is critical to ensure we don't
   * falsely trigger alternate message validation when it's not turned on.
   * @param event
   */
  toggleAlternateMessageText(event: string | boolean) {
    if (event) {
      this.alternateMessageTextCtl.enable();
    } else {
      this.alternateMessageTextCtl.disable();
    }
  }

  /**
   * Handles toggling the alternate message text control (NOT
   * the toggle itself). This is critical to ensure we don't
   * falsely trigger alternate message validation when it's not turned on.
   * @param event
   */
  toggleMessageLevelBranding(event: string | boolean) {
    if (event) {
      this.message.message_level_branding_text = this.productGroup.id;
      this.feedMessageLevelBrandingCtl.enable();
      this.message.message_level_branding = {
        brand_name: this.productGroup.name,
        branding_id: this.productGroup.id,
        branding_logo: AwsUtils.removeHostName(
          this.productGroup.branding.banner_s3_url,
        ),
        hex_code: this.productGroup.branding.color,
        timestamp: new Date().toDateString(),
      };
      this.feedMessageLevelBrandingCtl.setValue({
        brand_name: this.productGroup.name,
        branding_id: this.productGroup.id,
        branding_logo: AwsUtils.removeHostName(
          this.productGroup.branding.banner_s3_url,
        ),
        hex_code: this.productGroup.branding.color,
        timestamp: new Date().toDateString(),
      });
    } else {
      this.message.message_level_branding_text = '';
      this.feedMessageLevelBrandingCtl.setValue({});
      this.message.message_level_branding = null;
      this.feedMessageLevelBrandingCtl.disable();

      // turn fdic back to default if message level branding is OFF
      this.message.fdic = null;
    }
  }

  // if user doesn't have perms to edit product groups and there is no T&C
  getSmsBody(): string {
    return this.smsService.getFullSmsBody(
      this.message.sms.body,
      this.message.sms.disclaimer,
    );
  }

  loadData(): void {
    this.journeyService.getJourneyById(this.journeyId).subscribe((journey) => {
      this.journey = journey;
      this.setupDraft();
      this.getBrands();
      this.message = journey.draft.components.find(
        (f) => f.name === this.messageId,
      );
      if (this.journey.draft.product_group !== 'all') {
        this.clientService
          .getProductGroupForCurrentUser(this.journey.draft.product_group)
          .subscribe((product) => {
            this.productGroup = product;
            this.productGroupCopy = ProductGroup.deserialize(product);
            this.smsService.init(this.message.sms, this.productGroup);
            this.hasIWCU = this.productGroup.consent.in_wire_upgrade.enabled;
            this.checkLoadingComplete();
            this.setupMessage();
          });
      } else {
        this.checkConsents();
        this.clientService
          .getProductGroupForCurrentUser('default')
          .pipe(first())
          .subscribe((product) => {
            this.productGroup = ProductGroup.deserialize({
              ...ProductGroup.allProductGroupPreview(),
              ...{ fdic: product.fdic },
            });
            this.productGroupCopy = Object.assign({}, this.productGroup);
            this.smsService.init(this.message.sms, this.productGroup);
            this.checkLoadingComplete();
            this.setupMessage();
          });
      }
    });
  }

  /**
   * This function is to set up all form groups after fetching message and product group
   */
  setupMessage() {
    if (this.message) {
      this.initForm(this.message); // note: toggleSMS & toggleWire both require the form to already be set up.
      this.toggleSMS();

      // only build action controls once we have message and product-group (product-group is required for disclaimer action)
      this.buildActionControls(this.message);

      // Migrate SMS messages that don't have intro, body, auth_link, disclaimer fields yet.
      if (
        this.message.sms.is_shown === 'true' &&
        !this.message.sms.body &&
        this.message.sms.text
      ) {
        this.message.sms.body = this.message.sms.text;
      }

      this.previousBrandId = this.message.wire.branding;
      if (this.message.type !== 'MessageSender') {
        this.messageGroup.removeControl('sms_body');
      } else {
        const sms = this.message.sms;
        if (sms.blackout_settings.blackout_type === 'default') {
          this.message.sms.blackout_settings.blackout_window.end_time =
            this.currentClient.blackout_window.end_time;
          this.message.sms.blackout_settings.blackout_window.start_time =
            this.currentClient.blackout_window.start_time;
          this.message.sms.blackout_settings.blackout_window.include_saturday =
            this.currentClient.blackout_window.include_saturday;
          this.message.sms.blackout_settings.blackout_window.include_sunday =
            this.currentClient.blackout_window.include_sunday;
        }
        this.messageGroup.controls['sms_body'].setValidators(
          this.smsValidators,
        );
      }
      if (this.message.message_level_branding) {
        this.messageLevelBranding = true;
      }

      /* Message Expiration */
      if (this.message?.message_expiration) {
        this.feedMessageExpirationCtl.setValue({
          type: this.message.message_expiration.type,
          unit: this.message.message_expiration.unit,
          amount: this.message.message_expiration.amount,
        });
      } else {
        this.message.message_expiration = {
          unit: null,
          type: 'none',
          amount: null,
        };
        this.feedMessageExpirationCtl.setValue({
          type: 'none',
          unit: null,
          amount: null,
        });
      }

      this.feedMessageExpirationTypeCtl.valueChanges.subscribe((type) => {
        this.message.message_expiration.type = type;
        this.updateExpirationAmountValidators(type);
      });

      this.feedMessageExpirationAmountCtl.valueChanges.subscribe((amount) => {
        this.message.message_expiration.amount = amount;
      });

      this.feedMessageExpirationUnitCtl.valueChanges.subscribe((unit) => {
        this.message.message_expiration.unit = unit;
      });

      if (this.message.wire.media_id) {
        if (!this.newFeed.enabled) {
          this.messageTextCtl.clearValidators();
        }
        this.mediaService
          .getMediaAsset(this.message.wire.media_id)
          .subscribe((media) => {
            if (media.asset_type === 'video') {
              this.message.wire.video_url = media.url;
            } else {
              this.message.wire.image_url = media.s3_url;
            }
          });
      }
      this.toggleWire();
    }
  }

  checkLoadingComplete(): void {
    if (this.journey && this.productGroup && this.brands && this.message) {
      this.loaded = true;

      if (this.message.wire.branding) {
        const brand = this.brands.find(
          (c) => c.asset_id === this.message.wire.branding,
        );
        if (brand) {
          this.productGroup.name = brand.shortname;
          this.productGroup.branding.icon_s3_url = brand.s3_url;
        }
      }
    }
  }

  getBrands(): void {
    this.mediaService
      .getAllBrands(new Ordering('shortname'))
      .subscribe((mediaAssets) => {
        this.brands = mediaAssets;
        if (
          this.message &&
          this.message.message_level_branding &&
          this.message.message_level_branding.branding_id &&
          this.brands &&
          this.brands.length > 0
        ) {
          this.message.message_level_branding_text =
            this.brands.find(
              (el) =>
                el.asset_id === this.message.message_level_branding.branding_id,
            )?.asset_id || this.productGroup.id;
        }
        this.checkLoadingComplete();
      });
  }

  addEmailElement(isPrimaryAction?: boolean) {
    const action = {
      type: 'email',
      description: 'Email',
      label: '',
      value: '',
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
    };
    this.addEmailControl(action);
    this.addAction(action, isPrimaryAction);
  }

  addLinkElement(isPrimaryAction?: boolean) {
    const action = {
      type: 'hyperlink',
      description: 'Hyperlink',
      label: '',
      value: '',
      open_in_new_tab: false,
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
      ...(this.fdicEnabled && { nonDepositPopUpEnabled: false }),
    };
    this.addLinkControl(action);
    this.addAction(action, isPrimaryAction);
  }

  addCollapsibleElement() {
    const action = {
      type: 'collapsible',
      description: 'Collapsible',
      label: '',
      value: '',
      show: false,
      id: GuidUtils.createRandomGuid(),
    };
    this.addCollapsibleControl(action);
    this.addAction(action);
  }

  addDisclaimerElement() {
    const action = {
      type: 'disclaimer',
      description: 'Disclaimer',
      value: this.productGroup.disclaimer.text,
      show_disclaimer: true,
      id: GuidUtils.createRandomGuid(),
    };
    this.addDisclaimerControl(action);
    this.addAction(action);
  }

  get isMsgLevelDisclaimerEnabled(): boolean {
    return (
      this.newFeed.enabled &&
      this.productGroup?.disclaimer?.message_level_enabled === 'true'
    );
  }

  /**
   * Return the state of the toggle : 'on'/'off' ---- have to use a string here so [ngModel] can bind correctly with btn-groupd
   * Also throw error if the state of disclaimer action is not valid within the message
   */
  get isDisclaimerOn(): 'on' | 'off' {
    if (!this.isDisclaimerValidAndUnique) {
      throw new Error(
        `Invalid disclaimer action in message ${this.message.name}`,
      );
    }

    if (this.disclaimerAction && this.disclaimerAction.show_disclaimer) {
      return 'on';
    }
    return 'off';
  }

  /**
   * This function returns
   * TRUE - if either
   *        A: There is no disclaimer action in the message
   *        B: There is only 1 disclaimer action and it's the last action on the list
   * FALSE - if doesn't satisfy the conditions above
   */
  get isDisclaimerValidAndUnique(): boolean {
    const disclaimerElementIndex = this.message.wire.actions.findIndex(
      (action) => action.type === 'disclaimer',
    );
    if (disclaimerElementIndex < 0) {
      return true;
    }

    return disclaimerElementIndex === this.message.wire.actions.length - 1;
  }

  /**
   * Return reference of disclaimer action in message.wire.actions
   */
  get disclaimerAction() {
    if (this.message.wire.actions.length === 0) {
      return undefined;
    }
    const lastAction =
      this.message.wire.actions[this.message.wire.actions.length - 1];
    return lastAction.type === 'disclaimer' ? lastAction : undefined;
  }

  /**
   * Return a formControl reference to disclaimer['value']
   */
  get disclaimerActionFormGroup(): null | UntypedFormControl {
    if (!this.disclaimerAction) {
      return null;
    }
    return (
      this.actionFormArray.controls[
        this.actionFormArray.length - 1
      ] as UntypedFormGroup
    ).controls['value'] as UntypedFormControl;
  }

  /**
   * This function is triggered when user toggle ON the message level disclaimer.
   * - If there is a disclaimer action, we need to enable it and setup validators
   * ELSE
   * - add a disclaimer action
   */
  turnOnDisclaimer(): void {
    if (!!this.disclaimerAction && !this.disclaimerAction.show_disclaimer) {
      this.disclaimerAction.show_disclaimer = true;
      this.disclaimerActionFormGroup?.setValidators([
        Validators.maxLength(CharacterLimits.MessageLevelDisclaimerCharLimit),
        Validators.required,
      ]);
    } else if (!this.disclaimerAction) {
      this.addDisclaimerElement();
    }
  }

  /**
   * This function is triggered when user toggle OFF the message level disclaimer.
   * If there is a disclaimer action, we need to not show it and still keep the value.
   * Also clearing any validators and update its validity
   */
  turnOffDisclaimer(): void {
    if (!!this.disclaimerAction && this.disclaimerAction.show_disclaimer) {
      this.disclaimerAction.show_disclaimer = false;
      this.disclaimerActionFormGroup?.clearValidators();
      this.disclaimerActionFormGroup?.updateValueAndValidity();
    }
  }

  /** if user doesn't have perms to edit product groups and there is no T&C
   * text in the product group, don't allow them to add an iwcu action
   * AND
   * If new feed is enabled, don't allow them to add more than 1 IWCU action
   */
  userCanAddIWCUAction(): boolean {
    const isTCEmpty = _.isEmpty(
      this.productGroup.consent.in_wire_upgrade.ts_cs,
    );
    if (isTCEmpty) {
      return false;
    }

    if (!this.newFeed.enabled) {
      return true;
    } else {
      return !this.message.wire.actions.find(
        (action) => action.type === 'consent_upgrade',
      );
    }
  }

  addIWCUElement(isPrimaryAction?: boolean) {
    if (!this.userCanAddIWCUAction()) {
      return;
    }
    const action = {
      type: 'consent_upgrade',
      description: 'Consent Upgrade',
      label: '',
      value: this.productGroup.consent.in_wire_upgrade.ts_cs,
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
      ...(this.newFeed.enabled && { openConsentTCs: false }),
    };
    this.addIWCUControl(action);
    this.addAction(action, isPrimaryAction);
  }

  addCallElement(isPrimaryAction?: boolean) {
    const action = {
      type: 'call',
      description: 'Call',
      label: '',
      value: '',
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
    };
    this.addCallControl(action);
    this.addAction(action, isPrimaryAction);
  }

  addTwoWayElement(isPrimaryAction?: boolean): void {
    const action = {
      type: 'two_way',
      description: 'Two Way Message',
      label: '',
      value: '',
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
    };
    this.addTwoWayControl(action);
    this.addAction(action, isPrimaryAction);
  }

  addPlaidElement(): void {
    const action = {
      type: 'plaid',
      description: 'Plaid',
      label: '',
      disclaimer_header: '',
      disclaimer_body: '',
      disclaimer_check_label: '',
      disclaimer_submit_label: '',
      id: GuidUtils.createRandomGuid(),
    };
    this.addPlaidControl(action);
    this.addAction(action);
  }

  addEmailDataCaptureElement(): void {
    const action = {
      type: 'email_data_capture',
      label: '',
      description: 'Email Capture',
      body: '',
      header: '',
      success_text: '',
      failure_text: '',
      dc: [
        {
          dc_id: '',
          dc_label: '',
          dc_verify: true,
          dc_verify_label: '',
          dc_type: 'email',
        },
        {
          dc_id: '',
          dc_accept_label: '',
          dc_decline_label: '',
          dc_type: 'consent',
        },
      ],
      id: GuidUtils.createRandomGuid(),
    };
    this.addEmailDataCaptureControl(action);
    this.addAction(action);
  }

  addAppleChatElement(): void {
    // todo - i might have to do change the label and value
    const action = {
      type: 'apple_chat',
      description: 'Apple Chat',
      apple_chat_business_id: '',
      apple_chat_biz_intent_id: '',
      apple_chat_biz_group_id: '',
      apple_chat_phone: '',
      apple_chat_body: '',
      id: GuidUtils.createRandomGuid(),
    };
    this.addAppleChatControl(action);
    this.addAction(action);
  }

  addFormInfoCaptureElement(isPrimaryAction?: boolean): void {
    const action = {
      type: 'form_info_capture',
      description: 'Power-Up',
      label: '',
      value: '',
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
      ...(this.newFeed.enabled && { openWithinMessage: false }),
    };
    this.addFormInfoCaptureControl(action);
    this.addAction(action, isPrimaryAction);
  }

  /**
   * Adds message element to a journey by inserting it into the journey component list.
   * @param isPrimaryAction a boolean flag whether it's a primary action (default to False)
   */
  addMessageElement(isPrimaryAction?: boolean) {
    // intialize new message and set its parent to the current message
    const message = new MessageClass({
      showSmsByDefault: this.showSmsByDefault,
    });
    message.type = 'MessageResponse';
    message.parent = this.message.name;
    message.to = null;

    // determine where in the list of components this new message should be inserted
    let componentIndex;
    if (this.message.type === 'MessageSender') {
      componentIndex = this.journey.draft.components.findIndex(
        (c) => c.name === this.message.to,
      );
    } else {
      // determine how many children already exist under this parent, so we can insert at the end
      const parentIndex = this.journey.draft.components.findIndex(
        (c) => c.name === message.parent,
      );
      componentIndex =
        parentIndex +
        this.journey.draft.components.filter((c) => c.parent === message.parent)
          .length +
        1;
    }

    this.journey.draft.components.splice(componentIndex, 0, message);

    // Add action to message
    const action = {
      type: 'message',
      description: 'Message',
      label: '',
      value: message.name,
      id: GuidUtils.createRandomGuid(),
      ...(isPrimaryAction && { primary: true }),
    };
    this.addMessageControl(action);
    this.addAction(action, isPrimaryAction);
  }

  onBrandSelected(assetId) {
    if (assetId === null) {
      if (this.journey.draft.product_group === 'all') {
        this.productGroup.name = 'all';
        this.productGroup.branding.icon_s3_url = '/assets/icons/asterisk.jpg';
      } else {
        this.productGroup.name = this.productGroupCopy.name;
        this.productGroup.branding.icon_s3_url =
          this.productGroupCopy.branding.icon_s3_url;
      }
    } else if (assetId === 'create') {
      this.addBrand();
    } else {
      const brand = this.brands.find((c) => c.asset_id === assetId);
      if (!this.newFeed.enabled) {
        this.previousBrandId = brand.asset_id;
        this.productGroup.name = brand.shortname;
        this.productGroup.branding.icon_s3_url = brand.s3_url;
      } else {
        if (assetId === this.productGroup.id) {
          this.feedMessageLevelBrandingCtl.setValue({
            brand_name: this.productGroup.name,
            branding_id: this.productGroup.id,
            branding_logo: AwsUtils.removeHostName(
              this.productGroup.branding.banner_s3_url,
            ),
            hex_code: this.productGroup.branding.color,
            timestamp: new Date().toDateString(),
          });
          this.message.message_level_branding = {
            brand_name: this.productGroup.name,
            branding_id: this.productGroup.id,
            branding_logo: AwsUtils.removeHostName(
              this.productGroup.branding.banner_s3_url,
            ),
            hex_code: this.productGroup.branding.color,
            timestamp: new Date().toDateString(),
          };
        } else {
          this.feedMessageLevelBrandingCtl.setValue({
            brand_name: brand.shortname,
            branding_logo: AwsUtils.removeHostName(brand.s3_url),
            hex_code: brand.branding_color,
            timestamp: new Date().toDateString(),
            branding_id: brand.asset_id,
          });
          this.message.message_level_branding = {
            brand_name: brand.shortname,
            branding_logo: AwsUtils.removeHostName(brand.s3_url),
            hex_code: brand.branding_color,
            timestamp: new Date().toDateString(),
            branding_id: brand.asset_id,
          };
        }
      }
    }
  }

  onCustomBlackoutStartChange(): void {
    const blackout_window = this.message.sms.blackout_settings.blackout_window;
    if (blackout_window.start_time === blackout_window.end_time) {
      blackout_window.end_time = this.clientService.getNextBlackoutHour(
        blackout_window.start_time,
      );
    }
  }

  onCustomBlackoutEndChange(): void {
    const blackout_window = this.message.sms.blackout_settings.blackout_window;
    if (blackout_window.start_time === blackout_window.end_time) {
      blackout_window.start_time = this.clientService.getPreviousBlackoutHour(
        blackout_window.end_time,
      );
    }
  }

  addCollapsibleControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [Validators.required, CustomValidators.nonSpace],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    this.addFormControl(t, !!action['primary']);
  }

  addIWCUControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required],
      CustomValidators.iFCURestricionValidator(
        this.newFeed.enabled,
        this.numberOfIFCUActions,
      ),
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const openConsentTCsValue: boolean = action['openConsentTCs'] || false;
    const openConsentTCsControl: UntypedFormControl = new UntypedFormControl(
      openConsentTCsValue,
      [Validators.required],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    this.addFormControl(t, !!action['primary']);
    t.addControl('openConsentTCs', openConsentTCsControl);
  }

  addLinkControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [
        Validators.required,
        CustomValidators.nonSpace,
        CustomValidators.urlWithQueryValidator,
      ],
    );
    const open_in_new_tab_value: boolean = action['open_in_new_tab'] || false; // this key was added, and may not exist on all existing hyperlink actions.  Defaulting to false.
    const open_in_new_tab_control: UntypedFormControl = new UntypedFormControl(
      open_in_new_tab_value,
      [Validators.required],
    );

    const nonDepositPopUpEnabled_value: boolean =
      action['nonDepositPopUpEnabled'] || false; // this key was added, and may not exist on all existing hyperlink actions.  Defaulting to false.
    const nonDepositPopUpEnabled_control: UntypedFormControl =
      new UntypedFormControl(nonDepositPopUpEnabled_value, [
        Validators.required,
      ]);
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    t.addControl('open_in_new_tab', open_in_new_tab_control);
    t.addControl('nonDepositPopUpEnabled', nonDepositPopUpEnabled_control);
    this.addFormControl(t, !!action['primary']);
  }

  addCallControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [
        Validators.required,
        CustomValidators.nonSpace,
        Validators.maxLength(30),
        CustomValidators.phoneNumberValidator,
      ],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    this.addFormControl(t, !!action['primary']);
  }

  addPlaidControl(action: Object): void {
    const label: UntypedFormControl = new UntypedFormControl(action['label'], [
      Validators.required,
    ]);
    if (this.newFeed.enabled) {
      label.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const disclaimer_header: UntypedFormControl = new UntypedFormControl(
      action['disclaimer_header'],
      [Validators.required],
    );
    const disclaimer_body: UntypedFormControl = new UntypedFormControl(
      action['disclaimer_body'],
      [Validators.required],
    );
    const disclaimer_check_label: UntypedFormControl = new UntypedFormControl(
      action['disclaimer_check_label'],
      [Validators.required],
    );
    const disclaimer_submit_label: UntypedFormControl = new UntypedFormControl(
      action['disclaimer_submit_label'],
      [Validators.required],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label);
    t.addControl('disclaimer_header', disclaimer_header);
    t.addControl('disclaimer_body', disclaimer_body);
    t.addControl('disclaimer_check_label', disclaimer_check_label);
    t.addControl('disclaimer_submit_label', disclaimer_submit_label);
    this.addFormControl(t, !!action['primary']);
  }

  /** Creates an action for opting in by email
   * action['dc'] contains an array of form groups split out by
   * [0] - The data capture email group used to capture the customers email address
   * [1] - The data capture consent used to capture if the customer accepted or declined.
   */
  addEmailDataCaptureControl(action: Object): void {
    const dataCapture = new UntypedFormGroup({});
    const emailDataCapture = new UntypedFormArray([]);
    const dataCaptureEmail = new UntypedFormGroup({});
    const dataCaptureConsent = new UntypedFormGroup({});

    dataCapture.addControl(
      'label',
      new UntypedFormControl(action['label'], [Validators.required]),
    );
    if (this.newFeed.enabled) {
      dataCapture.controls['label'].addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    dataCapture.addControl(
      'header',
      new UntypedFormControl(action['header'], [Validators.required]),
    );
    dataCapture.addControl(
      'body',
      new UntypedFormControl(action['body'], [Validators.required]),
    );
    dataCapture.addControl(
      'success_text',
      new UntypedFormControl(action['success_text'], [Validators.required]),
    );
    dataCapture.addControl(
      'failure_text',
      new UntypedFormControl(action['failure_text'], [Validators.required]),
    );

    const [dataCaptureEmailAction, dataCaptureConsentAction] = action['dc'];
    dataCaptureEmail.addControl(
      'dc_id',
      new UntypedFormControl(dataCaptureEmailAction.dc_id, [
        Validators.required,
      ]),
    );
    dataCaptureEmail.addControl(
      'dc_label',
      new UntypedFormControl(dataCaptureEmailAction.dc_label, [
        Validators.required,
      ]),
    );
    dataCaptureEmail.addControl(
      'dc_verify',
      new UntypedFormControl(dataCaptureEmailAction.dc_verify, [
        Validators.required,
      ]),
    );
    dataCaptureEmail.addControl(
      'dc_verify_label',
      new UntypedFormControl(dataCaptureEmailAction.dc_verify_label, [
        Validators.required,
      ]),
    );
    dataCaptureEmail.addControl(
      'dc_type',
      new UntypedFormControl(dataCaptureEmailAction.dc_type),
    );

    dataCaptureConsent.addControl(
      'dc_id',
      new UntypedFormControl(dataCaptureConsentAction.dc_id, [
        Validators.required,
      ]),
    );
    dataCaptureConsent.addControl(
      'dc_accept_label',
      new UntypedFormControl(dataCaptureConsentAction.dc_accept_label, [
        Validators.required,
      ]),
    );
    dataCaptureConsent.addControl(
      'dc_decline_label',
      new UntypedFormControl(dataCaptureConsentAction.dc_decline_label, [
        Validators.required,
      ]),
    );
    dataCaptureConsent.addControl(
      'dc_type',
      new UntypedFormControl(dataCaptureConsentAction.dc_type),
    );

    // add data capture elements
    dataCapture.addControl('dc', emailDataCapture);
    emailDataCapture.push(dataCaptureEmail);
    emailDataCapture.push(dataCaptureConsent);

    const dataCaptureVerify = dataCaptureEmail.get('dc_verify');
    const verifyLabel = dataCaptureEmail.get('dc_verify_label');
    //initialize verify label is enabled/disabled if dc_verify is true/false
    dataCaptureVerify.value ? verifyLabel.enable() : verifyLabel.disable();

    //subscribe to dataCptureVerify checkbox to toggle verify label
    dataCaptureVerify.valueChanges.subscribe((val) => {
      val ? verifyLabel.enable() : verifyLabel.disable();
    });

    this.addFormControl(dataCapture, !!action['primary']);
  }

  addTwoWayControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [Validators.required, CustomValidators.nonSpace],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    this.addFormControl(t, !!action['primary']);
  }

  addAppleChatControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const apple_chat_business_id: UntypedFormControl = new UntypedFormControl(
      action['apple_chat_business_id'],
      [Validators.required, CustomValidators.nonSpace],
    );
    const apple_chat_biz_intent_id: UntypedFormControl = new UntypedFormControl(
      action['apple_chat_biz_intent_id'],
      [CustomValidators.nonSpace],
    );
    const apple_chat_biz_group_id: UntypedFormControl = new UntypedFormControl(
      action['apple_chat_biz_group_id'],
      [CustomValidators.nonSpace],
    );
    const apple_chat_phone: UntypedFormControl = new UntypedFormControl(
      action['apple_chat_phone'],
      [
        Validators.required,
        CustomValidators.nonSpace,
        Validators.maxLength(30),
        CustomValidators.phoneNumberValidator,
      ],
    );
    const apple_chat_body: UntypedFormControl = new UntypedFormControl(
      action['apple_chat_body'],
      [CustomValidators.nonSpace],
    );

    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('apple_chat_business_id', apple_chat_business_id);
    t.addControl('apple_chat_biz_intent_id', apple_chat_biz_intent_id);
    t.addControl('apple_chat_biz_group_id', apple_chat_biz_group_id);
    t.addControl('apple_chat_phone', apple_chat_phone);
    t.addControl('apple_chat_body', apple_chat_body);
    this.addFormControl(t, !!action['primary']);
  }

  addFormInfoCaptureControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [
        Validators.required,
        CustomValidators.nonSpace,
        CustomValidators.digitsOnly,
      ],
    );
    const openWithinMessageValue: boolean =
      action['openWithinMessage'] || false;
    const openWithinMessageControl: UntypedFormControl = new UntypedFormControl(
      openWithinMessageValue,
      [Validators.required],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    t.addControl('openWithinMessage', openWithinMessageControl);
    this.addFormControl(t, !!action['primary']);
  }

  addEmailControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const value_control: UntypedFormControl = new UntypedFormControl(
      action['value'],
      [
        Validators.required,
        CustomValidators.nonSpace,
        CustomValidators.emailActionValidator,
      ],
    );
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    t.addControl('value', value_control);
    this.addFormControl(t, !!action['primary']);
  }

  addMessageControl(action: Object): void {
    const label_control: UntypedFormControl = new UntypedFormControl(
      action['label'],
      [Validators.required, CustomValidators.nonSpace],
    );
    if (this.newFeed.enabled) {
      label_control.addValidators(
        Validators.maxLength(
          action['primary']
            ? CharacterLimits.ActionLabelCharLimitPrimary
            : CharacterLimits.ActionLabelCharLimitSecondary,
        ),
      );
    }
    const t = new UntypedFormGroup({});
    t.addControl('label', label_control);
    this.addFormControl(t, !!action['primary']);
  }

  addDisclaimerControl(action: Object): void {
    const disclaimerControl: UntypedFormControl = new UntypedFormControl(
      action['value'],
      this.isMsgLevelDisclaimerEnabled && this.isDisclaimerOn === 'on'
        ? [
            Validators.maxLength(
              CharacterLimits.MessageLevelDisclaimerCharLimit,
            ),
            Validators.required,
          ]
        : [],
    );

    /**
     * this formControl is not used/synced with any input. It is a tag to check where we need to add within this.actionFormArray
     * @see addFormControl
     */
    const disclaimerType: UntypedFormControl = new UntypedFormControl(
      'disclaimer',
    );
    const t = new UntypedFormGroup({});
    t.addControl('value', disclaimerControl);
    t.addControl('type', disclaimerType);
    this.addFormControl(t);
  }
  /**
   * This function is used to add action to either at the top or bottom of actionFormArray list
   * Primary -> Top
   * Non-primary -> Last
   * @param newFormControl a new formGroup to be added to actionFormArray
   * @param primary flag if the formGroup is associated to the action that is being added is Primary or not
   */
  addFormControl(newFormControl: UntypedFormGroup, primary: boolean = false) {
    if (primary) {
      this.actionFormArray.insert(0, newFormControl);
    } else {
      const disclaimerFormGroup = this.actionFormArray.controls.find(
        (actionFormGroup: UntypedFormGroup) =>
          actionFormGroup.controls['type'] &&
          actionFormGroup.controls['type'].value === 'disclaimer',
      );
      if (disclaimerFormGroup) {
        this.actionFormArray.insert(
          this.actionFormArray.length - 1,
          newFormControl,
        );
      } else {
        this.actionFormArray.push(newFormControl);
      }
    }
  }

  /**
   * This function is used to add action to either at the top or bottom of
   * action list
   * Primary -> Top
   * Non-primary -> Last
   * @param newAction an action to be added to message.wire.actions
   * @param primary flag if the action to be added is a primary action
   */
  addAction(newAction: any, primary: boolean = false) {
    if (primary) {
      this.message.wire.actions = [newAction, ...this.message.wire.actions];
    } else if (this.disclaimerAction) {
      this.message.wire.actions.splice(
        this.message.wire.actions.length - 1,
        0,
        newAction,
      );
    } else {
      this.message.wire.actions.push(newAction);
    }
  }

  getTooltipData(errors: Object): string | void {
    return this.actionService.getTooltipData(errors);
  }

  deleteOrphans(msg) {
    const components = this.journey.draft.components.filter(
      (c) => c.type === 'MessageResponse' && c.parent === msg.name,
    );
    if (components.length) {
      components.forEach((v) => {
        this.deleteMessage(v);
      });
    }
  }

  deleteMessage(msg) {
    const componentIndex = this.journey.draft.components.findIndex(
      (c) => c.name === msg.name,
    );
    if (componentIndex >= 0) {
      this.journey.draft.components.splice(componentIndex, 1);
      this.deleteOrphans(msg);
    }
  }

  deleteAction(action: any, index: number) {
    if (this.message.wire.actions[index].type === 'message') {
      // Delete the component for a message action
      const component = this.journey.draft.components.find(
        (c) => c.name === action.value,
      );
      this.deleteMessage(component);
    }

    MessageBuilderComponent.fadeOutMessageAction(index);

    setTimeout(() => {
      this.message.wire.actions.splice(index, 1);
      this.rebindActionsForDelete();
    }, 600);
  }

  moveActionUp(action: any, index: number) {
    const newIndex = this.nextMoveUpActionIndex(action, index);
    if (index > 0 && newIndex > -1) {
      MessageBuilderComponent.fadeOutMessageAction(index);
      MessageBuilderComponent.fadeOutMessageAction(newIndex);

      setTimeout(() => {
        this.rebindActionsForMove(index, newIndex);
      }, 600);
    }
  }

  moveActionDown(action: any, index: number) {
    const newIndex = this.nextMoveDownActionIndex(action, index);
    const numberOfActions = this.message.wire.actions.length;
    if (index < numberOfActions - 1 && newIndex < numberOfActions) {
      MessageBuilderComponent.fadeOutMessageAction(index);
      MessageBuilderComponent.fadeOutMessageAction(newIndex);

      setTimeout(() => {
        this.rebindActionsForMove(index, newIndex);
      }, 600);
    }
  }

  get numberOfIFCUActions(): number {
    return this.message.wire.actions.filter(
      (action) => action.type === 'consent_upgrade',
    ).length;
  }

  buildActionControls(message: MessageClass) {
    this.actionFormArray = new UntypedFormArray([]);
    for (let i = 0; i < message.wire.actions.length; i++) {
      const action = this.message.wire.actions[i];
      if (action.type === 'hyperlink') {
        this.addLinkControl(action);
      }
      if (action.type === 'call') {
        this.addCallControl(action);
      }
      if (action.type === 'collapsible') {
        this.addCollapsibleControl(action);
      }
      if (action.type === 'message') {
        this.addMessageControl(action);
      }
      if (action.type === 'email') {
        this.addEmailControl(action);
      }
      if (action.type === 'consent_upgrade') {
        this.addIWCUControl(action);
      }
      if (action.type === 'two_way') {
        this.addTwoWayControl(action);
      }
      if (action.type === 'email_data_capture') {
        this.addEmailDataCaptureControl(action);
      }
      if (action.type === 'plaid') {
        this.addPlaidControl(action);
      }
      if (action.type === 'apple_chat') {
        this.addAppleChatControl(action);
      }
      if (action.type === 'form_info_capture') {
        this.addFormInfoCaptureControl(action);
      }
      if (action.type === 'disclaimer') {
        this.addDisclaimerControl(action);
      }
    }
  }

  checkIWCU(): void {
    if (!this.hasIWCU && this.iwcuHelperService.messageHasIWCU(this.message)) {
      this.iwcuWarning.showMessage(
        'Saving this message will require removing consent upgrade actions. If you would like to keep these actions, please contact your Client Success Manager.',
      );
    } else {
      this.saveMessage();
    }
  }

  cleanIWCUAndSave(): void {
    this.iwcuHelperService.cleanIWCU(this.productGroup, this.message);
    this.saveMessage();
  }

  prepMessageForSave(): void {
    this.journey.draft.updated_at = new Date();
    this.message.date_modified = new Date();
    this.message.wire.actions.map((f) => {
      f.show = false;
    });
    this.message.wire.actions.map((action) => {
      if (action.type === 'consent_upgrade') {
        action.value = this.productGroup.consent.in_wire_upgrade.ts_cs;
      }
    });
    this.message.wire.text = this.removeAutoCompleteSpans(
      this.message.wire.text,
    );

    if (this.message.sms.auth_link.length !== 0) {
      this.message.sms.text = `${
        this.message.sms.body
      } ${this.smsService.getMappedAuthLink()} ${this.message.sms.disclaimer}`;
    } else {
      this.message.sms.text = `${this.message.sms.body} ${this.message.sms.disclaimer}`;
    }
    this.journey.draft.updated_at = new Date();
    const renumberer = new Renumberer(this.journey);
    renumberer.renumber(renumberer.families[0], 0);

    // If it's MessageResponse, remove the following fields
    if (this.message.type === 'MessageResponse') {
      delete this.message.message_expiration;
      delete this.message.message_level_branding;
      delete this.message.fdic;
    }
  }

  findName(idToFind: string, arrayOfTypes: Outcome[] | ExperienceType[]) {
    return arrayOfTypes.find((item) => item.id === idToFind).title['en-us'];
  }

  saveMessage() {
    // if either form is invalid, mark it as touched so that error indicators show
    if (!this.messageGroup.valid || !this.actionFormArray.valid) {
      CustomValidators.markAllAsTouched(this.messageGroup);
      CustomValidators.markAllAsTouched(this.actionFormArray);
    }

    this.prepMessageForSave();

    let sub;
    sub = this.journeyService.updateJourney(this.journeyId, this.journey);
    sub.subscribe(
      () => {
        let inputs = InputParamUtil.findInputParametersForObject(this.message);
        this.clientService
          .getClient(this.currentClient.id)
          .subscribe((client) => {
            inputs = inputs
              .filter((inputTag) => inputTag.startsWith('input_'))
              .filter(
                (inputTag) =>
                  client.ext_dynamic_fields.indexOf(inputTag) === -1,
              );
            if (inputs.length) {
              client.ext_dynamic_fields =
                client.ext_dynamic_fields.concat(inputs);
              if (
                this.ps.checkPermissions(
                  this.permissions.client_edit_ext_fields,
                )
              ) {
                this.clientService
                  .updateClientPartial(
                    client.id,
                    'ext_dynamic_fields',
                    client.ext_dynamic_fields,
                  )
                  .subscribe();
              }
            }
          });
        this.navigateBackToJourneyBuilder();
      },
      (error: HttpErrorResponse) => {
        const errBody = error.error;
        if (
          error.status === 409 &&
          errBody.response &&
          errBody.response.indexOf('Mismatch') > -1
        ) {
          this.messageDialogWithKickback.showMessage(
            'The experience could not be promoted because a new version is available.',
          );
        } else if (error.status === 409 && errBody.message && errBody.message) {
          this.messageDialogWithKickback.showMessage(error.message);
        } else if (error.status !== 401) {
          this.messageDialogWithKickback.showMessage(
            `Oops...The experience could not be updated: ${error.error.response}`,
          );
        }
      },
    );
  }

  removeAutoCompleteSpans(text): string {
    return this.actionService.removeAutoCompleteSpans(text);
  }

  navigateBackToJourneyBuilder() {
    this.router.navigateByUrl(
      `/cx-builder/experience-builder/${this.journeyId}`,
    );
  }

  checkInputParams(messageGroup: UntypedFormGroup): void {
    const wireInputParams = InputParamUtil.findInputParametersForObject(
      this.message.wire,
    ).filter((input) => input !== 'CCID');

    this.message.sms.text = ''; // Erase prior concatenated text, just use intro, body, etc.
    const smsInputParams = InputParamUtil.findInputParametersForObject(
      this.message.sms,
    ).filter((input) => input !== 'CCID');

    if (
      this.message.wire.is_shown === 'true' &&
      wireInputParams.length > 0 &&
      (!this.message.wire.is_alternate_message ||
        this.message.wire.alternate_text.trim().length === 0)
    ) {
      this.inputWarningMessageDialog.showMessage(this.inputWarningText);
    } else if (
      this.message.sms.is_shown === 'true' &&
      smsInputParams.length > 0 &&
      (!this.message.sms.is_alternate_message ||
        this.message.sms.alternate_text.trim().length === 0)
    ) {
      this.inputWarningMessageDialog.showMessage(this.inputWarningText);
    } else {
      this.submitForm(messageGroup);
    }
  }

  /**
   * This is the main entry point for all saving and validation logic.
   * Generally, if there's a sneaky validation error at play, it's going to be
   * visible at this point. When debugging validation, setting a breakpoint
   * here is invaluable and will typically point out the problematic control.
   * @param messageGroup
   */
  async submitForm(messageGroup: any): Promise<void> {
    const messageControls = messageGroup.controls;
    messageControls['message_text'].setValue(
      this.removeAutoCompleteSpans(messageControls['message_text'].value),
    );

    for (const control in messageControls) {
      if (
        !messageControls.hasOwnProperty(control) &&
        !messageControls[control].value
      ) {
        continue;
      }
      messageControls[control].markAsDirty();
      messageControls[control].markAsTouched();
      this.isInputFormatted(messageControls[control]);
    }
    // if custom message expiration amount wasn't touched
    this.feedMessageExpirationAmountCtl.markAsTouched();

    CustomValidators.markAllAsTouched(this.actionFormArray);
    _.forEach(this.actionFormArray.controls, (control: UntypedFormGroup) => {
      this.isInputFormatted(control);
    });

    // if user is able to select outcome and experience type, set the appropriate outcome and experience type name for Elasticsearch
    if (this.showOutcomeExpTypeDropdowns) {
      const outcomeId = this.outcomeCtl.value;
      const experienceTypeId = this.experienceTypeCtl.value;
      this.message.outcome_id = outcomeId;
      this.message.experience_type_id = experienceTypeId;

      if (outcomeId === '') {
        // no experience type should be set unless an outcome is set
        this.message.outcome_name = '';
        this.message.experience_type_name = '';
      } else {
        this.message.outcome_name = this.findName(outcomeId, this.outcomes);
        if (experienceTypeId === '') {
          this.message.experience_type_name = '';
        } else {
          this.message.experience_type_name = this.findName(
            experienceTypeId,
            this.experienceTypes,
          );
        }
      }
    }

    if (this.newFeed.enabled) {
      this.setMessageExpirationOnSave();
    }

    if (messageGroup.valid && this.actionFormArray.valid) {
      this.checkIWCU();
    } else {
      this.focusOnError();
    }
  }

  setMessageExpirationOnSave() {
    if (this.feedMessageExpirationTypeCtl.value === 'custom') {
      this.message.message_expiration = {
        unit: this.feedMessageExpirationUnitCtl.value,
        amount: this.feedMessageExpirationAmountCtl.value,
        type: 'custom',
      };
    }
    if (this.feedMessageExpirationTypeCtl.value === 'none') {
      this.message.message_expiration = {
        unit: null,
        amount: null,
        type: 'none',
      };
    }
  }

  setMessageExpiration(type: string) {
    if (type === 'none') {
      this.feedMessageExpirationCtl.setValue({
        unit: null,
        amount: null,
        type: 'none',
      });
    }
    if (type === 'custom') {
      this.feedMessageExpirationCtl.setValue({
        unit: 'days',
        amount: null,
        type: 'custom',
      });
    }
  }

  focusOnError() {
    setTimeout(() => {
      const firstErrorMsg = document.getElementsByClassName('has-error')[0];
      if (firstErrorMsg) {
        firstErrorMsg.scrollIntoView(false);
      }
    }, 0);
  }

  setBlackout(state: string) {
    if (state === 'default') {
      this.message.sms.blackout_settings.blackout_window.start_time =
        this.currentClient.blackout_window.start_time;
      this.message.sms.blackout_settings.blackout_window.end_time =
        this.currentClient.blackout_window.end_time;
      this.message.sms.blackout_settings.blackout_type = 'default';
    }
    if (state === 'none') {
      this.message.sms.blackout_settings.blackout_type = 'none';
    }
    if (state === 'custom') {
      this.message.sms.blackout_settings.blackout_window.start_time =
        this.currentClient.blackout_window.start_time;
      this.message.sms.blackout_settings.blackout_window.end_time =
        this.currentClient.blackout_window.end_time;
      this.message.sms.blackout_settings.blackout_type = 'custom';
    }
  }

  isBlackoutType(type: string): boolean {
    return this.message.sms.blackout_settings.blackout_type === type;
  }

  isMessageExpirationType(type: 'default' | 'custom' | 'none'): boolean {
    return this.feedMessageExpirationTypeCtl.value === type;
  }

  weekendSwitchesAreSet() {
    const blackoutWindow = this.message.sms.blackout_settings.blackout_window;
    return !(
      blackoutWindow.include_saturday == null ||
      blackoutWindow.include_sunday == null
    );
  }

  addBrand() {
    this.showAddAssetDialog = true;
    this.mediaAssetCanSelect = false;
    this.addMediaAssetType = ['brand'];
  }

  addMediaAsset() {
    this.showAddAssetDialog = true;
    this.mediaAssetCanSelect = true;
    this.addMediaAssetType = ['image', 'video'];
  }

  removeMediaAsset() {
    if (!this.newFeed.enabled) {
      this.messageGroup.controls['message_text'].setValidators([
        Validators.required,
        CustomValidators.nonSpace,
        Validators.maxLength(CharacterLimits.MessageTextCharLimitWire),
      ]);
    }
    this.message.wire.media_type = undefined;
    this.message.wire.media_id = undefined;
    this.message.wire.image_url = undefined;
    this.message.wire.video_url = undefined;
  }

  onMediaAssetAdded(mediaAsset: MediaAsset) {
    if (!this.newFeed.enabled) {
      this.messageGroup.controls['message_text'].clearValidators();
    }
    this.showAddAssetDialog = false;
    if (mediaAsset.asset_type === 'image') {
      this.message.wire.media_id = mediaAsset.asset_id;
      this.message.wire.media_type = mediaAsset.asset_type;
      this.message.wire.image_url = mediaAsset.s3_url;
    } else if (mediaAsset.asset_type === 'brand') {
      this.brands.push(mediaAsset);
      if (!this.newFeed.enabled) {
        this.message.wire.branding = mediaAsset.asset_id;
        this.previousBrandId = this.message.wire.branding;
        this.productGroup.branding.icon_s3_url = mediaAsset.s3_url;
        this.productGroup.name = mediaAsset.shortname;
      } else {
        this.feedMessageLevelBrandingCtl.setValue({
          brand_name: mediaAsset.shortname,
          branding_logo: AwsUtils.removeHostName(mediaAsset.s3_url),
          hex_code: mediaAsset.branding_color,
          timestamp: new Date().toDateString(),
          branding_id: mediaAsset.asset_id,
        });
        this.message.message_level_branding = {
          brand_name: mediaAsset.shortname,
          branding_logo: AwsUtils.removeHostName(mediaAsset.s3_url),
          hex_code: mediaAsset.branding_color,
          timestamp: new Date().toDateString(),
          branding_id: mediaAsset.asset_id,
        };
      }
    } else if (mediaAsset.asset_type === 'video') {
      this.message.wire.media_type = mediaAsset.asset_type;
      this.message.wire.video_url = mediaAsset.url;
      this.message.wire.media_id = mediaAsset.asset_id;
    }
  }

  onMediaAssetSelected(mediaAsset: MediaAsset) {
    this.onMediaAssetAdded(mediaAsset);
  }

  onCloseAddMediaAssetDialog() {
    this.showAddAssetDialog = false;
    this.message.wire.branding = this.previousBrandId;
  }

  setupDraft() {
    if (!this.journey.draft) {
      this.journey.draft = Object.assign(
        new JourneyContent(),
        this.journey.live,
      );
      this.message = this.journey.draft.components.find(
        (f) => f.name === this.messageId,
      );
    }
  }

  blackoutWindowExists(): boolean {
    return this.message.sms.blackout_settings.hasBlackoutWindow();
  }

  actionById(index: number, action: any): string {
    return action.id + index;
  }

  private checkConsents(): void {
    this.clientService
      .getProductGroupsByClient(
        this.currentClient.id,
        100,
        0,
        new Ordering('created_at'),
        true,
      )
      .subscribe(
        (productGs: ProductGroup[]) => {
          _.each(productGs, (pg: ProductGroup) => {
            if (pg.consent.in_wire_upgrade.enabled) {
              this.hasIWCU = true;
            }
          });
        },
        (err) =>
          this.messageDialogNoKickback.showMessage(
            `Oops...The experience could not be updated: ${err['response']}`,
          ),
      );
  }

  private rebindActionsForDelete() {
    this.message.wire.actions = _.cloneDeep(this.message.wire.actions);

    this.buildActionControls(this.message);
  }

  private rebindActionsForMove(index: number, newIndex: number) {
    const actions: any[] = _.cloneDeep(this.message.wire.actions);
    const sourceAction = _.cloneDeep(this.message.wire.actions[index]);
    const targetAction = _.cloneDeep(this.message.wire.actions[newIndex]);

    this.message.wire.actions = actions;
    this.message.wire.actions[index] = targetAction;
    this.message.wire.actions[newIndex] = sourceAction;

    this.buildActionControls(this.message);
  }

  private initTinyEditorConfig() {
    this.tinyEditorConfig = new TinyEditorConfig();
    this.tinyEditorConfig.includeMentionsAutoComplete = true;
    this.tinyEditorConfig.includeAccountMenu = true;
    this.tinyEditorConfig.includeInputsMenu = true;
    this.tinyEditorConfig.accountMenuList =
      this.tinyEditorService.accountMenuList;
    this.tinyEditorConfig.inputsMenuList =
      this.tinyEditorService.inputsMenuList;
  }

  private isInputFormatted(control) {
    let valid = true;
    const inputs = InputParamUtil.findInputParametersForObject({
      v: control.value,
    });
    inputs.forEach((i) => {
      if (
        i.indexOf('input_') === -1 &&
        i.indexOf('account_') === -1 &&
        i.indexOf('ext_') === -1
      ) {
        valid = false;
      }
    });
    if (!valid) {
      control.setErrors(
        "Custom inputs must start with 'input_' (ex: @{input_my-custom-input})",
      );
    }
  }

  /**
   * This function takes in a string value and add an action to the chain with tag (primary:true)
   * using the existing funtionality of adding actions
   * @param actionType type of action to add
   * @returns
   */
  addPrimaryAction(actionType: string) {
    if (!this.isAbleToAddPrimaryAction) {
      return;
    }

    switch (actionType) {
      case 'hyperlink':
        this.addLinkElement(true);
        break;
      case 'call':
        this.addCallElement(true);
        break;
      case 'email':
        this.addEmailElement(true);
        break;
      case 'message':
        this.addMessageElement(true);
        break;
      case 'two_way':
        this.addTwoWayElement(true);
        break;
      case 'form_info_capture':
        this.addFormInfoCaptureElement(true);
        break;
      case 'consent_upgrade':
        this.addIWCUElement(true);
        break;

      default:
        break;
    }
  }

  get isAbleToAddPrimaryAction(): boolean {
    return !this.primaryAction;
  }

  get primaryAction() {
    if (this.message.wire.actions.length === 0) {
      return undefined;
    }
    const firstAction = this.message.wire.actions[0];
    return firstAction.primary ? firstAction : undefined;
  }

  isActionTypeSupportedInNewFeed(type: string): boolean {
    return !UnSupportedSecondaryActionFeature.includes(type);
  }

  /**
   * This function calculates the next index to move an action up when
   * the user click "Move Up"
   * @param action an action object (used to determine if it's Primary)
   * @param index index of the action on the list
   * @returns the next possible index to move up (return -1 if moving up is not possible)
   */
  nextMoveUpActionIndex(action, index): number {
    if (!this.newFeed.enabled) {
      return index > 0 ? index - 1 : -1;
    }

    // primary action does not move
    if (action.primary) {
      return -1;
    }

    let newIndex = index - 1;

    while (newIndex >= 0) {
      if (
        !this.message.wire.actions[newIndex].primary &&
        !UnSupportedSecondaryActionFeature.includes(
          this.message.wire.actions[newIndex].type,
        )
      ) {
        break;
      }
      newIndex -= 1;
    }

    return newIndex;
  }

  /**
   * This function calculates the next index to move an action down when
   * the user click "Move Down"
   * @param action an action object (used to determine if it's Primary)
   * @param index index of the action on the list
   * @returns the next possible index to move down (return the length of the list if moving down is not possible)
   */
  nextMoveDownActionIndex(action, index): number {
    const numbeOfActions = this.message.wire.actions.length;

    if (!this.newFeed.enabled) {
      return index < numbeOfActions - 1 ? index + 1 : numbeOfActions;
    }

    // primary action does not move
    if (action.primary) {
      return numbeOfActions;
    }

    let newIndex = index + 1;

    while (newIndex < numbeOfActions) {
      if (
        !this.message.wire.actions[newIndex].primary &&
        this.message.wire.actions[newIndex].type !== 'disclaimer' &&
        !UnSupportedSecondaryActionFeature.includes(
          this.message.wire.actions[newIndex].type,
        )
      ) {
        break;
      }
      newIndex += 1;
    }

    return newIndex;
  }
}
