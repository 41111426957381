import React from 'react';
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid';
import {
  StyledHeadCellOverride,
  ButtonGroupOverride,
  ButtonGroupFirstButtonOverride,
  ButtonGroupFirstButtonActiveOverride,
  ButtonGroupLastButtonActiveOverride,
  ButtonGroupLastButtonOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import {
  BodyCell,
  RowContainer,
} from '@app/react-baseweb/styled-containers/styledContainers';
import {
  DocumentationContainer,
  DocumentationText,
} from '@app/api-mgmt/components/push-api/styles/PushAPIHomeStyles';
import {
  Integration,
  ModifiableIntegration,
} from '@app/api-mgmt/components/push-api/types/push-api-models';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { DisplayAction } from './Actions';
import { ButtonGroup } from 'baseui/button-group';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';
import { PushAPIService } from '@app/api-mgmt/components/push-api/push-api-service';

const integrationTypeLabels = {
  'no-auth': 'Custom',
  oauth: 'OAuth',
  'basic-auth': 'Basic Authentication',
  sfdc: 'Salesforce Unmanaged Package',
  'sfdc-app-exchange': 'Salesforce App Exchange Package',
  sfmc: 'Salesforce Marketing Cloud',
};

const IntegrationRow = ({
  integration,
  editIntegrationClicked,
  toggleIntegrationClicked,
  deleteIntegrationClicked,
  auditIntegrationClicked,
  eventFiltersClicked,
}: {
  integration: Integration;
  editIntegrationClicked: (newIntegration: Integration) => void;
  toggleIntegrationClicked: (newIntegration: Integration) => void;
  deleteIntegrationClicked: (newIntegration: Integration) => void;
  auditIntegrationClicked: (newIntegration: Integration) => void;
  eventFiltersClicked: (newIntegration: Integration) => void;
}) => {
  const [css] = useStyletron();
  const [sfmcEventList, setSfmcEventList] = React.useState<string[]>([]);
  const [sfdcEventList, setSfdcEventList] = React.useState<string[]>([]);
  const [sfdcAppExchangeEventList, setSfdcAppExchangeEventList] =
    React.useState<string[]>([]);

  React.useEffect(() => {
    PushAPIService.getEventSubscriptionList('sfmc').then((fetchedEvents) => {
      setSfmcEventList(fetchedEvents);
    });

    PushAPIService.getEventSubscriptionList('sfdc').then((fetchedEvents) => {
      setSfdcEventList(fetchedEvents);
    });

    PushAPIService.getEventSubscriptionList('sfdc-app-exchange').then(
      (fetchedEvents) => {
        setSfdcAppExchangeEventList(fetchedEvents);
      },
    );
  }, []);

  return (
    <>
      <div
        role="row"
        onClick={() => editIntegrationClicked(integration)}
        className={css({ display: 'contents', cursor: 'pointer' })}
      >
        <RowContainer>
          <BodyCell>{integration.name}</BodyCell>
          <BodyCell>
            {integrationTypeLabels[integration.type] ?? 'Legacy'}
          </BodyCell>
          <BodyCell>
            {integration.type === 'sfdc' &&
              sfdcEventList.map((event) => {
                return <div key={event}> {event} </div>;
              })}
            {integration.type === 'sfdc-app-exchange' &&
              sfdcAppExchangeEventList.map((event) => {
                return <div key={event}> {event} </div>;
              })}
            {integration.type === 'sfmc' &&
              sfmcEventList.map((event) => {
                return <div key={event}> {event} </div>;
              })}
            {integration.type !== 'sfdc' &&
              integration.type !== 'sfdc-app-exchange' &&
              integration.type !== 'sfmc' &&
              ((integration as { eventlist: string[] }).eventlist ?? []).map(
                (event) => {
                  return <div key={event}> {event} </div>;
                },
              )}
          </BodyCell>
          <BodyCell>
            {integration.type !== 'other' && (
              <ButtonGroup
                onClick={(e) => {
                  e.stopPropagation();
                  toggleIntegrationClicked(integration);
                }}
                overrides={{ Root: ButtonGroupOverride }}
              >
                <Button
                  overrides={{
                    BaseButton: (integration as { active: boolean }).active
                      ? ButtonGroupFirstButtonOverride
                      : ButtonGroupFirstButtonActiveOverride,
                  }}
                >
                  OFF
                </Button>
                <Button
                  overrides={{
                    BaseButton: (integration as { active: boolean }).active
                      ? ButtonGroupLastButtonActiveOverride
                      : ButtonGroupLastButtonOverride,
                  }}
                >
                  ON
                </Button>
              </ButtonGroup>
            )}
          </BodyCell>
          <BodyCell>
            <StatefulTooltip
              accessibilityType={'tooltip'}
              content="Edit"
              placement={PLACEMENT.top}
            >
              <DisplayAction
                onClick={(e) => {
                  e.stopPropagation();
                  editIntegrationClicked(integration);
                }}
              >
                <i className="fa fa-pencil" style={{ fontSize: '18px' }}></i>
              </DisplayAction>
            </StatefulTooltip>
            <StatefulTooltip
              accessibilityType={'tooltip'}
              content="Delete"
              placement={PLACEMENT.top}
            >
              <DisplayAction
                onClick={(e) => {
                  e.stopPropagation();
                  deleteIntegrationClicked(integration);
                }}
              >
                <i className="fa fa-trash" style={{ fontSize: '18px' }}></i>
              </DisplayAction>
            </StatefulTooltip>
            <StatefulTooltip
              accessibilityType={'tooltip'}
              content="Audit trail"
              placement={PLACEMENT.top}
            >
              <DisplayAction
                onClick={(e) => {
                  e.stopPropagation();
                  auditIntegrationClicked(integration);
                }}
              >
                <i
                  className="fa-regular fa-clipboard"
                  style={{ fontSize: '18px' }}
                ></i>
              </DisplayAction>
            </StatefulTooltip>
            {'event_filters' in integration &&
              Object.keys(integration.event_filters).length > 0 && (
                <StatefulTooltip
                  accessibilityType={'tooltip'}
                  content="Event filters"
                  placement={PLACEMENT.top}
                >
                  <DisplayAction
                    onClick={(e) => {
                      e.stopPropagation();
                      eventFiltersClicked(integration);
                    }}
                  >
                    <i
                      className="fa-sharp fa-regular fa-filter-list"
                      style={{ fontSize: '18px' }}
                    ></i>
                  </DisplayAction>
                </StatefulTooltip>
              )}
          </BodyCell>
        </RowContainer>
      </div>
    </>
  );
};

/**
 * A table to display a list of configurations.
 */
export const ConfigurationsDisplay = ({
  configurations,
  editConfigurationClicked,
  toggleConfigurationClicked,
  deleteConfigurationClicked,
  auditConfigurationClicked,
  eventFiltersClicked,
}: {
  configurations: Integration[];
  editConfigurationClicked: (
    selectedIntegration: ModifiableIntegration,
  ) => void;
  toggleConfigurationClicked: (
    selectedIntegration: ModifiableIntegration,
  ) => void;
  deleteConfigurationClicked: (
    selectedIntegration: ModifiableIntegration,
  ) => void;
  auditConfigurationClicked: (
    selectedIntegration: ModifiableIntegration,
  ) => void;
  eventFiltersClicked: (selectedIntegration: ModifiableIntegration) => void;
}) => {
  const [css] = useStyletron();
  return (
    <>
      {!(configurations?.length > 0) && (
        <DocumentationContainer>
          <DocumentationText>
            There are currently no Push API integrations.
          </DocumentationText>
          <DocumentationText>
            Please review our documention to assist with creating new
            integrations.
          </DocumentationText>
          <DocumentationText>
            <ul>
              <li>
                <a href="https://clientdocs.relayzone.com" target="_blank">
                  Relay Integration (Basic Auth, OAuth, API Key)
                </a>
              </li>
              <li>
                <a
                  href="https://salesforcedocs.relayzone.com/#_relay_salesforce_integration"
                  target="_blank"
                >
                  Relay Salesforce Integration
                </a>
              </li>
              <li>
                <a
                  href="https://salesforcedocs.relayzone.com/sfmc/index.html#_relay_sfmc_installation"
                  target="_blank"
                >
                  Relay Salesforce Marketing Cloud Integration
                </a>
              </li>
            </ul>
          </DocumentationText>
        </DocumentationContainer>
      )}
      {configurations.length > 0 && (
        <>
          <StyledTable
            role="grid"
            $gridTemplateColumns="auto auto auto auto auto"
            style={{
              border: 'none',
              paddingRight: '25px',
              paddingLeft: '25px',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <div
              role="row"
              className={css({
                display: 'contents',
              })}
            >
              <StyledHeadCell style={StyledHeadCellOverride}>
                Integration Name
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Type
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Events
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Active
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Actions
              </StyledHeadCell>
            </div>

            {configurations.map((integration) => {
              return (
                <IntegrationRow
                  key={integration.uid}
                  integration={integration}
                  editIntegrationClicked={editConfigurationClicked}
                  toggleIntegrationClicked={toggleConfigurationClicked}
                  deleteIntegrationClicked={deleteConfigurationClicked}
                  auditIntegrationClicked={auditConfigurationClicked}
                  eventFiltersClicked={eventFiltersClicked}
                />
              );
            })}
          </StyledTable>
        </>
      )}
    </>
  );
};
