import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import * as _ from 'lodash';

/************************************************
 * Shared service for utils needed in
 * Control Tags, Messaging, Setup, and globally
 ***********************************************/

@Injectable()
export class TwoWaySharedService {

  constructor(private sessionService: SessionService) {
  }

  showTwoWay(): boolean {
    const client = this.sessionService.getCurrentUsersClient();
    return _.get(client, 'two_way_config.enabled');
  }
}
