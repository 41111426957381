import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TimeOutLocalStorageService } from './timeOutLocalStorage.service';

@Injectable()
export class TimerResetInterceptor implements HttpInterceptor {
  constructor(private timeOutLocalStorageService: TimeOutLocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const relayTimeout = event.headers.get('X-Relay-Timeout');
          if (relayTimeout) {
            this.timeOutLocalStorageService.setTimeoutValue(
              parseInt(relayTimeout),
            );
          }
        }
      }),
    );
  }
}
