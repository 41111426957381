<div class="dialog-background" *ngIf="showWarning">
  <div class="dialog-content timer">
    <i class="fa-regular fa-clock fa-4x timer-clock"></i>
    <h3 class="timer-title">
      <span class="px-2">Your session is about to end.</span>
    </h3>
    <p class="m-0">
      For your security, <strong>we will automatically log you out in:</strong>
    </p>
    <p class="timer-text text-center font-weight-bold p-0 m-0">
      {{ minutesLeft }}:{{ secondsLeft }}
    </p>
    <p>Choose "Stay Logged In" to continue or "Log Out" to end your session.</p>
    <div class="row d-flex justify-content-between align-items-stretch">
      <div class="col-6">
        <button
          class="btn btn-lg btn-outline-secondary btn-block"
          (click)="logOut()"
        >
          Log Out
        </button>
      </div>
      <div class="col-6">
        <button
          class="btn btn-lg btn-primary btn-block"
          (click)="stayLoggedIn()"
        >
          Stay Logged In
        </button>
      </div>
    </div>
  </div>
</div>
<audio #audioControl>
  <source src="/assets/audio/ping-bing.wav" type="audio/wav" />
  <source src="/assets/audio/ping-bing.ogg" type="audio/ogg" />
</audio>
