import { styled } from 'baseui';

export const TitleContainer = styled('h2', {
  color: '#5b5a59',
  marginTop: '25px',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31px',
  paddingRight: '25px',
  paddingLeft: '25px',
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const SubTitleContainer = styled('h3', {
  color: '#5b5a59',
  marginTop: '25px',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '31px',
  paddingRight: '25px',
  paddingLeft: '25px',
  marginRight: 'auto',
  marginLeft: 'auto',
});

export const ContentContainer = styled('div', {
  color: '#908e8f',
  fontSize: '16px',
  fontWeight: '400',
  padding: '0px 25px 25px 25px',
});

export const ReportHomeContainer = styled('div', { 
  padding: '25px 50px 0px 50px',
});

export const ReportHeaderContainer = styled('div', {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '400',
  maxWidth: '900px'
});

export const FilterComponentsRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  marginBottom: '10px',
});

export const TableInfoRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  marginBottom: '10px',
  justifyContent: 'space-between',
});

export const SearchContainer = styled('div', {
  width: '280px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const CalendarContainer = styled('div', {
  width: '200px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '-10px',
});

export const SelectContainer = styled('div', {
  width: '220px',
  paddingLeft: '30px',
  display: 'flex',
  alignItems: 'center',
  margin: '-10px',
});

export const ButtonSearchIconContainer = styled('div', {
  borderColor: 'rgba(91, 90, 89, 0.2)',
  borderWidth: '1px',
  paddingRight: '.28em',
  paddingLeft: '.28em',
});

export const CalendarIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  borderStyle: 'solid',
  borderTopWidth: '1px',
  borderRightWidth: '0px',
  borderBottomWidth: '1px',
  borderLeftWidth: '1px',

  borderTopColor: 'rgba(91, 90, 89, 0.2)',
  borderRightColor: 'rgba(91, 90, 89, 0.2)',
  borderBottomColor: 'rgba(91, 90, 89, 0.2)',
  borderLeftColor: 'rgba(91, 90, 89, 0.2)',

  borderTopLeftRadius: '4px',
  borderTopRightRadius: '0px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '0px',

  paddingLeft: '10px',
});

export const ButtonIconRight = styled('div', {
  lineHeight: '31px',
  display: 'inline-block',
  borderStyle: 'none none none solid',
  borderColor: 'rgba(91, 90, 89, 0.2)',
  borderWidth: '1px',
  paddingRight: '0px',
  paddingLeft: '.28em',
  marginLeft: '.3em',
});

export const DownloadContainer = styled('div', {
  display: 'flex',
  alignContent: 'center',
  color: '#626367',
  ':hover': {
    color: 'white',
  },
});

export const RowContainer = styled('tr', {
  display: 'contents',

  ':hover > td': {
    background: '#efefef',
    transitionProperty: 'background',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
  }
});

export const BodyCell = styled('td', {
  color: '#908e8f',
  fontSize: '14px',
  fontWeight: '400',
  paddingTop: '20px',
  paddingRight: '16px',
  paddingLeft: '16px',
  paddingBottom: '20px',
  borderBottom: 'solid 1px #dbdbdb',
});
