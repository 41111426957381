import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';
import { MediaAsset } from '../models/media';
import { Ordering } from '../utils/ordering';
import { SearchCriteria } from '../utils/search-criteria';
import { SessionService } from '@app/security/session.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const PROXY_ROUTE_PREFIX = '/rn-portal/1.0/v3';
@Injectable()
export class MediaService {
  static CACHE = {};

  constructor(
    private sessionService: SessionService,
    private http: SecureHttp,
  ) {}

  getMediaAsset(
    assetId: string,
    hideMask: boolean = true,
  ): Observable<MediaAsset> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${PROXY_ROUTE_PREFIX}${environment.mediaURLBase}/client/${clientId}/media_asset/${assetId}`;
    if (MediaService.CACHE[url]) {
      return of(MediaService.CACHE[url]);
    }
    return this.http.get(url, null, hideMask).pipe(
      map((response) => {
        const mediaMetadata = MediaAsset.deserialize(response);
        MediaService.CACHE[url] = mediaMetadata;
        return mediaMetadata;
      }),
    );
  }

  updateMediaAssetMetaData(mediaAsset: MediaAsset) {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${PROXY_ROUTE_PREFIX}${environment.mediaURLBase}/client/${clientId}/media_asset/${mediaAsset.asset_id}`;
    delete MediaService.CACHE[url];
    return this.http.put(url, mediaAsset.serialize(true));
  }

  getAllMediaAssets(
    limit: number,
    offset: number,
    ordering?: Ordering,
    searchCriteria?: SearchCriteria,
    hideMask: boolean = true,
  ): Observable<Array<MediaAsset>> {
    const clientId = this.sessionService.currentUser.client.id;

    let result: any;
    //temp reirect to search api
    //TODO: Remove when reg api can handle the load
    if (!searchCriteria) {
      searchCriteria = new SearchCriteria();
    }
    //end of temp code
    if (searchCriteria) {
      let url = `${environment.searchURLBase}/client/${clientId}/media_assets?limit=${limit}&offset=${offset}`;

      const searchRequest = searchCriteria.getSearchRequest();

      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
      result = this.http.post(url, searchRequest, null, hideMask);
    } else {
      let url = `${PROXY_ROUTE_PREFIX}${environment.mediaURLBase}/client/${clientId}/media_assets?limit=${limit}&offset=${offset}`;
      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
      result = this.http.get(url, null, hideMask);
    }

    return result.pipe(
      map((response) => {
        return response['media_assets'].map((mediaAssetObj) =>
          MediaAsset.deserialize(mediaAssetObj),
        );
      }),
    );
  }

  getAllBrandingAssets(
    limit: number,
    offset: number,
    ordering?: Ordering,
    searchCriteria?: SearchCriteria,
    hideMask: boolean = true,
    branding: boolean = true,
  ): Observable<Array<MediaAsset>> {
    const clientId = this.sessionService.currentUser.client.id;

    let result: any;
    //temp reirect to search api
    //TODO: Remove when reg api can handle the load
    if (!searchCriteria) {
      searchCriteria = new SearchCriteria();
    }
    //end of temp code
    if (searchCriteria) {
      let url = `${environment.searchURLBase}/client/${clientId}/media_assets?limit=${limit}&offset=${offset}`;

      const searchRequest = searchCriteria.getSearchRequest();
      searchRequest.branding = branding;
      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
      result = this.http.post(url, searchRequest, null, hideMask);
    } else {
      let url = `${PROXY_ROUTE_PREFIX}${environment.mediaURLBase}/client/${clientId}/media_assets?limit=${limit}&offset=${offset}`;
      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
      result = this.http.get(url, null, hideMask);
    }

    return result.pipe(
      map((response) => {
        return response['media_assets'].map((mediaAssetObj) =>
          MediaAsset.deserialize(mediaAssetObj),
        );
      }),
    );
  }

  getMediaAssetsByClient(
    clientId: string,
    assetType: string,
    limit: number,
    offset: number,
    ordering?: Ordering,
    searchCriteria?: SearchCriteria,
    hideMask?: boolean,
  ): Observable<Array<MediaAsset>> {
    let result: any;

    if (searchCriteria) {
      let url = `${environment.searchURLBase}/client/${clientId}/media_assets`;

      const searchRequest = searchCriteria.getSearchRequest();

      result = this.http.post(url, searchRequest, null, hideMask);
      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
    } else {
      let url = `${PROXY_ROUTE_PREFIX}${environment.mediaURLBase}/client/${clientId}/media_assets/${assetType}?limit=${limit}&offset=${offset}`;
      if (ordering) {
        url = `${url}&${ordering.getQueryStringUnderscorish()}`;
      }
      result = this.http.get(url, null, hideMask);
    }

    return result.pipe(
      map((response) => {
        return response['media_assets'].map((mediaAssetObj) =>
          MediaAsset.deserialize(mediaAssetObj),
        );
      }),
    );
  }

  getAllBrands(ordering?: Ordering): Observable<Array<MediaAsset>> {
    const result = new Observable<Array<MediaAsset>>((subscriber) => {
      const limit = 1000;
      let offset = 0;
      const allMediaAssets: MediaAsset[] = [];
      const service = this;

      function processNextMediaAssetBatch(mediaAssets: Array<MediaAsset>) {
        if (mediaAssets.length > 0) {
          mediaAssets.forEach((asset) => {
            allMediaAssets.push(asset);
          });

          offset += limit;

          service
            .getAllBrandingAssets(limit, offset, ordering)
            .subscribe(
              (b) => {
                processNextMediaAssetBatch(b);
              },
              (error) => {
                subscriber.error(error);
              },
            );
        } else {
          subscriber.next(allMediaAssets);
          subscriber.complete();
        }
      }

      this.getAllBrandingAssets(limit, offset, ordering).subscribe(
        (mediaAssets) => {
          processNextMediaAssetBatch(mediaAssets);
        },
        (error) => {
          subscriber.error(error);
        },
      );
    });
    return result;
  }

  getUploadUrl(clientId: string): string {
    const url = `${environment.mediaURLBase}/client/${clientId}/media_assets`;

    return url;
  }

  getUploadUrlForCurrentUser(): string {
    const clientId = this.sessionService.currentUser.client.id;

    const url = `${environment.mediaURLBase}/client/${clientId}/media_assets`;

    return url;
  }

  getUpdateUrl(clientId: string, assetId: string): string {
    const url = `${environment.mediaURLBase}/client/${clientId}/media_asset/${assetId}`;

    return url;
  }

  createMediaAsset(mediaAsset: MediaAsset) {
    return this.http.post(
      this.getUploadUrlForCurrentUser(),
      mediaAsset.serialize(false),
    );
  }
}
