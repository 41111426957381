import { styled } from 'baseui';

export const SelectTitle = styled('div', {
  fontSize: '24px',
})

export const ConfigurationFooter = styled('footer', {
  paddingTop: '10px',
  paddingBottom: '50px',
});
