import { Component, Input, OnInit } from '@angular/core';
import { Client } from '@app/core/models/client';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'rn-sftp-key-upload-instructions',
  templateUrl: './sftp-key-upload-instructions.component.html',
  styleUrls: ['./sftp-key-upload-instructions.component.scss']
})
export class SftpKeyUploadInstructionsComponent implements OnInit {
  @Input() client: Client;
  sshKeyFilenameCtl = new UntypedFormControl('myfile', []); // for the "instructions" section
  showMacInstructions: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  getSshKeyFilename(): string {
    if (this.sshKeyFilenameCtl.value.length > 0) {
      return this.macInstructions(this.sshKeyFilenameCtl.value);
    }
  }

  macInstructions(filename): string {
    return `cd Desktop;\n ssh-keygen -e -f ${filename}.pub > ${filename}.ssh2.pub;`;
  }
}
