import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { TitleService } from '@app/core/services/title.service';

@Component({
  selector: 'app-launcher-list',
  templateUrl: './launcher-list.component.html',
  styleUrls: ['./launcher-list.component.scss']
})
export class LauncherListComponent implements OnInit, OnDestroy {
  primaryTitle: string = 'Experience Launcher';
  secondaryTitle: string = 'Select an experience to launch';

  csrFilter: boolean;
  limit: number;
  constructor(private permissionService: PermissionService,
              private titleService: TitleService) { }

  ngOnInit() {
    this.titleService.activate(this.primaryTitle, this.secondaryTitle);

    this.csrFilter = this.permissionService.checkPermissions(Permissions.ui_journey_launcher_msg_only)
      && !this.permissionService.checkPermissions(Permissions.ui_journey_list);
    this.csrFilter ? this.limit = 100 : this.limit = 20;
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }
}
