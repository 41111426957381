import { Component, OnInit } from '@angular/core';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})

export class TermsAndConditionsComponent implements OnInit {

  constructor(private loadingMaskService: LoadingMaskService) { 
    this.loadingMaskService.loadingOn();
  }

  ngOnInit() {
  }
}
