import { Pipe, PipeTransform } from '@angular/core';
import { FormatUtils } from '@app/core/utils/format-utils';

@Pipe({
  name: 'ellipsis'
})

export class EllipsisPipe implements PipeTransform {
  transform(value: string, length: number): string {
    return FormatUtils.truncateTextWithEllipsis(value, length);
  }
}
