import React from 'react';
import { RelayMessengerFeatureFlag } from '../relay-messenger.component';

interface FeatureFlagsProviderProps {
  featureFlags: RelayMessengerFeatureFlag[];
  children: React.ReactNode;
}

const FeatureFlagsContext = React.createContext<RelayMessengerFeatureFlag[]>(
  [],
);

export const FeatureFlagsProvider = ({
  featureFlags,
  children,
}: FeatureFlagsProviderProps) => {
  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error(
      `useFeatureFlags must be used within a FeatureFlagsProvider`,
    );
  }

  return context;
};
