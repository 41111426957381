import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import {
  ButtonCancelFormConfigOverride,
  ButtonConfigurationOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '@app/client-config/client-form/client-power-ups-config/styles/AddConfigurationButtonStyles';
import { Button } from 'baseui/button';

export const DeleteModal = ({
  name,
  onCloseClicked,
  onDeleteClicked,
}: {
  name: string;
  onCloseClicked: () => void;
  onDeleteClicked: () => void;
}) => {
  return (
    <>
      <Modal isOpen onClose={onCloseClicked}>
        <ModalHeader style={ModalHeaderOverride.style}>
          Delete Power-Ups Configuration
        </ModalHeader>
        <ModalBody style={{}}>
          Are you sure you want to delete the configuration for {name}?{' '}
          <b>
            This will prevent this client from using this Power-Up in the
            creation of future Experiences. The form will continue to be active
            on existing Experiences.
          </b>
        </ModalBody>
        <ModalFooter>
          <Button
            kind="tertiary"
            onClick={onCloseClicked}
            overrides={{ BaseButton: ButtonCancelFormConfigOverride }}
          >
            Cancel
          </Button>
          <Button
            onClick={onDeleteClicked}
            overrides={{ BaseButton: ButtonConfigurationOverride }}
          >
            <span>DELETE</span>
            <ButtonIconRight>
              <i className="fa fa-trash"></i>
            </ButtonIconRight>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
