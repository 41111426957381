import React from 'react';
import { useStyletron } from 'baseui';
import { Card as BaseCard, StyledBody } from 'baseui/card';
import { Role } from '../chat-item/ChatItem';

import { CustomTheme } from '../baseweb-custom-theme/customTheme';

export interface ChatBubbleProps {
  role?: Role;
  text?: string;
  styleProp?: React.CSSProperties;
  children?: React.ReactNode;
}

export const ChatBubble = ({
  role = Role.Agent,
  text,
  styleProp,
  children,
}: ChatBubbleProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;

  return (
    <BaseCard
      overrides={{
        Root: {
          style: ({ $theme }: { $theme: CustomTheme }) => ({
            borderTopLeftRadius: $theme.borders.chatBubbleBorderRadius,
            borderTopRightRadius: $theme.borders.chatBubbleBorderRadius,
            borderBottomLeftRadius: $theme.borders.chatBubbleBorderRadius,
            borderBottomRightRadius: $theme.borders.chatBubbleBorderRadius,
            borderTopColor: $theme.borders.chatBubbleBorderColor,
            borderRightColor: $theme.borders.chatBubbleBorderColor,
            borderBottomColor: $theme.borders.chatBubbleBorderColor,
            borderLeftColor: $theme.borders.chatBubbleBorderColor,

            ...(role === Role.Agent
              ? {
                  backgroundColor: $theme.colors.chatBubbleBackgroundAgent,
                }
              : {
                  backgroundColor: $theme.colors.chatBubbleBackgroundCustomer,
                }),
          }),
        },
        Contents: {
          style: ({ $theme }: { $theme: CustomTheme }) => ({
            marginTop: '0px',
            marginBottom: '0px',
            marginLeft: '0px',
            marginRight: '0px',
            paddingTop: $theme.sizing.chatBubblePadding,
            paddingBottom: $theme.sizing.chatBubblePadding,
            paddingLeft: $theme.sizing.chatBubblePadding,
            paddingRight: $theme.sizing.chatBubblePadding,
          }),
        },
        Body: {
          style: {
            marginBottom: '0px',
          },
        },
      }}
    >
      <StyledBody
        className={css({
          marginBottom: '0px',
          ...customTheme.typography.chatBubbleFont,
          ...(role === Role.Agent
            ? {
                color: customTheme.colors.chatBubbleTextAgent,
              }
            : {
                color: customTheme.colors.chatBubbleTextCustomer,
              }),
        })}
      >
        <div>{text || children}</div>
      </StyledBody>
    </BaseCard>
  );
};
