import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { RelayMessengerHome } from '@app/two-way/relay-messenger/components/relay-messenger-home/RelayMessengerHome';
import { RelayMessengerProvider } from '../../context/RelayMessengerProvider';
import { RelayMessengerFeatureFlag } from '../../relay-messenger.component';

export type TwoWayMessagingContainerProps = {
  clientId: string;
  userId: string;
  userRoleIds: string[];
  channelSid: string | null;
  featureFlags: RelayMessengerFeatureFlag[];
};

const engine = new Styletron({ prefix: 'new-two-way-' }); // generate classNames with a prefix of 'new-two-way';

export const TwoWayMessagingContainer = ({
  clientId,
  userId,
  userRoleIds,
  channelSid,
  featureFlags,
}: TwoWayMessagingContainerProps) => {
  return (
    <RelayMessengerProvider
      clientId={clientId}
      userId={userId}
      userRoleIds={userRoleIds}
      channelSid={channelSid}
      featureFlags={featureFlags}
    >
      <RelayMessengerHome></RelayMessengerHome>
    </RelayMessengerProvider>
  );
};
