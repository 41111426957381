<div class="container-fluid grid">
  <ng-container *ngIf="industriesLoaded()">
    <div  *ngIf="!hasError" class="container-fluid">
      <button id="add-industry-btn" class="btn btn-primary btn-sm" (click)="navigateToAdd()">
        Add new
      </button>
    </div>
    <div class="row grid-header">
      <div class="col-sm-3 column-heading no-text-selection" (click)="onColumnHeadingClick('id')">
        <span i18n="Industry ID column heading">Industry ID</span>
        <app-column-sort-icon id="sort-icon-industry-name" [ordering]="ordering" [fieldName]="'id'">
        </app-column-sort-icon>
      </div>
      <div class="col-sm-3 column-heading no-text-selection" (click)="onColumnHeadingClick('title')">
        <span i18n="Industry name column heading">Industry Name</span>
        <app-column-sort-icon id="sort-icon-industry-name" [ordering]="ordering" [fieldName]="'title'">
        </app-column-sort-icon>
      </div>
      <div class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('created_at')">
        <span i18n="Industry date created column heading">Date Created</span>
        <app-column-sort-icon id="sort-icon-industry-name" [ordering]="ordering" [fieldName]="'created_at'">
        </app-column-sort-icon>
      </div>
      <div class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('updated_at')">
        <span i18n="Industry date modified column heading">Date Modified</span>
        <app-column-sort-icon id="sort-icon-industry-name" [ordering]="ordering" [fieldName]="'updated_at'">
        </app-column-sort-icon>
      </div>
    </div>
    <ng-container *ngFor="let industry of sortedIndustries">
      <div class="grid-row">
        <div class="row">
          <div class="col-sm-3 industry-name-col">
            <span class="industry-name strong">{{industry.id}}</span>
          </div>
          <div class="col-sm-3 industry-name-col">
            <span class="industry-name strong">{{industry.title['en-us']}}</span>
          </div>
          <div class="col-sm-2">
            <span>{{industry.created_at | date:'shortDate'}}</span>
          </div>
          <div class="col-sm-2">
            <span>{{industry.updated_at | date:'shortDate'}}</span>
          </div>
          <div class="color-sm-1 action-column center">
            <i class="fa fa-pencil" aria-hidden="true" (click)="navigateToEdit(industry.id)"></i>
            <i class="fa fa-caret-right expand-collapse"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
<div *ngIf="noIndustriesFound()" class="not-found"
  i18n="Message that the user gets when there are no industries to show in the list">No industries were found.
</div>
<div *ngIf="hasError" class="not-found" i18n="Message that the user gets when there was an error">
  An error occured with your request, please try again later.
</div>
<app-spinner *ngIf="!isLoaded" class="not-found"></app-spinner>
<message-dialog></message-dialog>