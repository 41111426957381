import { Background } from '@app/core/utils/background';
import { SessionService } from '../session.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  submitted: boolean;
  requestInFlight: boolean;
  error: string;
  now: Date;
  loginFormGroup: UntypedFormGroup;

  constructor(private sessionService: SessionService,
              private formBuilder: UntypedFormBuilder) {
    this.loginFormGroup = formBuilder.group({
      'username': [null, [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    Background.loginOn();
    this.now = new Date();
  }

  ngOnDestroy() {
    Background.loginOff();
  }

  reset(): void {
    this.submitted = false;
    this.error = undefined;
  }

  submitForgotLogin(): void {
    this.requestInFlight = true;
    this.submitted = false;
    this.error = undefined;

    const controls = this.loginFormGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        controls[control].markAsTouched();
      }
    }

    if (this.loginFormGroup.valid) {
      this.sessionService.forgotPassword(this.loginFormGroup.controls['username'].value).subscribe(
        r => {
          this.submitted = true;
          this.requestInFlight = false;
        },
        err => {
          this.submitted = true;
          this.requestInFlight = false;
          this.parseError(err);
        });
    }

  }

  checkForEnter(event): void {
    if (event.keyCode === 13) {
      this.submitForgotLogin();
    }
  }

  private parseError(err: any) {
    if (!err) {
      this.error = 'An error occurred. Please try again.';
    }
    if (err && err.reason) {
      this.error = err.reason;
    } else {
      this.error = err.toString();
    }
  }

}
