import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
  @Input() tabs: { id: string; label: string }[];
  @Input() currentTabId: string;
  @Input() experienceLibraryAdminTabs: boolean;
  @Output() currentTabIdChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onTabClick(newTabId: string) {
    this.currentTabId = newTabId;
    this.currentTabIdChange.emit(this.currentTabId);
  }
}
