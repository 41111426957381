import { Pipe, PipeTransform } from "@angular/core";
import { FormatUtils } from '../../core/utils/format-utils';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value === 'string' && /\d+/.test(value)) {
      return FormatUtils.formatPhoneNumber(value);
    } else {
      return value;
    }
  }
}
