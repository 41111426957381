<div class="d-flex-center">
    <label [for]="name" class="col-sm-3 form-label action">{{fieldLabel}}</label>
    <div
      class="col-sm-9"
      [ngClass]="{ 'has-error': control?.invalid && control?.touched }"
    >
      <span
        class="error-msg"
        *ngIf="control?.invalid && control?.touched"
        tooltip="{{ getTooltipData(control?.errors) }}"
        placement="bottom"
      >
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>
      <input
        disableAutoComplete
        [name]="name"
        [id]="name"
        class="form-control"
        type="text"
        [mention]="actionMention"
        [mentionConfig]="actionMentionConfig"
        [formControl]="control"
      />
    </div>
</div>