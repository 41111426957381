import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { SecureHttp } from '@app/security/secure-http';
import { SessionService } from '@app/security/session.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LookerService {

  readonly customerEngagementId: string = 'relay_v3_dw::customer_engagement';
  readonly messagingId: string = 'relay_v3_dw::messaging';
  readonly relayMessengerId: string = 'relay_v3_dw::relay_messenger_dashboard';

  constructor(private http: SecureHttp, private sessionService: SessionService) {
  }

  getDashboardUrl(dashboardId: string): Observable<string> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.cdmURLBase}/client/${clientId}/reporting/looker/sso`;
    const options = {dashboard: dashboardId};

    return this.http.post(url, options).pipe(
      map(response => response.looker_sso_url)
    );
  }
}
