import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'generic-phone',
  templateUrl: './generic-phone.component.html',
  styleUrls: ['./generic-phone.component.scss'],
})
export class GenericPhoneComponent implements OnInit {
  @Input() overflowY?: string;

  constructor() {}

  ngOnInit() {}
}
