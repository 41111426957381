import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '@app/core/services/title.service';


@Component({
  selector: 'app-user-edit-page',
  templateUrl: './user-edit-page.component.html',
  styleUrls: ['./user-edit-page.component.scss']
})
export class UserEditPageComponent implements OnInit {
  userID: string;
  isNew: boolean;

  constructor(private titleService: TitleService,
              private activeRoute: ActivatedRoute,
              private route: Router) {}

  ngOnInit() {
    this.titleService.activate('User Management', this.isNew ? 'Add new user' : 'Edit user');
    this.isNew = this.activeRoute.snapshot.url[1].path === 'new';
    this.userID = this.activeRoute.snapshot.params['userID'];
  }
  
  returnToListPage() {
    this.route.navigateByUrl('/user-mgmt');
  }
}
