import { Component, OnInit } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'security-component',
  templateUrl: './security-component.component.html'
})
export class SecurityComponent implements OnInit {

  constructor(private sessionService: SessionService,
              private router: Router) {}

  ngOnInit() {
    this.sessionService.loggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        this.router.navigateByUrl('/safe-redirect');
      }
    });
  }
}

