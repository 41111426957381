<div class="input-container">
  <div class="input">
    <label i18n="Label for product group contact info">Product Group Customer Contacts</label>
    <div class="no-validate contact-info-container">
      <div cdkDropList (cdkDropListDropped)="dropElement($event)" >
        <div cdkDrag class="contact-info-row" [formGroup]="rowGroup" *ngFor="let rowGroup of contactInfoForm.controls; let i = index">
          <i class="fa fa-ellipsis-v"></i>
          <select id="contact-info-select-{{i}}" class="form-control light contact-info" formControlName="type"
                  (change)="changeFormControlValidators(rowGroup)">
            <option value="telephone">telephone</option>
            <option value="website">website</option>
            <option value="email">email</option>
            <option value="twitter">twitter</option>
          </select>

          <!-- 
            UPGRADE NOTE:  There is an error about duplicate ids appearing in console when a draggable element is picked up. 
            This has been fixed in version 8+, so we'll have to deal with it until then.  https://github.com/angular/components/commit/751fe8a0b3cc433b53d7acf7ef23a9fc1c4dc1cf
          -->
          <div class="field-container">
            <i *ngIf="!isValid(rowGroup.get('display_text'))"
              class="fa fa-exclamation-circle display-text-error" aria-hidden="true"
              tooltip="{{getTooltipData(rowGroup.get('display_text').errors)}}"></i>
            <input id="display-text-{{i}}" name="display-text-{{i}}" class="contact-info" placeholder="display text"
              formControlName="display_text"
              [ngClass]="{'has-error': !isValid(rowGroup.get('display_text'))}">
          </div>

          <div class="field-container">
            <i *ngIf="!isValid(rowGroup.get('value'))"
              class="fa fa-exclamation-circle contact-value-error" aria-hidden="true"
              tooltip="{{getTooltipData(rowGroup.get('value').errors)}}"></i>
            <input id="value-{{i}}" name="value-{{i}}" class="contact-info" [placeholder]="getPlaceholderByType(rowGroup.get('type').value)" trim="blur"
              formControlName="value"
              [ngClass]="{'has-error': !isValid(rowGroup.get('value'))}"
              (blur)="format(rowGroup.get('value'), rowGroup.get('type').value)">
          </div>

          <i class="fa fa-minus-circle" (click)="removeContactInfo(i)"></i>
        </div>
      </div>

      <div *ngIf="productGroup.contact_us_info && productGroup.contact_us_info.length < 5" (click)="addContactInfo()"
           class="add-contact no-text-selection">
        <i class="fa fa-plus-circle"></i><span i18n="Label for button to add new contact field">Add field</span>
      </div>
    </div>
  </div>
</div>
