import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// Model and Service Imports
import { Client } from '../../../../../core/models/client';


@Component({
  selector: 'app-agent-validation',
  templateUrl: './agent-validation.component.html',
  styleUrls: ['./agent-validation.component.scss']
})

export class AgentValidationComponent implements OnInit {
  @Input() client: Client;

  validationFields = ['ssn_last4', 'date_of_birth'];
  validationFieldsFormGroup: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.validationFieldsFormGroup = this.formBuilder.group({
      'validation_script': [this.client.onboarding.csr.validation_language, Validators.required]
    });

    // need to update model on form change so that the preview gets the correct data
    this.validationFieldsFormGroup.valueChanges.subscribe(() => {
      this.client.onboarding.csr.validation_language = this.validationFieldsFormGroup.controls['validation_script'].value; 
    })
  }

  getTooltipData(errors?: Object): string {
    if (errors === null) {
      return `This field is invalid.`;
    }
    if (errors['required']) {
      return `This field is required.`;
    }
    if (typeof (errors) === "string") {
      return errors;
    }
  }
}
