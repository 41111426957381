import React, { useState, ReactNode } from 'react';
import { Client } from '@twilio/conversations';

export type TwilioClientContextType = {
  twilioClient: Client;
  setTwilioClient: (twilioClient: Client) => void;
};

export type TwilioClientContextProviderProps = {
  children: ReactNode;
};

export const TwilioClientContext = React.createContext<
  TwilioClientContextType | undefined
>(undefined);

export function TwilioClientProvider({
  children,
}: TwilioClientContextProviderProps) {
  const [twilioClient, setTwilioClient] = useState<Client | null>(null);
  return (
    <TwilioClientContext.Provider value={{ twilioClient, setTwilioClient }}>
      {children}
    </TwilioClientContext.Provider>
  );
}

export const useTwilioClient = () => {
  const context = React.useContext(TwilioClientContext);

  if (context === undefined) {
    throw new Error(
      `useTwilioClient must be used within a TwilioClientProvider`,
    );
  }

  return context;
};
