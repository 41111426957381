<div class="container-fluid grid">
  
  <div *ngIf="!hasError" class="row">
    <button id="add-outcomes-btn" class="btn btn-primary btn-sm add-btn" (click)="navigateToAdd()">
      Add new
    </button>
  </div>

  <ng-container *ngIf="isAllDataLoadedAndNoErrors">
    <!-- List Headings -->
    <div class="row grid-header">
      <div id="outcome-sort-by-id" class="col-sm-1 column-heading no-text-selection" (click)="onColumnHeadingClick('id')">
        <span i18n="Outcome id column heading">Outcome ID</span>
        <app-column-sort-icon id="sort-icon-outcome-id" [ordering]="ordering" [fieldName]="'id'"></app-column-sort-icon>
      </div>
      <div id="outcome-sort-by-title" class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('title')">
        <span i18n="Outcome column heading">Outcome</span>
        <app-column-sort-icon id="sort-icon-outcome-name" [ordering]="ordering" [fieldName]="'title'"></app-column-sort-icon>
      </div>
      <div class="col-sm-2 column-heading no-text-selection">
        <span i18n="Outcome Industries column heading">Industries</span>
      </div>
      <div class="col-sm-2 column-heading no-text-selection">
        <span i18n="Outcome Company Types column heading">Company Types</span>
      </div>
      <div id="outcome-sort-by-date-created" class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('created_at')">
        <span i18n="Outcome date created column heading">Date Created</span>
        <app-column-sort-icon id="sort-icon-date-created" [ordering]="ordering" [fieldName]="'created_at'"></app-column-sort-icon>
      </div>
      <div id="outcome-sort-by-date-updated" class="col-sm-2 column-heading no-text-selection" (click)="onColumnHeadingClick('updated_at')">
        <span i18n="Outcome date modified column heading">Date Modified</span>
        <app-column-sort-icon id="sort-icon-date-modified" [ordering]="ordering" [fieldName]="'updated_at'"></app-column-sort-icon>
      </div>
    </div>

    <!-- List Items -->
    <ng-container *ngFor="let outcome of sortedOutcomes">
      <rn-outcome-list-item id="outcome-list-item-{{outcome.id}}" (editClicked)="navigateToEdit($event)" [outcome]="outcome" [companyTypeOptions]="companyTypes" [industryOptions]="industries"></rn-outcome-list-item>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="isDataLoadedAndHasDataAndNoErrors" class="not-found"
  i18n="Message that the user gets when there are no outcomes to show in the list">There were no outcomes found.
</div>

<div *ngIf="hasError" class="not-found"
  i18n="Message that the user gets when there was an error loading outcomes">There was an error loading outcomes. 
</div>