import { ClientInfoProvider } from './ClientInfoContext';
import React from 'react';
import { UserInfoProvider } from './UserInfoContext';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { customTheme } from '../components/baseweb-custom-theme/customTheme';
import { CustomerInfoProvider } from './CustomerInfoContext';
import { CustomerStatusProvider } from './CustomerStatusContext';
import { TwilioClientProvider } from './TwilioClientContext';
import { PLACEMENT, SnackbarProvider } from 'baseui/snackbar';
import { AssignedAgentsProvider } from './AssignedAgentsContext';
import { NewlyAssignedConvSidsProvider } from './NewlyAssignedConvSidsContext';
import { RefreshConversationsProvider } from './RefreshConversationsContext';
import { RelayMessengerFeatureFlag } from '../relay-messenger.component';
import { FeatureFlagsProvider } from './FeatureFlagsContext';
import { ClosedReasonsProvider } from './ClosedReasonsContext';

type RelayMessengerProviderProps = {
  clientId: string;
  userId: string;
  userRoleIds: string[];
  channelSid?: string | null;
  children: React.ReactNode;
  featureFlags: RelayMessengerFeatureFlag[];
};

const engine = new Styletron({ prefix: 'new-two-way-' }); // generate classNames with a prefix of 'new-two-way';

export const RelayMessengerProvider = ({
  children,
  clientId,
  userId,
  userRoleIds,
  channelSid,
  featureFlags,
}: RelayMessengerProviderProps) => {
  return (
    <FeatureFlagsProvider featureFlags={featureFlags}>
      <ClientInfoProvider clientId={clientId}>
        <UserInfoProvider userId={userId} userRoleIds={userRoleIds}>
          <StyletronProvider value={engine}>
            <BaseProvider theme={customTheme} zIndex={202}>
              <CustomerInfoProvider channelSid={channelSid}>
                <AssignedAgentsProvider>
                  <CustomerStatusProvider>
                    <NewlyAssignedConvSidsProvider>
                      <RefreshConversationsProvider>
                        <ClosedReasonsProvider>
                          <TwilioClientProvider>
                            <SnackbarProvider
                              placement={PLACEMENT['bottom']}
                              overrides={{
                                Root: {
                                  style: {
                                    outline: `#37474F solid`,
                                    backgroundColor: '#37474F',
                                    color: customTheme.colors.white,
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '12.57px',
                                    borderTopRightRadius: '5px',
                                    borderTopLeftRadius: '5px',
                                    borderBottomRightRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                  },
                                },
                                Content: {
                                  style: {
                                    height: 'auto',
                                    justifyContent: 'center',
                                  },
                                },
                              }}
                            >
                              {children}
                            </SnackbarProvider>
                          </TwilioClientProvider>
                        </ClosedReasonsProvider>
                      </RefreshConversationsProvider>
                    </NewlyAssignedConvSidsProvider>
                  </CustomerStatusProvider>
                </AssignedAgentsProvider>
              </CustomerInfoProvider>
            </BaseProvider>
          </StyletronProvider>
        </UserInfoProvider>
      </ClientInfoProvider>
    </FeatureFlagsProvider>
  );
};
