import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientService } from '@app/core/services/client.service';
import { Client } from '@app/core/models/client';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'app-quick-launch',
  templateUrl: './quick-launch.component.html',
  styleUrls: ['./quick-launch.component.scss']
})
export class QuickLaunchComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @Input() client: Client;
  colors: string[] = this.clientService.selectJourneyColors;
  selectedJourney: string;
  ccid: string;
  customerValidated = false;
  validationGroup: UntypedFormGroup;
  validationModel = {phone_number: ''};

  constructor(private titleService: TitleService,
              private clientService: ClientService,
              private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.validationGroup = this.formBuilder.group({
      'ccid': [null, Validators.required],
      'phone': [null, Validators.required],
      'consent': [null, Validators.required]
    });
    this.titleService.activate('Onboarding');
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  setValidation([validation, journeyId]): void {
    this.selectedJourney = journeyId;
    this.validationModel = validation;
    this.customerValidated = true;
  }

  onValidationFailed(event) {
    this.customerValidated = false;
    if (event.error === "cancel") {
      this.validationModel = event.validation;
      this.ccid = event.ccid;
      return;
    }
    if (event.error.indexOf('The following fields did not match:') > -1) {
      const fields = event.error.split(': ')[1];
      const fieldsArr = fields.split(',');
      fieldsArr.forEach(c => {
          this.validationGroup.controls[c.replace("-", "_").trim()].setErrors(() => 'validation failed');
      });
      return;
    }
    this.messageDialog.showMessage(event.error);
  }

}
