<app-search-bar [searchableFields]="searchableFields"
                (onRequestSearch)="onRequestSearch($event)"
                (onClearSearch)="onClearSearch()">
</app-search-bar>
<div class="container-fluid list customers" *ngIf="customers">
  <div class="row colhdrs">
    <div class="colw colw-20 column-heading no-text-selection" (click)="onColumnHeadingClick('full_name')">
      <span i18n="Customer list customer name heading">Customer name</span>
      <app-column-sort-icon id="sort-icon-client-name" [ordering]="ordering" [fieldName]="'full_name'"></app-column-sort-icon>
    </div>
    <div class="colw colw-20 column-heading no-text-selection" (click)="onColumnHeadingClick('ccid')">
      <span i18n="Customer list ccid heading">Account ID (CCID)</span>
      <app-column-sort-icon id="sort-icon-client-ccid" [ordering]="ordering" [fieldName]="'ccid'"></app-column-sort-icon>
    </div>
    <div class="colw colw-20 column-heading no-text-selection">
      <span i18n="Customer list channels">Mobile Number(s)</span>
    </div>
    <div class="colw colw-20 column-heading no-text-selection">
      <span i18n="Customer list channels">Product Group</span>
    </div>
    <div class="colw colw-15 column-heading"></div>
  </div>
  <ng-container *ngIf="customers">
    <div class="infinite-scroll" data-infinite-scroll
         debounce
         [infiniteScrollDistance]="scrollDistance"
         [infiniteScrollThrottle]="throttle"
         (scrolled)="onScrollDown()">
    <rn-customer-row  *ngFor="let customer of customers" [client]="client" [customer]="customer" [id]="'row-' + customer.id" [openRowId]="openRowId" class="list-row" [class.active]="displayDetails[customer.ccid]" (consentChanged)="onConsentChanged($event)" (phoneAdded)="onPhoneAdded($event)" (phoneRemoved)="onPhoneRemoved($event)" (rowOpened)="onRowOpened($event)"></rn-customer-row>
    </div>
  </ng-container>
  <div *ngIf="customers.length == 0" class="center not-found" i18n="Message when customer search finds nothing"><h1>No customers were found.</h1></div>
</div>
<div *ngIf="!customers" class="center" >
  <div class="instructions" i18n="Customer lookup instructions">
    <h1>Search for a customer by name, mobile number or account ID (CCID).</h1>
  </div>
</div>

<message-dialog #serverErrorDialog (affirm)="serverErrorDialog.close()"></message-dialog>
