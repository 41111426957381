// Angular Core Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Data Models && Services
import { Client } from '../../core/models/client';
import { ClientService } from '../../core/services/client.service';
import { BlackoutUtils } from '@app/core/utils/blackout-utils';
import { BlackoutWindow } from '@app/core/models/blackout-window';
import { BlackoutHour} from '@app/core/models/blackout-hour';

@Component({
  selector: 'app-blackout-select',
  templateUrl: './blackout-select.component.html',
  styleUrls: ['./blackout-select.component.scss']
})
export class BlackoutSelectComponent implements OnInit {
  @Input() labelPrefix: string = 'Blackout';
  @Input() thingBeingSent: string = 'notifications';
  @Input() invert: boolean = false;
  @Input() blackoutWindow: BlackoutWindow;
  @Output() change: EventEmitter<BlackoutWindow> = new EventEmitter<BlackoutWindow>();
  hours: BlackoutHour[];

  constructor(public clientService: ClientService) {
    this.hours = this.clientService.getBlackoutHours();
  }

  ngOnInit() {}
  
  onBlackoutStartChange(event: string) {
    if (this.blackoutWindow.start_time === this.blackoutWindow.end_time) {
      this.blackoutWindow.end_time = this.clientService.getNextBlackoutHour(this.blackoutWindow.end_time);
    }

    this.change.emit(this.blackoutWindow);
  }

  get timeRange(): string {
    const start = this.clientService.getDisplayHour(this.blackoutWindow.start_time);
    const end = this.clientService.getDisplayHour(this.blackoutWindow.end_time);
    return `from ${start} until ${end}`;
  }

  get verb() {
    return this.invert ? 'will receive' : 'will not receive';
  }
  
  onBlackoutEndChange(event: string) {    
    if (this.blackoutWindow.start_time === this.blackoutWindow.end_time) {
      this.blackoutWindow.start_time = this.clientService.getPreviousBlackoutHour(this.blackoutWindow.end_time);
    }

    this.change.emit(this.blackoutWindow);
  }

  get weekendBlackoutMessage(): string {
    const days = BlackoutUtils.weekendBlackoutDays(this.blackoutWindow);
    const joiner = this.invert ? ' and ' : ' or ';
    return days.length > 0 ? `Users ${this.verb} ${this.thingBeingSent} on ${days.join(joiner)}` : '';
  }
}
