import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent implements OnInit {
  @Input() btnLabel: string;
  @Input() btnLabelTrue: string = 'On';
  @Input() btnLabelFalse: string = 'Off';
  @Input() btnName: string;
  @Input() btnModel: string | boolean;
  @Input() btnAutoSize: boolean;
  @Input() btnWidth?: string;
  @Input() tiptext?: string;
  @Input() btnDisplayTrueFirst: boolean = false;
  @Input() disabled?: boolean;
  @Input() labelIndentWidth?: number;
  @Input() feedEnabled?: boolean;
  @Output() btnModelChange: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();

  isBoolean: boolean;

  constructor() {
  }

  ngOnInit() {
    this.buttonTyping();
  }

  emitChangeEvent(event: string) {
    this.btnModelChange.emit(event);
  }

  emitBooleanChangeEvent() {
    this.btnModel = !this.btnModel;
    this.btnModelChange.emit(this.btnModel);
  }

  formatBtnID(label: string, onOrOff: string): string {
    label = label.toLowerCase().replace(/\s/ig, '-');
    return `${label}-${onOrOff}`;
  }

  private buttonTyping(): void {
    if (typeof this.btnModel === 'boolean') {
      this.isBoolean = true;
    }
  }
}
