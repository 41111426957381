import { Injectable } from '@angular/core';
import { expand, reduce, map, switchMap } from 'rxjs/operators';
import { Observable, of, empty, ReplaySubject } from 'rxjs';
import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';
import { SessionService } from '@app/security/session.service';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { FeatureFlags } from '../models/feature-flags';
import { FeatureService } from './feature.service';

export class ControlTag {
  tag_id: string;
  tag_name: string;
  tag_autoresponse?: string;

  constructor(data: { tag_id?: string, id?: string, tag_name?: string, tag_autoresponse?: string } = {}) {
    this.tag_id = data.tag_id || data.id;  // tags endpoint returns 'id'.  tags-for-group returns 'tag_id'
    this.tag_name = data.tag_name || this.tag_name;
    
    // only add if there is a value
    if (data.tag_autoresponse) {
      this.tag_autoresponse = data.tag_autoresponse;
    }
  }
}

export class ControlGroup {
  group_id: string = null;
  group_name: string = '';
  tags?: ControlTag[];

  // ui-only key used to track which control group is selected.  
  // - If there is a group_id present on initial load, this will match it. 
  // - New/never-saved groups, and saved-within-this-session groups, will have a generated uuid.
  temp_id: string = uuid.v4();

  constructor(data: { group_id?: string, id?: string, group_name?: string, tags?: any[] } = {}) {
    this.group_id = data.group_id || data.id || this.group_id;  // `GET /group` returns 'id'.  `GET group/:groupid/tag` and `POST /group/tag` return 'group_id'.  this accounts for both.
    this.group_name = data.group_name || this.group_name;
    this.tags = data.tags ? data.tags.map(tag => new ControlTag(tag)) : [];
    this.temp_id = this.group_id || this.temp_id;
  }

  addTags(tags: ControlTag[]): void {
    this.tags = tags;
  }

  matches(compareGroup: ControlGroup): boolean {
    return _.isEqual(_.omit(compareGroup, 'temp_id'), _.omit(this, 'temp_id'));
  }
}

@Injectable()
export class ControlTagsService {
  // app assumes these are 'loading' if they're undefined.
  // these replaySubjects are here for caching purposes - nothing More 
  private tags$: ReplaySubject<ControlTag[]>;
  private groups$: ReplaySubject<ControlGroup[]>;
  private tagsByGroup$: { [key: string]: ReplaySubject<ControlTag[]> } = {};

  constructor( private http: SecureHttp,
               private sessionService: SessionService,
               private featureService: FeatureService,) {
    this.sessionService.clientChanged.subscribe(() => {
      this.reset(); // clear all Subjects when the client changes
    });
  }

  /**
   * Public Methods
   */

  getGroups(clientId = this.clientId): Observable<ControlGroup[]> {
    return this.groups$ ? this.groups$ : this.fetchGroups(clientId);
  }

  getTags(clientId = this.clientId): Observable<ControlTag[]> {
    return this.tags$ ? this.tags$ : this.fetchTags(clientId);
  }

  getTagsByGroup(groupId, clientId = this.clientId): Observable<ControlTag[]> {
    return this.tagsByGroup$[groupId] ? this.tagsByGroup$[groupId] : this.fetchTagsByGroup(groupId, clientId);
  }

  getTagsForAgent(clientId = this.clientId): Observable<ControlTag[]> {
    return this.fetchTagsForAgent(clientId);
  }
  getTag(tagId, clientId = this.clientId): Observable<ControlTag> {
    return this.fetchTag(tagId, clientId);
  }

  // clear stored data after changes have been saved server-side, or when client changes
  reset(): void {
    this.groups$ = undefined;
    this.tags$ = undefined;
    this.tagsByGroup$ = {};
  }

  /**
   * Post Requests
   */

  saveGroup(groupData, clientId = this.clientId): Observable<ControlGroup> {
    const url = `${this.baseUrl(clientId)}/group/tag`;
    const data = {
      group_id: groupData.group_id,
      group_name: groupData.group_name,
      group_tags: groupData.tags
    };

    return this.http.post(url, data, {}, true).pipe(
      switchMap((response) => {
        const responseGroupData = {
          group_name: response.group.group_name,
          group_id: response.group.group_id,
          tags: response.group.group_tags
        };
  
        return of(new ControlGroup(responseGroupData));
      })
    );
  }

  /**
   * Specific GET methods
   */

  private fetchGroups(clientId, limit = 20, offset = 0): ReplaySubject<ControlGroup[]> {
    this.groups$ = new ReplaySubject();
    const url = `${this.baseUrl(clientId)}/group/`;

    this.getAll(url, limit, offset).pipe(
      map((groups) => _.map(groups, (group) => new ControlGroup(group)))
    ).subscribe(this.groups$);

    return this.groups$;
  }

  fetchTags(clientId = this.clientId, limit = 1000, offset = 0): ReplaySubject<ControlTag[]> {
    this.tags$ = new ReplaySubject();
    const url = `${this.baseUrl(clientId)}/tag`;

    this.getAll(url, limit, offset).pipe(
      map((tags) => _.map(tags, (tag) => new ControlTag(tag)))
    ).subscribe(this.tags$);

    return this.tags$;
  }

  private fetchTagsByGroup(groupId, clientId, limit = 20, offset = 0): ReplaySubject<ControlTag[]> {
    this.tagsByGroup$[groupId] = new ReplaySubject();
    const url = `${this.baseUrl(clientId)}/group/${groupId}/tag`;

    this.getAll(url, limit, offset).pipe(
      map((tags) => _.map(tags, (tag) => new ControlTag(tag)))
    ).subscribe(this.tagsByGroup$[groupId]);

    return this.tagsByGroup$[groupId];
  }

  private fetchTag(tagId, clientId): Observable<ControlTag> {
    const url = `${this.baseUrl(clientId)}/tag/${tagId}`;
    return this.http.get(url, {}, true).pipe(
      map((response) => new ControlTag(response['data']))
    );
  }

  private fetchTagsForAgent(clientId, limit = 20, offset = 0): Observable<ControlTag[]> {
    const url = `${this.baseUrl(clientId)}/agent/tags`;

    return this.getAll(url, limit, offset).pipe(
      map((tags) => _.map(tags, (tag) => new ControlTag(tag)))
    );
  }

  /**
   * Generic GET utils
   */

  // returns observable that'll emit once with all results
  private getAll(url, limit = 20, offset = 0): Observable<any> {
    return this.getBatches(url, limit, offset).pipe(
      reduce((accumulatedItems, newItems) => accumulatedItems.concat(newItems), [])
    );
  }

  // returns observable that'll emit once until all batches have been fetched
  private getBatches(url, limit = 20, offset = 0): Observable<any> {
    return this.getBatch(url, limit, offset).pipe(
      expand((items, index) => {
        const newOffset = (index + 1) * limit;
        return items.length === limit ? this.getBatch(url, limit, newOffset) : empty();
      })
    );
  }

  // returns observable that emits once for one batch
  private getBatch(url, limit, offset): Observable<any> {
    const batchUrl = `${url}?limit=${limit}&offset=${offset}`
    return this.http.get(batchUrl, {}, true).pipe(
      map((result) => result['data'])
    );
  }

  /**
  * Private Utils
  */

  private baseUrl(clientId): string {
    return `${environment.twoWayURLBase}/client/${clientId}`;
  }

  private get clientId(): string {
    return this.sessionService.selectedClient.getValue();
  }
}
