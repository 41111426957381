<div>
    <tab-bar [tabs]="tabs" [(currentTabId)]="currentTabId" (currentTabIdChange)="onTabChange($event)"></tab-bar>
        <!-- BEG: API Key Management -->
        <div *ngIf="isApiKeyManagementTabDisplayed">
            <rn-apikey-mgmt></rn-apikey-mgmt>
        </div>
        <!-- END: API Key Management -->
        <!-- BEG: Push API Integration -->
        <div *ngIf="isPushApiTabDisplayed">
            <rn-push-api></rn-push-api>
        </div>
        <!-- END: Push API Integration -->
</div> 