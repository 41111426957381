import { Component, Input, OnInit } from '@angular/core';
import { Client, ProductGroup } from '@app/core/models/client';
import { WireClass } from '@app/core/models/message';

@Component({
  selector: 'sign-up-preview',
  templateUrl: './sign-up-preview.component.html',
  styleUrls: ['./sign-up-preview.component.scss']
})
export class LoginPreviewComponent implements OnInit {
  @Input() productGroup: ProductGroup;
  @Input() client: Client;

  wire: WireClass;
  viewProductInfo: boolean = false;

  constructor() { }

  ngOnInit() {
    this.wire = new WireClass();
    this.wire.text = "";
    this.wire.actions = [];
  }

}
