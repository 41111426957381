<app-quick-launch-content *ngIf="!customerValidated"
  (launch)="setValidation($event)"
  [validationModel]="validationModel">
</app-quick-launch-content>

<app-test-launcher *ngIf="customerValidated"
  [journeyId]="selectedJourney"
  [ccid]="ccid"
  [validation]="validationModel"
  (validationFailed)="onValidationFailed($event)">
</app-test-launcher>

<message-dialog [showCancel]="false"></message-dialog>
