<div class="rn-page">
  <div class="loading-page" *ngIf="loading">
    <h2>Loading Two Way Messaging</h2>
    <app-spinner></app-spinner>
  </div>

  <div class="rn-page__section" *ngIf="error && !loading">
    <div class="rn-page--error">
      <h1>{{error}}</h1>
    </div>
  </div>

  <ng-container *ngIf="!error && !loading">
    <div class="rn-page__section--left">
      <div class="tag-select">
        <tag-select *ngIf="tags" [tags]="tags" (filter)="tagFilterChange($event)"></tag-select>
      </div>
      <div class="launch-select">
        <launch-select *ngIf="launches" [launches]="launches" (filter)="launchFilterChange($event)"></launch-select>
      </div>
      <hr>
      <div class="assigned-messages">
        <assigned *ngIf="tags"
                  [tags]="tags"
                  [tagFilter]="$tagFilter"
                  [launchFilter]="$launchFilter"
                  (selectedChannelEmitter)="setSelectedChannel($event)"
                  [$selectedChannel]="$selectedChannel"></assigned>
      </div>
      <hr>
      <div class="unassigned-messages">
        <unassigned *ngIf="tags"
                    [tags]="tags"
                    [tagFilter]="$tagFilter"
                    [launchFilter]="$launchFilter"
                    (selectedChannelEmitter)="setSelectedChannel($event)"></unassigned>
      </div>
    </div>
    <div class="rn-page__section--middle">
      <div class="wrapper messaging" *ngIf="selectedChannel">
        <message-window
          (textAreaHasFocus)="textAreaFocused($event)"
          [selectedChannel]="selectedChannel"
          [$selectedChannel]="$selectedChannel"></message-window>
      </div>
    </div>
    <div class="rn-page__section--right">
      <two-way-customer-details [$selectedChannel]="$selectedChannel"></two-way-customer-details>
    </div>
  </ng-container>
  <audio #audioControl>
    <source src="/assets/audio/ping-bing.wav" type="audio/wav" />
    <source src="/assets/audio/ping-bing.ogg" type="audio/ogg" />
  </audio>
  
</div>
