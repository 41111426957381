<div class="select-tag-container">
  <h4>Launched By</h4>
  <select class="form-control" id="select-tag" [(ngModel)]="selectedLaunch">
    <option value="Select">Select</option>
    <option *ngFor="let launch of filterLaunches" [value]="launch.lb_name">{{launch.lb_name}}</option>
  </select>
  <button class="btn btn-primary btn-block" (click)="addLaunch()">Filter</button>
</div>

<div class="selected-tags">
  <div *ngFor="let l of selectedLaunches">
    <button class="btn btn-primary btn-remove" (click)="removeLaunch(l.lb_name)">
      <span>{{l.lb_name}}</span>
    </button>
  </div>
</div>
