/**
 * This class helps manage "pages" of data returned from API calls used in a
 * sorted infinite scroll list. It ensures that the pages are maintained in order
 * even though the API calls may not complete in the order they were made.
 */

export class PageManager {
  pages = new Map<number, any>();
  
  addPage(ordinal: number, page: any) {
    this.pages.set(ordinal, page);
  }
  
  flattenPages(): any[] {
    const ordinals = Array.from(this.pages.keys()).sort((a, b) => a - b);
    let flatArray = [];
    
    ordinals.forEach(ordinal => {
      flatArray = flatArray.concat(this.pages.get(ordinal));
    });
    
    return flatArray;
  }
  
  forEachPage(f: (page: any) => void) {
    Array.from(this.pages.values()).forEach(page => { f(page); });
  }
}
