import {
  CategoryWithAncestors,
  HasAncestors,
} from '@app/core/models/categorization-types';

export class CategoryAncestryDetails {
  industryId: string;
  companyTypeId?: string;
  outcomeId?: string;
  fullKey: string = '';

  constructor({
    industryId,
    companyTypeId = '',
    outcomeId = '',
  }: {
    industryId: string;
    companyTypeId?: string;
    outcomeId?: string;
  }) {
    this.companyTypeId = companyTypeId;
    this.industryId = industryId;
    this.outcomeId = outcomeId;

    const rawKey = `${this.industryId}.${this.companyTypeId}.${this.outcomeId}`;
    this.fullKey = rawKey.replace(/\.+$/, '');
  }
}

export class CategorizationAncestorUtils {
  constructor() {}

  /**
   * Generic function used to tell if an object of type {@link Category} & {@link HasAncestors} has a closest
   * ancestor that matches provided id.
   * @param param0 Category with ancestors
   * @param ancestorId
   * @returns
   */
  hasMatchingClosestAncestor<T extends HasAncestors>(
    { ancestor_paths }: T,
    ancestorId: string,
  ): boolean {
    return (
      ancestor_paths.findIndex((ancestorPath) => {
        const closestAncestorId = ancestorPath.split('.').pop();
        return closestAncestorId === ancestorId;
      }) > -1
    );
  }

  getChildrenForParent(
    allPotentialChildren: CategoryWithAncestors[],
    parentId: string,
  ): CategoryWithAncestors[] {
    return allPotentialChildren.filter((potentialChild) => {
      return this.hasMatchingClosestAncestor(potentialChild, parentId);
    });
  }

  getChildrenForParentAncestor(
    allPotentialChildren: CategoryWithAncestors[],
    ancestryDetails: CategoryAncestryDetails,
  ): CategoryWithAncestors[] {
    return allPotentialChildren.filter((potentialChild) => {
      return potentialChild.ancestor_paths.some(
        (path) => path === ancestryDetails.fullKey,
      );
    });
  }
}
