<h3>Select appropriate customer experience</h3>
<p class="error-msg" *ngIf="journeysSelected.length > 100">* You currently have {{journeysSelected.length}} Experiences Selected.  We recommend keeping it below 100.</p>
<div class="btn-container">
  <ng-container *ngIf="journeysSelected && journeysSelected.length > 0">
    <app-journey-icon *ngFor="let journey of journeysSelected; let i = index"
                      [clientId]="client.id" [journeyId]="journey" [backgroundColor]="generateColor(i)"
                      [isPreview]="false"    (removeJourney)="journeyToRemove = $event"></app-journey-icon>
  </ng-container>
  <app-select-journey-button></app-select-journey-button>
</div>

<app-remove-journey-modal *ngIf="journeyToRemove" (cancelRemove)="journeyToRemove = undefined"
                                                  (affirmRemove)="removeJourney(journeyToRemove)"></app-remove-journey-modal>
