<div class="grid-row">
    <div class="row">
        <div class="col-sm-2 company-type-id-col truncate">
            <span class="company-type-id">{{companyType.id}}</span>
            </div>

            <div class="col-sm-2 company-type-name-col truncate">
            <span class="company-type-name">{{companyType.title["en-us"]}}</span>
        </div>

        <div class="col-sm-3 company-type-industries-col">
            <span class="industries truncate">
                {{getFirstItemName(companyType, categoryIndex.Industries)}}
            </span>
            <div *ngIf="hasAdditionalItems(companyType, categoryIndex.Industries)" class="additional-industries-bubble">
                <span class="additional-industries-bubble__count">
                    +{{getAdditionalCount(companyType, categoryIndex.Industries)}}
                </span>
            </div>
        </div>

        <div class="col-sm-2 truncate">
            <span>{{companyType.created_at | date:'shortDate'}}</span>
        </div>

        <div class="col-sm-2 truncate">
            <span>{{companyType.updated_at | date:'shortDate'}}</span>
        </div>

        <div class="color-sm-1 action-column">
            <i id="edit-company-type-btn-{{companyType.id}}" class="fa fa-pencil" aria-hidden="true" (click)="emitEditEvent()"></i>
            <div *ngIf="hasAdditionalItems(companyType, categoryIndex.Industries)" class="expander-div">
                <i *ngIf="!detailsOpen" (click)="toggleDetails()" class="fa fa-caret-right expander-image"></i>
                <i *ngIf="detailsOpen" (click)="toggleDetails()" class="fa fa-caret-down expander-image"></i>
            </div>
        </div>
    </div> 
</div>

<ng-container *ngIf="hasAdditionalItems(companyType, categoryIndex.Industries) && detailsOpen">
    <div class="company-type-detail">
        <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
                <div *ngFor="let industry of getAdditionalItems(companyType, categoryIndex.Industries)" class="additional-industry">
                    {{industry}}
                </div>
            </div>
        </div> 
    </div>
</ng-container>
