import React from 'react';
import {
  Card,
  FormFieldItem,
  HTML,
  StyleProvider,
} from '@relaynetwork/design-system';
import { FeedHeader } from '../feed-header/FeedHeader';
import type { ProductGroup } from '@app/core/models/client';

export type SetPasswordPagePreviewProps = {
  pswMessageText: ProductGroup['password']['label'];
  pswButtonLabel: ProductGroup['password']['label'];
  pswTCS: ProductGroup['password']['tcs'];
  pswTCMandatory: ProductGroup['password']['tc_mandatory'];
  logoUrl: ProductGroup['branding']['icon_s3_url'];
  color: ProductGroup['branding']['color'];
  showProductGroup: ProductGroup['hide_pg_wire_display_name'];
  productGroupName: ProductGroup['name'];
  productGroupDescription: ProductGroup['description'];
  fdicSignage: ProductGroup['fdic'];
  showFdic: boolean;
};

export default function SetPasswordPagePreview({
  pswMessageText,
  pswButtonLabel,
  pswTCS,
  pswTCMandatory,
  logoUrl,
  color,
  showProductGroup,
  productGroupName,
  productGroupDescription,
  fdicSignage,
  showFdic
}: SetPasswordPagePreviewProps) {
  const fields: FormFieldItem[] = [
    {
      field: 'password',
      prompt: 'Password',
      type: 'password',
      alwaysShowHints: true,
      required: true,
      upperCaseRequired: true,
      lowerCaseRequired: true,
      numberRequired: true,
      minimumLength: 8,
      maximumLength: 256,
    },
    {
      field: 'confirm_password',
      prompt: 'Confirm Password',
      type: 'password',
      required: true,
      equalityCheckField: 'password',
    },
  ];

  return (
    <StyleProvider>
      <FeedHeader
        productGroupDescription={productGroupDescription}
        productGroupName={productGroupName}
        showProductGroup={showProductGroup}
        logoUrl={logoUrl}
        color={color}
        isPreview={true}
        showFDIC={showFdic}
        fdic={fdicSignage}
      />
      <div
        style={{
          marginTop: '1rem',
          marginLeft: '0',
          marginRight: '0',
        }}
      >
        <Card
          id="set-password-page-preview"
          showHeader={true}
          variant="passwordLogin"
          isMobile={false}
          fields={fields}
          submitButtonLabel={pswButtonLabel}
          disclaimerContent={pswTCS} // when card variant is passwordLogin, disclaimerContent is actually a Terms and Conditions text
          showTermsAndConditions={true}
          pswTCMandatory={pswTCMandatory}
          showAcceptanceOption={true}
        >
          <div
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <HTML markup={pswMessageText ?? ''}></HTML>
          </div>
        </Card>
      </div>
    </StyleProvider>
  );
}
