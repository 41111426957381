import { Component, OnInit, OnDestroy } from '@angular/core';
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';
import { TitleService } from '@app/core/services/title.service';
import { ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { User } from '@app/core/models/user';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';

@Component({
  selector: 'app-setup-summary',
  templateUrl: './setup-summary.component.html',
  styleUrls: ['./setup-summary.component.scss'],
})
export class SetupSummaryComponent implements OnInit, OnDestroy {
  twoWayConfig: TwoWayConfig;
  twoWayUsers: User[];
  defaultProductGroup: ProductGroup;
  currentClient: Client;

  constructor(
    private titleService: TitleService,
    private twoWaySetupService: TwoWaySetupService,
    public newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleService.activate(
      this.currentClient.feed_enabled
        ? 'Relay Messenger Setup'
        : 'Two Way Messaging Setup',
      'Summary',
    );
    this.twoWaySetupService.getTwoWayConfig().subscribe((twoWayConfig) => {
      this.twoWayConfig = twoWayConfig;
    });

    this.twoWaySetupService
      .getDefaultProductGroup()
      .subscribe((defaultProductGroup) => {
        this.defaultProductGroup = defaultProductGroup;
      });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  isLoading() {
    return this.twoWayConfig === undefined;
  }
}
