
import {of, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { SessionService } from '@app/security/session.service';
import { environment } from '@env/environment';
import { switchMap, tap, filter, } from 'rxjs/operators';

import * as _ from 'lodash';

const bytesPerKb = 1024;

export interface InitializationResult {
  header_errors: object;
  errors: string[]; 
  count: number;
  job_id: string;
}

export interface ProgressData {
  bytes: number;
  totalBytes: number;
  percent: number;
}

export type Filetype = 'messaging' | 'onboarding' | 'cca' | 'phone_management' | 'consent_update' | 'deactivation' | 'journey_messaging';

@Injectable()
export class FileUploadService {
  file: File;
  jobId: string;
  progress: BehaviorSubject<ProgressData> = new BehaviorSubject({ bytes: 0, percent: 0, totalBytes: 0 });
  totalBytes: number;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService) {
  }

  /*
    Goes through these steps: 
      1. request a presigned S3 url + job ID
      2. Upload the File to the returned presigned url & report progress
      3. call the validation endpoint for file validation results
      4. put all the bits together into an InitializationResult object and return it through the Observable
    It intentionally does not store any data after it's finished.  job Id & files must be passed in next time a method is used.
  */
  initializeAndValidate(file: File, filetype: Filetype, triggerId?: string): Observable<InitializationResult> {
    this.file = file;
    this.totalBytes = Math.ceil(file.size / bytesPerKb);
    this.progress = new BehaviorSubject({ bytes: 0, percent: 0, totalBytes: file.size });
    
    return this.getPresignedUrl(file, filetype, triggerId).pipe(
      tap((response) => this.jobId = response['job']['job_id']), // save job id on the instance so it's accessible down the chain
      switchMap((response) => { // creates an inner observable that'll report upload events.  
        return this.uploadToS3(response['job']['s3_url'], file).pipe(
          tap((event) => this.reportProgressEvents(event)), // progress events come from the behaviorsubject
          filter((event) => event instanceof HttpResponse) // only return to the outer observable when the request is complete
        );
      }),
      switchMap((response) => { // will only run after inner observable is complete
        return this.validateFile(this.clientId, this.jobId);
      }),
      switchMap((validationResult) => {
        validationResult['response']['job_id'] = this.jobId;  // adds jobId to validation result so it's available to subscribers
        return of(validationResult['response'] as InitializationResult)
      }),
      tap(() => this.clearData()) // clears old data so nobody will be tempted to use it
    );
  }

  schedule(jobId: string, datetime: Date): Observable<object> {
    const scheduleFileUrl = `${environment.cdmURLBase}/client/${this.clientId}/job/${jobId}`;
    return this.http.put(scheduleFileUrl, { 
      command_type: 'schedule',
      schedule_at: datetime
    });
  }

  run(jobId: string): Observable<object> {
    const runFileUrl = `${environment.cdmURLBase}/client/${this.clientId}/job/${jobId}`;
    return this.http.put(runFileUrl, { 
      command_type: 'run'
    });
  }

  private getPresignedUrl(file: File, filetype: Filetype, triggerId: string = null): Observable<object> {
    const presignedUrlUrl = `${environment.cdmURLBase}/client/${this.clientId}/job`;
    const data = {
      filename: file.name,
      filetype: filetype, 
    };

    if (triggerId) { data['trigger_id'] = triggerId; }

    return this.http.post(presignedUrlUrl, data);
  }

  private uploadToS3(s3Url: string, file: File): Observable<object> {
    // not sure why this.http.post doesn't have the same contract here but... 
    // it seems to return a different type of object, so I can't use it
    const req = new HttpRequest('PUT', s3Url, file, { 
      reportProgress: true, 
      headers: new HttpHeaders({ 
        'Content-Type': 'text/csv' 
      })
    });
    return this.http.request(req)
  }

  private reportProgressEvents(event: any): void {
    if (event.type === HttpEventType.UploadProgress) {
      const eventData: ProgressData = {
        percent: Math.round(100 * event.loaded / event.total),
        bytes: Math.ceil(event.loaded / bytesPerKb),
        totalBytes: this.totalBytes
      };
      this.progress.next(eventData);
    }
    
    if (event instanceof HttpResponse) {
      this.progress.complete();
    }
  }

  private validateFile(clientId: string, jobId: string): Observable<Object> {
    const validationUrl = `${environment.cdmURLBase}/client/${clientId}/job/${jobId}/validation`
    return this.http.put(validationUrl, {});
  }

  private clearData(): void {
    this.file = undefined;
    this.jobId = undefined;
  }

  private get clientId(): string {
    return this.sessionService.selectedClient.value;
  }
}
