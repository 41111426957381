import { MessageDelayerClass, ComponentBaseClass } from '../../core/models/message';
import { DelayUtils } from '../delay-utils';
import { SelectableJourneyComponent } from '../selectable-journey-component';
import { ToolbarService } from '../services/toolbar.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

export class DelayUnit {
  num: number;
}

@Component({
  selector: 'journey-delay',
  templateUrl: './journey-delay.component.html',
  styleUrls: ['./journey-delay.component.scss']
})
export class JourneyDelayComponent implements OnInit, SelectableJourneyComponent {
  @Input() delay: MessageDelayerClass = new MessageDelayerClass();
  @Input() selected: boolean;
  @Output() deleteCmp = new EventEmitter;
  @ViewChild("delayBox", { read: ElementRef, static: true }) delayElement: ElementRef;
  
  delayUnits: number[];
  
  boxMenuItems = [
    {
      labelText: 'Delete delay',
      onClickHandler: () => { this.deleteComponent(); }
    },
  ];
  
  constructor(private toolbarService: ToolbarService) { 
  }
  
  ngOnInit(): void {
    this.setUnitArray();
    
    // Updates to this delay's interval type might come from the inspector (or from this component).
    this.toolbarService.delayIntervalTypeUpdate.subscribe(delayName => {
      if (this.delay.name === delayName) {
        this.setUnitArray();
      }
    });
  }
  getJourneyComponentElementRef(): ElementRef {
      return this.delayElement;
  }
  getJourneyComponent(): ComponentBaseClass {
    return this.delay;
  }
  deleteComponent() {
      this.deleteCmp.emit(this.delay);
  }
  deselectChildren() {
    // Delays don't have children, so nothing to do.
    return;
  }
  onIntervalTypeChange() {
    this.toolbarService.delayIntervalTypeUpdate.emit(this.delay.name);
  }
  onSelectClick(event) {
    // Prevent selecting a new time delay value from selecting and re-centering the whole time delay.
    event.stopPropagation();
  }
  setUnitArray() {
    this.delayUnits = DelayUtils.allUnits[this.delay.interval_type];
    
    // If the new delay units doesn't have the interval_value, use the last delay unit value.
    if (this.delayUnits.indexOf(this.delay.interval_value) < 0) {
      this.delay.interval_value = this.delayUnits[this.delayUnits.length - 1];
    }
  }
}
