import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductGroup } from '@app/core/models/client';
import * as _ from 'lodash';

@Component({
  selector: 'app-product-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.scss']
})
export class HelpTextComponent implements OnInit {

  @Input() productGroup: ProductGroup;
  helpTextForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.helpTextForm = this.formBuilder.group({
      'help_instructions': [this.productGroup.help_instructions, Validators.required],
      'help_instructions_spanish': [this.productGroup.help_instructions_spanish]
    });

    this.helpTextForm.valueChanges.subscribe((val) => _.assign(this.productGroup, val));
  }
}
