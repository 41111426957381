import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { SecureHttp } from '@app/security/secure-http';
import { environment } from '@env/environment';
import { Journey } from '@app/core/models/journey';
import { TemplateExperience } from '@app/core/models/template-experience';
import { CopyExperienceLibrary } from '@app/core/models/copy-experience-library';
import { Filter, SearchCriteria } from '../utils/search-criteria';
import { Ordering } from '../utils/ordering';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeatureService } from './feature.service';
import { FeatureFlags } from '../models/feature-flags';

@Injectable()
export class ExperienceLibraryService {

  experienceLibraryURLBase: string = environment.experienceLibraryURLBase;
  portalURLBase: string = environment.portalURLBase;
  constructor(private secureHttp: SecureHttp,
              private rawHttp: HttpClient,
              private featureService: FeatureService) {
  }

  /**
   * Retrieve template experiences for the given client.
   *
   * @param clientId the client requesting the templates
   * @param limit
   * @param offset
   * @param searchCriteria
   * @param ordering
   * @param hideMask
   * @returns the template experiences
   */
  getExperiences(
    clientId: string,
    limit: number,
    offset: number,
    searchCriteria: SearchCriteria,
    ordering?: Ordering,
    hideMask: boolean = true): Observable<TemplateExperience> {

    if (!searchCriteria) {
      searchCriteria = new SearchCriteria();
    }
    searchCriteria.filtersIfFieldExists.push(new Filter('deleted', 'false'));

    let url = `${this.experienceLibraryURLBase}/client/${clientId}/templates?limit=${limit}&offset=${offset}`;
    if (ordering) {
      url = `${url}&${ordering.getQueryStringUnderscorish()}`;
    }
    const searchRequest = searchCriteria.getSearchRequest();
    searchRequest['include_draft'] = false;

    return this.secureHttp.post(url, searchRequest, null, hideMask).pipe(take(1));
  }

  postCopyExperience(clientId: string, experience: Journey): Observable<string> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/experiences`;
    return this.secureHttp.post(url, this.buildCopyExperienceLibrary(clientId, experience));
  }

  getExperienceExport(clientId: string, experienceId: string, usePortalUrl: boolean): Observable<any> {
    const baseUrl = usePortalUrl ? this.portalURLBase : this.experienceLibraryURLBase;
    const url = `${baseUrl}/client/${clientId}/transport/${experienceId}`;
    return this.rawHttp.get(url);
  }

  validateExportedExperience(clientId: string, exportedExperience: string): Observable<any> {
    let requestUrl: string;

    this.featureService.checkFlag(FeatureFlags.new_experience_transport)
      ? requestUrl = `${this.portalURLBase}/client/${clientId}/transport/validate`
      : requestUrl = `${this.experienceLibraryURLBase}/client/${clientId}/transport/validate`;

    return this.secureHttp.post(requestUrl, { data: exportedExperience }, {}, true);
  }

  postExportedExperience(clientId: string, exportedExperience: string): Observable<any> {
    const url = `${this.experienceLibraryURLBase}/client/${clientId}/transport`;
    return this.secureHttp.post(url, { data: exportedExperience }, {}, true);
  }

  importExperience(clientId: string, exportedExperience: string, checksum: string): Observable<{experienceId: string}> {
    const url = `${this.portalURLBase}/client/${clientId}/transport`;
    return this.secureHttp.post(url, { data: exportedExperience, checksum }, {}, true);
  }

  buildCopyExperienceLibrary(clientId: string, experience: Journey): CopyExperienceLibrary {
    return {
      target: {
        clientId: clientId
      },
      source: {
        clientId: experience.client_id,
        experienceId: experience.id
      }
    }
  }
}
