import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ActionItem } from './ActionItem';

export enum Actions {
  PingCustomer = 'Ping Customer',
  SendExperience = 'Send Experience',
  CloseConversation = 'Close Conversation',
}

export type CustomerActionsProps = {
  closePopover: () => void;
  setIsPingModalOpen: (value: boolean) => void;
  setIsCloseChannelModalOpen: (value: boolean) => void;
  navigateToExperienceLauncher: () => void;
};

export const CustomerActions = ({
  closePopover,
  setIsPingModalOpen,
  setIsCloseChannelModalOpen,
  navigateToExperienceLauncher,
}: CustomerActionsProps) => {
  const [css] = useStyletron();

  return (
    <Block padding={'10px'}>
      <ActionItem
        text={Actions.PingCustomer}
        color="#3693BF"
        onClick={() => {
          closePopover();
          setIsPingModalOpen(true);
        }}
        dataTestId="ping-customer-btn"
      />
      <ActionItem
        text={Actions.SendExperience}
        color="#3693BF"
        onClick={() => {
          closePopover();
          navigateToExperienceLauncher();
        }}
      />
      <hr className={css({ marginTop: '5px', marginBottom: '5px' })} />
      <ActionItem
        text={Actions.CloseConversation}
        color="#FF7070"
        onClick={() => {
          closePopover();
          setIsCloseChannelModalOpen(true);
        }}
        dataTestId="close-conversation-btn"
      />
    </Block>
  );
};
