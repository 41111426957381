import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NewFeedTransitionService} from '@app/core/services/new-feed-transition.service';

@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss']
})
export class ProductReportComponent implements OnInit {
  @Input() client: Client;
  @Input() product: ProductGroup;

  clientId: string;
  productId: string;
  screen: string = 'feed';

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private messageDialog: MessageDialogComponent,
              private router: Router,
              public newFeed: NewFeedTransitionService) {
  }

  ngOnInit() {
    this.getClient();
  }

  navigateToPrevious(): void {
    this.router.navigateByUrl(`/client/${this.clientId}/report`);
  }

  private getProductGroup(): void {
    this.clientService.getProductGroup(this.client.id, this.productId).subscribe(
      productGroup => {
        this.product = productGroup;
      },
      error => {
        this.messageDialog.showMessage(`Oops...couldn't get a list of product groups for ${this.client.id}.`);
      }
    );
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientId = params['clientId'];
      this.productId = params['productId'];
      this.clientService.getClient(params['clientId']).subscribe(
        client => {
          this.client = client;
          if (this.productId) {
            this.getProductGroup();
          } else {
            this.messageDialog.showMessage(`Oops...Could not load product group: ${params['productId']}`);
          }
        },
        error => {
          this.messageDialog.showMessage(`Oops...Could not load client: ${params['clientId']}`);
        });
    });
  }
}
