import { JourneyContent } from './journey';

import * as traverse from 'traverse';

export class InputParamUtil {
  public static readonly inputParamPattern = /@{[A-Za-z0-9_\-.]+}/;

  public static readonly inputParamPatternFull = /^@{[A-Za-z0-9_\-.]+}$/;

  static findAllInputParams(
    journeyContent: JourneyContent,
    triggerId: string,
  ): Array<string> {
    const triggerComponent = journeyContent.components.find(
      (c) => c.name === triggerId,
    );
    const inputParams = this.getInputParametersForComponent(
      triggerComponent,
      journeyContent.components,
    );
    let inputParamsArray = Array.from(inputParams);
    inputParamsArray = inputParamsArray.filter((s) => !s.match(/CCID/i));
    inputParamsArray.unshift('CCID');
    return inputParamsArray;
  }

  static removeAutoCompleteSpans(text): string {
    // Replace tinymce-mentions autocomplete spans with just the text of the input parameter.
    const regex1 =
      /<span id="autocomplete"><span id="autocomplete-delimiter">[@#]<\/span><span id="autocomplete-searchtext"><span class="dummy">(.*)<\/span><\/span><\/span>/;
    const regex2 =
      /<span id="autocomplete-delimiter">[@#]<\/span><span id="autocomplete-searchtext"><span class="dummy">(.*)<\/span><\/span>/;
    let match1 = null;
    let match2 = null;
    do {
      match1 = text.match(regex1);
      if (match1) {
        text = text.replace(regex1, '@' + match1[1]);
      }

      match2 = text.match(regex2);
      if (match2) {
        text = text.replace(regex2, '@' + match2[1]);
      }
    } while (match1 || match2);
    return text;
  }

  static findCompleteJourneyParams(
    journeyContent: JourneyContent,
  ): Array<string> {
    const triggerComponents = journeyContent.components.filter(
      (c) => c.type === 'APITrigger' || c.type === 'WorkflowStarter',
    );
    let paramsArray: string[];
    const paramSet = new Set<string>();

    triggerComponents.forEach((element) => {
      InputParamUtil.getInputParametersForComponent(
        element,
        journeyContent.components,
      ).forEach((param) => {
        paramSet.add(param);
      });
    });

    paramsArray = Array.from(paramSet);
    paramsArray = paramsArray.filter((s) => !s.match(/CCID/i));
    paramsArray.unshift('CCID');
    return paramsArray;
  }

  static findInputParametersForObject(object: any): Array<string> {
    const params = this.getInputParametersForObject(object);
    return Array.from(params);
  }

  private static getInputParametersForObject(obj: any): Set<string> {
    const objectInputParams = new Set<string>();
    traverse(obj).forEach((value) => {
      if (typeof value === 'string') {
        const valueInputParams = this.getInputParametersForValue(value);
        valueInputParams.forEach((param) => {
          objectInputParams.add(param);
        });
      }
    });
    return objectInputParams;
  }

  private static getInputParametersForComponent(
    component: any,
    allComponents: Array<any>,
  ): Set<string> {
    if (!component) {
      return new Set<string>();
    }

    const inputParams = this.getInputParametersForObject(component);

    // Process any message actions
    if (
      (component.type === 'MessageSender' ||
        component.type === 'MessageResponse') &&
      component.wire &&
      component.wire.actions
    ) {
      for (let i = 0; i < component.wire.actions.length; i++) {
        const action = component.wire.actions[i];
        if (action.type === 'message' && action.value) {
          const messageActionComponent = allComponents.find(
            (c) => c.name === action.value,
          );
          const messageActionInputParams = this.getInputParametersForComponent(
            messageActionComponent,
            allComponents,
          );
          messageActionInputParams.forEach((param) => inputParams.add(param));
        }
      }
    }

    // Process the next message if any
    if (component.to) {
      const nextMessageComponent = allComponents.find(
        (c) => c.name === component.to,
      );
      const nextMessageInputParams = this.getInputParametersForComponent(
        nextMessageComponent,
        allComponents,
      );
      nextMessageInputParams.forEach((param) => inputParams.add(param));
    }
    return inputParams;
  }

  private static getInputParametersForValue(text: string): Set<string> {
    let inputVars = [];
    const re1 = /@{[A-Za-z0-9_\-.]+}/g;
    const re2 = /#{[A-Za-z0-9_\-.]+}/g;

    inputVars.push(text.match(re1));
    inputVars.push(text.match(re2));

    // flatten to single array
    inputVars = [].concat.apply([], inputVars);
    // remove null values
    inputVars = inputVars.filter(function (n) {
      return n !== undefined && n !== null;
    });
    // remove duplicate values
    const uniq = new Set(inputVars);
    const stripped = new Set();
    uniq.forEach((param) => {
      stripped.add(this.stripParameterName(param));
    });
    stripped.delete('auth-link');
    stripped.delete('auth-link-top-of-feed');
    stripped.delete('custom-tracked-url');
    stripped.delete('auth-link-experience-on-feed');
    stripped.delete('product_group_name');
    return stripped as Set<string>;
  }

  private static stripParameterName(parameterName: string) {
    return parameterName.substring(
      '@{'.length,
      parameterName.length - '}'.length,
    );
  }
}
