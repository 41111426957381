import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { ArrowRight, Search } from 'baseui/icon';
import { Button, SHAPE, SIZE } from 'baseui/button';
import React from 'react';
import { Combobox } from 'baseui/combobox';

export type SearchProps = {
  setIsSearchInputOpen: (value: boolean) => void;
  isSearchInputOpen: boolean;
  tagsAndCustomersList: string[];
  searchOptions: string[];
  setSearchOptions: (value: string[]) => void;
  isAgent?: boolean;
};

export const SearchInput = ({
  setIsSearchInputOpen,
  isSearchInputOpen,
  tagsAndCustomersList,
  searchOptions,
  setSearchOptions,
  isAgent = false,
}: SearchProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const [value, setValue] = React.useState("");

  const setSearchOption = (value) => {
          setValue("");
          if (!searchOptions.includes(value)) {
            let searchValue = value
            if (isAgent && value && typeof value === 'string') searchValue = value.toLowerCase()
            const newSearchOptions = [...searchOptions, searchValue];
            setSearchOptions(newSearchOptions);
          }
  }

  if (isSearchInputOpen) {
    return (
    <>
      <Combobox
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
        }}
          onSubmit={(selected) => {
          setSearchOption(selected.value);
          }

          }
        options={tagsAndCustomersList}
        mapOptionToString={option => option.label}
            overrides={{
            Root: {
            style: ({ $theme }) => ({
              width: `100%`,
              backgroundColor: '#FFFFFF',
              outline: `#00000`,
            })
          },
              Input: {
                props: {
                  id: 'messenger-search-input',
                  placeholder: isAgent? 'Search by first or last name' :'Search by tags or customer name',
                  startEnhancer: <Search size="18px" />,
                  endEnhancer: <Button shape={SHAPE.circle} size="mini" id='search-submit' onClick={()=>{
                    setSearchOption(value)
                    }} overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        backgroundColor: '#3693bf',
                        color: `#FFFFFF`, 
                    })
                  }}}><ArrowRight size="24px"></ArrowRight></Button>,
                  overrides: {
                    Root: {
                      style: ({ $theme }) => ({
                        // outline: `#00000`,
                        backgroundColor: '#FFFFFF',
                        padding: `0px`,
                      })
                    },
                    Input: {
                      style: ({ $theme }) => ({
                        backgroundColor: '#FFFFFF',
                        padding: `10px 2px`,
                      })
                    },
                    StartEnhancer: {
                      style: ({ $theme }) => ({
                        backgroundColor: '#FFFFFF',
                      })
                    }
                  }
                },
                }
            }}
      />
      <Button
        onClick={() => setIsSearchInputOpen(false)}
        size={SIZE.compact}
        kind={"tertiary"}
        overrides={{
          BaseButton: {
            props: { 'data-testid': 'search-actions-close-btn' },
          },
        }}
      >
        Close
      </Button>
    </>  
  );
    }

  if (!isSearchInputOpen) {
  return (
      <Button
        onClick={() => setIsSearchInputOpen(true)}
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isSearchInputOpen
                ? '#3693BF'
                : customTheme.colors.white,
              color: isSearchInputOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'search-actions-btn' },
          },
        }}
      >
        <Search size={28} />
      </Button>
  );
  }
}