import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rn-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent {
  @Input() links: { text: String, path: String }[];
  @Input() color: string;

  constructor() {}

  isCurrent(breadcrumbLink): boolean {
    return window.location.pathname === breadcrumbLink.path;
  }

  isRelayGreen(): boolean {
    return this.color === 'relay-green';
  }

  isLightGray(): boolean {
    return this.color === 'light-gray-1' || this.color === undefined;
  }
}
