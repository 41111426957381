// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';

// Model and Service Imports
import { Client } from '../../../../core/models/client';


@Component({
  selector: 'app-client-feature-config',
  templateUrl: './client-feature-config.component.html',
  styleUrls: ['./client-feature-config.component.scss']
})
export class ClientFeatureConfigComponent implements OnInit {
  @Input() client: Client;

  constructor() { }

  ngOnInit() {
  }

}
