import { Pipe, PipeTransform } from "@angular/core";
import { FormatUtils } from '@app/core/utils/format-utils';

@Pipe({
  name: 'inputField'
})
export class InputFieldPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return FormatUtils.formatInputField(value);
  }
}
