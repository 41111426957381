<div class="form-group">
  <div *ngIf="inputParams" class="parameters-box">
    <div *ngFor="let inputParam of inputParams"
         class="input-param"
         [ngClass]="{'has-error':!enteredInputGroup.controls[inputParam].valid && enteredInputGroup.controls[inputParam].touched}">
      <div class="param-name" id="param-{{inputParam}}">
        {{inputParam === 'CCID' ? 'Account ID (CCID)' : inputParam}}
      </div>
      <input type="text" class="form-control" id="{{inputParam}}-input"
             [formControl]="enteredInputGroup.controls[inputParam]"
             [attr.disabled]="isInputDisabled(inputParam) ? '' : null"
             (keyup)="onKeyUp($event)">
    </div>
  </div>
</div>
