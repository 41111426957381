<div class="row" [class.highlighted-row]="isHighlighted"><!-- highlight used by assigned -->
  <div class="col">{{fname}} {{lname}}</div>
  <div class="col">{{tagName}}</div>
  
  <div class="col"><ng-content select="[content-1]"></ng-content></div>

  <div class="col">{{created | date : 'short'}}</div>

  <div class="col" *ngIf="updated">{{updated | date : 'short'}}</div>
  
  <!-- unassigned chats pass this in -->
  <div class="col"><ng-content select="[content-2]"></ng-content></div>
</div>
