export class Filter {
  fieldName: string;
  fieldValue: string;

  constructor(fieldName: string, fieldValue) {
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
  }
}

export class SearchCriteria {
  searchPhrase: string;
  searchFields: string[] = [];
  nonEmptyFilters: string[] = [];
  filters: Filter[] = [];
  branding: boolean = false;
  filtersIfFieldExists: Filter[] = [];

  addFilter(fieldName, fieldValue) {
    this.filters.push(new Filter(fieldName, fieldValue));
  }

  clearFilters() {
    this.filters = [];
  }

  getSearchRequest() {
    const searchRequest = {
      branding: this.branding,
      search_fields: this.searchFields,
      search_phrase: this.searchPhrase,
      non_empty_filters: this.nonEmptyFilters,
      filters_if_field_exists: this.filtersIfFieldExists.map(filter => {
        const fieldValue = Array.isArray(filter.fieldValue) ? filter.fieldValue.join(' ') : filter.fieldValue; // the 'role_id' field is now an array
        return { field_name: filter.fieldName, field_value: fieldValue};
      }),
      filters: this.filters.map(filter => {
        const fieldValue = Array.isArray(filter.fieldValue) ? filter.fieldValue.join(' ') : filter.fieldValue; // the 'role_id' field is now an array
        return { field_name: filter.fieldName, field_value: fieldValue};
      })
    };
    return searchRequest;
  }
}
