<div class="time-window-group">
  <div class="input-container">
    <div class="input">
      <label i18n="Time window start label">{{startTimeLabel}}</label>

      <select class="form-control light" [(ngModel)]="startTime" id="time-window-start-select" (ngModelChange)="onTimeWindowStartChange($event)">
        <ng-container *ngFor="let hour of hours">
          <option [attr.value]="hour.isoHour">{{hour.displayHour}}</option>
        </ng-container>
      </select>
    </div>
  </div>

  <div class="input-container">
    <div class="input">
      <label i18n="Time window end label">{{endTimeLabel}}</label>

      <select class="form-control light" [(ngModel)]="endTime" id="time-window-end-select" (ngModelChange)="onTimeWindowEndChange($event)">
        <ng-container *ngFor="let hour of hours">
          <option [attr.value]="hour.isoHour">{{hour.displayHour}}</option>
        </ng-container>
      </select>
    </div>
  </div>
</div>

<p class="tip-text time-window-tip-text">
  <svg width="15" height="70" style="float: left;">
    <polyline points="8,0 8,28, 2,34 8,40 8,72" style="fill: none; stroke: #5b5a59; stroke-width:1" />
  </svg>
  <span i18n="Description for time window start/end values">
    Note: The start and end times cannot be set equal.  Attempting to do so will cause the other to be adjusted by one hour.
  </span>
</p>
