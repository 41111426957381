<nav>
  <div class="side-navbar no-text-selection" [style.width.px]="navbarStateService.sideNavbarWidth">
    <div class="side-navbar-body">
      <div #sideNavMenu class="side-navbar-menu">
        <img [class.active]="navbarStateService.fullSize"
              [attr.src]="sessionService?.currentUser?.client?.branding?.banner_s3_url" class="side-navbar-header-image">
        <img [class.active]="!navbarStateService.fullSize"
              [attr.src]="sessionService?.currentUser?.client?.branding?.icon_s3_url" class="side-navbar-header-image">
        <img *ngIf="navbarStateService.fullSize" src="assets/icons/collapse-toggle.png" class="collapse-toggle"
             (click)="toggle()">
        <img *ngIf="!navbarStateService.fullSize" src="assets/icons/expand-toggle.png" class="collapse-toggle"
             (click)="toggle()">
        <ng-container *ngFor="let menu of menuItems">
          <span class="side-navbar-section-title"
                [class.menu-text-small]="!navbarStateService.fullSize" *ngIf="menu.title === 'two_way' && client.feed_enabled">Relay Messenger</span>
          <span class="side-navbar-section-title"
                [class.menu-text-small]="!navbarStateService.fullSize" *ngIf="menu.title === 'two_way' && !client.feed_enabled">Two Way Messaging</span>
          <span class="side-navbar-section-title"
                [class.menu-text-small]="!navbarStateService.fullSize" *ngIf="menu.title !== 'two_way'">{{menu.title}}</span>
          <ng-container *ngFor="let child of menu.children">
            <div class="side-navbar-item" routerLink="{{child.link}}" routerLinkActive="active">
              <i class="{{child.icon}}" matTooltip="{{setMatTooltipMessage(child.title)}}" matTooltipPosition="right" matTooltipClass="navbar-tooltip-style"></i>
              <span [class.menu-text-small]="!navbarStateService.fullSize">{{child.title}}</span>
            </div>
          </ng-container>
          <img class="side-navbar-divider" src="assets/side-navbar-divider.png"
               [style.width.px]="navbarStateService.sideNavbarWidth">
        </ng-container>
        <!-- <a class="side-navbar-item" href="http://help.relaynetwork.com" target="_blank">
          <i class="fa fa-question" matTooltip="{{setMatTooltipMessage('Help')}}" matTooltipPosition="right" matTooltipClass="navbar-tooltip-style"></i>
          <span [class.menu-text-small]="!navbarStateService.fullSize">Help</span>
        </a>
        <img class="side-navbar-divider-no-section" src="assets/side-navbar-divider.png"
             [style.width.px]="navbarStateService.sideNavbarWidth"> -->
      </div>
      <div #sideNavFooter class="side-navbar-footer" [class.small]="!navbarStateService.fullSize">
        <div class="side-navbar-footer-content" [class.small]="!navbarStateService.fullSize">
          <br>
          <img [class.active]="navbarStateService.fullSize" src="assets/relay-logo-full.png">
          <img class="small" [class.active]="!navbarStateService.fullSize" src="assets/relay-logo-small.png">
          <span class="version">{{version}}</span>
          <div class="cx-builder-text">
            CX Builder
            <span>&trade;</span>
            <a class="side-navbar-link" [class.small]="!navbarStateService.fullSize" routerLink="/terms"
              i18n="Link to terms and conditions document">
              {{termsText()}}
            </a>
            <a class="side-navbar-link" [class.small]="!navbarStateService.fullSize" routerLink="/privacy-policy"
            i18n="Link to privacy policy document">
              {{privacyText()}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
