import { of, from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../core/services/permission.service';
import { FeatureService } from '@app/core/services/feature.service';
import { LoggerService } from '@app/core/services/logger.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import * as _ from 'lodash';

/**
 * Redirects any route to the login page if the user is not logged in.
 */
@Injectable()
export class AuthGuardService  {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    public permService: PermissionService,
    public featureService: FeatureService,
    private twoWaySharedService: TwoWaySharedService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.sessionService
      .getCurrentUser()
      .pipe(
        switchMap((user) => {
          if (user) {
            LoggerService.log('AuthGuard ', 'current user exists');
            return from(this.featureService.init(user));
          } else {
            LoggerService.log('AuthGuard ', 'no current user');
            return of({});
          }
        }),
        switchMap((flags) => {
          LoggerService.log('AuthGuard ', 'checking perms');
          return of(this.checkPermsAndRedirect(route, state, flags));
        })
      )
      .pipe(
        catchError((error) => {
          return of(this.handleError(error));
        })
      );
  }

  checkPermsAndRedirect(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    flags
  ): boolean {
    if (
      !this.hasPermission(route) &&
      !this.sessionService.hasUserInLocalStorage()
    ) {
      this.router.navigateByUrl('/logout');
      return false;
    }

    if (!this.hasPermission(route) || this.missingRequiredFlag(route, flags)) {
      this.safeRedirect(route, state);
      return false;
    }

    if (this.isTwoWay(state.url) && !this.twoWaySharedService.showTwoWay()) {
      this.router.navigateByUrl('/experiences');
      return false;
    }

    return true;
  }

  safeRedirect(route, state): void {
    this.router.navigate(['/safe-redirect'], {
      queryParams: { redirect: route.queryParams.redirect },
      skipLocationChange: true,
    });
  }

  isTwoWay(path): boolean {
    return _.includes(path, 'two-way');
  }

  missingRequiredFlag(route, flags): boolean {
    return (
      !!route.data['flag'] &&
      !_.isEmpty(flags) &&
      !this.featureService.checkFlag(route.data.flag)
    );
  }

  hasPermission(route): boolean {
    return (
      !!(route.data.perm === '*') ||
      (this.sessionService.currentUser &&
        this.permService.checkPermissions(route.data['perm']))
    );
  }

  handleError(error): boolean {
    LoggerService.log('AuthGuard Error', JSON.stringify(error));
    this.router.navigateByUrl('/logout');
    return false;
  }
}
