import { BlackoutWindow } from '@app/core/models/blackout-window';
import { BlackoutHour} from '@app/core/models/blackout-hour';
import moment from "moment-timezone";

function createBlackoutHours(): BlackoutHour[] {
  const hours = [];
  for (let h = 1; h <= 24; h++) {

    const hour = new BlackoutHour();

    if (h.toString().length < 2) {
      hour.isoHour = '0' + h + ':00:00';
    } else {
      hour.isoHour = h + ':00:00';
    }

    if (h === 24) {
      hour.displayHour = (h - 12) + ':00 MIDNIGHT';
      hour.isoHour = '00:00:00';
    } else if (h === 12) {
      hour.displayHour = h + ':00 NOON';
    } else if (h < 12) {
      hour.displayHour = h + ':00 AM';
    } else {
      hour.displayHour = (h - 12) + ':00 PM';
    }
    hours.push(hour);
  }
  return hours;
}

export class BlackoutUtils {
  static blackoutHours: BlackoutHour[] = createBlackoutHours();

  static isTimeInBlackoutWindow(timeToTest: Date, blackoutWindow: BlackoutWindow) {
    let inBlackoutWindow = false;
  
    const ianaTimeZone = blackoutWindow.ianaTimeZone;
    
    const t = moment(timeToTest);
    
    // Convert an hh:mm:ss format time to an absolute time with date and time zone 
    const createAbsoluteTime = (timeOfDay: string) => {
      let absoluteTime = null;
      const timeFields = timeOfDay.split(':').map((t) => Number(t));
      if (timeFields.length === 3) {
        const hours = timeFields[0];
        const minutes = timeFields[1];
        const seconds = timeFields[2];
        absoluteTime = moment(t).hours(hours).minutes(minutes).seconds(seconds).tz(ianaTimeZone);
      }
      return absoluteTime;
    };
    
    const blackoutStart = createAbsoluteTime(blackoutWindow.start_time);
    const blackoutEnd = createAbsoluteTime(blackoutWindow.end_time);
    const blackoutSat = blackoutWindow.include_saturday;
    const blackoutSun = blackoutWindow.include_sunday;

    // Make sure we're using the blackout window end that corresponds to this start (i.e. is after it)
    if (blackoutEnd.isBefore(blackoutStart)) {
      blackoutEnd.add(1, 'days');
    }
    
    // Make sure we're looking at the blackout window that starts before time t, not after it.
    if (blackoutStart.isAfter(t)) {
      blackoutStart.subtract(1, 'days');
      blackoutEnd.subtract(1, 'days');
    }

    if (t.isBetween(blackoutStart, blackoutEnd, null, '[)')) {
      inBlackoutWindow = true;
    }

    if (blackoutSat && t.day() === 6) {
      inBlackoutWindow = true;
    }

    if (blackoutSun && t.day() === 7) {
      inBlackoutWindow = true;
    }

    return inBlackoutWindow; 
  }

  static weekendBlackoutDays(blackoutWindow: BlackoutWindow): string[] {
    const days = [];

    if (blackoutWindow.include_saturday) {
      days.push('Saturday');
    }
    if (blackoutWindow.include_sunday) {
      days.push('Sunday');
    }

    return days;
  }

  static weekendBlackoutMessage(blackoutWindow: BlackoutWindow, prefixText?: string): string {
    const days = this.weekendBlackoutDays(blackoutWindow);
    
    if (!days.length) { return ''; }

    return `${prefixText}${days.join(' or ')}.`;
  }

  static getDisplayHour(isoHour: string): string {
    const blackoutHour = this.blackoutHours.find(bh => bh.isoHour === isoHour);
    if (blackoutHour) {
      return blackoutHour.displayHour;
    } else {
      return undefined;
    }
  }

  static getNextBlackoutHour(isoHour: string): string {
    const index = this.blackoutHours.findIndex(bh => bh.isoHour === isoHour);
    let nextIndex = index + 1;
    if (nextIndex >= this.blackoutHours.length) {
      nextIndex = 0;
    }
    return this.blackoutHours[nextIndex].isoHour;
  }

  static getPreviousBlackoutHour(isoHour: string): string {
    const index = this.blackoutHours.findIndex(bh => bh.isoHour === isoHour);
    let nextIndex = index - 1;
    if (nextIndex < 0) {
      nextIndex = this.blackoutHours.length - 1;
    }
    return this.blackoutHours[nextIndex].isoHour;
  }
}
