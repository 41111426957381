import { CompanyType, Industry, Outcome, CategoryWithAncestors } from '@app/core/models/categorization-types';

export enum CategoryIndex {
    Industries = 0,
    CompanyTypes = 1,
    Outcomes = 2,
    ExperienceTypes = 3
}
  
export abstract class CategorizationListItem {
    
    private getUniqueIds(item: CategoryWithAncestors, index: CategoryIndex): string[] {
        const arrayOfIds = item.ancestor_paths.map((path) => path.split('.')[index]); 

        return [...new Set(arrayOfIds)];
    }

    private hasAncestors(item: CategoryWithAncestors): boolean {
        return item.ancestor_paths !== undefined && item.ancestor_paths.length > 0;
    }

    hasAdditionalItems(item: CategoryWithAncestors, index: CategoryIndex): boolean {
        return this.getAdditionalCount(item, index) > 0;
    }

    getAdditionalCount(item: CategoryWithAncestors, index: CategoryIndex): number {
        if (this.hasAncestors(item)) {
        return this.getUniqueIds(item, index).length - 1;
        } else {
        return 0;
        }
    }

    getFirstItemName(item: CategoryWithAncestors, index: CategoryIndex): string {
        if (this.hasAncestors(item)) {
        const firstItemId = this.getUniqueIds(item, index)[0];
        return this.getItemNameForId(firstItemId, index);
        } else {
        return 'None';
        }
    }

    getAdditionalItems(item: CategoryWithAncestors, index: CategoryIndex): string[] {
        if (this.hasAncestors(item)) {
        const additionalItemIds = this.getUniqueIds(item, index).slice(1);
        return additionalItemIds.map((id) => this.getItemNameForId(id, index));
        } else {
        return [];
        }
    }

    abstract getItemNameForId(itemId: string, index: CategoryIndex): string;
}
