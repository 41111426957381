<div
  class="intro-line-1"
  i18n="Introductory for creating a new client - step 2, line 1"
>
  CX Builder Settings
</div>

<client-header
  [headerLabel]="'Portal images'"
  [headerGroup]="'portalImages'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>

<div
  class="collapse-container portal-images-container"
  *ngIf="headerGroups.portalImages && client.branding"
>
  <app-image-input
    [client]="client"
    [imgModel]="client"
    [label]="'Portal icon'"
    [type]="'icon'"
    [tipText]="'Must be at least 95px wide by 95px tall and fewer than 1MB.'"
  ></app-image-input>

  <app-image-input
    [client]="client"
    [imgModel]="client"
    [label]="'Portal banner'"
    [type]="'banner'"
    [tipText]="'Must be at least 1200px wide by 375px tall and fewer than 1MB.'"
  ></app-image-input>
</div>
<ng-container *ngIf="client">
  <div
    class="intro-line-1"
    i18n="Feed Settings"
  >
    Feed Settings
  </div>
  <client-header
    [headerLabel]="'Relay Feed'"
    [allowExpand]="false"
  ></client-header>
  <div
    class="collapse-container"
    *ngIf="headerGroups.clientID"
    style="padding-bottom: 5px"
  >
    <app-radio-btn
      *ngIf="newFeedFeatureFlagOn"
      [btnLabel]="'New Relay Feed'"
      [btnWidth]="'225px'"
      [btnName]="'new-feed'"
      [btnModel]="newFeedEnabled"
      (btnModelChange)="toggleNewFeedEnabled()"
      [feedEnabled]="newFeedEnabled"
    ></app-radio-btn>
    <span *ngIf="!newFeedEnabled" class="helper-text"
      >This will update this client to the new Feed design and enable new
      features.</span
    >
    <span *ngIf="newFeedEnabled" class="helper-text"
    >Relay Feed is enabled.</span
  >
  </div>
</ng-container>
<ng-container>
  <client-header
    [headerLabel]="(client.feed_enabled)?'Enable Relay Messenger':'Enable Two Way Messaging'"
    [headerGroup]="'twoWayMessaging'"
    (openHeader)="toggleHeader($event)"
    (closeHeader)="toggleHeader($event)"
  ></client-header>
  <div
    class="collapse-container"
    *ngIf="headerGroups.twoWayMessaging"
    style="padding-bottom: 5px"
  >
    <app-radio-btn
      [btnLabel]="(client.feed_enabled)?'Relay Messenger':'Two Way Messaging'"
      [btnWidth]="'300px'"
      [btnName]="'two-way-messaging'"
      [btnModel]="client.two_way_config.enabled"
      (btnModelChange)="toggleTwoWay()"
    ></app-radio-btn>
  </div>
</ng-container>
<client-header
  [headerLabel]="'Blackout window'"
  [headerGroup]="'messageBlackoutWindow'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>
<div class="collapse-container" *ngIf="headerGroups.messageBlackoutWindow">
  <app-blackout-select
    [(blackoutWindow)]="client.blackout_window"
  ></app-blackout-select>
</div>
<ng-container *ngIf="client && showFDIC">
  <client-header
    [headerLabel]="'FDIC Settings'"
    [allowExpand]="false"
  ></client-header>
  <div
    class="collapse-container"
    *ngIf="headerGroups.clientID"
    style="padding-bottom: 5px"
  >
    <app-radio-btn
      [btnLabel]="'Enable FDIC capabilities'"
      [btnWidth]="'300px'"
      [btnName]="'fdic'"
      [btnModel]="fdicEnabled"
      (btnModelChange)="toggleFdicEnabled()"
    ></app-radio-btn>
    <span class="helper-text">
      <div>
        <p>Turning this on will activate the FDIC signage settings in two areas:</p>
        <ul>
          <li>Feed level settings available in the Product Group Information under the Legal section.</li>
          <li>Message level settings available in the Message Builder.</li>
        </ul>

      </div>
      <div>
        <p>When turning off:</p>
        <ul>
          <li>Configurations will be removed from Message Builder and the Legal section.</li>
          <li>Any Feed level settings will be removed.</li>
          <li>Messages sent will retain FDIC signage settings.</li>
        </ul>
      </div>
    </span>
  </div>
</ng-container>
<hr />

<div class="button-container">
  <button
    id="prev-btn"
    class="btn btn-primary btn-with-icon"
    (click)="previousStep()"
  >
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button
    class="btn btn-primary"
    id="next-btn"
    (click)="saveAndContinue()"
  >
    <span
      i18n="Label for Next button on new client configuration"
      id="save-and-next-button"
    >
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
<message-dialog
  #toggleOnFeedWarning
  [showCancel]="true"
  [showClose]="true"
  affirmButtonText="Enable"
  [affirmButtonClass]="'btn-primary'"
  [cancelButtonClass]="'btn-link'"
  (affirm)="closeToggleFeedDialog()"
  (cancel)="toggleNewFeedEnabled(true)"
>
  <h3>Enable New Relay Feed for {{ client ? client.company_name : '' }}?</h3>
  <p>
    This will enable the new Feed design and features for this client and their
    customers. Are you sure you want to enable the new Feed?
  </p>
</message-dialog>

<message-dialog
  #toggleOffFeedWarning
  [showCancel]="true"
  [showClose]="true"
  affirmButtonText="Disable"
  [affirmButtonClass]="'btn-primary'"
  [cancelButtonClass]="'btn-link'"
  (affirm)="closeToggleFeedDialog()"
  (cancel)="toggleNewFeedEnabled(true)"
>
  <h3>Disable New Relay Feed for {{ client ? client.company_name : '' }}?</h3>

  <p>
    This client will be reverted back to the Wire.
    <strong
      >Some features will be lost and the visual experience will change for
      their customers.</strong
    >
  </p>
  <p>Are you sure you want to disable the new Relay Feed?</p>
</message-dialog>
