import React, { useCallback, useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import {
  ParticipantConversation,
  TwilioConversation,
  TwoWayClosedChannel,
  TwoWayUnassignedChannel,
} from '@app/two-way/twilio-conversation.types';
import { ControlTag } from '@app/core/services/control-tags.service';
import {
  ConversationsClicked,
  FilterOptionType,
  FilterMethodOptions,
  FilterOptionDateCreatedClickedType,
  FilterOptionLastModifiedClickedType,
  RelayMessengerChatTypes,
  FilterOptionUnreadClickedType,
  FilterMethods,
  FilterOptionClosedReasonClickedType,
} from '../relay-messenger-conversations/RelayMessengerConversations';
import { RelayMessengerSingleConversation } from '../relay-messenger-single-conversation/RelayMessengerSingleConversation';
import {
  TwoWayService,
  getTagNameById,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { Conversation, JSONValue } from '@twilio/conversations';
import { useClientInfo } from '../../context/ClientInfoContext';
import { useTwilioClient } from '../../context/TwilioClientContext';
import { useCustomerStatus } from '../../context/CustomerStatusContext';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';
import { FilterSortingPopover } from '../filter-sorting/FilterSortingPopover';
import { useNewlyAssignedConvSids } from '../../context/NewlyAssignedConvSidsContext';
import { ParagraphSmall } from 'baseui/typography';
import { SortingToast } from '../filter-sorting/SortingToastMessage';
import { format } from 'date-fns';
import { Button } from '@relaynetwork/design-system';
import { SearchInput } from '../search/SearchInput';

export type ChatsTypes =
  | TwilioConversation[]
  | TwoWayUnassignedChannel[]
  | TwoWayClosedChannel[]
  | ParticipantConversation[];
export type ChatToShow =
  | 'My Conversations'
  | 'Unassigned'
  | 'Closed'
  | 'Agent Conversations';

export interface SortingNameProps {
  sortingOptionNameClicked: string;
  setSortOptionNameClicked: React.Dispatch<React.SetStateAction<string>>;
  sortByAsc: string;
  setSortByAsc: React.Dispatch<React.SetStateAction<string>>;
  sortByDesc: string;
  setSortByDesc: React.Dispatch<React.SetStateAction<string>>;
}

export interface SortingDateCreatedProps {
  sortingOptionDateCreatedClicked: string;
  setSortOptionDateCreatedClicked: React.Dispatch<React.SetStateAction<string>>;
  sortByNewestDateCreated: string;
  setSortByNewestDateCreated: React.Dispatch<React.SetStateAction<string>>;
  sortByOldestDateCreated: string;
  setSortByOldestDateCreated: React.Dispatch<React.SetStateAction<string>>;
}

export enum SortingToastOptions {
  DateCreatedNewest = 'Date Created (Newest first)',
  DateCreatedOldest = 'Date Created (Oldest first)',
  LastModifiedNewest = 'Last Modified (Newest first)',
  LastModifiedOldest = 'Last Modified (Oldest first)',
  NameAsc = 'Name (Ascending first)',
  NameDesc = 'Name (Descending first)',
}

export interface SortingLastModifiedProps {
  sortingOptionLastModifiedClicked: string;
  setSortOptionLastModifiedClicked: React.Dispatch<
    React.SetStateAction<string>
  >;
  sortByNewestLastModified: string;
  setSortByNewestLastModified: React.Dispatch<React.SetStateAction<string>>;
  sortByOldestLastModified: string;
  setSortByOldestLastModified: React.Dispatch<React.SetStateAction<string>>;
}

export interface FilterDateCreatedProps {
  filterOptionDateCreatedClicked: FilterOptionDateCreatedClickedType;
  setFilterOptionDateCreatedClicked: (
    filterOptionDateCreatedClicked: FilterOptionDateCreatedClickedType,
  ) => void;
  filterByDateCreated: FilterOptionType;
  setFilterByDateCreated: (filterByDateCreated: FilterOptionType) => void;
}
export interface FilterLastModifiedProps {
  filterOptionLastModifiedClicked: FilterOptionLastModifiedClickedType;
  setFilterOptionLastModifiedClicked: (
    filterOptionLastModifiedClicked: FilterOptionLastModifiedClickedType,
  ) => void;
  filterByLastModified: FilterOptionType;
  setFilterByLastModified: (filterByLastModified: FilterOptionType) => void;
}
export interface FilterUnreadMessagesProps {
  filterOptionUnreadMessagesClicked: FilterOptionUnreadClickedType;
  setFilterOptionUnreadMessagesClicked: (
    filterOptionUnreadMessagesClicked: FilterOptionUnreadClickedType,
  ) => void;
}

export interface FilterClosedReasonProps {
  filterOptionClosedReasonClicked: FilterOptionClosedReasonClickedType;
  setFilterOptionClosedReasonClicked: (
    filterOptionClosedReasonClicked: FilterOptionClosedReasonClickedType,
  ) => void;
  filterByClosedReason: FilterOptionType;
  setFilterByClosedReason: (filterByClosedReason: FilterOptionType) => void;
}

interface RelayMessengerListProps {
  chats: ChatsTypes;
  chatType: ChatToShow | string;
  tagsAndCustomersList: string[];
  agentTags: ControlTag[];
  conversationsClicked: ConversationsClicked;
  sortingName: SortingNameProps;
  sortDateCreated: SortingDateCreatedProps;
  sortLastModified: SortingLastModifiedProps;
  isAgentJoined?: boolean;
  isSortingPopoverOpen?: boolean;
  filterDateCreated: FilterDateCreatedProps;
  filterLastModified: FilterLastModifiedProps;
  filterUnreadMessages: FilterUnreadMessagesProps;
  filterOptionsSelected: FilterMethods.Unread | FilterOptionType;
  sortOptionsSelected: string;
  searchOptions: string[];
  setSearchOptions: React.Dispatch<React.SetStateAction<string[]>>;
  searchOptionsSelected: boolean;
  agentName?: string;
  filterClosedReason?: FilterClosedReasonProps;
}

export const getCustomerInfoByChatType = (
  chat:
    | TwilioConversation
    | TwoWayUnassignedChannel
    | TwoWayClosedChannel
    | ParticipantConversation,
  chatType: ChatToShow | string,
) => {
  const channelSid = chat['sid'] ?? chat['channel_sid'];
  if (
    chatType === RelayMessengerChatTypes.MyConversations ||
    chatType === RelayMessengerChatTypes.AgentConversations
  ) {
    const {
      customer,
      product_group_id: productGroupId,
      tag_id: tagId,
    } = chat['attributes'];
    return {
      firstName: (customer && customer.first_name) || '',
      lastName: (customer && customer.last_name) || '',
      customerId: customer && customer.id,
      productGroupId,
      channelSid,
      tagId,
    };
  } else if (
    chatType === RelayMessengerChatTypes.UnassignedConversations ||
    chatType === RelayMessengerChatTypes.ClosedConversations
  ) {
    const {
      customer: { first_name: firstName, last_name: lastName, id: customerId },
      product_group_id: productGroupId,
    } = chat['attrs'];
    return {
      firstName,
      lastName,
      customerId,
      productGroupId,
      channelSid,
      tagId: chat['tag_id'],
    };
  }
};

const decryptMessageText = async (
  chatSid: string,
  attributes: JSONValue,
  messageBody: string,
  clientId: string,
) => {
  if (messageBody) {
    if (attributes['v3']?.encrypted || attributes['v2']?.encrypted) {
      // Case 1: new feed and message encrypted v3 and old wire messages encrypted v2
      const messagesToDecrypt = [
        {
          cypher: messageBody,
          version: attributes['v3'] ? 'v3' : 'v2',
        },
      ];
      const decryptedData = await TwoWayService.decryptMessage(
        messagesToDecrypt,
        clientId,
        chatSid,
      );
      return decryptedData?.decryptedMessages[0]['text'];
    } else {
      // Case 2: message not encrypted (ex: auto response msg)
      return messageBody;
    }
  }
  // Case 3: empty message was sent
  return '';
};

export const RelayMessengerList = ({
  chats,
  chatType,
  tagsAndCustomersList,
  agentTags,
  conversationsClicked: {
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
  },
  sortingName,
  sortLastModified,
  sortDateCreated,
  isAgentJoined,
  filterDateCreated,
  filterLastModified,
  filterUnreadMessages,
  sortOptionsSelected,
  filterOptionsSelected,
  searchOptions,
  setSearchOptions,
  searchOptionsSelected,
  agentName = null,
  filterClosedReason,
}: RelayMessengerListProps) => {
  const [css] = useStyletron();
  const { customerInfo, setCustomerInfo } = useCustomerInfo();
  const [{ clientId }] = useClientInfo();
  const [latestMessages, setLatestMessages] = useState<string[]>([]);
  const [isFilterSortingPopoverOpen, setIsFilterSortingPopoverOpen] =
    useState(false);
  const { twilioClient } = useTwilioClient();
  const { setCustomerStatus } = useCustomerStatus();
  const { fetchAssignedAgents } = useAssignedAgents();
  const { newlyAssignedConvSids, setNewlyAssignedConvSids } =
    useNewlyAssignedConvSids();
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);

  // custom filtering state for date range
  const [rangeDateCreated, setRangeDateCreated] = useState<Date[]>([]);
  const [rangeLastModified, setRangeLastModified] = useState<Date[]>([]);

  const formatDates = (dates: Date[]) => {
    const formatted = dates.map((date) => format(date, 'dd MMMM yyyy'));
    return `${formatted[0]} - ${formatted[1]}`;
  };

  useEffect(() => {
    // Update Customer Information based on the type of conversations being clicked
    if (
      isMyConversationsClicked &&
      (isAgentJoined || customerInfo?.channelSid)
    ) {
      // Store channelSid for ping/close functionality
      let index = 0;
      if (customerInfo.channelSid) {
        index = chats.findIndex((item) => item.sid === customerInfo.channelSid);
        if (index < 0) index = 0;
      }
      let chat = chats[index];

      const {
        firstName,
        lastName,
        customerId,
        productGroupId,
        channelSid,
        tagId,
      } = getCustomerInfoByChatType(chat, chatType);
      const chatAssigned: any = chat;
      const { dateCreated } = chatAssigned;
      const { dateCreated: dateUpdated } =
        chatAssigned.lastMessage ?? chatAssigned;
      const tagName = getTagNameById(agentTags, tagId);
      setCustomerInfo({
        firstName,
        lastName,
        customerId,
        productGroupId,
        channelSid,
        tagId,
        dateCreated,
        dateUpdated,
        tagName,
      });
    } else if (
      isMyConversationsClicked ||
      IsMyUnassignedConversationClicked ||
      IsMyClosedConversationsClicked ||
      agentConversationsClicked
    ) {
      setCustomerInfo({});
    }
  }, [
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
    isAgentJoined,
  ]);

  const getLatestMessages = useCallback(
    async (chats: ChatsTypes) => {
      try {
        const messagesPromises = chats.map(async (chat) => {
          const chatSid: string = chat['sid'] ?? chat['channel_sid'];
          try {
            const twilioConversation =
              chatType === RelayMessengerChatTypes.MyConversations
                ? (chat as Conversation)
                : await TwoWayService.getTwilioConversation(
                    twilioClient,
                    chatSid,
                  );
            if (twilioConversation) {
              const messagesPaginator = await twilioConversation.getMessages(1);
              const attributes = messagesPaginator?.items[0]?.attributes;
              const messageBody = messagesPaginator?.items[0]?.body;
              return await decryptMessageText(
                chatSid,
                attributes,
                messageBody,
                clientId,
              );
            }
            return '';
          } catch (error) {
            console.error('Error fetching message for chat:', chatSid, error);
            return '';
          }
        });
        const latestMessages = await Promise.all(messagesPromises);
        setLatestMessages(latestMessages);
      } catch (error) {
        console.error('Error processing chats:', error);
      }
    },
    [chats, chatType, clientId, twilioClient],
  );

  useEffect(() => {
    if (isMyConversationsClicked) {
      getLatestMessages(chats);
    }
  }, [chats, chatType, clientId, isMyConversationsClicked, twilioClient]);

  useEffect(() => {
    // Clear latest messages, customer status when switching between conversation tabs
    setLatestMessages([]);
    setCustomerStatus('offline');
  }, [
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
  ]);
  const resetFilterSorting = () => {
    // clear search state
    setSearchOptions([]);
    // clear filter state
    filterDateCreated.setFilterOptionDateCreatedClicked(null);
    filterDateCreated.setFilterByDateCreated(null);
    filterLastModified.setFilterOptionLastModifiedClicked(null);
    filterLastModified.setFilterByLastModified(null);
    filterUnreadMessages.setFilterOptionUnreadMessagesClicked(null);
    setRangeDateCreated([]);
    setRangeLastModified([]);
    filterClosedReason.setFilterOptionClosedReasonClicked(null);
    filterClosedReason.setFilterByClosedReason(null);
    // clear sorting state
    sortingName.setSortOptionNameClicked(null);
    sortingName.setSortByAsc(null);
    sortingName.setSortByDesc(null);
    sortDateCreated.setSortOptionDateCreatedClicked(null);
    sortDateCreated.setSortByNewestDateCreated(null);
    sortDateCreated.setSortByOldestDateCreated(null);
    sortLastModified.setSortOptionLastModifiedClicked(null);
    sortLastModified.setSortByNewestLastModified(null);
    sortLastModified.setSortByOldestLastModified(null);
  };

  useEffect(() => {
    // clear filter, sorting, search options when new tab is opened
    if (
      !isMyConversationsClicked ||
      !IsMyUnassignedConversationClicked ||
      !IsMyClosedConversationsClicked ||
      !agentConversationsClicked
    ) {
      resetFilterSorting();
    }
  }, [
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
  ]);

  const handleDateActionsClick = () => {
    if (sortDateCreated.sortByNewestDateCreated) {
      sortDateCreated.setSortByNewestDateCreated(null);
      sortDateCreated.setSortOptionDateCreatedClicked(null);
    } else if (sortDateCreated.sortByOldestDateCreated) {
      sortDateCreated.setSortByOldestDateCreated(null);
      sortDateCreated.setSortOptionDateCreatedClicked(null);
    }
  };
  const handleLastModifiedActionsClick = () => {
    if (sortLastModified.sortByNewestLastModified) {
      sortLastModified.setSortByNewestLastModified(null);
      sortLastModified.setSortOptionLastModifiedClicked(null);
    } else if (sortLastModified.sortByOldestLastModified) {
      sortLastModified.setSortByOldestLastModified(null);
      sortLastModified.setSortOptionLastModifiedClicked(null);
    }
  };
  const handleNameActionsClick = () => {
    if (sortingName.sortByAsc) {
      sortingName.setSortByAsc(null);
      sortingName.setSortOptionNameClicked(null);
    } else if (sortingName.sortByDesc) {
      sortingName.setSortByDesc(null);
      sortingName.setSortOptionNameClicked(null);
    }
  };
  const clearFilterByDateCreated = () => {
    filterDateCreated?.setFilterByDateCreated(null);
    filterDateCreated?.setFilterOptionDateCreatedClicked(null);
    setRangeDateCreated([]);
  };
  const clearFilterByLastModified = () => {
    filterLastModified?.setFilterByLastModified(null);
    filterLastModified?.setFilterOptionLastModifiedClicked(null);
    setRangeLastModified([]);
  };
  const clearFilterByClosedReason = () => {
    filterClosedReason?.setFilterByClosedReason(null);
    filterClosedReason?.setFilterOptionClosedReasonClicked(null);
  };
  const showClearAllButton =
    (filterOptionsSelected && !sortOptionsSelected) ||
    (filterOptionsSelected && sortOptionsSelected) ||
    searchOptionsSelected;

  const removeSearchOpton = (option) => {
    const newSearchOptions = searchOptions.filter((value) => value !== option);
    setSearchOptions(newSearchOptions);
  };

  const formatLongCloseReasonToastText = (reason: string) => {
    return reason.length > 20 ? `${reason.substring(0, 20)}...` : reason;
  };
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <div
        className={css({
          display: 'flex',
        })}
      >
        {!isSearchInputOpen && agentName && (
          <p
            className={css({
              fontWeight: `700`,
              lineHeight: `20px`,
              marginTop: `20px`,
              width: '350px',
            })}
          >
            {agentName} ({chats.length})
          </p>
        )}
        {!isSearchInputOpen && !agentName && (
          <p
            className={css({
              fontWeight: `700`,
              lineHeight: `20px`,
              marginTop: `20px`,
              width: '350px',
            })}
          >
            {chatType} ({chats.length})
          </p>
        )}
        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          })}
        >
          <SearchInput
            isSearchInputOpen={isSearchInputOpen}
            setIsSearchInputOpen={setIsSearchInputOpen}
            tagsAndCustomersList={tagsAndCustomersList}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
          <FilterSortingPopover
            isFilterSortingPopoverOpen={isFilterSortingPopoverOpen}
            setIsFilterSortingPopoverOpen={setIsFilterSortingPopoverOpen}
            sortingName={sortingName}
            sortLastModified={sortLastModified}
            sortDateCreated={sortDateCreated}
            filterDateCreated={filterDateCreated}
            filterLastModified={filterLastModified}
            filterUnreadMessages={filterUnreadMessages}
            rangeDate={{
              rangeDateCreated,
              setRangeDateCreated,
              rangeLastModified,
              setRangeLastModified,
            }}
            conversationsClicked={{
              isMyConversationsClicked,
              IsMyUnassignedConversationClicked,
              IsMyClosedConversationsClicked,
              agentConversationsClicked,
            }}
            filterClosedReason={filterClosedReason}
          />
        </div>
      </div>
      <div>
        <hr className={css({ border: '1', margin: '0px' })} />
        {(filterOptionsSelected || searchOptionsSelected) && (
          <>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              })}
            >
              <ParagraphSmall
                className={css({
                  color: 'rgba(55, 71, 79, 0.25) !important',
                  fontFamily: 'Source Sans Pro !important',
                  fontSize: '12px !important',
                  fontStyle: 'normal !important',
                  fontWeight: '600 !important',
                  lineHeight: 'normal !important',
                  marginBottom: '10px',
                  marginTop: '10px',
                })}
                data-testid="filter-by-text"
              >
                FILTER BY
              </ParagraphSmall>
              {showClearAllButton && (
                <Button
                  variant="empty"
                  onClick={resetFilterSorting}
                  size="small"
                  data-testid="clear-all-button"
                >
                  Clear all
                </Button>
              )}
            </div>
            <div
              className={css({
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
              })}
            >
              {filterDateCreated?.filterOptionDateCreatedClicked &&
                (filterDateCreated?.filterByDateCreated ===
                FilterMethodOptions.PastDay ? (
                  <SortingToast
                    onActionClick={clearFilterByDateCreated}
                    text={`Created (${FilterMethodOptions.PastDay})`}
                    dataTestId={'toast-filter-date-created-past-day'}
                  />
                ) : filterDateCreated?.filterByDateCreated ===
                  FilterMethodOptions.PastWeek ? (
                  <SortingToast
                    onActionClick={clearFilterByDateCreated}
                    text={`Created (${FilterMethodOptions.PastWeek})`}
                    dataTestId={'toast-filter-date-created-past-week'}
                  />
                ) : filterDateCreated?.filterByDateCreated ===
                  FilterMethodOptions.PastMonth ? (
                  <SortingToast
                    onActionClick={clearFilterByDateCreated}
                    text={`Created (${FilterMethodOptions.PastMonth})`}
                    dataTestId={'toast-filter-date-created-past-month'}
                  />
                ) : Array.isArray(filterDateCreated?.filterByDateCreated) &&
                  filterDateCreated?.filterByDateCreated.length === 2 ? (
                  <SortingToast
                    onActionClick={clearFilterByDateCreated}
                    text={`Created between ${formatDates(
                      filterDateCreated?.filterByDateCreated,
                    )}`}
                    dataTestId={'toast-filter-date-created-custom'}
                  />
                ) : (
                  ''
                ))}
              {filterLastModified?.filterOptionLastModifiedClicked &&
                (filterLastModified?.filterByLastModified ===
                FilterMethodOptions.PastDay ? (
                  <SortingToast
                    onActionClick={clearFilterByLastModified}
                    text={`Modified (${FilterMethodOptions.PastDay})`}
                    dataTestId={'toast-filter-last-modified-past-day'}
                  />
                ) : filterLastModified?.filterByLastModified ===
                  FilterMethodOptions.PastWeek ? (
                  <SortingToast
                    onActionClick={clearFilterByLastModified}
                    text={`Modified (${FilterMethodOptions.PastWeek})`}
                    dataTestId={'toast-filter-last-modified-past-week'}
                  />
                ) : filterLastModified?.filterByLastModified ===
                  FilterMethodOptions.PastMonth ? (
                  <SortingToast
                    onActionClick={clearFilterByLastModified}
                    text={`Modified (${FilterMethodOptions.PastMonth})`}
                    dataTestId={'toast-filter-last-modified-past-month'}
                  />
                ) : Array.isArray(filterLastModified?.filterByLastModified) &&
                  filterLastModified?.filterByLastModified.length === 2 ? (
                  <SortingToast
                    onActionClick={clearFilterByLastModified}
                    text={`Modified between ${formatDates(
                      filterLastModified?.filterByLastModified,
                    )}`}
                    dataTestId={'toast-filter-last-modified-custom'}
                  />
                ) : (
                  ''
                ))}
              {filterUnreadMessages?.filterOptionUnreadMessagesClicked && (
                <SortingToast
                  onActionClick={() =>
                    filterUnreadMessages?.setFilterOptionUnreadMessagesClicked(
                      null,
                    )
                  }
                  text={`Unread Messages`}
                  dataTestId={'toast-filter-unread-messages'}
                />
              )}
              {filterClosedReason?.filterOptionClosedReasonClicked &&
                filterClosedReason.filterByClosedReason && (
                  <SortingToast
                    onActionClick={clearFilterByClosedReason}
                    text={`Closed Reason: ${formatLongCloseReasonToastText(
                      filterClosedReason.filterByClosedReason as string,
                    )}`}
                    dataTestId={'toast-filter-closed-reason'}
                  />
                )}
              {searchOptionsSelected &&
                searchOptions.map((option) => {
                  return (
                    <SortingToast
                      onActionClick={() => removeSearchOpton(option)}
                      text={option}
                      dataTestId={`toast-filter-option-` + option}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
      {chats.length === 0 && (
        <div>
          <p>No conversations are available</p>
        </div>
      )}
      <div
        className={css({
          marginBottom: '10px',
        })}
      >
        {sortOptionsSelected && (
          <>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              })}
            >
              <ParagraphSmall
                className={css({
                  color: 'rgba(55, 71, 79, 0.25) !important',
                  fontFamily: 'Source Sans Pro !important',
                  fontSize: '12px !important',
                  fontStyle: 'normal !important',
                  fontWeight: '600 !important',
                  lineHeight: 'normal !important',
                  marginBottom: '10px',
                  marginTop: '10px',
                })}
                data-testid="sort-by-text"
              >
                SORT BY
              </ParagraphSmall>
              {sortOptionsSelected && !filterOptionsSelected && (
                <Button
                  variant="empty"
                  onClick={resetFilterSorting}
                  size="small"
                >
                  Clear all
                </Button>
              )}
            </div>
            <div
              className={css({
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
              })}
            >
              {(sortDateCreated.sortByNewestDateCreated ||
                sortDateCreated.sortByOldestDateCreated) &&
              sortDateCreated.sortingOptionDateCreatedClicked ? (
                <SortingToast
                  onActionClick={handleDateActionsClick}
                  text={
                    sortDateCreated.sortByNewestDateCreated
                      ? SortingToastOptions.DateCreatedNewest
                      : sortDateCreated.sortByOldestDateCreated
                      ? SortingToastOptions.DateCreatedOldest
                      : ''
                  }
                  dataTestId={`${
                    sortDateCreated.sortByNewestDateCreated
                      ? 'sort-by-newest'
                      : sortDateCreated.sortByOldestDateCreated
                      ? 'sort-by-oldest'
                      : ''
                  }`}
                />
              ) : (
                ''
              )}
              {(sortLastModified.sortByNewestLastModified ||
                sortLastModified.sortByOldestLastModified) &&
              sortLastModified.sortingOptionLastModifiedClicked ? (
                <SortingToast
                  onActionClick={handleLastModifiedActionsClick}
                  text={
                    sortLastModified.sortByNewestLastModified
                      ? SortingToastOptions.LastModifiedNewest
                      : sortLastModified.sortByOldestLastModified
                      ? SortingToastOptions.LastModifiedOldest
                      : ''
                  }
                  dataTestId={`${
                    sortLastModified.sortByNewestLastModified
                      ? 'sort-by-newest-last-modified'
                      : sortLastModified.sortByOldestLastModified
                      ? 'sort-by-oldest-last-modified'
                      : ''
                  }`}
                />
              ) : (
                ''
              )}
              {(sortingName.sortByAsc || sortingName.sortByDesc) &&
              sortingName.sortingOptionNameClicked ? (
                <SortingToast
                  onActionClick={handleNameActionsClick}
                  text={
                    sortingName.sortByAsc
                      ? SortingToastOptions.NameAsc
                      : sortingName.sortByDesc
                      ? SortingToastOptions.NameDesc
                      : ''
                  }
                  dataTestId={`${
                    sortingName.sortByAsc
                      ? 'sort-by-ascending'
                      : sortingName.sortByDesc
                      ? 'sort-by-descending'
                      : ''
                  }`}
                />
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      {(filterOptionsSelected || sortOptionsSelected) && (
        <hr className={css({ border: '1', margin: '0px' })} />
      )}
      <div>
        <div>
          {chatType === RelayMessengerChatTypes.MyConversations ||
          chatType === RelayMessengerChatTypes.AgentConversations
            ? chats.map((chatAssigned, i) => {
                const { dateCreated } = chatAssigned;
                const { dateCreated: dateUpdated } =
                  chatAssigned.lastMessage ?? chatAssigned;
                const {
                  firstName,
                  lastName,
                  customerId,
                  productGroupId,
                  channelSid,
                  tagId,
                } = getCustomerInfoByChatType(chatAssigned, chatType);
                const tagName = getTagNameById(agentTags, tagId);
                let count = 0;
                if (
                  chatAssigned?.lastMessage?.index &&
                  chatAssigned?.lastReadMessageIndex
                ) {
                  count =
                    chatAssigned.lastMessage.index -
                    chatAssigned.lastReadMessageIndex;
                } else if (
                  chatAssigned?.lastMessage?.index &&
                  !chatAssigned.lastReadMessageIndex
                ) {
                  // newly assigned conversations case
                  count = chatAssigned.lastMessage.index;
                }
                const newlyAssigned =
                  newlyAssignedConvSids?.includes(channelSid);
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setCustomerInfo({
                        firstName,
                        lastName,
                        customerId,
                        productGroupId,
                        channelSid,
                        tagId,
                        dateCreated,
                        dateUpdated,
                        tagName,
                      });
                      setCustomerStatus('offline');
                      fetchAssignedAgents(twilioClient, channelSid);
                      // remove newly assigned conversation from the list
                      if (newlyAssignedConvSids?.length > 0) {
                        const newAssignedConvIndex =
                          newlyAssignedConvSids.indexOf(channelSid);
                        if (newAssignedConvIndex > -1) {
                          newlyAssignedConvSids.splice(newAssignedConvIndex, 1);
                          setNewlyAssignedConvSids(newlyAssignedConvSids);
                        }
                      }
                    }}
                  >
                    <RelayMessengerSingleConversation
                      firstLastName={{ firstName, lastName }}
                      tagId={tagId}
                      dateCreated={dateCreated}
                      dateUpdated={
                        agentConversationsClicked
                          ? chatAssigned.dateUpdated
                          : dateUpdated
                      }
                      agentTags={agentTags}
                      id={channelSid}
                      tagName={tagName}
                      messagePreview={latestMessages[i]}
                      chat={chatAssigned}
                      chatType={chatType}
                      unreadMessageCount={
                        !agentConversationsClicked ? count : 0
                      }
                      newlyAssigned={
                        !agentConversationsClicked ? newlyAssigned : false
                      }
                    />
                  </div>
                );
              })
            : chatType === RelayMessengerChatTypes.UnassignedConversations ||
              chatType === RelayMessengerChatTypes.ClosedConversations
            ? chats.map((chats, i) => {
                const {
                  created_at: dateCreated,
                  updated_at: dateUpdated,
                  latest_conversation_closed_at: closedDate,
                  latest_conversation_closed_reason: closedReason,
                  latest_conversation_closed_reason_additional_detail:
                    closedReasonAdditionalDetail,
                } = chats;
                const {
                  firstName,
                  lastName,
                  customerId,
                  productGroupId,
                  channelSid,
                  tagId,
                } = getCustomerInfoByChatType(chats, chatType);
                const tagName = getTagNameById(agentTags, tagId);
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setCustomerInfo({
                        firstName,
                        lastName,
                        customerId,
                        productGroupId,
                        channelSid,
                        tagId,
                        dateCreated,
                        dateUpdated,
                        tagName,
                        closedReason,
                        closedReasonAdditionalDetail,
                      });
                      setCustomerStatus('offline');
                    }}
                  >
                    <RelayMessengerSingleConversation
                      key={i}
                      firstLastName={{ firstName, lastName }}
                      tagId={tagId}
                      dateCreated={dateCreated}
                      dateUpdated={dateUpdated}
                      agentTags={agentTags}
                      id={channelSid}
                      tagName={tagName}
                      chat={chats}
                      chatType={chatType}
                      closedDate={closedDate}
                      closedReason={closedReason}
                    />
                  </div>
                );
              })
            : []}
        </div>
      </div>
    </div>
  );
};
