import { Component, Input } from '@angular/core';
import { ProductGroup } from '@app/core/models/client';

@Component({
  selector: 'wire-header',
  templateUrl: './wire-header.component.html',
  styleUrls: ['./wire-header.component.scss']
})

export class WireHeaderComponent {
  @Input() productGroup: ProductGroup;
  @Input() showTitle: boolean = false;

  constructor() {}

  get headerColor() {
    return this.productGroup && this.productGroup.branding.color ? this.productGroup.branding.color : '#231F20';
  }

  get headerTitle() {
    return this.productGroup.name;
  }

  get hideHamburger() {
    return this.productGroup.hide_hamburger;
  }
}
