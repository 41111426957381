import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { SessionService } from '@app/security/session.service';
import { LoggerService } from '@app/core/services/logger.service';
import { environment } from '@env/environment';

import * as _ from 'lodash';

import { Job } from '@app/jobs-list/models/job';
import { FilterGroupingService } from '@app/jobs-list/services/filter-grouping.service';
import { SecureHttp } from '@app/security/secure-http';

@Injectable()
export class JobsService {
  private jobsError$: BehaviorSubject<boolean>;
  private jobsStore$: BehaviorSubject<Job[]>;
  private clientChangeSub$: Subscription;

  constructor(
    private secureHttp: SecureHttp,
    private sessionService: SessionService,
    private filterGroupingService: FilterGroupingService ) {
  }

  /**
   * Public Function For changing sort/filters without making additional requests
   * will make a request if no store exists.
   */
  rearrangeJobs(filters?: string[], sort?: string, sortDirection?: 'asc' | 'desc'): Observable<Job[]> {
    // if no job cache exists, call the function that'll hit the api
    if (!this.jobsStore$) {
      return this.updateJobs(filters, sort, sortDirection);
    }

    return this.jobsStore$.pipe(
      map((jobs) => this.rejectBadData(jobs)),
      map((jobs) => this.filterJobs(jobs, filters)),
      map((jobs) => this.sortJobs(jobs, sort, sortDirection))
    );
  }

  /**
   * Public Function For updating the actual data via a new request
   */
  updateJobs(filters?: string[], sort?: string, sortDirection?: 'asc' | 'desc'): Observable<Job[]> {
    if (!this.jobsStore$) {
      this.jobsStore$ = new BehaviorSubject([]);
      this.jobsError$ = new BehaviorSubject(false);
      this.initClientChangeListener();
    }

    return this.requestJobsFromApi().pipe(
      map((jobs) => this.rejectBadData(jobs)),
      map((jobs) => this.filterJobs(jobs, filters)),
      map((jobs) => this.sortJobs(jobs, sort, sortDirection))
    );
  }

  /**
   * Public Function For cancelling a job
   */
  cancelJob(jobId: string): Observable<any> {
    return this.sessionService.selectedClient.pipe( // get the user's selected client id
      switchMap((clientId) => { // call the cancel endpoint
        const jobsCancelUrl = `${environment.cdmURLBase}/client/${clientId}/job/${jobId}/cancel`;
        return this.secureHttp.post(jobsCancelUrl, {}, {}, true);
      })
    );
  }

  /**
   * Public Function for connecting to the error state of the service's last jobs request
   */
  hasError(): BehaviorSubject<boolean> {
    return this.jobsError$;
  }

  private requestJobsFromApi(): Subject<Job[]> {
    const jobsReq$ = new Subject<Job[]>();

    this.sessionService.selectedClient.pipe( // get client id
      switchMap((clientId) => {  // use client id to get jobs
        const jobsUrl = `${environment.cdmURLBase}/client/${clientId}/jobs`;
        return this.secureHttp.get(jobsUrl, {}, true);
      }),
      map((response) => this.convertResponseToJobs(response)),
      tap((jobs: Job[]) => this.saveUpdateToStore(jobs)),
      catchError((error) => {
        this.saveErrorToStore();
        LoggerService.log('JobsService', `requestJobs() error: ${JSON.stringify(error)}`);
        return error;
      })
    ).subscribe((jobs: Job[]) => {
      // subscription and subject are needed because http calls are cold/don't run without a subscription.
      // We want this to run every time it's called, regardless of whether there's a subscriber.  
      jobsReq$.next(jobs);
    });

    return jobsReq$;
  }

  private saveUpdateToStore(jobs: Job[]): void {
    this.jobsError$.next(false);
    this.jobsStore$.next(jobs);
  }

  private saveErrorToStore(): void {
    this.jobsError$.next(true);
    this.jobsStore$.next([]);
  }

  /** 
   * temporarily filter out bad data (data missing an id or last_modified_at field)
   */ 
  private rejectBadData(jobs): Job[] {
    const validJobsData = _.reject(jobs, (job) => {
      return _.isEmpty(job.id) || job['last_modified_at'] === undefined;
    });
    return validJobsData;
  }

  /**
   * for each filterGroup (status, filetype, etc)
   * remove jobs that don't match at least 1 filter in the group
   */
  private filterJobs(jobs: Job[], filters: string[]): Job[] {
    const filterGroups = this.groupedFilters(filters);
    let subset = jobs;

    _.each(filterGroups, (filterGroup) => {
      subset =  _.filter(subset, (job) => {
        return this.matchesFilter(job, filterGroup);
      });
    });
    
    return subset;
  }

  private sortJobs(jobs: Job[], sort: string, sortDirection: 'asc' | 'desc'): Job[] {
    const sorted =  _.sortBy(jobs, (job) => {
      return job[sort];
    });
    if (sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }

  /**
   *  When the user's client changes, clear out the old client's cached jobs 
   *  and request new ones.
   */ 
  private initClientChangeListener(): void {
    if (this.clientChangeSub$) {  return; }

    this.clientChangeSub$ = this.sessionService.clientChanged.subscribe((clientData) => {
      if (clientData.prev && clientData.current !== clientData.prev) {
        this.requestJobsFromApi();
      }
    });
  }

  private convertResponseToJobs(response): Job[] {
    return _.map(response['jobs'], (jobObject) => new Job(jobObject));
  }

  /**
   * @returns true if job matches any of the filters, 
   * or if there were no filters passed
   */
  private matchesFilter(job: Job, filters): boolean {
    if (_.isEmpty(filters)) { return true; }

    return _.reduce(filters, (result, filter) => {
      return result || job.search(filter);
    }, false);
  }

  /**
   * @returns an object literal in a form like this
   *   { 
   *     status: ['completed', 'idle'], 
   *     filetype: ['messaging', 'cca']
   *   }
   */
  private groupedFilters(filterNames: string[]): object {
    return _.values(this.filterGroupingService.groupFilters(filterNames));
  }
}
