import { Client } from '@app/core/models/client';
import { BlackoutWindow } from '@app/core/models/blackout-window';
import { JobService } from '@app/core/services/job.service';
import { TitleService } from '@app/core/services/title.service';
import { JourneyService } from '@app/core/services/journey.service';
import { InputParamUtil } from '@app/core/models/input-param-util';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Journey } from '@app/core/models/journey';
import { Customer } from '@app/core/models/customer';
import { ComponentBaseClass } from '@app/core/models/message';
import { ClientService } from '@app/core/services/client.service';
import { SessionService } from '@app/security/session.service';
import { CustomerService } from '@app/core/services/customer.service';
import { BlackoutUtils } from '@app/core/utils/blackout-utils';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { InputParamsComponent } from '../input-params/input-params.component';
import { FormatUtils } from '@app/core/utils/format-utils';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { flatMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { FileLauncherComponent } from '@app/file-launcher/components/file-launcher/file-launcher.component';
import { ConsentEnum } from '@app/core/models/consent-type';
import { EMPTY, forkJoin } from 'rxjs';

enum JourneyLaunchStates {
  waitingForUser = 1,  // Waiting for user to do something (not ready to launch)
  uploading = 2,
  readyToLaunch = 3,
  successfullyLaunched = 4,
  error = 0
}

enum Tabs {
  selectUser = 1,
  upload = 2,
  enroll = 3
}

@Component({
  selector: 'app-workflow-launcher',
  templateUrl: './workflow-launcher.component.html',
  styleUrls: ['./workflow-launcher.component.scss']
})

export class WorkflowLauncherComponent implements OnInit, OnDestroy {
  @ViewChild('fileLauncher') fileLauncher: FileLauncherComponent;
  @ViewChild('launcherTabs') launcherTabs: TabsetComponent;
  @ViewChild('searchTextInput') searchTextInput: ElementRef;
  @ViewChild('errorDialog', { static: true }) errorDialog: MessageDialogComponent;
  @ViewChild('blackoutWarningDialog', { static: true }) blackoutWarningDialog: MessageDialogComponent;
  @ViewChild(InputParamsComponent) inputParamsChild: InputParamsComponent;
  currentStep: number = -1;
  currentTab: Tabs = Tabs.selectUser;
  journey: Journey;
  journeyId: string;
  messages: Array<String> = [];
  fileName: string;
  jobId: string;
  enteredInputParams: any[] = [];
  inputParams: Array<string>;
  onUploadCompleteResponse: string;
  journeyLaunchStates = JourneyLaunchStates;
  tabs = Tabs;
  journeyLaunchState: number;
  triggerComponent: ComponentBaseClass;
  tab1ValidationError: string;
  tab2ValidationError: string;
  selectedUsers: Customer[] = [];
  searchText: string;
  searchList: Customer[];
  showSchedulingDialog: boolean = false;
  scheduleTime: Date;
  productGroupNames: Map<string, string> = new Map<string, string>();
  client: Client;
  blackoutWindow: BlackoutWindow;
  blackoutStartDisplay: string;
  blackoutEndDisplay: string;
  blackoutOnSaturday: boolean;
  blackoutOnSunday: boolean;
  fileLines: number;
  fetchingUsers: boolean = false;
  journeyLaunchResponse: any;
  clientMessageTags: string[] = [];
  defaultClientMessageTag: string;
  statusTabs: object[] = [{id: this.journeyLaunchStates.waitingForUser, text: 'Select Recipients'},
                          {id: this.journeyLaunchStates.readyToLaunch, text: 'Confirm Launch'}];
  permissions = Permissions;


  constructor(private sessionService: SessionService,
              private journeyService: JourneyService,
              private clientService: ClientService,
              private titleService: TitleService,
              private jobService: JobService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private changeDetectorRef: ChangeDetectorRef,
              private customerService: CustomerService,
              private ps: PermissionService) {
  }

  ngOnInit() {
    this.journeyLaunchState = JourneyLaunchStates.waitingForUser;
    this.titleService.activate('Experience Launcher');

    this.route.params.pipe(
      flatMap((params: Params) => {
        this.journeyId = params['journeyId'];
        return this.journeyService.getJourneyById(params['journeyId']);
      })
    ).subscribe(
      journey => {
        this.journey = journey;
        this.titleService.activate('Experience Launcher', this.journey.live.name);
      }, error => {
        const errorMessage = error.error.response;
        this.errorDialog.showMessage('Oops... there was an error loading the experience: ' + errorMessage);
      });

    // Get a fresh copy of the client for the default blackout window
    this.clientService.getClient(this.sessionService.currentUser.client.id).subscribe(
      client => {
        this.client = client;
        this.determineBlackoutWindow();
      }
    );
  }

  changeStatusTabs(): void {
    if (this.currentTab === this.tabs.upload) {
      this.statusTabs = [{id: this.journeyLaunchStates.waitingForUser, text: 'Select Recipients'}, // 1 or 0
                         {id: this.journeyLaunchStates.uploading, text: 'Uploading'}, // 2 or 0
                         {id: this.journeyLaunchStates.readyToLaunch, text: 'Confirm Launch'}]; // 3 or higher
    } else {
      this.statusTabs = [{id: this.journeyLaunchStates.waitingForUser, text: 'Select Recipients'},
                         {id: this.journeyLaunchStates.readyToLaunch, text: 'Confirm Launch'}];
    }
  }

  hasRequiredPermission(): boolean {
    return this.ps.checkPermissions(this.permissions.jobs_add_journey_messaging);
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  getClientMessageTag(trigger: any): void {
    this.defaultClientMessageTag = undefined;
    const targetUUID = trigger.to;
    _.forEach(this.journey.live.components, (c) => {
      if (c.name === targetUUID) {
        this.defaultClientMessageTag = _.get(c, 'client_message_tag');
      }
    });
  }

  /**
   * Retrieve the up-to-date consent record for the customer and update the
   * search results.
   * 
   * @param customer the customer to retrieve consent for
   */
  getConsents(customer: Customer) {
    return this.customerService
      .getConsentByCCID(customer.ccid)
      .pipe(take(1))
      .subscribe(
        (consents) => {
          this.searchList[this.searchList.indexOf(customer)].consents = consents;
        },
        () => {
          this.errorDialog.showMessage(
            'Ooops...there was error loading customer consent details.'
          );
        }
      );
  }

  /**
   * Whether or not the customer has notification channels set to STOP consent.
   * 
   * @param customer the customer to check
   * @returns true if all channels are STOP, false otherwise
   */
  customerAllStopConsent(customer: Customer): boolean {
    if (customer.consents.length) {
      return customer.consents.every((c) => c.consent_type === ConsentEnum.STOP);
    }
    return false;
  }

  getConsentTooltip(customer: Customer): string {
    return this.customerAllStopConsent(customer) ? 'Unable to send message. Customer has a consent status of \'Stop\'.' : ''
  }

  addToSelectedUsers(user): void {
    if (this.customerAllStopConsent(user)) {
      user.selected = false;
      return;
    }

    if (user.selected) {
      this.selectUsers(user);
    } else {
      this.deselectUser(user);
    }
  }

  cancelSelectedTrigger(): void {
    this.currentStep = -1;
    this.enteredInputParams = [];
    this.journeyLaunchState = this.journeyLaunchStates.waitingForUser;
    this.triggerComponent = undefined;
    this.changeDetectorRef.detectChanges();
  }

  checkInputReadyState(): void {
    if (this.triggerComponent && this.checkFormValidity()) {
      this.journeyLaunchState = JourneyLaunchStates.readyToLaunch;
    } else {
      this.journeyLaunchState = JourneyLaunchStates.waitingForUser;
    }
    this.clearValidationErrors();
    this.changeDetectorRef.detectChanges();
  }

  checkFileReadyState(): void {
    if (this.triggerComponent && this.fileName) {
      this.journeyLaunchState = JourneyLaunchStates.readyToLaunch;
    } else {
      this.journeyLaunchState = JourneyLaunchStates.waitingForUser;
    }
    this.clearValidationErrors();
    this.changeDetectorRef.detectChanges();
  }

  checkFormValidity(): boolean {
    let valid = true;
    for (let i = 0; i < this.enteredInputParams.length; i++) {
      for (const control in this.enteredInputParams[i]) {
        if (this.enteredInputParams[i].hasOwnProperty(control) && control.startsWith('input_') && !this.enteredInputParams[i][control]) {
          valid = false;
          return;
        }
      }
      if (!valid) {
        return;
      }
    }
    if (!this.selectedUsers.length) {
      return false;
    }
    return valid;
  }

  checkEnrollReadyState(): void {
    this.journeyLaunchState = JourneyLaunchStates.waitingForUser;
    this.clearValidationErrors();
  }

  clearValidationErrors(): void {
    this.tab1ValidationError = undefined;
    this.tab2ValidationError = undefined;
  }

  deselectUser(user: Customer): void {
    const index = this.selectedUsers.findIndex(c => c.ccid === user.ccid);
    this.selectedUsers.splice(index, 1);
    this.clientMessageTags.splice(index, 1);
    this.searchList.find(u => u.ccid === user.ccid).selected = false;
    this.enteredInputParams.splice(this.enteredInputParams.findIndex(c => c['CCID'] === user.ccid), 1);
    this.checkInputReadyState();
  }

  determineBlackoutWindow(): void {
    if (this.triggerComponent == null) {
      return;
    }

    this.blackoutWindow = null;

    const firstMessageName = this.triggerComponent.to;
    const firstMessage = this.journey.live.components.find(c => c.name === firstMessageName);

    if (firstMessage.sms.is_shown) {
      const blackoutSettings = firstMessage.sms.blackout_settings;

      if (blackoutSettings.blackout_type === 'custom') {
        this.blackoutWindow = blackoutSettings.blackout_window;
      } else if (blackoutSettings.blackout_type === 'default') {
        this.blackoutWindow = this.client?.blackout_window ?? this.blackoutWindow;
      }
    }

    if (this.blackoutWindow) {
      this.blackoutStartDisplay = this.clientService.getDisplayHour(this.blackoutWindow.start_time);
      this.blackoutEndDisplay = this.clientService.getDisplayHour(this.blackoutWindow.end_time);
      this.blackoutOnSaturday = this.blackoutWindow.include_saturday;
      this.blackoutOnSunday = this.blackoutWindow.include_sunday;
    }
  }

  fetchProductGroupNames(customer: Customer[]) {
    const productGroupIds = this.getUniqueProductGroupIds(customer);
    return productGroupIds.map(id => {
      if (!this.productGroupNames.has(id)) {
        return this.clientService.getProductGroupForCurrentUser(id).subscribe(
          productGroup => {
            this.productGroupNames.set(id, productGroup.name);
          }
        );
      }
      return EMPTY;
    });
  }

  focusSearchText(): void {
    this.searchTextInput.nativeElement.focus();
  }

  getFormValues(): void {
    if (this.inputParamsChild) {
      this.enteredInputParams.splice(this.currentStep, 1, this.inputParamsChild.enteredInputParams);
    }
  }

  getNextForm(): void {
    if (this.currentStep < (this.selectedUsers.length - 1)) {
      this.getFormValues();
      this.currentStep++;
      this.setFormValues();
    }
  }

  getPrevForm(): void {
    if (this.currentStep !== -1) {
      this.getFormValues();
      this.currentStep--;
      this.setFormValues();
    }
  }

  getUniqueProductGroupIds(customers: Customer[]): string[] {
    const uniqueIds = new Set<string>();
    customers.forEach(c => {
      uniqueIds.add(c.product_group_id);
    });
    return Array.from(uniqueIds);
  }

  goBack(): void {
    this.location.back();
  }

  handleErrorResponse(error: any): void {
    if (error.status !== 401) {
      this.errorDialog.showMessage('Ooops...there was error loading customers.');
    }
  }

  launchJourney(): void {
    if (this.currentTab === Tabs.selectUser) {
      this.launchJourneyManually();
    }
  }

  launchAgain(): void {
    this.currentTab = Tabs.selectUser;
    this.currentStep = -1;
    this.resetState();
    this.selectedUsers = new Array<Customer>();
    this.searchText = '';
    this.searchList = undefined;
    this.scheduleTime = undefined;
    this.selectTrigger(this.triggerComponent);
  }

  launchJourneyManually(): void {
    if (!this.triggerComponent) {
      this.tab1ValidationError = 'Please select a trigger before launching.';
    }

    if (this.checkFormValidity() && this.triggerComponent) {
      this.getFormValues();
      this.jobService.triggerJourneyStepBatch(this.journeyId, this.triggerComponent.name, this.enteredInputParams, this.clientMessageTags).subscribe(
        response => {
          this.journeyLaunchState = JourneyLaunchStates.successfullyLaunched;
          this.journeyLaunchResponse = response;
        },
        error => {
          this.journeyLaunchState = JourneyLaunchStates.error;
          const errorMessage = error['error'];
          this.errorDialog.showMessage('Oops... there was an error launching the trigger: ' + errorMessage);
          this.resetState();
          this.journeyLaunchState = JourneyLaunchStates.readyToLaunch;
        });
    }
  }

  isScheduleTimeInBlackoutWindow(launchTime: Date): boolean {
    let inBlackoutWindow = false;

    if (this.blackoutWindow) {
      inBlackoutWindow = BlackoutUtils.isTimeInBlackoutWindow(launchTime, this.blackoutWindow);
    }
    return inBlackoutWindow;
  }

  get weekendBlackoutMessage(): string {
    if (this.blackoutWindow) {
      return BlackoutUtils.weekendBlackoutMessage(this.blackoutWindow, 'No messages on ');
    }
  }

  isOnSelectUserTab(): boolean {
    return this.currentTab === Tabs.selectUser;
  }

  monitor(): void {
    this.router.navigateByUrl('/monitor');
  }

  onAffirmScheduleInBlackoutWindow(): void {
    this.launchJourney();
  }

  onCancelScheduleInBlackoutWindow(shouldShow): void {
    this.showSchedulingDialog = shouldShow;
  }

  onConfirmScheduledLaunchTime(): void {
    if (this.isScheduleTimeInBlackoutWindow(this.scheduleTime)) {
      this.blackoutWarningDialog.showMessage(null);
    } else {
      this.launchJourney();
    }
  }

  onLaunchButtonClicked(): void {
    if (this.isScheduleTimeInBlackoutWindow(new Date())) {
      this.blackoutWarningDialog.showMessage(null);
    } else {
      this.launchJourney();
    }
  } 

  onUploadStarted(): void {
    this.clearValidationErrors();
    this.journeyLaunchState = JourneyLaunchStates.uploading;
  }

  onUploadComplete(uploadObj): void {
    if (uploadObj.response.batch_job.status === 'failed') {
      this.errorDialog.showMessage('Oops...there was a problem with the file.');
      this.resetState();
      this.fileName = undefined;
      this.onUploadCompleteResponse = undefined;
      this.journeyLaunchState = JourneyLaunchStates.waitingForUser;
    } else {
      this.onUploadCompleteResponse = uploadObj.response;
      this.fileName = uploadObj.upload.fileName;
      this.fileLines = uploadObj.response.batch_job.total_recs;
      this.jobId = uploadObj.response.batch_job.id;
      if (this.triggerComponent && this.currentTab === Tabs.upload) {
        this.journeyLaunchState = JourneyLaunchStates.readyToLaunch;
      }
    }
  }

  onUserRowClick(user): void {
    user.selected = !user.selected;
    this.addToSelectedUsers(user);
  }

  onSelectUploadTab(): void {
    this.currentTab = Tabs.upload;
    this.checkFileReadyState();
    this.changeStatusTabs()
  }

  onSelectUsersTab(e: any): void {
    if (!e.target || e.target.nodeName !== 'INPUT') {
      this.currentTab = Tabs.selectUser;
      this.currentStep = -1;
      this.checkInputReadyState();
      this.changeStatusTabs();
    }
  }

  resetState(): void {
    this.fileName = undefined;
    this.enteredInputParams = [];
    this.journeyLaunchState = this.journeyLaunchStates.waitingForUser;
    this.changeDetectorRef.detectChanges();
  }

  scheduleLaunch(): void {
    this.scheduleTime = new Date();
    this.showSchedulingDialog = true;
  }

  selectedTabId(): string | Tabs {
    return this.currentTab >= 3 ? '' : this.currentTab;
  }

  selectTrigger(trigger: ComponentBaseClass): void {
    this.getClientMessageTag(trigger);

    this.currentStep = -1;
    this.triggerComponent = trigger;
    this.inputParams = InputParamUtil.findAllInputParams(this.journey.live, this.triggerComponent.name);
    this.determineBlackoutWindow();

    if (this.launcherTabs && this.launcherTabs.tabs) {
      if (this.currentTab === Tabs.selectUser) {
        this.selectedUsers.forEach(u => this.selectUsers(u));
        this.checkInputReadyState();
      } else if (this.currentTab === Tabs.upload) {
        this.checkFileReadyState();
      } else if (this.currentTab === Tabs.enroll) {
        this.checkEnrollReadyState();
      } else {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  selectUsers(user: Customer): void {
    if (!this.selectedUsers.find(u => u.ccid === user.ccid)) {
      this.selectedUsers.push(user);
      this.clientMessageTags.push(this.defaultClientMessageTag)
    }
    this.searchText = '';
    this.enteredInputParams = [];
    this.selectedUsers.forEach(u => {
      const param = {};
      this.inputParams.map(c => {
        if (c === 'CCID') {
          return param[c] = u.ccid;
        } else if (c === 'account_firstname') {
          return param[c] = u.first_name;
        } else if (c === 'account_lastname') {
          return param[c] = u.last_name;
        } else if (c === 'account_product_group') {
          return param[c] = this.productGroupNames.get(u.product_group_id);
        } else if (c === 'account_secondary_account_id') {
          return param[c] = u.secondary_account_id;
        } else if (c.startsWith('ext_')) {
          const propertyName = c.substr(4, c.length - 4);
          if (u.ext) {
            return param[c] = u.ext[propertyName];
          }
        } else {
          return param[c] = '';
        }
      });
      this.enteredInputParams.push(param);
    });

    this.checkInputReadyState();
  }

  setFormsByIndex(i: number): void {
    this.getFormValues();
    this.currentStep = +i;
    this.setFormValues();
  }

  setFormValues(): void {
    this.changeDetectorRef.detectChanges();
    if (this.inputParamsChild) {
      const params = this.enteredInputParams.find(c => c['CCID'] === this.selectedUsers[this.currentStep].ccid);
      this.inputParamsChild.setInitialValues(params);
    }
  }

  /**
   * Submit the customer search to the API with user's keywords.
   * 
   * NOTE: A successful response will kickoff a series of subsequent requests to
   * fetch both product group ({@link fetchProductGroupNames}) & consent
   * ({@link getConsents}) information. Running in parallel, these have a time
   * complexity of _O(n)_ for _n_ customers. Right now it can be deemed safe
   * since the search will only send back customers that nearly match the text,
   * which limits the total number of results. Furthermore, this search will
   * only ever return the first 20 results, and there is no inifinte scroll so
   * the actual time complexity is _O(1)_ (a maximum of 20 consent requests).
   */
  submitSearchRequest(): void {
    if (this.searchText) {
      this.fetchingUsers = true;

      const searchCriteria = new SearchCriteria();
      searchCriteria.searchFields = ['full_name', 'notification_channels.phone_number', 'ccid', 'product_group_id'];
      searchCriteria.searchPhrase = this.searchText;
      searchCriteria.nonEmptyFilters = ['notification_channels'];
      const customerSearchCriteria = {
        limit: 20,
        offset: 0,
        ordering: null,
        searchCriteria: searchCriteria,
        hideMask: true,
      };

      this.customerService.getCustomerBySearch(customerSearchCriteria).subscribe(
        (customers) => {
          customers.forEach(c => {
            c.telephone = FormatUtils.formatPhoneNumber(c.telephone);
            if (this.selectedUsers.find(d => d.id === c.id)) {
              c.selected = true;
            }
          });
          this.searchList = customers;

          forkJoin([
            ...this.fetchProductGroupNames(customers),
            ...customers.map((c) => this.getConsents(c))
          ]);
        },
        error => {
          this.fetchingUsers = false;
          this.handleErrorResponse(error);
        },
        () => {
          this.fetchingUsers = false;
        }
      );
    }
  }
}
