export class SmsResponseTypes {
    help: SmsResponseLanguage = new SmsResponseLanguage();
    stop: SmsResponseLanguage = new SmsResponseLanguage();
    stopall: SmsResponseLanguage = new SmsResponseLanguage();
    join: SmsResponseLanguage = new SmsResponseLanguage();
    unrecognized: SmsResponseLanguage = new SmsResponseLanguage();

    static deserialize(input: object): SmsResponseTypes {
        const smsKeywords = new SmsResponseTypes();
        smsKeywords.help = SmsResponseLanguage.deserialize(input['help']);
        smsKeywords.stop = SmsResponseLanguage.deserialize(input['stop']);
        smsKeywords.stopall = SmsResponseLanguage.deserialize(input['stopall']);
        smsKeywords.join = SmsResponseLanguage.deserialize(input['join']);
        smsKeywords.unrecognized = SmsResponseLanguage.deserialize(input['unrecognized']);
        return smsKeywords;
    }

    constructor() {
    }
}

export class ShortcodeSmsResponseLanguage {
    en: SmsResponse = new SmsResponse();

    static deserialize(input: Object): ShortcodeSmsResponseLanguage {
        const smsKeyword = new ShortcodeSmsResponseLanguage();
        smsKeyword.en = SmsResponse.deserialize(input['en']);
        return smsKeyword;
    }
}

export class SmsResponseLanguage {
    en: SmsResponse = new SmsResponse();
    es: SmsResponse = new SmsResponse();
    fr: SmsResponse = new SmsResponse();

    static deserialize(input: Object): SmsResponseLanguage {
        const smsKeyword = new SmsResponseLanguage();
        smsKeyword.en = SmsResponse.deserialize(input['en']);
        smsKeyword.es = SmsResponse.deserialize(input['es']);
        smsKeyword.fr = SmsResponse.deserialize(input['fr']);
        return smsKeyword;
    }
}

export class SmsResponse {
    response_template: string = '';

    static deserialize(input: Object): SmsResponse {
        const smsResponse = new SmsResponse();
        if (typeof(input) === 'undefined') {
            return smsResponse;
        }

        smsResponse.response_template = input['response_template'];
        return smsResponse;
    }
}

/**
 * Very similar to SmsResponseTypes, but used at the shortcode level, not shortcode:client level.
 * Limited to help and stop and en for both.
 */
export class ShortcodeSmsResponseTypes {
    help: ShortcodeSmsResponseLanguage = new ShortcodeSmsResponseLanguage();
    stop: ShortcodeSmsResponseLanguage = new ShortcodeSmsResponseLanguage();

    static deserialize(input: object): ShortcodeSmsResponseTypes {
        const smsKeywords = new ShortcodeSmsResponseTypes();
        smsKeywords.help = input['help'] ? ShortcodeSmsResponseLanguage.deserialize(input['help']) : new ShortcodeSmsResponseLanguage();
        smsKeywords.stop = input['stop'] ? ShortcodeSmsResponseLanguage.deserialize(input['stop']) : new ShortcodeSmsResponseLanguage();
        return smsKeywords;
    }
}
