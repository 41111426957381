<div class="loading" *ngIf="!error && loading">
  <app-spinner></app-spinner>
</div>

<div class="error" *ngIf="error && !loading">
  <h3>{{ error }}</h3>
  <button class="btn btn-primary" (click)="retry()">retry</button>
</div>

<div *ngIf="!error && !loading">
  <h4>My Conversations ({{ assignedChannels.length }})</h4>

  <!-- List Headings -->

  <rn-channel-list-headings
    [prefix]="'two-way-assigned-channels-sort-by-'"
    [headings]="headings"
    [ordering]="ordering"
    (headingClicked)="onColumnHeadingClick($event)"
  >
  </rn-channel-list-headings>

  <ng-container *ngFor="let channel of assignedChannels">
    <div
      [id]="channel?.sid"
      class="channel"
      (click)="selectChannel(channel?.sid)"
    >
      <rn-channel-list-item
        [fname]="channel?.attributes?.customer?.first_name"
        [lname]="channel?.attributes?.customer?.last_name"
        [tagName]="getTagNameById(channel?.attributes.tags)"
        [created]="channel?.dateCreated"
        [updated]="channel?.lastMessage?.dateCreated"
        [isHighlighted]="channel?.sid === selectedChannel"
      >
        <span content-1 [id]="'unread-' + channel?.sid"></span>
      </rn-channel-list-item>
    </div>
  </ng-container>
</div>
