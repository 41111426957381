import React from 'react';
import { Modal as BaseModal, ModalBody, ModalHeader, SIZE } from 'baseui/modal';
import { useStyletron } from 'baseui';

type ModalProps = {
  closeFn: () => void;
  isOpen: boolean;
  content: JSX.Element | string;
  isFullHeight?: boolean;
  noOuterSpacing?: boolean;
  autoFocus?: boolean;
  modalTitle?: string;
  modalSubTitle?: string;
  closeable?: boolean;
};

export const Modal = ({
  closeFn,
  isOpen,
  content,
  isFullHeight = false,
  noOuterSpacing = false,
  autoFocus = true,
  modalTitle = '',
  modalSubTitle = '',
  closeable = true,
}: ModalProps) => {
  const [css] = useStyletron();

  return (
    <BaseModal
      data-testid="modal"
      isOpen={isOpen}
      size={SIZE.default}
      animate
      autoFocus={autoFocus}
      onClose={closeFn}
      overrides={{
        Dialog: {
          style: {
            width: '600px',
            maxHeight: '80vh',
            overflow: 'hidden', // remove default baseweb scroll
          },
        },
        Close: {
          style: {
            display: closeable ? 'flex' : 'none',
          },
        },
      }}
    >
      {modalTitle && (
        <ModalHeader>
          <p
            className={css({
              color: '#37474F',
              fontSize: '20px',
              lineHeight: '13.8px',
              fontWeight: '700',
            })}
          >
            {modalTitle}
          </p>
          {modalSubTitle && (
            <p
              className={css({
                fontSize: '14px',
                color: '#37474F80',
                fontWeight: '400',
                lineHeight: '15.6px',
              })}
            >
              {modalSubTitle}
            </p>
          )}
        </ModalHeader>
      )}
      <ModalBody>{content}</ModalBody>
    </BaseModal>
  );
};
