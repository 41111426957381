import { Injectable } from '@angular/core';

@Injectable()
export class ActionService {

  constructor() { }

  getTooltipData(errors: Object): string | void {
    if (!errors) {
      return;
    }

    if (errors['maxlength']) {
      return `this input has a maximum length of ${errors['maxlength'].requiredLength}`;
    }
    if (errors['required']) {
      return `this input is required`;
    }
    if (errors['nonSpace'] || errors['htmlNonSpace']) {
      return `this input cannot be all spaces`;
    }
    if (errors['emailOrInput']) {
      return `this input must be an email address or a dynamic input parameter`;
    }
    if (errors['phoneNumberOrInput']) {
      return `this input must be a phone number, e.g. (555) 555-5555, or a dynamic input parameter`;
    }
    if (errors['urlOrInput']) {
      return `this input must be a URL, e.g. http://example.com, or a dynamic input parameter`;
    }
    if (errors['invalidPattern']) {
      return `This input is not allowed. Please make sure to enter a valid URL or ${errors['invalidPattern'].pattern} parameter`;
    }
    if (errors['negativePatternMatch']) {
      if (errors['negativePatternMatch'].value === "New Message") {
        return `you must give this message a new nickname`;
      }
      return `this input cannot contain any callouts(@{abc})`;
    }
    if (errors['customErrorMessage']) {
      return errors['customErrorMessage']
    }
    if (typeof (errors) === 'string') {
      return errors;
    }
  }


  removeAutoCompleteSpans(text): string {
    // Replace tinymce-mentions autocomplete spans with just the text of the input parameter.
    const regex1 = /<span id="autocomplete"><span id="autocomplete-delimiter">[@#]<\/span><span id="autocomplete-searchtext"><span class="dummy">(.*)<\/span><\/span><\/span>/;
    const regex2 = /<span id="autocomplete-delimiter">[@#]<\/span><span id="autocomplete-searchtext"><span class="dummy">(.*)<\/span><\/span>/;
    let match1 = null;
    let match2 = null;
    do {
      match1 = text.match(regex1);
      if (match1) {
        text = text.replace(regex1, '@' + match1[1]);
      }

      match2 = text.match(regex2);
      if (match2) {
        text = text.replace(regex2, '@' + match2[1]);
      }
    } while (match1 || match2);
    return text;
  }

}
