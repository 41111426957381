<div class="input-container">
  <div class="input clearfix" *ngFor="let input of selectedFields">
    <span>{{input.field | inputField}}</span>
    <i class="fa fa-minus-circle" aria-hidden="true" (click)="removeField(input)"></i>
  </div>
</div>

<div class="add-field-container">
  <i class="fa fa-plus-circle" aria-hidden="true"></i>

  <div class="select-container">
    <select [ngStyle]="{'width.px':'210', 'display':'inline'}" class="form-control" (change)="holdField($event.target.value)">
      <option *ngFor="let input of availableFields" value="{{input.field}}" [attr.selected]="isSelected(input)">{{input.field | inputField}}</option>
      <option *ngIf="availableFields.length === 0" selected>N/A</option>
    </select>
  </div>

  <button class="btn btn-primary" (click)="selectField(heldField)" [class.inactive]="availableFields && availableFields.length === 0" [disabled]="availableFields && availableFields.length === 0">ADD</button>

</div>