export class ColorUtils {
  static stringToHSL(str): string {
    return this.numToHSL(this.stringToHashCode(this.prepString(str)));
  }

  private static prepString(str): string {
    return str.toLowerCase().replace(/\s/g, '');
  }

  private static numToHSL(num): string {
    const hue = num % 360;
    return `hsl(${hue},50%,50%)`;
  }

  // Oft-referenced interperetation of Java's String.hashCode() method
  // see: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
  private static stringToHashCode(str): number {
    let hash = 0;
    if (str.length === 0) { return hash; }
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}

