import { Component, Input, OnInit } from '@angular/core';
import { SmsClass, SmsLinkType, SmsLinkTypes } from '@app/core/models/message';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';
import { SmsService } from '@app/core/services/sms.service';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { FeatureService } from '@app/core/services/feature.service';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';

@Component({
  selector: 'rn-sms-builder',
  templateUrl: './sms-builder.component.html',
  styleUrls: ['./sms-builder.component.scss'],
})
export class SmsBuilderComponent implements OnInit {
  @Input() smsModel: SmsClass;
  @Input() smsFormGroup: UntypedFormGroup;
  @Input() isTwoWaySms: boolean = false;
  @Input() customInputs: string[] = [];
  @Input() maxCharacterCount: number = CharacterLimits.SmsTextCharLimit;
  fullLinkTypes: SmsLinkTypes;
  linkTypes: SmsLinkType[];
  selectedLinkType: UntypedFormControl;
  standardInputs: string[] = [
    'account_ccid',
    'account_firstname',
    'account_lastname',
    'account_product_group',
    'account_secondary_account_id',
  ];
  alternateInputs: string[] = ['auth-link'];
  dynamicInputs = [];
  mentionConfig = {
    mentionSelect: this.formatter,
  };

  _wireMessageIsShown: boolean = true;

  constructor(
    private actionService: ActionService,
    private smsService: SmsService,
    private featureService: FeatureService,
    public newFeed: NewFeedTransitionService,
  ) {
    this.fullLinkTypes = smsService.allLinkTypes;
  }

  get wireMessageIsShown(): boolean {
    return this._wireMessageIsShown;
  }

  @Input() set wireMessageIsShown(value: boolean) {
    this._wireMessageIsShown = value;
    this.linkTypes = this.getLinkTypesList();
    const linkValues = this.linkTypes.map((l) => l.value);
    if (this.selectedLinkType !== undefined) {
      const linkIsValid = linkValues.includes(this.selectedLinkType.value);

      // only change the sms destination if the current destination is incompatible
      // with the current wire message configuration
      if (!linkIsValid) {
        // apparently angular doesn't like when the selectedLinkType is changed
        // synchronously with wire.is_shown, so this timeout splits these changes
        // into two events. (I think it has something to do with the experience
        // preview)
        setTimeout(() => {
          this.selectedLinkType.setValue(this.fullLinkTypes.none.value);
        }, 5);
      }
    }
  }

  ngOnInit() {
    this.linkTypes = this.getLinkTypesList();
    this.selectedLinkType = new UntypedFormControl();
    if (this.isTwoWaySms) {
      this.selectedLinkType.disable();
    }

    this.getLinkType();
    this.toggleAlternateSMS();
    this.setDynamicInputs();

    this.selectedLinkType.valueChanges.subscribe(() => {
      this.processLinkType();
    });

    this.smsFormGroup.controls['sms_body'].valueChanges.subscribe(() => {
      this.smsModel.body = this.smsFormGroup.get('sms_body').value;
    });

    this.smsFormGroup.controls['alternate_sms_text'].valueChanges.subscribe(
      () => {
        this.smsModel.alternate_text =
          this.smsFormGroup.get('alternate_sms_text').value;
      },
    );

    this.smsFormGroup.controls['sms_disclaimer'].valueChanges.subscribe(() => {
      this.smsModel.disclaimer = this.smsFormGroup.get('sms_disclaimer').value;
    });

    this.smsFormGroup.controls['auth_link'].valueChanges.subscribe(() => {
      this.smsModel.auth_link = this.smsFormGroup.get('auth_link').value;
      this.smsService.updateAuthLink(this.smsFormGroup.get('auth_link').value);
    });
  }

  getLinkTypesList(): SmsLinkType[] {
    const filteredFFOptions = Object.keys(this.fullLinkTypes)
      .map((key) => this.fullLinkTypes[key])
      .filter((linkType) => {
        if (linkType.feature_flag.length === 0) {
          return true;
        } else {
          return this.featureService.checkFlag(linkType.feature_flag);
        }
      });
    const filteredFFOptionsAndWireFeedReqs = filteredFFOptions.filter(
      (linkType) => {
        if (linkType.requires_wire_only) {
          return !this.wireMessageIsShown;
        } else if (this.wireMessageIsShown) {
          return true;
        } else {
          return !linkType.requires_feed_message;
        }
      },
    );
    return filteredFFOptionsAndWireFeedReqs;
  }

  setDynamicInputs(): void {
    this.dynamicInputs = this.standardInputs.concat(this.customInputs);
  }

  authLinkHasError(): boolean {
    return (
      this.selectedLinkType.value !== this.fullLinkTypes.none &&
      !this.smsFormGroup.controls['auth_link'].valid &&
      this.smsFormGroup.controls['auth_link'].touched
    );
  }

  getTooltipData(errors: Object): string | void {
    return this.actionService.getTooltipData(errors);
  }

  getLinkType(): void {
    switch (this.smsModel.auth_link) {
      case this.fullLinkTypes.details.auth_link:
        this.selectedLinkType.setValue(this.fullLinkTypes.details.value);
        return;
      case this.fullLinkTypes.feed_experience.auth_link:
        this.selectedLinkType.setValue(
          this.fullLinkTypes.feed_experience.value,
        );
        return;
      case this.fullLinkTypes.top_of_feed.auth_link:
        this.selectedLinkType.setValue(this.fullLinkTypes.top_of_feed.value);
        return;
      case this.fullLinkTypes.url.auth_link:
        this.selectedLinkType.setValue(this.fullLinkTypes.url.value);
        return;
      case this.fullLinkTypes.custom_tracked_url.auth_link:
        this.selectedLinkType.setValue(
          this.fullLinkTypes.custom_tracked_url.value,
        );
        return;
      case this.fullLinkTypes.none.auth_link:
        this.selectedLinkType.setValue(this.fullLinkTypes.none.value);
        return;
      default:
        this.selectedLinkType.setValue(this.fullLinkTypes.url.value);
    }
    this.smsFormGroup.controls['auth_link'].setValidators([
      Validators.required,
      CustomValidators.validateReverseReservedInputURL,
    ]);
  }

  processLinkType(): void {
    switch (this.selectedLinkType.value) {
      case this.fullLinkTypes.details.value:
        this.smsModel.auth_link = this.fullLinkTypes.details.auth_link;
        break;
      case this.fullLinkTypes.feed_experience.value:
        this.smsModel.auth_link = this.fullLinkTypes.feed_experience.auth_link;
        break;
      case this.fullLinkTypes.top_of_feed.value:
        this.smsModel.auth_link = this.fullLinkTypes.top_of_feed.auth_link;
        break;
      case this.fullLinkTypes.url.value:
        this.smsModel.auth_link = this.fullLinkTypes.url.auth_link;
        break;
      case this.fullLinkTypes.custom_tracked_url.value:
        this.smsModel.auth_link =
          this.fullLinkTypes.custom_tracked_url.auth_link;
        break;
      case this.fullLinkTypes.none.value:
        this.smsModel.auth_link = this.fullLinkTypes.none.auth_link;
        break;
      default:
        this.smsModel.auth_link = this.fullLinkTypes.url.auth_link;
    }

    this.smsFormGroup.controls['auth_link'].setValue(this.smsModel.auth_link);

    // remove validators for the "none" link type
    if (this.selectedLinkType.value === this.fullLinkTypes.none.value) {
      this.smsFormGroup.controls['auth_link'].clearValidators();
    } else {
      this.smsFormGroup.controls['auth_link'].setValidators([
        Validators.required,
        CustomValidators.validateReverseReservedInputURL,
      ]);
    }
  }

  toggleAlternateSMS(): void {
    if (!this.smsModel.is_alternate_message) {
      this.smsFormGroup.controls['alternate_sms_text'].disable();
    } else {
      this.smsFormGroup.controls['alternate_sms_text'].enable();
    }
  }

  smsCharacterCount(): number {
    return this.smsService.remainingCharacterCount(
      this.smsModel.body,
      this.smsModel.disclaimer,
      !!this.wireMessageIsShown,
      this.maxCharacterCount,
    );
  }

  formatter(mentionInfo: { label: string }): string {
    return `@{${mentionInfo.label}} `;
  }

  /**
   * Shows the readonly controls if true, shows the editable if false.
   */
  showReadOnlyIntro() {
    let showReadOnly: boolean;

    switch (this.selectedLinkType.value) {
      case this.fullLinkTypes.url.value:
        showReadOnly = false;
        break;
      default:
        showReadOnly = true;
        break;
    }

    return showReadOnly;
  }

  showErrorToolTip() {
    return (
      !!this.smsFormGroup.controls['sms_disclaimer'] &&
      !this.smsFormGroup.controls['sms_disclaimer'].valid &&
      this.smsFormGroup.controls['sms_disclaimer'].touched
    );
  }

  showAlternateInputError() {
    return (
      !this.smsFormGroup.controls['alternate_sms_text'].valid &&
      this.smsFormGroup.controls['alternate_sms_text'].touched
    );
  }

  showSmsBodyError() {
    return (
      !this.smsFormGroup.controls['sms_body'].valid &&
      this.smsFormGroup.controls['sms_body'].touched
    );
  }
}
