import React from 'react';
import { DatePicker } from 'baseui/datepicker';
import { format } from 'date-fns';

type CalendarComponentProps = {
  rangeDate: Date[];
  onChange: (value: Date[]) => void;
  close: () => void;
};

export const CalendarComponent = ({
  rangeDate,
  onChange,
  close,
}: CalendarComponentProps) => {
  const customStyle = {
    color: '#3693BF',
  };

  const formatDisplayValue = (date: Date[]) => {
    if (Array.isArray(date) && date.length === 2) {
      return `${format(date[0], 'd MMMM yyyy')} - ${format(
        date[1],
        'd MMMM yyyy',
      )}`;
    } else if (Array.isArray(date) && date.length === 1) {
      return `${format(date[0], 'd MMMM yyyy')} - `;
    }
    return '';
  };

  return (
    <DatePicker
      value={rangeDate}
      onChange={({ date }: { date: Date[] }) => onChange(date)}
      range
      formatString="MM/dd/yy"
      placeholder="Please choose a date range"
      formatDisplayValue={(date: Date[]) => formatDisplayValue(date)}
      maxDate={new Date()}
      onClose={close}
      overrides={{
        Input: {
          props: {
            overrides: {
              Input: {
                style: ({ $theme, $range }) => ({
                  backgroundColor: $theme.colors.white,
                  color: $range ? '#AAAAAA' : '#3693BF',
                }),
              },
              Root: {
                style: {
                  width: '300px',
                  borderLeftColor: '#37474F',
                  borderRightColor: '#37474F',
                  borderTopColor: '#37474F',
                  borderBottomColor: '#37474F',
                  borderLeftWidth: '0.5px',
                  borderRightWidth: '0.5px',
                  borderTopWidth: '0.5px',
                  borderBottomWidth: '0.5px',
                  borderLeftStyle: 'solid',
                  borderRightStyle: 'solid',
                  borderTopStyle: 'solid',
                  borderBottomStyle: 'solid',
                  borderTopRightRadius: '5px',
                  borderTopLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                  borderBottomLeftRadius: '5px',
                },
              },
            },
          },
        },
        InputWrapper: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            boxShadow: '0px 0px 6px 0px #00000026',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px',
          }),
        },
        CalendarHeader: {
          style: { backgroundColor: '#F3F3F3' },
        },
        MonthHeader: {
          style: { backgroundColor: '#F3F3F3' },
        },
        PrevButton: {
          style: {
            ...customStyle,
          },
        },
        NextButton: {
          style: {
            ...customStyle,
          },
        },
        MonthYearSelectButton: {
          style: {
            ...customStyle,
          },
        },
        Day: {
          style: ({ $theme, $isHighlighted, $selected }) => {
            let borderColor = 'transparent';
            if ($isHighlighted) {
              borderColor = '#DBDBDB';
            } else if (($isHighlighted && $selected) || $selected) {
              borderColor = '#3693BF';
            }
            return {
              ':after': {
                borderBottomColor: borderColor,
                borderLeftColor: borderColor,
                borderRightColor: borderColor,
                borderTopColor: borderColor,
                backgroundColor: $selected ? '#3693BF' : $theme.colors.white,
              },
            };
          },
        },
      }}
    />
  );
};
