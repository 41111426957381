<div class="select-container customer-type clearfix">
  <label>Customer type</label>
  <i class="fa fa-question-circle" aria-hidden="true" 
     (mouseover)="showCustomerTable = true"
     (mouseout)="showCustomerTable = false"></i>
  <div *ngIf="showCustomerTable" class="table-container" 
       [ngClass]="{
         'reposition-lower': this.client.validation.enabled === 'true',
         'field-1': this.client.validation.fields.length === 1,
         'field-2': this.client.validation.fields.length === 2,
         'field-3': this.client.validation.fields.length === 3,
         'field-4': this.client.validation.fields.length === 4,
         'field-5': this.client.validation.fields.length === 5,
         'field-6': this.client.validation.fields.length === 6,
         'field-7': this.client.validation.fields.length === 7
        }">
    <div class="pointer"></div>
    <table class="customer-table">
      <tr>
        <th class="col-left">Type</th>
        <th class="col-right">Description</th>
      </tr>
      <tr>
        <td class="col-left">1</td>
        <td class="col-right">Strictly enforces a single account to a single mobile number. Verification is mandatory.</td>
      </tr>
      <tr>
        <td class="col-left">2</td>
        <td class="col-right">Allows for unlimited accounts to the same mobile number as long as all account numbers have the same verification value. Verification is mandatory.</td>
      </tr>
      <tr>
        <td class="col-left">3</td>
        <td class="col-right">Allows for unlimited accounts to unlimited mobile numbers. The verification value does not have to be the same for all accounts but verification is mandatory.</td>
      </tr>
      <tr>
        <td class="col-left">4</td>
        <td class="col-right">Allows for unlimited accounts to unlimited mobile numbers and does not require verification.</td>
      </tr>
    </table>
  </div>

  <select class="form-control customer-type" [(ngModel)]="client.verification.customer_type" (change)="toggleVerificationEnabled($event.target.value)">
    <option [value]="1" [attr.selected]="client.verification.customer_type === '1' ? true : null">1</option>
    <option [value]="2" [attr.selected]="client.verification.customer_type === '2' ? true : null">2</option>
    <option [value]="3" [attr.selected]="client.verification.customer_type === '3' ? true : null">3</option>
    <option [value]="4" [attr.selected]="client.verification.customer_type === '4' ? true : null">4</option>
  </select>
</div>

<p class="helper-text">Customer verification is used to determine whether the user who receives the message was the intended recipient in the case of customer accounts that share phone numbers.</p>

<ng-container *ngIf="client.verification.enabled === 'true'">
  <div class="select-container">
    <label>Maximum number of attempts</label>
    <select class="form-control" (change)="setMaxAttempts($event)">
      <option *ngFor="let num of max_attempts; index as i" value="{{i + 1}}" [attr.selected]="(i + 1) === client.verification.max_attempts ? true : null">{{i + 1}}</option>
    </select>
  </div>

  <div class="fields-container">
    <label>Verification fields</label>

    <div class="input-container">
      <div class="input clearfix" *ngFor="let input of selectedFields">
        <span>{{input.field | inputField}}</span>
        <i class="fa fa-minus-circle" aria-hidden="true" (click)="removeField(input)"></i>
      </div>
    </div>


    <div class="add-field-container">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>

      <div class="select-container">
        <select [ngStyle]="{'width.px':'210', 'display':'inline'}" class="form-control" (change)="holdField($event.target.value)">
          <option *ngFor="let input of availableFields" value="{{input.field}}" [attr.selected]="initSelected(input)">{{input.field | inputField}}</option>
          <option *ngIf="availableFields.length === 0" selected>N/A</option>
        </select>
      </div>

      <button class="btn btn-primary" (click)="selectField(heldField)" [class.inactive]="availableFields && availableFields.length === 0" [disabled]="availableFields && availableFields.length === 0">ADD</button>

    </div>
  </div>
</ng-container>