import { EventEmitter, Injectable } from '@angular/core';

export class Title {
  primaryTitle?: string;
  secondaryTitle?: string;
  buttonText?: string;
  buttonIconClass?: any;
  buttonAlignLeft?: boolean;
  buttonHandler?: Function;
  active?: boolean;
}

@Injectable()
export class TitleService {
  showEditDialog: EventEmitter<boolean> = new EventEmitter();
  titleChange: EventEmitter<Title> = new EventEmitter();
  buttonHandler: () => void = null;
  active = false;

  constructor() {}

  activate(
    primaryTitle: string,
    secondaryTitle?: string,
    buttonText?: string,
    buttonIconClass?,
    buttonHandler?: () => void,
    buttonAlignLeft?: boolean
  ) {
    const title = {
      primaryTitle,
      secondaryTitle,
      buttonText,
      buttonIconClass,
      buttonHandler,
      buttonAlignLeft,
      active: true,
    };
    this.titleChange.emit(title);
  }

  deactivate() {
    const title = new Title();
    this.titleChange.emit({ ...title, active: false });
  }

  getShowEditFlag() {
    return this.showEditDialog;
  }

  emitOnShowEditChange(flag: boolean) {
    this.showEditDialog.emit(flag);
  }
}
