import * as React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import {
  ButtonCancelOverride,
  ButtonOverride,
  ModalHeaderOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '@app/api-mgmt/components/push-api/styles/AddConfigurationButtonStyles';


const ActivateModalBody = ({
  onActivateClicked,
  onCancelClicked,
  integrationName
}: {
  onActivateClicked: () => void,
  onCancelClicked: () => void,
  integrationName: string
}) => {
  return (
    <>
      <ModalHeader style={ModalHeaderOverride.style}>
        Activate Push API Integration
      </ModalHeader>
      <ModalBody>
        Do you want to activate <b>{integrationName}</b>? This will begin to
        transmit events to the connected service.
      </ModalBody>
      <ModalFooter>
        <ModalButton
          type="reset"
          kind="tertiary"
          onClick={onCancelClicked}
          overrides={{ BaseButton: ButtonCancelOverride }}
        >
          Activate Later
        </ModalButton>
        <ModalButton
          type="submit"
          onClick={onActivateClicked}
          overrides={{ BaseButton: ButtonOverride }}
        >
          <span>ACTIVATE INTEGRATION</span>
          <ButtonIconRight>
            <i className="fa fa-check"></i>
          </ButtonIconRight>
        </ModalButton>
      </ModalFooter>
    </>
  );
};

const DeactivateModalBody = ({
  onDeactivateClicked,
  onCancelClicked,
  integrationName
}: {
  onDeactivateClicked: () => void,
  onCancelClicked: () => void,
  integrationName: string
}) => {
  return (
    <>
      <ModalHeader style={ModalHeaderOverride.style}>
        Deactivate Push API Integration
      </ModalHeader>
      <ModalBody>
        Do you want to deactivate <b>{integrationName}</b>? This will stop
        transmitting events to connected service.
      </ModalBody>
      <ModalFooter>
        <ModalButton
          type="reset"
          kind="tertiary"
          onClick={onCancelClicked}
          overrides={{ BaseButton: ButtonCancelOverride }}
        >
          Cancel
        </ModalButton>
        <ModalButton
          type="submit"
          onClick={onDeactivateClicked}
          overrides={{ BaseButton: ButtonOverride }}
        >
          <span>DEACTIVATE</span>
          <ButtonIconRight>
            <i className="fa fa-ban"></i>
          </ButtonIconRight>
        </ModalButton>
      </ModalFooter>
    </>
  );
};

export const ActivationModal = ({
  integrationName,
  integrationActive,
  onToggleClicked,
  onCancelClicked
}: {
  integrationName: string,
  integrationActive: boolean,
  onToggleClicked: (newState: boolean) => Promise<void>,
  onCancelClicked: () => void
}) => {

  return (
    <>
      <Modal
        isOpen
        onClose={onCancelClicked}>
        {integrationActive ? (
          <DeactivateModalBody
            integrationName={integrationName}
            onDeactivateClicked={() => onToggleClicked(false) }
            onCancelClicked={onCancelClicked}
          ></DeactivateModalBody>
        ) : (
          <ActivateModalBody
            integrationName={integrationName}
            onActivateClicked={() => onToggleClicked(true) }
            onCancelClicked={onCancelClicked}
          ></ActivateModalBody>
        )}
      </Modal>
    </>
  );
};
