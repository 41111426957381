import { Client } from './client';

export interface AgentUnassignResponse {
  message: string;
}

export type LoginQR = {
  qr: string;
};

export type LoginTOTP = {
  totp: boolean;
};
export class Role {
  id: string;
  created_at: string;
  updated_at: string;
  type: string;
  data: string[];

  static deserialize(input: Object) {
    const role = new Role();
    role.id = input['id'];
    role.created_at = input['created_at'];
    role.updated_at = input['updated_at'];
    role.type = input['type'];
    role.data = input['data'];
    return role;
  }
}

export class User {
  clientId: string;
  client_id: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  selected: boolean = false;
  secondary_clients: string[] = [];
  last_name: string;
  password: string;
  role_id: string[] = [];
  id: string;
  email_address: string;
  last_login: string;
  mobile_number: string;
  permissions: string[];
  client: Client;
  abbreviatedName: string;
  ld: string;
  group_id: string[];
  /**
   * Added for new rn-portal API. eventually all props will be camelcase
   */
  groupId: string[];

  static deserialize(input: Object) {
    const user = new User();
    user.clientId = input['client_id'];
    user.client_id = input['client_id'];
    user.created_at = input['created_at'];
    user.updated_at = input['updated_at'];
    user.first_name = input['first_name'];
    user.last_name = input['last_name'];
    user.password = input['password'];
    user.id = input['id'];
    user.group_id = input['group_id'];
    if (!input['secondary_clients']) {
      input['secondary_clients'] = [];
    }
    user.secondary_clients = input['secondary_clients'];

    user.last_login = input['last_login'];
    user.permissions = input['permissions'];
    user.email_address = input['email_address'];
    user.mobile_number = input['mobile_number'];

    if (user.first_name) {
      user.abbreviatedName = `${user.first_name[0]}. ${user.last_name}`;
    }

    // if role_id is a string, make it an array of one string.  If it's already an array then just use the array.
    if (typeof input['role_id'] === 'string') {
      user.role_id = [input['role_id']];
    } else if (Array.isArray(input['role_id'])) {
      user.role_id = input['role_id'];
    }

    user.ld = input['ld'];
    return user;
  }

  static serialize(user: User, keepPassword?: boolean) {
    delete user.created_at;
    delete user.updated_at;
    delete user.id;
    delete user.selected;
    delete user.permissions;
    delete user.last_login;
    delete user.email_address;
    delete user.abbreviatedName;
    delete user.client_id;
    delete user.clientId;
    if (!keepPassword) {
      delete user.password;
    }
    return user;
  }
}
