import React, { Dispatch, SetStateAction, useState, ReactNode } from 'react';

export type ClientInfo = {
  /**
   * ID of the current client
   */
  clientId: string;
};

export type ClientInfoContextType = [
  ClientInfo,
  Dispatch<SetStateAction<ClientInfo>>,
];

export type ClientInfoContextProviderProps = {
  children: ReactNode;
} & ClientInfo;

const ClientInfoContext = React.createContext<ClientInfoContextType>([
  {
    clientId: '',
  },
  () => {},
]);

export function ClientInfoProvider({
  clientId,
  children,
}: ClientInfoContextProviderProps) {
  const [clientInfo, setClientInfo] = useState({
    clientId,
  });
  return (
    <ClientInfoContext.Provider value={[clientInfo, setClientInfo]}>
      {children}
    </ClientInfoContext.Provider>
  );
}

export const useClientInfo = () => {
  const context = React.useContext(ClientInfoContext);

  if (context === undefined) {
    throw new Error(`useClientInfo must be used within a ClientInfoProvider`);
  }

  return context;
};
