<!-- Old PDF preview template -->
<ng-component *ngIf="!isExpandedPDFEnabled">
  <div class="pdf-preview-proof-page" *ngIf="journey && productGroup">
    <div class="header">
      <h1 class="header__title" id="journey-name">
        {{ journey?.latestContent?.name }}
      </h1>
      <div class="header__buttons">
        <button
          id="close-btn"
          class="btn btn-primary"
          i18n="Close preview tab button"
          (click)="close()"
        >
          Close
        </button>
        <button
          id="edit-mode-btn"
          class="btn btn-primary"
          (click)="toggleEditMode()"
        >
          {{ editMode ? 'Disable Content Edit' : 'Enable Content Edit' }}
        </button>
        <button
          id="pdf-btn"
          class="btn btn-primary"
          (click)="requestPdf()"
          [disabled]="this.pdfPreviewIsBusy"
        >
          Download PDF
        </button>
      </div>
      <!-- <div class="color-input">
        <label i18n="Product group banner label text">Brand Color</label>
        <color-picker [(color)]="pdfBrandColor"
                      (colorChanged)="pdfBrandColor=$event"></color-picker>
      </div> -->

      <p class="header__text">
        This is your PDF proof. You can edit before downloading by interacting
        with messages below, or editing text by clicking "content edit" above.
        Changes will appear in your final PDF.
      </p>
    </div>
    <div class="edit-mode-message" *ngIf="editMode">
      Text is now Editable. Click on any text to edit it before generating your
      PDF. These changes cannot be saved, but will appear in generated PDFs.
    </div>
    <div class="journey-pdf-preview-wrapper">
      <div id="journey-pdf-preview">
        <ul class="document" [ngStyle]="{ height: documentHeight() }">
          <li class="page" *ngFor="let page of pages; let pageNumber = index">
            <h2 class="page__title">{{ page.title }}</h2>
            <div class="page__previews-group">
              <ng-container
                *ngFor="
                  let message of page.messagePreviews;
                  let phoneIndex = index
                "
              >
                <ng-container *ngIf="isSmsMessage(message)">
                  <div
                    class="phone"
                    [id]="'sms--' + message.name"
                    *ngIf="isSmsMessage(message)"
                  >
                    <h3>SMS {{ message.step }}</h3>
                    <phone
                      [productGroup]="productGroup"
                      [message]="message"
                      [screen]="'sms'"
                      [large]="true"
                      [components]="journeyContent.components"
                    ></phone>
                  </div>
                  <journey-arrow
                    *ngIf="isDisplayableWireMessage(message)"
                    [arrowType]="'straight-right'"
                  ></journey-arrow>
                </ng-container>

                <div
                  class="phone"
                  [id]="'message--' + message.name"
                  *ngIf="isDisplayableWireMessage(message)"
                >
                  <h3>{{ message.step || message.label }}</h3>
                  <phone
                    [message]="message"
                    *ngIf="message.type !== 'form_info_capture'"
                    [screen]="'wire'"
                    [productGroup]="productGroup"
                    [large]="true"
                    [showPdfMarkup]="true"
                    [components]="journeyContent.components"
                  ></phone>
                  <phone
                    [message]="message"
                    *ngIf="message.type === 'form_info_capture'"
                    [screen]="'form'"
                    [productGroup]="productGroup"
                    [large]="true"
                    [showPdfMarkup]="true"
                    [components]="journeyContent.components"
                  ></phone>
                </div>
                <journey-arrow
                  *ngIf="phoneIndex < page.messagePreviews.length - 1"
                  [arrowType]="'straight-right'"
                ></journey-arrow>
              </ng-container>
            </div>

            <div class="page__footer">
              <div class="page__details-group">
                <ng-container
                  *ngFor="
                    let message of page.messagePreviews;
                    let phoneIndex = index
                  "
                >
                  <div class="page__detail" *ngIf="isSmsMessage(message)"></div>
                  <div
                    class="page__detail"
                    *ngIf="isDisplayableWireMessage(message)"
                  >
                    <ol class="page__detail_actions">
                      <li *ngFor="let action of message?.wire?.actions">
                        <span
                          *ngIf="action.type !== 'two_way'"
                          [innerHtml]="actionDetailText(action)"
                        ></span>
                        <span *ngIf="action.type === 'two_way'">
                          {{ twoWayActionDetailText(action) | async }}
                        </span>
                      </li>
                    </ol>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="page__metadata">
              <div
                *ngIf="journeyContent.product_group_name"
                class="page__metadata-item"
              >
                <span i18n="Experience product group label"
                  >Product Group:
                </span>
                <span id="product-group-name">{{
                  journeyContent.product_group_name
                }}</span>
              </div>

              <div *ngIf="journeyContent.campaign" class="page__metadata-item">
                <span>Campaign: </span>
                <span>{{ journeyContent.campaign }}</span>
              </div>

              <div *ngIf="journey.created_at" class="page__metadata-item">
                <span>Date Created: </span>
                <span>{{ journey.created_at | date: 'shortDate' }}</span>
              </div>

              <div *ngIf="journey.updated_at" class="page__metadata-item">
                <span>Date Modified: </span>
                <span>{{ journey.updated_at | date: 'shortDate' }}</span>
              </div>

              <div *ngIf="journey.created_by_name" class="page__metadata-item">
                <span>Created By: </span>
                <span>{{ journey.created_by_name }}</span>
              </div>

              <div *ngIf="journey.created_by_name" class="page__metadata-item">
                <span>Page: </span>
                <span>{{ pageNumber + 1 }} of {{ pages.length }}</span>
              </div>
            </div>

            <div class="page__background"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-component>

<!-- New PDF preview template (expanded or collapsed) -->
<ng-component *ngIf="isExpandedPDFEnabled">
  <div class="pdf-preview-proof-page" *ngIf="journey && productGroup">
    <div class="header__new">
      <div class="header__top__new">
        <h1 class="header__title__new" id="journey-name">
          {{ journey?.latestContent?.name }}
        </h1>
        <div class="header__buttons__new">
          <button
            id="edit-mode-btn"
            class="btn btn-primary"
            (click)="toggleEditMode()"
          >
            {{ editMode ? 'Disable Content Edit' : 'Enable Content Edit' }}
          </button>
          <button
            id="pdf-btn"
            class="btn btn-primary"
            (click)="requestPdf()"
            [disabled]="this.pdfPreviewIsBusy"
          >
            Download PDF
          </button>
        </div>
      </div>
      <p class="header__text__new">
        This is your PDF proof. By enabling Content Edit you can make changes to
        the text below, which will appear ONLY in your final PDF.
      </p>
      <p *ngIf="previewMode === 'collapsed'" class="header__text__new">
        This PDF is optimized for printing and will not export to show Feed
        content in its entirety. You can
        <span class="link__to__preview" (click)="viewExpandedPreview()"
          >view an Expanded PDF here</span
        >.
      </p>
      <p *ngIf="previewMode === 'expanded'" class="header__text__new">
        This PDF is formatted to show feed content in its entirety and is not
        optimized for printing. You can
        <span class="link__to__preview" (click)="viewCollapsedPreview()"
          >view a Print-ready PDF here</span
        >.
      </p>
    </div>
    <div class="edit-mode-message" *ngIf="editMode">
      Text is now Editable. Click on any text to edit it before generating your
      PDF. These changes cannot be saved, but will appear in generated PDFs.
    </div>
    <div class="journey-pdf-preview-wrapper">
      <div id="journey-pdf-preview">
        <div class="metadata" *ngIf="previewMode === 'expanded'">
          <div class="metadata--content">
            <div
              *ngIf="journeyContent.product_group_name"
              class="metadata--item"
            >
              <span i18n="Experience product group label">Product Group: </span>
              <span id="product-group-name">{{
                journeyContent.product_group_name
              }}</span>
            </div>

            <div *ngIf="journeyContent.campaign" class="metadata--item">
              <span>Campaign: </span>
              <span>{{ journeyContent.campaign }}</span>
            </div>

            <div *ngIf="journey.created_at" class="metadata--item">
              <span>Date Created: </span>
              <span>{{ journey.created_at | date: 'shortDate' }}</span>
            </div>

            <div *ngIf="journey.updated_at" class="metadata--item">
              <span>Date Modified: </span>
              <span>{{ journey.updated_at | date: 'shortDate' }}</span>
            </div>

            <div *ngIf="journey.created_by_name" class="metadata--item">
              <span>Created By: </span>
              <span>{{ journey.created_by_name }}</span>
            </div>
          </div>
          <div class="journey-pdf-toc my-0 mx-auto pt-4">
            <h2 class="m-0">{{ journeyContent.name }}</h2>
            <ol class="anchor-list mb-0">
              <li
                *ngFor="let anchor of pageAnchors"
                [ngClass]="{ 'indent-anchor': anchor.isWorkFlowDelayer }"
              >
                <a
                  class="toc-preview-only"
                  [routerLink]="[]"
                  (click)="goToPageId(anchor.pageId)"
                  >{{
                    anchor.step
                      ? anchor.step + ': ' + anchor.title
                      : anchor.title
                  }}</a
                >
                <a class="toc-print-only" href="#{{ anchor.pageId }}">{{
                  anchor.step ? anchor.step + ': ' + anchor.title : anchor.title
                }}</a>
              </li>
            </ol>
          </div>
          <!-- This empty div is to block metadata from being editted -->
          <div class="metadata--background" *ngIf="editMode"></div>
        </div>
        <ul class="document">
          <li class="page" *ngFor="let page of pages; let pageNumber = index">
            <h2 class="page__title" id="{{ getPDFPageId(page, pageNumber) }}">
              {{ page.title }}
            </h2>
            <div class="page__trigger_ids">
              <p *ngIf="page.triggerId">
                <span>Trigger ID: </span>{{ page.triggerId }}
              </p>
              <p *ngIf="page.clientTriggerId">
                <span>Client Trigger ID: </span>{{ page.clientTriggerId }}
              </p>
            </div>
            <div
              class="page__previews-group"
              [class.page--expanded-view]="previewMode === 'expanded'"
            >
              <ng-container
                *ngFor="
                  let message of page.messagePreviews;
                  let phoneIndex = index
                "
              >
                <ng-container *ngIf="isSmsMessage(message)">
                  <div
                    class="phone"
                    [id]="'sms--' + message.name"
                    *ngIf="isSmsMessage(message)"
                  >
                    <h3>SMS {{ message.step }}</h3>
                    <phone
                      [productGroup]="productGroup"
                      [message]="message"
                      [screen]="'sms'"
                      [large]="true"
                      [components]="journeyContent.components"
                      [previewMode]="previewMode"
                    ></phone>
                  </div>
                  <journey-arrow
                    *ngIf="isDisplayableWireMessage(message)"
                    [arrowType]="'straight-right'"
                  ></journey-arrow>
                </ng-container>

                <div
                  class="phone"
                  [id]="'message--' + message.name"
                  *ngIf="isDisplayableWireMessage(message)"
                >
                  <h3>{{ message.step || message.label }}</h3>
                  <phone
                    [message]="message"
                    *ngIf="message.type !== 'form_info_capture'"
                    [screen]="'wire'"
                    [productGroup]="productGroup"
                    [large]="true"
                    [showPdfMarkup]="true"
                    [components]="journeyContent.components"
                    [stretch]="previewMode === 'expanded'"
                    [previewMode]="previewMode"
                  ></phone>
                  <phone
                    [message]="message"
                    *ngIf="message.type === 'form_info_capture'"
                    [screen]="'form'"
                    [productGroup]="productGroup"
                    [large]="true"
                    [showPdfMarkup]="true"
                    [components]="journeyContent.components"
                    [stretch]="previewMode === 'expanded'"
                    [previewMode]="previewMode"
                  ></phone>
                </div>
                <journey-arrow
                  *ngIf="phoneIndex < page.messagePreviews.length - 1"
                  [arrowType]="'straight-right'"
                ></journey-arrow>
              </ng-container>
            </div>

            <div class="page__footer">
              <div class="page__details-group">
                <ng-container
                  *ngFor="
                    let message of page.messagePreviews;
                    let phoneIndex = index
                  "
                >
                  <div class="page__detail" *ngIf="isSmsMessage(message)"></div>
                  <div
                    class="page__detail"
                    *ngIf="isDisplayableWireMessage(message)"
                  >
                    <ol class="page__detail_actions">
                      <li *ngFor="let action of message?.wire?.actions">
                        <span
                          *ngIf="action.type !== 'two_way'"
                          [innerHtml]="actionDetailText(action)"
                        ></span>
                        <span *ngIf="action.type === 'two_way'">
                          {{ twoWayActionDetailText(action) | async }}
                        </span>
                      </li>
                    </ol>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="page__metadata" *ngIf="previewMode === 'collapsed'">
              <div
                *ngIf="journeyContent.product_group_name"
                class="page__metadata-item"
              >
                <span i18n="Experience product group label"
                  >Product Group:
                </span>
                <span id="product-group-name">{{
                  journeyContent.product_group_name
                }}</span>
              </div>

              <div *ngIf="journeyContent.campaign" class="page__metadata-item">
                <span>Campaign: </span>
                <span>{{ journeyContent.campaign }}</span>
              </div>

              <div *ngIf="journey.created_at" class="page__metadata-item">
                <span>Date Created: </span>
                <span>{{ journey.created_at | date: 'shortDate' }}</span>
              </div>

              <div *ngIf="journey.updated_at" class="page__metadata-item">
                <span>Date Modified: </span>
                <span>{{ journey.updated_at | date: 'shortDate' }}</span>
              </div>

              <div *ngIf="journey.created_by_name" class="page__metadata-item">
                <span>Created By: </span>
                <span>{{ journey.created_by_name }}</span>
              </div>

              <div *ngIf="journey.created_by_name" class="page__metadata-item">
                <span>Page: </span>
                <span>{{ pageNumber + 1 }} of {{ pages.length }}</span>
              </div>
            </div>

            <div class="page__background"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-component>

<message-dialog #messageDialogProgress [showAffirm]="false"></message-dialog>
<message-dialog #messageDialogError (affirm)="closeDialog()"></message-dialog>
<rn-timer-popup></rn-timer-popup>
