import React from 'react';
import { Block } from 'baseui/block';

export type CustomerStatusBadgeProps = {
  content?: string;
  variant?: 'primary' | 'secondary';
};

export const CustomerStatusBadge = ({
  content = '',
  variant = 'primary',
}: CustomerStatusBadgeProps) => {
  const bgColor = variant === 'primary' ? '#E8F8B7' : '#edeeef';
  const color = variant === 'primary' ? '#37474F' : '#908E8F';

  return (
    <Block
      overrides={{
        Block: {
          style: () => ({
            backgroundColor: bgColor,
            color: color,
            textAlign: 'left',
            boxShadow: '0px 4px 4px 0px #0000000D',
            paddingTop: '3px',
            paddingBottom: '3px',
            paddingLeft: '25px',
            fontSize: '12px',
          }),
        },
      }}
    >
      {content}
    </Block>
  );
};
